import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import { dispatch } from "../store";

const initialState = {
    isLoading: false,
    error: null,
    sourceChannelAdvisor:{},
    targetChannelAdvisor:{},
    marketPlaceStore:[],
    marketPlaceCart:[],
    qualityCheckedImageStore:[],
    qualityCheckedCart:[],
    processedImages:{},
    openNoProcessedImages:true,
    sourceAndTargetList:{},
    cartItemLimit:50,
    totalCountMarketplace:10,
    totalCountQualityChecked:10,
}

const slice = createSlice({
    name: "qcboard",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
        state.isLoading = true;
        },
        // STOP LOADING
        stopLoading(state) {
          state.isLoading = false;
          },
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
        //SET NO PROCESSED IMAGES
        setOpenNoProcessedImages(state, action) {
          state.openNoProcessedImages = action.payload;
        },
        // SET SOURCE CHANNEL ADVISOR
        setSourceChannelAdvisor(state, action) {
          state.sourceChannelAdvisor = action.payload;
        },
        // SET TARGET CHANNEL ADVISOR
        setTargetChannelAdvisor(state, action) {
          state.targetChannelAdvisor = action.payload;
        },
        //MARKETPLACE CART
        setMarketPlaceCart(state, action) {
            state.marketPlaceCart = action.payload;
        },
        //MARKETPLACE STORE
        setMarketPlaceStore(state,action){
            state.isLoading=false;
            state.marketPlaceStore=action.payload;
        },
        //QUALITY CHECKED IMAGE STORE
        setQualityCheckedImageStore(state,action){
          state.isLoading=false;
          state.qualityCheckedImageStore=action.payload;
        },
        // SET QUALITY CHECKED CART
        setQualityCheckedCart(state, action) {
          state.qualityCheckedCart = action.payload;
        },
        // SET PROCESSED IMAGES
        setProcessedImages(state, action){
          state.isLoading=false;
          state.processedImages = action.payload;
        },
        // SOURCE AND TARGET LIST
        setSourceAndTargetList(state,action){
          state.isLoading=false;
          state.sourceAndTargetList = action.payload;
        },
        setTotalCountMarketPlace(state, action){
          state.totalCountMarketplace=action.payload;
        },
        setTotalCountQualityChecked(state, action){
          state.totalCountQualityChecked=action.payload;
        },

    }
});

// Reducer
export default slice.reducer;


export const {startLoading, stopLoading, hasError, setMarketPlaceStore, setTotalCountMarketPlace, setSourceChannelAdvisor, setTargetChannelAdvisor, setMarketPlaceCart, setQualityCheckedCart, setProcessedImages, setOpenNoProcessedImages, setQualityCheckedImageStore, setTotalCountQualityChecked } = slice.actions;



export const getAlreadyProcessedImages = (entityId) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/taskProcessedStatus/${entityId}`);
      dispatch(slice.actions.setProcessedImages(response.data.data));
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getSourceAndTargetList = () =>{
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(
       `${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/marketplaces?source=true&target=true`
      );
      dispatch(slice.actions.setSourceAndTargetList(response.data.data));
      
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
    }
  };
}
export const getMarketPlaceData = (marketplaceId,page,pageSize) => {
    return axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/marketplace/${marketplaceId}/listing?page=${page}&pageSize=${pageSize}`
        );
  };

  export const uploadMarketplaceingestData = (source, target, products) =>{
      return axios.post(`${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/ingest`,{
        sourceMarketplaceId:source,
        targetMarketplaceId:target,
        products:products
         })      
  };

  export const uploadQualitycheckedDataToQueue = (products,marketplaceId) =>{
      return axios.put(`${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/createJobs/addToQueue`,{
        products,
        target:marketplaceId
      });     
};

  export const getQualityCheckedImages = (page,pageSize) => {
    return axios.get(`${process.env.NEXT_PUBLIC_API_URL}v4/quality-check/listing?pageSize=${pageSize}&page=${page}`);
  };
