import React from "react";
import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

export default function CircularLoader(props) {
  const { size, color, thickness, sx } = props;

  return (
    <CircularProgress size={size} color={color} thickness={thickness} sx={sx} />
  );
}

CircularLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  thickness: PropTypes.number,
  sx: PropTypes.object,
};

CircularLoader.defaultProps = {
  size: 12,
  color: "secondary",
  thickness: 2,
  sx: { marginLeft: "4px" },
};
