import { getCategories } from "@/modules/editor-v2/services/Jobs";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export default function useOtherCategory() {

    const {data:categories, isLoading, isError, refetch } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategories,
    });
   
    const {otherCategory, otherSubCategory} = useMemo(() => {
        if (categories && Array.isArray(categories)) {
            const otherCategory = categories?.find(cat => 
                cat.name?.toLowerCase()?.includes('other'),
            );

            const otherSubCategory = otherCategory?.subcategories?.find(subCat =>
                subCat?.name?.toLowerCase()?.includes('other'),
            );

            return {
                otherCategory: otherCategory ?? null,
                otherSubCategory: otherSubCategory ?? null,
            };
        }
        return {
            otherCategory: null,
            otherSubCategory: null,
        };
    }, [categories]);

    return {otherCategory, otherSubCategory, isLoading: isLoading || isError, refetch};
}