import axios from "src/utils/axios";
import API_SERVICE from "src/utils/axios";

const MY_WORKS_LIMIT = 10;

export async function getPaginatedJobs({ pageParam = 0, searchText = "", projectFilterInfographics = "", showPreExistOnly = null, ...args }) {
  let jobOrderStatusParams = "";
  
  if (projectFilterInfographics == "Download") {
    jobOrderStatusParams = "&jobOrder_status[0]=Download";
  } else if (projectFilterInfographics == "Incomplete") {
    jobOrderStatusParams = "&jobOrder_status[0]=Incomplete";
  } else if (projectFilterInfographics == "all") {
    jobOrderStatusParams = "&jobOrder_status[0]=Download&jobOrder_status[1]=Incomplete";
  }

  const res = await axios.get(
    `v4/exploreTemplates/myWorkListNew?searchStr=${searchText}&status=all&limit=${MY_WORKS_LIMIT}&skip=${Number(pageParam) * MY_WORKS_LIMIT}${showPreExistOnly ? `&showPreExistOnly=${showPreExistOnly}` : ''}${jobOrderStatusParams}`
  );

  return res.data?.data?.jobsList?.length > 0 ? res.data?.data?.jobsList : undefined;
}

export async function getPaginatedTemplates({queryKey:[_0,_1,searchText,searchString,searchType,imageFormat,colors,hotspotOnly,imageId,angleId,updateRecentSearch,isInfographics, tags=[]],pageParam = 0}) {
  const res = await axios.put(
    `/v3/exploreTemplates/getTemplates?limit=24&skip=${pageParam * 24}`,
    {
      searchStrings:searchText?.length > 0 ? [...searchString, searchText] : searchString,
      searchType,
      filters: {
        productType: [],
        imageFormat,
        category:[],
        colors,
        hotspotOnly,
        imageId,
        angleId,
        isInfographics,
        theme:tags
      },
      updateRecentSearch,
    }
  );

  const templateList = res.data?.data?.templateList || [];
  return templateList;
}

export async function getTemplatesData({queryKey:[_0,_1,searchText,searchString,searchType,imageFormat,colors,hotspotOnly,imageId,angleId,updateRecentSearch,isInfographics],pageParam = 0}) {
  const res = await axios.put(
    `/v3/exploreTemplates/getTemplates`,
    {
      searchStrings:searchText?.length > 0 ? [...searchString, searchText] : searchString,
      searchType,
      filters: {
        productType: [],
        imageFormat,
        category:[],
        colors,
        hotspotOnly,
        imageId,
        angleId,
        isInfographics
      },
      updateRecentSearch,
    }
  );

  const templateList = res.data?.data || {};
  return templateList;
}

export async function getTemplatesCount({queryKey:[_0,_1,selectedFilter,searchType, imageId]}) {
  const res = await axios.put(`/v3/exploreTemplates/getTemplates?count=true`,{
    searchStrings: selectedFilter?.searchText?.length > 0 ? [...selectedFilter?.searchString, selectedFilter?.searchText] : selectedFilter?.searchString,
    searchType: searchType,
    filters: {
      productType: [],
      imageFormat: selectedFilter?.imageFormat,
      category: [],
      colors:selectedFilter?.colors,
      hotspotOnly:selectedFilter?.hotspotOnly,
      imageId,
      angleId:selectedFilter?.prespective?.angleGroupId,
      isInfographics:selectedFilter?.isInfographics,
    }
  });
  return (res.data?.data?.count)?res.data?.data?.count:0;
}

export async function getTrendingList({queryKey:[_0,_1,searchString]}) {
  const res = await axios.put(`/v3/exploreTemplates/getTemplates`,{
    searchStrings: searchString,
    searchType: '',
    filters: {
      productType: [],
      imageFormat: [],
      category: [],
      colors:[]
    }
  });
  return res.data?.data;
}

export async function getTemplate(_id:string) {
  const res = await axios.get("/v3/exploreTemplates/getTemplateData?templateId="+_id);
  return res.data?.data;
}

export async function uploadInfographicsCSV(data:FormData) {
  const res = await axios.post('/v5/shootnewLifestyleImage/importInfographics', data);
  return res.data?.data;
}

export async function getSuggestionList(){
  const res = await axios.get("/v3/exploreTemplates/filters");
  return res.data?.data;
}

export async function deleteJobImage(image_id:string){
  return axios.put(`/v4/shootnewLifestyleImage/removeImage?image_id=${image_id}`);
};

export async function getPrespectiveList({queryKey:[_0,_1,imageFormat,imageId,size=3]}) {
  const res = await axios.put(`/v3/exploreTemplates/getTemplatesByAngleGroup/?size=${size}`,{
    imageId,
    filters: {
      imageFormat:imageFormat,
    }
  });
  return res.data?.data;
}

export async function updateBatchEditorJob(payload:any){
  return axios.put(`/v4/pages/batchUpdateAllEditorObjects`,payload);
};
export async function getFeaturedTemplates() {
  const res = await axios.put(
    `/v3/exploreTemplates/getTemplates?limit=24&skip=${0}`,{
      searchStrings:[],
      filters: {
        productType: [],
        category:[],
        colors: [],
        isInfographics: true,
        hotspotOnly: false,
        imageFormat: ["Square","Landscape","Portrait"],
      },
      disableEmbeddingSearch: true,
      updateRecentSearch:false,
  });

  return res.data?.data?.templateList || [];
}

export async function getTemplateByTags(tags: string[]) {
  const res = await axios.put(
    `/v3/exploreTemplates/getTemplates?limit=24&skip=${0}`,{
      searchStrings:[],
      filters: {
        productType: [],
        category:[],
        colors: [],
        isInfographics: true,
        hotspotOnly: false,
        imageFormat: ["Square","Landscape","Portrait"],
        theme: [...tags],
      },
      updateRecentSearch:false,
  });

  return res.data?.data?.templateList || [];
}

export async function sendMessage(payload: FormData) {

  try {
    return await API_SERVICE({
      url: 'v1/api/chat/sendMediaInChat',
      baseURL: process.env.NEXT_PUBLIC_API_URL_V2 || "",
      method: "POST",
      data: payload,
    });
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; // Rethrow error for handling in the caller function
  }
}
export async function negativefeedBack(payload) {

  try {
    await API_SERVICE.post(`/v4/feedback/negativeFeedback`,payload)
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; 
  }
}

export async function getRecentTicket(userId) {

  try {
    const baseUrl = process.env.NEXT_PUBLIC_API_URL_V2;
    const result = await API_SERVICE.get(`${baseUrl}v1/api/chat/getRecentTicket/0/25/?userId=${userId}`);
    return result.data.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; 
  }
}

