import { useSetAtom } from "jotai";
import { backgroundFilterAtom,
         backgroundFilterBackgroundModeAtom,
         backgroundFilterIsAllAngleGroupActiveAtom,
         backgroundFilterProductImageIdAtom,
         backgroundFilterProductImageLayerIdAtom,
         backgroundFilterProductImageUrlAtom,
         backgroundFilterTemplateTypeAtom,
         materialAtom,
         settingsAtom,
         productAtom,
         lightingAtom,
         moodAtom,
         scaleAndProportionAtom,
         colorPalleteAtom,
         additionalElementsAtom,
         materialListAtom,
         settingsListAtom,
         productListAtom,
         lightingListAtom,
         moodListAtom,
         scaleAndProportionListAtom,
         colorPalleteListAtom,
         additionalElementsListAtom,
         aiBackgroundUrlsAtoms,
         aiBackgroundPromptAtom,
         aiBackgroundsS3UrlsAtom,
         aigenerationLayerProgressAtom
       } from "../Atoms";
import { BACKGROUND_MODE, TEMPLATE_TYPE, filterObject } from "../LeftToolBar/Templates";

const useResetAtomHandler = () => {
 const setFilterIsAllAngleGroupActiveAtom = useSetAtom(backgroundFilterIsAllAngleGroupActiveAtom);
 const setFilterBackgroundModeAtom = useSetAtom(backgroundFilterBackgroundModeAtom);
 const setFilterTemplateTypeAtom = useSetAtom(backgroundFilterTemplateTypeAtom);
 const setFilterProductImageLayerIdAtom = useSetAtom<any>(backgroundFilterProductImageLayerIdAtom);
 const setFilterProductImageUrlAtom = useSetAtom<any>(backgroundFilterProductImageUrlAtom);
 const setFilterProductImageIdAtom = useSetAtom<any>(backgroundFilterProductImageIdAtom);
 const setFilterAtom = useSetAtom(backgroundFilterAtom);
 const setMaterial = useSetAtom(materialAtom);
 const setSettings = useSetAtom(settingsAtom);
 const setProduct = useSetAtom(productAtom);
 const setLighting = useSetAtom(lightingAtom);
 const setMood = useSetAtom(moodAtom);
 const setScaleAndProportion = useSetAtom(scaleAndProportionAtom);
 const setColorPallete = useSetAtom(colorPalleteAtom);
 const setAdditionalElements = useSetAtom(additionalElementsAtom);
 const setMaterialList = useSetAtom(materialListAtom);
 const setSettingsList = useSetAtom(settingsListAtom);
 const setProductList = useSetAtom(productListAtom);
 const setLightingList = useSetAtom(lightingListAtom);
 const setMoodList = useSetAtom(moodListAtom);
 const setScaleAndProportionList = useSetAtom(scaleAndProportionListAtom);
 const setColorPalleteList = useSetAtom(colorPalleteListAtom);
 const setAdditionalElementsList = useSetAtom(additionalElementsListAtom);
 const setAiBackgroundUrlsAtoms = useSetAtom(aiBackgroundUrlsAtoms);
 const setAiBackgroundPromptAtom = useSetAtom(aiBackgroundPromptAtom);
 const setAiBackgroundsS3UrlsAtom = useSetAtom(aiBackgroundsS3UrlsAtom);
 const setAigenerationLayerProgressAtom = useSetAtom(aigenerationLayerProgressAtom);
   
 const resetBackgroundFilter = () => {
    setFilterIsAllAngleGroupActiveAtom(false);
    setFilterBackgroundModeAtom(BACKGROUND_MODE.BASIC);
    setFilterTemplateTypeAtom(TEMPLATE_TYPE.ALL);
    setFilterProductImageLayerIdAtom(undefined);
    setFilterProductImageUrlAtom(undefined);
    setFilterProductImageIdAtom(undefined);
    setFilterAtom(filterObject);
 };

 const resetPromptHandler = () =>{
   setMaterial('');
   setSettings('');
   setProduct('');
   setLighting('');
   setMood('');
   setScaleAndProportion('');
   setColorPallete('');
   setAdditionalElements('');
   setMaterialList([]);
   setSettingsList([]);
   setProductList([]);
   setLightingList([]);
   setMoodList([]);
   setScaleAndProportionList([]);
   setColorPalleteList([]);
   setAdditionalElementsList([]);
   setAiBackgroundUrlsAtoms([]);
   setAiBackgroundPromptAtom('');
   setAiBackgroundsS3UrlsAtom([]);
   setAigenerationLayerProgressAtom(0);
 }

  return {resetBackgroundFilter, resetPromptHandler}
}

export default useResetAtomHandler;
