
import React, { useEffect } from "react";
import { useImperativeHandle, useState } from "react";
import { HexColorPicker } from "react-colorful";

export default React.forwardRef(function ColorPicker({onChange, defaultValue="#FCFCFC"}, ref) {
    const [color, setColor] = useState(defaultValue);
    const [inputValue, setInputValue] = useState(defaultValue);

    useImperativeHandle(ref, () => ({
        setColor: _onChange
    }));

    const _onChange = (color) => {
        onChange?.(color);
        setColor(color);
        setInputValue(color);
    };

    const onInputChange = (e) => {
        if (/^#[A-Fa-f0-9]*$/.test(e.target.value)) setInputValue(e.target.value);
        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(e.target.value)) _onChange(e.target.value);
    };
    
    useEffect(()=>{
        if(defaultValue !== color){
            setColor(defaultValue)
            setInputValue(defaultValue);
            }

    },[defaultValue]);

    return <div style={styles.pRelative}>
        <HexColorPicker onChange={_onChange} color={color}/>
        <div style={styles.inputContainer}>
            <input value={inputValue.toUpperCase()} style={styles.input} onChange={onInputChange} />
        </div>
    </div>
});

const styles = {
    inputContainer: {
        position: "absolute", 
        width:"96px", 
        paddingLeft:"16px",
        bottom:4, 
        right:0
    },
    input: {
        width:"100%", 
        height:"22px", 
        fontSize:"12px", 
        fontWeight:"400",
        border: "1px solid #EEEEEE", 
        borderRadius:"4px", 
        paddingTop:"3px", 
        paddingBottom:"1px", 
        lineHeight: "150%", 
        fontFamily:'Objectivity', 
        paddingLeft:'4px', 
        paddingRight:"4px"
    },
    pRelative: {
        position: "relative"
    }
}