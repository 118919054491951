import React from "react";
import { css } from "@emotion/react";
import IconButton, { IconButtonSizes, IconButtonTypes } from "../../ui-elements/IconButton";
import { useSelector } from "react-redux";
import SVGComponent from "./SVGComponent";

const styles = {
    premiumHeader: css`
        font-size: 15px;
        height: 56px;
        font-weight: 500;
        display: flex; 
        flex-direction: row;
        align-items: start;
        margin-bottom: 17px;
        padding: 16px 0px 0px 20px;
        background: rgba(18, 18, 18, 0.08);
    `,
    premiumContainer: css`
        flex: 1;
        max-width:520px;
        background: rgb(95,46,235);
        background: linear-gradient(114deg, rgba(95,46,235,1) 0%, rgba(228,58,170,1) 99%, rgba(240,55,80,1) 100%);
        color: white;
        border-radius:16px 0px 0px 16px;
        padding:48px;
        position:relative;
    `,
    unlockPremium: css`
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        padding-bottom: 10px;
    `,
    unlockPremiumSubtitle:css`
        font-family: Objectivity;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 10px;
    `,
    premiumContent: css`
        display: flex;
        flex-direction: column; 
        margin-top: 30%; 
        align-items: center; 
    `,
    iconStyle:css`
        display: flex;
        flex-direction: row;
        padding-bottom: 12px;
        align-items: center;
        font-family: Objectivity;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    `,
    loginVector: {
        background: "radial-gradient(3301.74% 163.8% at 0% -3.91%, rgba(95, 46, 235, 0.05) 0%, rgba(235, 46, 131, 0.05) 31.65%, rgba(210, 2, 2, 0.05) 71.24%, rgba(240, 55, 80, 0.05) 78.3%)",
        top: "250px",
        height: "60.5%",
        width: "50%",
        position: "absolute"
    },
    crossedText: {
        textDecoration: "line-through"
    },
    subtext: { 
        fontSize: "24px" 
    },
    premiumHeaderImage: {
        paddingRight: "8px" 
    },
    text: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    supStyle: {
        marginRight: "5px"
    }
};
export default function premiumSection() {
    const {entireStrapiData} = useSelector((state)=> state.strapi);
    const strapiData = entireStrapiData?.NewFeaturePricingCont?.NewFeaturePricingCont;
    const featureArray = [["/images/home/highResLight.svg", strapiData?.HighResolutionOutput || "High Resolution Image Outputs"], ["/images/home/customBGLight.svg",strapiData?.CustomizableBackground || "Customizable Backgrounds"], ["/images/home/bulkEditLight.svg", strapiData?.ExperienceBulkEditing ||  "Experience Bulk Editing Experience"]]

    return (
        <div css={styles.premiumContainer}>
            <div css={styles.mb}>
                <h4 css={styles.unlockPremium}>{strapiData?.UpgradeToText || "Unlock high quality Images"}</h4>
                <p css={styles.unlockPremiumSubtitle}>{strapiData?.SignInGainAccess || "By signing up with DoMyShoot, you’ll gain access to:"}</p>
                {
                    featureArray && featureArray.map((value) => (
                        <div css={styles.iconStyle}>
                            <IconButton icon={value?.[0]} size={IconButtonSizes.xs} type={IconButtonTypes.purple} />
                            {value?.[1]}
                        </div>
                    ))
                }
            </div>
            <SVGComponent 
              src0={"/images/home/Frame4347.png"}
              src1={"/images/home/Frame4348.png"}
              src2={"/images/home/Frame4345.png"}
              src3={"/images/home/Frame4354.png"}
              src4={"/images/home/Frame4351.png"}
              src5={"/images/home/Frame4353.png"}
              src6={"/images/home/Frame4350.png"}
              />
        </div>
    )

}