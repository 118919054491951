'use client';

// scroll bar
import 'simplebar/src/simplebar.css';

import "./style.css";

// lazy image
import PropTypes from 'prop-types';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
// next
import Head from 'next/head';
// utils
// contexts
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
// theme
import ThemeProvider from '../theme';
// components
import { useEffectOnce } from "@/hooks/useEffectOnce";
import SuccessPopUp from '@/modules/editor-v2/SuccessJobPopup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DevTools } from 'jotai-devtools';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import NotistackProvider from 'src/components/NotistackProvider';
import { AuthContext, AuthProvider } from 'src/contexts/JWTContext';
import AuthSection from 'src/modules/AuthSection';
import AuthenticationHandler from "src/modules/AuthenticationHandler";
import { setCouponData } from 'src/redux/reducers/appRedirect.reducer';
import { dispatch, persistor, store } from 'src/redux/store';
import ProgressBar from '../components/ProgressBar';
import RtlLayout from '../components/RtlLayout';
import ThemeColorPresets from '../components/ThemeColorPresets';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
import "../components/color-picker-alpha/color-picker-alpha.css";
import "../components/color-picker.css";
import MaskLayer from './Mask';
// ----------------------------------------------------------------------
MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  settings: PropTypes.object,
};
export default function MyApp(props) {
  const { Component, pageProps, settings } = props;
  const router=useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);
  const ctx = useContext(AuthContext);
  const branchCouponData=()=>{
    //load branch
    (function(b,r,a,n,c,h,_,s,d,k){
      if(!b[n]||!b[n]._q)
         {for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}
       })
  (window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
  // init Branch
  branch.init(`${process.env.NEXT_PUBLIC_BRANCH_ID}`, function(err, data) {
    const branchData = data?.data_parsed;
    localStorage.setItem("branchData",JSON.stringify(branchData));
   dispatch(setCouponData(data?.data_parsed));
  //  Do not redirect on desktop/webapp (it will remove the query params)
  //  if(data?.data_parsed?.$desktop_url){
  //   router.push(data?.data_parsed?.$desktop_url ?? "/");
  //  }
   if(err) console.error("Error in fetching branch data",err);

    });
  };

  function removeParametersFromURL(url, parametersToRemove) {
    const urlObject = new URL(url);
    for (const param of parametersToRemove) {
        urlObject.searchParams.delete(param);
    }
    const newURL = urlObject.origin + urlObject.pathname + urlObject.search;
  
    return newURL?.toString();
  }

  const UrlBasedNavHandler=()=>{
    sessionStorage.removeItem("loginType");
    const queryUrl = window.location.search;
    const urlParams = new URLSearchParams(queryUrl);

    const loginQuery = urlParams.get("login");
    const templateQuery = urlParams.get("template_id");
    const emailIdQuery = urlParams.get("email_id");
    const emailQuery = urlParams.get("email");
    const userIdQuery = urlParams.get("userId");

    if(userIdQuery){
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.delete('email_id');
      url.searchParams.delete('email');
      url.searchParams.delete('login');
      if(emailQuery || emailIdQuery || loginQuery)window.location.href = url.toString()
    }
    if(templateQuery)sessionStorage.setItem('selected_template_id',templateQuery);
    if(loginQuery && !userIdQuery){
      if(router.pathname !== '/dashboard/editor'){
        sessionStorage.setItem('nextPath',removeParametersFromURL(window.location.href,['email','email_id','login','userId','code']));
        window.location.href = `${window.location.origin}/dashboard/editor?email_id=${emailIdQuery || emailQuery}&login=${loginQuery}`;
      }
    }
  };
  
useEffectOnce(()=>{

   //url navigationHandler
   UrlBasedNavHandler();
   // load Branch handler
   branchCouponData();   
},[])


  const client = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 1, // 1 minute
        networkMode: "always",
        enabled:true,
        retry: 1,
      },
    },
  });
  
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        {/* <script type="text/javascript">
        {`!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");

        Moengage = moe({
        app_id:"${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}", 
        debug_logs: ${process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS}
        swPath: "public/serviceworker.js",
        });
        window.Moengage = Moengage;`}
        </script> */}
        <script dangerouslySetInnerHTML={{__html:`!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage")`}}>
          </script>
          <script dangerouslySetInnerHTML={{__html:`
            Moengage = moe({
              app_id:"${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}", 
              debug_logs: ${process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS},
              swPath: "public/serviceworker.js",
              });
              window.Moengage = Moengage;
              `}}>
          </script>
        {/* <script src="https://cdn.paddle.com/paddle/paddle.js"></script> */}
        <link rel="stylesheet" href="/fonts/index.css" />
        {/* <link rel="preload" href="/fonts/index.css/objectivity-bold.otf" as="font" type="font/otf" crossorigin /> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='anonymous' />
      </Head>
      <QueryClientProvider client={client} >
        <AuthProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <CollapseDrawerProvider>
                  <ThemeProvider>
                    <NotistackProvider>
                      <MotionLazyContainer>
                        <ThemeColorPresets>
                          <RtlLayout>
                            <AuthenticationHandler>
                              {/* <Settings /> */}
                              <ProgressBar />
                              <AuthSection/>
                              <SuccessPopUp />
                              {getLayout(<Component {...pageProps} />)}
                              <MaskLayer/>
                            </AuthenticationHandler>
                          </RtlLayout>
                        </ThemeColorPresets>
                      </MotionLazyContainer>
                    </NotistackProvider>
                  </ThemeProvider>
              </CollapseDrawerProvider>
            </PersistGate>
          </ReduxProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <DevTools />
      </QueryClientProvider>
    </>
  );
}
