import { css } from "@emotion/react";
import { Icon, ICONS } from "../Icon";

export default function PropertyInput({icon, propertyText, value, onChange,...props}) {

    const _onChange = ({target:{value}})=>{
        onChange(value)
    }

    return <div css={css`
        
        border-radius: 4px;
        border: 0.5px solid rgba(238, 238, 238, 0.24);
        background: #F9F9F9;
        display:flex;
        flex:1;
        flex-direction:row;
        align-items:center;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        
        
    `}>
        <div
            css={css`
                display:flex;
                flex-direction:row;
                align-items:center;
                padding: 12px 8px;
                background-color: #EEEEEE;
            `}
        >
            {icon}
            <span css={css`
                margin:0px 4px; 
                color: #262626;
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 170%; /* 23.8px */
                letter-spacing: 2.52px;
                display:flex;
            `}> {propertyText}</span>
        </div>
        <input 
            css={css`
                color: #262626;
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 170%; /* 23.8px */
                letter-spacing: 0.42px;
                background:transparent;
                display:flex;
                border:0px solid transparent;
                flex:1;
                width:83px;
                padding-left:12px;
                &:focus-visible {
                    outline:0px;
                }
                align-items: center;
            `}
            value={value}
            onChange={_onChange}
        />
    </div>
}