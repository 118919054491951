import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import PopUp from "@/modules/ui-elements/PopUp";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import CustomizedButton from "@/modules/ui-elements/CustomizedButton";
import { ICONS } from "@/modules/ui-elements/Icon";
import { firebaseConfig } from "@/configs/firebaseCredential";
import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "@/contexts/JWTContext";
import MobileInput from "@/modules/setting/Profile/MobileInput";
import { get } from "lodash";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { loginTypeEmailAtom, userInputEmailAtom, userInputMobileAtom } from "@/modules/editor-v2/Atoms";
import { useRouter } from "next/router";
import { isRemoveWatermarkClickedAtom, sendToHomepageAtom, showLoginPopupAtom, tempComplementryJobIdAtom, tempComplementryProTouchAtom } from "../../editor-v2/Atoms";
import { nanoid } from "nanoid";
import PremiumSection from "../premiumSection";
import { Icon } from "../../ui-elements/Icon";
import { isDownloadCsvButtonClickedAtom, isLoginInButtonClickedAtom, triggerAutoCsvDownloadWhenLoggedInAtom, triggerAutoPackageSubmissionWhenLoggedInAtom } from "../../marketplaceListing/Atoms";
import {
  setMyProjectRefreshPage
} from "src/redux/reducers/myProject.reducer";

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
firebase.firestore();

const LoginSection = ({ authRef, verifyRef, autoTrigger, setAutoTrigger, handleCreateJobFromTemplate }) => {
  const { login, loginMobile, loginSocialService, logout, refreshUserDetails, user } = useAuth();
  const emailRef = useRef(null);
  const isLoadingRef = useRef(null);
  const Toast = useToast();
  const {query, pathname, push, replace,...data} = useRouter();
  const ctx = useContext(AuthContext);
  const loginTypeEmailValue = useAtomValue(loginTypeEmailAtom);
  const setLoginTypeEmail = useSetAtom(loginTypeEmailAtom);
  const [userInputEmail, setInputEmail] = useAtom(userInputEmailAtom);
  const setInputMobile = useSetAtom(userInputMobileAtom);
  const getTempComplementryJobId = useAtomValue(tempComplementryJobIdAtom);
  const setTempComplementryJobId = useSetAtom(tempComplementryJobIdAtom);
  const setTempComplementryProTouch = useSetAtom(tempComplementryProTouchAtom);
  const isLoginButtonClicked = useAtomValue(isLoginInButtonClickedAtom);
  const [userMobileNo, setUserMobileNo] = useState({});
  const [errorEmail,setErrorEmail] = useState(false);
  const [errorMobile,setErrorMobile] = useState(false);
  const { couponData } = useSelector((state) => state.appRedirect);
  const dispatch = useDispatch();
  const {entireStrapiData:{EnterYourEmailScreenOnWebapp,EnterYourMobileScreenOnWebapp}} = useSelector((state)=> state.strapi);
  const strapiDataEmail = EnterYourEmailScreenOnWebapp?.EnterYourEmailScreenOnWebapp || {};
  const strapiDataMobile = EnterYourMobileScreenOnWebapp?.EnterYourMobileScreenOnWebapp || {};
  const setIsRemoveWatermarkClicked = useSetAtom(isRemoveWatermarkClickedAtom);
  const setIsLoginButtonClicked = useSetAtom(isLoginInButtonClickedAtom);
  const setTriggerForAutoCsvDownload = useSetAtom(triggerAutoCsvDownloadWhenLoggedInAtom)
  const setTriggerForAutoPackageSubmission = useSetAtom(triggerAutoPackageSubmissionWhenLoggedInAtom)
  const setCsvButtonClick = useSetAtom(isDownloadCsvButtonClickedAtom)
  const sendToHomepage = useAtomValue(sendToHomepageAtom)
  const setSendToHomepage = useSetAtom(sendToHomepageAtom)

  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEmailSubmit = async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
    try {
      setErrorEmail(false);
      setErrorMobile(false);
      ctx.isSocialLoginHandler(true);
      await login(email);
      setInputEmail(email);
      Toast.success("OTP send Successfully");
      authRef.current?.close();
      verifyRef.current?.open();
    } catch (error) {
      setErrorEmail(true);
      if(error?.response?.data?.message === "Unauthorized Access!"){
        await logout();
        handleEmailSubmit(email);
      }else{
        Toast.error(error?.response?.data?.message ?? "Error");
      }
    } finally {
      isLoadingRef.current = null;
      ctx.isSocialLoginHandler(false);
    }
}else{
    Toast.error('Please enter a valid email');
}
  };

  const autoEmailLogin = async(email) => {
    try{
    if(isLoadingRef.current)return;
    setErrorEmail(false);
    setErrorMobile(false);
    ctx.isSocialLoginHandler(true);
    const result = await refreshUserDetails()
    if(!email) return; 
    if(email === result?.user?.email) {
      authRef.current?.close();
      setAutoTrigger(false);
      const {email,email_id,login,...queryParams} = query;
      const newUrl = {
        pathname: pathname,
        query:queryParams,
      };
      replace(newUrl);
      const template_id = sessionStorage.getItem('selected_template_id');
      sessionStorage.removeItem('selected_template_id');
      if(template_id){
        await handleCreateJobFromTemplate(template_id);
        sessionStorage.removeItem('nextPath');
      }else if(sessionStorage.getItem('nextPath')){
        window.location.href = sessionStorage.getItem('nextPath');
      }
      ctx.isSocialLoginHandler(false);
      sessionStorage.removeItem('nextPath');
      sessionStorage.removeItem('login_flow');
      return;
    }
    
    isLoadingRef.current=true;
    if(user?.user?.login_type !== 'Guest') await logout(); // This will convert user to guest
  
    ctx.isSocialLoginHandler(false);
    if(!localStorage.getItem('accessToken')) {
  
      ctx.isSocialLoginHandler(false);
      throw new Error("Guest login Failed!");
      }
  
    await handleEmailSubmit(email);
  
    isLoadingRef.current = false;
  
  }catch(error){
    isLoadingRef.current = false;
    setErrorEmail(true);
    console.error("error in user login",error?.message);
  }finally{
    setAutoTrigger(false);
    const {email,email_id,login,...queryParams} = query;
      const newUrl = {
        pathname: pathname,
        query: {...queryParams},
      };
      replace(newUrl);
  }
  };

  const handleMobileSubmit = async (userData) => {
    if (!userData?.mobile || !userData?.countryCode) return;
    let payload = {};
    payload.phone = get(userData, "mobile");
    payload.countryCode = get(userData, "countryCode");
    try {
      setErrorMobile(false);
      setErrorEmail(false);
      ctx.isSocialLoginHandler(true);
      if (userData.isValid) {
        await loginMobile(payload);
        setInputMobile(userData);
        authRef.current?.close();
        verifyRef.current?.open();
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message ?? "Error");
      setErrorMobile(true);
      if(error?.response?.data?.message === "Unauthorized Access!")logout();
    }finally {
      ctx.isSocialLoginHandler(false);
    }
  };

  const onSignInWithGoogle = (e) => {
    ctx.isSocialLoginHandler(true);
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let userUniqueId = localStorage.getItem('userUniqueId');
        if(!userUniqueId) {
          userUniqueId = nanoid();
          localStorage.setItem('userUniqueId', userUniqueId);    
        }
        let userDetail = {
          email: result.additionalUserInfo.profile.email,
          firebaseId: result.credential.idToken,
          mode: "web",
          loginType: "Google",
          build: 2,
          version: 1,
          long: 0,
          lat: 0,
          language: window.localStorage.getItem("Selected_Language") || "en",
          osType: "windows",
          deviceId: userUniqueId,
          couponCode: couponData?.couponCode ?? "",
          jobIdForProTouch:getTempComplementryJobId || '',
          source: "organic"
        };

        await loginSocialService(userDetail);
        ctx.isSocialLoginHandler(false);
        authRef.current?.close();
        dispatch(setMyProjectRefreshPage(true));
        if(!isLoginButtonClicked){
          setTriggerForAutoCsvDownload(true)
          setTriggerForAutoPackageSubmission(true)
        }
        if(sessionStorage.getItem('bulkUploadProTouchActivate')){
          sessionStorage.setItem('bulkUploadProTouchActive',true);
        }
      })
      .catch((error) => {
        Toast.error(
          error?.response?.data?.message ?? "Login using Google failed!"
        );
        ctx.isSocialLoginHandler(false);
        setTriggerForAutoCsvDownload(false)
        setTriggerForAutoPackageSubmission(false)
        setCsvButtonClick(false)
        console.error("error in google social login", error);
      });
  };

  const onSignInWithApple = (e) => {
    ctx.isSocialLoginHandler(true);
    let provider = new firebase.auth.OAuthProvider("apple.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let userUniqueId = localStorage.getItem('userUniqueId');
        if(!userUniqueId) {
          userUniqueId = nanoid();
          localStorage.setItem('userUniqueId', userUniqueId);    
        }
        let userDetail = {
          email: result.additionalUserInfo.profile.email,
          firebaseId: result.credential.idToken,
          mode: "web",
          loginType: "Apple",
          build: 2,
          version: 1,
          long: 0,
          lat: 0,
          language: "en",
          osType: window.navigator.userAgentData.platform,
          deviceId: userUniqueId,
          couponCode: couponData?.couponCode ?? "",
          jobIdForProTouch:getTempComplementryJobId || '',
          source: "organic"
        };
        await loginSocialService(userDetail);
        ctx.isSocialLoginHandler(false);
        authRef.current?.close();
        dispatch(setMyProjectRefreshPage(true));
        if(!isLoginButtonClicked){
          setTriggerForAutoCsvDownload(true)
          setTriggerForAutoPackageSubmission(true)
        }
        if(sessionStorage.getItem('bulkUploadProTouchActivate')){
          sessionStorage.setItem('bulkUploadProTouchActive',true);
        }
      })
      .catch((error) => {
        Toast.error(
          error?.response?.data?.message ?? "Login using Apple failed!"
        );
        ctx.isSocialLoginHandler(false);
        setTriggerForAutoCsvDownload(false)
        setTriggerForAutoPackageSubmission(false)
        setCsvButtonClick(false)
        console.error("error in apple social login", error);
      });
  };

  const onSignInWithMicrosoft = async (e) => {
    ctx.isSocialLoginHandler(true);
    let response = await ctx.microsoftLoginRedirect();
    if (!response || response.trim() === "") {
      Toast.error("Login redirect to Microsoft failed");
    }
    let newTab;
    if (response) {
      newTab = window.open(response, "_blank");
    }
    if (newTab) {
      let closeFlag = false;
      while (!newTab.closed) {
        try {
          await new Promise((r) => setTimeout(r, 500));
          let url = undefined;
          try {
            url = newTab.location.href;
          } catch (e) {}
          
          if (url?.search("dashboard/home") > -1) {
            closeFlag = true;
            let code = undefined;
            console.log("ms url",url)
            if (url.includes("code=")) {
              code = url.split("code=")[1].split("&")[0];
            }
            newTab.close();
            if (code) {
              let userUniqueId = localStorage.getItem('userUniqueId');
              if(!userUniqueId) {
                userUniqueId = nanoid();
                localStorage.setItem('userUniqueId', userUniqueId);    
              }
              let userDetail = {
                code,
                mode: "web",
                loginType: "microsoft",
                build: 2,
                version: 1,
                long: 0,
                lat: 0,
                language: "en",
                osType: "windows",
                deviceId: userUniqueId,
                couponCode: couponData?.couponCode ?? "",
                jobIdForProTouch:getTempComplementryJobId || '',
                source: "organic"
              };
              await ctx.loginSocialService(userDetail);
              authRef.current?.close();
              dispatch(setMyProjectRefreshPage(true));
              if(!isLoginButtonClicked){
                setTriggerForAutoCsvDownload(true)
                setTriggerForAutoPackageSubmission(true)
              }
              if(sessionStorage.getItem('bulkUploadProTouchActivate')){
                sessionStorage.setItem('bulkUploadProTouchActive',true);
              }
            } else {
              Toast.error("Login using Microsoft failed!");
            }
          }
        } catch (e) {
            Toast.error(
                e?.response?.data?.message || "Login using Microsoft failed!"
            );
            setTriggerForAutoCsvDownload(false)
            setTriggerForAutoPackageSubmission(false)
            setCsvButtonClick(false)
            console.error(e);
        }
      }
      if (!closeFlag) {
        Toast.error("You have closed the microsoft login tab");
      }
    }
    ctx.isSocialLoginHandler(false);
  };

  const OpenTermAndService = () => {
    window.open(`${EnterYourEmailScreenOnWebapp?.EnterYourEmailScreenOnWebapp?.termsAndConditions?.termsOfServiceURL}`, '_blank', 'noopener,noreferrer');
  };

  const OpenPrivacyPolicy = () => {
    window.open(`${EnterYourEmailScreenOnWebapp?.EnterYourEmailScreenOnWebapp?.termsAndConditions?.privacyPolicyURL}`, '_blank', 'noopener,noreferrer');
  };

  const onCloseHandler = () => {
    setErrorMobile(false);
    setErrorEmail(false);
    sessionStorage.removeItem('login_flow');
  };
  
  useEffect(()=>{
    if(localStorage.getItem("magicQrInfo"))return;
    const userEmail = query?.email || query?.email_id;
    if(userEmail && autoTrigger){
      if(isLoadingRef.current)return;
      autoEmailLogin(userEmail);
    }
  },[query,autoTrigger]);

  const onPopUpBackDropAndCloseButtonClick = () => {
    setIsRemoveWatermarkClicked(false)
    setTempComplementryJobId('');
    setTempComplementryProTouch(false);
    setCsvButtonClick(false)
    setIsLoginButtonClicked(false)
    sendToHomepage && push("/dashboard/homePage")
    setSendToHomepage(false);
  }

  return (
    <PopUp 
      ref={authRef} 
      width={ Math.max(Math.min(screenSize.width - 300, 1040), 430)} 
      borderRadius={"16px"}
      styleBody={{ padding: "0" }} 
      styleClose={styleClose} 
      onCloseHandler={onCloseHandler} 
      onBackdropClick={onPopUpBackDropAndCloseButtonClick} 
      onCloseButtonClick={onPopUpBackDropAndCloseButtonClick}
      disableBackdropClick={sendToHomepage}
    >
      <div css={styles.container}>
        {screenSize.width > 950 && <PremiumSection/>}
        <div css={styles.loginContainer}>
          <div css={styles.innerContainer}>
          <p css={styles.unlockPremium}>
            {strapiDataEmail?.textHeading || "Login"}
            </p>
          {loginTypeEmailValue ? (
            <form css={styles.form} onSubmit={(e)=>e.preventDefault()}>
              <label for="email" css={styles.inputTitle}>
                {strapiDataEmail?.textAboveTextbox || "Email"}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                ref={emailRef}
                defaultValue={userInputEmail}
                css={styles.inputEmail}
                placeholder={strapiDataEmail?.textPlaceholder || "email@account.com"}
                required
                onKeyDown={(e)=>{
                  if(e.key === "Enter")handleEmailSubmit(emailRef.current?.value)
                }}
              />
              {errorEmail ?
              <span css={[styles.subtitleText,css`color:red;`]}>
                {strapiDataEmail?.textEmailValidationError || 'Please enter a valid email ID'}
              </span>
              :
              <span css={styles.subtitleText}>
                {strapiDataEmail?.textUnderTextbox || 'We’ll send an OTP to confirm your email'}
              </span>}
              <CustomizedButton
                type="pink_with_border"
                btnText={strapiDataEmail.textButton || 'Send OTP'}
                btnStyle={styles.otpButton}
                disabled={false}
                isActive={true}
                onClick={()=>handleEmailSubmit(emailRef.current?.value)}
              />
            </form>
          ) : (
            <form css={styles.form} onSubmit={(e)=>e.preventDefault()} >
              <label for="email" css={styles.inputTitle}>
                {strapiDataMobile?.textAboveTextbox || "Mobile Number"}
              </label>
              <MobileInput
                mobile={userMobileNo.mobile}
                countryCode={userMobileNo.countryCode}
                setMobile={setUserMobileNo}
                textPlaceholder={strapiDataMobile?.textPlaceholder || "XXXXXXXXXX"}
              />
              {errorMobile ?
              <span css={[styles.subtitleText,css`color:red;`]}>
                {strapiDataMobile?.textEmailValidationError || "Please enter a valid mobile number"}
              </span>
              : 
              <span css={styles.subtitleText}>
                {strapiDataMobile?.textUnderTextbox || "We’ll send an OTP to confirm your number"}
              </span>
              }
              <CustomizedButton
                type="pink_with_border"
                btnText={strapiDataMobile.textButton || 'Send OTP'}
                btnStyle={styles.otpButton}
                disabled={false}
                isActive={true}
                onClick={() => handleMobileSubmit(userMobileNo)}
              />
            </form>
          )}
          <div css={styles.subtitleText3}>{strapiDataEmail?.textUnderButton ||"Or Sign in with"}</div>
            <div css={styles.btnContainer}>
              <div style={styles.btnInnerContainer}>{!loginTypeEmailValue ? (
              <CustomizedButton
                type="grey"
                btnText={strapiDataMobile?.EmailLoginCTA  || "Email"}
                startIcon={strapiDataMobile?.socialButtons?.find(obj=>obj?.Order===1)?.iconURL || ICONS.emailDark}
                btnStyle={styles.buttonStyle}
                startIconStyle={styles.specialIconStyle}
                disabled={false}
                isActive={true}
                onClick={() => setLoginTypeEmail(true)}
              />
            ) : (
              <CustomizedButton
                type="grey"
                btnText={strapiDataEmail?.PhoneLoginCTA || "Phone"}
                startIcon={strapiDataEmail?.socialButtons?.find(obj=>obj?.Order===3)?.iconURL ||ICONS.phoneDark}
                btnStyle={styles.buttonStyle}
                startIconStyle={styles.specialIconStyle}
                disabled={false}
                isActive={true}
                onClick={() => setLoginTypeEmail(false)}
              />
            )}
            <CustomizedButton
              type="grey"
              btnText={strapiDataMobile?.AppleLoginCTA || "Apple"}
              startIcon={strapiDataMobile?.socialButtons?.find(obj=>obj?.Order===3)?.iconURL || ICONS.appleDark}
              btnStyle={styles.buttonStyle}
              startIconStyle={styles.specialIconStyle}
              disabled={false}
              isActive={true}
              onClick={onSignInWithApple}
            />
            </div>
            
            <div style={styles.btnInnerContainer}>
            <CustomizedButton
              type="grey"
              btnText={strapiDataMobile?.GoogleLoginCTA || "Google"}
              startIcon={strapiDataMobile?.socialButtons?.find(obj=>obj?.Order===2)?.iconURL || ICONS.googleDark}
              btnStyle={styles.buttonStyle}
              startIconStyle={styles.specialIconStyle}
              disabled={false}
              isActive={true}
              onClick={onSignInWithGoogle}
            />  
            <CustomizedButton
              type="grey"
              btnText={strapiDataMobile?.MicrosoftLoginCTA || "Microsoft"}
              startIcon={strapiDataMobile?.socialButtons?.find(obj=>obj?.Order===4)?.iconURL || ICONS.microsoftColorLogo}
              btnStyle={styles.buttonStyle}
              startIconStyle={styles.specialIconStyle}
              disabled={false}
              isActive={true}
              onClick={onSignInWithMicrosoft}
            /></div>
            
          </div>
          </div>
          <div css={styles.termAndConditionBox}>
               <p css={styles.subtitleText2}>
                {strapiDataEmail?.termsAndConditions?.termsAndConditionsBeginText || "By signing up, I agree to DoMyShoot"}{" "}
                <span css={styles.subtitleTextBold}>
                <span onClick={OpenTermAndService}>{strapiDataEmail?.termsAndConditions?.termsOfServiceText || "Terms of Service"}</span> {" "}
                {strapiDataEmail?.termsAndConditions?.AndText|| "&"} {" "}
                <span onClick={OpenPrivacyPolicy}>{strapiDataEmail?.termsAndConditions?.privacyPolicyText || "Privacy Policy"}</span>
                </span>
              </p>
          </div>
        </div>
      </div>
    </PopUp>
  );
};
const styleClose={
  borderRadius: "999px", 
  padding: "8px", 
  background: "white", 
  right: "-45px", 
  top:"-2px"
}
const styles = {
  container: css`
    display: flex;
    flex-direction: row;
  `,
  heading: css`
    margin-bottom: 17px;
    color: #262626;
    font-family: Objectivity;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    box-shadow: 0px 1px 24px 0px rgba(38, 38, 38, 0.05);
    height: 56px;
    padding: 16px 20px 20px 20px;
  `,
  loginContainer: css `
    font-family: Objectivity;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    flex: 1;
    min-width:436px;
  `,
  innerContainer:css`
    padding:72px 72px 0px 72px;
  `,
  unlockPremium: css`
    font-family: Objectivity;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(38, 38, 38, 1);
  `,
  termAndConditionBox:css`
    border-top: 1px solid rgba(238, 238, 238, 1);
    padding:0px 72px;
    margin-top:42px;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:24px;
  `,
  inputEmail: css`
    width: 100%;
    height: 54px;
    border-radius: 4px;
    border: 1px solid #262626;
    padding: 16px 0px 16px 16px;
    background: #fff;
    ::placeholder {
      color: rgba(38, 38, 38, 0.3);
      font-family: "Objectivity";
      font-size: 14px;
    }
  `,
  inputTitle: css`
    color: rgba(38, 38, 38, 1);
    font-family: Objectivity;
    font-size: 14px;
    font-weight:600;
  `,
  subtitleText: css`
    color:rgba(38, 38, 38, 1);
    font-family: "Objectivity";
    font-size: 12px;
    padding: 2px 0px 8px;
  `,
  subtitleText2: css`
    color: #262626;
    font-family: Objectivity;
    font-size: 12px;
    padding: 16px 0px;
  `,
  subtitleTextBold: css`
    color: #262626;
    font-family: Objectivity;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
  `,
  otpButton: {
    fontWeight: 400,
    paddingTop: "16px",
    paddingBottom: "16px",
    borderRadius: "4px",
    border:'none'
  },
  subtitleText3: css`
    color: rgba(38, 38, 38, 0.6);
    width: 100%;
    text-align: center;
    font-family: Objectivity;
    font-size: 12px;
    padding: 24px 0px;
    margin: 20px 0px 20px 0px;
  `,
  btnContainer: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
  `,
  buttonStyle: {
    fontWeight: 500,
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: "4px",
    position: "relative",
    marginBottom: "5px",
  },
  specialIconStyle: {
    position: "absolute",
    left: "14px",
  },
  btnInnerContainer: {
    display: "flex", 
    flex: "1", 
    flexDirection: "column"
  }
};
export default LoginSection;
