import * as React from "react";
const SVGComponent = ({
  src0 = "",
  src1 = "",
  src2 = "",
  src3 = "",
  src4 = "",
  src5 = "",
  src6 = "",
}) => (
  <svg
    height={386}
    viewBox="0 0 440 386"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ position: "absolute", bottom: 0, width: "80%" }}
  >
    <g clipPath="url(#clip0_1_174)">
      <rect width={440} height={860} rx={16} fill="#A94ED4" />
      <rect
        width={124}
        height={172}
        transform="translate(24 48)"
        fill="white"
      />
      <rect
        width={124}
        height={172}
        transform="translate(24 48)"
        fill="url(#pattern0)"
      />
      <rect
        width={124}
        height={172}
        transform="translate(24 230)"
        fill="white"
      />
      <rect
        width={124}
        height={172}
        transform="translate(24 230)"
        fill="url(#pattern1)"
      />
      <rect
        width={124}
        height={124}
        transform="translate(158 24)"
        fill="white"
      />
      <rect
        width={124}
        height={124}
        transform="translate(158 24)"
        fill="url(#pattern2)"
      />
      <rect
        width={124}
        height={124}
        transform="translate(158 158)"
        fill="white"
      />
      <rect
        width={92.0606}
        height={92.0606}
        transform="translate(173.97 173.97)"
        fill="url(#pattern3)"
      />
      <rect
        width={124}
        height={124}
        transform="translate(158 292)"
        fill="white"
      />
      <rect
        width={124}
        height={124}
        transform="translate(158 292)"
        fill="url(#pattern4)"
      />
      <rect
        width={124}
        height={172}
        transform="translate(292 48)"
        fill="white"
      />
      <rect
        width={124}
        height={172}
        transform="translate(292 48)"
        fill="url(#pattern5)"
      />
      <rect
        width={124}
        height={172}
        transform="translate(292 230)"
        fill="white"
      />
      <rect
        width={124}
        height={172}
        transform="translate(292 230)"
        fill="url(#pattern6)"
      />
      <g filter="url(#filter0_f_1_174)">
        <circle cx={123.112} cy={33.784} r={2.88} fill="white" />
      </g>
      <mask
        id="mask0_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={109}
        y={15}
        width={29}
        height={21}
      >
        <rect
          x={109}
          y={15.352}
          width={28.224}
          height={19.872}
          rx={4.32}
          fill="url(#paint0_linear_1_174)"
        />
      </mask>
      <g mask="url(#mask0_1_174)">
        <g filter="url(#filter1_f_1_174)">
          <ellipse
            cx={109.144}
            cy={25.288}
            rx={9.936}
            ry={2.16}
            transform="rotate(90 109.144 25.288)"
            fill="white"
            fillOpacity={0.5}
          />
        </g>
        <g filter="url(#filter2_f_1_174)">
          <ellipse
            cx={135.64}
            cy={25.288}
            rx={9.936}
            ry={2.16}
            transform="rotate(90 135.64 25.288)"
            fill="white"
            fillOpacity={0.5}
          />
        </g>
        <g filter="url(#filter3_f_1_174)">
          <circle
            cx={113.906}
            cy={20.5459}
            r={6.75382}
            transform="rotate(-30 113.906 20.5459)"
            fill="white"
            fillOpacity={0.4}
          />
        </g>
        <g filter="url(#filter4_f_1_174)">
          <rect
            x={109}
            y={15.352}
            width={28.224}
            height={19.872}
            rx={4.32}
            stroke="white"
            strokeOpacity={0.5}
            strokeWidth={2.88}
          />
        </g>
        <g filter="url(#filter5_f_1_174)">
          <ellipse
            cx={123.256}
            cy={35.224}
            rx={6.192}
            ry={0.863999}
            transform="rotate(-180 123.256 35.224)"
            fill="white"
            fillOpacity={0.7}
          />
        </g>
      </g>
      <circle
        opacity={0.5}
        cx={123.256}
        cy={25.288}
        r={3.6}
        stroke="white"
        strokeWidth={2.304}
      />
      <g filter="url(#filter6_f_1_174)">
        <circle cx={114.04} cy={19.528} r={1.584} fill="white" />
      </g>
      <g opacity={0.4} filter="url(#filter7_f_1_174)">
        <ellipse cx={417.26} cy={39.168} rx={2.7} ry={1.728} fill="white" />
      </g>
      <mask
        id="mask1_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={407}
        y={18}
        width={21}
        height={21}
      >
        <rect
          x={407}
          y={18}
          width={20.736}
          height={20.736}
          rx={4.32}
          fill="#407BED"
        />
      </mask>
      <g mask="url(#mask1_1_174)">
        <path
          d="M411.476 30.3273C412.232 28.6063 414.674 28.6063 415.43 30.3273L418.26 36.7648C418.888 38.1923 417.842 39.794 416.283 39.794H410.623C409.064 39.794 408.018 38.1923 408.646 36.7648L411.476 30.3273Z"
          fill="url(#paint1_linear_1_174)"
        />
        <path
          d="M421.623 31.9252C422.302 30.8591 423.859 30.8591 424.538 31.9252L427.252 36.1854C427.985 37.3357 427.159 38.8418 425.795 38.8418H420.366C419.003 38.8418 418.176 37.3357 418.909 36.1854L421.623 31.9252Z"
          fill="url(#paint2_linear_1_174)"
        />
        <g opacity={0.5} filter="url(#filter8_f_1_174)">
          <rect
            x={427.524}
            y={37.8896}
            width={2.11592}
            height={21.3708}
            transform="rotate(90 427.524 37.8896)"
            fill="white"
          />
        </g>
        <g filter="url(#filter9_f_1_174)">
          <ellipse
            cx={413.348}
            cy={38.5245}
            rx={4.86662}
            ry={0.423184}
            fill="white"
          />
        </g>
        <g filter="url(#filter10_f_1_174)">
          <ellipse
            cx={422.869}
            cy={38.5245}
            rx={3.80866}
            ry={0.423184}
            fill="white"
          />
        </g>
        <g filter="url(#filter11_f_1_174)">
          <ellipse
            cx={421.516}
            cy={22.7916}
            rx={7.02243}
            ry={7.57638}
            transform="rotate(120 421.516 22.7916)"
            fill="white"
            fillOpacity={0.5}
          />
        </g>
        <g filter="url(#filter12_f_1_174)">
          <rect
            x={407}
            y={18}
            width={20.736}
            height={20.736}
            rx={4.32}
            stroke="url(#paint3_linear_1_174)"
            strokeOpacity={0.5}
            strokeWidth={2.16}
          />
        </g>
        <g filter="url(#filter13_f_1_174)">
          <path
            d="M413.082 17.988C411.601 18.3407 407.317 19.4812 407.106 25.6173C405.73 21.3855 407.411 16.7184 413.082 17.988Z"
            fill="white"
            fillOpacity={0.84}
          />
        </g>
        <circle cx={422.335} cy={22.968} r={1.728} fill="white" />
        <g filter="url(#filter14_f_1_174)">
          <circle cx={413.588} cy={30.42} r={0.972001} fill="white" />
        </g>
        <g filter="url(#filter15_f_1_174)">
          <circle cx={423.308} cy={32.364} r={0.972001} fill="white" />
        </g>
      </g>
      <g filter="url(#filter16_f_1_174)">
        <ellipse
          cx={30.0816}
          cy={49.7449}
          rx={5.43878}
          ry={3.34694}
          fill="white"
        />
      </g>
      <mask
        id="mask2_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={10}
        y={10}
        width={41}
        height={41}
      >
        <circle cx={30.5} cy={30.5} r={20.5} fill="#2CE254" />
      </mask>
      <g mask="url(#mask2_1_174)">
        <g
          style={{
            mixBlendMode: "soft-light",
          }}
          opacity={0.25}
        >
          <rect
            x={7.4375}
            y={10}
            width={44.4167}
            height={41}
            fill="url(#pattern7)"
          />
        </g>
        <g filter="url(#filter17_f_1_174)">
          <circle
            cx={30.4997}
            cy={30.0729}
            r={17.0833}
            stroke="url(#paint4_linear_1_174)"
            strokeWidth={6.27551}
          />
        </g>
        <g filter="url(#filter18_f_1_174)">
          <circle
            cx={33.1284}
            cy={30.993}
            r={16.6562}
            transform="rotate(135 33.1284 30.993)"
            stroke="url(#paint5_linear_1_174)"
            strokeWidth={3.34694}
          />
        </g>
        <g filter="url(#filter19_f_1_174)">
          <circle
            cx={30.4998}
            cy={30.073}
            r={19.8837}
            transform="rotate(-165 30.4998 30.073)"
            stroke="url(#paint6_linear_1_174)"
            strokeWidth={8.36735}
          />
        </g>
        <g filter="url(#filter20_f_1_174)">
          <path
            d="M17.6875 22.0082C17.6876 18.4251 21.1308 17.1009 22.5733 16.8694C24.8797 16.4993 25.6446 19.0916 22.0151 19.3694C17.6876 19.786 17.6874 25.7582 17.6875 22.0082Z"
            fill="url(#paint7_linear_1_174)"
          />
        </g>
      </g>
      <g filter="url(#filter21_f_1_174)">
        <ellipse cx={301.754} cy={36.3461} rx={2.1} ry={1.29231} fill="white" />
      </g>
      <mask
        id="mask3_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={294}
        y={21}
        width={16}
        height={16}
      >
        <circle cx={301.915} cy={28.9154} r={7.91538} fill="#2CE254" />
      </mask>
      <g mask="url(#mask3_1_174)">
        <g
          style={{
            mixBlendMode: "soft-light",
          }}
          opacity={0.25}
        >
          <rect
            x={293.011}
            y={21}
            width={17.15}
            height={15.8308}
            fill="url(#pattern8)"
          />
        </g>
        <g filter="url(#filter22_f_1_174)">
          <circle
            cx={301.915}
            cy={28.7505}
            r={6.59615}
            stroke="url(#paint8_linear_1_174)"
            strokeWidth={2.42308}
          />
        </g>
        <g filter="url(#filter23_f_1_174)">
          <circle
            cx={302.93}
            cy={29.1057}
            r={6.43125}
            transform="rotate(135 302.93 29.1057)"
            stroke="url(#paint9_linear_1_174)"
            strokeWidth={1.29231}
          />
        </g>
        <g filter="url(#filter24_f_1_174)">
          <circle
            cx={301.915}
            cy={28.7505}
            r={7.67743}
            transform="rotate(-165 301.915 28.7505)"
            stroke="url(#paint10_linear_1_174)"
            strokeWidth={3.23077}
          />
        </g>
        <g filter="url(#filter25_f_1_174)">
          <path
            d="M296.968 25.6366C296.968 24.2531 298.298 23.7418 298.855 23.6524C299.745 23.5095 300.041 24.5104 298.639 24.6177C296.968 24.7786 296.968 27.0845 296.968 25.6366Z"
            fill="url(#paint11_linear_1_174)"
          />
        </g>
      </g>
      <g filter="url(#filter26_f_1_174)">
        <ellipse
          cx={1.08163}
          cy={225.745}
          rx={5.43878}
          ry={3.34694}
          fill="white"
        />
      </g>
      <mask
        id="mask4_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={-19}
        y={186}
        width={41}
        height={41}
      >
        <circle cx={1.5} cy={206.5} r={20.5} fill="#2CE254" />
      </mask>
      <g mask="url(#mask4_1_174)">
        <g
          style={{
            mixBlendMode: "soft-light",
          }}
          opacity={0.25}
        >
          <rect
            x={-21.5625}
            y={186}
            width={44.4167}
            height={41}
            fill="url(#pattern9)"
          />
        </g>
        <g filter="url(#filter27_f_1_174)">
          <circle
            cx={1.49971}
            cy={206.073}
            r={17.0833}
            stroke="url(#paint12_linear_1_174)"
            strokeWidth={6.27551}
          />
        </g>
        <g filter="url(#filter28_f_1_174)">
          <circle
            cx={4.1284}
            cy={206.993}
            r={16.6562}
            transform="rotate(135 4.1284 206.993)"
            stroke="url(#paint13_linear_1_174)"
            strokeWidth={3.34694}
          />
        </g>
        <g filter="url(#filter29_f_1_174)">
          <circle
            cx={1.49984}
            cy={206.073}
            r={19.8837}
            transform="rotate(-165 1.49984 206.073)"
            stroke="url(#paint14_linear_1_174)"
            strokeWidth={8.36735}
          />
        </g>
      </g>
      <g filter="url(#filter30_f_1_174)">
        <ellipse
          cx={441.082}
          cy={330.745}
          rx={5.43878}
          ry={3.34694}
          fill="white"
        />
      </g>
      <mask
        id="mask5_1_174"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={421}
        y={291}
        width={41}
        height={41}
      >
        <circle cx={441.5} cy={311.5} r={20.5} fill="#2CE254" />
      </mask>
      <g mask="url(#mask5_1_174)">
        <g
          style={{
            mixBlendMode: "soft-light",
          }}
          opacity={0.25}
        >
          <rect
            x={418.438}
            y={291}
            width={44.4167}
            height={41}
            fill="url(#pattern10)"
          />
        </g>
        <g filter="url(#filter31_f_1_174)">
          <circle
            cx={441.5}
            cy={311.073}
            r={17.0833}
            stroke="url(#paint15_linear_1_174)"
            strokeWidth={6.27551}
          />
        </g>
        <g filter="url(#filter32_f_1_174)">
          <circle
            cx={444.128}
            cy={311.993}
            r={16.6562}
            transform="rotate(135 444.128 311.993)"
            stroke="url(#paint16_linear_1_174)"
            strokeWidth={3.34694}
          />
        </g>
        <g filter="url(#filter33_f_1_174)">
          <circle
            cx={441.5}
            cy={311.073}
            r={19.8837}
            transform="rotate(-165 441.5 311.073)"
            stroke="url(#paint17_linear_1_174)"
            strokeWidth={8.36735}
          />
        </g>
        <g filter="url(#filter34_f_1_174)">
          <path
            d="M428.688 303.008C428.688 299.425 432.131 298.101 433.573 297.869C435.88 297.499 436.645 300.092 433.015 300.369C428.688 300.786 428.687 306.758 428.688 303.008Z"
            fill="url(#paint18_linear_1_174)"
          />
        </g>
      </g>
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image0_1_174"
          transform="matrix(0.003125 0 0 0.00225291 0 -0.0406977)"
        />
      </pattern>
      <pattern
        id="pattern1"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image1_1_174"
          transform="matrix(0.003125 0 0 0.00225291 0 -0.0406977)"
        />
      </pattern>
      <pattern
        id="pattern2"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image2_1_174"
          transform="translate(-0.302676) scale(0.00334448)"
        />
      </pattern>
      <pattern
        id="pattern3"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image3_1_174"
          transform="translate(0 -0.201754) scale(0.00292398)"
        />
      </pattern>
      <pattern
        id="pattern4"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image4_1_174"
          transform="translate(-0.189655) scale(0.00287356)"
        />
      </pattern>
      <pattern
        id="pattern5"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image5_1_174"
          transform="matrix(0.00288978 0 0 0.00208333 -0.0548387 0)"
        />
      </pattern>
      <pattern
        id="pattern6"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#image6_1_174"
          transform="matrix(0.00288978 0 0 0.00208333 -0.0533938 0)"
        />
      </pattern>
      <filter
        id="filter0_f_1_174"
        x={117.352}
        y={28.024}
        width={11.52}
        height={11.52}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.44}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter1_f_1_174"
        x={104.104}
        y={12.472}
        width={10.08}
        height={25.632}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.44}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter2_f_1_174"
        x={129.16}
        y={11.032}
        width={12.96}
        height={28.512}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.16}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter3_f_1_174"
        x={102.831}
        y={9.47091}
        width={22.1499}
        height={22.1499}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.16}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter4_f_1_174"
        x={104.68}
        y={11.032}
        width={36.864}
        height={28.512}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.44}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter5_f_1_174"
        x={115.912}
        y={33.208}
        width={14.688}
        height={4.032}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.576}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter6_f_1_174"
        x={110.728}
        y={16.216}
        width={6.624}
        height={6.624}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.864}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter7_f_1_174"
        x={411.968}
        y={34.848}
        width={10.584}
        height={8.64001}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.296}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter8_f_1_174"
        x={402.913}
        y={34.6496}
        width={27.8508}
        height={8.59593}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.62}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter9_f_1_174"
        x={407.186}
        y={36.8053}
        width={12.3252}
        height={3.43837}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.648001}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter10_f_1_174"
        x={417.765}
        y={36.8053}
        width={10.2093}
        height={3.43837}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.648001}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter11_f_1_174"
        x={406.296}
        y={7.84964}
        width={30.4385}
        height={29.8838}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={3.888}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter12_f_1_174"
        x={403.76}
        y={14.76}
        width={27.216}
        height={27.216}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={1.08}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter13_f_1_174"
        x={405.356}
        y={16.4798}
        width={9.02249}
        height={10.4335}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.648001}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter14_f_1_174"
        x={410.888}
        y={27.72}
        width={5.40001}
        height={5.40001}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.864001}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter15_f_1_174"
        x={420.608}
        y={29.664}
        width={5.40001}
        height={5.40001}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.864001}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter16_f_1_174"
        x={19.6224}
        y={41.3776}
        width={20.9184}
        height={16.7347}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <pattern
        id="pattern7"
        patternContentUnits="objectBoundingBox"
        width={10.1727}
        height={15.5918}
      >
        <use
          xlinkHref="#image7_1_174"
          transform="scale(0.00941915 0.0102041)"
        />
      </pattern>
      <filter
        id="filter17_f_1_174"
        x={5.25822}
        y={4.83144}
        width={50.483}
        height={50.483}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter18_f_1_174"
        x={13.1252}
        y={10.9898}
        width={40.0064}
        height={40.0064}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.836735}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter19_f_1_174"
        x={2.24281}
        y={1.81593}
        width={56.5141}
        height={56.5141}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.09184}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter20_f_1_174"
        x={17.2691}
        y={16.415}
        width={7.67008}
        height={7.24298}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.209184}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter21_f_1_174"
        x={297.715}
        y={33.1154}
        width={8.07692}
        height={6.46154}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.969231}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <pattern
        id="pattern8"
        patternContentUnits="objectBoundingBox"
        width={10.1727}
        height={15.5918}
      >
        <use
          xlinkHref="#image7_1_174"
          transform="scale(0.00941915 0.0102041)"
        />
      </pattern>
      <filter
        id="filter22_f_1_174"
        x={292.169}
        y={19.0043}
        width={19.4923}
        height={19.4923}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.969231}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter23_f_1_174"
        x={295.207}
        y={21.3822}
        width={15.4471}
        height={15.4471}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.323077}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter24_f_1_174"
        x={291.005}
        y={17.84}
        width={21.821}
        height={21.821}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.807692}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter25_f_1_174"
        x={296.807}
        y={23.4769}
        width={2.96154}
        height={2.79663}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.0807692}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter26_f_1_174"
        x={-9.37755}
        y={217.378}
        width={20.9184}
        height={16.7347}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <pattern
        id="pattern9"
        patternContentUnits="objectBoundingBox"
        width={10.1727}
        height={15.5918}
      >
        <use
          xlinkHref="#image7_1_174"
          transform="scale(0.00941915 0.0102041)"
        />
      </pattern>
      <filter
        id="filter27_f_1_174"
        x={-23.7418}
        y={180.831}
        width={50.483}
        height={50.483}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter28_f_1_174"
        x={-15.8748}
        y={186.99}
        width={40.0064}
        height={40.0064}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.836735}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter29_f_1_174"
        x={-26.7572}
        y={177.816}
        width={56.5141}
        height={56.5141}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.09184}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter30_f_1_174"
        x={430.622}
        y={322.378}
        width={20.9184}
        height={16.7347}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <pattern
        id="pattern10"
        patternContentUnits="objectBoundingBox"
        width={10.1727}
        height={15.5918}
      >
        <use
          xlinkHref="#image7_1_174"
          transform="scale(0.00941915 0.0102041)"
        />
      </pattern>
      <filter
        id="filter31_f_1_174"
        x={416.258}
        y={285.831}
        width={50.483}
        height={50.483}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.5102}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter32_f_1_174"
        x={424.125}
        y={291.99}
        width={40.0064}
        height={40.0064}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.836735}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter33_f_1_174"
        x={413.243}
        y={282.816}
        width={56.5141}
        height={56.5141}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2.09184}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <filter
        id="filter34_f_1_174"
        x={428.269}
        y={297.415}
        width={7.67007}
        height={7.24298}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={0.209184}
          result="effect1_foregroundBlur_1_174"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1_174"
        x1={123.112}
        y1={15.352}
        x2={123.112}
        y2={35.224}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC700" />
        <stop offset={1} stopColor="#FF9900" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_174"
        x1={413.453}
        y1={30.3781}
        x2={413.406}
        y2={39.0531}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.128559} stopColor="white" stopOpacity={0.71} />
        <stop offset={0.614583} stopColor="white" stopOpacity={0.3} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_174"
        x1={423.081}
        y1={33.023}
        x2={423.094}
        y2={38.9476}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.102163} stopColor="white" stopOpacity={0.74} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_174"
        x1={424.351}
        y1={19.058}
        x2={411.549}
        y2={38.207}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0.5} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_174"
        x1={13.4164}
        y1={20.4362}
        x2={29.1856}
        y2={40.1477}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.7} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_174"
        x1={23.907}
        y1={14.0799}
        x2={39.7482}
        y2={44.8732}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.5} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_174"
        x1={28.0262}
        y1={10.731}
        x2={31.8469}
        y2={28.8785}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.84} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_174"
        x1={23.315}
        y1={17.0335}
        x2={18.1121}
        y2={22.8594}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1_174"
        x1={295.319}
        y1={25.0296}
        x2={301.408}
        y2={32.6405}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.7} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1_174"
        x1={299.37}
        y1={22.5753}
        x2={305.486}
        y2={34.4651}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.5} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1_174"
        x1={300.96}
        y1={21.2823}
        x2={302.435}
        y2={28.2893}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.84} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1_174"
        x1={299.141}
        y1={23.7158}
        x2={297.132}
        y2={25.9653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1_174"
        x1={-15.5836}
        y1={196.436}
        x2={0.18561}
        y2={216.148}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.7} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1_174"
        x1={-5.09303}
        y1={190.08}
        x2={10.7482}
        y2={220.873}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.5} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1_174"
        x1={-0.973838}
        y1={186.731}
        x2={2.84685}
        y2={204.879}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.84} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1_174"
        x1={424.416}
        y1={301.436}
        x2={440.186}
        y2={321.148}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.7} />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1_174"
        x1={434.907}
        y1={295.08}
        x2={450.748}
        y2={325.873}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.5} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1_174"
        x1={439.026}
        y1={291.731}
        x2={442.847}
        y2={309.879}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity={0.84} />
        <stop offset={0.332036} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1_174"
        x1={434.315}
        y1={298.034}
        x2={429.112}
        y2={303.859}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <clipPath id="clip0_1_174">
        <rect width={440} height={860} rx={16} fill="white" />
      </clipPath>
      <image id="image0_1_174" width={320} height={480} xlinkHref={src0} />
      <image id="image1_1_174" width={350} height={474} xlinkHref={src1} />
      <image id="image2_1_174" width={480} height={299} xlinkHref={src2} />
      <image id="image3_1_174" width={342} height={480} xlinkHref={src3} />
      <image id="image4_1_174" width={480} height={348} xlinkHref={src4} />
      <image id="image5_1_174" width={384} height={480} xlinkHref={src5} />
      <image id="image6_1_174" width={383} height={475} xlinkHref={src6} />
    </defs>
  </svg>
);
export default SVGComponent;
