import React, { useCallback, useRef, useState } from "react";
import { css } from "@emotion/react";
import PopUp from "@/modules/ui-elements/PopUp";
import { useEffect } from "react";
import { ICONS, Icon } from "@/modules/ui-elements/Icon";
import CustomizedButton from "@/modules/ui-elements/CustomizedButton";
import ShadedText from "@/modules/ui-elements/ShadedText"
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { reopenSuccessProjectPopupAtom, showLoginPopupAtom, showSuccessProjectPopupAtom, tempComplementryJobIdAtom, tempComplementryProTouchAtom, activeProjectAtom, pageStatesAtom, rechargeRedirection, isProFeatureClickedAtom,pagesAtom, segmentEventPayload, jobSuccessScreenDataAtom, zipAndDownloadImagesDataAtom } from "../Atoms";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import {proTouchConfirmCompletedJob, proTouchConfirmJob, updateInstruction, getProTouchRequiredCredits, getJobDetails} from '../services/Jobs';
import useToast from "@/hooks/useToast";
import axiosInstance from "@/utils/axios";
import { completeStrapiData } from "src/strapiData";
import { useQueryClient } from "@tanstack/react-query";
import AddInstruction from "./addInstruction";
import { Button } from "@mui/material";
import FeedbackPop from "../../my-work/MarketPlace/FeedbackPop";
import ThumbsUpJourney from "../../my-work/MarketPlace/ThumbsUp";
import SideDislikePopup from "../../my-work/sideDislikePopup";
import Referal from "../../../layouts/darkdashboard/navbar/referal";
import useAuth from "src/hooks/useAuth";
import { debounce, delay } from "lodash";
import { AuthContext } from "@/contexts/JWTContext";
import useEditorService from "@/hooks/useEditorService";
import {
  setCurrentSelectedTab
} from "src/redux/reducers/myProject.reducer";
import { useMyProjectTabs } from "src/utils/constant";
import { useContext } from "react";
import { TrackWebappEvent } from "../../segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "../../segment-event-tracking/constant";
import useClearBatchEditor from "../../../hooks/useClearBatchEditor";

import ConfirmJobScreen from "./ConfirmJobScreen";
const POPUP_TYPE = {
  RETOUCH: "RETOUCH",
  BEFORE_PROTOUCH: "BEFORE_PROTOUCH",
  NON_PROTOUCH_COMPLETED_JOB: "NON_PROTOUCH_COMPLETED_JOB",
  DOWNLOADED_BEFORE_PROTOUCH: "DOWNLOADED_BEFORE_PROTOUCH",
  GUEST_JOB_LOW_RESOLUTION: "GUEST_JOB_LOW_RESOLUTION",
  LOGGED_IN_JOB_HIGH_RESOLUTION: "LOGGED_IN_JOB_HIGH_RESOLUTION",
  SEND_JOB_FOR_PROTOUCH: "SEND_JOB_FOR_PROTOUCH",
  SEND_PRO_TOUCH: "SEND_PRO_TOUCH"
};

const handleDownload = (url) => {
  if (url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    // link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};


const SuccessPopUp = () => {
  const successPopUpRef = useRef(null);
  const paymentPopUpRef = useRef(null);
  const closeDialogRef = useRef(false);
  const router=useRouter();
  const Toast = useToast();
  const queryClient = useQueryClient();
  const {refreshUserDetails, user} = useAuth();
  const companyName = user?.entity?.entity?.company_name;
  const userDetail = user?.user;
  const [loading,setLoading] = useState(false);
  const [successObject, setSuccessObject] = useState({});
  const [paymentObject, setPaymentObject] = useState({});
  const getSuccessProjectValue = useAtomValue(showSuccessProjectPopupAtom);
  const setSuccessProjectValue = useSetAtom(showSuccessProjectPopupAtom)
  const setRedirection = useSetAtom(rechargeRedirection);;
  const getRedirection = useAtomValue(rechargeRedirection);
  const reopenProPopup = useAtomValue(reopenSuccessProjectPopupAtom);
  const setReopenProPopup = useSetAtom(reopenSuccessProjectPopupAtom);
  const setTempComplementryJobId = useSetAtom(tempComplementryJobIdAtom);
  const setTempComplementryProTouch = useSetAtom(tempComplementryProTouchAtom);
  const setShowLoginPopupAtom = useSetAtom(showLoginPopupAtom);
  const setJobSuccessScreenDataAtom = useSetAtom(jobSuccessScreenDataAtom);
  const pages = useAtomValue(pagesAtom);
  const {entireStrapiData} = useSelector(state=>state.strapi);
  const [isOpen, setOpen]= useState(false);
  const [instructionArray, setInstructionsArray]= useState([]);
  const styles = controlledStyles(successObject?.type);
  const [jobData, setJobData] = useState(null);
  const activeProject = useAtomValue(activeProjectAtom);
  const setProFeatureClicked = useSetAtom(isProFeatureClickedAtom);
  const pagesState = useAtomValue(pageStatesAtom);
  const setPagesState = useSetAtom(pageStatesAtom);
  const getSegmentPayloadAtom = useAtomValue(segmentEventPayload);
  const MY_PROJECT_TABS = useMyProjectTabs();
  const dispatch = useDispatch();
  const ctx = useContext(AuthContext);
  const {disposeCanvas} = useEditorService();
  const {clearAll} = useClearBatchEditor();
  const strapiSuccessText = entireStrapiData?.SendForProTouchBE?.SendForProTouchBE;
  const strapiSuccessText2 = entireStrapiData?.StudioSuccessScreenNew?.StudioSuccessScreenNew;
  const [openFeedback, setOpenFeedBack] = useState(false);
  const [feedbackPop, setFeedbackPop] = useState(false);
  const [openFeedbackChat, setFeedBackChat] = useState(false);
  const [isSideMenu, setSideMenu] = useState(false)
  const [thumbsUp, setThumbsUp] = useState(false)
  const [popUp , setActivePopUp] = useState(false)
  const [share, setShare] = useState(false) 
  const setZipAndDownloadImagesData = useSetAtom(zipAndDownloadImagesDataAtom);
  const RedirectionURLMyWork = `${process.env.NEXT_PUBLIC_DOMAIN}/dashboard/my_projects?tab=IN_PROCESS`
  const RedirectionURLEditor = `${process.env.NEXT_PUBLIC_DOMAIN}/dashboard/editor`
  const RedirectionURLBatchEditor = `${window.location.origin}/dashboard/editor`
  const selected_templates = pagesState?.map(x=>({image_id: x.imageId}))?.length > 0 ? pagesState?.map(x=>({image_id: x.imageId})) : successObject?.selected_templates || [];

  useEffect(()=>{
    if(pagesState){
      setPagesState(pagesState)
    }
  },[pagesState])

  useEffect(() => {
    if (activeProject !== undefined && activeProject !== null) {
      setJobData(activeProject);
    }
  }, [activeProject]);

  const openHandler =()=>{
    setOpen(!isOpen)
  }

  const instructionHandler = (instructions)=>{
    setInstructionsArray(instructions)
  }

  const feedbackHandler = ()=>{
    sessionStorage.setItem('successivePopUpTypeAfterLogging',"feedback")
    successPopUpRef.current?.close();
    user?.user?.login_type === 'Guest'? signupPremiumhandler():setFeedbackPop(true);
  }

  useEffect (()=>{
    if(user?.user?.userType == "DoMyShoot" )
      {
        if(sessionStorage.getItem('successivePopUpTypeAfterLogging')=="feedback")
        {setFeedbackPop(true);
        }
        else if(sessionStorage.getItem('successivePopUpTypeAfterLogging')=="proTouch")
        { 
          successPopUpRef.current?.open();
        }
        sessionStorage.removeItem('successivePopUpTypeAfterLogging')
      }
  },[user?.user?.login_type])

  const signupPremiumhandler = () => {
    setProFeatureClicked(true);
    successPopUpRef.current?.close();
    // if(successObject) sessionStorage.setItem('pending_pro_touch',JSON.stringify(successObject));
    // console.log("feedback success", successObject)
    // const successObject = sessionStorage.getItem(pending_pro_touch)
    if(successObject?.job_id){
      setTempComplementryJobId(successObject?.job_id);
      setTempComplementryProTouch(true);
    }
  };

  const createAccountHandler = () => {
    if(user?.user?.login_type === 'Guest')
        {
          setShowLoginPopupAtom(true);
          setJobSuccessScreenDataAtom({...successObject,type:'LOGGED_IN_JOB_HIGH_RESOLUTION'});
        }
  };

  const getEnhancedRetouching = async() => {
    try {
      setLoading(true);
      if(user?.user?.login_type === 'Guest')
        {
          setShowLoginPopupAtom(true);
          setJobSuccessScreenDataAtom({...successObject,type:'SEND_JOB_FOR_PROTOUCH'});
          sessionStorage.setItem('successivePopUpTypeAfterLogging',"proTouch")
          setTempComplementryJobId(successObject?.job_id);
          setTempComplementryProTouch(true);
          setLoading(false);
          return;
        }
      if (successObject?.job_id) {
        let projectCost = {
          totalCost: successObject?.totalCost,
          templateCost: successObject?.templateCost,
          isComplimentaryShoot: successObject?.isComplimentaryShoot
        };
          projectCost = await getProTouchRequiredCredits(successObject?.job_id, successObject?.selected_templates, successObject?.pre_exist, successObject?.guideline);
        if(successObject?.totalCost > user?.entity?.entity?.credits && !projectCost?.isComplimentaryShoot){
          const savedObjData = {
            ...successObject,
            templateCost: projectCost?.templateCost,
            credit: user?.entity?.entity?.credits,
            totalCost: projectCost?.totalCost,
            selected_templates: successObject?.selected_templates,
            totalImages: selected_templates?.length,
            autoProTouch: successObject?.totalCost ? true : false,
            isComplimentaryShoot: projectCost?.isComplimentaryShoot
        };
        delete savedObjData.images;
        const serializedObject = JSON.stringify(savedObjData);
        sessionStorage.setItem('pending_pro_touch', serializedObject);
        
          // successPopUpRef.current?.close();
          router.push('/dashboard/setting?tab=PAY_PER_USE');
          disposeCanvas();
          closeDialogRef.current = true;
          return;
        }
        const response = await proTouchConfirmJob(successObject?.job_id, successObject?.selected_templates, successObject?.pre_exist, successObject?.guideline);
        await updateInstruction(successObject?.job_id, instructionArray);
        setInstructionsArray([]);
        await refreshUserDetails();
        const segmentObjectData = {
          ...getSegmentPayloadAtom,
          job_id:successObject?.job_id,
          subCategoryName: "",
          category_id: "",
          Template_1:selected_templates || "",
          images_clicked_count: selected_templates?.length || "",
          jobOrderName: successObject?.projectName || "",
          credit_used: successObject?.totalCost,
        }
        if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._send_pro_touch}`,segmentObjectData);
        setSuccessObject({...successObject,type:'SEND_JOB_FOR_PROTOUCH',deadlineDate:response?.deadlineDate});
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      Toast.error("Please try again");
    }
};


const sendRetouchingAfterRecharge = async(jobData,userData) => {
  try {
    setLoading(true);
    if (jobData?.job_id) {
      let projectCost = {
        totalCost: jobData?.totalCost,
        templateCost: jobData?.templateCost,
        isComplimentaryShoot: jobData?.isComplimentaryShoot
      };
        projectCost = await getProTouchRequiredCredits(jobData?.job_id, jobData?.selected_templates, jobData?.pre_exist, jobData?.guideline);
      if(jobData?.totalCost > userData?.entity?.entity?.credits && !projectCost?.isComplimentaryShoot){
        const savedObjData = {
          ...jobData,
          templateCost: projectCost?.templateCost,
          credit: user?.entity?.entity?.credits,
          totalCost: projectCost?.totalCost,
          autoProTouch: jobData?.totalCost ? true : false,
      };
      delete savedObjData.images;
      const serializedObject = JSON.stringify(savedObjData);
      sessionStorage.setItem('pending_pro_touch', serializedObject);
      closeDialogRef.current = true;
      successPopUpRef.current?.close();
      setLoading(false);
      return;
      }
      const response = await proTouchConfirmJob(jobData?.job_id, jobData?.selected_templates, jobData?.pre_exist, jobData?.guideline);
      await updateInstruction(jobData?.job_id, instructionArray);
      setInstructionsArray([]);
      await refreshUserDetails();
      const segmentObjectData = {
        ...getSegmentPayloadAtom,
        job_id:jobData?.job_id,
        subCategoryName: "",
        category_id: "",
        Template_1:jobData?.selected_templates || "",
        images_clicked_count: jobData?.selected_templates?.length || "",
        jobOrderName: jobData?.projectName || "",
        credit_used: jobData?.totalCost,
      }
      if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._send_pro_touch}`,segmentObjectData);
      setSuccessObject({...jobData,type:'SEND_JOB_FOR_PROTOUCH',deadlineDate:response?.deadlineDate});
      setLoading(false);
    }
  } catch (e) {
    setLoading(false);
    Toast.error("Please try again");
  }
};

  const sendForProTouchHandler = async() => {
    try{
successObject?.type === "NON_PROTOUCH_COMPLETED_JOB" //NON_PROTOUCH_COMPLETED_JOB & DOWNLOADED_BEFORE_PROTOUCH have same purpose but seperated for redirection
  ? (setRedirection(RedirectionURLMyWork))
  : successObject?.isBatchEditor ? setRedirection(RedirectionURLBatchEditor) : setRedirection(RedirectionURLEditor);
  //for multiple jobs in batchEditor    
    if(successObject?.totalCost <= user?.entity?.entity?.credits || user?.user?.firstShoot)
    {
      if(successObject?.isMultipleJobs)
        {
          successObject?.multipleJobDetails.map(async (job)=>{
          const data = (successObject?.type === "NON_PROTOUCH_COMPLETED_JOB" || successObject?.type === "DOWNLOADED_BEFORE_PROTOUCH")
          ? await proTouchConfirmCompletedJob(job?.job_id)
          : await proTouchConfirmJob(job?.job_id, job?.selected_templates, job?.pre_exist, job?.guideline);
          await updateInstruction(job?.job_id, instructionArray)
          
          if(!data?.isPaymentDue){
            setSuccessObject({...successObject,type:'RETOUCH',totalCost:successObject?.totalCost || data?.creditsUsed,deadlineDate:data?.deadlineDate});
            if(RedirectionURLMyWork === getRedirection)
            {
              router.push('/dashboard/my_projects?tab=IN_PROCESS')
              dispatch(setCurrentSelectedTab(MY_PROJECT_TABS[2].value));
            }else{
              disposeCanvas();
              router.push('/dashboard/homePage');
            }
            queryClient.invalidateQueries();
            successPopUpRef.current.open();
          }
          else{
            sessionStorage.setItem('pending_pro_touch',JSON.stringify(successObject));
            Toast.error(data?.isPaymentDue ? "please recharge to pro-touch" : 'Something went wrong');
          }
          ctx.IsScreenLoadingHandler(false);
        })
          setInstructionsArray([]);
          await refreshUserDetails();
          clearAll();
        }
      else
      {
        setLoading(true);
        ctx.IsScreenLoadingHandler(true);
        const data = (successObject?.type === "NON_PROTOUCH_COMPLETED_JOB" || successObject?.type === "DOWNLOADED_BEFORE_PROTOUCH")
        ? await proTouchConfirmCompletedJob(successObject?.job_id)
        : await proTouchConfirmJob(successObject?.job_id, successObject?.selected_templates, successObject?.pre_exist, successObject?.guideline);
        const segmentObjectData = {
          ...getSegmentPayloadAtom,
          job_id:successObject?.job_id,
          subCategoryName: "",
          category_id: "",
          Template_1:successObject?.selected_templates || "",
          images_clicked_count: successObject?.images?.length || "",
          jobOrderName: successObject?.projectName || "",
          credit_used: successObject?.totalCost,
      }
      if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._send_pro_touch}`,segmentObjectData);
      const res= await updateInstruction(data?.job_id, instructionArray)
      setInstructionsArray([]);
      await refreshUserDetails();
      clearAll();
      if(!data?.isPaymentDue){
        setSuccessObject({...successObject,type:'RETOUCH',totalCost:data?.CreditUsed || data?.creditsUsed,deadlineDate:data?.deadlineDate});
        if(RedirectionURLMyWork === getRedirection)
        {
          router.push('/dashboard/my_projects?tab=IN_PROCESS')
          dispatch(setCurrentSelectedTab(MY_PROJECT_TABS[2].value));
        }else{
          disposeCanvas();
          router.push('/dashboard/homePage');
        }
        queryClient.invalidateQueries();
        successPopUpRef.current.open();
      }else{
       sessionStorage.setItem('pending_pro_touch',JSON.stringify(successObject));
       Toast.error(data?.isPaymentDue ? "please recharge to pro-touch" : 'Something went wrong');
      }
      ctx.IsScreenLoadingHandler(false);
      }
    }else{
     
      const savedObjData ={};
      savedObjData.templateCost = successObject?.templateCost;
      savedObjData.credit = user?.entity?.entity?.credits,
      savedObjData.projectName = successObject?.projectName;
      savedObjData.totalCost= successObject?.totalCost;
      savedObjData.type= successObject?.type;
      savedObjData.job_id= successObject?.job_id;
      savedObjData.selected_templates= selected_templates;
      savedObjData.pre_exist= successObject?.pre_exist;
      savedObjData.banner_image = successObject?.banner_image;
      savedObjData.totalImages= selected_templates?.length;
      savedObjData.guideline= successObject?.guideline;
      savedObjData.autoProTouch = successObject?.totalCost ? true : false; 
      sessionStorage.setItem('pending_pro_touch', JSON.stringify(savedObjData));
      successPopUpRef.current?.close();
      disposeCanvas();
      router.push('/dashboard/setting?tab=PAY_PER_USE');
      closeDialogRef.current = true;
     }
     setLoading(false);
     setInstructionsArray([])
    }catch(error){
      setLoading(false);
      console.error('Error', error);
      Toast.error(error.response?.data?.message || 'Something went wrong')
      
    }
  };

  const prevPendingJobJourney = async() => {
    const preData = JSON.parse(sessionStorage.getItem('pending_pro_touch'));

    if(reopenProPopup && Object.keys(preData || {})?.length && (preData?.type === 'BEFORE_PROTOUCH' || preData?.type === 'DOWNLOADED_BEFORE_PROTOUCH' || preData?.type === 'NON_PROTOUCH_COMPLETED_JOB' || preData?.type === 'SEND_PRO_TOUCH') ){
      const userData = await refreshUserDetails();
      setSuccessObject(preData);
      successPopUpRef.current?.open();
      sessionStorage.removeItem('pending_pro_touch');
      setReopenProPopup(false);
      if(preData?.type === 'SEND_PRO_TOUCH') sendRetouchingAfterRecharge(preData,userData);
    }
  };

  const downloadReceiptHandler = async() => {
    const paymentIntentId = localStorage.getItem('paymentIntentId');
    if(paymentIntentId){
      try{
        const {data} = await axiosInstance.get(`/v4/user-invoices/getPaymentStatus/${paymentIntentId}`);
        handleDownload(data?.data);
        localStorage.removeItem('paymentIntentId');
        paymentPopUpRef.current?.close();
      }catch(error){
        console.warn("Something went wrong in payment receipt download",error)
      }
    }
  };

  useEffect(() => {

    if (getSuccessProjectValue) {
      refreshUserDetails();
      setSuccessObject(getSuccessProjectValue);
      setSuccessProjectValue(null);
      setInstructionsArray([])
      setPaymentObject({})
      successPopUpRef.current?.open();
    }
  }, [getSuccessProjectValue]);

  useEffect(()=>{
    prevPendingJobJourney();
  },[reopenProPopup]);

  useEffect(()=>{
    if(router?.query?.credit && router?.query?.success){
      setPaymentObject(router.query || {});
      paymentPopUpRef.current?.open();
      const {success,credit,...queryParams} = router?.query;
      const newUrl = {
        pathname: router?.pathname,
        query: queryParams
      };
      router.replace(newUrl);
      paymentPopUpRef.current?.close();
    }
    else if(sessionStorage.getItem('credit')){
      const creditValue = sessionStorage.getItem('credit');
      const successValue = sessionStorage.getItem('success');
      setPaymentObject({ credit: creditValue, success: successValue, getRedirection: getRedirection });
      const listingKey = sessionStorage.getItem('listing')

      if(listingKey && creditValue && successValue){
        router.push(`/dashboard/marketplace-listing/${listingKey}?redirect=true`)
      }

      sessionStorage.removeItem('credit');
      sessionStorage.removeItem('success');
      if(getRedirection && getRedirection !== RedirectionURLMyWork)
      {
        paymentPopUpRef.current.open();
      }
      sessionStorage.removeItem('getRedirection');
      setReopenProPopup(true)

    }
  },[router?.query]);

  const closeReferal = () => {
    setShare(false);
  };
  const handleThumbsUp = () => {

      if (!popUp) {
        setThumbsUp(true);
        setShare(false);
        setActivePopUp(!popUp);
        TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._Click_Thumbs_Up_Fbk}`,getSegmentPayloadAtom);
      } else {
        setThumbsUp(false);
        setShare(true);
        setActivePopUp(!popUp);
      }
  };

  const handleClose = () => {
        setFeedbackPop(false);
        router.push('/')
      };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const openPopup = urlParams.get('openFeedback');
      if (openPopup === 'true') {
        setFeedbackPop(true);
      }
  }, []);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (window.location.pathname?.includes('/dashboard/setting') && closeDialogRef.current) {
        successPopUpRef.current?.close();
        closeDialogRef.current = false;
        setLoading(false);
      }
    };

    router?.events?.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [window.location.pathname]);

  const handleCloseButtonClick = async()=>{
    if(successObject?.type === POPUP_TYPE.SEND_JOB_FOR_PROTOUCH )
    { 
      await disposeCanvas();
      setSuccessObject({});
      successPopUpRef.current?.close();
      setZipAndDownloadImagesData(null);
      clearAll();
      router.push('/dashboard/homePage');
      setRedirection('')
    }
    else if(successObject?.type === POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION 
      || successObject?.type === POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION
      )
    {
      setSuccessObject({});
      successPopUpRef.current?.close();
      setZipAndDownloadImagesData(null);
    }
  }

const RenderIcon = () => {
    if (successObject?.type === POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION) {
      return <Icon src={ICONS.okGreen} width={32} height={32} style={commonStyle.mb8} />;
    } else if (successObject?.type === POPUP_TYPE.SEND_JOB_FOR_PROTOUCH || successObject?.type === POPUP_TYPE.SEND_PRO_TOUCH) {
      return <Icon src={ICONS.proTouchLogo} width={116} height={44} style={commonStyle.mb8} />;
    } else if (successObject?.type === POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION) {
      return <Icon src={ICONS.okLogoMarigold} width={32} height={32} style={commonStyle.mb8} />;
    }
    return <></>;
};
const RenderShadedText = ({type}) => {
      const color = successObject?.type === POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION ? "ORANGE" : "GREEN";
      const text = successObject?.type === POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION ? strapiSuccessText2?.LowResolutionStudioText || "Low Resolution" : strapiSuccessText2?.HighResolutionStudioText || "High Resolution";
      return type.includes(successObject?.type) ? <ShadedText color={color} text={text} /> : <></>;
};
const ProjectNameText = ({type}) => (
    type.includes(successObject?.type) && (<span css={commonStyle.title}>{successObject?.projectName}</span>)
);
const RetouchMessage = ({ type, text }) => (
    type.includes(successObject?.type) && (
      <span css={[commonStyle.subtitle2, commonStyle.maxwidth272, commonStyle.mb22,commonStyle.txtWhite,commonStyle.mt24]}>
        {text}
      </span>
    )
);
const SuccessMessage = ({ type, text }) => (
    type.includes(successObject?.type) && (
      <span css={[commonStyle.subtitle2, commonStyle.maxwidth272, commonStyle.mb32]}>
        {text}
      </span>
    )
);
const CustomButtonSection = ({type,btnCTA}) => (
     type.includes(successObject?.type) && (
      <CustomizedButton
        type={"white_with_border"}
        isActive={true}
        btnText={btnCTA}
        startIcon={ICONS.bagDark}
        startIconHeight={24}
        startIconWidth={24}
        btnStyle={commonStyle.btnStyle}
        onClick={()=>{}}
        isLoading={loading}
      />
    )
);
const HighResolutionSection = ({type,text,btnCTA}) => (
    type.includes(successObject?.type) &&
      <div css={[commonStyle.hdCreateContainer, commonStyle.mt48]}>
        <Icon src={ICONS.hdLogo} width={40} height={40} style={commonStyle.mb8} />
        <div css={[commonStyle.gradPinkText]}>{text}</div>
        <CustomizedButton
          type={"pink_with_border"}
          isActive={true}
          btnText={btnCTA}
          btnStyle={commonStyle.btnStyle}
          onClick={createAccountHandler}
          isLoading={loading}
        />
      </div>
);
const ProfessionalLoggedInSection = ({type,workCTA,recievedCTA,balCreditCTA}) => (
    type.includes(successObject?.type) && (
      <>
        <span css={[commonStyle.subtitle2]}>
          {workCTA}
        </span>
        <span css={[commonStyle.subtitle2, commonStyle.maxwidth238, commonStyle.mt16]}>
          {recievedCTA}
          <strong>{successObject?.deadlineDate}</strong>
        </span>
        <span css={commonStyle.divider(40)}></span>
        <div css={[commonStyle.creditTotal, commonStyle.imageTotal, commonStyle.gap8, commonStyle.violetColor, commonStyle.mb48]}>
          <span>{balCreditCTA} </span>
          <strong>{user?.entity?.entity?.credits}</strong>
        </div>
      </>
    ) 
  );
  const SendProTouchVideoSection = ({type,src}) => (
    type.includes(successObject?.type) && (
      <div style={{ width: "436px", height: "180px", marginBottom: "24px", background: "#000000" }}>
        <video controls width="100%" height="100%" autoPlay={true}>
          <source
            src={src}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    )
  );
  const SendProTouchSummarySection = ({type,headerCTA,creditCTA,freeCTA,validOnlyCTA,btnCTA}) => (
    type.includes(successObject.type) && (
      <div style={{ width: "100%", padding: "16px" }}>
        <div css={commonStyle.proTouchSummary}>
          <h3 css={[commonStyle.flexCenter, commonStyle.borderBtmGray]}>{headerCTA}</h3>
          <box css={commonStyle.flexCenter}>
            <span>{creditCTA}</span>
            <strong>
              <span css={successObject?.isComplimentaryShoot && commonStyle.strikeThrough}>{(+successObject?.totalCost).toLocaleString("en-US",{
                                    minimumIntegerDigits:2,
                                    useGrouping: false
                                })}</span> {successObject?.isComplimentaryShoot && freeCTA}
            </strong>
          </box>
          {successObject?.isComplimentaryShoot && <ShadedText startIcon={ICONS.giftVoilet} color={'VOILET'} text={validOnlyCTA} />}
        </div>
        <CustomizedButton
            type={"grey"}
            isActive={true}
            btnStyle={commonStyle.instructionButton}
            btnText={instructionArray.length > 0 ? (instructionArray?.[instructionArray.length - 1].slice(0, 25)) : entireStrapiData?.SendForProTouchBE?.SendForProTouchBE?.AddInstructionText || "Add instructions"}
            onClick={openHandler}
            endIcon={"/icons/instructionAddition.svg"}
            endIconHeight={24}
            endIconWidth={24}
            disabled={loading}
        />
        <CustomizedButton
          type={"violet_with_border"}
          isActive={true}
          btnText={btnCTA}
          btnStyle={{ width: "100%", marginTop: "16px" }}
          onClick={getEnhancedRetouching}
          isLoading={loading}
          disabled={loading}
        />
      </div>
    )
  );

  return (
    <React.Fragment>
    <PopUp
      ref={successPopUpRef}
      width={styles.containerWidth}
      styleBody={{ padding: "0" }}
      disableBackdropClick={true}
      showCloseButton={true}
      onCloseHandler={handleCloseButtonClick}
    >
        <div css={styles.container}>
        <RenderIcon />
        <RenderShadedText
            type={[POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION, POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION]}
        />
        {successObject?.type === POPUP_TYPE.SEND_PRO_TOUCH &&
        <Icon
            src={ICONS.proFeatureBackground}
            width={468}
            height={380}
            style={{ position: 'absolute', top: 70, left: 16, zIndex: -1 }}
        />}
        <ProjectNameText
            type={[
            POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION,
            POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION,
            POPUP_TYPE.SEND_JOB_FOR_PROTOUCH,
            ]}
        />
        <RetouchMessage
            type={[POPUP_TYPE.SEND_PRO_TOUCH]}
            text={strapiSuccessText2?.ImagesAreDownloadAndRetouched || "Get your images retouched by our expert designers"}
        />
        <SuccessMessage
            type={[POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION, POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION]}
            text={strapiSuccessText2?.ImagesAreDownloadInGallery || "Your images are now available in the DoMyShoot folder in your gallery"}
        />
        <SendProTouchVideoSection 
          type={[POPUP_TYPE.SEND_PRO_TOUCH]}
          src={entireStrapiData?.NewFeaturePricingCont?.NewFeaturePricingCont?.VideoBALink || completeStrapiData?.VideoBALink}
        />
        <SendProTouchSummarySection 
          type={[POPUP_TYPE.SEND_PRO_TOUCH]}
          headerCTA={strapiSuccessText2?.SummaryRetouchingText || "Summary"}
          creditCTA={strapiSuccessText2?.CreditsUsedRetouchingText || "Credits Used:"}
          freeCTA={strapiSuccessText2?.GetFreeImageRetouchingText || "Free"}
          validOnlyCTA={strapiSuccessText2?.ValidOnlyForShoot || "Only for this Project"}
          btnCTA={strapiSuccessText2?.GetEnhancedCTAProTouch || "Send for Pro-Touch"} 
        />
        <HighResolutionSection 
          type={[POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION]}
          text={strapiSuccessText2?.GetHighResolutionImagesText || "To Get High Resolution Images"}
          btnCTA={strapiSuccessText2?.GetEnhancedCTAFreeAccount || "Create a Free Account"}
        />
        <ProfessionalLoggedInSection 
          type={[POPUP_TYPE.SEND_JOB_FOR_PROTOUCH]}
          workCTA={strapiSuccessText2?.OurProfessWorkText || "Professional designers are working on your images"}
          recievedCTA={strapiSuccessText2?.OurProfessTextEmail || `You'll receive them via email by `}
          balCreditCTA={strapiSuccessText2?.BalanceCreditText || "Balance Credits:"}
        />
        {/* <CustomButtonSection
            type={[POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION, POPUP_TYPE.SEND_JOB_FOR_PROTOUCH]}
            btnCTA={strapiSuccessText2?.StudioSuccessListAProductText || "List a Product"}
        /> */}
        </div>


    {successObject?.type === POPUP_TYPE.RETOUCH  && (
      
    ((paymentObject?.success && getRedirection !== RedirectionURLEditor) ? (<div css={commonStyle.paymentSuccess}>
      <Icon src={ICONS.okGreen} width={32} height={32} />
      <br/>
      <span css={commonStyle.headerTxt}>{strapiSuccessText?.PaymentSuccessText || "Payment Successful"}</span>
      <br/>
      <span css={commonStyle.ctaStyle}>{paymentObject?.credit} {strapiSuccessText?.CreditsAddedToYour || "Credits added to your account & project sent for Pro-touch"}</span>
      <span css={commonStyle.divider()}></span>
      <span css={[commonStyle.subtitle3, styles.subtitle3]}>
      {strapiSuccessText?.OurProfessText || "Our professional designers are working on your images. We will deliver them by "}{successObject?.deadlineDate}
      </span>
      <div css={styles.bottomContainer}>
        <div css={[commonStyle.creditHeading, commonStyle.summeryTitle]}>{strapiSuccessText?.CreditStatus || "Credit Status"}</div>
        <div css={commonStyle.imageSubtitle}>
          <div css={commonStyle.creditSubtitle}>
            <span>{strapiSuccessText?.OpeningCreditText || "Opening Credits:"}</span>
            <span>{Number(user?.entity?.entity?.credits)}</span>
          </div>
          <div css={commonStyle.creditSubtitle}>
            <span>{strapiSuccessText?.UsedCreditText || "Used Credits"}</span>
            <span>{successObject?.totalCost}</span>
          </div>
          <div css={[commonStyle.creditTotal, commonStyle.imageTotal]}>
            <span>{strapiSuccessText?.BalanceCreditText || "Balance Credits:"} </span>
            <span>{user?.entity?.entity?.credits - Number(successObject?.totalCost)}</span>
          </div>
        </div>
      </div>
      <CustomizedButton btnText={'Download receipt'} type={'grey'} isActive={true} btnStyle={commonStyle.download} onClick={downloadReceiptHandler} />
    </div>) : <div css={styles.container}>
          <Icon src={ICONS.okGreen} width={32} height={32} />
          <span css={commonStyle.title}>{successObject?.projectName}</span>
          <span css={commonStyle.divider()}></span>
          <span css={[commonStyle.subtitle3, styles.subtitle3]}>
          {strapiSuccessText?.OurProfessText || "Our professional designers are working on your images. We will deliver them by "}{successObject?.deadlineDate}
          </span>
          <div css={styles.bottomContainer}>
            <div css={commonStyle.creditHeading}>{strapiSuccessText?.CreditStatus || "Credit Status"}</div>
            <div css={commonStyle.creditSubtitle}>
              <span>{strapiSuccessText?.OpeningCreditText || "Opening Credits:"}</span>
              <span>{user?.entity?.entity?.credits}</span>
            </div>
            <div css={commonStyle.creditSubtitle}>
              <span>{strapiSuccessText?.UsedCreditText || "Used Credits:"}</span>
              <span>{successObject?.totalCost}</span>
            </div>
            <div css={commonStyle.creditTotal}>
              <span>{strapiSuccessText?.BalanceCreditText || "Balance Credits:"}</span>
              <span>{user?.entity?.entity?.credits - Number(successObject?.totalCost)}</span>
            </div>
          </div>
        </div>)
  )} 

  {(successObject?.type === POPUP_TYPE.BEFORE_PROTOUCH ||successObject?.type === "DOWNLOADED_BEFORE_PROTOUCH" || successObject?.type === POPUP_TYPE.NON_PROTOUCH_COMPLETED_JOB) && 
    <ConfirmJobScreen
        successObject={successObject}
        instructionArray={instructionArray}
        loading={loading}
        sendForProTouchHandler={sendForProTouchHandler}
        styles={styles}
        openHandler={openHandler}
        entireStrapiData={entireStrapiData}
        completeStrapiData={completeStrapiData}
      />}

    </PopUp>
    <PopUp
      ref={paymentPopUpRef}
      width={382}
      styleBody={{ padding: "0" }}
    >
     {paymentObject?.success === 'true' && (paymentObject?.credit > 0) && (paymentObject?.getRedirection != RedirectionURLMyWork) ? (
     
    <div css={commonStyle.paymentSuccess}>
       <span css={commonStyle.headerTxt}>{strapiSuccessText?.PaymentSuccessText || "Payment Successful"}</span>
      <span css={commonStyle.ctaStyle}>{paymentObject?.credit} {strapiSuccessText?.CreditsAddedToYour || "Credits added to your account"}</span>
      <span css={commonStyle.divider()}></span>
      <span css={commonStyle.ctaStyle}>{strapiSuccessText?.BalanceCreditText || "Balance Credits:"} {user?.entity?.entity?.credits}</span>
      {/* <CustomizedButton btnText={'Download receipt'} type={'grey'} isActive={true} btnStyle={commonStyle.download} onClick={downloadReceiptHandler} /> */}
    </div>
) : paymentObject?.success === 'false' && paymentObject?.credit > 0 ? (
  <div css={commonStyle.paymentFailed}>
    <span css={commonStyle.paymentTitle}>Payment Failed</span>
    <CustomizedButton type={'secondary'} btnText={'Try Again'} btnStyle={commonStyle.tryAgain} />
  </div>
) : null}

    </PopUp>
    <AddInstruction isOpen={isOpen} openHandler={openHandler} instructionHandler={instructionHandler}/>
          {feedbackPop &&
            (<FeedbackPop
              setOpenFeedBack={setOpenFeedBack}
              openFeedbackChat={openFeedbackChat}
              setFeedBackChat={setFeedBackChat}
              openpop={feedbackPop}
              handleClose={handleClose}
              jobData={jobData}
              isSideMenu={isSideMenu} 
              setSideMenu={setSideMenu}
              thumbsUp={thumbsUp}
              setThumbsUp={setThumbsUp}
              share={share}
              setShare={setShare}
              handleThumbsUp={handleThumbsUp}
              popUp= {popUp}
              setActivePopUp={setActivePopUp}
            />)
          }

          <SideDislikePopup
            openFeedbackChat={openFeedbackChat}
            isSideMenu={isSideMenu}
            jobInfo={jobData}
            userDetail={userDetail}
            companyName={companyName}
            setSideMenu={setSideMenu}
          />

          {thumbsUp && (<ThumbsUpJourney
            openpop={thumbsUp}
            setThumbsUp={setThumbsUp}/>)}

          {share && (<Referal 
            showReferal={share}
            onCloseReferal={closeReferal}
            setShare={setShare} />)} 
            {/* {openFeedback && <Grid container item xs={3.5} sx={{backgroundColor:"#F8F8F8",position:"fixed",right:10,pt:1}}>
              {console.log("final",jobData?.name)}
              <iframe
                id="feedback_Box"
                src={`${process.env.NEXT_PUBLIC_FEEDBACK_CHAT}?mode="app"&jobOrderLabel="${jobData?.name}"&jobOrderId="${jobData?.job_id}"&user_identity="${user?.user?.email || user?.user?.phone}"&company_name="${user?.user?.name}"&userId="${user?.user?._id}"&chat_type="EXTERNAL"`}
                style={{width:"100%",height:"calc(100vh - 135px)",border:"1px solid #EEEEEE",borderRadius:"8px"}} onLoad={feedbackChatTokenHandler}>
              </iframe>
            </Grid>} */}
    </React.Fragment>
  );
};




const commonStyle = {
  title: css`
    color: #262626;
    text-align: center;
    font-family: "Objectivity";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    margin-top: 16px;
    margin-bottom:4px;
  `,
  divider:(val)=> {return css`
    width: ${val ? val : 148}px;
    background: #eee;
    height: 1px;
    margin: 16px 0px;
  `},
  subtitle2: css`
    color: #262626;
    text-align: center;
    font-family: "Objectivity";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
  `,
  videoContainer: css`
    width: 100%;
    height: 200px;
    margin-top: 8px;
    padding-left:10%;
    padding-right:10%;
  `,
  bottomContainer: css`
    max-width: 350px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  subtitle3: css`
    color: #262626;
    font-family: "Objectivity";
    font-size: 12px;
    line-height: 1.4;
  `,
  btnStyle: {
    width: "100%",
    borderRadius: "8px",
    padding: "14px 0",
    marginTop: "4px",
    maxWidth: "382px",
  },
  creditHeading: css`
    display: flex;
    justify-content: flex-start;
    font-family: "Objectivity";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  `,
  creditSubtitle: css`
    display: flex;
    justify-content: space-between;
    font-family: "Objectivity";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
  `,
  creditTotal: css`
    display: flex;
    justify-content: space-between;
    font-family: "Objectivity";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  `,
  hdCreateContainer:css`
    width: 382px;
    height: 186px;
    flex-shrink: 0;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:24px;
    border-radius: 4px;
    border: 0.5px solid rgba(235, 46, 131, 0.16);
    background: linear-gradient(95deg, rgba(240, 55, 80, 0.02) 18.9%, rgba(235, 46, 131, 0.02) 91.1%);
    `,
  gradPinkText:css`
    text-align: center;
    font-family: Objectivity;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: 0.16px;
    background: var(--Pink, linear-gradient(95deg, #F03750 18.9%, #EB2E83 91.1%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `,
  summeryTitle: css`
    border-bottom: 1px solid #eee;
    padding: 12px 16px;
  `,
  imageSubtitle: css`
    color: rgba(38, 38, 38, 0.7);
    padding: 12px 16px;
  `,
  imageTotal: css`
    padding-top: 16px;
  `,
  gap8:css`gap:8px;`,
  violetColor:css`
    background: linear-gradient(94deg, #5F2EE5 0%, #9F35F0 101.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Objectivity;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    letter-spacing: 0.14px;
   `,
  paymentSuccess:css`
    padding:56px 40px 32px;
    display:flex;
    flex-direction:column;
    align-items:center;
    `,
    headerTxt:css`
      color: #262626;
      text-align: center;
      font-family: 'Objectivity';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;`,
    ctaStyle:css`
      color: #262626;
      text-align: center;
      font-family: 'Objectivity';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;`,
    paymentFailed:css`
    padding:56px 40px 32px;
    display:flex;
    flex-direction:column;
    align-items:center;
    `,
    paymentTitle:css`
      color: #262626;
      text-align: center;
      font-family: 'Objectivity';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
    `,
    tryAgain:{
      borderRadius:'100px',
      padding:'8px 32px',
      marginTop:'8px',
      color:'#fff'
    },
    download:{
      padding:'10px 48px',
      fontSize:'16px',
      marginTop:'10px',
      background: '#F9F9F9',
      borderRadius: '999px',
    },
    maxwidth272:css`
      max-width:272px
    `,
    maxwidth238:css`
      max-width:238px
    `,
    mb32:css`
    padding-bottom:32px;
    `,
    mb48:css`
    padding-bottom:48px;
    `,
    mb8:{marginBottom:"8px"},
    mb22:css`margin-bottom:22px`,
    mt16:css`margin-top:16px`,
    mt24:css`margin-top:24px`,
    txtWhite:css`color:#ffffff;`,
    strikeThrough:css`text-decoration-line: line-through;`,
    borderBtmGray:css`border-bottom: 0.5px solid #EEE;`,
    flexCenter:css`
        display:flex;
        justify-content:space-between;
        color: #262626;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        line-height: 170%;
        padding:16px;
    `,
    proTouchSummary:css`
        flex-shrink: 0;
        border-radius: 4px;
        border: 0.5px solid #EEE;
        background: #FFF;
        
        h3{
            border-bottom: 0.5px solid #EEE;
        };
        p{
            padding-top:6px;
            padding-bottom:6px;
            line-height:100%;
        }
    `,
    instructionButton:{ 
      justifyContent: "space-between",
      maxHeight:'40px',
      marginTop:'10px',
      border: '1px solid rgba(238, 238, 238, 1)',
      background: 'rgba(249, 249, 249, 1)',
      color: 'rgba(38, 38, 38, 0.7)',
      padding:'10px',
      fontSize:'12px'
    },
};

function controlledStyles(type) {
  let containerWidth = 0,
    container,
    subtitle3,
    bottomContainer,
    videoContainer;
  switch (type) {
      case POPUP_TYPE.LOGGED_IN_JOB_HIGH_RESOLUTION: {
        containerWidth = 500;
        container = css`
          display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 60px;
      `;
      subtitle3 = css`
        max-width: 350px;
        text-align: center;
      `;
      break;
    }
    case POPUP_TYPE.SEND_JOB_FOR_PROTOUCH: {
        containerWidth = 500;
        container = css`
          display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 60px;
      `;
      subtitle3 = css`
        max-width: 350px;
        text-align: center;
      `;
      break;
    }
    case POPUP_TYPE.GUEST_JOB_LOW_RESOLUTION:{
        containerWidth = 500;
        container = css`
          display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 60px;
    `;
        subtitle3 = css`
            max-width: 350px;
            text-align: center;
        `;
        break;
    }
    case POPUP_TYPE.SEND_PRO_TOUCH: {
        containerWidth = 500;
        container = css`
          display: flex;
          min-Height:500px;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 10px;
      `;
      subtitle3 = css`
        max-width: 350px;
        text-align: center;
      `;
      break;
    }
    case POPUP_TYPE.NON_COMPLIMENTARY_DOWNLOAD: {
      containerWidth = 600;
      container = css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 92px;
        padding-bottom: 92px;
      `;
      subtitle3 = css`
        max-width: 350px;
        text-align: center;
        margin-top: 16px;
      `;
      bottomContainer = css`
        width: 382px;
        border: 1px solid #eee;
        padding: 4px;
        text-align: center;
        border-radius: 8px;
        margin-top: 16px;
      `;
      break;
    }
    case POPUP_TYPE.RETOUCH: {
      containerWidth = 500;
      container = css`
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding-top: 92px;
        padding-bottom: 92px;
      `;
      subtitle3 = css`
        max-width: 350px;
        text-align: center;
        margin-top: 16px;
      `;
      bottomContainer = css`
        width: 382px;
        border: 1px solid #eee;
        padding: 24px;
        text-align: center;
        border-radius: 8px;
        margin-top: 16px;
      `;
      break;
    }
    case POPUP_TYPE.NON_PROTOUCH_COMPLETED_JOB: {
      containerWidth = 800;
      container = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 946px;
        max-height: 616px;
      `;
      bottomContainer = css`
        width: 350px;
        border: 1px solid #eee;
        border-radius: 8px;
        margin: 16px 0px;
      `;
      break;
    }
    case POPUP_TYPE.BEFORE_PROTOUCH: {
      containerWidth = 800;
      container = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 946px;
        max-height: 616px;
      `;
      bottomContainer = css`
        width: 350px;
        border: 1px solid #eee;
        border-radius: 8px;
        margin: 16px 0px;
      `;
      break;
    }
    case POPUP_TYPE.DOWNLOADED_BEFORE_PROTOUCH: {
      containerWidth = 800;
      container = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 946px;
        max-height: 616px;
      `;
      bottomContainer = css`
        width: 350px;
        border: 1px solid #eee;
        border-radius: 8px;
        margin: 16px 0px;
      `;
      break;
    }
  }
  return {
    containerWidth,
    container,
    subtitle3,
    bottomContainer,
    videoContainer,
  };
}

const videoMediaBoxCss = css`
  width:100%;
  height:100%;
  background-color:rgb(0,0,0,0.2);
`;
export default SuccessPopUp;
