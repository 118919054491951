export enum EditorScreen {
  UPLOAD = "UPLOAD",
  GENERATE = "GENERATE",
  MAIN = "MAIN",
  PRODUCT_LIFESTYLE = "PRODUCT_LIFESTYLE",
  MODEL_LIFESTYLE = "MODEL_LIFESTYLE",
}

export const COVERAGE_AREA_LIST = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];

export const IMAGE_POSITION_LIST = [
  "Center Center",
  "Right Center",
  "Left Center",
  "Top Right",
  "Top Center",
  "Top Left",
  "Bottom Left",
  "Bottom Right",
  "Bottom Center",
];

export const UPGRADE_VALUE = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 40,
    label: 40,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 60,
    label: 60,
  },
  {
    value: 70,
    label: 70,
  },
  {
    value: 80,
    label: 80,
  },
  {
    value: 90,
    label: 90,
  },
  {
    value: 100,
    label: 100,
  },
];
