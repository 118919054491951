import { Observable } from "rxjs";
import { IPixelDimensions, SerializedFillInfo } from "./Interfaces";
import { fabric } from "fabric";

export default class Fill {

    public backgroundImageSrc: string;
    public backgroundImage: HTMLImageElement | null = null;
    public backgroundImageOffsetX: number = 0;
    public backgroundImageOffsetY: number = 0;
    public backgroundImageHeight: number = 0;
    public backgroundImageWidth: number = 0;
    public backgroundImageZoom: number = 1.0; 
    public backgroundColor: string = "#FFFFFF";
    public opacity: number = 0;
    public dimensions:IPixelDimensions = {height: 0,width:0}
    public dimensionsObservable:Observable<IPixelDimensions>;
    public canvas = document.createElement("canvas");
    public ctx: CanvasRenderingContext2D | null;
    public positionX: number = 0; // -100 to 100
    public positionY: number = 0; // -100 to 100
    public imageRotation: number = 0;
    public imageOpacity: number = 1;
    public backgroundBlury: boolean = false;


    constructor(height:number,width:number) {
        this.ctx = this.canvas.getContext('2d');
        this.dimensions.height = this.canvas.height = height;
        this.dimensions.width = this.canvas.width = width;
    }

    public setBackgroundImage(imageElement: HTMLImageElement) {
        this.backgroundImage = imageElement;
        const aspectRatio = this.backgroundImage.width / this.backgroundImage.height;
        this.backgroundImageHeight = this.dimensions.height;
        this.backgroundImageWidth = this.dimensions.height * aspectRatio;
        if (this.dimensions.width / this.dimensions.height < aspectRatio) {
            this.backgroundImageHeight = this.dimensions.width / aspectRatio;
            this.backgroundImageWidth = this.dimensions.width;
        }
        this.backgroundImageOffsetX = Number(this.dimensions.width - this.backgroundImageWidth) / 2;
        this.backgroundImageOffsetY = Number(this.dimensions.height - this.backgroundImageHeight) / 2;
        this.backgroundImageSrc = imageElement.src;
        
    }

    private hexToRGBA(hex:string, alpha = 1) {
        // Convert hex color to RGBA
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
    
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    private drawEllipticalGradient(ctx, hexColor:string) {
        const maxWidth = ctx.canvas.width;
    const maxHeight = ctx.canvas.height;

    // Calculate the scaling factors for width and height to maintain the elliptical shape
    const scaleX = maxWidth / maxHeight;
    const scaleY = 1; // Keep scaleY as 1 to maintain the aspect ratio for the ellipse

    // Determine the center of the canvas
    let centerX = maxWidth / 2;
    let centerY = maxHeight / 2;

    // Save the current state of the context
    ctx.save();

    // Apply scaling to create an elliptical gradient
    ctx.scale(scaleX, scaleY);

    // Adjust the center position based on the scaling
    let x = centerX / scaleX;
    let y = centerY;

    // The radius for the outer edge of the gradient, adjusted for the scaling
    let outerRadius = Math.min(maxHeight / 2, maxHeight / 2);

    // Create the radial gradient
    let gradient = ctx.createRadialGradient(x, y, 0, x, y, outerRadius);


    // Add color stops to the gradient based on the blur percentage
    gradient.addColorStop(0, this.hexToRGBA(hexColor, 1)); 

    gradient.addColorStop(1, this.hexToRGBA(hexColor, this.backgroundBlury ? 0 : 1)); 

    // Fill the ellipse with the gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, maxWidth / scaleX, maxHeight); // Adjusting fill rect for the scaled context

    // Restore the context to undo the scaling
    ctx.restore();
    }

    private cloneAndRedrawCanvas(): HTMLCanvasElement {
        const clone = this.canvas.cloneNode(true) as HTMLCanvasElement;
        clone.width = this.canvas.width || 10; // Set the width to the original canvas width
        clone.height = this.canvas.height || 10; // Set the height to the original canvas height
        const cloneCtx = clone.getContext('2d');
        if(!cloneCtx) return clone;
    
        cloneCtx.clearRect(0, 0, clone.width, clone.height);
        if(this.backgroundBlury){
            this.drawEllipticalGradient(cloneCtx, this.backgroundColor)
        }else{
            cloneCtx.fillStyle = this.backgroundColor;
            cloneCtx.fillRect(0, 0, this.dimensions.width, this.dimensions.height);
        }

        if (this.backgroundImage) {
            const zoomedWidth = this.backgroundImageWidth * this.backgroundImageZoom;
            const zoomedHeight = this.backgroundImageHeight * this.backgroundImageZoom;
            const offsetX = (this.positionX / 100) * ((this.dimensions.width + zoomedWidth)/2);
            const offsetY = (this.positionY / 100) * ((this.dimensions.height + zoomedHeight)/2);
            const zoomedOffsetX = this.backgroundImageOffsetX - ((zoomedWidth - this.backgroundImageWidth) / 2) + offsetX;
            const zoomedOffsetY = this.backgroundImageOffsetY - ((zoomedHeight - this.backgroundImageHeight) / 2) + offsetY;

            cloneCtx.save();
            cloneCtx.globalAlpha = this.imageOpacity;
            cloneCtx.translate(zoomedOffsetX + zoomedWidth / 2, zoomedOffsetY + zoomedHeight / 2);
            cloneCtx.rotate(this.imageRotation * Math.PI / 180);
            cloneCtx.drawImage(this.backgroundImage, -zoomedWidth / 2, -zoomedHeight / 2, zoomedWidth, zoomedHeight);
            cloneCtx.restore();
        }
        return clone;
    }

    public redraw() {
        
        return new fabric.Pattern({
            source:this.cloneAndRedrawCanvas() as any,
            crossOrigin:"anonymous",
            repeat:"no-repeat",
            offsetX:0,
            offsetY:0
        })
    }

    public serialize(): SerializedFillInfo {
        return {
            backgroundImageSrc: this.backgroundImageSrc,
            backgroundImageOffsetX: this.backgroundImageOffsetX,
            backgroundImageOffsetY: this.backgroundImageOffsetY,
            backgroundImageHeight: this.backgroundImageHeight,
            backgroundImageWidth: this.backgroundImageWidth,
            backgroundImageZoom: this.backgroundImageZoom,
            backgroundColor: this.backgroundColor,
            positionX: this.positionX,
            positionY: this.positionY,
            imageRotation: this.imageRotation,
            imageOpacity: this.imageOpacity,
            dimensions: this.dimensions,
            backgroundBlury: this.backgroundBlury
        };
    }

    public toJSON(): SerializedFillInfo {
        return {
            backgroundImageSrc: this.backgroundImageSrc,
            backgroundImageOffsetX: this.backgroundImageOffsetX,
            backgroundImageOffsetY: this.backgroundImageOffsetY,
            backgroundImageHeight: this.backgroundImageHeight,
            backgroundImageWidth: this.backgroundImageWidth,
            backgroundImageZoom: this.backgroundImageZoom,
            backgroundColor: this.backgroundColor,
            positionX: this.positionX,
            positionY: this.positionY,
            imageRotation: this.imageRotation,
            imageOpacity: this.imageOpacity,
            dimensions: this.dimensions,
            backgroundBlury: this.backgroundBlury
        };
    }
    

    public async enliven(data: SerializedFillInfo) {
        if(data?.backgroundImageSrc) {
            const img = new Image(); 
            img.crossOrigin = "anonymous";
            img.src = data.backgroundImageSrc;
            try {
                await new Promise((resolve, reject) =>{ img.onload = resolve, img.onerror = reject});
                this.setBackgroundImage(img); // Set the loaded image as the background
            } catch(err) {
                console.error('Error loading image:', err);
            }
        }

        if(data?.backgroundColor)this.backgroundColor = data.backgroundColor;
        if(data?.backgroundImageZoom)this.backgroundImageZoom = data.backgroundImageZoom;
        // this.backgroundImageHeight = data.backgroundImageHeight;
        // this.backgroundImageWidth = data.backgroundImageWidth;
        // this.backgroundImageOffsetX = data.backgroundImageOffsetX;
        // this.backgroundImageOffsetY = data.backgroundImageOffsetY;
        if(data?.imageOpacity)this.imageOpacity = data.imageOpacity;
        if(data?.imageRotation)this.imageRotation = data.imageRotation;
        if(data?.backgroundBlury) this.backgroundBlury = data.backgroundBlury;
        // this.dimensions = data.dimensions;
        if(data?.positionX)this.positionX = data.positionX;
        if(data?.positionY)this.positionY = data.positionY;
    }

}