import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { activePageAtom, createNewPopUpAtom, gridCanvasAtom, historyDalleAtom, isLayersPaneVisibleAtom, layersAtom, pageStatesAtom, selectedLayerAtom, standardInfographic, zoomAtom } from "../Atoms";
import useEditorService from "src/hooks/useEditorService";
import IconButton, { IconButtonSizes, IconButtonTypes } from "../../ui-elements/IconButton";
import { ICONS, Icon } from "../../ui-elements/Icon";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useToast  from "../../../hooks/useToast";
import { useSelector } from "react-redux";
import useAuth from "@/hooks/useAuth";

export default function CanvasTopBar({resizePopUpRef,deletePageRef}) {

    const activePage = useAtomValue(activePageAtom);
    const pages = useAtomValue(pageStatesAtom)
    const { duplicatePage} = useEditorService();
    const toaster = useToast();
    const {user} = useAuth();
    const [getHistoryValue,setHistoryValue] = useAtom(historyDalleAtom);
    const zoom = useAtomValue(zoomAtom);
    const {activeEditor} = useEditorService();
    const layers = useAtomValue(layersAtom);
    const {entireStrapiData} = useSelector(state=>state?.strapi);
    const iconText = entireStrapiData?.StudioBackgroundText?.StudioBackgroundText
    const deletePageData = entireStrapiData?.DeletePageDetails?.DeletePageDetails
    const infoToasterText = useSelector(state=>state?.strapi?.StudioInfoToasterList?.StudioInfoToasterList?.LoadingPage);
    const successToasterText = useSelector(state=>state?.strapi?.StudioSuccessToasterList?.StudioSuccessToasterList?.PageDuplicateSuccess);
    const errorToasterText = useSelector(state=>state?.strapi?.StudioErrorToasterList?.StudioErrorToasterList?.PleaseTryAgainLater);
    // const [duplicateInProcess,setDuplicateInProcess] = useState(false);
    const isInfoGraphicUser = useMemo(()=> user?.user?.infoGraphicsUser || false,[user?.entity?.entity?._id]);
    const pagesStates = useAtomValue(pageStatesAtom);
    const isStandard = useAtomValue(standardInfographic)
    const [LayersPaneOpen, setLayersPaneOpen] = useAtom(isLayersPaneVisibleAtom);
    const selectedLayer = useAtomValue(selectedLayerAtom);
    const createNewPopUp = useAtomValue(createNewPopUpAtom);
    const [gridOnCanvas,setGridOnCanvas] = useAtom(gridCanvasAtom);

    const duplicate = useCallback(()=>{
        const isAutoSaving = pagesStates.find(x=>x.isAutoSaving);
        if(isAutoSaving) return toaster.info("Please wait while auto saving ....", 10000);
        duplicatePage().catch(console.error);
        
    },[duplicatePage, pagesStates])

    const isGridActive = useMemo(()=>{
        let isGrid = false;
        let gridLines = layers.filter(layer=>layer.getTags()?.includes("grid-line"));
        gridLines?.map(async(layer)=>{
        let currOpacity = 0;
        const opacitySubscription = layer.opacity.subscribe((val)=>{currOpacity = val})
        if(currOpacity)isGrid = true;
        opacitySubscription.unsubscribe();
       });
       return isGrid;
    },[gridOnCanvas, layers]);

    const openPopUp = useCallback(()=>{
        resizePopUpRef.current.open({
            top: 0,
            width: 340,
            height: window.innerHeight - 16 - 64,
            left: Math.max(window.innerWidth - 1190, 0),
        });
    },[]);

    const onPageDeleteIconClick = () => {
        deletePageRef.current?.open({
            width:460,
            top: 0  ,
            left: 650,
            backdrop:true, 
            closeOnBackdropClick:true, 
            backdropOpacity:0.1
        });
    }

    const onGridIconClick = async() => {
        if (!activeEditor || !activeEditor?.canvas.viewportTransform) return;
        let addGrid = true;
        let gridLines = layers.filter(layer=>layer.getTags()?.includes("grid-line"));
        if(gridLines.length > 0) addGrid = false;
        gridLines.map(async(layer)=>{
            let currOpacity = 0;
            const opacitySubscription = layer.opacity.subscribe((val)=>{currOpacity = val})
            layer.setOpacity(currOpacity ? 0 : 1);
            opacitySubscription.unsubscribe();
        });
        
        if(!addGrid){
            setGridOnCanvas(!gridOnCanvas);
            return;
        }
           // Create grid lines
        const gridSpace = Math.min(activeEditor?.canvas.height,activeEditor?.canvas.width)/10;
        const gridColor = 'rgba(235, 46, 131, 1)'
    
        const canvasWidth = activeEditor?.canvas.width;
        const canvasHeight = activeEditor?.canvas.height;
    
        const horizontalLinesCount = canvasWidth / gridSpace;
        const verticalLinesCount = canvasHeight / gridSpace;
    
        const promises = [];
    
        for (let i = 1; i < horizontalLinesCount || i < verticalLinesCount; i++) {
            if (i < horizontalLinesCount) {
                const horizontalLine = new fabric.Line([i * gridSpace, 0, i * gridSpace, canvasHeight], { stroke: gridColor, selectable: false, evented: false });
                activeEditor?.canvas.add(horizontalLine);
                const svgLayer = await activeEditor.getLayerByRef(horizontalLine);
                svgLayer.addTag("grid-line");
                promises.push(svgLayer);
            }
            if (i < verticalLinesCount) {
                const verticalLine = new fabric.Line([0, i * gridSpace, canvasWidth, i * gridSpace], { stroke: gridColor, selectable: false, evented: false });
                activeEditor?.canvas.add(verticalLine);
                const svgLayer = await activeEditor.getLayerByRef(verticalLine);
                svgLayer.addTag("grid-line");
                promises.push(svgLayer);
            }
        }
    
        await Promise.all(promises);
        activeEditor?.canvas.renderAll();
        setGridOnCanvas(!gridOnCanvas);
    };

    return <div style={{...styles.container}}>
            <div css={styles.rightColumn}>
                    {((isInfoGraphicUser && !isStandard) || !isInfoGraphicUser) && <IconButton 
                        tooltipText={iconText?.AddNewPageHover || "Add New Page"} 
                        icon={createNewPopUp ? ICONS.createLight : "/icons/create.png"}
                        type={IconButtonTypes.grey} 
                        size={IconButtonSizes.mdBigIcon} 
                        style={{marginRight:'10px', background: createNewPopUp && '#464646'}} 
                        onClick={openPopUp}
                    />}
                    {((isInfoGraphicUser && !isStandard) || !isInfoGraphicUser) && <IconButton 
                        tooltipText={iconText?.DuplicatePageHover || "Duplicate Page"} 
                        icon="/icons/newlybuild.png" 
                        type={IconButtonTypes.grey} 
                        size={IconButtonSizes.mdBigIcon} 
                        style={{marginRight:'10px'}}
                        onClick={duplicate}
                    />}
                    {((isInfoGraphicUser && !isStandard) || !isInfoGraphicUser) && pages?.length > 1 && <IconButton
                        tooltipText={deletePageData?.RightButtonText || "Delete"} 
                        icon={ICONS.deleteIcon} 
                        type={IconButtonTypes.grey} 
                        size={IconButtonSizes.mdBigIcon} 
                        style={{marginRight:'10px'}}
                        onClick={onPageDeleteIconClick}
                        />}
                    <IconButton
                        icon={!LayersPaneOpen ? ICONS.layerDark : ICONS.layersLight}
                        size={IconButtonSizes.mdBigIcon}
                        style={{background: LayersPaneOpen && '#464646', marginRight:'10px'}}
                        tooltipText={"Layers"}
                        type={IconButtonTypes.grey}
                        onClick={()=>setLayersPaneOpen(x=>!x)}
                    />
                    <IconButton
                        tooltipText={"Grid"} 
                        icon={isGridActive ? ICONS.gridLight : ICONS.gridDark}
                        type={isGridActive ? IconButtonTypes.dark : IconButtonTypes.grey} 
                        size={IconButtonSizes.mdBigIcon} 
                        onClick={onGridIconClick}
                    />
                </div>
    </div>

}

const styles = {
    container: {
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'flex-end',
        // position: 'absolute',
        // zIndex: 1000,
        // transform:"translateY(-100%)",
        // minWidth:"200px",
    },
    leftColumn: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 170%;
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        color: rgba(38, 38, 38, 0.7);
        border-bottom: 1px solid rgba(38, 38, 38, 0.32);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right:8px;
    `,
    rightColumn: css`
        display: flex;
    `,
    mr10: {
        marginRight:"10px"
    },
    ml10: {
        marginLeft:"10px"
    },
}