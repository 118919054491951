import API_SERVICE from "src/utils/axios";

const UPDATE_EMAIL_PHONE_URL = "/v4/users/user/update-phone-email";
const VERIFY_EMAIL_PHONE_URL = "/v4/users/user/verify-update-phone-email";
const UPDATE_USER_URL = "/v4/users/auth/user/update-user";
const VERIFY_SOCIAL_SERVICE_URL = "/v4/users/user/social/signin";
const DISCONNECT_CHANNEL_ADVISOR_ACCOUNT =
  "v4/channel-advisor/disconnect-account";
const CHANNEL_ADVISOR_AUTHORISATION = "v4/channel-advisor/auth-token";
const CHANGE_PROFILE_PICTURE = "/v4/users/auth/user/get-presigned-url";
const SET_CHANNEL_ADVISOR_DEFAULT_MARKETPLACE_ATTRIBUTE =
  "/v4/channel-advisor/default-marketplace-attribute";
const FETCH_PAYMENT_CARDS = "/v4/user-invoices/stripe/customers";
const FETCH_PLAN_DETAILS = "/v4/user-invoices/getEntityMembershipDetail";
const FETCH_RECHARGE_LIST = "/v4/user-invoices/getRechargeList";
const FETCH_PAYMENT_VALUES = "/v4/user-invoices/calculateCreditRechargeLogic";
const START_PAYMENT_CREDITS =
  "/v4/user-invoices/stripe/ProcessPaymentForCreditPurchase";
const PAYMENT_INTENT = "/v4/user-invoices/getPaymentIntentStatus";
const FETCH_MEMBERSHIP_PLANS = "/v3/admin/getallEntityPlanForWeb";
const FETCH_PRORATED_PLAN_PRICE =
  "/v4/user-invoices/stripe/retrieve-upcoming-invoice";
const CANCEL_CURRENT_PLAN = "/v4/user-invoices/stripe/cancelSubscription";
const START_PAYMENT_SUBSCRIPTION =
  "/v4/user-invoices/stripe/ProcessPaymentForSubscription";
const SAVE_CONTACT_DETAILS_FOR_SALES = "/v4/user-invoices/contact-us";
const GET_COUNTRY_CODES = "/v4/utilities/countryCodes";
const AMAZON_AUTHORISATION = "/v4/amazon/create-amazon-refresh-token";
const AMAZON_DISCONNECT = "/v4/amazon/disconnect";
const WALMART_DISCONNECT = "/v4/walmart/disconnect";
const WALMART_CONNECT = "/v4/walmart/connect";
const SET_WALMART_DEFAULT_MARKETPLACE_ATTRIBUTE = "/v4/walmart/default-marketplace-attribute";
const APP_SUMO_API = "/v3/admin/getMembershipPlans/";
const SHOPIFY_AUTHORISATION = "/v4/shopify/get-accessToken"
const SET_SHOPIFY_DEFAULT_MARKETPLACE_ATTRIBUTE = "/v4/shopify/default-marketplace-attribute";
const SHOPIFY_DISCONNECT = "/v4/shopify/disconnect";
const FETCH_USER_LIST = "v4/inviteUser/getUserAssociatedEntityList";
const CHANGE_ROLE = "v4/inviteUser/changeRole";
const REMOVE_USER = "v4/inviteUser/remove-user";
const INVITE = "v4/inviteUser/invite";
const FETCH_SHOOT_LIMIT_HISTORY = "v4/shootnewLifestyleImage/getShootLimitHistory";
const UPDATE_SHOOT_LIMIT = "v4/users/auth/user/update-user";
const ACCEPT_INVITE = "v4/users/user-merge";

export const updatePhoneOrEmailService = async (payload) => {
  try {
    return await API_SERVICE.post(UPDATE_EMAIL_PHONE_URL, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const verifyPhoneOrEmailService = async (payload) => {
  try {
    return await API_SERVICE.post(VERIFY_EMAIL_PHONE_URL, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const updateUserDetailsService = async (payload) => {
  try {
    return await API_SERVICE.put(UPDATE_USER_URL, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const verifySocialServiceUrl = async (payload) => {
  try {
    return await API_SERVICE.post(VERIFY_SOCIAL_SERVICE_URL, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const channelAdvisorAuthorisationApi = (authCode) => {
  const url = `${CHANNEL_ADVISOR_AUTHORISATION}/${authCode}`;
  return API_SERVICE.post(url);
};

export const disconnectChannelAdvisorAccountApi = (profileId) => {
  const payload = {
    profileId,
  };
  return API_SERVICE.put(DISCONNECT_CHANNEL_ADVISOR_ACCOUNT, payload);
};

export const shopifyAuthorisationApi = (authCode, shopName) => {
    return API_SERVICE.post(SHOPIFY_AUTHORISATION,{
        code:authCode,
        shopName
    });
  };

export const profilePictureUpload = async (payload) => {
  try {
    return await API_SERVICE.post(CHANGE_PROFILE_PICTURE, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const amazonAuthorisationApi = (payload) => {
  return API_SERVICE.post(AMAZON_AUTHORISATION, payload);
}

export const amazonAccountDisconnect = (payload) => {
  return API_SERVICE.patch(AMAZON_DISCONNECT, payload);
}

export const walmartAccountDisconnect = (walmartId) => {
    return API_SERVICE.put(`${WALMART_DISCONNECT}/${walmartId}`);
}

export const shopifyAccountDisconnect = (shopifyId) => {
    return API_SERVICE.put(`${SHOPIFY_DISCONNECT}/${shopifyId}`);
}

export const walmartAccountConnect = (payload) => {
    return API_SERVICE.post(WALMART_CONNECT, payload);
}

export const patchMarketplaceDefaultAttributeApi = async (payload) => {
    let url;
    switch (payload.marketplace) {
        case "ChannelAdvisor": {
            url = SET_CHANNEL_ADVISOR_DEFAULT_MARKETPLACE_ATTRIBUTE;
            break;
        }
        case "Walmart": {
            url = SET_WALMART_DEFAULT_MARKETPLACE_ATTRIBUTE;
            break;
        }
        case "Shopify": {
            url = SET_SHOPIFY_DEFAULT_MARKETPLACE_ATTRIBUTE;
            break;
        }
    } 
  return await API_SERVICE.patch(
    url,
    payload
  );
};

export const fetchPaymentCardsList = async () => {
  try {
    return await API_SERVICE.get(FETCH_PAYMENT_CARDS);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchPlanDetails = async () => {
  try {
    return await API_SERVICE.get(FETCH_PLAN_DETAILS);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchRechargeList = async () => {
  try {
    return await API_SERVICE.get(FETCH_RECHARGE_LIST);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchRechargePaymentValues = async (count) => {
  try {
    return await API_SERVICE.get(`${FETCH_PAYMENT_VALUES}?credits=${count}`);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchProratedPaymentValues = async (payload) => {
  try {
    return await API_SERVICE.post(`${FETCH_PRORATED_PLAN_PRICE}`, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const startPaymentCredits = async (payload) => {
  try {
    payload.mode="web";
    return await API_SERVICE.post(START_PAYMENT_CREDITS, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const checkPaymentStatus = async (paymentId) => {
    if(!paymentId) return;
  try {
    return await API_SERVICE.get(`${PAYMENT_INTENT}/${paymentId}`);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchMembershipPlans = async () => {
  try {
    return await API_SERVICE.get(FETCH_MEMBERSHIP_PLANS);
  } catch (e) {
    throw Error(e);
  }
};

export const saveUserContactDetailsForSales = async (payload) => {
  try {
    return await API_SERVICE.post(SAVE_CONTACT_DETAILS_FOR_SALES, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const cancelCurrentPlan = async () => {
  try {
    return await API_SERVICE.post(CANCEL_CURRENT_PLAN);
  } catch (e) {
    throw Error(e);
  }
};

export const startPaymentSubscription = async (payload) => {
  try {
    payload.mode="web";
    return await API_SERVICE.post(START_PAYMENT_SUBSCRIPTION, payload);
  } catch (e) {
    throw Error(e);
  }
};

export const fetchCountryCodes = async () => {
    try {
        return await API_SERVICE.get(GET_COUNTRY_CODES);
      } catch (e) {
        throw Error(e);
      }
}

export const fetchAppSumoData = async (refId) => {
  try {
      return await API_SERVICE.get(`${APP_SUMO_API}${refId}`);
    } catch (e) {
      throw Error(e);
    }
}

export const fetchUserList = async () => {
    try {
        return await API_SERVICE.get(`${FETCH_USER_LIST}`);
    } catch (e) {
        throw Error(e);
    }
}

export const changeRole = async (payload) => {
    try {
        return await API_SERVICE.put(`${CHANGE_ROLE}`, payload);
    } catch (e) {
        throw Error(e);
    }
}

export const removeSelectedUser = async (userId) => {
    try {
        return await API_SERVICE.post(`${REMOVE_USER}`, {userId});
    } catch (e) {
        throw Error(e);
    }
}

export const cancelUserInvite = async (email) => {
  try {
    return await API_SERVICE.post(`${INVITE}`, {
      invitedUserEmail: email,
      invitationType: "CANCELED",
    });
  } catch (e) {
    throw Error(e);
  }
};

export const inviteUser = async (email, role, shoot_limit) => {
    try {
        return await API_SERVICE.post(`${INVITE}`, {
        invitedUserEmail: email,
        invitationRole: role,
        shoot_limit:shoot_limit,
        invitationType: "INVITED",
        });
    } catch (e) {
        throw Error(e?.response?.data?.data || e?.response?.data?.message || e?.message);
    }
}

export const fetchShootLimitHistory = async(user_id) => {
  try{
    return await API_SERVICE.get(`${FETCH_SHOOT_LIMIT_HISTORY}?userId=${user_id}`);
  } catch (e) {
    throw Error(e?.response?.data?.data || e?.response?.data?.message || e?.message);
  }
};

export const updateShootLimitApi = async(payload) => {
  try{
    return await API_SERVICE.put(`${UPDATE_SHOOT_LIMIT}`,payload);
  }catch (e) {
    throw Error(e?.response?.data?.data || e?.response?.data?.message || e?.message);
  }
};
export const acceptInvite = async () => {
  try {
      return await API_SERVICE.post(`${ACCEPT_INVITE}`);
  } catch (e) {
      throw Error(e?.response?.data?.data || e?.response?.data?.message || e?.message);
  }
};

export const rejectInvite = async (email) => {
  try {
      return await API_SERVICE.post(`${INVITE}`, {
      invitedUserEmail: email,
      invitationType: "REJECTED",
      });
  } catch (e) {
      throw Error(e?.response?.data?.data || e?.response?.data?.message || e?.message);
  }
};
