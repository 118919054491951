import { useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import useEditorService from "@/hooks/useEditorService";
import Layer from "local_modules/editor/src/Layer";
import UploadAssetPrompt from "./UploadAssetPrompt";
import PromptGenerator from "./PromptGenerator";
import AIBackgroundResults from "./AIBackgroundsResults";
import ImageLayer from "local_modules/editor/lib/ImageLayer";
import { AttributesList, createNewTemplate, generateAttributesAndPrompt, generateBackgrounds, uploadToS3 } from "../../services/AIBackgrounds";
import RemoveBackgroundAndApply from "./RemoveBackgroundAndApply";
import { 
    aiBackgroundPromptAtom, 
    aiBackgroundUrlsAtoms, 
    aiBackgroundsS3UrlsAtom,
    materialAtom,
    settingsAtom,
    productAtom,
    lightingAtom,
    moodAtom,
    scaleAndProportionAtom,
    colorPalleteAtom,
    additionalElementsAtom,
    materialListAtom,
    settingsListAtom,
    productListAtom,
    lightingListAtom,
    moodListAtom,
    scaleAndProportionListAtom,
    colorPalleteListAtom,
    additionalElementsListAtom,
    aigenerationLayerProgressAtom
 } from "../../Atoms";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import DMSCanvas from "local_modules/editor/src/DMSCanvas";
import useResetAtomHandler from "@/modules/editor-v2/resetAtomHandler";

const pattern = /(?<subcategory>[\w\s]+) on (?<material>[\w\s]+) (?<setting>[\w\s]+) in a (?<environment>[\w\s]+) in a (?<style>[\w\s]+) style next to (?<prop>\b\w+\b(?:\s+\b\w+\b)?)$/;

export default function AIBackgrounds({setIsAIBackgrounds, setAttributePage, attributePage}) {

    const [layers,setLayers] = useState<Layer[]>([])
    const {activeEditor} = useEditorService();
    const [generating, setGenerating] = useState(false);
    const [urls, setUrls] = useAtom(aiBackgroundUrlsAtoms);
    const [prompt, setPrompt] = useAtom(aiBackgroundPromptAtom);
    const [s3Url, setS3Url] = useAtom(aiBackgroundsS3UrlsAtom);
    const [assisted, setAssisted] = useState(true);
    const [promptGenerating, setPromptGenerating] = useState(false);
    const [material, setMaterial] = useAtom(materialAtom);
    const [settings, setSettings] = useAtom(settingsAtom);
    const [product, setProduct] = useAtom(productAtom);
    const [lighting, setLighting] = useAtom(lightingAtom);
    const [mood, setMood] = useAtom(moodAtom);
    const [scaleAndProportion, setScaleAndProportion] = useAtom(scaleAndProportionAtom);
    const [colorPallete, setColorPallete] = useAtom(colorPalleteAtom);
    const [additionalElements, setAdditionalElements] = useAtom(additionalElementsAtom);
    const {user} = useAuth();
    const aigenerationLayerProgress = useSetAtom(aigenerationLayerProgressAtom);
    const aigenerationLayer = useAtomValue(aigenerationLayerProgressAtom)

    // Dynamically served assisted prompt List
    const [materialList, setMaterialList] = useAtom(materialListAtom);
    const [settingsList, setSettingsList] = useAtom(settingsListAtom);
    const [productList, setProductList] = useAtom(productListAtom);
    const [lightingList, setLightingList] = useAtom(lightingListAtom);
    const [moodList, setMoodList] = useAtom(moodListAtom);
    const [scaleAndProportionList, setScaleAndProportionList] = useAtom(scaleAndProportionListAtom);
    const [colorPalleteList, setColorPalleteList] = useAtom(colorPalleteListAtom);
    const [additionalElementsList, setAdditionalElementsList] = useAtom(additionalElementsListAtom);
    const toast = useToast();
    const {resetPromptHandler} = useResetAtomHandler();

    const productImage = useMemo(()=>{
        let layer = layers.find(layer=>layer?.getTags()?.includes("product-image"));
        if(layer?.getTags()?.includes("non-bg-removed")) return undefined;
        return layer;
    },[layers]);

    const isNonBgRemoved = useMemo(
        ()=>layers.find(layer=>layer?.getTags()?.includes("product-image"))?.getTags()?.includes("non-bg-removed"),
        [layers]
    );

    const uploadProductImage = async (prompt) => {
        if(!prompt) return toast.error("Please enter prompt!",2000);
        let serializedEditor = await activeEditor.save();
        const layer = serializedEditor.layers.find(layer=>layer.id===productImage.id);
        if(!layer) return toast.error("Cannot find Product Image!");
        for (let i = 1; i < 7; i++) {
            await new Promise(resolve => setTimeout(resolve, 2000)); 
            aigenerationLayerProgress(10 * i+1);
        }
        const editor = new DMSCanvas("canvas2",activeEditor.dimensions,activeEditor.originalSize);
        await new Promise((res)=>editor.canvas.setBackgroundColor("rgba(0,0,0,0)",res));
        const layerRef = await editor.addLayerFromSerializedData(layer);
        await layerRef.setPropertiesFromSerializedData(layer);
        editor.canvas.renderAll();
        const aspectRatio = activeEditor.originalSize.height / activeEditor.originalSize.width;
        if(aspectRatio > 1) await editor.resize({height: 640, width: 640 / aspectRatio});
        else await editor.resize({height: 640 * aspectRatio, width: 640});
        editor.canvas.renderAll();
        const base64 = editor.toBase64Url();
        setGenerating(true);
        setUrls(Array(4).fill(0).map(x=>({url:"",loading:true})));
        try{
            let url = await uploadToS3(base64);
            setS3Url(url);
            aigenerationLayerProgress(90)
            const urls = (await generateBackgrounds(url,prompt, user?.entity?.entity?._id || ""))?.map(x=>({url:x?.url,loading:false}));
            setUrls(urls);
            setGenerating(false);
        }
        catch(e){
            toast.error("Unable to process the Image, Please try again!");
            setIsAIBackgrounds(false)
            resetPromptHandler()
        }
        finally{
            aigenerationLayerProgress(100);
        }
    }

    useEffect(()=>{
        const subscription = activeEditor?.layerManager.layers.subscribe(setLayers);
        return () => {
            subscription?.unsubscribe();
        }
    },[activeEditor]);

    useEffect(()=>{
        if(!productImage || urls?.length > 0) return;
        if(!attributePage && !aigenerationLayer)
        {
            //@ts-ignore
            const url = (productImage as ImageLayer).imageFabricRef.getSrc();
            setPromptGenerating(true);
            setPrompt('');
            aigenerationLayerProgress(10);
            generateAttributesAndPrompt(url, user?.entity?.entity?._id || "")
            .then(res => {
                if (res && res.prompt) {
                    setProductList(res?.attributesList?.[0]?.values)
                    setSettingsList(res?.attributesList?.[1]?.values)
                    setMaterialList(res?.attributesList?.[2]?.values)
                    setLightingList(res?.attributesList?.[3]?.values)
                    setColorPalleteList(res?.attributesList?.[4]?.values)
                    setMoodList(res?.attributesList?.[5]?.values)
                    setScaleAndProportionList(res?.attributesList?.[6]?.values)
                    setAdditionalElementsList(res?.attributesList?.[7]?.values)
        
                    //Initialization of assistance
                    setMaterial(res?.attributesList?.[2]?.value)
                    setProduct(res?.attributesList?.[0]?.value)
                    setSettings(res?.attributesList?.[1]?.value)
                    setMaterial(res?.attributesList?.[2]?.value)
                    setLighting(res?.attributesList?.[3]?.value)
                    setColorPallete(res?.attributesList?.[4]?.value)
                    setMood(res?.attributesList?.[5]?.value)
                    setScaleAndProportion(res?.attributesList?.[6]?.value)
                    setAdditionalElements(res?.attributesList?.[7]?.value)
                    setPrompt(res?.prompt);
                    setPromptGenerating(false);
                    uploadProductImage(res?.prompt);
                } else {
                    toast.error("Unable to process the Image, Please try again!")
                    setIsAIBackgrounds(false)
                    resetPromptHandler()
                    return;
                }
            })
        }
    },[productImage,urls])

    useEffect(()=>{
        if(!prompt || promptGenerating) return;
        const match = prompt.match(pattern);
        if(!match) {
            setAssisted(false);
            return ()=>{};
        }
        const { material, settings, product, lighting, mood, scaleAndProportion, colorPallete, additionalElements } = match.groups;
        setAssisted(true);
        setSettings(settings);
        setProduct(product);
        setLighting(lighting);
        setMood(mood);
        setScaleAndProportion(scaleAndProportion);
        setColorPallete(colorPallete);
        setAdditionalElements(additionalElements);
        setMaterial(material);
            
    },[prompt,promptGenerating]);

    useEffect(()=>{
        if(!prompt) return;
        // if(urls?.length > 1) return;
        const newPrompt = `${product} in ${settings} on ${material} in Lighting ${lighting} with setting color ${colorPallete} and Mood ${mood} and Scale Context ${scaleAndProportion} next to ${additionalElements}`
        setAssisted(true);
        setPrompt(newPrompt);
    },[material, settings, product, lighting, mood, scaleAndProportion, colorPallete, additionalElements])

    return (<>
        {isNonBgRemoved && <RemoveBackgroundAndApply />}
        {!productImage && !isNonBgRemoved && <UploadAssetPrompt setIsAIBackgrounds={setIsAIBackgrounds}/>}
        {productImage && !attributePage && <AIBackgroundResults 
            urls={urls} 
            setGenerating={setGenerating} 
            setUrls={setUrls} 
            setS3Url={setS3Url} 
            s3Url={s3Url} 
            prompt={prompt} 
            productImage={productImage} 
            setPrompt={setPrompt}
            generating={generating}
            setAttributePage={setAttributePage}
        />}
        {productImage && attributePage && <PromptGenerator 
            setGenerating={setGenerating} 
            generating={generating}
            setUrls={setUrls} 
            setS3Url={setS3Url} 
            prompt={prompt} 
            assisted={assisted}
            setMaterial={setMaterial}
            setProduct={setProduct}
            setAdditionalElements={setAdditionalElements}
            setColorPalette={setColorPallete}
            setLighting={setLighting}
            setSettings={setSettings}
            setMood={setMood}
            setScaleAndProportion={setScaleAndProportion}
            setPrompt={setPrompt}
            setAssisted={setAssisted}
            materialList={materialList}
            settingsList={settingsList}
            productList={productList}
            lightingList={lightingList}
            moodList={moodList}
            scaleAndProportionList={scaleAndProportionList}
            additionalElementsList={additionalElementsList}
            colorPalleteList={colorPalleteList}
            material={material}
            settings={settings}
            product={product}
            lighting={lighting}
            mood={mood}
            scaleAndProportion={scaleAndProportion}
            colorPalette={colorPallete}
            additionalElements={additionalElements}
            setIsAIBackgrounds={setIsAIBackgrounds}
            setAttributePage={setAttributePage}
        />}
    </>)
}