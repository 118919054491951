import { useEffect, useState, useCallback } from "react"
import FilteredTemplates from "./FilteredTemplates"
import RecentTemplates from "./RecentTemplates"
import TemplatesHeader from "./TemplateHeader/index"
import { ICONS } from "@/modules/ui-elements/Icon";
import useEditorService from "@/hooks/useEditorService";
import useEditorLayerService from "@/hooks/useEditorLayerService";
import { activePageAtom,
         backgroundFilterAtom, 
         backgroundFilterBackgroundModeAtom, 
         backgroundFilterIsAllAngleGroupActiveAtom, 
         backgroundFilterProductImageIdAtom, 
         backgroundFilterProductImageLayerIdAtom, 
         backgroundFilterProductImageUrlAtom, 
         pageStatesAtom,
         backgroundFilterTemplateTypeAtom,
         aiBackgroundUrlsAtoms,
         backgroundImageAtom,
         aigenerationLayerProgressAtom,
         homepageFeatureAtom
         } from "../../Atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {useCustomTemplateQuery} from '@/hooks/FetchCountAndListHook';
import {useAngleTemplateQuery} from '@/hooks/FetchAngleTemplateHook';
import { useQueryClient } from "@tanstack/react-query";
import { isArray } from "lodash";
import AIBackgrounds from "../AIBackgrounds";
import AIBackgroundsBanner from "./AIBackgroundsBanner";
import VisualPrompt from "./VisualPrompt";
import TemplateBottomSection from './TemplateHeader/TemplateBottomSection';
import FilteredTemplateTopPane from './FilteredTemplateTopPane';
import { useSearchParams } from "next/navigation";
import useResetAtomHandler from "@/modules/editor-v2/resetAtomHandler";

export const TEMPLATE_TYPE={
    RECENT:"recentused",
    FAVOURITE:'yourfavorite',
    ALL:'all'
};

export const BACKGROUND_MODE={
    BASIC:"basic",
    SELECTANGLE:'selectangle',
};

export const FORMAT_TYPES=[
    {
        type:'Square',
        title:'Square',
        aspectRatio: "1/1",
        icon:ICONS.squareLight,
        lightIcon:ICONS.squareLight
    },
    {
        type:'Landscape',
        title:'Landscape',
        aspectRatio: "16/9",
        icon:ICONS.landscapeLight,
        lightIcon:ICONS.landscapeLight

    },
    {
        type:'Portrait',
        title:'Portrait',
        aspectRatio: "9/16",
        icon:ICONS.portraitLight,
        lightIcon:ICONS.portraitLight

    },
]

export const filterObject = {
    searchString:[],
    searchText:'',
    selectedFormat:FORMAT_TYPES[0],
    imageFormat:[FORMAT_TYPES[0].title],
    colors:[],
    hotspotOnly: false,
    updateRecentSearch:false,
    lifestyleImages:false,
    isInfographics:false,
    prespective:{}
};


export default function Templates() {
    const activePage = useAtomValue(activePageAtom);
    const queryClient = useQueryClient();
    const [backgroundMode,setBackgroundMode] = useAtom(backgroundFilterBackgroundModeAtom);
    const [isAllAngleGroupActive,setIsAllAngleGroupActive] = useAtom(backgroundFilterIsAllAngleGroupActiveAtom);
    const [productImageId,setProductImageId] = useAtom(backgroundFilterProductImageIdAtom);
    const [selectedFilter,setSelectedFilter] = useAtom(backgroundFilterAtom);
    const {activeEditor} = useEditorService();
    const setProductImageUrl = useSetAtom(backgroundFilterProductImageUrlAtom);
    const [productImageLayerId, setProductImageLayerId] = useAtom(backgroundFilterProductImageLayerIdAtom);
    const pagesStates = useAtomValue(pageStatesAtom);
    const {templatesResult,templatesResultIsLoading} = useCustomTemplateQuery();
    const {prespectiveList,prespectiveListLoading} = useAngleTemplateQuery();
    const [isAIBackgrounds, setIsAIBackgrounds] = useState(false);
    const [isVisualPrompt, setIsVisualPrompt] = useState(false);
    const [templateType,setTemplateType] = useAtom(backgroundFilterTemplateTypeAtom);
    const [backgroundImage, setBackgroundImage] = useAtom(backgroundImageAtom);
    const {deleteBackground} = useEditorService();
    const [attributePage, setAttributePage] = useState(false)
    const searchParams = useSearchParams();
    const aiBackgroundUrls = useAtomValue(aiBackgroundUrlsAtoms);
    const {resetPromptHandler} = useResetAtomHandler();
    const aigenerationLayerProgress = useAtomValue(aigenerationLayerProgressAtom); 
    const [homepageFeature, setHomepageFeature] = useAtom(homepageFeatureAtom);
    const bannerOnClick = useCallback(async ()=>{
        deleteBackground();
        setAttributePage(false)
        setIsAIBackgrounds(true)
      },[deleteBackground])

    useEffect(()=>{
        setSelectedFilter(filterObject);
        if(!activeEditor)
        return
        const layers = activeEditor.layerManager._layers.value.filter(layer=>layer.getTags()?.includes("product-image"));
        !layers.length && resetPromptHandler();
        if ((searchParams.has("AI-backgrounds")&&!homepageFeature)|| aigenerationLayerProgress ) { //Need to open AIBackgrounResults directly for AI-background jobs from homepage, website and tab switch
            setHomepageFeature("AI-Backgrounds")
            setIsAIBackgrounds(true);
        }
        if(!aigenerationLayerProgress && !aiBackgroundUrls?.length && !searchParams.has("AI-backgrounds"))
        {
            setIsAIBackgrounds(false);
        }
    },[])
      
    const [visualPromptData, setVisualPromptData]  = useState({
        baseUrls: [],
        backgroundImage: "",
        s3Url: "",
        prompt: "", 
        setGenerating: () => {},
        setUrls: () => {},
        setPrompt: ()=>{},
        generating: false,
    });

    useEffect(()=>{
        
        if(!activeEditor) {
            setProductImageId(undefined); 
            setProductImageUrl(undefined);
            setProductImageLayerId(undefined);
            setIsAllAngleGroupActive(true);
            setSelectedFilter(prev=>({...prev,prespective:{}}));
            return ()=>{};
        }
        let tagsSubscription;
        let subscription = activeEditor.layerManager.layers.subscribe(layers=>{

            let productImages = layers.filter(layer=>layer.getTags()?.includes("product-image"));
            if(tagsSubscription) tagsSubscription?.unsubscribe();
            if(!productImages?.[0]){
                if(!isArray(prespectiveList) || !prespectiveList?.length < 1)queryClient.invalidateQueries(['prespectiveSearch',"list",selectedFilter?.imageFormat, productImageId]);
                setProductImageId(undefined); 
                setProductImageUrl(undefined);
                setProductImageLayerId(undefined);
                setSelectedFilter(prev=>({...prev,prespective:{}}));
                setIsAllAngleGroupActive(true);
                setBackgroundMode(BACKGROUND_MODE.BASIC);
            }
            tagsSubscription = productImages?.[0]?.$tags.asObservable().subscribe(productImageTags=>{     
                let productImageType = "IMAGE";
                let productImageId = productImageTags?.filter(x=>x.includes("product-image-id-"))?.[0]?.replace("product-image-id-","");
                // setBackgroundMode(BACKGROUND_MODE.BASIC);
                if(!productImageId && productImageTags?.find(x=>x.includes("product-asset-id-"))) {
                    productImageType = "ASSET";
                    productImageId = pagesStates[activePage]?.imageId
                    if(productImageTags?.filter(x=>x.includes("product-asset-id-"))?.[0] && !productImageTags?.filter(x=>x.includes("angle-group"))?.[0] && !isAllAngleGroupActive)
                        setBackgroundMode(BACKGROUND_MODE.SELECTANGLE);
                    // productImageId = productImageTags?.filter(x=>x.includes("product-asset-id-"))?.[0]?.replace("product-asset-id-","");
                }
                let angleGroupId = productImageTags?.find(x=>x.includes("angle-group-id-"))?.replace("angle-group-id-","");
                let angleGroupName = productImageTags?.find(x=>x.includes("angle-group-name-"))?.replace("angle-group-name-","");
                if(angleGroupId && angleGroupName && !isAllAngleGroupActive)setSelectedFilter(prev=>({...prev,prespective:{angleGroupId,angleGroupName}}));
                else setSelectedFilter(prev=>({...prev,prespective:{}}));
                
                setProductImageId(productImageId || undefined); 
                setProductImageUrl(productImages?.[0]?.src);
                setProductImageLayerId(productImages?.[0]?.id);
            })
            
        });
         
        return ()=>{
            if(subscription) subscription?.unsubscribe()
            if(tagsSubscription) tagsSubscription?.unsubscribe();
        }
    },[activeEditor,isAllAngleGroupActive])

    useEffect(()=>{
        // setBackgroundMode(BACKGROUND_MODE.BASIC);
        // setIsAllAngleGroupActive(true);
    },[])
    
    const formatSelectHandler = async (item) => {
        setSelectedFilter((prev) => ({
        ...prev,
        selectedFormat: item,
        imageFormat: [item?.title],
        }));
    };

    const onRemoveHandler = (item) => () => {
    if (selectedFilter.searchString.find((txt) => txt === item))
        setSelectedFilter((prev) => ({
        ...prev,
        searchString: prev.searchString.filter((obj) => obj !== item),
        }));
    else if (selectedFilter.colors.find((txt) => txt === item))
        setSelectedFilter((prev) => ({
        ...prev,
        colors: prev.colors.filter((obj) => obj !== item),
        }));
    };
    
    useEffect(()=>{
        if(!activeEditor) return;
        let sub = activeEditor.onChangeEmitter.asObservable().subscribe(x=>{
        if(activeEditor.originalSize.height > activeEditor.originalSize.width) {
            setSelectedFilter(filter=>({...filter,selectedFormat:FORMAT_TYPES[2],imageFormat:[FORMAT_TYPES[2].type]}));
            return;
        }
        
        if(activeEditor.originalSize.height < activeEditor.originalSize.width) {
            setSelectedFilter(filter=>({...filter,selectedFormat:FORMAT_TYPES[1],imageFormat:[FORMAT_TYPES[1].type]}))
            return;
        }

        setSelectedFilter(filter=>({...filter,selectedFormat:FORMAT_TYPES[0],imageFormat:[FORMAT_TYPES[0].type]}))
        })
        
        return () => {
            sub?.unsubscribe();
        }

    },[activeEditor])


    if(isVisualPrompt) return <VisualPrompt 
        productImage={visualPromptData.productImage}
        s3Url={visualPromptData.s3Url}
        setGenerating={visualPromptData.setGenerating}
        generating={visualPromptData.generating}
        basePrompt={visualPromptData.prompt}
        setUrls={visualPromptData.setUrls}
        baseUrls={visualPromptData.baseUrls}
        backgroundImage={visualPromptData.backgroundImage}
        setIsVisualPrompt={setIsVisualPrompt}
        backgroundBasedAIgeneration={true}
    />

    if(isAIBackgrounds) return <AIBackgrounds setIsAIBackgrounds={setIsAIBackgrounds} setAttributePage={setAttributePage} attributePage={attributePage} />

    return <> <div style={styles.container}>
        <TemplatesHeader 
           templatesResult={templatesResult}
           templatesResultIsLoading={templatesResultIsLoading} 
           backgroundMode={backgroundMode}
           setBackgroundMode={setBackgroundMode}
           prespectiveList={prespectiveList}
           prespectiveListLoading={prespectiveListLoading}
           productImageLayerId={productImageLayerId}
           disableResultBar = "true"
        />
        {<AIBackgroundsBanner onClick={()=>bannerOnClick()} />}
        {backgroundMode !== BACKGROUND_MODE.SELECTANGLE && (
        <>
            {templateType !== TEMPLATE_TYPE.RECENT &&
                templateType !== TEMPLATE_TYPE.FAVOURITE && 
                <TemplateBottomSection
                selectedFilter={selectedFilter}
                onRemoveHandler={onRemoveHandler}
            />}
            </>
        )}
        <div style={styles.divider}/>

        {(templateType === TEMPLATE_TYPE.RECENT ||
            templateType === TEMPLATE_TYPE.FAVOURITE || Object.keys(selectedFilter?.prespective).length >= 1) && 
            <FilteredTemplateTopPane
            selectedFilter={selectedFilter}
            formatSelectHandler={formatSelectHandler}
            setTemplateType={setTemplateType}
            templateType={templateType}
            backgroundMode={backgroundMode}
        />}
        <FilteredTemplates 
           templatesResult={templatesResult}
           templatesResultIsLoading={templatesResultIsLoading} 
           backgroundMode={backgroundMode}
           setBackgroundMode={setBackgroundMode}
           prespectiveList={prespectiveList}
           prespectiveListLoading={prespectiveListLoading}
           productImageLayerId={productImageLayerId}
           setIsVisualPrompt={setIsVisualPrompt}
           setVisualPromptData={setVisualPromptData}
        />
        {/* <RecentTemplates /> */}
    </div>
    </>
}

const styles = {
    container : { 
        height:"100%", display:'flex', flexDirection:"column"
    },
    divider: {
        height: "0.5px",
        background: "rgba(255, 255, 255, 0.24)"
    }
}