import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { STEPS, UPLOAD_STATUS } from "src/utils/constant";
/* 
Sample DS

{
  name: "Sample",
  files: { _id: {_id: number, imageUrl: string load: boolean}},
  folders: { _id: {_id: number, files: string[] name: string}, upload: boolean}
}
*/
const initialState = {
  name: "",
  title: "Create Project",
  files: {},
  fileUploadId: null,
  status: UPLOAD_STATUS.INIT,
  pendingFolderUploads: [],
  projectCreated: false,
  folders: {},
  showDialog: false,
  step: STEPS.INIT,
  fileUploadStep:STEPS.FILE_UPLOAD,
  folderUploadStep:STEPS.FOLDER_UPLOAD,
  failedImagesInFolders: {},
  linkedAccountuploadOptions:{},
  selectedLinkedAccountProjectList:[],
  linkedAccountStep:STEPS.LINKED_ACCOUNT,
  googleDriveCustomPreset:[],
  googleDriveFiles:[],
  selectedGDCustomPreset:{},
  googleDriveUserLink:"",
  googleDriveStep:STEPS.GOOGLE_DRIVE,
  csvFileStep:STEPS.CSV_FILE,
  linkedAccountType:"",
  selectedMarketPlaceId:"",
  uploadedSuccesfully:0,
  userGoogleDriveFilesName:'',
  userGDFileImageLength:0,
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    updateFileUploadId(state, action) {
      state.fileUploadId = action.payload;
    },
    updateProjectCreate(state, action) {
      state.projectCreated = action.payload;
    },
    updateTitle(state, action) {
      state.title = action.payload;
    },
    updateStep(state, action) {
      state.step = action.payload;
    },
    updateShowDialog(state, action) {
      state.showDialog = action.payload;
    },
    addPendingFolderUploads(state, action) {
      state.pendingFolderUploads.push(action.payload);
    },
    removePendingFolderUploads(state, action) {
      state.pendingFolderUploads = state.pendingFolderUploads.filter(
        (p) => p !== action.payload
      );
    },
    updateProjectName(state, action) {
      state.name = action.payload;
    },
    updateStatus(state, action) {
      state.status = action.payload;
    },
    resetFolderProgress(state, action) {
      state.folders[action.payload.id] = {
        ...state.folders[action.payload.id],
        load: true,
        progress: 0,
      };
    },
    updateFolderProgress(state, action) {
      state.folders[action.payload.id] = {
        ...state.folders[action.payload.id],
        progress:
          state.folders[action.payload.id].progress + action.payload.progress,
      };
    },
    startFolderProjectCreate(state, action) {
      state.folders[action.payload.id] = {
        ...state.folders[action.payload.id],
        progress: 20,
        valueBuffer: 20,
        uploading: true,
      };
    },
    endFolderProjectCreate(state, action) {
      state.folders[action.payload.id] = {
        ...state.folders[action.payload.id],
        uploading: false,
        progress: 100,
      };
    },
    updateFolders(state, action) {
      state.folders[action.payload.id] = {
        ...state.folders[action.payload.id],
        load: action.payload.load,
        files: [
          ...state.folders[action.payload.id].files,
          ...action.payload.files,
        ],
      };
    },
    startFolderUpload(state, action) {
      state.folders[action.payload.id] = {
        id: action.payload.id,
        files: [],
        name: action.payload.name,
        progress: action.payload.progress,
        valueBuffer: action.payload.progress,
        load: true,
        uploading: false,
      };
    },
    endFolderUpload(state, action) {
      state.folders[action.payload.id] = {
        id: action.payload.id,
        files: action.payload.files,
        name: action.payload.name,
        progress: 100,
        load: false,
        uploading: false,
      };
    },
    addFailedFilesInFolderUpload(state, action) {
      state.failedImagesInFolders[action.payload.id] = {
        id: action.payload.id,
        name: action.payload.name,
      };
    },
    removeFailedImageInFolders(state, action) {
      delete state.failedImagesInFolders[action.payload.id];
    },
    updateFile(state, action) {
      state.files[action.payload.id] = {
        ...state.files[action.payload.id],
        ...action.payload,
      };
    },
    updateFileUploadProgress(state, action) {
      state.files[action.payload.id] = {
        ...state.files[action.payload.id],
        progress: action.payload.progress,
      };
    },
    startFileUpload(state, action) {
      state.files[action.payload.id] = {
        load: false,
        progress: 20,
        valueBuffer: 20,
        failed: false,
      };
    },
    endFileUpload(state, action) {
      state.files[action.payload.id] = {
        id: action.payload.id,
        imageUrl: action.payload.imageUrl,
        load: true,
        failed: false,
      };
    },
    deleteFolder(state, action) {
      delete state.folders[action.payload];
    },
    deleteFile(state, action) {
      delete state.files[action.payload.id];
    },
    reset(state) {
      state.files = {};
      state.folders = {};
      state.name = "";
      state.title = "Create Project";
      state.step = STEPS.INIT;
      state.pendingFolderUploads = [];
      state.fileUploadId = null;
      state.status = UPLOAD_STATUS.INIT;
      state.linkedAccountuploadOptions = {};
      state.selectedLinkedAccountProjectList = [];
      state.linkedAccountStep = STEPS.LINKED_ACCOUNT;
      state.linkedAccountType = "";
      state.selectedMarketPlaceId = "";
      state.uploadedSuccesfully = 0;
      state.googleDriveStep = STEPS.GOOGLE_DRIVE;
      state.csvFileStep = STEPS.CSV_FILE;
      state.googleDriveUserLink = "";
      state.selectedGDCustomPreset = {};
      state.googleDriveFiles = [];
      state.userGoogleDriveFilesName = '';
      state.googleDriveInstruction = {instructionText:'',instructionFile:null};
      state.userGDFileImageLength = 0;
      state.fileUploadStep=STEPS.FILE_UPLOAD;
      state.folderUploadStep = STEPS.FOLDER_UPLOAD;
    },
    LinkedAccountuploadOptions(state, action){
      state.linkedAccountuploadOptions=action.payload;
    },
    setSelectedLinkedAccountProjectList(state,action){
      state.selectedLinkedAccountProjectList=action.payload;
    },
    setFileUploadStep(state,action){
      state.fileUploadStep = action.payload;
    },
    setFolderUploadStep(state,action){
      state.folderUploadStep = action.payload;
    },
    setLinkedAccountStep(state,action){
      state.linkedAccountStep=action.payload;
    },
    setGoogleDriveStep(state,action){
      state.googleDriveStep=action.payload;
    },
    setCsvFileStep(state,action){
      state.csvFileStep=action.payload;
    },
    setLinkedAccountType(state,action){
      state.linkedAccountType=action.payload;
    },
    setMarketPlaceId(state,action){
      state.selectedMarketPlaceId=action.payload;
    },
    setUploadedSuccesfully(state,action){
      state.uploadedSuccesfully=action.payload;
    },
    setGoogleDriveUserLink(state,action){
      state.googleDriveUserLink=action.payload;
    },
    setGoogleDriveCustomPreset(state,action){
      state.googleDriveCustomPreset = action.payload;
    },
    setSelectedGDCustomPreset(state,action){
      state.selectedGDCustomPreset = action.payload;
    },
    setGoogleDriveFiles(state,action){
      state.googleDriveFiles = action.payload;
    },
    setGoogleDriveInstruction(state, action){
      state.googleDriveInstruction = action.payload;
    },
    setUserGoogleDriveFilesName(state,action){
      state.userGoogleDriveFilesName = action.payload;
    },
    setUserGDFileImageLength(state,action){
      state.userGDFileImageLength = action.payload;
    },
  },
});

// Reducers
export default slice.reducer;

// Actions
export const {
  updateProjectName,
  endFileUpload,
  startFileUpload,
  deleteFile,
  deleteFolder,
  reset,
  startFolderUpload,
  endFolderUpload,
  updateFolderProgress,
  startFolderProjectCreate,
  endFolderProjectCreate,
  addPendingFolderUploads,
  updateShowDialog,
  updateStep,
  updateTitle,
  updateProjectCreate,
  updateFileUploadProgress,
  updateFile,
  removePendingFolderUploads,
  updateStatus,
  updateFileUploadId,
  addFailedFilesInFolderUpload,
  removeFailedImageInFolders,
  saveFailedUploadFilesInFolders,
  updateFolders,
  resetFolderProgress,
  LinkedAccountuploadOptions,
  setSelectedLinkedAccountProjectList,
  setLinkedAccountStep,
  setGoogleDriveStep,
  setCsvFileStep,
  setLinkedAccountType,
  setMarketPlaceId,
  setUploadedSuccesfully,
  setGoogleDriveUserLink,
  setGoogleDriveFiles,
  setGoogleDriveCustomPreset,
  setSelectedGDCustomPreset,
  setGoogleDriveInstruction,
  setUserGoogleDriveFilesName,
  setUserGDFileImageLength,
  setFileUploadStep,
  setFolderUploadStep,
} = slice.actions;
