import { Icon } from "src/modules/ui-elements/Icon";
import { css } from "@emotion/react";
import { Loader } from "@/modules/editor-v2/MiddleSection/EditorTopToolBar";
import { useRef } from "react";

export const DefaultTypes = {
  primary: "primary",
  secondary: "secondary",
  dark: "dark",
  voilet: "voilet",
  grey:'grey',
  darkGrey:'darkGrey',
  white_with_border:'white_with_border',
  white_with_light_border:'white_with_light_border',
  black_with_white_border:'black_with_white_border',
  pink_with_border:'pink_with_border',
  dark_with_justification: 'dark_with_justification',
  violet_with_border: 'violet_with_border'
};

const CustomizedButton = ({
  type = "primary",
  btnText = null,
  startIcon = null,
  endIcon = null,
  btnStyle = {},
  btnTextStyle = {},
  startIconStyle = {},
  endIconStyle = {},
  disabled = false,
  isActive=false,
  onClick = () => {},
  isLoading=false,
  loaderSize=20,
  startIconHeight = 16,
  startIconWidth = 16,
  endIconHeight = 16,
  endIconWidth = 16,
  loaderColor='dark',
  buttonRef = useRef(),
  onMouseEnter=()=>{}, 
  onMouseLeave=()=>{}
}) => {
  const typeBasedStyles = getCorrespondingStyleObject(type, disabled, isActive);

  return (
    <div
      css={typeBasedStyles.container}
      style={{ ...styles.container, ...btnStyle }}
      ref={buttonRef}
      onClick={disabled ? ()=>{} : onClick}
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}
    >
      {!isLoading && startIcon && (
        <Icon
          src={startIcon}
          height={startIconHeight}
          width={startIconWidth}
          style={{ ...startIconStyle }}
        />
      )}
      {!isLoading && <div css={styles.btnStyle} style={{...btnTextStyle}}>{btnText}</div>}
      {isLoading && <Loader height={loaderSize} width={loaderSize} type={loaderColor} />}
      {!isLoading && endIcon && (
        <Icon
          src={endIcon}
          height={endIconHeight}
          width={endIconWidth}
          style={{ ...endIconStyle }}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    gap: "10px",
    fontSize: "14px",
  },
  btnStyle:css`
    align-self: end;
    font-family: Objectivity;
    letter-spacing: 0.03em;
    text-align: center;
    margin-top: 4px;
    `,
};

function getCorrespondingStyleObject(type, disabled, isActive) {
  let container;

  switch (type) {
    case DefaultTypes.primary: {
      container = css`
        background-color: #f9f9f9;
        color: rgba(38, 38, 38, 0.8);
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.secondary: {
      container = css`
        background-color: rgb(0,0,0,${disabled ? 0.5 : 1});
        color: #fff;
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.dark: {
      container = css`
        background-color: rgb(0,0,0,${disabled ? 0.5 : 1});
        color: #fff;
        border-radius:4px;
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.voilet: {
      container = css`
        background-color: #5f2ee5;
        color: #fff;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.grey: {
      container = css`
        background-color: #EEE;
        border-radius:4px;
        color: #262626;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.white_with_border: {
      container = css`
        background-color: #FFF;
        border-radius:4px;
        color: #262626;
        border: 1px solid #262626;
        font-weight:600;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.white_with_light_border: {
      container = css`
        background-color: #FFF;
        border-radius:4px;
        color: #262626;
        border: 1px solid #EEE;
        font-weight:400;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
      `;
      break;
    }
    case DefaultTypes.black_with_white_border: {
      container = css`
        background-color: rgba(255, 255, 255, 0.02);
        border-radius:4px;
        color: #EEE;
        border: 1px solid #EEE;
        font-weight:400;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
        &:hover{
          background:#FFFF;
          color: black !important;
        }
      `;
      break;
    }
    case DefaultTypes.pink_with_border: {
      container = css`
        background: linear-gradient(136deg, #F03750 0%, #EB2E83 100%);
        color:#fff;
        background-clip: ${isActive ? '' : 'text'};
        -webkit-background-clip: ${isActive ? '' : 'text'};
        -webkit-text-fill-color: ${isActive ? '' : 'transparent'};
        border-radius:4px;
        border: 1px solid #EB2E83;
        font-weight:600;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
        &:hover{
          background-clip:${disabled ? '' : 'content-box'};
          -webkit-background-clip:${disabled ? '' : 'content-box'};
          -webkit-text-fill-color:${disabled ? '#eee' : '#fff'};
          background: ${disabled ? '' : 'linear-gradient(136deg, #F03750 0%, #EB2E83 100%)'};
        }
      `;
      break;
    }
    case DefaultTypes.violet_with_border: {
      container = css`
        background: linear-gradient(92deg, #5F2EE5 -9.99%, #9F35F0 120.27%);
        color:#fff;
        background-clip: ${isActive ? '' : 'text'};
        -webkit-background-clip: ${isActive ? '' : 'text'};
        -webkit-text-fill-color: ${isActive ? '' : 'transparent'};
        border-radius:4px;
        border: 1px solid #9F35F0;
        font-weight:600;
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? "unset" : "pointer"};
        &:hover{
          background-clip:${disabled ? '' : 'content-box'};
          -webkit-background-clip:${disabled ? '' : 'content-box'};
          -webkit-text-fill-color:${disabled ? '#eee' : '#fff'};
          background: ${disabled ? '' : 'linear-gradient(92deg, #5F2EE5 -9.99%, #9F35F0 120.27%)'};
        }
      `;
      break;
    }
    case DefaultTypes.darkGrey:{
      container = css`
       background:rgba(238, 238, 238, 0.24);
       color:rgba(255, 255, 255, 0.70);
       border-radius:4px;
       font-weight:500;
       opacity: ${disabled ? 0.5 : 1};
       cursor: ${disabled ? "unset" : "pointer"};
       `
       break;
    }
    case DefaultTypes.dark_with_justification:{
      container = css`
       background-color: black;
       color: #fff;
       opacity: ${disabled ? 0.5 : 1};
       cursor: ${disabled ? "unset" : "pointer"};
       justify-content: flex-start !important;
       margin: 4px;
       border-radius: 8px;
       &:hover{
        background:rgba(238, 238, 238, 0.24);
      }
       `
       break;
    }
    
  }

  return { container };
}

export default CustomizedButton;
