import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useRef, useState } from "react";
// utils
import axios from "src/utils/axios";
import { createGuest, isValidToken, setOtpData, setSession } from "src/utils/jwt";
import { get } from "lodash";
import { EncryptMessage } from "src/utils/cryptoEncrypt";
import { nanoid } from "nanoid";
import LoadingScreen from "@/components/LoadingScreen";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isOtpVerified: false,
  isInitialized: false,
  isSocialLogin:false,
  isLoadingScreen:false,
  user: null,
  verifyData: null,
  userInfo:null,
  loginErrorObj:{},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isOtpVerified, user, verifyData } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isOtpVerified,
      isInitialized: true,
      user,
      verifyData,
    };
  },
  IsSocialLogin: (state, action) => {
    const {isInProcess} = action.payload;
    return {
      ...state,
      isSocialLogin:isInProcess,
      loginErrorObj:{},
    }
  },
  IsLoadingScreen: (state, action) => {
    const {screenloading} = action.payload;
    return {
      ...state,
      isLoadingScreen:screenloading,
    }
  },

  LOGIN: (state, action) => {
    const { verifyData, isOtpVerified } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isOtpVerified,
      user: null,
      verifyData,
      loginErrorObj:{},
    };
  },
  VERIFY_OTP: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isOtpVerified: false,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isOtpVerified: false,
    user: null,
    verifyData: null,
    userInfo:null,
    loginErrorObj:{},
  }),
  UPDATE_USER: (state, action) => {
    let payload = {
      ...state,
      user: action.payload,
    };
    return payload;
  },
  RESET_USER: (state, action) => {
    const { isOtpVerified } = action.payload;
    return {
      ...state,
      isOtpVerified,
    };
  },
  USER_TYPE: (state, action) => {
    return {
      ...state,
      userInfo:action.payload,
    }
  },
  LOGIN_ERROR: (state, action) => {
    return {
      ...state,
      loginErrorObj:action.payload,
    }
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  isSocialLoginHandler: ()=>{},
  IsScreenLoadingHandler: ()=>{},
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verifyPassword: () => Promise.resolve(),
  verifyOtp: () => Promise.resolve(),
  clearOtpData: () => Promise.resolve(),
  updateUserData: () => Promise.resolve(),
  loginMobile: () => Promise.resolve(),
  loginSocialService: () => Promise.resolve(),
  microsoftLoginRedirect: () => Promise.resolve(),
  deleteUserAccount: () => Promise.resolve(),
  initUserSession: (token,otpData) => Promise.resolve(),
  refreshUserDetails: () => Promise.resolve(),
  magicQrLogin: (data) => Promise.resolve(),
});



// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, _dispatch] = useReducer(reducer, initialState);
  const dispatch = (action) => {
    _dispatch(action)
  }
  const guestFetchRef = useRef(null);
  let isFetching = false;
  let fetchPromise = null;

  const initUserSession = async (accessToken, otpData) => {
    // alert("Initializing user session!");
    const query = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if(query.accessToken) {
        accessToken = query.accessToken;
    }

    try {
      if (accessToken) {
        await setSession(accessToken);
        await refreshUserDetails();

      } else {
        if (otpData) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              isOtpVerified: true,
              user: null,
              verifyData: otpData,
            },
          });
        } else {
         


          if(localStorage.getItem('accessToken')) return;
          if(guestFetchRef.current)return;
          guestFetchRef.current = true; 
          let data = await createGuest();
          if(data?.token) localStorage.setItem("accessToken",data?.data?.token)

          await initUserSession(data?.data?.token);
          // dispatch({
          //   type: "INITIALIZE",
          //   payload: {
          //     isAuthenticated: false,
          //     isOtpVerified: false,
          //     user: null,
          //     verifyData: null,
          //   },
          // });
          guestFetchRef.current = null; 
          return;
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          isOtpVerified: false,
          user: null,
          verifyData: null,
        },
      });
      guestFetchRef.current = null;     }

      return {success:true}
  };

  const [loadingScreen, setLoadingScreen] = useState(false);


  const login = async (email) => {
    try{
    const currLang = localStorage.getItem("Selected_Language");
    const response = await axios.post("/v4/users/user/sendotpToGuestUser", {
      email,
      mode: "web",
      language: (currLang && currLang !== undefined) ? currLang : "en",
    });
    if (response.status === 200 || response.status === 201) {
      const { data } = response.data;
      setOtpData(data);
      dispatch({
        type:"USER_TYPE",
        payload:data,
      });
      sessionStorage.setItem('loginType', JSON.stringify({type:"Email",email}));
      dispatch({
        type: "LOGIN",
        payload: {
          verifyData: data,
          isOtpVerified: true,
        },
      });
    }
  }catch(error){
    if(error.response?.data){
      dispatch({
        type:"LOGIN_ERROR",
        payload:error.response?.data
      });
    }
    throw error;
    }
  };
  const loginMobile = async (data) => {
    const phone=data.phone;
     const countryCode=data.countryCode;
     const currLang = localStorage.getItem("Selected_Language");
    const response = await axios.post("/v4/users/user/sendotpToGuestUser", {
      phone,
      countryCode,
      mode: "web",
      language: (currLang && currLang !== undefined) ? currLang : "en",

    });
    if (response.status === 200 || response.status === 201) {
      const { data } = response.data;
      
      setOtpData(data);
      dispatch({
        type:"USER_TYPE",
        payload:data,
      });
      sessionStorage.setItem('loginType', JSON.stringify({type:"Mobile",phoneNumber:phone,countryCode:countryCode}));
      dispatch({
        type: "LOGIN",
        payload: {
          verifyData: data,
          isOtpVerified: true,
          mode: "web",
        },
      });
    }
  };
  const loginSocialService = async (payload) => {
    try{
    const response = await axios.post("/v4/users/user/socialLoginGuestUser", payload);
    if (response.status === 200 || response.status === 201) {
    if(get(response.data, "data.token", "")){
        initUserSession(get(response.data, "data.token", ""), null);
        dispatch({
            type: "INITIALIZE",
            payload: {
                isAuthenticated: true,
                isOtpVerified: false,
                user: null,
                verifyData: response.data.data,
            },
        });
        }
    }
  }catch(error){
    throw error;
  }
  };

  const microsoftLoginRedirect = async (payload) => {
    try {
      const response = await axios.get("/v4/users/user/microsoftLoginRedirect");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  const verifyPassword=async(payload)=>{
    let encryptedPassword=EncryptMessage(payload.publicKey,payload.password);
    const currLang = localStorage.getItem("Selected_Language");
   const response = await axios.post("/v4/users/user/verify",{
      userId: payload.userId,
      preferredMethod: "password",
      language: (currLang && currLang !== undefined) ? currLang : "en",
      long: 0,
      lat: 0,
      passcode: encryptedPassword,
      mode: "web",
      firebaseId: "",
      version: 1,
      build: 1,
      deviceId: "01-23-45-67-89-AB",
      osType: "windows",
   });
   if (response.status === 200 || response.status === 201) {
    const { data } = response.data;
    setSession(data.token);
    setOtpData(null);
    initUserSession(get(data, "token", ""), null);
    dispatch({
      type: "VERIFY_OTP",
      payload: {
        user: null,
        isOtpVerified: false,
        isAuthenticated: true,
      },
    });
  }
  }
  
  const verifyOtp = async (data) => {
    const currLang = localStorage.getItem("Selected_Language");
    const payload = {
        deviceId: localStorage.getItem('userUniqueId'),
        language: (currLang && currLang !== undefined) ? currLang : "en",
        mode: "web",
        version: 1,
        osType: "windows",
        build: 1,
        userId: data.userId,
        preferredMethod: "otp",
        loginChannel: data.otp,
        long: 0,
        lat: 0,
        firebaseId: "",
        jobIdForProTouch:data?.jobOrderId || '',
        source:data?.source || ''
    }
    if(data?.email){
      payload.email = data.email;
    }else{
      payload.phone = data.phone?.mobile;
      payload.countryCode = data.phone?.countryCode
    }
    const response = await axios.post("/v4/users/user/mergeGuestUserToUser",payload);

    if (response.status === 200) {
      const { data } = response.data;
      setSession(data.token);
      setOtpData(null);
      initUserSession(get(data, "token", ""), null);
      dispatch({
        type: "VERIFY_OTP",
        payload: {
          user: null,
          isOtpVerified: false,
          isAuthenticated: true,
        },
      });
      return data;
    }
  };

  const magicQrLogin = async (data) => {
    let userUniqueId = localStorage.getItem('userUniqueId');
    if(!userUniqueId) {
      userUniqueId = nanoid();
      localStorage.setItem('userUniqueId', userUniqueId);    
    } 
    const payload = {
        deviceId: userUniqueId,
        language: "en",
        mode: "web",
        version: 1,
        osType: "windows",
        build: 1,
        userId: data.userId,
        preferredMethod: "otp",
        loginChannel: data.otp,
        long: 0,
        lat: 0,
        firebaseId: "",
        jobIdForProTouch:data?.jobOrderId || '',
        source:data?.source || ''
    }
    if(data?.email){
      payload.email = data.email;
    }else{
      payload.phone = data.phone?.mobile;
      payload.countryCode = data.phone?.countryCode
    }
    let response = {};
    try{
    response = await axios.post("/v4/users/user/verify",payload);
    }catch(err){
      await setSession();
      await initUserSession();
      localStorage.setItem('magicQrInfo',false);
      localStorage.removeItem('magicQrInfo');
      window.location.href = window.location.origin + '/dashboard/homePage'
    }

    if (response.status === 200) {
      const { data } = response.data;
      await setSession(data.token);
      await refreshUserDetails();
      localStorage.setItem('magicQrInfo',false);
      localStorage.setItem('triggerMailEvent',true);
      localStorage.removeItem('magicQrInfo');
      sessionStorage.removeItem('login_flow');
      return data;
    }
  };

  const clearOtpData = async () => {
    setOtpData(null);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isOtpVerified: false,
        user: null,
        verifyData: null,
      },
    });
  };

  const logout = async () => {
    await setSession(null);
    const token = localStorage.getItem("accessToken");
    dispatch({ type: "LOGOUT" });
    localStorage.setItem("identifier_call",true);
    await initUserSession(token, null);
    window.location.reload();
  };

  const resetUserAuth = () => {
    setOtpData(null);
    dispatch({
      type: "RESET_USER",
      payload: {
        verifyData: null,
        isOtpVerified: false,
      },
    });
  };

  const refreshUserDetails = async () => {
  // If already fetching, return the existing promise
  if (isFetching)return fetchPromise;
  // Set the flag to indicate that a new fetch is in progress
  isFetching = true;
  // Create a new promise for the current fetch
  fetchPromise = new Promise((resolve, reject) => {
    axios
    .get("/v4/users/auth/user/get")
    .then(({ data }) => {
      // Reset the flag and promise on successful fetch
      if(!data?.data?.user?.login_type) return;
      isFetching = false;
      if (!data) resolve({});
      let isAuthenticateData=true;
      if (data?.data?.user?.login_type === "Guest") isAuthenticateData=false;
      else isAuthenticateData=true;
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: isAuthenticateData,
          isOtpVerified: false,
          user: data?.data,
          verifyData: null,
        },
      });
      resolve(data?.data);
    })
    .catch((e) => {
       // Reset the flag and promise on fetch error
       isFetching = false;
       console.error(e);
       reject(e);
      });
    });
    return fetchPromise;
  };

  const IsSocialLoginHandler = (data) =>{
    dispatch({
      type: "IsSocialLogin",
      payload: {
        isInProcess:data,
      },
    });
    
  };

  const IsScreenLoadingHandler = (data) => {
    dispatch({
      type: "IsLoadingScreen",
      payload: {
        screenloading: data,
        },
      });
  };

  const deleteUserAccount = async() => {
    await axios.delete('/v4/users/user/delete').then(async (response) => {
      if (response.status === 200) {
        dispatch({ type: 'LOGOUT' });
        await setSession(null);
        window.location.href = `${window.location.origin}/dashboard/homePage`;
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        isSocialLoginHandler:IsSocialLoginHandler,
        IsScreenLoadingHandler:IsScreenLoadingHandler,
        login,
        logout,
        verifyPassword,
        verifyOtp,
        clearOtpData,
        resetUserAuth,
        refreshUserDetails,
        loginSocialService,
        microsoftLoginRedirect,
        loginMobile,
        deleteUserAccount,
        initUserSession,
        magicQrLogin,
      }}
    >
      {loadingScreen && <LoadingScreen />}
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
