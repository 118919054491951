import { get } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "src/redux/store";
import axiosInstance from "src/utils/axios";

const initialState = {
    isModifyOpen: false,
    loading:false,
    error: null,
    success: null,
    completeShootDetail:{},
    confirmImageOrder:[],
    userDetail:{},
    projectName:"",
  }

  const slice = createSlice({
    name: "modify",
    initialState,
    reducers: {
      startLoading(state, action) {
        state.loading=true;
      },
      stopLoading(state, action){
        state.loading=false;
      },
      hasError(state, action){
        state.error=action.payload;
      },
      setModifyImageState(state, action){
        state.isModifyOpen=action.payload;
      },
      setModifySuccessMessage(state, action){
        state.success=action.payload;
      },
      setCompleteShootDetail(state,action){
        state.completeShootDetail = action.payload;
      },
      setConfirmImageOrder(state, action){
        state.confirmImageOrder = action.payload;
      },
      setUserDetail(state, action){
        state.userDetail = action.payload;
      },
      setProjectName(state,action){
        state.projectName=action.payload;
      },
      resetModifyImageReducer(state,action){
        state.isModifyOpen = false;
        state.loading = false;
        state.error = null;
        state.success = null;
        state.completeShootDetail = {};
        state.confirmImageOrder = [];
        state.userDetail = {};
        state.projectName = "";
      },
    }
})

export default slice.reducer;

export const { startLoading, stopLoading, setModifyImageState, setModifySuccessMessage, setCompleteShootDetail, hasError, setConfirmImageOrder, setUserDetail, setProjectName, resetModifyImageReducer } = slice.actions;

export const getCompleteShootDetail = async(jobId) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(
        `/v4/shootnewLifestyleImage/fetchClickedAngle-PreSignedURL?jobId=${jobId}`);

        dispatch(slice.actions.setCompleteShootDetail(response?.data?.data));
        dispatch(slice.actions.stopLoading());
      return response?.data?.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopLoading());
    }
  };