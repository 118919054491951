import axios from "@/utils/axios";
import { nanoid } from "nanoid";

export async function getAssets({queryKey:[_0,searchText]}) {
    let res:any;
    if(searchText)res = await axios.get(`/v4/assets?search=${searchText}`);
    else res = await axios.get(`/v4/assets`);
    return res.data;
}

export async function deleteAsset(id:string) {
    const res = await axios.delete(`/v4/assets/${id}`);
    return res.data;
}

export async function addAsset(asset:File) {
    const timestamp = new Date().getTime();
    const newName = `${timestamp}_${asset.name}`;//Allowing duplicate named asset to be reuploaded
    const formData = new FormData();
    formData.append("file", asset);
    formData.append("type", "image");
    formData.append("name", newName);
    const res = await axios.post("/v4/assets", formData);
    return res.data;
}

export async function addAssets(assets: File[]) {
    return await Promise.all(assets.map(asset => addAsset(asset)));
}

export async function removeAssetBackground(id:string) {
    const res = await axios.put(`/v4/assets/remove-background/${id}`);
    return res.data;
}


export async function addAssetFromUrl(url: string) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to download asset');
        }
        const blob = await response.blob();
        const filename = nanoid() + ".png"; // Generate a unique filename using nanoid
        const file = new File([blob], filename);
        return await addAsset(file);
    } catch (error) {
        console.error('Error adding asset:', error);
    }
}