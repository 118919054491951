import React from "react";
import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { TextField, Box, Drawer } from "@mui/material";
import {showSuccessProjectPopupAtom} from "../Atoms";
import { useAtomValue } from "jotai";
import { useSelector } from "react-redux";
export default function AddInstruction(props) {
    const isOpen = props?.isOpen;
    const { openHandler, instructionHandler } = props;
    const [inputValue, setInputValue] = useState('');
    const [instuctionsArray, setInstuctionsArray] = useState([]);
    const getSuccessProjectValue = useAtomValue(showSuccessProjectPopupAtom);
    const {entireStrapiData} = useSelector(state=>state.strapi);
    const strapiData = entireStrapiData?.SendForProTouchBE?.SendForProTouchBE;
    let styles = commonStyle(isOpen, inputValue);
    useEffect(()=>{
        styles = commonStyle(isOpen, inputValue) 
    }, [inputValue])
    const handleInputChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setInputValue(e.target.value);

    };

    const handleEnterKeyPress = (e) => {
        console.log("key event", e.key)
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (inputValue.trim() !== '') {
            const updatedInstruction = [...instuctionsArray, inputValue];
            setInstuctionsArray(updatedInstruction);
            instructionHandler(updatedInstruction);
            setInputValue('');
        }
    };
     const closeHandler = () =>{
        setInputValue(''); 
        openHandler();
     }
     useEffect(() => {
          setInstuctionsArray([])
      }, [getSuccessProjectValue]);

    return (<div>
        {/* {isOpen ? <div css={styles?.closeButton} onClick={closeHandler}>
        <img src="/icons/instructionClose.svg" />
    </div>: <></>} */}
    <Drawer open={isOpen} onClose={closeHandler} anchor="right" BackdropProps={{
        invisible: true
    }}  sx={{
        width: 378, 
        zIndex: 1500,
        flexShrink: 0,
        right: isOpen ? 0 : 'unset',
        ".MuiDrawer-paper":{
            borderRadius:'8px 0px 0px 8px',
        }
      }}>
        <div style={{width:"368px", zIndex: "15600", background: "white"}}>
        <div css={styles?.title}>
            {strapiData?.AddCommentText ||`Add Comment`}
        </div>
        <div css={styles?.subtitle1}>
            {strapiData?.WouldYouLikeText || `Would you like to give any special instructions for these images`}
        </div></div>
        <div css={styles?.instructionsContainer}>
            {instuctionsArray?.length > 0 ? (
                instuctionsArray.map((message, index) => (
                    <div key={index} css={styles?.message}>
                        {message}
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
        <div css={styles?.bottomContainer}>
            <div className="button"><input
                css={styles?.input}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleEnterKeyPress}
                placeholder={strapiData?.WriteAComment || "Write a comment..."}
                autoFocus
            /></div>

            <button
                css={[styles?.sendButton,inputValue.trim() !== '' && styles.pointer]}
                onClick={handleSubmit}
                type="button"
            >
                <img src={inputValue ? "/icons/send-light.svg" : "/icons/send.svg"} />
            </button>

        </div></Drawer></div>

    )
}
function commonStyle(isOpen, inputValue) {

    const styles = {
    pointer: css`
      cursor:pointer;
    `,
    instructionsContainer: css`
    position: absolute;
    bottom: 56px;
    width: 100%;
  `,
        title: css`
      color: #262626;
      text-align: center;
      font-family: "Objectivity";
      font-size: 164x;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      margin-top: 16px;
    `,
        subtitle1: css`
      color: #262626CC;
      text-align: center;
      font-family: "Objectivity";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid #eee;
        padding: 16px 0px;
    `,
        bottomContainer: css`
        width: 100%;
        height: 60px;
        flex-shrink: 0;
        display:flex;
        bottom: 0;
        position: absolute;
        justify-content: space-evenly;
        align-items: center;
        border-top: 1px solid #eee;
        padding: 16px 0px;
    `,
        input: css`
        display: inline-flex;
        height: 40px;
        padding: 10px;
        align-items: center;
        flex-shrink: 0;
        background: #F9F9F9;
        border: none;
        width: 280px;
        :focus{
            outline: none;
        }
    `,
        sendButton: css`
        display: flex;
        width: 40px;
        height: 40px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 999px;
        border: none;
        background: ${inputValue ?  "linear-gradient(95.35deg, #F03750 18.9%, #EB2E83 91.1%)": "#F9F9F9"};
    `,
        message: css`
        padding: 16px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background: #F9F9F9;
        margin: 16px;
        max-width: 300px;
        word-wrap: break-word; 
     `,
        closeButton: css`
        display: flex;
        height: 56px;
        padding: 16px 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px 0px 0px 8px;
        background: #FFF;
        position: absolute;
        right: ${isOpen ? "368px" : "0px"};
        z-index:1500;
        transition-duration: 100;
        margin-top: 10px;
        width: max-content;

     `
    }
    return styles;
}
