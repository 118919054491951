import * as React from 'react';
import { css, jsx } from "@emotion/react";
import { Icon} from 'src/modules/ui-elements/Icon';
import { Loader } from '@/modules/editor-v2/MiddleSection/EditorTopToolBar';

export const ButtonTypes = {
    dark:"dark",
    primary:"primary",
    light: "light",
    darkBgWhiteText:"dark-bg-white-text",
    primaryLight:"primary-light",
    darkOutline:"dark-outline",
    lightAlternate:"light-alternate",
    pinkBgPinkText:"pink-bg-pink-text",
}

const DEFAULT_ICON_SIZE = 16;

const PrimaryButton = ({
    type=ButtonTypes.primary,
    icon=undefined,
    style={},
    text="",
    onClick = ()=>{},
    disabled = false,
    buttonRef = React.createRef(),
    isLoading = false,
    loaderHeight = 24,
    loaderWidth = 24,
    afterTextIcon = "",
    loaderStyle = {}
}) => {
    const typeBasedStyles = getCorrespondingStyleObject(type);
    return (
        <button ref={buttonRef} css={typeBasedStyles.container} style={{...styles.container(disabled), ...style}} onClick={onClick} disabled={disabled}>
            {icon && <div css={typeBasedStyles.iconContainer} style={styles.iconContainer}>
                <Icon src={icon} height={typeBasedStyles.iconHeight} width={typeBasedStyles.iconWidth}/>
            </div>}
            <div css={typeBasedStyles.textContainer} style={styles.textContainer}>
                {isLoading ? <Loader height={loaderHeight} width={loaderWidth} style={loaderStyle} /> : text } 
            </div>
            {afterTextIcon && <Icon src={afterTextIcon} height={typeBasedStyles.iconHeight} width={typeBasedStyles.iconWidth}/> }
        </button>)
}

const styles = {
    container:(disabled)=>({
        flexDirection:'row', display:'flex', alignItems:'center', cursor:'pointer',opacity:disabled ? 0.6 : 1,
    }),
    iconContainer: {
        display:'flex', 
        alignSelf:'center', 
    },
    textContainer: {
         display: "flex",alignSelf: "center",position: "relative", alignItems:'center'
    }
};

function getCorrespondingStyleObject(type) {
    let container, textContainer, iconContainer;
    let iconHeight = DEFAULT_ICON_SIZE, iconWidth = DEFAULT_ICON_SIZE;
    switch (type) {
        case ButtonTypes.dark: {
            container = css`background-color:#191919;
            padding: 6px 12px;
            border-radius: 4px;
            border: 0px solid transparent;
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height:170%;
            color:#FFFFFF50;
            border:1px solid #191919;
            transition: all 0.2s ease-in-out;
            &:hover{
                border:1px solid #e6e6e6;
                color:#FFFFFF50;
                background-color:transparent;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                transform: translate(0px, -1px);
                ${'' /* transform:scale(1.05); */}
            }`;
            iconContainer = css`
                margin-right:4px
            `
            break;
        }
        case ButtonTypes.primary: {
            container = css`
                background-color:#2A2A2A;
                padding: 4px 10px;
                border-radius: 4px;
                border: 0px solid transparent;
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color:#FFFFFF50;
            `
            textContainer = css`
                background: linear-gradient(97.2deg, #F03750 5.71%, #EB2E83 136.98%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                height: 24px;
                top:2px;
            `
            break;
        }
        case ButtonTypes.darkBgWhiteText: {
            container = css`
                background-color:#2A2A2A;
                padding: 4px 10px;
                border-radius: 4px;
                border: 0px solid transparent;
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color:#EEEEEE;
                border:1px solid #2A2A2A;
                transition: all 0.2s ease-in-out;
                &:hover{
                    border:1px solid #d6d6d6;
                    color:#EEEEEE;
                    backgroundColor:transparent;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    transform: translate(0px, -1px);
                    ${'' /* transform:scale(1.05); */}
                };
            `;
            textContainer = css`
                height: 24px;
                top:2px;
            `
            iconContainer = css`
                margin-right:6px;
            `;
            break;
        }
        case ButtonTypes.primaryLight: {
            container = css`
                background-color:#FFFFFF;
                padding: 8px 16px;
                border-radius: 4px;
                border: 0px solid transparent;
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 170%;
            `;
            textContainer = css`
                background: linear-gradient(97.2deg, #F03750 5.71%, #EB2E83 136.98%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            `
            iconContainer = css`
                margin-right:8px;
            `;
            break;
        }
        case ButtonTypes.light: {
            container = css`
                background-color:#FFFFFF;
                padding: 10px 0px;
                border-radius: 4px;
                border: 0px solid transparent;
                color: #262626;
                font-family: Objectivity;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                transition: all 0.2s ease-in-out;
                &:hover {
                    transform:scale(1.02);
                }
            `;
            textContainer = css`
                color: #262626;
                position:relative;
                top:2px;
            `
            iconContainer = css`
                margin-right:10px;
            `;
            iconHeight=22;
            iconWidth=22;
            break;
        }
        case ButtonTypes.darkOutline: {
            container = css`
                background-color:transparent;
                padding: 8px 16px;
                border-radius: 4px;
                border: 1px solid #262626;
                background: #FFF;
                color: #262626;
                text-align: center;
                font-family: Objectivity;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 170%; 
                justify-content: center;
            `;
            textContainer = css`
                color: #262626;
            `
            iconContainer = css`
                margin-right:10px;
            `;
            iconHeight=22;
            iconWidth=22;
            break;
        }
        case ButtonTypes.lightAlternate: {
            container = css`
                background-color:#FFFFFF;
                border-radius: 4px;
                border: 0px solid transparent;
                color: #262626;
                font-family: Objectivity;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                transition: all 0.2s ease-in-out;
                padding:10px;
                &:hover {
                    ${'' /* transform:scale(1.02); */}
                }
            `;
            textContainer = css`
                color: #262626;
                position:relative;
                top:3px;
            `
            iconContainer = css`
                margin-right:4px;
            `;
            iconHeight=16;
            iconWidth=16;
            break;
        }
        case ButtonTypes.pinkBgPinkText:{
            container = css`
                background: linear-gradient(158deg, rgba(240,55,80,0.1) 0%, rgba(235,46,131,0.1) 100%);                border-radius: 8px;
                border: 0px solid transparent;
                color: #262626;
                font-family: Objectivity;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                transition: all 0.2s ease-in-out;
                padding:10px;
                &:hover {
                    ${'' /* transform:scale(1.02); */}
                }
            `;
            textContainer = css`
                background:linear-gradient(158deg, #F03750 0%, #EB2E83 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position:relative;
                top:3px;
                font-weight:600;
                padding:2px 6px 2px 0px;
                `
            iconContainer = css`
                margin-right:4px;
                padding-left:6px;
            `;
            iconHeight=24;
            iconWidth=24;
            break;
        }
    }
    return {container,textContainer, iconContainer,iconHeight, iconWidth};
}

export default PrimaryButton;