import React from "react";


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        error: null, 
        errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <h2>Something went wrong in {this.props.name}.</h2>
            <div style={{fontSize:18,textDecoration:"underline",cursor:"pointer"}} onClick={()=>this.setState({error: null,errorInfo: null})}>retry</div>
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

  export default ErrorBoundary;