import { COVERAGE_AREA_LIST, IMAGE_POSITION_LIST } from "@/modules/batchEditor/BulkUploadEditor/constant";
import { atom } from "jotai";
import { CustomPackageQtyError, GroupJob, InstructionData, JobImages, PimProduct } from "../Types";

export const triggerJobGroupCreationAtom = atom(null);
export const imagesForLoadingAtom = atom(null);
export const jobGroupIdAtom = atom<string | string[]>("");
export const activeGroupJobAtom = atom<GroupJob>(null);
export const imagesDataAtom = atom<Array<JobImages>>([]);
export const imagesURLsAtom = atom<Array<string>>([]);
export const positionAtom = atom(IMAGE_POSITION_LIST[0]);
export const coverageAtom = atom(COVERAGE_AREA_LIST[4]);
export const promptAtom = atom("");
export const transactionIdAtom = atom("");
export const fetchGroupJobDataLoadingAtom = atom<Boolean>(false);
export const pimDetailsAtom = atom<PimProduct>(null);
export const currentJobDetailsAtom = atom<any>(null);
export const packageListDetailsAtom = atom<any>([]);
export const uploadedImagesUrlAtom = atom<any>([]);
export const addNewAngleLoaderAtom = atom<Boolean>(false);
export const listingScoreDataAtom = atom<any>([]);
export const instructionListDetailsAtom = atom<any>([])
export const pimSkuIdAtom = atom<string>("");
export const selectedImageDataAtom = atom([]);
export const createAnotherDataAtom = atom(null);
export const triggerAutoCsvDownloadWhenLoggedInAtom = atom<Boolean>(false);
export const triggerAutoPackageSubmissionWhenLoggedInAtom = atom(false);
export const packageSelectedDetailsAtom = atom(null);
export const instructionsPayloadAtom = atom<InstructionData>(null);
export const imagesIdAtom = atom([]);
export const dropDownAtom = atom([]);
export const callGetBackgrounds = atom(false);
export const heightAtom = atom(1350);
export const widthAtom = atom(1350);
export const showInstructionIconAtom = atom(false)
export const pimDataLoadingAtom = atom<Boolean>(false);
export const customizeLifestyleButtonAtom = atom(false);
export const allBrandNamesAtom = atom([]);
export const packageSelectionSubmissionLoaderAtom = atom(false);
export const triggerFetchOnPackageSelectionSubmissionAtom = atom(false);
export const addNewAngleFlagAtom = atom(false);
export const addRetouchAngleFromListingAtom = atom(false);
export const replaceJobAndDeleteExistingAtom = atom(false);
export const instructionsSubmittedAtom = atom(false);
export const addToListingClickedAtom = atom(false);
export const creditsRechargedSuccessAtom = atom(false);
export const lifeStyleCreationStartedAtom = atom(false);
export const isPimDataChangedAtom = atom(false);
export const lifeStyleGenerateImagesAtom = atom([]);
export const lifestyleBackgroundLoaderAtom = atom(false);
export const localPimDataForExportAtom = atom({});
export const packageSubmitWithCreditsAtom = atom(false);
export const selectedBrandKit = atom([]);
export const isImageDeleteClicked = atom(false);
export const createAnotherListingPopupAtom = atom(false);
export const updatingPimDataAtom = atom(false);
export const savedBrankitNameAtom = atom(""); 
export const jobClosureDateAtom = atom(null);
export const selectedAnglesForRetouchingAtom = atom(0);
export const clickedInfograpgicsFromListingPopupAtom = atom(false);
export const retouchPackageCreditsAmountAtom = atom(0);
export const disableDownloadCsvButtonAtom = atom(false);
export const triggerSendForRetouchAtom = atom(false);
export const sendForRetouchSubmittedAtom = atom(false);
export const crossLifestylePopupAtom = atom(false);
export const generateMoreLoadersAtom = atom(false);
export const isDownloadCsvButtonClickedAtom = atom(false);
export const triggerDeleteOnCloseAtom = atom(false);
export const disableCloseUntillJobCreated = atom(false);
export const lifestyleCustomizeDataAtom = atom({});
export const instructionsSaveSubmitLoaderAtom = atom(false);
export const onLogoClickAtom = atom(false);
export const isBackgroundsDataEmptyAtom = atom(false);
export const regenerateLoadingAtom = atom(false);
export const isLoginInButtonClickedAtom = atom(false);
export const togglePimDropdownAtom = atom(null);
export const openFileUploaderAtom = atom(false);
export const customPackageAtomJourneyAtom = atom(false);
export const customPackageAtom = atom(false);
export const customPackageCountAtom = atom({});
export const subCategoryListBasedOnCategory = atom([]);
export const subCategoryChangeLoaderAtom = atom(false);
export const customPackageDropDownAtom = atom({
  "Product on Lifestyle": false,
  "Product on Model": false,
  "Infographics": false,
  "Size Chart": false,
  "Dimension Chart": false
});
export const selectedQuantitiesAtom = atom({
  "Product on Lifestyle": 0,
  "Product on Model": 0,
  "Infographics": 0,
  "Size Chart": 0,
  "Dimension Chart": 0
});
export const customPackageImageQtyAtom = atom({});
export const customPackageQtyErrorAtom = atom<CustomPackageQtyError>({
  lifestyle: false,
  infographics: false
});
export const customPackageJourneyLoaderAtom = atom<boolean>(false);
export const openCustomPackageMenuAtom = atom<boolean>(false);
export const customPackageQtyAtom = atom({});
export const totalCreditsCustomPerPackageAtom = atom({});
