import API_SERVICE from "src/utils/axios";

const GET_JOB_BY_ID = "v3/exploreTemplates/getJobData";
const GET_COMPLTED_JOBS = "v3/exploreTemplates/jobSearchList";
const GET_EDITOR_DATA = "v3/exploreTemplates/createJobFromTemplate"

export const getJobById = (jobId) => {
  return API_SERVICE.get(`${GET_JOB_BY_ID}?jobId=${jobId}`);
};

export const getCompletedJobs = (skip=0,limit=10,status='Download',searchStr="") => {
  return API_SERVICE.get(`${GET_COMPLTED_JOBS}?skip=${skip}&limit=${limit}&searchStr=${searchStr}&status=${status}`);
};

export const getEditorJobId = ({jobId,imageId,templateId}) => {
  if(jobId && imageId && templateId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?jobId=${jobId}&imageId=${imageId}&templateId=${templateId}&isEditorJob=true`);
  }else if(imageId && templateId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?imageId=${imageId}&templateId=${templateId}&isEditorJob=true`);
  }else if(jobId && templateId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?jobId=${jobId}&templateId=${templateId}&isEditorJob=true`);
  }else if(jobId && imageId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?jobId=${jobId}&imageId=${imageId}&isEditorJob=true`);
  }else if(templateId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?templateId=${templateId}&isEditorJob=true`);
  }else if(imageId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?imageId=${imageId}&isEditorJob=true`);
  }else if(jobId){
    return API_SERVICE.post(`${GET_EDITOR_DATA}?jobId=${jobId}&isEditorJob=true`);
  }
}