import { useAtomValue } from "jotai";
import {
  backgroundFilterAtom,
  backgroundFilterProductImageIdAtom,
  backgroundFilterTemplateTypeAtom,
} from "../modules/editor-v2/Atoms";
import { getTemplatesData } from "../modules/editor-v2/services/MyWorks";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

export function useCustomTemplateQuery() {
  const debounceRef = useRef(null);
  const selectedFilter = useAtomValue(backgroundFilterAtom);
  const templateType = useAtomValue(backgroundFilterTemplateTypeAtom);
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const [filteredPayload, setFilteredPayload] = useState([]);

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setFilteredPayload([
        "filteredTemplates", 
        "count",
        selectedFilter?.searchText,
        selectedFilter?.searchString,
        templateType,
        selectedFilter?.imageFormat,
        selectedFilter?.colors,
        selectedFilter?.hotspotOnly,
        productImageId,
        selectedFilter?.prespective?.angleGroupId,
        selectedFilter?.updateRecentSearch,
        selectedFilter?.isInfographics,
      ]);
    }, 400);
  }, [
    selectedFilter?.searchText,
    selectedFilter?.searchString,
    templateType,
    selectedFilter?.imageFormat,
    selectedFilter?.colors,
    selectedFilter?.hotspotOnly,
    productImageId,
    selectedFilter?.prespective?.angleGroupId,
    selectedFilter?.updateRecentSearch,
    selectedFilter?.isInfographics,
  ]);

  const {
    data:templatesResult,
    error:templatesResultError,
    status:templatesResultStatus,
    isLoading:templatesResultIsLoading,
  } = useQuery(filteredPayload, getTemplatesData, {
    // enabled: filteredPayload.length > 0,
  });

  return {
    templatesResult,
    templatesResultError,
    templatesResultStatus,
    templatesResultIsLoading
  };
}
