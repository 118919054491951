import API_SERVICE from "src/utils/axios";

const GENERATE_JOB_LINK = "/v1/client-api/get-cdn";
const GET_USERS_JOB_COUNT = "/v3/exploreTemplates/usersJobCount";
const CONFIRM_JOB_ORDER = "/v5/shootnewLifestyleImage/checkCostAndConfirmJob";
const UPDATE_JOB_ORDER_NAME = "v3/shootsetupjobs/jobName-Update";
const GET_BG_IMAGES = "/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages";
const COMPLETE_SHOOT_NOTIFICATION = "/v3/exploreTemplates/notifyRejectShoot";
const DELETE_PROJECT_SHOOT = "/v3/shootsetupjobs/jobStatus-deleteOrSeen";
const GET_LINK_FOR_MULTIPLE_JOBS = "/v4/jobOrders/zip-multiple-jobs";
const CREATE_ADAPTATION = "/v4/shootnewLifestyleImage/createJobAndFetchAngles";
const GET_CURR_JOB_DETAILS = "/v3/exploreTemplates/getJobData";
const GET_MARKETPLACE_SYNC_ENTRIES = '/v4/marketplaceSync/entries';
const GET_LINK_FOR_MULTIPLE_JOBS_CSV = "/v4/jobOrders/multi-jobs-csv-data";
const GET_LINK_FOR_JOB_CSV = "/v4/jobOrders/job-csv-data";
const CONFIRM_CSV_FORMAT = '/v4/csv-format/create';
const GET_CSV_FORMAT = '/v4/csv-format/get-formats';
const GET_CSV_DATA = '/v4/csv-format/get-data';
const GET_COMPLETED_JOB_IMAGE = '/v3/shootsetupjobs/getAll-angleImage';
const GET_JOB_IMAGE = '/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages'

export const getJobLink = (jobId) => {
  return API_SERVICE({
    url: `${GENERATE_JOB_LINK}/${jobId}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL_V2 || "",
  });
};

export const getBgImages = (jobId) => {
  return API_SERVICE({
    url: `${GET_BG_IMAGES}?job_id=${jobId}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
  });
};

export const confirmJobOrder = (payload) => {
  return API_SERVICE({
    url: CONFIRM_JOB_ORDER,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "PUT",
    data: payload,
  });
};

export const updateJobOrderName = (jobId, jobName) => {
  return API_SERVICE({
    url: `${UPDATE_JOB_ORDER_NAME}?jobId=${jobId}&jobName=${jobName}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "PUT",
  });
};

export const getUsersJobCount = () => {
  return API_SERVICE({
    url: GET_USERS_JOB_COUNT,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
  });
};

export const deleteProjectShoot = (payload) => {
  return API_SERVICE({
    url: DELETE_PROJECT_SHOOT,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "PUT",
    data: payload,
  });
};

export const completedShootNotification = (payload) => {
  return API_SERVICE({
    url: COMPLETE_SHOOT_NOTIFICATION,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const getLinkUrlForMultipleJobs = (payload) => {
  return API_SERVICE({
    url: GET_LINK_FOR_MULTIPLE_JOBS,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const createAdaptation = (payload) => {
  return API_SERVICE({
    url: CREATE_ADAPTATION,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const getCurrentJobDetails = (jobId) => {
    if(!jobId) return {error: "jobId required"};
    return API_SERVICE({
      url: `${GET_CURR_JOB_DETAILS}?jobId=${jobId}`,
      baseURL: process.env.NEXT_PUBLIC_API_URL || "",
      method: "GET"
    });
  };

export const getMarketplaceSyncEntriesApi = (skip = 0, limit = 20) => {
  return API_SERVICE({
    url: `${GET_MARKETPLACE_SYNC_ENTRIES}/${skip}/${limit}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
  })
}

export const getLinkForMultipleJobsCSV = (payload) => {
  return API_SERVICE({
    url: GET_LINK_FOR_MULTIPLE_JOBS_CSV,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const getLinkForJobCSV = (jobId) => {
  return API_SERVICE({
    url: `${GET_LINK_FOR_JOB_CSV}/${jobId}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
  });
};

export const getCsvFormat = (payload) => {
  return API_SERVICE({
    url: GET_CSV_FORMAT,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  })
};

export const updateCsvFormat = (payload) => {
  return API_SERVICE({
    url: `${CONFIRM_CSV_FORMAT}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const getCsvFormatData = (payload) => {
  return API_SERVICE({
    url: `${GET_CSV_DATA}`,
    baseURL: process.env.NEXT_PUBLIC_API_URL || "",
    method: "POST",
    data: payload,
  });
};

export const jobOrderImageById = async(jobId,status="COMPLETED",skip=0,limit=5) => {
  if (status === "COMPLETED") {
    const { data } = await API_SERVICE({
      url: `${GET_COMPLETED_JOB_IMAGE}/${jobId}?status=${status}&skip=${skip}&limit=${limit}`,
      baseURL: process.env.NEXT_PUBLIC_API_URL || "",
      method: "GET",
    })
    
    return data.data;
  } else {
    const { data } = API_SERVICE({
      url: `${GET_JOB_IMAGE}/${jobId}?status=${status}&skip=${skip}&limit=${limit}`,
      baseURL: process.env.NEXT_PUBLIC_API_URL || "",
      method: "GET",
    })
    const payload = data.data.bg_removed_images.map((img) => ({
      ...img,
      image_url: img.image_png,
    }));
    return payload;
  }
}