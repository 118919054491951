import { css } from "@emotion/react";
import { ICONS, Icon } from "src/modules/ui-elements/Icon";
import PrimaryInput, {
  PrimaryInputSizes,
  PrimaryInputTypes,
} from "src/modules/ui-elements/PrimaryInput";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Loader } from "@/modules/editor-v2/MiddleSection/EditorTopToolBar";


const SearchOption = ({
  strapiData,
  selectedFilter,
  queryClient,
  setSelectedFilter,
  onSearchHandler,
  trendingList,
  trendingListLoading,
  recommendedList,
  setIsComponentVisible,
}) => {
  const debounceRef = useRef(null);
  const [showRecentAndTrending, setShowRecentAndTrending] = useState(true);
  const [userSearchInput, setUserSearchInput] = useState("");

  const onChangeHandler = () => {
    setSelectedFilter((prev) => ({
      ...prev,
      searchText: userSearchInput,
    }));
  };

  const searchDebounce = (delay) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      onChangeHandler();
    }, delay);
  };
  useEffect(() => {
    if (selectedFilter?.searchText?.length > 0) setShowRecentAndTrending(false);
    else setShowRecentAndTrending(true);
  }, [selectedFilter?.searchText]);

  useEffect(() => {
    searchDebounce(600);
  }, [userSearchInput]);

  return (
    <div css={styles.inputContainer}>
      <PrimaryInput
        size={PrimaryInputSizes.lg}
        type={PrimaryInputTypes.white}
        icon={ICONS.search}
        placeholder={
          strapiData?.SearchPlaceholderText ||
          "Search keywords or theme or color"
        }
        value={userSearchInput}
        onFocus={() => {
          queryClient.invalidateQueries(["trendingSearch"]);
        }}
        onChange={(e) => setUserSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearchHandler(userSearchInput);
            setIsComponentVisible(false);
          }
        }}
      />
      <SuggestionBox
        trendingList={trendingList}
        onSearchHandler={onSearchHandler}
        trendingListLoading={trendingListLoading}
        strapiData={strapiData}
        showRecentAndTrending={showRecentAndTrending}
      />
      {recommendedList?.length > 0 && selectedFilter?.searchText?.length > 0 && (
        <div css={styles.suggestionContainer}>
          {recommendedList
            ?.filter((txt) =>
              txt
                .toUpperCase()
                .includes(selectedFilter?.searchText?.toUpperCase())
            )
            ?.map((txt) => (
              <span onClick={() => onSearchHandler(txt)}>{txt}</span>
            ))}
        </div>
      )}
    </div>
  );
};

export const SuggestionBox = ({
    trendingList,
    onSearchHandler,
    trendingListLoading,
    strapiData,
    showRecentAndTrending,
  }) => {
    return (
      <div css={styles.suggestionTrendingContainer(showRecentAndTrending)}>
        {trendingList?.recentSearches?.length > 0 && (
          <>
            <div css={styles.trendingHeader}>
              {strapiData?.RecentSearchesText || "Recent Searches"}
            </div>
            <div css={styles.trendingAndRecentOptionsContainer}>
              {trendingList?.recentSearches?.map((txt) => (
                <div
                  css={styles.trendingAndRecentOptions}
                  onClick={() => onSearchHandler(txt)}
                >
                  <span>{txt}</span>
                  <Icon src={ICONS.trendingUpDark} width={10} height={10} />
                </div>
              ))}
            </div>
          </>
        )}
        {trendingList?.popularSearches?.length > 0 && (
          <>
            <div css={styles.trendingHeader}>
              {strapiData?.TrendingSearchesText || "Trending Searches"}
            </div>
            <div css={styles.trendingAndRecentOptionsContainer}>
              {trendingList?.popularSearches?.map((txt) => (
                <div
                  css={styles.trendingAndRecentOptions}
                  onClick={() => onSearchHandler(txt)}
                >
                  <span>{txt}</span>
                  <Icon src={ICONS.trendingUpDark} width={10} height={10} />
                </div>
              ))}
            </div>
          </>
        )}
        {trendingListLoading && (
          <div
            css={css`
              display: flex;
              font-size: 12px;
              align-item: center;
              gap: 5px;
              padding: 10px;
            `}
          >
            <Loader type="light" />
            {strapiData?.Heading || "Background"} Loading...
          </div>
        )}
      </div>
    );
  };

  const styles = {
    inputContainer: css`
      width: 100%;
      position: absolute;
      top: 8px;
      left: 0;
      z-index: 1;
    `,
    suggestionContainer: css`
      width: 100%;
      position: absolute;
      background-color: #fff;
      top: 41px;
      left: 0px;
      display: flex;
      flex-direction: column;
      jsutify-content: center;
      align-items: center;
      border-radius: 0px 0px 5px 5px;
      z-index: 1;
      max-height: 380px;
      overflow: auto !important;
      span {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: #262626;
        font-family: "Objectivity";
        font-size: 14px;
        padding: 8px 20px;
        border-bottom: 1px solid #eee;
        :hover {
          cursor: pointer;
          background-color: #eee;
        }
      }
    `,
    suggestionTrendingContainer: (active) => css`
      width: 100%;
      position: absolute;
      background-color: #fff;
      top: 41px;
      left: 0px;
      flex-direction: column;
      jsutify-content: center;
      align-items: center;
      border-radius: 0px 0px 5px 5px;
      z-index: 1;
      display: ${active ? "flex" : "none"};
    `,
    trendingHeader: css`
      display: flex;
      width: 100%;
      flex-direction: row;
      padding: 10px;
      color: rgba(38, 38, 38, 0.7);
      font-family: "Objectivity";
      font-size: 10px;
    `,
    trendingAndRecentOptionsContainer: css`
      display: flex;
      padding: 0px 10px 10px;
      justify-content: flex-start;
      gap: 4px;
      width: 100%;
      overflow-x: auto !important;
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    trendingAndRecentOptions: css`
      border-radius: 4px;
      background: #F1F1F1;
      display: flex;
      padding: 6px 8px;
      align-items: center;
      width: fit-content;
      gap: 4px;
      color: #262626;
      font-family: "Objectivity";
      font-size: 12px;
      cursor: pointer;
      span {
        max-height: 18px;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `,
    pointer: {
      cursor: "pointer",
    },
  };


export default SearchOption;