import { useAtom, useSetAtom } from "jotai";
import { setJobId } from "src/redux/reducers/jobs.reducer";
import {
  activeProjectAtom,
  appliedAtom,
  backgroundColorAtom,
  batchDeletedImageListAtom,
  batchEditorActiveJobAtom,
  brightnessAtom,
  contrastAtom,
  coverageAreaAtom,
  customPresetAppliedAtom,
  customPresetAtom,
  editorObjectAtom,
  heightAtom,
  hueAtom,
  pageAtom,
  pagesStatesAtom,
  positionXAtom,
  positionYAtom,
  saturationAtom,
  selectedMarketPlaceAtom,
  widthAtom,
  editorInitializedAtom,
  updateInitializedAtom,
  batchSelectedBrightnessAtom, 
  batchSelectedContrastAtom,
  batchSelectedSaturationAtom
} from "../modules/batchEditor/atoms";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { showLoginPopupAtom } from "src/modules/editor-v2/Atoms";

function useClearBatchEditor() {
  const dispatch = useDispatch();
  const router = useRouter();
  const defaultSettings = () => {
    editorMap.clear();
    brightnessMap.clear();
    contrastMap.clear();
    saturationMap.clear();
    hueMap.clear();
  };
  const [hueMap] = useAtom(hueAtom);
  const setWidth = useSetAtom(widthAtom);
  const setHeight = useSetAtom(heightAtom);
  const setPageArray = useSetAtom(pageAtom);
  const setApplied = useSetAtom(appliedAtom);
  const [contrastMap] = useAtom(contrastAtom);
  const setPositionY = useSetAtom(positionYAtom);
  const setPositionX = useSetAtom(positionXAtom);
  const [saturationMap] = useAtom(saturationAtom);
  const [brightnessMap] = useAtom(brightnessAtom);
  const setPagesStates = useSetAtom(pagesStatesAtom);
  const setCustomPreset = useSetAtom(customPresetAtom);
  const setCoverageArea = useSetAtom(coverageAreaAtom);
  const setActiveProject = useSetAtom(activeProjectAtom);
  const setBackgroundColor = useSetAtom(backgroundColorAtom);
  const [editorMap, setEditorMap] = useAtom(editorObjectAtom);
  const setSelectedMarketPlace = useSetAtom(selectedMarketPlaceAtom);
  const setCustomPresetApplied = useSetAtom(customPresetAppliedAtom);
  const setSelectedBatchActiveJob = useSetAtom(batchEditorActiveJobAtom);
  const setDeletedPages = useSetAtom(batchDeletedImageListAtom); 
  const setLoginPopupAtom = useSetAtom(showLoginPopupAtom);
  const setUpdateInitialized = useSetAtom(updateInitializedAtom);
  const setEditorInitialization = useSetAtom(editorInitializedAtom);
  const setBatchSelectedBrightnessAtom = useSetAtom(batchSelectedBrightnessAtom);
  const setBatchSelectedSaturationAtom =  useSetAtom(batchSelectedSaturationAtom);
  const setBatchSelectedContrastAtom =  useSetAtom(batchSelectedContrastAtom);
  
  const clearAll = () => {
      setBatchSelectedBrightnessAtom(0);
      setBatchSelectedSaturationAtom(0);
      setBatchSelectedContrastAtom(0);
      setWidth();
      setHeight();
      setPositionX();
      setPositionY();
      setApplied(true);
      setPageArray([]);
      setDeletedPages([]);
      setCoverageArea();
      defaultSettings();
      setPagesStates([]);
      setBackgroundColor();
      setCustomPreset(false);
      setActiveProject(false);
      setEditorMap(new Map());
      dispatch(setJobId(null));
      setSelectedMarketPlace();
      setCustomPresetApplied(false);
      setSelectedBatchActiveJob(null);
      sessionStorage.removeItem("bulkUploadProTouchActive");
      setLoginPopupAtom(false);
      setEditorInitialization(false);
      setUpdateInitialized(false);
  };
  return { clearAll };
}

export default useClearBatchEditor;
