import { capitalCase } from "change-case";
import {  useAtomValue } from "jotai";
import { AlignmentType } from "local_modules/editor/src/Interfaces";
import Layer from "local_modules/editor/src/Layer";
import { useCallback } from "react";
import { flipXAtom, flipYAtom, selectedLayerAtom, strapiDimensionLimitAtom } from "src/modules/editor-v2/Atoms";
import useEditorService from "./useEditorService";

export default function useEditorLayerService () {
    const {activeEditor} = useEditorService();
    const selectedLayer:Layer = useAtomValue(selectedLayerAtom);
    const flipX = useAtomValue(flipXAtom);
    const flipY = useAtomValue(flipYAtom);

    const dimensionLimit = useAtomValue(strapiDimensionLimitAtom);

    const alignLayer = useCallback((alignment:AlignmentType)=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        selectedLayer.align(alignment);
    },[activeEditor,selectedLayer]);

    const rotateClockwise = useCallback(()=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        const fn = async () => {
            const currentRotation =  await new Promise<number>(res=>{
                selectedLayer.rotation.subscribe((rotation)=>{
                    res(rotation);
                });
            });
            const snapClockwise = Math.floor(currentRotation / 90) * 90;
            const nextRotation = snapClockwise + 90;
            selectedLayer.setRotation(nextRotation);
        }
        return fn();
    },[activeEditor,selectedLayer]);

    const rotateCounterClockwise = useCallback(()=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        const fn = async () => {
            const currentRotation =  await new Promise<number>(res=>{
                selectedLayer.rotation.subscribe((rotation)=>{
                    res(rotation);
                });
            });
            const snapCounterClockwise = Math.ceil(currentRotation / 90) * 90;
            const nextRotation = snapCounterClockwise - 90;
            selectedLayer.setRotation(nextRotation);
        }
        return fn();
    },[activeEditor,selectedLayer]);

    const deleteLayer = useCallback((layer:Layer)=>{
        if(!activeEditor) return;
        activeEditor.layerManager.delete(layer);
    },[activeEditor]);

    const deleteSelected = useCallback(()=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        activeEditor.layerManager.delete(selectedLayer);
    },[activeEditor,selectedLayer]);

    const flipHorizontal = useCallback(()=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        selectedLayer.setFlipX(!!!flipX);
    },[activeEditor,selectedLayer, flipX]);

    const flipVertical = useCallback(()=>{
        if(!activeEditor) return;
        if(!selectedLayer) return;
        selectedLayer.setFlipY(!!!flipY);
    },[activeEditor,selectedLayer, flipY]);

    const moveLayer = useCallback((layer:Layer, direction:"up" | "down")=>{
        if(!activeEditor) return;
        const layerManager = activeEditor.layerManager;
        layerManager[`moveLayer${capitalCase(direction)}`](layer);
    },[activeEditor]);

    const moveSelectedLayer = useCallback((direction:"up" | "down")=>{
        if(!selectedLayer) return;
        moveLayer(selectedLayer, direction);
    },[activeEditor,selectedLayer]);

    const bringLayerToFront = useCallback((layer:Layer)=>{
        if(!activeEditor) return;
        const layerManager = activeEditor.layerManager;
        layerManager.bringToFront(layer);
    },[activeEditor]);

    const bringSelectedLayerToFront = useCallback(()=>{
        if(!selectedLayer) return;
        bringLayerToFront(selectedLayer);
    },[activeEditor,selectedLayer]);

    const sendLayerToBack = useCallback((layer:Layer)=>{
        if(!activeEditor) return;
        const layerManager = activeEditor.layerManager;
        layerManager.bringToBack(layer);
    },[activeEditor]);

    const sendSelectedLayerToBack = useCallback(()=>{
        if(!selectedLayer) return;
        sendLayerToBack(selectedLayer);
    },[activeEditor,selectedLayer]);

    const setOpacity = useCallback((layer:Layer, opacity:number)=>{
        if(!activeEditor) return;
        layer.setOpacity(opacity);
    },[activeEditor]);

    const setSelectedOpacity = useCallback((opacity:number)=>{
        if(!selectedLayer) return;
        setOpacity(selectedLayer, opacity);
    },[activeEditor,selectedLayer]);

    const setAspectRatioLock = useCallback((layer:Layer, lock:boolean)=>{
        if(!activeEditor) return;
        layer.setAspectRatioLock(lock);
    },[activeEditor]);

    const setSelectedAspectRatioLock = useCallback((lock:boolean)=>{
        if(!selectedLayer) return;
        if (activeEditor?.canvas.getActiveObject()?.width  <= dimensionLimit
        || activeEditor?.canvas.getActiveObject()?.height <= dimensionLimit) {
            return;
        }
        setAspectRatioLock(selectedLayer, lock);
    },[activeEditor,selectedLayer,dimensionLimit]);

    const setDirection = useCallback((layer:Layer, position)=>{
        if(!activeEditor) return;
        layer.setPosition({ x: position?.x, y: position?.y });
    },[activeEditor,,selectedLayer]);

    return {alignLayer, rotateClockwise, rotateCounterClockwise, deleteLayer, deleteSelected, flipHorizontal, flipVertical, moveLayer, moveSelectedLayer, bringLayerToFront, bringSelectedLayerToFront, sendLayerToBack, sendSelectedLayerToBack, setOpacity, setSelectedOpacity, setAspectRatioLock, setSelectedAspectRatioLock, setDirection}
}