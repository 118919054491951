import { css } from "@emotion/react"
import { useEffect, useMemo, useRef, useState } from "react";
import useEditorService from "src/hooks/useEditorService"
import { Icon, ICONS } from "src/modules/ui-elements/Icon"
import { Loader } from "../MyAssets/ShootAssets";
import { useQueryClient } from "@tanstack/react-query";
import useAuth from "../../../../hooks/useAuth"; 
import useToast from "../../../../hooks/useToast"; 
import { addToFavourite as addToFavorite, removeFromFavourite as removeFromFavorite} from "../../../../services/template";     
import TemplatePreview from "@/modules/ui-elements/TemplatePreview";
import { createEmptyEditorJob, getJobDetails } from "../../services/Jobs";
import { useAtomValue, useSetAtom } from "jotai";
import { activePageAtom, activeProjectAtom, backgroundFilterAtom, backgroundFilterProductImageIdAtom, initializingEditorAtom, shouldResizeAtom, toAddBackgroundAtom, toAddBackgroundIdAtom, toAddInfographicAtom, templateJsonAtom, standardInfographic } from "../../Atoms";
import { m } from "framer-motion";
import { DEFAULT_SIZES } from "@/modules/editor-v2/LeftToolBar/Resize/DefaultSizes";
import useOtherCategory from "@/hooks/useOtherCategory";
import { useSelector } from "react-redux";


export default function TemplateItem({src, editorJSONObject, isPaid, isFavorite, _id,aspectRatio, boundingBox, productImageUrl,productImageLayerId,index=0, backgroundId}) {
    const {setBackground, applyInfographic} = useEditorService();
    const queryClient = useQueryClient();
    const {user} = useAuth();
    const [isFav, setIsFav] = useState(isFavorite);
    const toaster = useToast();
    const setActiveProject = useSetAtom(activeProjectAtom);
    const setIsEditorInitializing = useSetAtom(initializingEditorAtom);
    const setToAddBackgroundId = useSetAtom(toAddBackgroundIdAtom);
    const setToAddBackground = useSetAtom(toAddBackgroundAtom);
    const setToAddInfographic = useSetAtom(toAddInfographicAtom);
    const setTemplateJsonAtom = useSetAtom(templateJsonAtom);
    const setShouldResize= useSetAtom(shouldResizeAtom);
    const selectedFilter = useAtomValue(backgroundFilterAtom);
    const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
    const {otherCategory, otherSubCategory, isLoading:isCategoriesLoading} = useOtherCategory();
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiErrorData = entireStrapiData?.StudioErrorToasterList?.StudioErrorToasterList;
    const strapiSuccessData = entireStrapiData?.StudioSuccessToasterList?.StudioSuccessToasterList;
    const strapiInfoData = entireStrapiData?.StudioInfoToasterList?.StudioInfoToasterList;
    const isStandardInfographic = useAtomValue(standardInfographic);

    const _boundingBox = useMemo(()=>({
        height: boundingBox?.height / 2,
        width: boundingBox?.width / 2,
        verticalJustification: boundingBox?.vertical_justification,
        horizontalJustification: boundingBox?.horizontal_justification,
        xOffset: boundingBox?.x_offset / 2,
        yOffset: boundingBox?.y_offset / 2,
        rotation: boundingBox?.rotation
    }),[boundingBox]);

    const onApplyHandler = async() =>{
        try{
            setIsEditorInitializing(true);
            setToAddBackgroundId(_id);
            if(editorJSONObject?.isInfographics){
                setToAddInfographic(true);
                setTemplateJsonAtom(editorJSONObject?.editorJSONObject?.data);
            }
            else{
                setToAddBackground(src);
            }
            const backgroundSize = DEFAULT_SIZES.find(obj=>obj?.aspectRatio === aspectRatio);
            setShouldResize({height:backgroundSize?.height,width:backgroundSize?.width})
            const jobId = await createEmptyEditorJob(otherCategory?._id, otherSubCategory?._id, isStandardInfographic);
            const job = await getJobDetails(jobId);
            setActiveProject(job);
        }catch(err){
            setIsEditorInitializing(false);
            toaster.error("Something went wrong while adding template to canvas");
            console.log(err)
        }
    }; 

    const toggleFav = () => {
        const nextValue = !isFav;

        setIsFav(nextValue);
        let promise;
        if(nextValue) promise = addToFavorite(user.user._id, _id);
        else promise = removeFromFavorite(user.user._id, _id);
        const key = toaster.info(nextValue ? strapiInfoData?.AddingBGToFav || "Adding template to favorites" : strapiInfoData?.RemovingBGFav || "Removing template from favorites");
        promise.then(()=>{
            queryClient.removeQueries([
                "filteredTemplates",
                "infinite",
                selectedFilter?.searchText,
                selectedFilter?.searchString,
                "yourfavorite",
                selectedFilter?.imageFormat,
                selectedFilter?.colors,
                selectedFilter?.hotspotOnly,
                productImageId,
                selectedFilter?.prespective?.angleGroupId,
                selectedFilter?.updateRecentSearch,
            ],);
            queryClient.removeQueries([
                "filteredTemplates", 
                "count",
                selectedFilter?.searchText,
                selectedFilter?.searchString,
                "yourfavorite",
                selectedFilter?.imageFormat,
                selectedFilter?.colors,
                selectedFilter?.hotspotOnly,
                productImageId,
                selectedFilter?.prespective?.angleGroupId,
                selectedFilter?.updateRecentSearch,
            ]);
            toaster.close(key);
            toaster.success(nextValue ? strapiSuccessData?.BGAddedToFav || "Template added to favorites" : strapiSuccessData?.BackgroundRemoved || "Template Removed from favorites");
        }).catch((err)=>{
            console.error("error in adding favourite",err);
            setIsFav(!nextValue);
            toaster.error(strapiErrorData?.BGtoFavorite || "Something went wrong while adding template to favorites");
        })
    }

    const height = useMemo(()=>{
        if(!aspectRatio || typeof aspectRatio !== "string" || !aspectRatio.includes("/")) return 108; 
        const [_, heightRatio] = aspectRatio.split("/")
        if(heightRatio === '9') return 96;
        if(heightRatio === '16') return 140;
        return 108 
    },[aspectRatio]);

    const width = useMemo(()=>{
        if(!aspectRatio || typeof aspectRatio !== "string") return 108; 
        const [widthRatio] = aspectRatio.split("/")
        if(widthRatio === '9') return 108;
        if(widthRatio === '16') return 166;
        return 108 
    },[aspectRatio]);

    return <m.button
        initial={{
            transform:'translateY(-10%)',
            opacity: 0,
        }}
        whileInView={{
            transform: 'translateY(0%)',
            opacity: 1,
            scale:1
        }}
        transition={{
            duration: 0.1,
            // delay: index * 0.05,
        }}
    
     css={styles.container(height,width)}  style={_id==backgroundId?{border:"1px solid white"}:{}}>
        {/* <img className="image" css={styles.image(aspectRatio)} src={src} crossOrigin="anonymous" onClick={()=>setBackground(src,_id)}/> */}
        <TemplatePreview templateUrl={src} onClickHandler={()=>onApplyHandler()} templateId={_id} boundingBox={_boundingBox} productImage={productImageUrl} productImageLayerId={productImageLayerId} editorJSONObject={editorJSONObject}/>
        <div css={styles.favoriteIcon()} onClick={toggleFav}>
            {/* <Icon height={12} width={12} src={ICONS.info}/> */}
            {/* <Loader /> */}
            <Icon src={isFav ? ICONS.heartDark : "/icons/heart.png"} height={28} width={28}  />
        </div>
        <div css={styles.hotspotIcon}>
            {selectedFilter?.hotspotOnly && <Icon src={"/icons/hotspot.svg"} height={28} width={28}  />}
        </div>
    </m.button>
}

const styles = {
    container:(height,width)=> css`
        margin:4px 4px 4px 4px;
        border: 0px solid transparent;
        background:#3C3C3C;
        height:${height}px;
        width:${width}px;
        border-radius:4px;
        position:relative;
        transition: 0.15s ease-in-out;
        &:hover {
            border: 1px solid #EEEEEE;
        }
    `,
    image:(aspectRatio)=>css`
        min-width:${aspectRatio !== '1/1' ? '' : '108px'};
        max-width:100%;
        min-height:${aspectRatio !== '1/1' ? '' : '108px'};;
        max-height:100%;
        aspect-ratio:${aspectRatio ? aspectRatio : 1};
        ${'' /* background:url('${src}'); */}
        border-radius:4px;
        transition: 0.15s ease-in-out;
        background-size:contain;
        background-position: center center;
        position:relative;
    `,
    favoriteIcon:(isFavorite)=>css`
        position:absolute;
        height:28px;
        width:28px;
        right:4px;
        top:4px;
        background: ${isFavorite?'red':'transparent'};
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:12px;
    `,
    hotspotIcon: css`
        position:absolute;
        height:28px;
        width:28px;
        left:4px;
        bottom:4px;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:12px;
    `,
    pill: css`
        position:absolute;
        right:4px;
        bottom:4px;
        background:rgba(38, 38, 38, 0.32);;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:12px;
        vertical-align: middle;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height:10px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        padding:6px 8px 4px 8px ;
        color: rgba(255, 255, 255, 0.7);

    `
}