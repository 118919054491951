import { css } from "@emotion/react";
import { ICONS, Icon } from "src/modules/ui-elements/Icon";
import { useState } from "react";
import { Radio } from "@mui/material";
import { isArray } from "lodash";
import { backgroundFilterIsAllAngleGroupActiveAtom, backgroundFilterProductImageIdAtom } from "@/modules/editor-v2/Atoms";
import { useAtom, useAtomValue } from "jotai";
import { useQueryClient } from "@tanstack/react-query";
import { BACKGROUND_MODE } from "..";


const BackgroundAngleList = ({
  selectedFilter,
  setSelectedFilter,
  prespectiveList,
  prespectiveListLoading,
  setIsPrespectiveVisible,
  onSelectAngleHandler,
  setIsFiltersPaneVisible,
  setBackgroundMode
}) => {
  const queryClient = useQueryClient();
  const [showPerspective, setShowPerspective] = useState(true);
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const [isAllAngleGroupActive,setIsAllAngleGroupActive] = useAtom(backgroundFilterIsAllAngleGroupActiveAtom);

  const showAllPrespectiveHandler = async() => {
    queryClient.invalidateQueries(["prespectiveSearch", "list", selectedFilter?.imageFormat, productImageId]);
      onSelectAngleHandler({});
      setIsPrespectiveVisible((prev) => !prev);
      setIsAllAngleGroupActive(false);
      setIsFiltersPaneVisible(false);
  };
  return (
    <div css={styles.backgroundListContainer}>
      <div
        css={styles.backgroundListAll(
          isAllAngleGroupActive
        )}
        onClick={() => {
          setSelectedFilter((prev) => ({ ...prev, prespective: {} }));
          setIsPrespectiveVisible((prev) => !prev);
          setIsAllAngleGroupActive(true);
          setIsFiltersPaneVisible(false);
          setBackgroundMode(BACKGROUND_MODE.BASIC);
        }}
      >
        <span>All Backgrounds</span>
        <Radio
          checked={isAllAngleGroupActive}
          size="small"
          sx={styles.backgroundListCheckbox}
        />
      </div>
      <div css={styles.backgroundList_prespective_cont}>
        <div
          css={styles.backgroundList_prespective}
        >
          <span>Perspective</span>
        </div>
        {showPerspective && (
          <div css={styles.backgroundList_prespectiveList}>
            { <>
              <div
              css={styles.backgroundList_prespectiveOption(!selectedFilter?.prespective?.angleGroupId && !isAllAngleGroupActive)}
              onClick={showAllPrespectiveHandler}
            >
              Show all Perspective
              <img
                src={
                  (!selectedFilter?.prespective?.angleGroupId && !isAllAngleGroupActive)
                    ? ICONS.circleDarkest
                    : ICONS.circleLight
                }
                width={"16px"}
              />
            </div>
              {isArray(prespectiveList) && prespectiveList?.map((obj) => (
                <div
                  css={styles.backgroundList_prespectiveOption(
                    selectedFilter?.prespective?.angleGroupId ===
                      obj?.angleGroupId
                  )}
                  onClick={() => {
                    onSelectAngleHandler(obj);
                    setIsPrespectiveVisible((prev) => !prev);
                    setIsAllAngleGroupActive(false);
                    setIsFiltersPaneVisible(false);
                  }}
                >
                  {obj?.angleGroupName}
                  <img
                    src={
                      selectedFilter?.prespective?.angleGroupId ===
                      obj?.angleGroupId
                        ? ICONS.circleDarkest
                        : ICONS.circleLight
                    }
                    width={"16px"}
                  />
                </div>
              ))}
              </>}
              {(!isArray(prespectiveList) || !prespectiveList) && (
              <div
                css={css`
                  font-size: 12px;
                  width: 100%;
                  padding:10px 0px;
                  text-align: center;
                `}
              >
                {prespectiveListLoading ? "Loading..." : <div>No Result found</div>}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


const styles = {
  pointer: {
    cursor: "pointer",
  },
  backgroundListContainer: css`
    position: absolute;
    top: 132px;
    right: 10px;
    width: 70%;
    max-width: 340px;
    background: #111111;
    color: #fff;
    z-index: 10;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 3px 70px 0px #26262666;
  `,
  backgroundListAll: (active) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 5px 5px 5px 12px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  `,
  backgroundListCheckbox: {
    border: "1px solid white",
    width: "18px",
    height: "18px",
    color: "#262626",
    "&:hover": { backgroundColor: "transparent" },
    "&.Mui-checked": {
      color: "white",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
  },
  backgroundList_prespective_cont: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
    border-radius: 4px;
    margin : 4px;
  `,
  backgroundList_prespective: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    cursor: pointer;
    border-top: 0.5px solid #333333;
    border-bottom: 0.5px solid #333333;
  `,
  backgroundList_prespectiveList: css`
    width: 100%;
    padding-top: 8px;
    background: black;
    color: white;
    max-height:205px;
    overflow-y:scroll;
    overflow-x: hidden;
  `,
  backgroundList_prespectiveOption: (active) => css`
    display: flex;
    justify-content: space-between;
    width: 98%;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    background: #333333;
    font-size: 12px;
    border-radius: 4px;
    font-weight: ${active ? 700 : 400};
    :hover {
      background: #333333;
      font-weight: 600;
      border: 1px solid #eee;
    };
    margin : 4px;
    height : 35px;
  `,
};


export default BackgroundAngleList;