import { Icon } from "@/modules/ui-elements/Icon";
import { css } from "@emotion/react";

export default function SizeItem({icon, name, height , width, onClick, disabled, active}) {
    return <div css={[styles.container(disabled),active && styles.active]} onClick={onClick}>
        <Icon src={icon} height={14} width={14} />
        <div css={styles.title}>{name}</div>
        <div css={styles.size}>{width} x {height}</div>
    </div>
}

const styles = {
    container: (disabled) => css`
        display: inline-flex;
        padding: 6px 17px 6px 17px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        border-radius: 4px;
        border: 0.5px solid #EEE;
        background: #F9F9F9;
        cursor: pointer;
        opacity: ${disabled ? 0.5 : 1};
        transition: all 0.2s ease-in-out;
        &:hover {
            border: 0.5px solid #262626;
            background: #FFF;
            box-shadow: 0px 1px 24px 0px rgba(38, 38, 38, 0.08);
            
        }
    `,
    title: css`
        color: #262626;
        text-align: center;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
    `,
    size: css`
        color: #262626;
        text-align: center;
        font-family: Objectivity;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    `,
    active: css`
        border: 0.5px solid #262626;
        background: #FFF;
        box-shadow: 0px 1px 24px 0px rgba(38, 38, 38, 0.08);
    `,
}