import styles from "./RemoveBackgroundAndApply.module.css";
import GenerateImageIcon from "./GenerateImageIcon.svg";
import { useCallback, useMemo } from "react";
import { useAtomValue } from "jotai";
import { layersAtom } from "../../Atoms";
import Layer from "local_modules/editor/src/Layer";
import useToast from "@/hooks/useToast";
import { removeAssetBackground } from "../../services/Assets";
import { useQueryClient } from "@tanstack/react-query";
import useEditorService, { ProductImageType } from "@/hooks/useEditorService";
import { useSelector } from "react-redux";

export default function RemoveBackgroundAndApply() {
    
    const layers:Layer[] = useAtomValue(layersAtom);
    const toast = useToast();
    const queryClient = useQueryClient();
    const {addProductImage, activeEditor} = useEditorService();
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);

    let productImage = useMemo(
        ()=>layers.find(layer=>layer.getTags()?.includes("non-bg-removed")),
        [layers]
    );

    const assetId = useMemo(()=>{
        if(!productImage) return;
        return productImage.getTags()?.filter(tag=>tag.includes("product-asset-id-"))?.[0]?.replace("product-asset-id-","");;
    },[productImage])

    const onClick = useCallback(() => {
        if(!assetId) return toast.error("Something went wrong!",2000);
        // Set Generating true
        const key = toast.info("Removing Background!",20000);
        removeAssetBackground(assetId).then(async (res)=>{
            toast.close(key);
            queryClient.invalidateQueries(['assets']);
            await addProductImage(res.url,ProductImageType.ASSET,assetId);
            toast.success("Background Removed",2000);
            activeEditor.layerManager.delete(productImage);
        }).catch(err=>{
            toast.close(key);
            console.error(err)
            toast.error("Something went wrong!",2000);
            // toast.error(err,2000);
        });
        // Delete old Product Image.
    },[assetId,productImage,activeEditor]);


    return <div className={styles.container}>
        <div className={styles.topRow}>
            <div>{strapiText?.PromptGeneratorTitle || "Prompt"}</div>
          
        </div>
        
        <textarea className={styles.textarea} placeholder={strapiText?.PromptGeneratorTitle || "Describe your Background"} />

        <div className={`${styles.generateButton} ${styles.generateButtonLoading}`}>
            <GenerateImageIcon />
            <div>{strapiText?.GenerateImagesButton ||  "Generate Images"}</div>
        </div>
        
        <div className={styles.uploadBox} onClick={onClick}>
            <div className={styles.uploadText}>
                {strapiText?.RemoveBGDescription || "Please select \"Remove BG & Apply\" while uploading the image on the canvas to get the best quality \"AI-Backgrounds\""}
            </div>
            <div className={styles.uploadAsset}>
                {strapiText?.RemoveBGButton || "Remove Background & Apply"}
            </div>
        </div>
    </div>
}