import React from "react";


async function fetchSVGContent(url) {
    const response = await fetch(url);
    const text = await response.text();
    return text;
}

function parseSVGContent(svgContent) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
    return svgDoc;
}

function extractFillValue(svgDoc) {
    let fillValues = [];
    const svgElements = svgDoc.querySelectorAll('[fill]');
    if(!svgElements?.length){
        const styleElement = svgDoc.querySelector('style');
        let cssRules = [];
        if (styleElement) {
            const cssText = styleElement.textContent;
            const styleSheet = new CSSStyleSheet();
            styleSheet.replaceSync(cssText);
            cssRules = Array.from(styleSheet.cssRules);
        }

        cssRules.forEach(rule => {
            if (rule.style && rule.style.fill) {
                const className = rule.selectorText.slice(1); // Remove leading dot
                const elements = svgDoc.querySelectorAll(`.${className}`);
                elements.forEach(element => {
                    fillValues.push(rule.style.fill);
                });
            }
        });
    }else{
        // @ts-expect-error
        fillValues = Array.from(svgElements).map(element => element.getAttribute('fill'));
    }
    return fillValues;
}

export const getFillValues = async (url) => {
    let fills;
    try {
      const svgContent = await fetchSVGContent(url);
      const svgDoc = parseSVGContent(svgContent);
      fills = extractFillValue(svgDoc);
      return fills
    } catch (error) {
      console.error('show me resp -> Error fetching or parsing SVG:', error);
    }
};

export function convertRgbToHex(rgba, forceRemoveAlpha = false) {
    return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map(string => parseFloat(string)) // Converts them to numbers
      .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
      .map(number => number.toString(16)) // Converts numbers to hex
      .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
      .join("") // Puts the array to togehter to a string
  }
