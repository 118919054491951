import { useSnackbar } from "notistack";

export default function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return {
    error: (message, duration) =>
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: duration || 3000
      }),
    info: (message, duration) =>
      enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: duration || 3000
      }),
    success: (message, duration) =>
      enqueueSnackbar(message, {
        variant: "success",
        autoHideDuration: duration || 3000
      }),
      close: (key) => closeSnackbar(key)
  };
}
