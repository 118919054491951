import { pxToRem, responsiveFontSizes } from "../utils/getFontValue";

// ----------------------------------------------------------------------

const FONT_PRIMARY = "Objectivity"; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    color: "#262626",
    fontSize: pxToRem(30),
    letterSpacing: 2,
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 42, md: 48, lg: 54 }),
  },
  h2: {
    fontWeight: 700,
    color: "#262626",
    lineHeight: 64 / 48,
    fontSize: pxToRem(22),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 30, md: 34, lg: 38 }),
  },
  h3: {
    fontWeight: 700,
    color: "#262626",
    lineHeight: 1.5,
    fontSize: pxToRem(22),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 24, md: 16, lg: 30 }),
  },
  h4: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: "#262626",
    fontSize: pxToRem(20),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: "#262626",
    fontSize: pxToRem(18),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    color: "#262626",
    fontSize: pxToRem(16),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: "#262626",
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
  },
  subtitle2: {
    fontWeight: 400,
    color: "#262626",
    lineHeight: 22 / 14,
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
  },
  subtitle3: {
    fontWeight: 400,
    color: "#262626B2",
    lineHeight: 22 / 14,
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
  },
  subtitle4: {
    fontWeight: 500,
    color: "#262626",
    lineHeight: 22 / 14,
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
  },
  body1: {
    lineHeight: 1.5,
    fontWeight: 400,
    color: "#262626",
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: "160%",
    fontWeight: 400,
    color: "rgba(38, 38, 38, 0.7)",
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    color: "#262626",
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#262626",
    fontSize: pxToRem(10),
    fontFamily: FONT_PRIMARY,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    color: "#262626",
    fontSize: pxToRem(14),
    fontFamily: FONT_PRIMARY,
    textTransform: "capitalize",
  },
  textStyle: {
    background: "linear-gradient(114.17deg, #F03750 4.08%, #EB2E83 82.78%)",
    "-webkit-background-clip": " text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  darkButtonText:{
    color:"rgba(255, 255, 255, 0.5)",
  }
};

export default typography;
