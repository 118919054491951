import axiosInstance from "@/utils/axios";
import { DMSCanvasFile } from "local_modules/editor/src/Interfaces";
import API_SERVICE from "src/utils/axios";

export async function createEditorJob(_id:string,isStandardInfographic: boolean, initiatedSource: string = 'editor'){
        const isStandardInfographicType = isStandardInfographic ? "INTERNAL" : "EXTERNAL";
        const res = await axiosInstance.post("v4/shootnewLifestyleImage/createJobAndFetchAngles",{
            "adaptation_job_id": _id,
            "initiatedSource": initiatedSource,
            "infographicType": isStandardInfographicType,
        });
        if(!res.data.success) throw new Error(res.data.message);
        return res.data.data.job_id;
}

export async function createEditorJobFromTemplate(templateId) {
    const res = await axiosInstance.post(`v3/exploreTemplates/createJobFromTemplate?templateId=${templateId}&isEditorJob=true`);
    return res.data?.data?.job_id;
}

export async function createEmptyEditorJob(categoryId:string, subCategoryId:string, isStandardInfographic?:boolean, marketPlaceId?:string, initiatedSource?:string) {
    const res = await axiosInstance.post("v4/shootnewLifestyleImage/createJobAndFetchAngles",{
        "initiatedSource": initiatedSource || "editor",
        "category_id": categoryId,
        "subcategory_id": subCategoryId,
        "marketPlace_id": '',
        "infographicType": isStandardInfographic ? "INTERNAL" : "EXTERNAL"
    });

    if(!res.data.success) throw new Error(res.data.message);
    return res.data.data.job_id;
}
export async function longPollJobDetails(_id, timeout) {
    return new Promise(async (resolve, reject) => {
        // setTimeout(() => {
        //     reject('max Timeout reached');
        // }, timeout);
        let job;
        while (true) {
            try {
                job = await getJobDetails(_id);
                //Job creation using virtual studio and website
                if(job?.initiatedSource === "homePage" || job?.initiatedSource === "editor") {
                    resolve(job);
                    break;
                }
                const allPagesSuccessful = job?.pages?.every(page => page?.editorJSONObject?.data);
                if (job.jobOrder_status === "Incomplete" && allPagesSuccessful) {
                    resolve(job);
                    break;
                }
                if(job.status === "Completed"){
                    reject({message:"This Job is Already Completed"});
                    break;
                }
            } catch (error) {
                reject(error);
                break;
            }
        }
    });
}

export async function getJobDetails(_id:string){
    const res = await axiosInstance.get(`v3/exploreTemplates/getJobData?jobId=${_id}`);
    if(!res.data.success) throw new Error(res.data.message);
    return res.data.data;
}

export async function setJobName(_id:string, name:string){
    const res = await axiosInstance.put(`v3/shootsetupjobs/jobName-Update?jobId=${_id}&jobName=${name}`);
    if(!res.data.success) throw new Error(res.data.message);
    return res.data.data;
}

function updateBlurForCastShadow(arr:any) {
    arr.forEach((element:any) => {
        if (typeof element === 'object' && element !== null && Array.isArray(element.tags) && element.tags.includes('cast-shadow')) {
             element.blur = 0;
        }
    });
    return arr;
}

let controller:AbortController;

export async function savePage(image_id:string, file:DMSCanvasFile, initiatedSource: string | undefined = undefined) {
    //Cancel Request
    controller = new AbortController();
    updateBlurForCastShadow(file.layers);
    const payload = {
        "imageId": image_id,
        "editorJSONObject": {
            data: file
        }
    };
    if(initiatedSource)payload["initiatedSource"] = initiatedSource
    await axiosInstance.put("v4/pages/updateEditorJSONObject",
    payload
    ,{signal: controller.signal});
}


export function cancelSavePage(){
    controller?.abort();
}

export function createPage(job_id?:string) {
    if (!job_id) return;
    return axiosInstance.post("v4/pages/addNewPage/" + job_id)
        .then((res) => res.data.data)
        .catch((error) => {
            console.error("Error creating page:", error);
            throw error;
        });
}

// export async function deletePage(image_id:string) {
//     const res = await axiosInstance.delete("v4/pages/deletePage/"+image_id);
//     return res.data.data;
// }

export async function updateCanvas(image_id, template_id) {
    const res = await axiosInstance.put("v4/shootnewLifestyleImage/updateCanvasPreview",{
        productId:image_id,
        lifestyleId:template_id,
        need_to_save: true,
    });
    return res.data.data;
}

export interface SelectedTemplate {
    image_id: string,
    // template_id: string,
}

interface ICheckCostAndConfirmJob {
    job_id:string;
    need_to_save:boolean;
    job_name?:string;
    marketplace_id?:string;
    // guideline: boolean; // If the job has selected preset
    // pre_exist: boolean; // If the job is pre-existing
    // selected_templates?: SelectedTemplate[];
    selected_products?: SelectedTemplate[];
}
//Prev should be canceled if this is called
let checkCostAbortController = new AbortController();
export async function getRequiredCredits(job_id:string, selectedTemplates:SelectedTemplate[]) {
    checkCostAbortController.abort();
    checkCostAbortController = new AbortController();
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: false,
        isProTouchEnabled:true,
        selected_products : selectedTemplates,
    } as ICheckCostAndConfirmJob,{signal: checkCostAbortController.signal});
    return {totalCost:res.data.data.jobOrder_cost, templateCost: res.data.data.templateCost, isComplimentaryShoot: res?.data?.data?.isComplimentaryShoot || false};
}

export async function getProTouchRequiredCredits(job_id:string, selectedTemplates:SelectedTemplate[]) {
    checkCostAbortController.abort();
    checkCostAbortController = new AbortController();
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: false,
        isProTouchEnabled:true,
        selected_products : selectedTemplates
    }) 
    return {totalCost:res.data.data.jobOrder_cost, templateCost: res.data.data.templateCost, isComplimentaryShoot: res?.data?.data?.isComplimentaryShoot || false};
}
/**
 * 
 * @param job_id:string  
 * @param selectedTemplates:SelectedTemplate[]
 * @returns isPaymentDue:boolean
 */
export async function confirmJob(job_id:string, selectedTemplates:SelectedTemplate[]) {
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: false,
        isProTouchEnabled:false,
        selected_products : selectedTemplates,
    } as ICheckCostAndConfirmJob);  
    return (res.data.data?.length > 0)? res.data.data : {};
}

export async function publishTemplate(job_id:string, selectedTemplates:SelectedTemplate[],preExist:boolean,guideline:boolean, isStandardInfographic: boolean, theme: any, workType:any) { 
    const infographicType = isStandardInfographic ? "INTERNAL" : "EXTERNAL";
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: false,
        isProTouchEnabled:false,
        job_type : 'INFOGRAPHICS',
        selected_products : selectedTemplates,
        infographicType: infographicType,
        themes: theme,
        workType: workType
    });  
    return (res.data?.data?.length > 0)? res.data?.data : {};
}

export async function confirmBatchJob(job_id:string, selectedTemplates:SelectedTemplate[]) {
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: false,
        isProTouchEnabled:false,
        selected_products : selectedTemplates
    });  
    return (res.data?.data?.length > 0)? res.data?.data : {};
}
export async function proTouchConfirmJob(job_id:string, selectedTemplates:SelectedTemplate[]) {
    const res = await axiosInstance.put("v5/shootnewLifestyleImage/checkCostAndConfirmJob",{
        job_id,
        need_to_save: true,
        isProTouchEnabled: true,
        selected_products : selectedTemplates,
    } as ICheckCostAndConfirmJob);  
    return res?.data?.data;
}

export async function proTouchConfirmCompletedJob(jobId:string) {
    const res = await axiosInstance.post("v4/jobOrders/cloneJobAndSendForProtouch",{jobId})
    return res?.data?.data;
}


export async function getCategories() {
    const res = await axiosInstance.get("/v5/shootnewLifestyleImage/fetchCategoryListNew");
    return res?.data?.data?.data || []; 
}

export async function getMarketPlaces() {
    const res = await axiosInstance.get("/v4/shootnewLifestyleImage/getShootMarketPlace");
    return res?.data?.data;
}

export async function updateJobBatchEditor(job_id: string, customDetails: any){
    if(customDetails.position === "center") customDetails.position = 'centre';
    const payload = {
        jobId: job_id,
        customDetails: customDetails
    }
    const res = await axiosInstance.post("/v5/shootnewLifestyleImage/updateJobOrder", payload);
    return res.data; 
}
export async function updateJobBatchEditorExistingMarketPlace(payload: any){
    const res = await axiosInstance.post("/v4/shootnewLifestyleImage/updateExistingJob", payload);
    return res.data; 
}
export async function updateRawImageInEditorObject(JobId) {
    const url = `/v3/exploreTemplates/updateRawImageInEditorObject?jobId=${JobId}`;
    try {
    const res = await axiosInstance.put(url);
    return res.data; 
    } catch (error) {
        console.error('Error updating raw image:', error);
        throw error;
    }
}

export async function getHotSpots({queryKey:[_,_id]}) {
    const res = await axiosInstance.get(`v1/backgroundCreation/get-hotspot-data/${_id}`);
    return res.data.data;
}

export async function createNewTemplate(url:string, baseTemplateId:string, type:string = "DALLE", status:string = "APPLY") {
    const res = await axiosInstance.post("v3/exploreTemplates/insertSelectedBackgroundVariation",{
        lifestyleImageId: baseTemplateId,
        selectedVariation: url,
        templateType:type,
        status:status
    });
    return res.data.data;
}

export async function getDummyJobs() {
   return (await axiosInstance.get('v3/exploreTemplates/fetchDummyJobs')).data?.data?.jobsList || [];
}
export async function updateInstruction(joborderId, instructionArray) {
    const comment = instructionArray.toString();
    const instruction = instructionArray.toString();
    return API_SERVICE({
        url: 'v1/api/chat/updateInstruction',
        baseURL: process.env.NEXT_PUBLIC_API_URL_V2 || "",
        method: "POST",
        data: {joborderId, comment, instruction},
      });
 }
 
 export async function updateInstructionMedia(payload: FormData) {
    try {
      return await API_SERVICE({
        url: 'v1/api/chat/updateInstruction',
        baseURL: process.env.NEXT_PUBLIC_API_URL_V2 || "",
        method: "POST",
        data: payload,
      });
    } catch (error) {
      console.error('Error sending message:', error);
      throw error; // Rethrow error for handling in the caller function
    }
  }

 export async function mergeWebsiteJob(JobId?:string, sourceEntityId?:string, destinationEntityId?:string, destinationUserId?:string) {
    if(!JobId || !sourceEntityId || !destinationEntityId || !destinationUserId) return;
    const res = await axiosInstance.post("/v4/jobOrders/verified-merge-joborder",{JobId,sourceEntityId, destinationEntityId, destinationUserId});
    return res.data.data;
}

 export async function entitiesCreatedByUserV4 (userid, skip, limit, search,type="") {
    let url = `/api/v4/users/entities/list/${userid}/${skip}/${limit}?type=${type}`
    if (search) url += `&searchKey=${search}`;
    return (await axiosInstance.get(url))
 }

 export const getImportCSVJobPolling = async(payload:any, signal:any) => {
    return await axiosInstance.post('/v5/shootnewLifestyleImage/pollingForImportInfographicsStatus', payload, signal)
};

export async function longPollImportCSVJob(payload: any, signal:any) {
    return new Promise(async (resolve, reject) => {
        const pollingInterval = 1000;
        let response: any;
        
        const poll = async () => {
            try {
                let flag = false;
                response = await getImportCSVJobPolling(payload, signal);
                const result = response?.data?.data || [];
                for(let i=0;i<result?.length;i++){
                    if(result[i]?.status === "FAILED"){
                        reject("Error in fetching variant");
                    }
                    if(result[i]?.status !== "UPLOADED"){
                        flag = true;
                    }
                }
                if(!flag){
                    resolve(response);
                }else{
                    setTimeout(poll, pollingInterval);
                }
            } catch (error) {
                reject(error);
            }
        };
        
        poll();
    });
}

export async function updateMarketPlace(jobId: string, marketplaceId: string) {
    const res = await axiosInstance.post("/v4/shootnewLifestyleImage/updateExistingJob",{
        jobType: "UPDATE",
        jobId: jobId, 
        marketplaceId: marketplaceId, 
    });
    return res.data;
}

export async function getThemes (){
    const res = await axiosInstance.get('v4/metadataV4/get-themes-strapi');
    return res?.data?.data?.SelectThemes;
}

export async function updateInstantDownloadImages (jobId){
    const res = await axiosInstance.put(`/v4/shootnewLifestyleImage/updateInstantDownloadImages?jobId=${jobId}`);
    return res;
}