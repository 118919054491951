import { css } from "@emotion/react";
import DropdownSelection from "src/modules/ui-elements/DropdownSelection";
import { ICONS, Icon } from "src/modules/ui-elements/Icon";
import IconButton, {
  IconButtonSizes,
  IconButtonTypes,
} from "src/modules/ui-elements/IconButton";
import { BACKGROUND_MODE, FORMAT_TYPES, TEMPLATE_TYPE } from "../index";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import ToggleButton from "src/components/toggleSwitch/ToggleButton";
import { useState } from "react";
import useComponentVisible from "src/hooks/useComponentVisible";
import BtnWithCheck from "src/modules/ui-elements/BtnWithCheck";
import { activePageAtom, backgroundFilterAtom, backgroundFilterProductImageIdAtom, backgroundFilterTemplateTypeAtom, pagesAtom,hotspotFilterAtom } from "../../../Atoms";
import { useAtom, useAtomValue } from "jotai";
import SearchOption from "./SearchOption";
import TemplateTopSection from './TemplateTopSection';
import TemplateBottomSection from './TemplateBottomSection';
import {useSuggestionList} from '@/hooks/SuggestionFilterHook';

export default function TemplatesHeader({
  templatesResult,
  templatesResultIsLoading,
  backgroundMode,
  prespectiveList,
  prespectiveListLoading,
  setBackgroundMode,
  productImageLayerId,
  disableResultBar
}) {
  const debounceRecentRef = useRef(null);
  const queryClient = useQueryClient();
  const [switchOn, setSwitch] = useAtom(hotspotFilterAtom);
  const [pages, setPages] = useAtom(pagesAtom);
  const [activePage, setActivePage] = useAtom(activePageAtom);
  const [selectedFilter,setSelectedFilter] = useAtom(backgroundFilterAtom);
  const [templateType,setTemplateType] = useAtom(backgroundFilterTemplateTypeAtom);
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const activeEditor = useMemo(() => pages?.[activePage], [pages, activePage]);
  const {recommendedList} = useSuggestionList();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


  const onSearchHandler = (item) => {
    setIsComponentVisible(false);
    if (!item) return;
    if (selectedFilter.searchString.find((txt) => txt === item)) {
      setSelectedFilter((prev) => ({ ...prev, searchText: "" }));
      return;
    }
    if (debounceRecentRef.current) clearTimeout(debounceRecentRef.current);
    setSelectedFilter((prev) => ({
      ...prev,
      searchString: [...prev.searchString, item],
      searchText: "",
      updateRecentSearch: true,
    }));
    debounceRecentRef.current = setTimeout(() => {
      setSelectedFilter((prev) => ({ ...prev, updateRecentSearch: false }));
    }, 600);
  };

  const onSelectAngleHandler = (data) => {
    setTemplateType(TEMPLATE_TYPE.ALL)
    if(data?.angleGroupId){
    const layer = activeEditor.layerManager._layers.value.find(
      (x) => x.id == productImageLayerId
    );
    if (!layer) return;
    layer.getTags()?.filter((obj) => {
      if (obj?.includes("angle-group")) layer.removeTag(obj);
    });
    layer.addTag("angle-group");
    layer.addTag("angle-group-id-" + data?.angleGroupId);
    layer.addTag("angle-group-name-" + data?.angleGroupName);
    setSelectedFilter((prev) => ({
      ...prev,
      prespective: {
        angleGroupId: data?.angleGroupId,
        angleGroupName: data?.angleGroupName,
      },
    }));
    setBackgroundMode(BACKGROUND_MODE.BASIC);
  }else{
    const layer = activeEditor.layerManager._layers.value.find(
      (x) => x.id == productImageLayerId
    );
    if (!layer) return;
    layer.getTags()?.filter((obj) => {
      if (obj?.includes("angle-group")) layer.removeTag(obj);
    });
    setSelectedFilter((prev) => ({
      ...prev,
      prespective: {},
    }));
    setBackgroundMode(BACKGROUND_MODE.SELECTANGLE);
  }
  };

  const { entireStrapiData } = useSelector((state) => state.strapi);
  const strapiData =
    entireStrapiData?.StudioBackgroundText?.StudioBackgroundText;
  const [onLifestyle, setLifestyle] = useState(true) ;
  const [onInfographics, setInfographics] = useState(false); 
  //To handle infographics payload 
  useEffect(() => {
    if (onInfographics && !onLifestyle) {
      setSelectedFilter((prev) => ({ ...prev, isInfographics: true }));
    } else if (!onInfographics && onLifestyle) {
      setSelectedFilter((prev) => ({ ...prev, isInfographics: false }));
    } else if (!onInfographics && !onLifestyle) {
      setLifestyle(true);
      setInfographics(true);
    } else{
    setSelectedFilter((prev) => {
      const { isInfographics, ...rest } = prev;
      return rest;
    });}
  },[onInfographics, onLifestyle])
  return (
    <div css={styles.container}>
      <TemplateTopSection
        strapiData={strapiData}
        templateType={templateType}
        setTemplateType={setTemplateType}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        prespectiveList={prespectiveList}
        prespectiveListLoading={prespectiveListLoading}
        setBackgroundMode={setBackgroundMode}
        onSelectAngleHandler={onSelectAngleHandler}
        trendingList={templatesResult}
        trendingListLoading={templatesResultIsLoading}
        onSearchHandler={onSearchHandler}
      />
      {/* {backgroundMode !== BACKGROUND_MODE.SELECTANGLE && !disableResultBar && (
        <>
          {templateType !== TEMPLATE_TYPE.RECENT &&
            templateType !== TEMPLATE_TYPE.FAVOURITE && 
            <TemplateBottomSection
            selectedFilter={selectedFilter}
            onRemoveHandler={onRemoveHandler}
            hotspotOnly = {switchOn}
            switchOnHandler = {switchOnHandler}
          />}
        </>
      )} */}
      <div css={styles.divider}></div>
    </div>
  );
}

const styles = {
  container: css`
    padding-top: 16px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  `,
  tools: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0px 0px 0px;
    position: relative;
    gap: 8px;
    margin-top:8px;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
  `,
  inputBoxContainer: css`
    width: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid rgba(238, 238, 238, 0.4);
  `,
  inputBox: css`
    flex: 1;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    gap: 8px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      color: rgba(255, 255, 255, 0.7);
      font-family: Objectivity;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
    }
  `,
  divider: css`
    position: absolute;
    width: 100%;
    height: 0.5px;
    left: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.24);
  `,
  customizedOnly: css`
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    padding: 5px 10px 4px;
    background-color: #464646;
    color: rgba(255, 255, 255, 0.7);
    font-family: Objectivity;
    font-size: 10px;
    line-height: 1.5;
    min-width: 50px;
  `,
  suggestionTrendingContainer: (active) => css`
    width: 100%;
    position: absolute;
    background-color: #fff;
    top: 41px;
    left: 0px;
    flex-direction: column;
    jsutify-content: center;
    align-items: center;
    border-radius: 0px 0px 5px 5px;
    z-index: 1;
    display: ${active ? "flex" : "none"};
  `,
  trendingHeader: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 10px;
    color: rgba(38, 38, 38, 0.7);
    font-family: "Objectivity";
    font-size: 10px;
  `,
  trendingAndRecentOptionsContainer: css`
    display: flex;
    padding: 0px 10px 10px;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  trendingAndRecentOptions: css`
    border-radius: 4px;
    background: #f9f9f9;
    display: flex;
    padding: 6px 8px;
    align-items: center;
    width: fit-content;
    gap: 4px;
    color: #262626;
    font-family: "Objectivity";
    font-size: 12px;
    cursor: pointer;
    span {
      max-height: 18px;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,
  pointer: {
    cursor: "pointer",
  },
  middleCenterContainer: css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    height: 50px;
    gap: 8px;
  `,
  middleBottomContainer: css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    margin-top:8px;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
  `,
};
