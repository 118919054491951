import { css } from "@emotion/react";
import { Icon, ICONS } from "src/modules/ui-elements/Icon";
import IconButton, { IconButtonSizes, IconButtonTypes } from "src/modules/ui-elements/IconButton";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { aspectRatioLockAtom, selectedLayerAtom, isShadowPanOpenAtom, isLayersPaneVisibleAtom } from "@/modules/editor-v2/Atoms";
import { capitalCase } from "change-case";
import Layer from "local_modules/editor/src/Layer";
import { startCase } from 'lodash';
import useEditorService from "@/hooks/useEditorService";
import useEditorLayerService from "@/hooks/useEditorLayerService";
import { useSelector } from "react-redux";
import EditName from "../LayersPane/EditName";
import { useState } from "react";
import useAuth from "@/hooks/useAuth";

export default function RightPaneHeader() {
    const selectedLayer = useAtomValue<Layer>(selectedLayerAtom);
    const aspectRatioLock = useAtomValue(aspectRatioLockAtom)
    const shadowPanVisible= useAtomValue(isShadowPanOpenAtom);
    const [isLayersPaneVisible, setIsLayersPaneVisible] = useAtom(isLayersPaneVisibleAtom);
    const setShadowSelector = useSetAtom(isShadowPanOpenAtom);
    // const {undo, redo} = useEditorService();
    const {deleteSelected, setSelectedAspectRatioLock} = useEditorLayerService();
    const [edit, setEdit] = useState(false);
    
    const cancelShadowSelector = () => setShadowSelector(prev=>!prev);

 
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudioBackgroundText?.StudioBackgroundText;
    const {user} = useAuth();
    const isInfoGraphicUser = user?.user?.infoGraphicsUser || false;
    
    const onEdit = (name) => {
        selectedLayer.setName(name); 
        setEdit(false)
    }

    const toggleEdit = () => {
        setEdit(x=>!x);
    }

    if(edit && selectedLayer) return <div style={{display:"flex", minHeight:50}}><EditName defaultValue={selectedLayer.name} onSuccess={onEdit} onCancel={toggleEdit} /></div>

    return <div css={styles.container(isLayersPaneVisible)}>
                {shadowPanVisible && <Icon src={ICONS.chevronLeftDark} height={24} width={24} style={styles.mr8} onClick={cancelShadowSelector} />}
                {isLayersPaneVisible && <Icon src={ICONS.chevronLeftDark} height={24} width={24} style={styles.mr8} onClick={()=>setIsLayersPaneVisible(false)} />}
                {!isLayersPaneVisible && <div css={{...styles.title}}>
                    {capitalCase(startCase(selectedLayer?.name || " ")) || (strapiData?.Heading ||"Background")}
                    {(selectedLayer && isInfoGraphicUser) && <div css={styles.editIcon} onClick={toggleEdit}> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16" fill="none">
                            <path d="M12.8375 5.24552C13.0542 5.02888 13.0542 4.66782 12.8375 4.4623L11.5377 3.16248C11.3322 2.94584 10.9711 2.94584 10.7545 3.16248L9.7324 4.179L11.8154 6.26205M3 10.917V13H5.08304L11.2266 6.85085L9.14359 4.76781L3 10.917Z" fill="#262626"/>
                        </svg>
                    </div>}
                </div>}
                {isLayersPaneVisible && <div css={{...styles.title}}>{strapiData?.LayerText || `Layers`}</div>}
                {/* <Icon src={ICONS.rightPaneUndo} height={24} width={24} style={styles.mr8} onClick={undo}/>
                <Icon src={ICONS.rightPaneRedo} height={24} width={24} style={styles.mr16} onClick={redo}/> */}
                {/* {!isLayersPaneVisible && <><IconButton type={(aspectRatioLock)?IconButtonTypes.dark:IconButtonTypes.light} size={IconButtonSizes.smBigIcon} icon={ICONS.rightPaneUnlock} style={styles.mr16} onClick={()=>setSelectedAspectRatioLock(!aspectRatioLock)} tooltipText={strapiData?.AspectRatioHover || "Aspect Ratio Lock"}/>
                <IconButton type={IconButtonTypes.light} size={IconButtonSizes.smBigIcon} icon={ICONS.rightPaneDelete} onClick={deleteSelected} /></>} */}
            </div>
}

const styles = {
    container: (isLayersPaneVisible) => css`
        box-shadow: 0px 3px 32px rgba(38, 38, 38, 0.05);
        display: flex;
        padding: ${isLayersPaneVisible ? "14px 16px" : "19px 16px"};
        align-items: center;
        border-left: 1px solid #DEDEDE;
        width: 320px;
    `,
    title: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #000000;
        flex: 1;
        display: flex;
        align-items:center;
        
    `,
    mr8: {
        marginRight: 8,
    },
    mr16: {
        marginRight: 16,
    },
    editIcon: css`
        display: flex;
        width: 20px;
        height: 14px;
        padding: 2px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 4px;
        background: #EEE;
        margin-left:6px;
        cursor: pointer;
    `,
    mb10: {
        marginBottom: '10px'
    }
}