import { css } from "@emotion/react"
import ColorListItem from "./ColorListItem"

export default function ColorOptions({title, colors, type, onClick=()=>{}}) {
    return<>
        <div css={styles.textContainer}>{title}</div>
        <div css={styles.colorsContainer}>
            {colors?.map(color=><ColorListItem type={type} color={color} onClickHandler={onClick} />)}
        </div>
    </> 
}

const styles = {
    textContainer: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        margin-bottom:12px;
        color: #262626;
    `,
    colorsContainer: css`
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        margin-bottom:12px;
    `
}