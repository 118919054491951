import { Box, Dialog, DialogContent, Typography, Button, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import typography from "src/theme/typography";
import { useSelector } from "react-redux";
import { completeStrapiData } from "src/strapiData";
import { css } from "@emotion/react";
import IconButton, {IconButtonSizes, IconButtonTypes} from "../ui-elements/IconButton";

import QRCode from 'qrcode.react';
import API_SERVICE from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import { ICONS, Icon } from "../ui-elements/Icon";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const GetAppLinkByScan = ({ open, onClose }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const {entireStrapiData} = useSelector((state)=> state.strapi)
  const getAppData = entireStrapiData?.ProfileWebapp?.ProfileWebapp?.GetTheAppOnProfile;
  const defaultStrapiData = completeStrapiData?.ProfileWebapp?.ProfileWebapp?.GetTheAppOnProfile;
  const strapiData = entireStrapiData?.GetTheAppPopUpStudio?.GetTheAppPopUpStudio;
  const featureArray= [["/icons/app-shoot.svg", strapiData?.InAppText || "In-App Shoot Guides"], ["/icons/marketplace-ready-shoot.svg", strapiData?.MarketplaceReadyText || "Marketplace-Ready Shots"],["/icons/unlimited-product.svg", strapiData?.UnlimitedText || "Unlimited Product-Centric Backgrounds"], ["/icons/high-resolution.svg", strapiData?.HighResoText || "High-Resolution images"]]
  const {user} = useAuth();
  const interval = 600;//in sec
  const waitTime= interval * 1000;//in ms
  const goToQR = async() => {
    const response = await API_SERVICE.post(`${process.env.NEXT_PUBLIC_API_URL_V2}api/v4/users/get-branch-link-qrCode`);
    setURL(response?.data?.data?.branchLink)
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    if(user?.user?.login_type !== "Guest" && open )
    {
    goToQR();
    const intervalId = setInterval(goToQR, waitTime);
    return () => {
    window.removeEventListener("resize", handleWindowResize);
    clearInterval(intervalId);

    };}
  }, [open]);
  const [URL, setURL] = useState("")
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      sx={{
        m: "auto",
        p: 0,
        border: "none",
        width: "60vw",
        minWidth: "600px",
        maxWidth: "800px",
        height: "525px",
        boxShadow: "none",
        overflow: "hidden",
        boxShadow: "0px 3px 32px rgba(38, 38, 38, 0.16)",
        borderRadius: "8px",
      }}
    >
      <DialogContent sx={{ display: "flex", p: 0, position: "relative" }}>
        <Box
          position="absolute"
          top={"16px"}
          right={"16px"}
          sx={{ cursor: "pointer" }}
          onClick={onClose}
        >
          <img src={getAppData?.CrossIcon || defaultStrapiData?.CrossIcon }
          alt="" width="24px" height="24px" />
        </Box>
        <Box
          flex={1}
          height="100%"
          position="relative"
          display={"flex"}
          flexDirection="column"
          alignItems={"space-between"}
          fontSize={"12px"}
        >
          <div style={styles.leftContainer}>
          <Typography sx={styles.subtitle1}>
            {strapiData?.StudioQualityImagesText || "Studio-Quality Images Anytime, Anywhere"}
          </Typography>
          <div>
            {
              featureArray && featureArray?.map((value)=>(
                  <div style={styles.subtitle2}>
                  <IconButton icon={value?.[0]} size={IconButtonSizes.smMidIcon} type={IconButtonTypes.pink} /> 
                  {value?.[1]}
                  </div>
               ))
            }
          </div>
          </div>
            {/* <img
              src="/icons/mobileScreen.svg"
              alt=""
              width="160px"
              height="160px"
            /> */}
            
          <img src="/icons/getAppVector.svg" style={styles.getAppVector}></img>
            <img src={getAppData?.GetTheAppImage || defaultStrapiData?.GetTheAppImage} alt="" style={styles.getAppImage}/> 
        </Box>
        <Box
          height="100%"
          sx={styles.rightContainer}
          flex={1}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
            flex={0.3}
            gap="8px"
          ><div style={styles.store}> 
              <div css={styles.storeLogo}><Icon src={ICONS.playstore} width={14} height={14}/><span>{strapiData?.GooglePlayText||"Google Play"}</span> </div>
              <div css={styles.storeLogo}><Icon src={ICONS.appleDark} width={12} height={12}/><span>{strapiData?.AppStoreText||"App Store"}</span></div>
            </div>
              <h1 css={styles.headerName}>{strapiData?.DownloadTheDMS || "Download the DoMyShoot App"}</h1>
            <div css={styles.textStyle}>
            {strapiData?.ScanToStartText||"Scan to Start Shoot"}
            </div>
            <Box
            flex={1}
            display="flex"
            width="100%"
            alignItems={"center"}
            justifyContent="center"
          > 
          {user?.user?.login_type === "Guest" ?  <img
              src="/images/getAppNew.svg"
              alt=""
              width="200px"
              height="200px"
            /> : URL && <QRCode value={URL} size={200} 
            imageSettings={{
              src:"/icons/Logo.png",
              excavate:true,
              height: "40",
              width: "40",
            }} />
           }
          </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  headerName:css`
  text-align: center;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  background: linear-gradient(97deg, #F03750 5.71%, #EB2E83 136.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px;
  `,
  textStyle:css`
  color: rgba(38, 38, 38, 0.70);
  text-align: center;
  font-family: Objectivity;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-Bottom: 40px;
  `,
  subtitle1: {
    fontSize: "14px", 
    fontWeight: 500, 
    paddingBottom: "10px", 
    lineHeight: "170%"
  },
  subtitle2: {
    display:"flex", 
    flexDirection:"row", 
    marginBottom: "10px", 
    marginRight: "8px", 
    alignItems: "center"
  },
  leftContainer: {
    background: "radial-gradient(742.16% 3217.25% at 0% -3.91%, rgba(95, 46, 235, 0.05) 0%, rgba(235, 46, 131, 0.05) 31.65%, rgba(210, 2, 2, 0.05) 71.24%, rgba(240, 55, 80, 0.05) 78.3%)",
    paddingLeft: "24px", 
    paddingTop: "24px"
  },
  getAppVector: {
    background: "radial-gradient(742.16% 3217.25% at 0% -3.91%, rgba(95, 46, 235, 0.05) 0%, rgba(235, 46, 131, 0.05) 31.65%, rgba(210, 2, 2, 0.05) 71.24%, rgba(240, 55, 80, 0.05) 78.3%)",
    height: "110%"
  },
  getAppImage: {
    position: "absolute", 
    top:"45%", 
    left: "35%", 
    height: "250px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    pt: "60px",
    pl: "20px",
    pr: "20px"
  },
  store: {
    display: "flex",
    gap:"15px"
  },
  storeLogo:css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:8px;
  gap:5px;
  span{
    margin-top:3px;
  }
  `
}
export default GetAppLinkByScan;
