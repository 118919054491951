const strapiData = {
    "message": "success",
    "data": {
        "VideoBALink": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/Pro-touch+Final_V1.mp4",
        "Homepage": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "Homepage": {
                "id": 1,
                "ForNewUserOrNoShoot": {
                    "id": 1,
                    "LooksLikeText": "Looks like you haven’t shot any product images yet! \n",
                    "DownloadTheAppText": "Download DoMyShoot and start clicking product photos \n",
                    "GooglePlayIcon": "https://cms.dresma.com/uploads/google_play_computer_icons_android_play_button_ac8cd7fa98.svg?updated_at=2022-06-02T10:16:44.400Z",
                    "GooglePlay": "Google Play",
                    "AppStoreIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/apple1.png",
                    "AppStore": "App Store",
                    "SendMeLinkButtonText": "Get the App",
                    "MobileButtonIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/mobile-solid.png",
                    "StartShootingText": "Click images on our mobile app",
                    "YourFirstProject": "Your First Project",
                    "FolderIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/image.png",
                    "StartShoot": "Start a shoot",
                    "MobileIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/tablet-1.png"
                },
                "HomepageExploreTemplates": {
                    "id": 1,
                    "ViewAllTemplatesButtonText": "View All ",
                    "ViewTemplatesNextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "BrowseOurTemplatesText": "Browse our unique, product-centric lifestyle backgrounds and create stand-out images in minutes! ",
                    "LeftIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
                    "RightIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "ExploreTemplates": "Explore Backgrounds"
                },
                "TemplateEditor": {
                    "id": 1,
                    "StartEditingText": "Create custom lifestyle images for your marketing content in 3 Steps:\n",
                    "GoToEditorButtonText": "Get Started",
                    "Heading": "Image Editor",
                    "Image": "https://assets.dresma.com/DoMyShoot/App%20Screens/326.png"
                },
                "EditorSteps": [
                    {
                        "id": 1,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/icon.png",
                        "Text": "Pick a product photo from your completed projects"
                    },
                    {
                        "id": 2,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/icon-1.png",
                        "Text": "Select a background that fits best"
                    },
                    {
                        "id": 3,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/icon-2.png",
                        "Text": "Customize by adding elements of your choice and refresh your product catalog"
                    }
                ],
                "ZoomUpScreen": {
                    "id": 1,
                    "CrossIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/circle-with-cross.png",
                    "AvailableIn": "Available In:",
                    "CompatiableFor": "Compatible for:",
                    "GoToEditorButtonText": "Go to Editor",
                    "GoToEditorButtonNextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png"
                },
                "ForExistingUser": {
                    "id": 1,
                    "WelcomeText": "Hi ${data.name}, welcome to DoMyShoot!\n",
                    "GetProText": "Get studio-quality images for eCommerce, Marketplaces, & Social Media\n"
                },
                "RecentProjects": {
                    "id": 1,
                    "ViewAllProjectsButtonText": "View All Projects",
                    "NextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "Heading": "Recent Projects",
                    "InProcessText": "In Process",
                    "InProcessIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/inprocess-shoot-gray.png",
                    "ImagesText": "Images",
                    "CompletedText": "Completed",
                    "CompletedIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/completed-shoot-gray.png"
                },
                "RecommendedTemplates": {
                    "id": 1,
                    "Heading": "Recommended Backgrounds For Your Product Shoot",
                    "ViewMoreButtonText": "View ",
                    "NextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "AvailableIn": "Available In:",
                    "GoToEditorButtonText": "Go to Editor",
                    "GoToEditorButtonNextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png"
                },
                "GetTheMobileAppLinkPopUp": {
                    "id": 1,
                    "GetTheMobileAppHeading": "Get the Mobile App",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "YouWillGetTheMobileAppText": "You will receive a download link on your mobile number or email",
                    "MobileNumber": "Mobile Number",
                    "EmailId": "Email Address",
                    "PlaceholderForEmail": null,
                    "PlaceholderForMobileNumber": null,
                    "EditIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/edit-profile.png",
                    "SendMeLinkButtonText": "Send me a link",
                    "MobileIconOnSendMeLinkButton": null,
                    "PleaseEnterAValidMobileNumber": "Please enter a valid mobile number",
                    "PleaseEnterAValidEmailId": "Please enter a valid email",
                    "AppLinkSentSuccessfullyText": "Download link sent successfully",
                    "SuccessfullIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/payment%20success.png"
                }
            }
        },
        "AccountSetting2Web5": {
            "createdAt": "2023-06-08T11:05:30.832Z",
            "updatedAt": "2023-06-23T04:47:08.963Z",
            "publishedAt": "2023-06-08T11:32:51.642Z",
            "locale": "en",
            "AccountSetting2Web5": {
                "id": 1,
                "Heading": "Account",
                "YourPrefrences": "Your Preferences",
                "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
                "DefaultPresetText": "Default Preset",
                "DefaultPresetIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/86.png",
                "PreferedCatHeading": "Preferred Categories",
                "PreferedCatIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/218.png",
                "TickIcon": null,
                "TeamPermissionText": "Team Permissions",
                "test12312": null,
                "BillingDetailsWeb2": {
                    "id": 1,
                    "Heading": "Billing Details",
                    "CompanyName": "Company name",
                    "BillingAddress": "Billing address",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "ZipCode": "Zip Code",
                    "TaxId": "Tax ID",
                    "Optional": "Optional",
                    "EmailAddress": "Email Address",
                    "Phone": "Phone",
                    "PhonePlaceholder": "XXXXXXXXXX",
                    "SaveCTA": "Save",
                    "CancelCTA": "Cancel",
                    "YourCountryText": "Your country",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "PleaseContactText": "Contact our Sales Team to change your selected country",
                    "ChangeCountryCTA": "Change country"
                },
                "ContactYourSales2": {
                    "id": 1,
                    "Heading": "Contact our sales team",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "OurSalesTeam": "Our sales team will get back to you within a 24 Hr",
                    "Name": "Name",
                    "NamePlaceholder": "Stephen",
                    "WorkEmailAddress": "Work email address *",
                    "WorkEmailPlaceholder": "account@gmail.com",
                    "Company": "Company",
                    "CompanyPlaceholder": "Ex. Myntra",
                    "PhoneNumberText": "Phone number *",
                    "PhoneNumberPlaceholder": "XXXXXXXXXX",
                    "SubmitCTA": "Submit"
                },
                "DeleteAccountWeb": {
                    "id": 1,
                    "Heading": "Delete Account",
                    "ThisWillRemove": "This will permanently delete your personal data.",
                    "DeleteMyAccountCTA": "Delete my account",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "AreYouSureText": "Are you sure, you want to delete the Account? This will permanently delete your personal data.",
                    "YesDeleteText": "Yes, Delete",
                    "CancelText": "Cancel"
                },
                "ActivateOfferWebapp8": {
                    "id": 1,
                    "Heading": "Activate Offer",
                    "DoYouHaveCoupon": "Enter coupon code and activate the offer now!",
                    "CodeText": "Code",
                    "ActivateOfferText": "Activate Offer",
                    "GreenTickIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/212.png",
                    "AppliedText": " Applied",
                    "CodeText2": "Code ",
                    "AppliedSuccessfullyText": " Applied Successfully",
                    "OkayCTA": "Okay",
                    "RemoveCTA": "Remove"
                },
                "ManageTeamWebapp": {
                    "id": 1,
                    "Heading": "Manage Team",
                    "PeopleText": "users",
                    "InviteIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/25.png",
                    "ActiveProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/280.png",
                    "InvitedProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/286.png",
                    "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
                    "YouText": " (You)",
                    "InvitedText": "Invited",
                    "OwnerText": "Owner",
                    "InviteMemberCTA": "Invite Member"
                },
                "InviteMembersWebapp": {
                    "id": 1,
                    "Heading": "Invite Members",
                    "TeamAllowsText": "Teams enable multiple individuals to collaborate on projects, establish brand presets, and exchange media files.",
                    "InviteByEmail": "Invite by Email",
                    "EnterEmailAddress": "Enter email address",
                    "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
                    "AddMorePeople": "Add more people",
                    "AddIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/283.png",
                    "SendInvitationCTA": "Send Invitations",
                    "MemberHasAlreadyText": "Member has already been added",
                    "PleaseEnterAValidText": "Please enter a valid email id",
                    "InvitationSentText": "Invitation Sent",
                    "InvitationTickIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/284.png",
                    "WhiteTickIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/284.png",
                    "InviteByEmailText": "Invite By Email",
                    "MembersWillBeText": "Members will be only added after they accept the invites in the email.",
                    "AssignCreditLimit": "Assign Shoot Limit",
                    "CreditIcon": null,
                    "BalanceCreditText": "Balance Credit",
                    "ChevronUpIcon": null,
                    "CreditLimitText": "Shoot Limit",
                    "CreditStatusText": "Credit Status",
                    "CrossIcon": null,
                    "CreditText7": "Credit",
                    "MemberLimitMessage": "Members will be only added after they accept the invites in the email.",
                    "MessageCrossIcon": null
                },
                "EntityMemberListOption": [
                    {
                        "id": 1,
                        "OptionName": "Administrator"
                    },
                    {
                        "id": 2,
                        "OptionName": "Manager"
                    },
                    {
                        "id": 3,
                        "OptionName": "Member"
                    }
                ],
                "PreferredCategoryWebapp": {
                    "id": 1,
                    "Heading": "Product Categories",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "HideUnusedText": "Hide unused categories",
                    "AllCategoryText": "All Categories",
                    "AllSelected": "All Selected",
                    "DoneCTA": "Done"
                },
                "DefaultPresetWebapp": {
                    "id": 1,
                    "Heading": "Presets",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "OnlyOneText": "You can select one preset",
                    "DoneCTA": "Done",
                    "DefaultPresetText": "Default Preset: ",
                    "YouHaveBeenText": "You have designated \" \" as the default preset, which will be automatically applied to all new shoots. To modify the preset during a shoot, simply click on the preset button located on the camera screen.",
                    "ConfirmCTA": "Confirm",
                    "CancelCTA": "Cancel"
                },
                "ProjectHistoryWebNew": {
                    "id": 9,
                    "Heading": "Project History",
                    "CreditsText": "Credits",
                    "FreeText": "Free",
                    "ImagesText": "Images",
                    "DeliveredText": "Delivered",
                    "InProcessText": "In Process",
                    "AdaptationInProcess": "Adaptation In Process",
                    "ProjectsText": "Projects",
                    "ProductCategory": "Product Category",
                    "DateText": "Date",
                    "DateDropDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png"
                },
                "PaymentHistoryWebNew": {
                    "id": 9,
                    "Heading": "Payment History",
                    "Plans": "Plans",
                    "OrderId": "Invoice ID",
                    "Amount": "Amount",
                    "Invoice": "Invoice",
                    "PaymentReceipt": "Payment Receipt",
                    "PayPerUseText": "Pay Per Use",
                    "CreditsText": "Credits",
                    "PlansProText": "Pro",
                    "PlanSoloText": "Solo",
                    "DownloadIcon": null,
                    "DownloadInvoiceText": "Download Invoice",
                    "PendingText": "Pending",
                    "FailedText": "Failed",
                    "DownloadReceiptText": "Download Receipt"
                },
                "ProfileWebappNew": {
                    "id": 25,
                    "Heading": "Profile",
                    "ProfilePhotoText": "Profile Photo",
                    "UpdateYourProfilePhotoText": "Upload your profile photo",
                    "UploadPhoto": "Upload Photo",
                    "Name": "Name",
                    "PlaceholderForName": null,
                    "EmailAddress": "Email Address",
                    "PlaceholderForEmail": null,
                    "Mobile": "Mobile",
                    "PlaceholderForMobile": null,
                    "CancelButtonText": "Cancel",
                    "SaveButtonText": "Save",
                    "EmailEditText": "Update",
                    "MobileEditText": "Update",
                    "UpdateText": "Update",
                    "AddYourMobileText": "Add Your Mobile",
                    "AddYourEmailText": "Add Your Email",
                    "PlusActiveIcon": null,
                    "PlusInactiveIcon": null,
                    "NameEditText": "Edit",
                    "SaveCTA": null,
                    "CancelCTA": null,
                    "UpdateEmailText": null,
                    "PleaseEnterNewEmail": null,
                    "NewEmailAddress": null,
                    "CrossIcon": null,
                    "SendOtpCTA": null,
                    "VerifyYourEmail": null,
                    "ResendOtpInText": null,
                    "AVerificationCodeText": null,
                    "DoneCTA": null,
                    "EmailUpdatedSuccefully": null,
                    "SuccessfulIcon": null,
                    "MobileUpdatedSucces": null,
                    "AddMobileText": null,
                    "PleaseAddMobile": null,
                    "MobileNumberText": null,
                    "VerifyYourMobile": null,
                    "VerificationCodeMobile": null,
                    "AddYourEmail": null,
                    "AddYourMobile": null,
                    "ActivePlusIcon": null,
                    "InActivePlusIcon": null,
                    "AddEmailHeading": null,
                    "AddMobileHeading": null
                },
                "AdminRoleWebText": [
                    {
                        "id": 25,
                        "Role": "Invite Members and Manage Team"
                    },
                    {
                        "id": 27,
                        "Role": " Create, View and Download all Projects"
                    },
                    {
                        "id": 26,
                        "Role": "Allocate and Purchase Credits"
                    }
                ],
                "ManagerRoleTextWeb": [
                    {
                        "id": 1,
                        "Role": "Create, View, Approve, and Download all projects"
                    },
                    {
                        "id": 2,
                        "Role": " Purchase credits"
                    }
                ],
                "UserRoleTextWebapp": [
                    {
                        "id": 1,
                        "Role": "Create, Submit and View self-created projects"
                    }
                ],
                "MultiuserRoleListWeb": [
                    {
                        "id": 43,
                        "Role": "As Administrator"
                    },
                    {
                        "id": 44,
                        "Role": "As Manager"
                    },
                    {
                        "id": 45,
                        "Role": "As Member"
                    }
                ],
                "TabsAccountWeb": [
                    {
                        "id": 1,
                        "Name": "Profile"
                    },
                    {
                        "id": 2,
                        "Name": "Settings"
                    },
                    {
                        "id": 3,
                        "Name": "Integration"
                    },
                    {
                        "id": 4,
                        "Name": "Credit"
                    },
                    {
                        "id": 5,
                        "Name": "Project History"
                    },
                    {
                        "id": 6,
                        "Name": "Payment History"
                    }
                ]
            }
        },
        "ProfileScreen": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "ProfileScreen": {
                "id": 1,
                "DoMyShootIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/229.png",
                "LeftProfileIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/user%20inactive.png",
                "RightDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/drop-down.png",
                "CreatePostButtonText": "Create Post",
                "AccountSettingHeading": "Account Settings",
                "AccountSettingIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/ep_setting.png",
                "RightBellButtonIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/notification.png",
                "RightInfoIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/help-circle+(1).png",
                "RightProfileIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/user%20inactive.png",
                "CreditsText": "Credits",
                "UpgradeToProButtonText": "Upgrade Your Plan",
                "MembershipIconOnUpgradeButton": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/ant-design_crown-filled+(1).png",
                "AccountSettingActiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/13.png",
                "LearnButtonText": "Learn",
                "LearnButtonIcon": "https://assets.dresma.com/DoMyShoot/App+Screens/help-circle.png",
                "CreateProjectText": "Create a new project",
                "ImportFilesText": "Import Files",
                "ImportFilesIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/62.png",
                "UploadYourText": "Upload your existing product images",
                "DarkModeLoggo": "https://assets.dresma.com/DoMyShoot/App%20Screens/230.png",
                "Home": {
                    "id": 3,
                    "Heading": "Home",
                    "ActiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/15.png",
                    "InactiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/Home.png"
                },
                "ExploreTemplateButton": {
                    "id": 1,
                    "Heading": "Explore Backgrounds",
                    "ActiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/Explore-pink.png",
                    "InactiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/explore.png"
                },
                "MyWorkButton": {
                    "id": 1,
                    "Heading": "My Projects",
                    "ActiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/my-work%20active.png",
                    "InactiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/my-work%20inactive.png"
                }
            }
        },
        "Editor": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "Editor": {
                "id": 1,
                "SelectProject": "Select Project",
                "CreateNewProject": "Create New Project",
                "ProjectName": "Project name",
                "ConfirmOrderButtonText": "Confirm Order",
                "SaveForLaterButtonText": "Save for Later",
                "CreateNewProjectFreeText": "Create New Project",
                "InfoIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/temp+info-logo.png",
                "Free": "Free",
                "ProjectNamePlaceholder": null,
                "Image": "image",
                "ImagesInAProject": "images",
                "DarkModeLoggo": "https://assets.dresma.com/DoMyShoot/App%20Screens/230.png",
                "Browsertoolbar": {
                    "id": 1,
                    "DMSIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/dresma_logo.png",
                    "LeftBackIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
                    "Back": "Back",
                    "Home": "Home",
                    "MyWork": "My Projects",
                    "BellIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/notification.png",
                    "InfoChainIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/help-circle+(1).png",
                    "ProfileIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/user%20inactive.png"
                },
                "SideToolbar": {
                    "id": 1,
                    "Placeholder": null,
                    "SearchKeywords": "Search keywords or theme or color",
                    "SearchIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/search.png",
                    "Template": "Template",
                    "Filter": "Filter",
                    "FilterIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/sort.png",
                    "RelatedTemplate": "Related Template",
                    "ViewAll": "View All",
                    "ClearAll": "Clear All",
                    "Results": "Results"
                },
                "BelowTemplate": {
                    "id": 1,
                    "Temp": "Temp#:",
                    "Free": " Free",
                    "InfoIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/temp+info.png",
                    "Remove": "Remove",
                    "Applied": "Applied",
                    "TickIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/check.png",
                    "Apply": "Apply"
                }
            }
        },
        "ExploreTemplateSection": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "ExploreTemplateSection": {
                "id": 1,
                "AllTemplatesHeading": "All Templates",
                "YourFavoriteHeading": "Your Favorites",
                "RecentlyUsedHeading": "Recently Used",
                "ResultsInExploreTemplate": "Results",
                "NoResultFoundText": "No Result Found",
                "Heading": {
                    "id": 1,
                    "HeadingText": "Explore Backgrounds",
                    "Description": "Access our library of trendy eCommerce backgrounds perfect to generate stand-out lifestyle images"
                },
                "ExploreTemplateSearchBar": {
                    "id": 1,
                    "Placeholder": null,
                    "SearchIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/carbon_search.png",
                    "SearchKeywordsOrThemeOrColorText": "Search by product category, formats themes, or color"
                },
                "TemplateFilter": {
                    "id": 1,
                    "Heading": "Filter",
                    "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/sort.png"
                }
            }
        },
        "MyProjectFrontEnd": {
            "Heading": "My Projects",
            "createdAt": "2023-04-11T13:50:12.440Z",
            "updatedAt": "2023-05-26T11:26:26.425Z",
            "publishedAt": "2023-04-11T13:50:15.003Z",
            "locale": "en",
            "MyProjectFrontEnd": {
                "id": 1,
                "Heading": "My Projects",
                "RefreshIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/339.png",
                "SearchPlaceholder": "Search",
                "SearchIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/109.png",
                "labelsText": "Labels",
                "labelsIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/335.png",
                "labelsDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/336.png",
                "CompletedText": "Completed",
                "ActionRequired": "Action Required",
                "InProcessText": "In Process",
                "FilterText": "Filter",
                "DateText": "Date",
                "DateIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/337.png",
                "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
                "ProjectText": "Project",
                "ProjectsText2": "Projects",
                "NoProjectText": "No Project",
                "CreateNewProject": "Create New Project",
                "StartShootingText": "Start shooting your product on ",
                "DoMyShootApp": "DoMyShoot app",
                "OrText": "OR",
                "UploadFilesText": "Upload Files",
                "UploadIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/343.png",
                "RemoveAllText": "Remove All",
                "ActiveCheckbox": "https://assets.dresma.com/DoMyShoot/App%20Screens/338.png",
                "InActiveCheckbox": null,
                "ProjectIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/353.png",
                "MaxUpToText": "Max up to 25 project  ",
                "ProjectText4": "project",
                "SelectedText": "Selected",
                "SlashIcon": "/",
                "IntegrationText": "Integration",
                "IntegrationIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/348.png",
                "ClearAllText": "Clear All",
                "ResultsText": "Results",
                "BlackCrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/340.png",
                "NoResultText": "No Result",
                "NoResultIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/342.png",
                "ClearText2": "Clear",
                "BulkActionOption": [
                    {
                        "id": 1,
                        "ActiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/349.png",
                        "InactiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/352.png",
                        "Name": "Share"
                    },
                    {
                        "id": 2,
                        "ActiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/350.png",
                        "InactiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/346.png",
                        "Name": "Download"
                    },
                    {
                        "id": 3,
                        "ActiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/351.png",
                        "InactiveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/347.png",
                        "Name": "Get Link"
                    }
                ],
                "MyProjectFilterBy": {
                    "id": 1,
                    "Heading": "Filter By",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
                    "ApplyCTA": "Apply",
                    "Status": "Status",
                    "ProductCategory": "Product Category"
                },
                "MyProjectDateFilter": {
                    "id": 1,
                    "Last10Project": "Last 10 Projects",
                    "Last15Days": "Last 15 Days",
                    "Last1Month": "Last 1 Month",
                    "OrText": "OR",
                    "SelectDate": "Select Date",
                    "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/341.png",
                    "ResetCTA": "Reset",
                    "ApplyCTA": "Apply"
                }
            }
        },
        "MyProjects": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "MyProjects": {
                "id": 1,
                "Heading": "My Projects",
                "NumberOfProjects": "Projects",
                "CheckBoxIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/check-box-unchecked.png",
                "SelectTwentyFiveMax": "Select 25 (max) / ",
                "Projects": "Projects",
                "ShareIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/share.png",
                "DownloadIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/download.png",
                "GetLinkIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/download.png",
                "MarketplaceIntegrationButtonText": "Integration",
                "MarketplaceIntegrationIcon": null,
                "RefreshIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/rotate-ccw+(3).png",
                "ByProjectText": "B Project",
                "AdaptationOptionMyProject": "Adaptation",
                "AdaptationIconMyProject": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/adaptation.png",
                "AddBackdropMyProject": "Add Backdrop",
                "AddBackdropIconMyProject": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/add-backdrop.png",
                "CrossIconViewDetailPage": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                "ConfirmOrderViewDetailsPage": "Confirm Order",
                "TotalCreditsThatWillBeText": null,
                "ToMarketplaceAccountButtonViewDetail": null,
                "CancelButtonViewDetail": "Cancel",
                "IntegrationSuccessfulText": "ChannelAdvisor account successfully connected",
                "ProductCategoryDropDown": {
                    "id": 1,
                    "Heading": "Product Category",
                    "DropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "SubHeading": "Product Category",
                    "DownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/accordian-down.png"
                },
                "ProductCategoryName": [
                    {
                        "id": 1,
                        "CategoryName": "All"
                    },
                    {
                        "id": 2,
                        "CategoryName": "Apparel - Hanger Shoot"
                    },
                    {
                        "id": 3,
                        "CategoryName": "Jewellery"
                    },
                    {
                        "id": 4,
                        "CategoryName": "Automobile"
                    },
                    {
                        "id": 5,
                        "CategoryName": "Electronics"
                    },
                    {
                        "id": 6,
                        "CategoryName": "Bags and Luggage"
                    },
                    {
                        "id": 7,
                        "CategoryName": "Footwear"
                    },
                    {
                        "id": 8,
                        "CategoryName": "Home"
                    }
                ],
                "StatusDropDown": {
                    "id": 1,
                    "Heading": "Status",
                    "DropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "SubHeading": "Status",
                    "DownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/accordian-down.png",
                    "All": "All",
                    "CheckIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/check.png",
                    "InProcess": "In Process",
                    "Completed": "Completed"
                },
                "SortByDropDown": {
                    "id": 1,
                    "Heading": "Sort By:",
                    "DropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "SubHeading": "Sort By",
                    "DownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/accordian-down.png",
                    "Recent": "Recent",
                    "CheckIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/check.png",
                    "LifestyleShoot": "Lifestyle Shoot",
                    "SavedShoot": "Saved Shoot",
                    "IncompletShoot": "Incomplete Shoot",
                    "AdaptationInProcess": "Adaptation in process",
                    "AdaptationCompleted": "Adaptation completed",
                    "RejectedShoot": "Rejected Shoot",
                    "PartiallyRejectedShoot": "Partially Rejected Shoot"
                },
                "DateDropDown": {
                    "id": 1,
                    "Heading": "Date",
                    "DropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "LastTenProjects": "Last 10 Days",
                    "LastFifteenDays": "Last 15 Days",
                    "LastOneMonth": "Last 1 Month",
                    "OR": "OR",
                    "SelectDate": "Select Date",
                    "SlideUpIcon": null,
                    "ApplyTextButton": "Apply",
                    "Applied": "Applied",
                    "ResetTextButton": "Reset"
                },
                "ThreeDotDropDownButton": [
                    {
                        "id": 1,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/add-backdrop.png",
                        "Name": "Add Backdrop"
                    },
                    {
                        "id": 2,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/adaptation.png",
                        "Name": "Adaptation"
                    },
                    {
                        "id": 5,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/download.png",
                        "Name": "Get Link"
                    },
                    {
                        "id": 3,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/download.png",
                        "Name": "Download"
                    },
                    {
                        "id": 4,
                        "Icon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/share.png",
                        "Name": "Share"
                    }
                ],
                "CreateAdaptationPopUpMyProject": {
                    "id": 1,
                    "Heading": "Create Adaptation",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "SelectMarketplaceAccountText": "Select marketplace and create new project with these images",
                    "ImagesText": "Images",
                    "AdaptationForText": "Adaptation for:",
                    "SelectMarketplaceItsFreeText": "Select Marketplace and create new project. It’s Free",
                    "NextButtonText": "Next",
                    "NextButtonIcon": null
                },
                "MarketplaceAccountsPopUpMyProject": {
                    "id": 1,
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "MarketplaceHeading": "Marketplace Accounts",
                    "NoAccountText": "No Account found",
                    "YouDontHaveText": "You don’t have any connected account, Please connect the account",
                    "AddMarketplaceButton": "Marketplace Integration",
                    "AddIcon": null
                },
                "IntegrationStatusPopUp": {
                    "id": 1,
                    "MarketplaceAccountHeading": "Marketplace Accounts",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "ConnectAccountButton": "Connect Account",
                    "AddIcon": null,
                    "LinkedAccountText": "Linked Accounts"
                },
                "SelectProjectPopUpMyProject": {
                    "id": 1,
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "SelectProjectToAddText": "Select Project to add images",
                    "YourProjectText": "Your project name should match with the ChannelAdvisor project name to integration",
                    "SearchPlaceholder": null,
                    "SearchPlaceholdericon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/search.png",
                    "PlaceholderText": "Search",
                    "CreateProjectButtonText": "Create Project",
                    "AddIcon": null
                },
                "SelectPrimaryImagePopUp": {
                    "id": 1,
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "SelectPrimaryText": "Select the primary image for ",
                    "ExistingImageText": "Existing Image",
                    "SlideLeftIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
                    "SlideRightIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "PrimaryImageButtonText": "Primary Image",
                    "UpddateImagesButtonText": "Update Images",
                    "UploadIcon": null
                },
                "ImageUpdationPopUp": {
                    "id": 1,
                    "MarketplaceIntegrationHeading": "Marketplace integration",
                    "MarketplaceIntegrationIcon": null,
                    "ImagesAreUpdatingToText": "Images are updating to",
                    "OkayButtonText": "Okay",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png"
                },
                "MarketplaceHistoryMyProject": {
                    "id": 1,
                    "MarketplaceHeading": "Marketplace integration",
                    "MarketplaceIcon": null,
                    "LoaderIcon": null,
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "HistoryText": "History",
                    "ViewAllText": "View All",
                    "MarketplaceHistoryHeading": "Marketplace History",
                    "Cross2Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "ProjectText": "Project",
                    "ImagesText": "Images",
                    "AccountText": "Account",
                    "DateText": "Date",
                    "FailsText": "Fails",
                    "TickIcon": null,
                    "RightArrowIcon": null,
                    "ImageText": "Image"
                }
            }
        },
        "ProfileWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "ProfileWebapp": {
                "id": 1,
                "AccountSettingText": "Account Settings",
                "AccountSettingIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/setting.png",
                "AccountSettingOnWebapp": {
                    "id": 1,
                    "Heading": "Account Settings",
                    "ManageProfileText": "Manage Profile",
                    "YouText": "You",
                    "AddMemberText": "Add Member",
                    "AddProfileIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/Frame+211.png",
                    "PrefernceText": "Preferences",
                    "ProductCategories": "Product Categories",
                    "CategoryDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "DefaultPreset": "Default Preset",
                    "PresetsDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "ActivateOffer": "Activate Offer",
                    "ActivateOfferDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-down.png",
                    "DoYouHaveCuponText": "Do you have a coupon, Activate the offer now!",
                    "ProductCategoryTickIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/check.png",
                    "PresetTickIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/check.png",
                    "CouponTickIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/check.png",
                    "AddOrRemoveMemberHeading": "Add or Remove Member",
                    "AllCategoriesText": "All Categories",
                    "SetYourDefaultPresetText": "Set your default preset",
                    "EnterTheCodeText": "Enter the Code",
                    "PlaceholderForCoupon": null,
                    "ActivateOfferButtonText": "Activate Offer",
                    "AppliedText": "Activated",
                    "DeleteYourAccountText": "Delete your account",
                    "DeleteAccountButtonText": "Delete Account"
                },
                "ProductCategoryPopUp": {
                    "id": 1,
                    "Heading": "Product Categories",
                    "HideUnusedCategories": "Hide unused categories",
                    "DoneButtonText": "Done",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "AllCategoriesText": "All Categories",
                    "SelectedText": "Selected",
                    "SelectAllText": "Select All"
                },
                "PresetsPopUp": {
                    "id": 1,
                    "Heading": "Presets",
                    "OnlyOnePresetText": "Only one presets can be selected",
                    "CancelButtonText": "Cancel",
                    "DoneButtonText": "Done",
                    "CrossIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/circle-with-cross.png",
                    "ConfirmButtonText": "Confirm",
                    "DefaultPresetText": "Default Preset:",
                    "PresetContent": "You have chosen “Amazon” as your default preset. This will be automatically applied to all new shoots.<br/><br/>  To change the preset during a shoot, click on the “Preset” button on the camera screen."
                },
                "CouponPopUpOnWebapp": {
                    "id": 1,
                    "DoYouHaveCuponHeading": "Do you have a coupon?",
                    "ActivateTheOfferNowText": "Activate the offer now!",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "EnterTheCodeText": "Enter the Code",
                    "PlaceholderForCouponCode": null,
                    "ResetText": "Reset",
                    "ActivateOfferButtonText": "Activate Offer",
                    "InactiveResetIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/rotate-ccw+(2).png",
                    "ActiveResetIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/rotate-ccw+(3).png"
                },
                "ProfileTabWebapp": {
                    "id": 1,
                    "Heading": "Profile",
                    "ProfilePhotoText": "Profile Photo",
                    "UpdateYourProfilePhotoText": "Upload your profile photo",
                    "UploadPhoto": "Upload photo",
                    "Name": "Name",
                    "PlaceholderForName": null,
                    "EmailAddress": "Email Address",
                    "PlaceholderForEmail": null,
                    "Mobile": "Mobile",
                    "PlaceholderForMobile": null,
                    "CancelButtonText": "Cancel",
                    "SaveButtonText": "Save",
                    "EmailEditText": "Edit",
                    "MobileEditText": "Edit",
                    "UpdateText": "Update",
                    "AddYourMobileText": "Add Your Mobile",
                    "AddYourEmailText": "Add Your Email",
                    "PlusActiveIcon": null,
                    "PlusInactiveIcon": null,
                    "NameEditText": "Edit"
                },
                "MembershipTabOnWebapp": {
                    "id": 1,
                    "StartYourMembershipHeading": "Start Your Membership",
                    "ChooseThePlanText": "Choose the plan that best suits your business needs and get the most out of our solution",
                    "Yearly": "Yearly",
                    "Monthly": "Monthly",
                    "GetUptoText": "Get up to a 30% discount on buying extra credits",
                    "BackText": "Back",
                    "BackIcon": null,
                    "YourMembership": "Your Membership"
                },
                "TypeOfPlansWebapp": [
                    {
                        "id": 1,
                        "PlanName": "Pay per use",
                        "Pricing": "Free",
                        "Description": "Recharge at standard rates",
                        "Feature": " $$ Marketplace integrations <br/> $$ Unlimited free adaptations<br/> $$ 24x7 chat + email support<br/> $$ 1000+ unique and premium template <br/> $$100+ fonts and sticker <br/> $$30 days storage for all your images",
                        "UpgradeToButtonText": null
                    },
                    {
                        "id": 2,
                        "PlanName": "Solo",
                        "Pricing": null,
                        "Description": "Billed yearly (Save 20%)",
                        "Feature": " $$ Everything in the free version, plus: <br/> $$ Get a **20% discount** on buying credits<br/> $$ Unlimited storage for all your images<br/> $$ Customized brand kit ",
                        "UpgradeToButtonText": "Upgrade to Solo"
                    },
                    {
                        "id": 3,
                        "PlanName": "Pro",
                        "Pricing": null,
                        "Description": "Billed yearly (Save 30%)",
                        "Feature": " $$ Everything in the free version, plus: <br/> $$ Get a **30% discount** on buying credits<br/> $$ Unlimited storage for all your images<br/> $$ Customized brand kit <br/> $$Create teams by adding users at ₹499 (up to 10 users)",
                        "UpgradeToButtonText": "Upgrade to Pro"
                    }
                ],
                "PaymentsOnWebapp": {
                    "id": 1,
                    "Heading": "Payment",
                    "LeftBackIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "PaymentOption": "Payment Option",
                    "OrderSummary": "Order Summary",
                    "CreditDebitCardText": "Credit/Debit Card",
                    "InactiveCardIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/payment%20card%20outline.png",
                    "RightNextIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                    "CardNumber": "Card Number",
                    "ActiveCardIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/payment%20card%20filled.png",
                    "PlaceholderForCardNumber": null,
                    "ValidTill": "Valid thru",
                    "CVV": "CVV",
                    "PlaceholderForCvv": null,
                    "SaveTHisCardText": "Save this card for faster payments",
                    "NameOnCardOptionalText": "Name on card (optional)",
                    "NameOnCard": "Name on card",
                    "Placeholder": null,
                    "ProceedToPayButtonText": "Proceed to pay",
                    "AddANewCardText": "Add a new card"
                },
                "OrderHistoryWebapp": {
                    "id": 1,
                    "Heading": "Order History",
                    "CreditsText": "Credits",
                    "FreeText": "Free",
                    "ImagesText": "Images",
                    "DeliveredText": "Delivered",
                    "InProcessText": "In process",
                    "AdaptationInProcess": "Adaptation In process",
                    "ProjectsText": "Projects",
                    "ProductCategory": "Product Category",
                    "DateText": "Date",
                    "DateDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/drop-down.png"
                },
                "ProfileWebappHeader": [
                    {
                        "id": 6,
                        "Heading": "Profile"
                    },
                    {
                        "id": 7,
                        "Heading": "Account Settings"
                    },
                    {
                        "id": 8,
                        "Heading": "Marketplace Accounts"
                    },
                    {
                        "id": 10,
                        "Heading": "Pay Per Use"
                    },
                    {
                        "id": 9,
                        "Heading": "Membership"
                    },
                    {
                        "id": 11,
                        "Heading": "Order History"
                    },
                    {
                        "id": 12,
                        "Heading": "Payment History"
                    }
                ],
                "UpdateEmailPopUpInProfile": {
                    "id": 1,
                    "UpdateEmailHeading": "Update Email",
                    "PleaseEnterYourNewEmail": "Please enter your new Email",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "NewEmailAddress": "New Email Address",
                    "PlaceholderForEmail": null,
                    "SendOtpButtonText": "Send OTP",
                    "VerifyYourEmail": "Verify Your Email",
                    "EmailAddress": "Email Address",
                    "ResendOtpText": "Resend OTP",
                    "AVerificationCodeText": "A verification code has been sent to account@gmail.com",
                    "DoneButtonText": "Done",
                    "EmailUpdatedSuccessfully": "Email updated successful",
                    "SuccessfullIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/payment%20success.png"
                },
                "UpdateMobilePopUpInProfile": {
                    "id": 1,
                    "UpdateMobileHeading": "Update Mobile",
                    "PleaseEnterYourNewMobileNo": "Please enter your new Mobile Number",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "NewMobileNumber": "New Mobile Number",
                    "PlaceholderForMobileNumber": null,
                    "SendOtpButtonText": "Send OTP",
                    "VerifyYourNumber": "Verify Your Mobile",
                    "ResendOtpText": "Resend OTP",
                    "AVerificationCodeText": "A varification code has been sent to ",
                    "DoneButtonText": "Done",
                    "MobileUpdatedSuccessfully": "Mobile updated successful",
                    "SuccessfullIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/payment%20success.png"
                },
                "PayPerUseTabOnProfileWebapp": {
                    "id": 1,
                    "MembershipIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/ant-design_crown-filled+(1).png",
                    "MembershipText": "Membership",
                    "UpgradeYourPlanDescription": "Upgrade your plan and get upto Extra  credits on every purchase.",
                    "UpgradeYourPlanButtonText": "Upgrade Your Plan",
                    "UpgradeYourPlanButtonIcon": null,
                    "PayPerUseHeading": "Pay per use",
                    "ChooseAnyPick": "Choose any pack",
                    "YouGetExtraText": "You get extra 20% credits on purchase",
                    "ValidTillText": "Valid Till"
                },
                "PayPerUsePriceProfileWebapp": [
                    {
                        "id": 1,
                        "Credits": null,
                        "Price": null
                    }
                ],
                "PaymentHistoryTabOnProfileWebapp": {
                    "id": 1,
                    "Heading": "Payment History",
                    "Plans": "Plans",
                    "OrderId": "Order ID",
                    "Amount": "Amount",
                    "Invoice": "Invoice",
                    "PaymentReceipt": "Payment receipt",
                    "PayPerUseText": "Pay Per Use:",
                    "CreditsText": "Credits",
                    "PlansProText": "Plan: Pro",
                    "PlanSoloText": "Plan: Solo",
                    "DownloadIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/download.png",
                    "DownloadInvoiceText": "Download Invoice",
                    "PendingText": "Pending",
                    "FailedText": "Failed",
                    "DownloadReceiptText": "Download receipt"
                },
                "MarketplaceAccountsOnWebappProfile": {
                    "id": 1,
                    "Heading": "Marketplace Accounts",
                    "LinkedAccounts": "Linked Accounts",
                    "ConnectButtonText": "Connect",
                    "LoginText": "Login",
                    "AuthenticationText": "Authentication",
                    "DoneButtonText": "Done",
                    "ConnectedText": "Connected",
                    "TickIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/check.png",
                    "DisconnectButtonText": "Disconnect",
                    "DummyAccountOnDisconnectPopUp": "account.x@gmail.com",
                    "AreYouSureTextOnDisconnectPopUp": "Are you sure, You want to disconnect?",
                    "DisconnectButtonTextOnPopUp": "Disconnect",
                    "CancelButtonTextOnPopUp": "Cancel",
                    "HistoryInactiveIcon": null,
                    "HistoryActiveIcon": null,
                    "HistoryText": "History",
                    "MarketplaceHistoryHeading": "Marketplace History",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "BackIcon": null,
                    "ProjectText": "Project",
                    "AccountText": "Account",
                    "AccountDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/drop-down.png",
                    "DateText": "Date",
                    "DateDropDownIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/drop-down.png",
                    "ImagesText": "Images"
                },
                "DelectAccountPopUpOnProfile": {
                    "id": 1,
                    "Heading": "Delete Account",
                    "DMSLoggo": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/dresma_logo.png",
                    "DummyAccount": "account.x@gmail.com",
                    "AreYouSureText": "Are you sure, You want to delete the Account?",
                    "YesDeleteButtonText": "Yes, Delete",
                    "CancelButtonText": "Cancel"
                },
                "EnterpriseGridOnMembershipTab": {
                    "id": 1,
                    "EnterpriseHeading": "Enterprise",
                    "DescriptionText": "Processing more than 1000 images a month or need it for more than 10 users?",
                    "GetInTouchText": "Get in touch with us for more details.",
                    "ContactSalesButtonText": "Contact Sales"
                },
                "ContactOurSalesTeamPopUp": {
                    "id": 1,
                    "Heading": "Contact our sales team",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "OurSalesTeamText": "Our sales team will get back to you within a 24 Hr",
                    "Name": "Name",
                    "PlaceholderForName": null,
                    "DummyName": "Stephen",
                    "WorkEmailAddress": "Work email address *",
                    "DummyWorkEmail": "account@gmail.com",
                    "PlaceholderForEmail": null,
                    "Company": "Company",
                    "PlaceholderForCompany": null,
                    "DummyCompanyName": "Ex. Myntra",
                    "PhoneNumber": "Phone number *",
                    "PlaceholderForPhoneNumber": null,
                    "CountryCodeForPhoneNumber": null,
                    "SubmitButtonText": "Submit"
                },
                "GetTheAppOnProfile": {
                    "id": 1,
                    "Heading": "Get the App",
                    "NoUnlockFeaturesText": "Now unlock all features on our mobile app with an DoMyShoot Membership.",
                    "StepsToDownloadTheApp": "1. Open Camera on your phone <br/><br/> 2. Scan this QR code and install the app on your phone <br/><br/> 3. Sign in with your DoMyShoot account",
                    "QRCodeForLink": "https://assets.dresma.com/DoMyShoot/App%20Screens/325.jpg",
                    "DoMyShootHeading": "DoMyShoot",
                    "DownloadAppText": "Download app and start shooting your product.",
                    "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                    "DoMyShootOnImage": "DoMyShoot",
                    "GetTheAppImage": "/icons/getApp.svg",
                    "AppStoreOnImage": "App Store",
                    "AppStoreLinkImage": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/apple1.png",
                    "PlayStoreIconImage": "Google Play",
                    "PlayStoreLinkImage": "https://cms.dresma.com/uploads/google_play_computer_icons_android_play_button_ac8cd7fa98.svg?updated_at=2022-06-02T10:16:44.400Z",
                    "ArrowDesignImage": "https://assets.dresma.com/DoMyShoot/App%20Screens/323.png"
                },
                "LanguageOptionsInProfile": [
                    {
                        "id": 1,
                        "DisplayText": "English",
                        "LanguageCode": "en",
                        "FlagURL": "https://assets.dresma.com/DoMyShoot/App+Screens/English.png"
                    }
                ],
                "MenuListInProfile": [
                    {
                        "id": 1,
                        "Title": "Home",
                        "IconLink": null
                    },
                    {
                        "id": 2,
                        "Title": "Profile",
                        "IconLink": null
                    },
                    {
                        "id": 3,
                        "Title": "Settings",
                        "IconLink": null
                    },
                    {
                        "id": 4,
                        "Title": "Logout",
                        "IconLink": null
                    }
                ]
            }
        },
        "MarketplaceIntegrationOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "MarketplaceIntegrationOnWebapp": {
                "id": 1,
                "CrossIcon": null,
                "MarketplaceIntegrationButtonText": "Integration",
                "AdaptationButtonText": "Adaptation",
                "AddBackdropButtonText": "Add Backdrop",
                "MarketplaceIcon": null,
                "AdaptationIcon": null,
                "AddBackdropIcon": null,
                "ShareIcon": null,
                "DownloadIcon": null,
                "GetLinkIcon": null,
                "EditIcon": null,
                "ActiveMarketplaceIcon": null,
                "AllSelectedText": null,
                "ToMarketplaceAccountsButtonText": null,
                "SelectAllText": null,
                "ImagesText": null,
                "MarketplaceAccountsPopUpOnMyProject": {
                    "id": 1,
                    "MarketplaceAccountsText": null,
                    "ConnectAccountText": null,
                    "PlusIcon": null,
                    "CrossIcon": null,
                    "LinkedAccountsText": null,
                    "NextIcon": null,
                    "DemoAccountEmail": null
                },
                "ChannelAdvisorPopUp": {
                    "id": 1,
                    "BackIcon": null,
                    "ChannelAdvisiorText": null,
                    "DemoAccountEmail": null,
                    "DemoAccountIcon": null,
                    "SelectProductText": null
                }
            }
        },
        "LanguageSelectorOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "LanguageSelectorOnWebapp": {
                "id": 25,
                "textAboveDropdown": null,
                "textAboveButton": null,
                "buttonText": null,
                "textBelowButton": null,
                "ChooseALanguageHeaderText": "Choose a language",
                "StartShootButtonText": null,
                "ORText": null,
                "SIgnInButtonText": null,
                "StartYourProductText": null,
                "FreeCreditText": null,
                "FreeCreditTickIcon": null,
                "ByClickingText": null,
                "YesAcceptText": null,
                "DMSLoggo": null,
                "DMSHeading": null,
                "ConfettiIcon": null,
                "GetComplimentaryText": null,
                "onboarding": [
                    {
                        "id": 77,
                        "heading": "SHOOT",
                        "byline": "Click pro quality eCommerce  photos for product listings & social media",
                        "page": 1,
                        "headerImageURL": "https://assets.dresma.com/DoMyShoot/App+Screens/d_tab_rounded.png"
                    },
                    {
                        "id": 78,
                        "heading": "ENHANCE",
                        "byline": "Generate stand out images with our unique product centric template",
                        "page": 2,
                        "headerImageURL": "https://assets.dresma.com/DoMyShoot/App+Screens/d_tab_rounded.png"
                    },
                    {
                        "id": 79,
                        "heading": "PROMOTE",
                        "byline": "Ensure you get high resolution photos of your products, good to go on any platform",
                        "page": 3,
                        "headerImageURL": "https://assets.dresma.com/DoMyShoot/App+Screens/d_tab_rounded.png"
                    }
                ],
                "languageOptions": [
                    {
                        "id": 261,
                        "displayText": "English",
                        "languageCode": "en",
                        "flagURL": "https://assets.dresma.com/DoMyShoot/App+Screens/English.png"
                    },
                    {
                        "id": 381,
                        "displayText": "Português",
                        "languageCode": "pt-BR",
                        "flagURL": "https://assets.dresma.com/DoMyShoot/App%20Screens/87.png"
                    }
                ],
                "TermsAndConditionCheckbox": {
                    "id": 66,
                    "termsAndConditionsBeginText": "By signing up, I agree to DoMyShoot's",
                    "termsOfServiceText": "Terms of Service",
                    "termsOfServiceURL": "https://www.domyshoot.com/terms-of-service",
                    "privacyPolicyText": "Privacy Policy",
                    "privacyPolicyURL": "https://www.domyshoot.com/privacy-policy",
                    "AndText": " & "
                }
            }
        },
        "EnterYourEmailScreenOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "EnterYourEmailScreenOnWebapp": {
                "id": 49,
                "textHeading": "Enter Your Email",
                "textAboveTextbox": "Email",
                "textPlaceholder": "email@account.com",
                "textButton": "Send OTP",
                "textUnderButton": "Or Sign in with",
                "textEmailValidationError": "Please enter a valid email ID",
                "textUnderTextbox": "We’ll send an OTP to confirm your email",
                "textPasswordSeperator": "Or",
                "textPasswordButton": "Login with Password",
                "PleaseLoginToConfirmYourOrderText": null,
                "CrossIconOnEmailScreen": null,
                "SMSConsentText": null,
                "socialButtons": [
                    {
                        "id": 297,
                        "Order": 1,
                        "buttonText": "Sign in with Google",
                        "loginType": "Google",
                        "iconURL": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/googlenew.png"
                    },
                    {
                        "id": 298,
                        "Order": 2,
                        "buttonText": "Sign in with Apple",
                        "loginType": "Apple",
                        "iconURL": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/apple1.png"
                    },
                    {
                        "id": 299,
                        "Order": 3,
                        "buttonText": "Sign in with Phone",
                        "loginType": "Phone",
                        "iconURL": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/mobile1.png"
                    }
                ],
                "termsAndConditions": {
                    "id": 67,
                    "termsAndConditionsBeginText": "By signing up, I agree to DoMyShoot's ",
                    "termsOfServiceText": "Terms of Service",
                    "termsOfServiceURL": "https://www.domyshoot.com/terms-of-service",
                    "privacyPolicyText": "Privacy Policy",
                    "privacyPolicyURL": "https://www.domyshoot.com/privacy-policy",
                    "AndText": "&"
                }
            }
        },
        "EmailOtpOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "EmailOtpOnWebapp": {
                "id": 50,
                "textButton": "Done",
                "textHeader": "Verify Your Email",
                "textByline": "A verification code has been sent to ",
                "textSocialHeader": null,
                "textSocialByline": null,
                "textOtpValidation": [
                    {
                        "id": 98,
                        "textError": "Incorrect OTP. Try again",
                        "errorType": "invalid OTP"
                    },
                    {
                        "id": 99,
                        "textError": "Uh-oh, this code has expired. Please enter the most recent code received",
                        "errorType": "expired OTP"
                    }
                ],
                "textConfirmationMessage": [
                    {
                        "id": 148,
                        "textMessage": "We've just send a code to",
                        "colourCode": "#4BB543",
                        "event": "success",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App%20Screens/circle-check-fill-white.png"
                    },
                    {
                        "id": 150,
                        "textMessage": "Unable to perform action. Please try again later or contact support at: support@domyshoot.com",
                        "colourCode": "#E50000",
                        "event": "other error",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App%20Screens/circle-alert-fill-white.png"
                    },
                    {
                        "id": 149,
                        "textMessage": "You've reached the max confirmation attempts. Try again in 1 hour",
                        "colourCode": "#E57C00",
                        "event": "max attempts",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App%20Screens/triangle-alert-white.png"
                    }
                ],
                "textBelowButton": [
                    {
                        "id": 50,
                        "textResendCodeTimer": "Resend OTP in",
                        "textCodeNotReceived": "Didn't get a code? <br/><br/> Resend Code",
                        "ResendCodeText": null
                    }
                ],
                "socialButtonOnOtpScreen": []
            }
        },
        "MobileOtpOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "MobileOtpOnWebapp": {
                "id": 51,
                "textButton": "Done",
                "textHeader": "Confirm Your Mobile Number",
                "textByline": "Enter the code we sent to ",
                "textSocialHeader": "Facing issues with the OTP",
                "textSocialByline": "Try logging in by continuing with",
                "textOtpValidation": [
                    {
                        "id": 100,
                        "textError": "Incorrect OTP. Try again",
                        "errorType": "invalid OTP"
                    },
                    {
                        "id": 101,
                        "textError": "Uh-oh, this code has expired. Please enter the most recent code received",
                        "errorType": "expired OTP"
                    }
                ],
                "textConfirmationMessage": [
                    {
                        "id": 151,
                        "textMessage": "We've just send a code to",
                        "colourCode": "#4BB543",
                        "event": "success",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App+Screens/circle-check-fill-white.png"
                    },
                    {
                        "id": 152,
                        "textMessage": "Unable to perform action. Please try again later or contact support at: support@domyshoot.com",
                        "colourCode": "#E50000",
                        "event": "other error",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App+Screens/circle-alert-fill-white.png"
                    },
                    {
                        "id": 153,
                        "textMessage": "You've reached the max confirmation attempts. Try again in 1 hour",
                        "colourCode": "#E57C00",
                        "event": "max attempts",
                        "iconURL": "https://assets.dresma.com/DoMyShoot/App+Screens/triangle-alert-white.png"
                    }
                ],
                "textBelowButton": [
                    {
                        "id": 51,
                        "textResendCodeTimer": "Resend OTP in",
                        "textCodeNotReceived": "Didn't get a code? Resend code",
                        "ResendCodeText": null
                    }
                ],
                "socialButtonOnOtpScreen": []
            }
        },
        "PasswordScreenOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "PasswordScreenOnWebapp": {
                "id": 25,
                "textHeader": "Enter Your Password",
                "textByline": "Enter password to",
                "textAboveTextbox": "Password",
                "placeholderText": null,
                "textButton": "Done",
                "textBelowButton": "Forgot Password",
                "confirmationMessage": "Incorrect Password"
            }
        },
        "ResendOtpTextOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "ResendOtpTextOnWebapp": [
                {
                    "id": 1,
                    "ResendOtpIn": "Resend OTP in",
                    "DidntGetACodeText": "Didn't get a code?",
                    "ResendCodeText": "Resend Code"
                }
            ]
        },
        "TutorialScreenOnWebapp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "TutorialScreenOnWebapp": {
                "id": 1,
                "Heading": "Tutorials",
                "VideoTextWebapp": "Videos",
                "RightArrowActiveIconWeb": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
                "RightArrowInActiveIconWeb": null,
                "LeftArrowActiveIconWeb": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
                "LeftArrowInActiveIconWeb": null,
                "VideoListCategory1Web": [
                    {
                        "id": 1,
                        "Title": "How to Sign Up",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/MS1QnolrOL0\" title=\"How to Sign Up on DoMyShoot App ?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    }
                ],
                "VideoListCategory2Web": [
                    {
                        "id": 2,
                        "Title": "Select Product Category",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/gaO64FKmiC4\" title=\"How to Select a Product Category on DoMyShoot\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 8,
                        "Title": "Add Angles",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/naKD_7rhD-Y\" title=\"How to Add More Angles on the Photoshoot Screen - DoMyShoot\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 9,
                        "Title": "Add backdrop to your photos",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/dI-SjNofoWY\" title=\"How to Add a Backdrop to Your Photos on DoMyShoot?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 11,
                        "Title": "Create Adaptations",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/2QKMnWdDEP0\" title=\"How to Create Adaptations for Final Images on the DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 10,
                        "Title": "How to shoot for a Marketplace",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/3IXJ24Wetm8\" title=\"How to Choose a Preset on DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 12,
                        "Title": "How to Zoom into your Product",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/HPoO7LPLxj4\" title=\"How to Use the Zoom Feature in DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 13,
                        "Title": "How to save a Shoot to complete later",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/kAFZfPUYTFw\" title=\"How to Save a Photoshoot for Later on #DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    }
                ],
                "VideoListCategory3Web": [
                    {
                        "id": 3,
                        "Title": "Track Your Project",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/31zxJAlM-HM\" title=\"How to track your project on DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 14,
                        "Title": "Access your Projects",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/YDqJXD1VhXM\" title=\"How to Access Your Projects on the #DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 15,
                        "Title": "How to Check your Project History",
                        "Source": "<iframe width=\"320\" height=\"560\" src=\"https://www.youtube.com/embed/KPEP7nILHFE\" title=\"How to Check Your Project History\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 16,
                        "Title": "How to Share DoMyShoot with your Friends",
                        "Source": "<iframe width=\"100\" height=\"560\" src=\"https://www.youtube.com/embed/-qWb0KVVAic\" title=\"How to Share the DoMyShoot App with Your Friends ?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 17,
                        "Title": "How to Share Feedback about DoMyShoot",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/SsQI3AoV3us\" title=\"How to Share Feedback about DoMyShoot App?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    },
                    {
                        "id": 18,
                        "Title": "Delete Account",
                        "Source": "<iframe width=\"100\" height=\"100\" src=\"https://www.youtube.com/embed/_Amvp9XNwTA\" title=\"How to Delete Your DoMyShoot Account ?\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
                        "Source2": null
                    }
                ],
                "VideoListCategory4Web": [],
                "VideoListCategory5Web": [],
                "CategoryHeadingOnWebapp": [
                    {
                        "id": 1,
                        "Heading": "Getting Started",
                        "VideoListCategoryNumber": "VideoListCategory1Web "
                    },
                    {
                        "id": 3,
                        "Heading": "How to Shoot",
                        "VideoListCategoryNumber": "VideoListCategory2Web "
                    },
                    {
                        "id": 2,
                        "Heading": "Product Features",
                        "VideoListCategoryNumber": "VideoListCategory3Web "
                    }
                ],
                "VideoListCategory6Web": []
            }
        },
        "CreateProjectWebApp": {
            "createdAt": "2022-06-15T10:22:34.314Z",
            "updatedAt": "2023-05-18T14:40:15.877Z",
            "publishedAt": "2022-06-28T08:00:06.284Z",
            "locale": "en",
            "bulkImageUploadSize": "6000000",
            "imageCompressionFactor": "80",
            "WebProfileIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/231.png",
            "GetOneCompliText": "Get one Complimentary Shoot!",
            "CameraIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/314.png",
            "RechargeNowCTA": "Recharge Now",
            "RechargeNowCredits": "Credits: ",
            "CreditSText2": "Credits",
            "CreditTextNew": "Credit",
            "CreateProjectWebApp": {
                "id": 1,
                "Heading": "Create New Project",
                "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
                "FileUploadText": "File Import",
                "FileUploadIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/62.png",
                "FileUploadChooseText": "Select file from your gallery",
                "FolderUploadText": "Folder Import",
                "FolderUploadIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/64.png",
                "FoldUploadChooseText": "Select folder from your gallery",
                "YourLinkedAccount": "Import from Linked Account",
                "ImportIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/37.png",
                "GoogleDriveIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/32.png",
                "GoogleDriveText": "Google Drive",
                "DropboxIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/312.png",
                "DropboxText": "Dropbox",
                "GoogleDriveHeading": "Google Drive",
                "EnterGoogleDrive": "Enter Google drive url ",
                "PlaceholderText": "Enter Google drive url",
                "NoteText": "Note: Before submit please share Edit Access to Google Drive folder with drive.access@dresma.com",
                "SubmitCTA": "Submit",
                "PleaseAllowText": "Please allow us Edit Access",
                "AlertIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/8.png",
                "SaveAsAProjectCTA": "Save as a Project",
                "ImagesText": "images",
                "ProjectsHaveBeenText": "Created successfully",
                "OkayCTA": "Okay",
                "FolderIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/64.png",
                "ImportFileHeading": "Import File...",
                "FolderUploadHeading": "Folder Uploads",
                "DeleteIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/7.png",
                "NewProjectName": "New Project Name",
                "EditIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/134.png",
                "PleaseEnterProjName": "Please enter project name",
                "SearchProjectText": "Search project",
                "SearchIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/search.png",
                "SelectAll": "Select All",
                "ChooseProjectCTA": "Select a Project",
                "NextCTA": "Next",
                "NextIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/chevron-right.png",
                "YouCanTrackText": "You can track your project in",
                "FileFormatTextWeb": "*File format allowed - JPEG, PNG",
                "ImportFiles2Text": "Import Files",
                "FilesImports4Text": "File Import",
                "ImportFolders3Text": "Import Folder",
                "FoldersImports2Text": "Folder Import",
                "ProjectNameValidText": "Project name is not valid.",
                "NotePleaseUploadText": "Note: Please upload folder with at least one file",
                "YouCanTrack2Text": null,
                "MyProjectCTA2": " My Work",
                "SuccessfulIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/313.png",
                "Project3Text": "Projects",
                "Project4Text": "Project",
                "FolderUploadFailedText": "Folder upload failed",
                "NoLinkedAccount45": "No Linked Account",
                "LinkedYourAccountText2": "Linked Your Account",
                "BackIcon56": "https://assets.dresma.com/DoMyShoot/App%20Screens/308.png",
                "AreYouSureText23": "Are you sure you want to cancel upload?",
                "YesCTAUpload": "Yes",
                "NoCTAUpload23": "No",
                "UploadExistingNote2": "Upload your existing product images",
                "LinkedAccountNotText1": "Linked Account Not Found",
                "ActiveUploadIcon3": "https://assets.dresma.com/DoMyShoot/App%20Screens/322.png",
                "LinkedAccount34": "Linked Account",
                "ProjectFailedText89": "File upload failed",
                "LinkedAccountIcon": [
                    {
                        "id": 1,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/63.png"
                    },
                    {
                        "id": 2,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/61.png"
                    },
                    {
                        "id": 3,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/60.png"
                    },
                    {
                        "id": 4,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/59.png"
                    },
                    {
                        "id": 33,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/310.png"
                    },
                    {
                        "id": 34,
                        "Icon": "https://assets.dresma.com/DoMyShoot/App%20Screens/311.png"
                    }
                ]
            }
        },
        "ExploreByColor": {
            "Heading": "Explore Backgrounds",
            "HeartIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/58.png",
            "SearchIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/search.png",
            "SearchPlaceholderText": "Search by keywords or theme",
            "FilterIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/sort.png",
            "SeeAllCTA": "See All",
            "ClockIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/51.png",
            "EyeIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/49.png",
            "BadgeIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/52.png",
            "ExploreByText": "Explore by :",
            "CreditsText": "Credits",
            "AvailableInText": "Available In:",
            "DesignedByText": "Designed by : ",
            "CompatibleForText": "Compatible for:",
            "AddToFavoriteText": "Add to Favorite",
            "AddToFavoriteIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/50.png",
            "GoToEditorText": "Go to Editor",
            "GoToEditorIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/chevron-right.png",
            "PopUpCrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
            "InActiveHeartIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/58.png",
            "createdAt": "2023-01-17T11:01:17.166Z",
            "updatedAt": "2023-05-10T06:24:55.273Z",
            "publishedAt": "2023-01-17T11:01:18.966Z",
            "FilterHeading": "Filter",
            "ResetText": "Reset",
            "ProductTypeText": "Product Type",
            "ImageFormatText": "Image Format",
            "CategoriesText": "Categories",
            "ApplyCTA": "Apply",
            "CancelCTA": "Cancel",
            "TemplatesHeading": "Explore",
            "FavoriteHeading": "Favorite ",
            "RecentSearchesText": "Recent Searches",
            "TrendingIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/57.png",
            "SearchPlaceholderText2": "Search background",
            "SearchCancelCTA": "Cancel",
            "SearchCrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
            "TrendingSearches": "Trending Searches",
            "ExculsiveCollection": "Exclusive Collection",
            "NoFavoriteText": "No Favorites",
            "NoFavoriteIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/53.png",
            "YouCanAddText": "You can add an background to your favorites by clicking “Heart Icon”",
            "GoBackCTA": "Go Back",
            "ChevronLeftIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App+Screens/chevron-left-logo.png",
            "GetPersonalizedText": "Get Personalized Experience",
            "SkipCTA": "Skip",
            "DoneCTA": "Done",
            "ChooseTheProductText": "Choose the product category for which you looking for background",
            "GetPersonalizedCross": "https://assets.dresma.com/DoMyShoot/App%20Screens/cross.png",
            "TickIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/check.png",
            "locale": "en",
            "ActiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/97.png",
            "InActiveIcon": "https://dresma-assets.s3.us-east-2.amazonaws.com/DoMyShoot/App%20Screens/96.png",
            "TemplateText": "Backgrounds",
            "AddedText": "Added",
            "FiltersText": "Filters",
            "NoResultsText": "No Result",
            "NoResultIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/98.png",
            "TrySomeDiffText": "Try some different keyword",
            "SearchTemplateText": "Search background by color",
            "TrendingColors": "Trending Colors",
            "ChooseColors": "Choose Colors",
            "ApplyText": "Apply",
            "CrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
            "ChevronDownIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
            "CircleWithCrossIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/233.png",
            "ProductShootText": "Product Shoot",
            "FolderIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/234.png",
            "ColorIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/232.png",
            "ColorText": "Color",
            "ExploreByShootText": "Explore Background by Product Shoot",
            "SearchProductText": "Search product shoot",
            "SearchIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/109.png",
            "CancelCTA2": "Cancel",
            "ApplyCTA2": "Apply",
            "SearchCrossIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png",
            "NoResultText": "No Result",
            "NoResultIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/222.png",
            "TrySomeDiffText2": "Try some different color",
            "GoBackCTA2": "Go Back",
            "BackIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/221.png",
            "ColorText2": "Color",
            "ColorIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/232.png",
            "ChevronDownIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/118.png",
            "ExploreByColor": true,
            "CustomizeThisText": "Customize this background on Web App",
            "CustomizeThisIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/316.png",
            "BlackInfoIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/317.png",
            "SendMeWebappLink": "Send me web app link",
            "SendMeWebappIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/315.png",
            "ShootForCTA": "Shoot for Background",
            "ShootForIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/319.png",
            "AddFromProjectsCTA": "Add from Projects",
            "AddFromProjectsIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/114.png",
            "EnterYourEmailHereText": "Enter your e-mail here.",
            "EmailSentSuccessfully": "Email sent successfully",
            "PleaseCheckYourText": "Please check your inbox",
            "DoneText2": "Done",
            "SuccessIcon2": "https://assets.dresma.com/DoMyShoot/App%20Screens/212.png",
            "GreenSentText": "Sent",
            "GreenSentIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/321.png",
            "BlackFilledInfoIcon": "https://assets.dresma.com/DoMyShoot/App%20Screens/318.png",
            "WeWillEmailLinkText": "We'll email you a link to our web app that allows you to resize and crop the background.",
            "WeWillEmailLinkCTA": "https://assets.dresma.com/DoMyShoot/App%20Screens/129.png"
        }
    },
    "success": true
}
export const completeStrapiData = strapiData?.data;
