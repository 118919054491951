import { Subject, Subscription } from 'rxjs';

class EventEmitter<T = any> {
  private events: Map<string, Subject<T>> = new Map();
  private subscriptionMap: Map<string, Subscription[]> = new Map();

  emit(event: string, data?: T) {
    if (!this.events.has(event)) {
      this.events.set(event, new Subject<T>());
    }
    this.events.get(event)!.next(data);
  }

  on(event: string, action: (data: T) => void): Subscription {
    if (!this.events.has(event)) {
      this.events.set(event, new Subject<T>());
    }
    const subscription = this.events.get(event)!.subscribe(action);

    if (!this.subscriptionMap.has(event)) {
      this.subscriptionMap.set(event, []);
    }
    this.subscriptionMap.get(event)!.push(subscription);

    return subscription;
  }

  off(event: string, subscription: Subscription) {
    subscription.unsubscribe();

    if (this.subscriptionMap.has(event)) {
      const subscriptions = this.subscriptionMap.get(event)!;
      const index = subscriptions.indexOf(subscription);
      if (index > -1) {
        subscriptions.splice(index, 1);
      }

      if (subscriptions.length === 0) {
        this.subscriptionMap.delete(event);
        this.events.delete(event);
      }
    }
  }
}

export default EventEmitter;