import { Skeleton, SxProps, Theme } from "@mui/material";
import styles from "./PromptGenerator.module.css";
import GenerateImageIcon from "./GenerateImageIcon.svg";
import useEditorService from "@/hooks/useEditorService";
import { useEffect, useMemo, useState } from "react";
import Layer from "local_modules/editor/src/Layer";
import DMSCanvas from "local_modules/editor/src/DMSCanvas";
import { generateBackgrounds, uploadToS3 } from "../../services/AIBackgrounds";
import { capitalCase } from "change-case";
import {environments,materials,promptStyles,props,settings, themesData} from './constants';
import useToast from "@/hooks/useToast";
import { useSelector } from "react-redux";
import useAuth from "@/hooks/useAuth";
import { useAtomValue, useSetAtom } from "jotai";
import PromptGeneratorHeader from "src/modules/editor-v2/LeftToolBar/AIBackgrounds/promptGeneratorHeader";
import useEditorLayerService from "@/hooks/useEditorLayerService";
import useResetAtomHandler from "@/modules/editor-v2/resetAtomHandler";
import {aigenerationLayerProgressAtom} from "src/modules/editor-v2/Atoms";

export default function PromptGenerator({
    setUrls, 
    generating,
    setGenerating, 
    setS3Url, 
    prompt, 
    assisted,
    setPrompt,
    setMaterial,
    setSettings,
    setProduct,
    setLighting,
    setMood,
    setScaleAndProportion,
    setColorPalette,
    setAdditionalElements,
    setting,
    materialList,
    settingsList,
    productList,
    lightingList,
    moodList,
    scaleAndProportionList,
    colorPalleteList,
    additionalElementsList,
    material,
    settings,
    product,
    lighting,
    mood,
    scaleAndProportion,
    colorPalette,
    additionalElements,
    setAssisted=(value)=>{},
    setIsAIBackgrounds,
    setAttributePage
}) {

    const {activeEditor} = useEditorService();
    const [layers, setLayers] = useState<Layer[]>([]);
    const toast = useToast();
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const {user} = useAuth();
    const {resetPromptHandler} = useResetAtomHandler();
    const {deAttachBackground} = useEditorService();
    const {deleteSelected} = useEditorLayerService();
    const aigenerationLayerProgress = useSetAtom(aigenerationLayerProgressAtom);

    const productImage = useMemo(
        ()=>layers?.find(layer=>layer?.getTags()?.includes("product-image")),
        [layers]
    );

    useEffect(()=>{
        const subscription = activeEditor?.layerManager.layers.subscribe(setLayers);
        return () => {
            subscription?.unsubscribe();
        }
    },[]);

    const uploadProductImage = async () => {
        if(!prompt) return toast.error("Please enter prompt!",2000);
        let serializedEditor = await activeEditor.save();
        const layer = serializedEditor.layers.find(layer=>layer.id===productImage.id);
        if(!layer) return toast.error("Cannot find Product Image!");
        console.log({activeEditor: activeEditor.originalSize});
        setGenerating(true);
        const editor = new DMSCanvas("canvas2",activeEditor.dimensions,activeEditor.originalSize);
        await new Promise((res)=>editor.canvas.setBackgroundColor("rgba(0,0,0,0)",res));
        const layerRef = await editor.addLayerFromSerializedData(layer);
        await layerRef.setPropertiesFromSerializedData(layer);
        editor.canvas.renderAll();
        const aspectRatio = activeEditor.originalSize.height / activeEditor.originalSize.width;
        if(aspectRatio > 1) await editor.resize({height: 640, width: 640 / aspectRatio});
        else await editor.resize({height: 640 * aspectRatio, width: 640});
        
        editor.canvas.renderAll();
        const base64 = editor.toBase64Url();
        setUrls(Array(4).fill(0).map(x=>({url:"",loading:true})));
        try{
            let url = await uploadToS3(base64);
            setS3Url(url);
            setAttributePage(false);
            const urls = (await generateBackgrounds(url,prompt, user?.entity?.entity?._id || "")).map(x=>({url:x.url,loading:false}));
            setUrls(urls);
            setGenerating(false);
        }
        catch(e){
            setIsAIBackgrounds(false)
            resetPromptHandler()
        }
        // finally{
        //     aigenerationLayerProgress(100);
        // }
    }

    const onPromptChange = ({target}) => {
        setPrompt(target?.value || "");
    }

    if(!product) product = productList?.[0];
    if (!material) material = materialList?.[0];
    if (!setting) setting = settingsList?.[0];
    if (!lighting) lighting = lightingList?.[0];
    if (!colorPalette) colorPalette = colorPalleteList?.[0];
    if (!mood) mood = moodList?.[0];
    if (!scaleAndProportion) scaleAndProportion = scaleAndProportionList?.[0];
    if (!additionalElements) additionalElements = additionalElementsList?.[0];
    
    const handleKeyDown = (event) => {
        // Check for Ctrl+Shift+V and prevent it
        if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'v') {
          event.preventDefault();
          navigator.clipboard.readText().then((text) => {
            // Set the input state with the pasted content
            setPrompt(prev=> prev.length ? `${prev}${text}` : text);
          });
        }
    };
    

    return <div className={styles.container}>
        <PromptGeneratorHeader setIsAIBackgrounds={setIsAIBackgrounds} displaytext = {strapiText?.EditPrompt || "Edit Prompt"} />
        <div className={styles.heading}>
            { strapiText?.PromptGenerator || "Prompt Generator" }
        </div>

        <div className={styles.row}>
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.ProductDropDown || "ProductDropDown"}
                </div>
                <select value={product} onChange={({target})=>{setProduct(target?.value)}}>
                    {productList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!productList.includes(product)) && product&& <option value={product}>{capitalCase(product)}</option>}
                </select>
            </div>
            
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.SettingDropdown || "Setting"}
                </div>
                <select value={settings} onChange={({target})=>{setSettings(target?.value)}}>
                    {settingsList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!settingsList.includes(settings)) && settings && <option value={settings}>{capitalCase(settings)}</option>}
                </select>
            </div>
        </div>

        <div className={styles.row}>
            
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.MaterialDropdown || "Material"}
                </div>
                <select value={material} onChange={({target})=>{setMaterial(target?.value)}}>
                    {materialList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!materialList.includes(material)) && material &&<option value={material}>{capitalCase(material)}</option>}
                </select>
            </div>
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.LightingDropdown || "Lighting"}
                </div>
                <select value={lighting} onChange={({target})=>{setLighting(target?.value)}}>
                    {lightingList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!lightingList.includes(lighting)) && lighting && <option value={lighting}>{capitalCase(lighting)}</option>}
                </select>
            </div>
        </div>

        <div className={styles.row}>
            
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.ColorDropdown || "Color Pallete"}
                </div>
                <select value={colorPalette} onChange={({target})=>{setColorPalette(target?.value)}}>
                    {colorPalleteList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!colorPalleteList.includes(colorPalette)) && colorPalette && <option value={colorPalette}>{capitalCase(colorPalette)}</option>}
                </select>
            </div>
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.MoodDropdown || "Mood"}
                </div>
                <select value={mood} onChange={({target})=>{setMood(target?.value)}}>
                    {moodList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!moodList.includes(mood)) && mood && <option value={mood}>{capitalCase(mood)}</option>}
                </select>
            </div>
        </div>

        <div className={styles.row}>
            
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.ScaleDropdown || "Scale and Proportion"}
                </div>
                <select value={scaleAndProportion} onChange={({target})=>{setScaleAndProportion(target?.value)}}>
                    {scaleAndProportionList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!scaleAndProportionList.includes(scaleAndProportion)) && scaleAndProportion && <option value={scaleAndProportion}>{capitalCase(scaleAndProportion)}</option>}
                </select>
            </div>
            <div className={styles.col}>
                <div className={styles.textContainer}>
                    {strapiText?.AdditionalElementsDropdown || "Additional Elements"}
                </div>
                <select value={additionalElements} onChange={({target})=>{setAdditionalElements(target?.value)}}>
                    {additionalElementsList.map(x=><option value={x}>{capitalCase(x)}</option>)}
                    {(!additionalElementsList.includes(additionalElements)) && additionalElements && <option value={additionalElements}>{capitalCase(additionalElements)}</option>}
                </select>
            </div>
        </div>

        <div className={styles.divider}></div>
        <div className={styles.topRow}>
            <div>
                {strapiText?.PromptGeneratorTitle || "Prompt"}
            </div>
            {assisted && <div className={styles.rightTag}>
             {strapiText?.AssistedText ||"Assisted"}
             </div>}
        </div>
        <div>
        <textarea 
            className={styles.textarea} 
            placeholder={strapiText?.PromptGeneratorInputPlaceholder || "Describe your Background"}
            onKeyDown={handleKeyDown} 
            value={prompt} 
            onChange={onPromptChange}
        >
        </textarea>

        <div className={`${styles.generateButton} ${(generating)?styles.generateButtonLoading:""}`} onClick={uploadProductImage}>
            <GenerateImageIcon />
            <div>{strapiText?.GenerateImagesButton ||" Generate Images"}</div>
        </div>
        </div>
        <div style={{overflow:'scroll',marginTop:"10px"}}>
            <div className={styles.themeText}>
               {strapiText?.theme || " Theme"}
            </div>
            <div className={styles.themeRow}>
                {themesData.map(theme=><ThemeItem title={theme.name} thumbnail={theme.thumbnail} onClick={()=>setSettings(theme.name)}/>)}
            </div>
        </div>
        <div style={{overflow:'hidden',opacity:0,pointerEvents:'none',position:'absolute'}}>
        <canvas id="canvas2"/>
        </div>
    </div>

}


function ThemeItem({title,thumbnail,onClick=()=>{}}) {
    return <div className={styles.themeCol} onClick={onClick}>
        <img src={thumbnail} style={{borderRadius:"4px", border:"1px solid #535353"}} />
        <div className={styles.themeTitle}>
            {title}
        </div>
    </div>
}