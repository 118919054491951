import { css } from "@emotion/react";

const btnType = {
  grayDark: "GRAY_DARK",
};
const BtnWithCheck = ({
  type = btnType.grayDark,
  checked,
  btnText,
  onClick,
}) => {
  const styles = customStyles(type);
  return (
    <div css={styles.container}>
      <input
        type="checkbox"
        checked={checked}
        css={styles.checkbox}
        onClick={onClick}
      />
      <span css={styles.btnText}>{btnText}</span>
    </div>
  );
};

function customStyles(type) {
  let container, checkbox, btnText;
  switch (type) {
    case btnType.grayDark:
      container = css`
        display: inline-flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        background: rgba(238, 238, 238, 0.16);
        width: 100%;
      `;
      checkbox = css`
        accent-color: #fff;
        border-color: #fff;
        cursor: pointer;
      `;
      btnText = css`
        color: #eee;
        font-family: Objectivity;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        letter-spacing: 0.14px;
      `;
      break;
    default:
      container = css`
        display: inline-flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        background: rgba(238, 238, 238, 0.16);
        width: 100%;
      `;
      checkbox = css`
        accent-color: #fff;
        border-color: #fff;
      `;
      btnText = css`
        color: #eee;
        font-family: Objectivity;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        letter-spacing: 0.14px;
      `;
  }
  return { container, checkbox, btnText };
}
export default BtnWithCheck;
