import { useAtomValue } from "jotai";
import {
  backgroundFilterAtom,
  backgroundFilterProductImageIdAtom,
  backgroundFilterTemplateTypeAtom,
} from "../modules/editor-v2/Atoms";
import { getPaginatedTemplates } from "../modules/editor-v2/services/MyWorks";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

export function useCustomTemplateInfiniteQuery() {
  const debounceRef = useRef(null);
  const selectedFilter = useAtomValue(backgroundFilterAtom);
  const templateType = useAtomValue(backgroundFilterTemplateTypeAtom);
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const [filteredPayload, setFilteredPayload] = useState([]);

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setFilteredPayload([
        "filteredTemplates",
        "infinite",
        selectedFilter?.searchText,
        selectedFilter?.searchString,
        templateType,
        selectedFilter?.imageFormat,
        selectedFilter?.colors,
        selectedFilter?.hotspotOnly,
        productImageId,
        selectedFilter?.prespective?.angleGroupId,
        selectedFilter?.updateRecentSearch,
        selectedFilter?.isInfographics,
        selectedFilter?.tags,
      ]);
    }, 400);
  }, [
    selectedFilter?.searchText,
    selectedFilter?.searchString,
    templateType,
    selectedFilter?.imageFormat,
    selectedFilter?.colors,
    selectedFilter?.hotspotOnly,
    productImageId,
    selectedFilter?.prespective?.angleGroupId,
    selectedFilter?.updateRecentSearch,
    selectedFilter?.isInfographics,
    selectedFilter?.tags,
  ]);

  const {
    data,
    error,
    status,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(filteredPayload, getPaginatedTemplates, {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length >= 24) {
        return allPages.length;
      }
      return undefined;
    },
    // enabled: filteredPayload.length > 0,
  });

  return {
    status,
    error,
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
}
