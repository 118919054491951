import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "src/redux/store";
import { getChannelAdvisorProductAttributesApi, getWalmartProductAttributesApi, getShopifyProductAttributesApi } from "src/services/job-order";
import {
  channelAdvisorAuthorisationApi,
  shopifyAuthorisationApi,
  disconnectChannelAdvisorAccountApi,
  updatePhoneOrEmailService,
  verifyPhoneOrEmailService,
  updateUserDetailsService,
  verifySocialServiceUrl,
  amazonAuthorisationApi,
  amazonAccountDisconnect,
  patchMarketplaceDefaultAttributeApi,
  walmartAccountDisconnect,
  shopifyAccountDisconnect
} from "../../services/setting";

const initialState = {
  isAuthorising: false,
  error: null,
  success: null,
  channelAdvisor: {},
  shopify:{},
  updatePhoneEmail: {},
  verifyPhoneEmail: {},
  verifyGoogleApple: {},
  updateUser: {},
  currCallType: {},
  marketplace: {},
  walmartPopupOpen: false,
  shopifyPopupOpen: false,
  currentSelectedTab: "",
  amazon: {},
  appSumoData:[],
}

const slice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    startLoading(state, action) {
      state = {
        ...state,
        [action.key]: action.value,
      }
    },
    haseError(state, action) {
      state = {
        ...state,
        [action.key]: true,
        error: action.error
      }
    },
    setWalmartPopup(state, action) {
        state.walmartPopupOpen = action.payload;
    },
    setShopifyPopup(state, action) {
        state.shopifyPopupOpen = action.payload;
    },
    setAppSumoData(state, action){
      state.appSumoData=action.payload;
    },
    setChannelAdvisor(state, action) {
      state.channelAdvisor = {
        error: action.error || state.channelAdvisor.error,
        isAuthorising: action.isAuthorising || state.channelAdvisor.isAuthorising,
        success: action.success || state.channelAdvisor.success,
      }
    },
    setCurrentSelectedTab(state, action) {
        state.currentSelectedTab = action.payload;
      },
    setChannelAdvisorStatus(state, action) {
      state.channelAdvisor.status = action.payload.status;
      state.channelAdvisor.error = action.payload.error;
    },
    setShopifyStatus(state, action) {
        state.shopify.status = action.payload.status;
        state.shopify.error = action.payload.error;
      },
    setUpdatePhoneEmailStatus(state, action) {
      state.updatePhoneEmail.status = action.payload.status;
      state.updatePhoneEmail.error = action.payload.error;
      state.currCallType = { ...action.payload, type: "update" }
    },
    setVerifyPhoneEmailStatus(state, action) {
      state.verifyPhoneEmail.status = action.payload.status;
      state.verifyPhoneEmail.error = action.payload.error;
      state.currCallType = { ...action.payload, type: "verify" }
    },
    setVerifyGoogleAppleStatus(state, action) {
      state.verifyGoogleApple.status = action.payload.status;
      state.verifyGoogleApple.error = action.payload.error;
      state.currCallType = { ...action.payload, type: "verify" }
    },
    setUserUpdateStatus(state, action) {
      state.updateUser.status = action.payload.status;
      state.updateUser.error = action.payload.error;
      state.updateUser.data = action.payload.data;
      state.currCallType = { ...action.payload, type: "updateUser" }
    },
    resetAllProfileApiStatus(state, action) {
      state.updatePhoneEmail = {}
      state.verifyPhoneEmail = {}
      state.updateUser = {}
      state.currCallType = {}
    },
    setMarketplaceDisconnectStatus(state, action) {
      state.marketplace.disconnectStatus = action.payload.status;
      state.marketplace.disconnectError = action.payload.error;
    },
    setAmazonStatus(state,action) {
      state.amazon.status = action.payload.status;
      state.amazon.error = action.payload.error;
    },

    setMarketplaceAttributes(state, action) {
      state.marketplace[`${action.payload.marketplace}Attributes`] = action.payload.data;
    },

    setMarketplaceAttributesState(state, action) {
      state.marketplace[`${action.payload.marketplace}AttributeStatus`] = action.payload.status;
      state.marketplace[`${action.payload.marketplace}AttributeError`] = action.payload.error;
    },

    setMarketplaceDefaultAttributeStatus(state, action) {
      if (!action.payload.status) {
        state.marketplace.attributePatchState = state.marketplace?.attributePatchState?.filter((element) => element.accountId !== action.payload.accountId);
        return;
      }
      const progressObj = state.marketplace?.attributePatchState?.find((element) => element.accountId === action.payload.accountId);
      if (progressObj) {
        progressObj.status = action.payload.status;
        progressObj.error = action.payload.error;
        state.marketplace.attributePatchState = [
          progressObj,
          ...state.marketplace.attributePatchState.filter((element) => element.accountId !== action.payload.accountId),
        ];
      } else {
        state.marketplace.attributePatchState = [
          ...(state?.marketplace?.attributePatchState ?? []),
          {
            accountId: action.payload.accountId,
            status: action.payload.status,
            error: action.payload.error,
          }
        ];
      }
    },
    resetSettingReducer(state, action){
      state.isAuthorising = false;
      state.error = null;
      state.success = null;
      state.channelAdvisor = {};
      state.shopify = {};
      state.updatePhoneEmail = {};
      state.verifyPhoneEmail = {};
      state.verifyGoogleApple = {};
      state.updateUser = {};
      state.currCallType = {};
      state.marketplace = {};
      state.walmartPopupOpen = false;
      state.shopifyPopupOpen = false;
      state.currentSelectedTab = "";
      state.amazon = {};
      state.appSumoData = [];
    },

  }
});

export default slice.reducer;

export const { startLoading, setChannelAdvisor, setChannelAdvisorStatus, setMarketplaceDisconnectStatus, setWalmartPopup, setShopifyPopup, setCurrentSelectedTab, setAppSumoData, resetSettingReducer } = slice.actions;

export const channelAdvisorAuthorisation = async (authCode) => {
  try {
    dispatch(slice.actions.setChannelAdvisorStatus({
      status: 'CA_AUTH_PROCESSING',
    }));
    await channelAdvisorAuthorisationApi(authCode);
    dispatch(slice.actions.setChannelAdvisorStatus({
      status: 'CA_AUTH_SUCCESS',
    }));
  } catch (e) {
    console.error(e);
    let errorMessage = e.message;
    if (e.response) {
      errorMessage = e?.response?.data?.error;
    }
    dispatch(slice.actions.setChannelAdvisorStatus({
      status: 'CA_AUTH_FAILED',
      error: errorMessage,
    }));
  }
}

export const shopifyAuthorisation = async (authCode, shopName) => {
    try {
      dispatch(slice.actions.setShopifyStatus({
        status: 'SHOPIFY_AUTH_PROCESSING',
      }));
      await shopifyAuthorisationApi(authCode, shopName);
      dispatch(slice.actions.setShopifyStatus({
        status: 'SHOPIFY_AUTH_SUCCESS',
      }));
    } catch (e) {
      console.error(e);
      let errorMessage = e.message;
      if (e.response) {
        errorMessage = e?.response?.data?.error;
      }
      dispatch(slice.actions.setShopifyStatus({
        status: 'SHOPIFY_AUTH_FAILED',
        error: errorMessage,
      }));
    }
  }

export const setChannelAdvisorAuthorisationStatus = (status, error) => {
  dispatch(slice.actions.setChannelAdvisorStatus({
    status,
    error,
  }));
}

export const disconnectChannelAdvisorAccount = async (profileId) => {
  try {
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'START' }));
    await disconnectChannelAdvisorAccountApi(profileId);
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH' }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH', error: e.message }));
  }
}

export const setShopifyAuthorisationStatus = (status, error) => {
    dispatch(slice.actions.setShopifyStatus({
      status,
      error,
    }));
  }

export const updatePhoneOrEmail = async (body) => {
  try {
    dispatch(slice.actions.setUpdatePhoneEmailStatus({
      status: 'PROCESSING',
    }));
    const res = await updatePhoneOrEmailService(body);
    dispatch(slice.actions.setUpdatePhoneEmailStatus({
      status: 'SUCCESS',
      error: res.data.error
    }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setUpdatePhoneEmailStatus({
      status: 'FAILED',
      error: e.message,
    }));
  }
}

export const verifyPhoneOrEmail = async (body) => {
  try {
    dispatch(slice.actions.setVerifyPhoneEmailStatus({
      status: 'PROCESSING',
    }));
    const res = await verifyPhoneOrEmailService(body);
    dispatch(slice.actions.setVerifyPhoneEmailStatus({
      status: 'SUCCESS',
      error: res.data.error
    }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setVerifyPhoneEmailStatus({
      status: 'FAILED',
      error: e.message,
    }));
  }
}

export const verifyGoogleOrAppleId = async (body) => {
  try {
    dispatch(slice.actions.setVerifyGoogleAppleStatus({
      status: 'PROCESSING',
    }));
    const res = await verifySocialServiceUrl(body);
    dispatch(slice.actions.setVerifyGoogleAppleStatus({
      status: 'SUCCESS',
      error: res.data.error
    }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setVerifyGoogleAppleStatus({
      status: 'FAILED',
      error: e.message,
    }));
  }
}

export const updateUserDetails = async (body) => {
  try {
    dispatch(slice.actions.setUserUpdateStatus({
      status: 'PROCESSING',
    }));
    const res = await updateUserDetailsService(body);
    dispatch(slice.actions.setUserUpdateStatus({
      status: 'SUCCESS',
      data:res.data?.data,
      error: res.data.error
    }));
    return res;
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setUserUpdateStatus({
      status: 'FAILED',
      error: "unable to update",
    }));
  }
}

export const resetAllProfileApiStatus = () => {
  try {
    dispatch(
      slice.actions.resetAllProfileApiStatus()
    );
  } catch (e) { }
};

export const setMarketplaceDisconnectStatusAction = (status) => {
  dispatch(slice.actions.setMarketplaceDisconnectStatus({ status }));
}

export const amazonAuthorisation = async (payload) => {
  try {
    dispatch(slice.actions.setAmazonStatus({
      status: 'AMZ_AUTH_PROCESSING',
    }));
    await amazonAuthorisationApi(payload);
    dispatch(slice.actions.setAmazonStatus({
      status: 'AMZ_AUTH_SUCCESS',
    }));
  } catch (e) {
      console.error(e);
      let errorMessage = e.message;
    if (e?.response) {
      errorMessage = e?.response?.data?.data?.message ?? e?.response?.data?.message;
    }
    dispatch(slice.actions.setAmazonStatus({
      status: 'AMZ_AUTH_FAILED',
      error: errorMessage,
    }));
  }
}

export const setAmazonAuthorisationStatus = (status, error) => {
  dispatch(slice.actions.setAmazonStatus({
    status,
    error,
  }));
}


export const disconnectAmazonAccountAction = async (entryId, entityId) => {
  try {
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'START' }));
    await amazonAccountDisconnect({ entryId, entityId });
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH' }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH', error: e.message }));
  }
}

export const getMarketplaceAttributesAction = async (marketplace) => {
  try {
    dispatch(slice.actions.setMarketplaceAttributesState({ status: 'START', marketplace }));
    let data;
    if (marketplace == "CHANNEL_ADVISOR") {
        data = await getChannelAdvisorProductAttributesApi(marketplace);
    } else if (marketplace == "WALMART") {
        data = await getWalmartProductAttributesApi(marketplace);
    } else if (marketplace == "SHOPIFY") {
        data = await getShopifyProductAttributesApi(marketplace);
    }
    data = data.data;
    dispatch(slice.actions.setMarketplaceAttributes({ data, marketplace }));
    dispatch(slice.actions.setMarketplaceAttributesState({ status: 'FINISH', marketplace }));
  } catch (e) {
    console.error(e);
    dispatch(slice.actions.setMarketplaceAttributesState({ status: 'FINSIH', error: e.message, marketplace }));
  }
}

export const setMarketplaceDefaultAttributeAction = async (marketplace, accountId, attribute) => {
  try {
    dispatch(slice.actions.setMarketplaceDefaultAttributeStatus({
      accountId,
      status: 'START',
    }));
    const { data } = await patchMarketplaceDefaultAttributeApi({
      marketplace,
      accountId,
      attribute,
    });
    dispatch(slice.actions.setMarketplaceDefaultAttributeStatus({
      accountId,
      status: 'FINISH',
    }));
    setTimeout(() => {
      setAttributeProgressStatusAction(accountId, null, null)
    }, 3000);
  } catch (e) {
    console.error('[setMarketplaceAttributeDefaultValue]', e);
    dispatch(slice.actions.setMarketplaceDefaultAttributeStatus({
      accountId,
      status: 'FINISH',
      error: e.message,
    }));
  }
}

export const setAttributeProgressStatusAction = async (accountId, status = null, error = null) => {
  dispatch(slice.actions.setMarketplaceDefaultAttributeStatus({
    accountId
  }))
}


export const disconnectWalmartAccountAction = async (walmartId) => {
    try {
      dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'START' }));
      await walmartAccountDisconnect( walmartId );
      dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH' }));
    } catch (e) {
      console.error(e);
      dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH', error: e.message }));
    }
  }

export const disconnectShopifyAccountAction = async (shopifyId) => {
    try {
        dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'START' }));
        await shopifyAccountDisconnect( shopifyId );
        dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH' }));
      } catch (e) {
        console.error(e);
        dispatch(slice.actions.setMarketplaceDisconnectStatus({ status: 'FINISH', error: e.message }));
      }
}