import { useRef } from "react"
import styles from './styles.module.css';

export default function EditName({defaultValue="",onSuccess,onCancel}) {
    const inputRef = useRef<HTMLInputElement>(null);
    return <div className={styles.editNameRow}>
        <input ref={inputRef} defaultValue={defaultValue} className={styles.inputStyle}/>
        <div className={styles.buttonDark} onClick={()=>onSuccess(inputRef?.current?.value)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.36665 12.0001L2.56665 8.20007L3.51665 7.25007L6.36665 10.1001L12.4833 3.9834L13.4333 4.9334L6.36665 12.0001Z" fill="#EEEEEE"/>
            </svg>
        </div>
        <div className={styles.buttonDark} onClick={onCancel}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.1787 13L8.5 9.31555L4.82134 13L4 12.1787L7.68445 8.5L4 4.82134L4.82134 4L8.5 7.68445L12.1787 4.00578L12.9942 4.82134L9.31555 8.5L12.9942 12.1787L12.1787 13Z" fill="#EEEEEE"/>
        </svg>
        </div>
    </div>
}