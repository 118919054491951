import { useQuery } from "@tanstack/react-query";
import { getSuggestionList } from "../modules/editor-v2/services/MyWorks";
import { useMemo } from "react";


export const useSuggestionList = () => {
    const { data } = useQuery(["suggestionList", "infinity"], getSuggestionList, {
        staleTime: "Infinity",
      });

    const recommendedList = useMemo(() => {
    let list = [];
    if (data?.length > 0) {
        data.map((cat) => {
        if (cat?.header?.toUpperCase() === "PRODUCT CATEGORY") {
            list.push(...cat?.options?.map((obj) => obj?.name));
        } else if (cat?.header?.toUpperCase() === "IMAGE FORMAT") {
            list.push(...cat?.options);
        }
        });
    }
    return list;
    }, [data]);


    return {recommendedList}
};
