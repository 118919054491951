import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

const id1="bCKcKf2UMw62dhxLwuHoWBFXijFA5GCf";
const id2="G19pS7cPlvv1dDj7Vv97efwdEOPcRiXt";

let segmentClient =  segmentPlugin({
  writeKey: id2
});

export const resetSegmentClient = () => {
  // Recreate or set segmentClient to a new instance
  segmentClient = segmentPlugin({
    writeKey: id2
  });
};

export const AnalyticsFunc = Analytics({
  app: 'DoMyShoot-Webapp',
  plugins: [
    segmentClient
  ]
});

/* Track a page view */
// AnalyticsFunc.page()

/* Track a custom event */
export const TrackWebappEvent=(event,payload)=>{
  let userId = payload.userId;
  window.Moengage.add_unique_user_id(userId);
  AnalyticsFunc?.track(event, payload)
};

export const IdentifierWebappEvent=(event,payload)=>{
  AnalyticsFunc?.identify(event, payload);
};

// export const segmentIdentifier = async (payload) => {
//   let userId = payload.userId;
//   if(!userId) return;
//   AnalyticsFunc.identify(userId,payload)
// }

export const segmentIdentifier =  (payload) => {
  const userId = payload.userId;
  if (!userId) {
    throw new Error('User ID is missing');
  }

  return new Promise((resolve, reject) => {
    AnalyticsFunc.identify(userId, payload)
      .then(() => {
       return  resolve(true);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};



/* Identify a visitor */
// AnalyticsFunc.identify('user-id-xyz', {
//   firstName: 'bill',
//   lastName: 'murray'
// })