import DropdownSelection from "@/modules/ui-elements/DropdownSelection";
import { ICONS } from "@/modules/ui-elements/Icon";
import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import SearchIcon from './searchIcon.svg';
import { CSSProperties, useEffect, useRef, useState } from "react";
import { SuggestionBox } from "./SuggestionBox";

export default function SearchPane({
    setSelectedFilter, 
    trendingList,
    onSearchHandler,
    trendingListLoading,
    strapiData,
    setIsSearchPaneVisible
}) {
    const debounceRef = useRef(null);
    const [showRecentAndTrending, setShowRecentAndTrending] = useState(true);
    const [userSearchInput, setUserSearchInput] = useState("");
  
    const onChangeHandler = () => {
      setSelectedFilter((prev) => ({
        ...prev,
        searchText: userSearchInput,
      }));
    };
  
    const searchDebounce = (delay) => {
      if (debounceRef.current) clearTimeout(debounceRef.current);
  
      debounceRef.current = setTimeout(() => {
        onChangeHandler();
      }, delay);
    };
  
    useEffect(() => {
      searchDebounce(600);
    }, [userSearchInput]);

    const queryClient = useQueryClient();

    const applyColorHandler = (item) => {
        setSelectedFilter((prev) => ({ ...prev, colors: [...prev.colors, item] }));
        setIsSearchPaneVisible(false);
    };

    const onSearchBarFocus = ()=>queryClient.refetchQueries(["trendingSearch"])

    return <div css={styles.container}>
      <div style={styles.row}>
        <div style={styles.dFlex}>
          <SearchIcon />
        </div>
        <div style={styles.dFullFlex}>
            <input 
                type="text" 
                placeholder={strapiData?.SearchPlaceholderText || "Search keywords or theme or color"} 
                css={styles.input}
                onFocus={onSearchBarFocus}
                value={userSearchInput} 
                onChange={(e) => setUserSearchInput(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSearchHandler(userSearchInput);
                    }
                }}
            />
            {userSearchInput &&<img src="/icons/cross-light.svg" style={{background: "#262626", width:"16px", height:"14px", borderRadius: "999px"}}onClick={()=>{setUserSearchInput("")}}/>}
        </div>
        <div>
         { !userSearchInput && <DropdownSelection
                type={"COLOR"}
                startIcon={ICONS.colorWheel}
                endIcon={ICONS.chevronFilledDown}
                onApplyColor={applyColorHandler}
                startIconWidth={"24px"}
                startIconHeight={"24px"}
                btnStyle={styles.dropdownBtn} 
                titleText={undefined} 
                formatList={undefined} 
                selectedFilter={undefined} 
                btnTextStyle={undefined} 
            /> }
        </div>
      </div>
      {!!!userSearchInput?.length && <SuggestionBox
        trendingList={trendingList}
        onSearchHandler={onSearchHandler}
        trendingListLoading={trendingListLoading}
        strapiData={strapiData}
        showRecentAndTrending={true}
      />}
  </div>
}

const styles = {
    container: css`
        position:absolute;
        width:calc(100% - 20px);
        top:54px;
        right:10px;
        background:white;
        z-index:10;
        border: 1px solid #EEEEEE66;
        border-radius:4px;
        display:flex;
        flex-direction:column;
    `,
    row: {flexDirection:'row', display:'flex', width:'100%', padding:"4px", paddingLeft:"10px", gap:10, alignItems:'center'} as React.CSSProperties,
    dFlex: {display:'flex'},
    dFullFlex: {flex:1, display:'flex', alignItems:"center", height:"32px"},
    input: css`
        outline:0px;
        border:0px;
        font-family: Objectivity;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        width:100%;
        margin-top: 6px;
    `,
    dropdownBtn: {
        background: "#EEE",
        gap: "8px",
        borderRadius: "4px",
        margin: "-6px 0px 0px 10px",
        border: "none",
    } as CSSProperties,
}