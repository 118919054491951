import { createSlice } from "@reduxjs/toolkit";
import { CANVAS_EDIT_TYPE, CANVAS_MODES } from "src/utils/constant";

const initialState = {
  mode: "",
  type: "",
  size: { width: 0, height: 0 },
  label: "Size (1:1)",
  initCanvasCoordinates: {
    sx: 0,
    sy: 0,
    sWidth: 0,
    sHeight: 0,
    dx: 0,
    dy: 0,
    dWidth: 0,
    dHeight: 0,
  },
  canvasCordinates: {
    sx: 0,
    sy: 0,
    sWidth: 0,
    sHeight: 0,
    dx: 0,
    dy: 0,
    dWidth: 0,
    dHeight: 0,
  },
  jobImageCoordinates: [],
  selectedImage:{},
  editedImageData:[],
  createdNewJobImageId:[],
  refreshEditorjobList:false,
  routerPath:"",
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    // SET ACTIVE
    setActive(state) {
      state.mode = CANVAS_MODES.ACTIVE;
    },
    // SET SAVE
    save(state) {
      state.mode = CANVAS_MODES.SAVE;
      // state.type = "";
    },
    //  refresh job list
    setRefreshEditorjobList(state,action){
      state.refreshEditorjobList = action.payload;
    },
    //SET ROUT PATH
    setRouthPath(state,action){
      state.routerPath = action.payload;
    },
    // SET IN ACTIVE
    setInActive(state) {
      state.mode = CANVAS_MODES.IN_ACTIVE;
      state.type = "";
    },
    // SET EDIT MODE
    setEditMode(state) {
      state.mode = CANVAS_MODES.EDIT;
      state.type = CANVAS_EDIT_TYPE.EDIT;
    },
    init(state) {
      state.mode = CANVAS_MODES.INIT;
    },
    resetCanvas(state) {
      state.mode = "";
      state.size = { width: 0, height: 0 };
      state.type = "";
      state.label = "Select crop size";
      state.canvasCordinates = {
        sx: 0,
        sy: 0,
        sWidth: 0,
        sHeight: 0,
        dx: 0,
        dy: 0,
        dWidth: 0,
        dHeight: 0,
      };
    },
    // RESET
    reset(state) {
      state.mode = "";
      state.size = { width: 0, height: 0 };
      state.type = "";
      state.label = "Select crop size";
      state.canvasCordinates = {
        sx: 0,
        sy: 0,
        sWidth: 0,
        sHeight: 0,
        dx: 0,
        dy: 0,
        dWidth: 0,
        dHeight: 0,
      };
      state.jobImageCoordinates = [];
    },
    setCanvasCoordinatesSize(state, action) {
      state.canvasCordinates.sWidth = action.payload.sWidth;
      state.canvasCordinates.sHeight = action.payload.sHeight;
    },
    // SET INIT CANVAS COORDINATES
    setInitCanvasCoordinatesSize(state, action) {
      state.initCanvasCoordinates = action.payload.canvasCordinates;
    },
    // UPDATE CANVAS COORDINATES
    setCanvasCoordinates(state, action) {
      state.canvasCordinates = action.payload.canvasCordinates;
    },
    // SET JOB IMAGE COORDINATES
    setJobImageCoordinates(state, action) {
      state.jobImageCoordinates = action.payload.jobImageCoordinates;
    },
    // SET CUSTOM WIDTH
    setWidth(state, action) {
      state.size.width = action.payload.width;
    },
    // SET CUSTOM HEIGHT
    setHeight(state, action) {
      state.size.height = action.payload.height;
    },
    // SET TYPE
    setType(state, action) {
      state.type = action.payload.editType;
      state.size = action.payload.size;
      state.label = action.payload.label;
      state.mode = CANVAS_MODES.ACTIVE;
    },
    resetMode(state) {
      state.mode = "";
    },
    setSelectedImage(state,action){
      state.selectedImage=action.payload;
    },
    setEditedImageData(state, action){
      state.editedImageData = action.payload;
    },
    setNewJobImageIdList(state, action){
      state.createdNewJobImageId = action.payload;
    }
  },
});

// Reducers
export default slice.reducer;

// Actions
export const {
  setActive,
  setInActive,
  setEditMode,
  reset,
  setCanvasCoordinates,
  setCanvasCoordinatesSize,
  setJobImageCoordinates,
  setWidth,
  setHeight,
  setType,
  init,
  resetMode,
  save,
  resetCanvas,
  setInitCanvasCoordinatesSize,
  setSelectedImage,
  setEditedImageData,
  setNewJobImageIdList,
  setRefreshEditorjobList,
  setRouthPath
} = slice.actions;
