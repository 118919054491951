import axios from "axios";
import Router from "next/router";
import { setSession } from "./jwt";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_V2 || "",
});

// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
// axiosInstance.defaults.timeout = 10000;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    //website-editor is for testing in non website env
    const websiteEditor = window.location.href.includes("website-editor") || (window.location.origin == process.env.NEXT_PUBLIC_WEBSITE)
    if (response && response.status === 401) {
      setSession(null).then((result)=>{
      localStorage.setItem("urlLocator",window.location.href);
      if (websiteEditor) {
        window.location.reload(); 
      } else {
          window.location.href = `${window.location.origin}/dashboard/editor`;
      }
    })
    }

    // if (response && response.status === 403) {
    //   const description = response.data.message;
    //   Router.push(
    //     { pathname: "/dashboard/error", query: { title: '403 Forbidden', description } });
    // }
    return Promise.reject(error);
  }
);

export default axiosInstance;
