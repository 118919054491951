import CustomizedButton from "@/modules/ui-elements/CustomizedButton";
import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { pagesAtom } from "../Atoms";
import { css } from "@emotion/react";
import { Icon } from "src/modules/ui-elements/Icon";



const ConfirmJobScreen =  ({successObject,instructionArray,loading,sendForProTouchHandler, styles,openHandler,entireStrapiData,completeStrapiData,imagesRef}) => {
    const confirmJobRef=useRef(null);
    const strapiData = entireStrapiData?.SendForProTouchBE?.SendForProTouchBE;
   const debounce = (delay)=>{
      if(confirmJobRef.current) clearTimeout(confirmJobRef.current)
      confirmJobRef.current = setTimeout(()=>{sendForProTouchHandler()}, delay)
    }
    useEffect(()=>{
      if(successObject?.autoProTouch)
        debounce(300);
    },[successObject?.autoProTouch])
    const matchingImage = successObject?.images?.find(image => image.image_id === successObject?.selected_templates[0].image_id);
    
    const pages = useAtomValue(pagesAtom);
    const DownloadedImages = pages.map((page) => {
      const image = page.toBase64Url();
      return image;
    });
  
    return (
      <div >
      <div style={{ borderBottom: "1px solid #EEE", padding: "16px" }}>
        <span css={commonStyle.title}>{strapiData?.SendForProTouch || `Send for Pro-Touch`}</span>
      </div>
      <div css={styles.container}>
        {!successObject?.isBatchEditor ? <div style={{ margin: "22px", border: "10px solid #F9F9F9", height: "488px", width: "400px", justifyContent: "center", display: "flex" }}>
          <img src={matchingImage?.url || DownloadedImages?.[0] ||successObject?.banner_image || matchingImage?.dmsPNG} css={css `object-fit:contain`} /> 
          <div>
          </div>
        </div> :
        <div css={css`width:400x;height:488px;margin:22px;`}>
        {(entireStrapiData?.NewFeaturePricingCont?.NewFeaturePricingCont?.VideoBALink || completeStrapiData?.VideoBALink) &&
          <video
            controls
            width="100%"
            height="100%"
            autoPlay={true}
            >
            <source
                src={entireStrapiData?.NewFeaturePricingCont?.NewFeaturePricingCont?.VideoBALink || completeStrapiData?.VideoBALink}
                type="video/mp4"
            />
            Your browser does not support the video tag.
            </video>
        }
        </div>}
        <div style={{ margin: "16px", marginTop: "-100px" }}>
          <div css={[commonStyle.validate, styles.validate]}>
            <Icon src="/icons/proTouch.svg" width={116} height={44}/>
            <span css={[commonStyle.colorText]}>
              {strapiData?.GetYourImagesText || `Get your images retouched by our expert designers`}
            </span>
          </div>
          <div css={styles.bottomContainer}>
            <div css={[commonStyle.creditHeading, commonStyle.summeryTitle]}>
              {strapiData?.SummaryForProTouch || `Summary`}
            </div>
            <div css={commonStyle.imageSubtitle}>
              <div css={commonStyle.creditSubtitle}>
                <span>Images:</span>
                <span>{successObject?.totalImages}</span>
              </div>
              <div css={commonStyle.creditSubtitle}>
                <span>Backgrounds:</span>
                <span>{successObject?.background}</span>
              </div>
              <div css={[commonStyle.creditTotal, commonStyle.imageTotal]}>
                <span>Total Credits Used:</span>
                <span>{successObject?.totalCost}</span>
              </div>
            </div>
          </div>
          <CustomizedButton
            type={"grey"}
            isActive={true}
            btnStyle={{ justifyContent: "space-between" }}
            btnText={instructionArray.length > 0 ? (instructionArray?.[instructionArray.length - 1].slice(0, 25)) : strapiData?.AddInstructionText || "Add instructions"}
            onClick={openHandler}
            endIcon={"/icons/instructionAddition.svg"}
            disabled={loading}
          />
          <CustomizedButton
            type={"pink_with_border"}
            isActive={true}
            btnText={strapiData?.SendForProTouchCTA || "Send for Pro-Touch"}
            btnStyle={commonStyle.btnStyle}
            onClick={sendForProTouchHandler}
            disabled={loading}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
    )
  }

  export default ConfirmJobScreen;

const commonStyle = {
    btnStyle: {
        width: "100%",
        borderRadius: "8px",
        padding: "14px 0",
        marginTop: "4px",
        maxWidth: "382px",
      },
    creditTotal: css`
        display: flex;
        justify-content: space-between;
        font-family: "Objectivity";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
     `,
    imageTotal: css`
        padding-top: 16px;
    `,
    creditSubtitle: css`
        display: flex;
        justify-content: space-between;
        font-family: "Objectivity";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 12px;
    `,
    imageSubtitle: css`
        color: rgba(38, 38, 38, 0.7);
        padding: 12px 16px;
    `,
    creditHeading: css`
        display: flex;
        justify-content: flex-start;
        font-family: "Objectivity";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 16px;
    `,
    summeryTitle: css`
        border-bottom: 1px solid #eee;
        padding: 12px 16px;
    `,
    colorText: css`
        color: #000000;
        text-align: center;
        font-family: "Objectivity";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    `,
};
