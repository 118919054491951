import useEditorService from "@/hooks/useEditorService";
import { activeProjectAtom, initializingEditorAtom } from "@/modules/editor-v2/Atoms";
import { createEditorJobFromTemplate } from "@/modules/editor-v2/services/Jobs";
import { css } from "@emotion/react"
import { useQueryClient } from "@tanstack/react-query";
import { useAtom, useAtomValue } from "jotai";
import { DMSCanvasFile, IPixelDimensions } from "local_modules/editor/lib/Interfaces";
import { DOMElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react"

export interface IBoundingBox {
    width: number,
    height: number,
    xOffset: number,
    yOffset: number,
    verticalJustification: "top" | "center" | "bottom",
    horizontalJustification: "left" | "center" | "right",
    rotation: number,
}

interface TemplatePreviewProps {
    templateUrl: string,
    productImage?: string,
    boundingBox?:IBoundingBox,
    productImageLayerId: string,
    templateId: string
    onClickHandler: () => {}
    editorJSONObject: any
}

export default function TemplatePreview(props:TemplatePreviewProps) {
    const queryClient = useQueryClient();
    const [resizedBoundingBox, setResizedBoundingBox] = useState<IBoundingBox>();
    const [templateNaturalDimensions, setTemplateNaturalDimensions] = useState<IPixelDimensions>();
    const [productImageNaturalDimensions, setProductImageNaturalDimensions] = useState<IPixelDimensions>();
    const [resizedProductImageDimensions, setResizedProductImageDimensions] = useState<IPixelDimensions>();
    const templateImageRef = useRef<HTMLImageElement>(null);
    const {setBackgroundAndSuperImposeProduct,activeEditor,setBackground, applyInfographic} = useEditorService();
    const [initializingEditor, setInitializingEditor] = useAtom(initializingEditorAtom);
    const getActiveProject = useAtomValue(activeProjectAtom);

    const onClick = async()=>{
        queryClient.invalidateQueries(["filteredTemplates","recentused"]);
        if(!getActiveProject){
            props.onClickHandler();
            return;
        }
        // If Infographics Apply here
        if(props?.editorJSONObject?.isInfographics)
        {
            await applyInfographic(props?.editorJSONObject?.editorJSONObject?.data, getActiveProject?.job_id)
        }
        else{
            if(!productImageNaturalDimensions || !props.boundingBox || !templateNaturalDimensions) setBackground(props.templateUrl, props.templateId )
            setBackgroundAndSuperImposeProduct(props.templateUrl,props.templateId,props.productImageLayerId,productImageNaturalDimensions,templateNaturalDimensions,props.boundingBox);
        }
    }

    useEffect(()=>{
        if(!templateNaturalDimensions) return;
        const scaleX = templateImageRef.current.width / templateNaturalDimensions.width;
        const scaleY = templateImageRef.current.height / templateNaturalDimensions.height;
        setResizedBoundingBox({
          height: scaleY * props.boundingBox.height,
          width: scaleX * props.boundingBox.width,
          xOffset: scaleX * props.boundingBox.xOffset,
          yOffset: scaleY * props.boundingBox.yOffset,
          horizontalJustification: props.boundingBox.horizontalJustification,
          verticalJustification: props.boundingBox.verticalJustification,
          rotation: props.boundingBox.rotation,
        });
    },[templateNaturalDimensions])

    useEffect(()=>{
        if(!props.productImage) setProductImageNaturalDimensions(null);
    },[props.productImage])

    const onTemplateLoad = useCallback((e:SyntheticEvent)=>{
        let img = e.target as HTMLImageElement;
        setTemplateNaturalDimensions({
            height: img.naturalHeight,
            width: img.naturalWidth
        })
    },[]);

    const onProductImageLoad = useCallback((e:SyntheticEvent)=>{
        let img = e.target as HTMLImageElement;
        setProductImageNaturalDimensions({
            height: img.naturalHeight,
            width: img.naturalWidth
        })
    },[]);

    useEffect(() => {
        if (!productImageNaturalDimensions || !resizedBoundingBox) return () => {};
        let {height, width} = productImageNaturalDimensions;
        let product_ratio = width/height;
        let bb_ratio = resizedBoundingBox.width / resizedBoundingBox.height;
        let _height, _width;
        if (product_ratio > bb_ratio) {
          _width =  resizedBoundingBox.width//width * scaleFactor
          _height = resizedBoundingBox.width / product_ratio;
          
        } 
        else {
          _height = resizedBoundingBox.height;
          _width =  resizedBoundingBox.height * product_ratio;
        }
        setResizedProductImageDimensions({width: _width, height: _height});
    }, [productImageNaturalDimensions,resizedBoundingBox]);

    return <div css={css`
        display:flex;
        position:relative;
        height: 100%;
        justify-content:center;
        align-items:center;
        cursor:pointer;
    `}
    onClick={onClick}
    >
        <img ref={templateImageRef} src={props.templateUrl} style={{height:"100%",width:"100%",position:'absolute', borderRadius:4}} onLoad={onTemplateLoad} crossOrigin="anonymous"/>
        <div css={css`
            position: absolute;
            top: ${resizedBoundingBox?.yOffset || 0}px;
            left: ${resizedBoundingBox?.xOffset || 0}px;
            height: ${resizedBoundingBox?.height || 0}px;
            width: ${resizedBoundingBox?.width || 0}px;
            transform: rotate(${resizedBoundingBox?.rotation || 0}deg);
            display:flex;
            flex-direction:row;
            justify-content:
            ${resizedBoundingBox?.horizontalJustification == 'left'
              ? 'flex-start'
              : resizedBoundingBox?.horizontalJustification == 'right'
              ? 'flex-end'
              : 'center'};
          align-items:${
            resizedBoundingBox?.verticalJustification == 'top'
              ? 'flex-start'
              : resizedBoundingBox?.verticalJustification == 'bottom'
              ? 'flex-end'
              : 'center'};
        `}>
           {props.productImage && !props?.editorJSONObject?.isInfographics && <img css={css`
                height:${resizedProductImageDimensions?.height || 0}px;
                width:${resizedProductImageDimensions?.width || 0}px;
                opacity:${resizedProductImageDimensions?.height?1:0};

            `} src={props.productImage} onLoad={onProductImageLoad} />}
        </div>
    </div>
}