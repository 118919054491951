import { Observable } from "rxjs";
import Filter, { HueShiftFilterValue } from "./Filter";
import FilterManager from "./FilterManager";
import { SerializedImageLayerInfo } from "./Interfaces";
import Layer from "./Layer";
import {fabric} from 'fabric';
import LayerManager from "./LayerManager";

const HUE_RANGE = 45;
const HUE_OFFSET = 30;

export default class ImageLayer extends Layer {
  imageFabricRef: fabric.Image;
  private filterManager: FilterManager;

  /* Brightness */
  brightnessFilter: Filter;
  brightnessValue: Observable<number | HueShiftFilterValue> = new Observable<number>();

  /* Contrast */
  contrastFilter: Filter;
  contrastValue: Observable<number | HueShiftFilterValue> = new Observable<number>();

  /* Saturation */
  saturationFilter: Filter;
  saturationValue: Observable<number | HueShiftFilterValue> = new Observable<number>();

  /* Hue */
  hueFilter: Filter;
  hueValue: Observable<number | HueShiftFilterValue> = new Observable<number>();

  /* HueShiftFilters */
  hueShiftFilters:Filter[] = [];
  hueShiftValues: Observable<number | HueShiftFilterValue>[] = [];

  /* Blur */
  blurFilter: Filter;
  blurValue: Observable<number | HueShiftFilterValue> = new Observable<number>();

  /*Original Source*/
  originalSrc: string;

  constructor(obj: fabric.Object, layerManager:LayerManager, name?: string) {
    super(obj, layerManager, name);
    this.imageFabricRef = this.fabricRef as fabric.Image;
    this.filterManager = new FilterManager(this.imageFabricRef);
    this.hueFilter = this.filterManager.add("hue", 0);
    this.hueValue = this.hueFilter.observableValue;
    this.brightnessFilter = this.filterManager.add("brightness", 0);
    this.brightnessValue = this.brightnessFilter.observableValue;
    this.contrastFilter = this.filterManager.add("contrast", 0);
    this.contrastValue = this.contrastFilter.observableValue;
    this.saturationFilter = this.filterManager.add("saturation", 0);
    this.saturationValue = this.saturationFilter.observableValue;
    this.blurFilter = this.filterManager.add("blur",0);
    this.blurValue = this.blurFilter.observableValue;
    for (let index = 0; index < 360/HUE_RANGE; index++) {
      const filter = this.filterManager.add("hueShift",{
        hueShift: 0,
        brightnessShift: 0,
        saturationShift: 0,
      },{
        hueStart: (index * HUE_RANGE) - HUE_OFFSET,
        hueEnd: ((index + 1) * HUE_RANGE) - HUE_OFFSET,
      })
      this.hueShiftFilters.push(filter);
      if(filter) this.hueShiftValues.push(filter.observableValue);
    }
    this.originalSrc = this.imageFabricRef.getSrc();
    this.type = "image";
  }

  /**
   * @description Sets Brightness of Image layer
   * @author Ajay Grover
   * @date 2023-01-21
   * @param {number} value:number
   * @returns void
   */
  setBrightness(value: number) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.brightnessFilter.value = value;
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
  }

  /**
   * @description Get Brightness of Image layer
   * @author Prabhat Kumar
   * @date 2024-07-26
   * @returns number
   */
  getBrightness() {
    return this.brightnessFilter.value;
  }


  /**
   * @description Sets Brightness of Image layer
   * @author Ajay Grover
   * @date 2023-01-21
   * @param {number} value:number
   * @returns void
   */
  setContrast(value: number) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    this.contrastFilter.value = value;
  }

  /**
   * @description Sets Brightness of Image layer
   * @author Ajay Grover
   * @date 2023-01-21
   * @param {number} value:number
   * @returns void
   */
  setSaturation(value: number) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    this.saturationFilter.value = value;
  }

  /**
   * @description Sets Brightness of Image layer
   * @author Ajay Grover
   * @date 2023-01-21
   * @param {number} value:number
   * @returns void
   */
  setHue(value: number) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    this.hueFilter.value = value;
  }

  /**
   * @description Sets blur of Image layer
   * @author Ajay Grover
   * @date 2023-01-21
   * @param {number} value:number
   * @returns void
   */
  setBlur(value: number) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    this.blurFilter.value = value;
  }
  
  setHueShift(index:number, value:HueShiftFilterValue) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    this.hueShiftFilters[index].value = value;
  }

  public set src(url: string) {
    this.emit("BEFORE_MODIFY", this.toJSON());
    this.imageFabricRef.setSrc(url, () => {
      this.imageFabricRef.canvas?.renderAll();
      this.imageFabricRef.canvas?.fire("object:modified", { target: this.imageFabricRef });
    },{crossOrigin:'anonymous'});
  }

  public get src(): string {
    return this.imageFabricRef.getSrc();
  }

  setWarmth(gamma: number) {
  if (!this.imageFabricRef) return;
  const adjustment = (gamma / 100) * -1;

  const adjustedBlue = adjustment >= 0 ?
    1 + adjustment * 0.8 : // Decrease blue for warmth
    1 + adjustment * 2.0; //  Increase blue for coolness

  const yellowishFilter = new fabric.Image.filters.ColorMatrix({
    matrix: [
      1, 0, 0, 0, 0, // Red channel
      0, 1, 0, 0, 0, // Green channel
      0, 0, adjustedBlue, 0, 0, // Blue channel (adjust blue based on slider input)
      0, 0, 0, 1, 0 // Alpha channel
    ]
  });
  this.imageFabricRef.filters = this.imageFabricRef.filters.filter(filter => {
    return !(filter instanceof fabric.Image.filters.ColorMatrix);
  });
  this.imageFabricRef.filters.push(yellowishFilter);
  this.imageFabricRef.applyFilters();
  this.imageFabricRef.canvas?.requestRenderAll();
}

  public override async setPropertiesFromSerializedData(
    data: SerializedImageLayerInfo
  ) {
    super.setPropertiesFromSerializedData(data);
    this.setBrightness(data.brightness);
    this.setContrast(data.contrast);
    this.setSaturation(data.saturation);
    this.setHue(data.hue);
    this.setBlur(data?.blur || 0);
    data.hueShift?.forEach((value,index)=>this.setHueShift(index,value));
    if(data.originalSrc) {
      this.originalSrc = data.originalSrc;
    }
  }

  public override toJSON(): SerializedImageLayerInfo {
      let serializedLayer = super.toJSON();
      let serializedImageLayer:SerializedImageLayerInfo = {
        ...serializedLayer, 
        brightness: this.brightnessFilter.value as number,
        contrast: this.contrastFilter.value as number,
        hue: this.hueFilter.value as number,
        saturation: this.saturationFilter.value as number,
        hueShift: this.hueShiftFilters.map(x=>x?.value) as HueShiftFilterValue[],
        src: this.src,
        originalSrc: this.originalSrc,
        blur: this.blurFilter.value as number,
      }
      return serializedImageLayer;
  }
}
