import { css } from "@emotion/react";
import { Loader } from "../MiddleSection/EditorTopToolBar";
import CustomizedButton from "@/modules/ui-elements/CustomizedButton";
import useAuth from "@/hooks/useAuth";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { activeProjectAtom, isProFeatureClickedAtom, isRemoveWatermarkClickedAtom, segmentEventPayload, showSuccessProjectPopupAtom } from "../Atoms";
import { TrackWebappEvent } from "@/modules/segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "@/modules/segment-event-tracking/constant";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import useToast from "@/hooks/useToast";
import { ICONS } from "@/modules/ui-elements/Icon";
interface IConfirmJobPopUpCTAPropType { 
    confirmOrder: ({}) => void;
    cancelOrder: () => void;
    loading?: boolean;
    loadingDownload?:boolean;
    loadingSendProTouch?:boolean;
    disabled: boolean;
    totalCost: number;
    setIsOpen:(data:boolean)=>void;
    proTouchJobHandler:({}) => void;
    setImageAsync:()=>Promise<string[]>;
    isComplimentaryJob?: boolean;
}

export default function ConfirmJobPopUpCTAs(props: IConfirmJobPopUpCTAPropType) {
   const {user} = useAuth();
   const getSegmentPayloadAtom = useAtomValue(segmentEventPayload);
   const activeProject = useAtomValue<any>(activeProjectAtom);
   const projectName = useAtomValue<any>(activeProjectAtom)?.name;
   const credit = user?.entity?.entity?.credits ?? 0;
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.StudioProjectDetailsNew?.StudioProjectDetailsNew;
    const toast = useToast();
    const onDownloadHandler = async(images?:string[]) => {
        if(props.disabled)return; 
        props.setImageAsync().then(res=>{
            props.confirmOrder({
                job_id: activeProject?.job_id,
                type:user?.user?.login_type === 'Guest' ? 'GUEST_JOB_LOW_RESOLUTION' : 'LOGGED_IN_JOB_HIGH_RESOLUTION',
                projectName,
                credit,
                images
            });
            props.setIsOpen(false);
         }
        );
        
    };


    const onProTouchHandler = () => {
        sessionStorage.clear();
        if(props.disabled)return;
        props.proTouchJobHandler({
            type:'SEND_PRO_TOUCH',
            projectName,
            credit
        })
        props.setIsOpen(false);
    };
    // useEffect(()=>{
    //     if(user?.user && isRemoveWatermarkClicked && user?.user?.login_type !== 'Guest'){ 
    //         toast.info("Starting Download",500);
    //         console.log("i am here 3")
    //         props.setImageAsync().then(onDownloadHandler);
    //         setIsRemoveWatermarkClicked(false);
    //     }
    // },[isRemoveWatermarkClicked, user])

    const creditCost = useMemo(()=>{
        if(props?.totalCost){
            // Removed type checking here as we get decimal value of total cost and need to compare it with exact 1.
            if(props.totalCost != 1){
                return ` - (${parseFloat((props?.totalCost || 0).toString()).toFixed(2) || '0.00'} ${strapiData?.GetRetouchServiceCredit || 'Credit'}s) `;
            }else{
                return `- (${parseFloat((props?.totalCost || 0).toString()).toFixed(2) || '1.00'} ${strapiData?.GetRetouchServiceCredit || 'Credit'})`;
            }
        }else{
            return '0.00 Credits'
        }
    },[props?.totalCost, strapiData?.GetRetouchServiceCredit]);

    return <div css={styles}>
                <CustomizedButton btnText={strapiData?.DownloadCTA || "Download"} type={'secondary'} startIcon={ICONS.downloadLight} isActive={true} btnStyle={commonCss.btnStyle(props.disabled)} btnTextStyle={commonCss.textStyle} onClick={onDownloadHandler} isLoading={props?.loadingDownload} disabled={props?.loadingDownload || props?.loadingSendProTouch}/>
                <CustomizedButton 
                    btnText={
                        (user?.user?.login_type === 'Guest' || props?.isComplimentaryJob) 
                            ? strapiData?.ImageRetouching || 'Get Free Image Retouching' 
                            : `${strapiData?.GetRetouchService || 'Send for Pro-Touch'} ${creditCost}`} 
                    type={'violet_with_border'} 
                    isActive={true} 
                    btnStyle={commonCss.btnStyle(props.disabled)} 
                    btnTextStyle={commonCss.textStyle} 
                    onClick={onProTouchHandler} 
                    isLoading={props?.loadingSendProTouch} 
                    disabled={props?.loadingDownload || props?.loadingSendProTouch} 
                />

    </div>
}

const commonCss = {
    btnStyle:(disabled:any)=>{
        return {borderRadius:'8px',
        padding:'16px 0',
        fontWeight:400,
        border:'none',
        opacity:disabled ? 0.6 : 1,
    }
    },
    textStyle:{
        padding:"4px 0px"
    }
}
const styles = css`
    & {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;
        gap:10px;
    }
    .disabled {
        opacity:0.5;
        cursor:default !important;
        box-shadow:none !important;
    }
    .primary-cta {
        background: linear-gradient(95.35deg, #F03750 18.9%, #EB2E83 91.1%);
        border-radius: 4px;
        padding:10px 16px;
        width:100%;
        text-align:center;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        color: #FFFFFF;
        margin-bottom:8px;
        cursor:pointer;
        transition:all 0.25s ease;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
    }
    .secondary-cta {
        background: #F9F9F9;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
        border-radius: 4px;
        padding:10px 16px;
        width:100%;
        text-align:center;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        color: rgba(38, 38, 38, 0.7);
        cursor:pointer;
        transition:all 0.25s ease;s
        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            background: #FAFAFA;
        }
    }
`