import InfoButton from "@/modules/ui-elements/InfoButton";
import { css } from "@emotion/react";
import { ICONS, Icon } from "src/modules/ui-elements/Icon";
import { TEMPLATE_TYPE } from "../index";
import useComponentVisible from "src/hooks/useComponentVisible";
import { backgroundFilterIsAllAngleGroupActiveAtom, backgroundFilterProductImageIdAtom } from "../../../Atoms";
import { useAtomValue } from "jotai";
import useEditorService from "@/hooks/useEditorService";
import BackgroundAngleList from "./BackgroundAngleList";
import { useState, useEffect, useRef } from "react";
import DropdownSelection from "src/modules/ui-elements/DropdownSelection";
import { useQueryClient } from "@tanstack/react-query";
import SearchPane from "./SearchPane";
import FiltersPane from "./FiltersPane";

const TemplateTopSection = ({
  strapiData,
  templateType,
  setTemplateType,
  selectedFilter,
  setSelectedFilter,
  prespectiveList,
  prespectiveListLoading,
  setBackgroundMode,
  onSelectAngleHandler,
  trendingList,
  trendingListLoading,
  onSearchHandler = () =>{},
}) => {
  const {
    isComponentVisible: isPrespectiveVisible,
    setIsComponentVisible: setIsPrespectiveVisible,
  } = useComponentVisible(false);
  const { activeEditor } = useEditorService();
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const isAllAngleGroupActive = useAtomValue(backgroundFilterIsAllAngleGroupActiveAtom);
  const [isSearchPaneVisible, setIsSearchPaneVisible] = useState(false);
  const [isFiltersPaneVisible, setIsFiltersPaneVisible] = useState(false);
  const queryClient = useQueryClient();
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsSearchPaneVisible(false);
      setIsFiltersPaneVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {/* {templateType === TEMPLATE_TYPE.RECENT ? (
        <div css={styles.typeContainer}>
          {strapiData?.RecentlyUsedText || "Recently Used"}
          <Icon
            src={ICONS.crossLight}
            width={28}
            height={28}
            style={styles.pointer}
            onClick={() => setTemplateType(TEMPLATE_TYPE.ALL)}
          />
        </div>
      ) : templateType === TEMPLATE_TYPE.FAVOURITE ? (
        <div css={styles.typeContainer}>
          {strapiData?.FavoriteText || "Favourite"}
          <Icon
            src={ICONS.crossLight}
            width={28}
            height={28}
            style={styles.pointer}
            onClick={() => setTemplateType(TEMPLATE_TYPE.ALL)}
          />
        </div>
      ) : ( */}
        <div css={styles.topRow} ref={ref}>
          <div css={styles.headingTitle}>
            <div css={styles.textContainer}>
              {strapiData?.Heading || "Backgrounds"}
            </div>
            <InfoButton
              title={strapiData?.Heading || "Backgrounds"}
              text={
                strapiData?.InfoText ||
                "Explore a wide range of 2D and 3D templates to compliment your project"
              }
            />
          </div>
          <div style={{ position: 'relative' }}>
            <RightIcons onSearchClick={() =>{ setIsSearchPaneVisible(x => !x), setIsFiltersPaneVisible(false)}} onFiltersClick={() => {setIsFiltersPaneVisible(x => !x), setIsSearchPaneVisible(false)}} isSearchPaneVisible={isSearchPaneVisible} />
            {(selectedFilter?.hotspotOnly || selectedFilter?.imageFormat[0].toUpperCase() !== "SQUARE" || !isAllAngleGroupActive || templateType === TEMPLATE_TYPE.FAVOURITE || templateType === TEMPLATE_TYPE.RECENT ) && <div
              css={css`
              height:10px;
              width:10px;
              background-color:#FFFFFF;
              position:absolute;
              top:-2px;
              right:-2px;
              border-radius:5px;  
            `}
            ></div>}
          </div>
          {isSearchPaneVisible && <SearchPane
            setSelectedFilter={setSelectedFilter}
            trendingList={trendingList}
            onSearchHandler={onSearchHandler}
            strapiData={strapiData}
            setIsSearchPaneVisible={(value)=>{setIsSearchPaneVisible(value)}}
            trendingListLoading={trendingListLoading}
          />}
          {isFiltersPaneVisible && <FiltersPane
            setTemplateType={setTemplateType}
            setSelectedFilter={setSelectedFilter}
            setIsFiltersPaneVisible={setIsFiltersPaneVisible}
            strapiData={strapiData}
            selectedFilter={selectedFilter}
            prespectiveList={prespectiveList}
            prespectiveListLoading={prespectiveListLoading}
            setIsPrespectiveVisible={setIsPrespectiveVisible}
            setBackgroundMode={setBackgroundMode}
            onSelectAngleHandler={onSelectAngleHandler}
          />}
          {/* {(activeEditor && productImageId) && (
            <div
              css={styles.sorting(isPrespectiveVisible)}
              onClick={() => setIsPrespectiveVisible((prev) => !prev)}
            >
              <span>
                {selectedFilter?.prespective?.angleGroupId
                  ? selectedFilter?.prespective?.angleGroupName
                  : isAllAngleGroupActive ? "All" : "Perspective"}
              </span>
              <img src={ICONS.chevronFilledLightDown} />
            </div>
          )}
          {isPrespectiveVisible && (
            <BackgroundAngleList
              prespectiveRef={prespectiveRef}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              prespectiveList={prespectiveList}
              prespectiveListLoading={prespectiveListLoading}
              setIsPrespectiveVisible={setIsPrespectiveVisible}
              setBackgroundMode={setBackgroundMode}
              onSelectAngleHandler={onSelectAngleHandler}
            />
          )} */}
        </div>
      {/* )} */}
    </>
  );
};

const TemplateBottomSection = ({ selectedFilter, onRemoveHandler }) => {
  return (
    <>
      {(selectedFilter.searchString?.length > 0 ||
        selectedFilter.colors?.length > 0) && (
        <div css={styles.searchList}>
          {selectedFilter.searchString.map((obj) => (
            <div
              css={[
                styles.searchLightOption,
                css`
                  min-width: fit-content;
                `,
              ]}
            >
              {obj}
              <Icon
                src={ICONS.crossLight}
                width={10}
                height={10}
                style={{ cursor: "pointer" }}
                onClick={() => onRemoveHandler(obj)()}
              />
            </div>
          ))}
          {selectedFilter.colors.map((obj) => (
            <div
              css={[
                styles.searchLightOption,
                css`
                  min-width: fit-content;
                `,
              ]}
            >
              <span>{`Color: ${obj.replace("#", "").toUpperCase()}`}</span>
              <Icon
                src={ICONS.crossLight}
                width={10}
                height={10}
                style={{ cursor: "pointer" }}
                onClick={() => onRemoveHandler(obj)()}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const RightIcons = ({ onSearchClick, onFiltersClick, isSearchPaneVisible }) => {
  return <div style={{ color: 'white', flexWrap: 'nowrap', display: 'flex', gap: 8 }}>
    <div
      css={css`
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 4px;
      background: ${isSearchPaneVisible ? "#EEEEEE" : " #EEEEEE29"};
      cursor:pointer;
    `}

      onClick={onSearchClick}
    >
      {isSearchPaneVisible ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#262626" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.0001 14.0001L11.1001 11.1001" stroke="#262626" stroke-linecap="round" stroke-linejoin="round" />
      </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#EEEEEE" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.0001 14L11.1001 11.1" stroke="#EEEEEE" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      }
    </div>
    <div
      css={css`
      width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 4px;
      background: #EEEEEE29;
      cursor:pointer;
    `}

    onClick={onFiltersClick}
  >
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7073 6.25641C15.7073 5.91654 15.9694 5.64103 16.2927 5.64103H18.6341C18.9574 5.64103 19.2195 5.91654 19.2195 6.25641C19.2195 6.59628 18.9574 6.87179 18.6341 6.87179H16.2927C15.9694 6.87179 15.7073 6.59628 15.7073 6.25641Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1707 4C13.494 4 13.7561 4.27552 13.7561 4.61538V7.89744C13.7561 8.2373 13.494 8.51282 13.1707 8.51282C12.8474 8.51282 12.5854 8.2373 12.5854 7.89744V4.61538C12.5854 4.27552 12.8474 4 13.1707 4Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.25641C4 5.91654 4.26208 5.64103 4.58537 5.64103H13.1707C13.494 5.64103 13.7561 5.91654 13.7561 6.25641C13.7561 6.59628 13.494 6.87179 13.1707 6.87179H4.58537C4.26208 6.87179 4 6.59628 4 6.25641Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 11.6601 4.26208 11.3846 4.58537 11.3846H7.70732C8.03061 11.3846 8.29268 11.6601 8.29268 12C8.29268 12.3399 8.03061 12.6154 7.70732 12.6154H4.58537C4.26208 12.6154 4 12.3399 4 12Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8293 9.74359C11.1526 9.74359 11.4146 10.0191 11.4146 10.359V13.641C11.4146 13.9809 11.1526 14.2564 10.8293 14.2564C10.506 14.2564 10.2439 13.9809 10.2439 13.641V10.359C10.2439 10.0191 10.506 9.74359 10.8293 9.74359Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2439 12C10.2439 11.6601 10.506 11.3846 10.8293 11.3846H19.4146C19.7379 11.3846 20 11.6601 20 12C20 12.3399 19.7379 12.6154 19.4146 12.6154H10.8293C10.506 12.6154 10.2439 12.3399 10.2439 12Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7073 17.7436C15.7073 17.4037 15.9694 17.1282 16.2927 17.1282H18.6341C18.9574 17.1282 19.2195 17.4037 19.2195 17.7436C19.2195 18.0835 18.9574 18.359 18.6341 18.359H16.2927C15.9694 18.359 15.7073 18.0835 15.7073 17.7436Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1707 15.4872C13.494 15.4872 13.7561 15.7627 13.7561 16.1026V19.3846C13.7561 19.7245 13.494 20 13.1707 20C12.8474 20 12.5854 19.7245 12.5854 19.3846V16.1026C12.5854 15.7627 12.8474 15.4872 13.1707 15.4872Z" fill="#EEEEEE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 17.7436C4 17.4037 4.26208 17.1282 4.58537 17.1282H13.1707C13.494 17.1282 13.7561 17.4037 13.7561 17.7436C13.7561 18.0835 13.494 18.359 13.1707 18.359H4.58537C4.26208 18.359 4 18.0835 4 17.7436Z" fill="#EEEEEE"/>
    </svg>
  </div>
</div>
}

const styles = {
  textContainer: css`
    font-family: "Objectivity";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: #eeeeee;
  `,
  topRow: css`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index:100;
  `,
  headingTitle: css`
    display: flex;
    gap: 8px;
    font-family: Objectivity;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;

  `,
  sorting: (active) => css`
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #121212;
    cursor: pointer;
    span {
      color: #fff;
      text-align: right;
      font-family: "Objectivity";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 170%;
      letter-spacing: 0.24px;
    }
    ,
    img {
      width: 16px;
      height: 16px;
      transform: rotate(${active ? "180deg" : "0deg"});
    }
  `,
  TemplateTopSection: css`
    position: absolute;
    width: 340px;
    height: 0.5px;
    left: 10px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.24);
  `,
  typeContainer: css`
    color: #eee;
    font-size: 14px;
    font-family: "Objectivity";
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  searchList: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0px 0px 0px;
    gap: 8px;
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  searchLightOption: css`
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    padding: 5px 10px 4px;
    background-color: #464646;
    color: rgba(255, 255, 255, 0.7);
    font-family: Objectivity;
    font-size: 11px;
    line-height: 1.5;
    min-width: 50px;
  `,
  pointer: {
    cursor: "pointer",
  },
  inputBoxContainer: css`
    width: 100%;
    display: flex;
    border-radius: 4px;
    background: #FFFF00;

  `,
  inputBox: css`
    flex: 1;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    gap: 8px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      color: rgba(255, 255, 255, 0.7);
      font-family: Objectivity;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
    }
  `,
};


export default TemplateTopSection;