import { css } from "@emotion/react"
import { Icon } from "../Icon"
import { Loader } from "@/modules/editor-v2/MiddleSection/EditorTopToolBar"

export const DashedInputTypes = {
    dark: "dark",
    light: "light",
    lightest: "lightest",
}

export default function DashedInput({icon, value, disabled=false, onChange=(e)=>{}, type=DashedInputTypes.dark, loading=false, isDashVisible=true, isIconVisible=true, dashedInputStyle={}}) {
    const ICON_SIZE = DashedInputTypes.light?16:12;
    return <div style={{...styles.container((icon)?.[type], isDashVisible), ...dashedInputStyle}}>
        <input
            css={{...styles.input?.[type]}}
            value={value}
            disabled={disabled}
            onChange={onChange}
            style={loading?{marginRight:4}:{}}
        />
        {!!!loading ? (isIconVisible && <Icon src={icon} height={ICON_SIZE} width={ICON_SIZE} style={{marginLeft:4}}/>) : <Loader height={ICON_SIZE-2} width={ICON_SIZE-2} type={type}/> }
    </div>
}

const styles = {
    container: (icon, isDashVisible) => ({
        dark:{
            paddingTop:"6px", 
            paddingBottom:"6px", 
            paddingLeft:"4px", 
            paddingRight:`${icon?"10px":"4px"}`, 
            backgroundColor:"#252525", 
            display:"flex",
            flex:1,
            borderWidth:"0px",
            borderBottomWidth: isDashVisible ? "1px" : "",
            borderColor:'white',
            borderStyle:'dashed',
            alignItems:'center'
        },
        light:{
            paddingTop:"10px", 
            paddingBottom:"12px", 
            paddingLeft:"8px", 
            paddingRight:`${icon?"16px":"8px"}`, 
            backgroundColor:"#F9F9F9", 
            display:"flex",
            flex:1,
            borderWidth:"0px",
            borderBottomWidth: isDashVisible ? "1px" : "",
            borderColor:'rgba(38, 38, 38, 0.7)',
            borderStyle:'dashed',
            alignItems:'center'
        },
        lightest:{
            padding:"8px", 
            paddingRight:`${icon?"16px":"8px"}`, 
            backgroundColor:"#FFFFFF", 
            display:"flex",
            flex:1,
            gap:'4px',
            borderWidth:"0px",
            borderBottomWidth: isDashVisible ? "1px" : "",
            borderColor:'rgba(38, 38, 38, 0.7)',
            borderStyle:'dashed',
            alignItems:'center'
        },
    }),
    input: {
        dark:css`
            flex:1;
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 170%;
            &:focus-visible {
                outline:0px;
            }
            display: flex;
            align-items: center;
            background:transparent;
            border: 0px;
            color: rgba(255, 255, 255, 0.9);
            width:100%;
            flex:1000;
            overflow: hidden;
            text-overflow: ellipsis;
        `,
        light:css`
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 170%;
            &:focus-visible {
                outline:0px;
            }
            display: flex;
            align-items: center;
            background:transparent;
            border: 0px;
            color: rgba(38, 38, 38, 0.9);
            width:100%;
            flex:1000;
            overflow: hidden;
            text-overflow: ellipsis;
        `,
        lightest:css`
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 170%;
            &:focus-visible {
                outline:0px;
            }
            display: flex;
            align-items: center;
            background:transparent;
            border: 0px;
            color: rgba(38, 38, 38, 0.9);
            width:100%;
            flex:1000;
            overflow: hidden;
            text-overflow: ellipsis;
        `,
    },
}