export const EncryptMessage = (publicKey, message) =>{
        try{
            let key = `-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`;
            if(!window) setImmediate(EncryptMessage);
            const { JSEncrypt } = require('jsencrypt');
            const jsEncrypt = new JSEncrypt();
            jsEncrypt.setPublicKey(publicKey);
            const encryptedPassword = jsEncrypt.encrypt(message);
            return encryptedPassword
    }catch(err){
        console.error({err});
            return false
    }
}
