import InfoButton from "@/modules/ui-elements/InfoButton";
import { css } from "@emotion/react";
import { useSetAtom } from "jotai";
import { useSelector } from "react-redux";

export default function AIBackgroundResultsHeader({setGenerating, setIsVisualPrompt=(isVisible:boolean)=>{}, urls, setAttributePage}) {

    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    
    return <div css={styles.container}  style={{ opacity: (urls.length && !urls?.[0]?.loading) ? 1 : 0.5, pointerEvents:(urls.length && !urls?.[0]?.loading ) ? 'auto' : 'none',cursor: (urls.length && !urls?.[0]?.loading ) ? "pointer" : "not-allowed"}}>
        <div css={styles.topRow}>
            <div style={{display:"flex", color:'white', alignItems:'center'}}>
                <div css={styles.backButton} onClick={()=>{
                    setGenerating(false);
                    setIsVisualPrompt?.(false);
                    setAttributePage?.(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.0002 6L9.00037 11.9999L15.0002 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div css={styles.textContainer}>{strapiText?.HeaderTitle || "Custom Backgrounds"}</div>
            </div>
        </div>
        <div css={styles.divider}></div>
    </div>
}

const styles = {
    container: css`
        padding-top:16px;
        padding-bottom:10px;
        padding-left:10px;
        padding-right:10px;
        position:relative;
    `,
    textContainer: css`
        color: #FFF;

        font-family: Objectivity-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 23.8px */
        letter-spacing: 0.14px;
        display: flex;
        align-items: center;
    `,
    topRow: css`
        flex-direction:row;
        display:flex;
        justify-content:space-between;
        align-items:center;
    `,
    divider: css`
        position: absolute;
        width: 340px;
        height: 0.5px;
        left: 10px;
        bottom: 0px;
        background:rgba(255, 255, 255, 0.24);
    `,
    backButton: css`
        display: flex;
        width: 32px;
        height: 32px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.16);
        margin-right:16px;
    `
}
