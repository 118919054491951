import { Icon } from "src/modules/ui-elements/Icon";
import { css } from "@emotion/react";

enum ShadedTextColor {
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  VOILET = "VOILET",
}

interface ShadedTextProps {
  text: string;
  startIcon:string;
  endIcon:string;
  color:ShadedTextColor
}

export default function ShadedText(props: ShadedTextProps) {
  const styles = customizedStyle(props.color);
  console.log("tag color",props.color)
  return (
    <div css={styles.container}>
      {props?.startIcon && <Icon
        src={props?.startIcon}
        width={20} height={20}
      />}
        <p css={styles.text}>{props.text}</p>
        {props?.endIcon && <Icon src={props?.endIcon} width={10} height={10} />}
    </div>
  );
};

const customizedStyle = (color: ShadedTextColor) => {
  let container: any, text: any;
  switch (color) {
    case ShadedTextColor.GREEN: {
      container = css`
        font-family: "Objectivity-Medium", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 16px;
        gap:5px;
        background: rgba(75, 181, 67, 0.08);
      `;
      text = css`
        color: #4BB543;
        text-align: center;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 20.4px */
        letter-spacing: 0.12px;
        padding-top: 2px;
      `;
      break;
    }
    case ShadedTextColor.ORANGE: {
      container = css`
        font-family: "Objectivity-Medium", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 16px;
        gap:5px;
        background: rgba(255, 133, 21, 0.08);
      `;
      text = css`
        color: #FF8515;
        text-align: center;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 20.4px */
        letter-spacing: 0.12px;
        padding-top: 2px;
      `;
      break;
    }
    case ShadedTextColor.VOILET: {
      container = css`
        width:100%;
        font-family: "Objectivity-Medium", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 16px;
        gap:5px;
        background: linear-gradient(92deg, rgba(95, 46, 229, 0.06) -9.99%, rgba(159, 53, 240, 0.06) 120.27%);
      `;
      text = css`
        text-align: center;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 20.4px */
        letter-spacing: 0.12px;
        padding-top: 2px;
        background: var(--Pro-Shoot, linear-gradient(92deg, #5F2EE5 -9.99%, #9F35F0 120.27%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
      break;
    }
    default:
      break;
  }
  return { container, text };
};

