import styles from "./UploadAssetPrompt.module.css";
import GenerateImageIcon from "./GenerateImageIcon.svg";
import UploadIcon from "./UploadIcon.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useToast from "@/hooks/useToast";
import { useQueryClient } from "@tanstack/react-query";
import { addAssets, removeAssetBackground } from "../../services/Assets";
import FileUploader from "@/modules/ui-elements/FileUploader";
import AddUploadAssetPopUp from "../MyAssets/AddUploadAssetPopUp";
import useEditorService, { ProductImageType } from "@/hooks/useEditorService";
import { createEmptyEditorJob, getJobDetails } from "../../services/Jobs";
import useOtherCategory from "@/hooks/useOtherCategory";
import { useSetAtom, useAtomValue } from "jotai";
import { activeProjectAtom, initializingEditorAtom, isToAddAssetNonBGRemovedAtom, toAddAssetAtom, toAddAssetIdAtom, standardInfographic, activeAssetTabAtom } from "../../Atoms";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import MyAssetsNavigator from "../MyAssets/MyAssetsNavigator";
import UploadAssets from "../MyAssets/UploadAssets";
import ShootAssets from "../MyAssets/ShootAssets";
import Head from "next/head";
import { css } from "@emotion/react";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PromptGeneratorHeader from "src/modules/editor-v2/LeftToolBar/AIBackgrounds/promptGeneratorHeader";

const CAROUSEL_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false, 
    arrows: false,
    // adaptiveHeight: true,
};

export default function UploadAssetPrompt({setIsAIBackgrounds}) {
    const fileUploaderRef = useRef<any>();
    const toaster = useToast();
    const queryClient = useQueryClient();
    const addUploadRef = useRef<any>();
    const currentAsset = useRef<any>();
    const {activeEditor, addProductImage, setBackground} = useEditorService();
    const setIsEditorInitializing = useSetAtom(initializingEditorAtom);
    const setToAddAssetId = useSetAtom(toAddAssetIdAtom);
    const setActiveProject = useSetAtom<any>(activeProjectAtom);
    const setToAddAsset = useSetAtom(toAddAssetAtom);
    const setIsToAddAssetNonBgRemoved = useSetAtom(isToAddAssetNonBGRemovedAtom);
    const {otherCategory, otherSubCategory, isLoading:isCategoriesLoading} = useOtherCategory();
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const isStandardInfographic = useAtomValue(standardInfographic);
    const [selected, setSelected] = React.useState(1);
    const carouselRef = React.useRef();
    const routeRef = React.useRef();
    const [shootAssetList, setShootAssetList] = useState([]);
    const setActiveAssetTab = useSetAtom(activeAssetTabAtom);
    
    const onClickGenerator = (index:number) => {
        return () => {
            setSelected(index);
        }
    };

    const onListChange = useCallback((list:any[])=>{
        setShootAssetList(list);
        queryClient.invalidateQueries(['assets'])
    },[])

    useEffect(()=>{
        if(carouselRef.current){
            // @ts-ignore
            carouselRef.current.slickGoTo(selected);
            setActiveAssetTab(selected);
        }
    },[selected])
    
    const routes = [
        {
            name: `${strapiText?.ShootAssestText || "Shoot Assets"}`,
            component: ShootAssets
        },
        {
            name: `${strapiText?.UploadAssestText || "Upload Assets"}`,
            component: UploadAssets
        },
    ];

    const openFilePicker = () => {
        if(!fileUploaderRef.current) return;
        fileUploaderRef.current.open();
    };

    const onFilesSelect = async (files) => {
        const key = toaster.info("Uploading Assets....", 5000);
        const asset = await addAssets(files).then(res=>{
            queryClient.invalidateQueries(['assets']).then(_=>toaster.close(key));
            return res;
        }).catch((err)=>{
            toaster.close(key);
            toaster.error(err?.response?.data || "Error uploading assets...", 5000);
        });
        console.log({asset});
        if(!asset?.[0]?.url || !asset?.[0]?._id) return;
        if(!addUploadRef.current) return;
        currentAsset.current = asset[0];
        addUploadRef.current?.open(asset[0].url, asset[0]._id);
    }

    const createCanvasWithAsset = async(assetURL,assetID, isBGRemoved=true) => {
        try{
            setIsEditorInitializing(true);
            setToAddAsset(assetURL);
            setToAddAssetId(assetID);
            if(!isBGRemoved) setIsToAddAssetNonBgRemoved(true);
            const jobId = await createEmptyEditorJob(otherCategory?._id, otherSubCategory?._id, isStandardInfographic);
            const job = await getJobDetails(jobId);
            setActiveProject(job);

        }catch(err){
            setIsEditorInitializing(false);
            toaster.error("Something went wrong while adding asset to canvas");
            console.log(err)
        }
    
}; 

    const onRemoveBg = useCallback(()=>{
        if(!currentAsset?.current) return;``
        const key = toaster.info("Removing Background",60000);
        removeAssetBackground(currentAsset.current._id).then(async(res)=>{
            toaster.success("Background Removed");
            const refreshingAssetsToasterKey = toaster.info("Refreshing Assets", 60000);
            queryClient.invalidateQueries(['assets']).then(()=>{
                toaster.close(refreshingAssetsToasterKey);
            });
            if(!activeEditor) {
                await createCanvasWithAsset(res.url,currentAsset.current._id);
                return;
            }
            const addingImageToCanvasToasterKey = toaster.info("Adding Image to Canvas", 60000);
            addProductImage(res.url,ProductImageType.ASSET,currentAsset.current._id).finally(()=>{
                toaster.close(addingImageToCanvasToasterKey);
            })
            setBackground(null,null);
        }).catch(err=>{
            if(activeEditor) toaster.error(err.response?.data || "Error Removing Background", 2000);
        }).finally(()=>{
            toaster.close(key);
            addUploadRef.current.close();
        });
    },[addProductImage]);


    return <>
    <div className={styles.container}>
    <PromptGeneratorHeader setIsAIBackgrounds={setIsAIBackgrounds} displaytext={strapiText?.AIBackgroundsWidgetHeader || "AI-Backgrounds"}/>
        <div className={styles.topRow}>
            <div>
                {strapiText?.PromptGeneratorTitle || "Prompt"}
            </div>
            <div className={styles.rightTag}>
                {strapiText?.AssistedText || "Assisted"}
             </div>
        </div>
        
        <textarea className={styles.textarea} placeholder={strapiText?.PromptGeneratorInputPlaceholder || "Describe your Background"} >
        </textarea>
       
        {/* <UploadAssets isSelected={selected} /> */}
        <div className={`${styles.generateButton} ${styles.generateButtonLoading}`} data-tooltip-id={"upload-asset"} data-tooltip-content={"Upload Asset"}>
            <GenerateImageIcon />
            <div>{strapiText?.GenerateImagesButton ||  "Generate Images"}</div>
        </div>
        <Tooltip id={"upload-asset"}  />
       
        
       
    </div>
    <div>
         
         <HeadInjector />
        <div css={aiStyles.topRow} id="my-assets-navigator-header">
            {routes.map((route,index)=>{
                return <div key={index} css={(selected === index ? aiStyles.selectedNavigationItem : aiStyles.navigationItem)} onClick={onClickGenerator(index)}>{route.name}</div>
            })}
        </div>
        <div css={css`
            display:flex;
            flex-direction:row;
            transition:all 0.3s ease-in-out;
            margin:0px 10px;
        `}>
            <div css={css`
                display:flex;
                flex:${1/routes.length};
                height:2px;
                background-color:rgba(255,255,255,1);
                transform: translateX(${selected * 100}%);
                transition:all 0.3s ease-in-out;
            `}></div>
        </div>
        <div css={aiStyles.divider}></div>
        <div id="slider" style={{color:'white', display:'flex', flexDirection:'column', flex:1, position:'relative',}} >
            <Slider {...CAROUSEL_SETTINGS}  ref={carouselRef} beforeChange={(_,i:number)=>{setSelected(i)}} style={{flex:1}}>
                {routes.map((_,index)=>{
                    const Route = useMemo(()=>_.component,[selected]);
                    //@ts-ignore
                    console.log({Route})
                    return(<div style={{height:'100%', flex:1, display:'flex'}}><Route ref={routeRef} isSelected={selected === index} onListChange={onListChange}/></div>);
                })}
            </Slider>
        </div>
    </div></>
}

const HeadInjector = () => {
    return <Head>
        <style>
            {`
                #slider .slick-slide > div {
                    height:100%;
                }
                #slider .slick-track {
                    height:100%;
                }
                #slider .slick-list {
                    height:100%;
                }
                #slider .slick-slide > div > div {
                    height:100% !important;
                }
            `}
        </style>
    </Head>
}


const aiStyles = {
    container: css`
        display:flex;
        flex-direction:column;
        padding:0px 0px;
        flex:1;
        overflow:auto;
    `,
    topRow: css`
        color:white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        cursor:pointer;
        
    `,
    navigationItem: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: rgba(238, 238, 238, 0.7);
        transition:all 0.3s ease-in-out;
        padding: 10px 10px;
    `,
    selectedNavigationItem: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #EEEEEE;
        transition:all 0.3s ease-in-out;
        padding: 10px 10px;
    `,
    divider: css`
        height: 0px;
        position: relative;
        top:-1.5px;
        border: 0.5px solid rgba(255, 255, 255, 0.24);
        margin:0px 10px;
    `,
    dummyAssetsContainer: css`
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        background:#464646;
        color:#fff;
    `,
    dummyAssetHeaderContainer:css`
        display:flex;
        justify-content:space-between;
        padding-bottom:4px;
        cursor:pointer;
        padding:16px 17px 0px 17px;
        `,
    dummyAssetHeader:css`
        color: #EEE;
        text-align: center;
        font-family: Objectivity;
        font-size: 14px;
        `,
    dummyAssetSubHeader:css`
        color: #EEE;
        text-align: center;
        font-family: Objectivity;
        font-size: 12px;
        display:flex;
        justify-content:flex-start;
        padding-bottom:16px;
        padding:0px 17px 16px 17px;
         `,
    dummyAssets: css`
        display:flex;
        flex-wrap:wrap;
        justify-content:flex-start;
        width:100%;
        column-gap:19px;
        padding:0px 17px 16px 17px;
        row-gap:19px;
        `
}