const  firebaseConfig = {
  apiKey: "AIzaSyA0aDScZwk_OCqG0w_XhY4UN272MSlBNLc",
  authDomain: "domyshoot-android.firebaseapp.com",
  databaseURL: "https://domyshoot-android.firebaseio.com",
  projectId: "domyshoot-android",
  storageBucket: "domyshoot-android.appspot.com",
  messagingSenderId: "54498761365",
  appId: "1:54498761365:web:c308d54cc18d2d73c4599c",
  measurementId: "G-1D86ZJ86HT"
};






export {
  firebaseConfig
}
