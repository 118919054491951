// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        sizeSmall: {
          color: "rgba(38, 38, 38, 0.7)",
          padding: "4px 8px",
          border: "none",
          height: 28,
          fontSize: "12px",
          "&:hover": {
            border: "none",
          },
        },
        // contained
        containedInherit: {
          color: theme.palette.common.white,
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          color: theme.palette.common.white,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedSecondary: {
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.common.black}`,
          "&:hover": {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
