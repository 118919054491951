import IconButton, { IconButtonSizes, IconButtonTypes } from "src/modules/ui-elements/IconButton"
import { ICONS, Icon } from "src/modules/ui-elements/Icon"
import React, { useCallback } from "react";
import { css } from "@emotion/react";

interface IInfoButtonProps {
    title: string;
    text: string;
}

export default function InfoButton(props:IInfoButtonProps) {
    const [isInfoPaneOpen, setIsInfoPaneOpen] = React.useState(false);
    const onInfoButtonClick = useCallback(()=>{
        setIsInfoPaneOpen(x=>!x);
    },[setIsInfoPaneOpen])

    return (
        <div style={{position:'relative', zIndex:1001}}>
            <IconButton icon={ICONS.info} size={IconButtonSizes.xs} type={IconButtonTypes.dark} tooltipText={""} onClick={onInfoButtonClick} onMouseEnter={onInfoButtonClick} onMouseLeave={onInfoButtonClick}/>
            {isInfoPaneOpen && <div style={{position:'absolute', backgroundColor:'white', left:"calc(100% + 3px)", top:0, width:320, minHeight:108, borderRadius:2, padding:"16px 10px 24px 10px"}}>
                <div css={styles.title}>{props.title}</div>
                <div css={styles.text}>{props.text}</div>
                <div css={styles.close}>
                    <Icon src="/images/maki_cross.png" width={10} height={10} onClick={onInfoButtonClick} />
                </div>
            </div>}
       </div>
    );
}

const styles = {
    title: css`
        font-family: 'Objectivity-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        /* identical to box height, or 26px */
        display: flex;
        align-items: center;
        color: #262626;
        margin-bottom:8px;
    `,
    text: css`    
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
    `,
    close: css`
        position: absolute;
        width: 16px;
        height: 16px;
        right: 10px;
        top: 10px;
        background: rgba(38, 38, 38, 0.03);
        border-radius: 4px;
        display:flex;
        justify-content:center;
        align-items:center;
    `,
}