

export const EventTrackName={
_DMS_SIGN_UP:"_DMS_SIGN_UP",
_DMS_LOGIN:"_DMS_LOGIN",

 _click_credits_header:"_click_credits_header",
 _click_upgarde_plan_header:"_click_upgarde_plan_header",
_click_notf_icon_header:"_click_notf_icon_header",
_click_get_help_icon_header:"_click_get_help_icon_header",
 _click_profile_icon_header:"_click_profile_icon_header",

_click_share_invite_link_menu:"_click_share_invite_link_menu",
 _click_create_post_menu:"_click_create_post_menu",
 _click_home_menu:"_click_home_menu",
 _click_explore_templates_menu:"_click_explore_templates_menu",
 _click_my_work_menu:"_click_my_work_menu",
_click_scan_qr_code_menu:"_click_scan_qr_code_menu",
 _click_account_setting_menu:"_click_account_setting_menu",
 _click_learn_discover:"_click_learn_discover",

 _click_gplaystore_hp:"_click_gplaystore_hp",
 _click_appstore_hp:"_click_appstore_hp",
 _click_scan_QR_hp:"_click_scan_QR_hp",
 _click_start_shoot_hp:"_click_start_shoot_hp",
 _click_view_more_rec_hp:"_click_view_more_rec_hp",
 _click_go_to_editor_rec_hp:"_click_go_to_editor_rec_hp",
 _click_view_all_explore_hp:"_click_view_all_explore_hp",
 _click_slide_left_explore_hp:"_click_slide_left_explore_hp",
 _click_slide_right_explore_hp:"_click_slide_right_explore_hp",
 _click_template_explore_hp:"_click_template_explore_hp",
 _click_close_details_explore_hp:"_click_close_details_explore_hp",
 _click_go_editor_details_explore_hp:"_click_go_editor_details_explore_hp",
 _click_view_all_recent_proj_hp:"_click_view_all_recent_proj_hp",

 _click_filter_explore:"_click_filter_explore",
 _select_filters_checkboxes_explore:"_select_filters_checkboxes_explore",
 _click_done_temp_filter_explore:"_click_done_temp_filter_explore",
 _click_cross_icon_filter_explore:"_click_cross_icon_filter_explore",
 _click_clear_all_filter_explore:"_click_clear_all_filter_explore",
 _click_template_explore:"_click_template_explore",
 _click_gotoeditor_temp_detail_explore:"_click_gotoeditor_temp_detail_explore",
 _click_like_icon_on_temp_explore:"_click_like_icon_on_temp_explore",
 _click_your_fav_explore:"_click_your_fav_explore",
 _click_all_temp_explore:"_click_all_temp_explore",
 _click_seach_temp_explore:"_click_seach_temp_explore",

_click_search_editor:"_click_search_editor",
_click_filter_editor:"_click_filter_editor",
_click_all_temp_editor:"_click_all_temp_editor",
_click_your_fav_temp_editor:"_click_your_fav_temp_editor",
_click_recent_used_explore:"_click_recent_used_explore",
_click_select_proj_dropdown_editor:"_click_select_proj_dropdown_editor",
_click_add_backdrop_editor:"_click_add_backdrop_editor",
_click_create_new_proj_editor:"_click_create_new_proj_editor",
_click_chosen_temp_editor:"_click_chosen_temp_editor",
_click_send_me_link_editor:"_click_send_me_link_editor",
_click_size_editor:"_click_size_editor",
_click_save_size_editor:"_click_save_size_editor",
_click_cancel_size_editor:"_click_cancel_size_editor",
_click_width_editor:"_click_width_editor",
_click_height_editor:"_click_height_editor",
_click_save_height_width_editor:"_click_save_height_width_editor",
_click_cancel_height_width_editor:"_click_cancel_height_width_editor",
_click_crop_editor:"_click_crop_editor",
_click_save_crop_editor:"_click_save_crop_editor",
_click_cancel_crop_editor:"_click_cancel_crop_editor",
_click_image_of_project_editor:"_click_image_of_project_editor",
_click_apply_editor:"_click_apply_editor",
_click_confirm_order_editor:"_click_confirm_order_editor",
_click_save_for_later_editor:"_click_save_for_later_editor",
_click_view_deliver_mail: "_click_view_deliver_mail",

_click_search_proj_myproj:"_click_search_proj_myproj",
 _click_prod_category__myproj:"_click_prod_category__myproj",
 _click_date__myproj:"_click_date__myproj",
 _click_status__myproj:"_click_status__myproj",
 _click_sort_by__myproj:"_click_sort_by__myproj",
 _click_track__myproj:"_click_track__myproj",
 _click_ques_mark_rej_shoot_myproj:"_click_ques_mark_rej_shoot_myproj",
 _click_delete_proj_rej_shoot_myproj:"_click_delete_proj_rej_shoot_myproj",
 _click_view_details_myproj:"_click_view_details_myproj",
 _select_proj_checkbox_myproj:"_select_proj_checkbox_myproj",
 _click_share_myproj:"_click_share_myproj",
 _click_download_myproj:"_click_download_myproj",
 _click_get_link_myproj:"_click_get_link_myproj",
 _click_select_max_myproj:"_click_select_max_myproj",
 _click_three_dots_myproj:"_click_three_dots_myproj",
 _click_add_backdrop_myproj:"_click_add_backdrop_myproj",
 _click_adaptation_myproj:"_click_adaptation_myproj",
 _click_by_user_multi_myproj:"_click_by_user_multi_myproj",
 _select_user_name_multi_myproj:"_select_user_name_multi_myproj",
_click_feedback_icon_detail_myproj:"_click_feedback_icon_detail_myproj",
_click_confm_order_detail_myproj:"_click_confm_order_detail_myproj",
_click_color_correct_mypro:"_click_color_correct_mypro",

 _click_marketplace_int_myproj:"_click_marketplace_int_myproj",
 _click_to_marketplace_acc_myproj:"_click_to_marketplace_acc_myproj",
 _select_linked_acc_mrkt_intg_myproj:"_select_linked_acc_mrkt_intg_myproj",
 _select_product_mrkt_intg_myproj:"_select_product_mrkt_intg_myproj",
_click_update_img_mrkt_intg_myproj:"_click_update_img_mrkt_intg_myproj",
_click_connect_acc_mrkt_intg_myproj:"_click_connect_acc_mrkt_intg_myproj",
_click_plus_mrkt_intg_myproj:"_click_plus_mrkt_intg_myproj",
_click_connect_mrkt_acc_myproj:"_click_connect_mrkt_acc_myproj",
_click_create_proj_mrkt_intg_myproj:"_click_create_proj_mrkt_intg_myproj",
_click_create_mrkt_intg_myproj:"_click_create_mrkt_intg_myproj",
_click_status_mrkt_intg_myproj:"_click_status_mrkt_intg_myproj",
_select_guideline_adap_myproj:"_select_guideline_adap_myproj",
_click_next_adap_myproj:"_click_next_adap_myproj",
//  _click_confirm_adap_myproj:"_click_confirm_adap_myproj",
_click_confirm_order:"_click_confirm_order",

 _click_setting_dropdwn_profile:"_click_setting_dropdwn_profile",
 _click_profile_dropdwn_profile:"_click_profile_dropdwn_profile",
_click_help_dropdwn_profile:"_click_help_dropdwn_profile",
 _click_log_out_dropdwn_profile:"_click_log_out_dropdwn_profile",
 _click_edit_username_profile:"_click_edit_username_profile",
 _click_cancel_username_profile:"_click_cancel_username_profile",
 _click_save_username_profile:"_click_save_username_profile",
 _click_email_update_profile:"_click_email_update_profile",
 _click_save_email_profile:"_click_save_email_profile",
 _click_cancel_email_profile:"_click_cancel_email_profile",
 _click_number_update_profile:"_click_number_update_profile",
 _click_save_mobile_profile:"_click_save_mobile_profile",
 _click_cancel_mobile_profile:"_click_cancel_mobile_profile",
 _click_upload_photo_profile:"_click_upload_photo_profile",
_click_acc_setting_profile:"_click_acc_setting_profile",
_click_add_mem_acc_set:"_click_add_mem_acc_set",
_click_prod_catg_acc_set:"_click_prod_catg_acc_set",
_select_icon_prod_catg_acc_set:"_select_icon_prod_catg_acc_set",
_click_done_prod_catg_acc_set:"_click_done_prod_catg_acc_set",
_click_default_presets_acc_set:"_click_default_presets_acc_set",
_select_icon_default_presets_acc_set:"_select_icon_default_presets_acc_set",
_click_done_default_presets_acc_set:"_click_done_default_presets_acc_set",
_click_activate_offers_acc_set:"_click_activate_offers_acc_set",
_click_enter_code_offer_acc_set:"_click_enter_code_offer_acc_set",
_click_activate_offer_cta_acc_set:"_click_activate_offer_cta_acc_set",
_click_membership_profile:"_click_membership_profile",
_click_monthly_membership:"_click_monthly_membership",
_click_yearly_membership:"_click_yearly_membership",
_click_upgrade_solo_m:"_click_upgrade_solo_m",
_click_upgrade_to_pro_y:"_click_upgrade_to_pro_y",
_click_proceed_to_pay_membership:"_click_proceed_to_pay_membership",
_click_order_history:"_click_order_history",
_click_search_order_history:"_click_search_order_history",
_click_payment_history:"_click_payment_history",
_click_download_payment_history:"_click_download_payment_history",
_click_connect_marketplace_acc:"_click_connect_marketplace_acc",
_click_disconnect_marketplace_acc:"_click_disconnect_marketplace_acc",
_click_history_marketplace_profile:"_click_history_marketplace_profile",
_click_upgrade_your_plan_ppu:"_click_upgrade_your_plan_ppu",
_proceed_to_pay_ppu:"_proceed_to_pay_ppu",
_success_payment_ppu:"_success_payment_ppu",
_failure_payment_ppu:"_failure_payment_ppu",
_click_paymentdue_myproj:"_click_paymentdue_myproj",


 _Language_Change_login:"_Language_Change_login",
 _language_selection_login:"_language_selection_login",
 _Click_cta_send_otp_emailID_login:"_Click_cta_send_otp_emailID_login",
 _Click_done_after_email_otp_login:"_Click_done_after_email_otp_login",
 _Click_Resend_Code_email_login:"_Click_Resend_Code_email_login",
 _Click_login_with_phone_login:"_Click_login_with_phone_login",
 _Click_cta_send_otp_mobile_login:"_Click_cta_send_otp_mobile_login",
 _Click_country_code_login:"_Click_country_code_login",
 _Click_done_after_mobile_otp_login:"_Click_done_after_mobile_otp_login",
 _Click_Resend_Code_mobile_login:"_Click_Resend_Code_mobile_login",
 _Click_continue_with_google_login:"_Click_continue_with_google_login",
 _Click_cta_cont_with_google_login:"_Click_cta_cont_with_google_login",
 _Click_continue_with_apple_login:"_Click_continue_with_apple_login",
 _Click_cta_cont_with_apple_login:"_Click_cta_cont_with_apple_login",
_Click_continue_with_facebook_login:"_Click_continue_with_facebook_login",
_Click_cta_cont_with_fb_login:"_Click_cta_cont_with_fb_login",


_View_Studio_Homepage:"_View_Studio_Homepage",
_import_file_studio_hp:"_import_file_studio_hp",
_select_project_studio_hp:"_select_project_studio_hp",
_click_login_hp:"_click_login_hp",
_DMS_SIGN_UP:"_DMS_SIGN_UP",
_DMS_LOGIN:"_DMS_LOGIN",
_export_studio:"_export_studio",
_remove_watermark:"_remove_watermark",
_click_download:"_click_download",
_click_start_free_trial:"_click_start_free_trial",
_create_free_account:"_create_free_account",
_send_pro_touch:"_send_pro_touch",
_click_bg_studio_hp:"_click_bg_studio_hp",
_click_color_adjust:"_click_color_adjust",
_generate_custom_prop:"_generate_custom_prop",
_generate_bg_variations:"_generate_bg_variations",
_click_texture_color:"_click_texture_color",
_apply_bg_variations:"_apply_bg_variations",
_apply_texture_color:"_apply_texture_color",
_click_batch_editor_cta:"_click_batch_editor_cta",
_click_upload_image_batch_edit:"_click_upload_image_batch_edit",
_click_download_batch_edit:"_click_download_batch_edit",
_click_assets_studio_hp:"_click_assets_studio_hp",
_click_upload_assets_studio:"_click_upload_assets_studio",
_click_upload_file_upload_assets_studio:"_click_upload_file_upload_assets_studio",
_click_projects_studio_hp:"_click_projects_studio_hp",
_click_new_proj_projects_studio:"_click_new_proj_projects_studio",
_click_text_studio_hp:"_click_text_studio_hp",
_click_elements_studio_hp:"_click_elements_studio_hp",


_Click_Feedback: "_Click_Feedback",
_Click_Thumbs_Up_Fbk: "_Click_Thumbs_Up_Fbk",
_Click_Thumbs_Down_Fbk: "_Click_Thumbs_Down_Fbk",

// Homepage Events
_load_studio_hp: "_load_studio_hp",
_click_my_projects_hp: "_click_my_projects_hp",
_click_show_more_projects_hp: "_click_show_more_projects_hp",
_click_featured_template_hp: "_click_featured_template_hp",
_click_view_more_featured_template_hp: "_click_view_more_featured_template_hp",
_click_marketplace_hp: "_click_marketplace_hp",
_select_tool_hp: "_select_tool_hp",
_upload_image_tool_hp: "_upload_image_tool_hp",
_select_product_tool_hp: "_select_product_tool_hp",
_send_pro_touch_batch_edit: "_send_pro_touch_batch_edit",
_click_social_media_hp: "_click_social_media_hp",
_upload_website : "_upload_website",
_click_theme_template_hp : "_click_theme_template_hp",
_load_batch_editor : "_load_batch editor",
_Click_Upload_Files: "_Click_Upload_Files",
_Click_Upload_Folders: "_Click_Upload_Folders",
_Click_Upload_Existing_Project: "_Click_Upload_Existing_Project",
_Click_Next_Upload_Existing_Project: "_Click_Next_Upload_Existing_Project",
_Click_Upload_GD: "_Click_Upload_GD",
_Click_Upload_CSV: "_Click_Upload_CSV",
_Click_Upload_create_free_account: "_Click_Upload_create_free_account",
_Click_Upload_sample_product: "_Click_Upload_sample_product",
_Click_Try_Batch_Edit: "_Click_Try_Batch_Edit",
_Click_Add_Canvas: "_Click_Add_Canvas",
_Click_create_free_account_success_popup: "_Click_create_free_account_success_popup",
_Click_go_home_sucess_popup: "_Click_go_home_sucess_popup",




// AMAZON LISTING EVENTS
_get_started_listing_hp: "_get_started_listing_hp",
_click_download_csv_list: "_click_download_csv_list",
_view_listing_score_list: "_view_listing_score_list",
_click_action_listing_score_list: "_click_action_listing_score_list",
_delete_angle_list: "_delete_angle_list",
_create_lifestyle_preview_list: "_create_lifestyle_preview_list",
_customize_ls_preview_list: "_customize_ls_preview_list",
_enhance_listing_cta_list: "_enhance_listing_cta_list",
_create_a_plus_cta_list: "_create_a_plus_cta_list",
_submit_package_list: "_submit_package_list",
_submit_instructions_list: "_submit_instructions_list",
_save_instructions_list: "_save_instructions_list",
_click_instruction_icon_list: "_click_instruction_icon_list",
_click_add_angle_list: "_click_add_angle_list",
_add_to_list_bg_generation_list: "_add_to_list_bg_generation_list",
_click_home_after_download_success: "_click_home_after_download_success",
_click_create_another_listing: "_click_create_another_listing",
_click_upload_myself: "_click_upload_myself",
_click_need_help: "_click_need_help",
_click_send_retouch: "_click_send_retouch",
_click_prod_info: "_click_prod_info",
_regenerate_details_list: "_regenerate_details_list",
_click_upload_image_list: "_click_upload_image_list",
_click_existing_project_list: "_click_existing_project_list",
click_generate_BG_variations_list: "click_generate_BG_variations_list"

//batch Editor Event

}