import axios from "@/utils/axios";
import axios2 from "axios";
import useToast from "@/hooks/useToast";

const GREYHOUND_URL = process.env.NEXT_PUBLIC_GREYHOUND_URL;

let uploadAbortController = new AbortController();
export async function uploadToS3(base64:string) {
    uploadAbortController.abort();
    uploadAbortController = new AbortController();
    try {
        let res = await axios.post("/v3/images/upload-imagetoS3-ai-background",{
            imageData: base64
        },{
            signal: uploadAbortController.signal
        });
        return res.data?.data;
    } catch (error) {
        return error;
    }
}

let uploadImageToS3AbortController = new AbortController();
export async function uploadImageToS3(base64:string) {
    uploadImageToS3AbortController.abort();
    uploadImageToS3AbortController = new AbortController();
    try {
        let res = await axios.post("/v3/images/upload-imagetoS3-ai-background",{
            imageData: base64
        },{
            signal: uploadImageToS3AbortController.signal
        });
        return res.data?.data;
    } catch (error) {
        return error;
    }
}

let generateBackgroundsAbortController = new AbortController();
export async function generateBackgrounds(url, prompt, entity_id) {
    generateBackgroundsAbortController.abort();
    generateBackgroundsAbortController = new AbortController();
    try {
        let res = await axios2.post(`${GREYHOUND_URL}/ai_generation`,{
            "url": url,
            "prompt": prompt,
            "batch_size": 4,
            "background_image": "",
            entity_id
        },{
            signal: generateBackgroundsAbortController.signal
        });
        return res.data?.data;
    } catch (error) {
        return error;
        console.log(error)
    }
}

export async function generatePrompt(url, entity_id) {
    try {
        let res = await axios2.post(`${GREYHOUND_URL}/ai_generation`,{
            "url": url,
            "prompt": "",
            "batch_size": 4,
            "background_image": "",
            entity_id
        });
        return res.data?.prompt;
    } catch (error) {
        return error;
        console.log(error)
    }
}

export interface AttributesList {
    fieldName: string,
    values: Array<string>,
    value: string
}

let generateAttributesAndPromptAbortController = new AbortController();
export async function generateAttributesAndPrompt(url:string, entity_id:string) {
    let attributes:AttributesList[] = []
    generateAttributesAndPromptAbortController.abort();
    generateAttributesAndPromptAbortController = new AbortController();
    try {
        let res = await axios2.post(`${GREYHOUND_URL}/ai_generation`,{
            "url": url,
            "prompt": "",
            "batch_size": 4,
            "background_image": "",
            entity_id
        },{
            signal: generateAttributesAndPromptAbortController.signal
        });
        const Formats = [
            "Name of the product",
            "Setting",
            "Textures and Materials",
            "Lighting",
            "Color Palette",
            "Mood",
            "Scale and Proportion",
            "Additional Elements"
        ];
        Formats.forEach((item:string)=>{
            attributes.push({
                fieldName: item,
                values: res?.data?.Attributes[item],
                value: res?.data?.Attributes[item]?.[0]
            })
        })
        return {
            prompt: res.data?.prompt, 
            formats: res.data?.Formats,
            attributesList: attributes
        };
    } catch (error) {
        return error;
        console.log(error)
    }
}

let backgroundsAbortController = new AbortController();
export async function generateBackgroundsVariation({url,prompt,entity_id, background_image, batch_size}) {
    backgroundsAbortController.abort();
    backgroundsAbortController = new AbortController();
    let res = await axios2.post(`${GREYHOUND_URL}/ai_generation`,{
        "url":url,
        "prompt":prompt,
        "batch_size":batch_size,
        "background_image": background_image,
        entity_id,
    },{
        signal: backgroundsAbortController.signal
    });

    return {
        images: res.data?.data,
        prompt: res.data?.prompt
    };
}

let abortController = new AbortController();
export async function createNewTemplate(url:string, baseTemplateId:string) {
    abortController?.abort();
    abortController = new AbortController();
    const res = await axios.post("v3/exploreTemplates/insertSelectedBackgroundVariation",{
        lifestyleImageId: baseTemplateId,
        selectedVariation: url,
        templateType:"DALLE",
        status:"UPDATE"
    },{
        signal: abortController.signal
    });

    return res.data.data;
}