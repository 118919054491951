import { css } from "@emotion/react";
import TemplateItem from "./TemplateItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import {activePageAtom, infographicsLayerProgressAtom, pageStatesAtom, toAddStickerAtom} from "../../Atoms";
import {useAtom, useAtomValue} from "jotai";
import useEditorService from "@/hooks/useEditorService";
import { generateBackgroundsVariation, uploadToS3, uploadImageToS3 } from "../../services/AIBackgrounds";
import DMSCanvas from "local_modules/editor/src/DMSCanvas";
import Loader from  '../AIBackgrounds/loader.svg';
import myStyles from  "../AIBackgrounds/AIBackgroundResults.module.css" 
import RightIcon from './rightIcon.svg';
import { useSelector } from "react-redux";
import useToast from "@/hooks/useToast";

const SOLID_FILL_TEMPLATE = process?.env?.NEXT_PUBLIC_SOLID_FILL_TEMPLATE;
const WHITE_TEMPLATE = process?.env?.NEXT_PUBLIC_WHITE_TEMPLATE;

const doNotDeAttachTemplateIds = [WHITE_TEMPLATE, SOLID_FILL_TEMPLATE];

export default function TemplatesGrid({templates,aspectRatio,productImageUrl, productImageLayerId, setIsVisualPrompt, setVisualPromptData}) {
    const infographicsLayerProgress = useAtomValue(infographicsLayerProgressAtom);

    const [layers, setLayers] = useState([]);

    const {activeEditor} = useEditorService();
    const toast = useToast();

    const productImage = layers.find(x=>{
        // console.log(x?.$tags?.value, x?.$tags?.value.includes?.('product-image'));
        return true;
    });
    
    const pageStates = useAtomValue(pageStatesAtom);
    const activePage = useAtomValue(activePageAtom);
    const backgroundImage = useMemo(()=>pageStates[activePage]?.selectedTemplate,[pageStates, activePage]);
    const [showVariations, setShowVariations] = useState(false);
    const [variations, setVariations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [productUrl, setProductUrl] = useState("");
    const strapiText = useSelector((state)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const stickerValue = useAtomValue(toAddStickerAtom);

    useEffect(()=>{
        setShowVariations(
            productImage && 
            backgroundImage && 
            !doNotDeAttachTemplateIds.includes(backgroundImage)
        );
    },[productImage, backgroundImage]);

    
    const createImage = async () => {
        let serializedEditor = await activeEditor.save();
        const layer = serializedEditor.layers.find(layer=>layer.id===productImage.id);
        if(!layer) return toast.error("Cannot find Product Image!");
        const editor = new DMSCanvas("canvas2ag",activeEditor.dimensions,activeEditor.originalSize);
        await new Promise((res)=>editor.canvas.setBackgroundColor("rgba(0,0,0,0)",res));
        const layerRef = await editor.addLayerFromSerializedData(layer);
        await layerRef.setPropertiesFromSerializedData(layer);
        const aspectRatio = activeEditor.originalSize.height / activeEditor.originalSize.width;
        if(aspectRatio > 1) await editor.resize({height: 1024, width: 1024 / aspectRatio});
        else await editor.resize({height: 1024 * aspectRatio, width: 1024});
        editor.canvas.renderAll();
        const base64 = editor.toBase64Url();
        let url = await uploadImageToS3(base64);
        return url;
    }

    const createBackground = async () => {
        let serializedEditor = await activeEditor.save();
        const layer = serializedEditor.layers.find(layer=>layer.id===productImage.id);
        if(!layer) return toast.error("Cannot find Product Image!");
        const editor = new DMSCanvas("canvas3ag",activeEditor.dimensions,activeEditor.originalSize);
        await new Promise((res)=>editor.canvas.setBackgroundColor("rgba(0,0,0,0)",res));
        const layerRef = await editor.addLayerFromSerializedData(layer);
        await layerRef.setPropertiesFromSerializedData(layer);
        const aspectRatio = activeEditor.originalSize.height / activeEditor.originalSize.width;
        if(aspectRatio > 1) await editor.resize({height: 1024, width: 1024 / aspectRatio});
        else await editor.resize({height: 1024 * aspectRatio, width: 1024});
        editor.canvas.renderAll();
        await editor.setBackgroundFromURL(serializedEditor.backgroundInfo.src)
        const base64 = editor.toBase64Url();
        let url = await uploadToS3(base64);
        return url;
    }


    useEffect(()=>{
        if(showVariations && activeEditor) {
            setLoading(true);
            Promise.all([createImage(), createBackground()])
            .then(async ([url, background]) => {
                setBackgroundUrl(background);
                setProductUrl(url);
                setVariations([]);
                const data = await generateBackgroundsVariation({
                    url: url, // Transparent Canvas + Product 
                    background_image: background, // Background Image + Product
                    batch_size: 2, 
                    entity_id: "",
                    prompt: ""
                });
                setPrompt(data.prompt);
                setVariations(data.images?.map(x => ({
                    url: x.url,
                    loading: false
                })));
                setLoading(false);
            });
        
            
        } else {
            setVariations([]);
        }
    },[showVariations, activeEditor, backgroundImage])

    useEffect(()=>{
        if(!activeEditor) return;
        if(!activeEditor.layerManager._layers.value) return;
        const subscription = activeEditor.layerManager.layers.subscribe((layers)=>{
            setLayers(layers)
        });

        return () => {
            if(subscription?.unsubscribe?.apply) subscription.unsubscribe();
        }
    },[activeEditor]);

    const templateIndex = templates?.findIndex(template=>template.value._id === backgroundImage);
    const indexToShowAt = (((Math.floor((templateIndex) / 3)) + 1 ) * 3)-1;

    return <div css={css`&::-webkit-scrollbar {
        display: none;
      };`} style={{...styles.container(infographicsLayerProgress)}} > 
      {templates.map((template,index)=>(<>
                <TemplateItem backgroundId={backgroundImage} aspectRatio={aspectRatio} editorJSONObject={template?.value} isPaid={!!template?.value?.template_cost} isFavorite={!!template?.value?.isFavourite} src={template.value.editorThumbnail} _id={template.value._id} boundingBox={template?.value?.bounding_box} productImageUrl={productImageUrl} productImageLayerId={productImageLayerId} index={index}/>
            {index===indexToShowAt && showVariations && !stickerValue &&
            <div style={{width:340, background: "linear-gradient(90.79deg, #5F2EE5 0%, #9F35F0 100%)", marginLeft:4, display:'flex', flexDirection:'row', padding:8, gap:8, cursor: loading ? "not-allowed" : "pointer"}} onClick={()=>{
                    if(loading) return;
                    setIsVisualPrompt(true);
                    setVisualPromptData({
                        baseUrls: variations?.map(x=>({
                            loading: false,
                            url: x.url
                        })),
                        backgroundImage: backgroundUrl,
                        s3Url: productUrl,
                        prompt: prompt, 
                        setGenerating: setLoading,
                        setUrls: setVariations,
                        setPrompt:setPrompt,
                        generating: loading,
                    })
                }}>
                <div style={{flex:0.5, display:'flex', gap:4}}>
                    <div style={{height:80, width:80, background: "#FFFFFF80", borderRadius:4, display:'flex', justifyContent:"center", alignItems:"center"}}>
                        {loading &&  <div className={myStyles.loader}>
                            <Loader />
                        </div>}
                        {!loading && variations?.[0] && <img src={variations?.[0]?.url} />}
                    </div>
                    <div style={{height:80, width:80, background: "#FFFFFF80", borderRadius:4, display:'flex', justifyContent:"center", alignItems:"center"}}>
                        {loading &&  <div className={myStyles.loader}>
                            <Loader />
                        </div>}
                        {!loading && variations?.[1] && <img src={variations?.[1]?.url} />}
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'column', flex:0.5, justifyContent:'space-between', paddingTop:10, paddingBottom:10, maxHeight:'340px'}} >
                    <div
                        css={css`
                            font-family: Objectivity;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0.015em;
                            text-align: left;
                            color:#FFF;
                        `}
                    >{strapiText?.aiGenerationVariation || "AI Generation Variation"}</div>

                    <div css={css`
                        font-family: Objectivity;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.015em;
                        text-align: left;
                        color: lightgray;
                        display:flex;
                        align-items:center;
                        gap: 5px;
                    `}
                    >{strapiText?.viewMore ||"View More "}<RightIcon/></div>
                </div>
            </div>}
            </>))}
        <div style={{overflow:'hidden',opacity:0,pointerEvents:'none',position:'absolute',top:'-30vh',left:0}}>
            <canvas id="canvas2ag"/>
        </div>
        <div style={{overflow:'hidden',opacity:0,pointerEvents:'none',position:'absolute',top:'-30vh',left:0}}>
            <canvas id="canvas3ag"/>
        </div>
    </div>
}

const styles = {
    container: (infographicsLayerProgress) => ({
        display:"flex",
        justifyContent: "flex-start",
        overflow:"auto",
        flexWrap:"wrap",
        height: "100%",
        marginLeft:"0px",
        marginRight:"0px",
        pointerEvents: `${infographicsLayerProgress ? "none" : "auto"}`,
        opacity: `${infographicsLayerProgress ? "0.5":1}`,
    })
}