// import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
import axiosv1 from './axiosv1';
import { nanoid } from 'nanoid';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  return true;
  // const decoded = jwtDecode(accessToken);
  // const currentTime = Date.now() / 1000;
  // return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = async (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosv1.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    sessionStorage.removeItem('authFailed');
    sessionStorage.removeItem('query_info');
    localStorage.removeItem('userId');
    if(window.Moengage)
    window.Moengage?.destroy_session();
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    sessionStorage.setItem('create_Guest',true);
    localStorage.clear();
    localStorage.setItem('visited', true);
    let data = await createGuest();
    sessionStorage.removeItem('create_Guest');
    setSession(data?.data?.token);    
  }
};

const createSession = async (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosv1.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.setItem('create_Guest',true);
    let data = await createGuest();
    sessionStorage.removeItem('create_Guest');
    setSession(data?.data?.token);    
  }
};

const setOtpData = (data) => {
  if (data) {
    localStorage.setItem('otpData', JSON.stringify(data));
  } else {
    localStorage.removeItem('otpData');
  }
}

export const createGuest = async () => {
  let userUniqueId = localStorage.getItem('userUniqueId');
  if(!userUniqueId) {
    userUniqueId = nanoid();
    localStorage.setItem('userUniqueId', userUniqueId);    
  } 

  const response = await axios.post("/v4/users/user/signInGuestUser", {
    deviceId:userUniqueId,
    mode: "web",
    language:window.localStorage.getItem("Selected_Language") || "en",
  });

  return response?.data
}

export { isValidToken, setOtpData, setSession, verify, sign, createSession };
