import { Canvas } from 'fabric/fabric-impl';
import { Observable, Subject, Subscription, count, debounceTime, skip, throttleTime } from 'rxjs';
import DMSCanvas from './DMSCanvas';
import { SerializedCanvasBackground, SerializedLayerInfo } from './Interfaces';
import Layer from './Layer';

interface BasicLayerInfo {
    id:string,
}

interface Action { 
    type: "LAYER_MODIFIED" | "LAYER_REMOVED" | "LAYER_ADDED" | "CANVAS_RESIZED" | "CANVAS_BACKGROUND_MODIFIED",
    payload: SerializedLayerInfo | SerializedCanvasBackground | BasicLayerInfo
}

export class HistoryManager {
    private readonly stackSizeLimit = 50;
    private undoStack: Action[] = [];
    private redoStack: Action[] = [];
    private canvas: Canvas;
    private dmsCanvas: DMSCanvas;
    private layerSubscriptions: Map<string, Subscription> = new Map();
    private actionSubject = new Subject<Action>();
    private currentLayers: Layer[];
    public disabled = false;
    private throttleTime = 300; 

    constructor(canvas: DMSCanvas) {
        this.canvas = canvas.canvas;
        this.dmsCanvas = canvas;
        this.addEventListeners();
        this.initializeThrottledSave();
        // this.dmsCanvas = canvas
        // this.onChangeObservable = onChangeObservable;

        // this.onChangeObservable.subscribe(() => {
        //     this.saveState();
        // });
    }

    private addEventListeners() {
       this.addLayersEventListeners();
       this.addLayerManagerEventListeners();
       this.addCanvasEventListeners();
    }

    private addLayersEventListeners() {
        this.dmsCanvas.layerManager.layers.subscribe(layers=>{
            this.currentLayers = layers;
            this.unsubscribeFromLayers();
            layers.forEach(layer => {
                const subscription = layer.on("BEFORE_MODIFY",(data)=>{
                    const action: Action = {
                        type: "LAYER_MODIFIED",
                        payload: data
                    };
                    this.saveState(action);
                });
                this.layerSubscriptions.set(layer.id, subscription);
            });
        })
    }

    private addLayerManagerEventListeners() {
        this.dmsCanvas.layerManager.on("LAYER_ADDED",(data)=>{
            const basicLayer = (data as BasicLayerInfo);
            const action: Action = {
                type: "LAYER_ADDED",
                payload: basicLayer
            };
            this.saveState(action);
        })

        this.dmsCanvas.layerManager.on("BEFORE_LAYER_DELETE",(data)=>{
            const action: Action = {
                type: "LAYER_REMOVED",
                payload: data
            };
            this.saveState(action);
        })
    }

    private addCanvasEventListeners() {
        this.dmsCanvas.on("BEFORE_BACKGROUND_MODIFY",(data)=>{
            const action: Action = {
                type: "CANVAS_BACKGROUND_MODIFIED",
                payload: data
            }
            this.saveState(action);
        })
    }

    private initializeThrottledSave() {
        this.actionSubject.pipe(throttleTime(this.throttleTime,undefined,{leading: true, trailing:false})).subscribe(action => {
            this.saveStateImmediately(action);
        });
    }


    private unsubscribeFromLayers() {
        this.layerSubscriptions.forEach((subscription, layerId) => {
          subscription.unsubscribe();
          this.layerSubscriptions.delete(layerId);
        });
    }

    private saveStateImmediately(action: Action) {
        if(this.disabled) return;
        if(!action.payload) return;
        if (this.undoStack.length >= this.stackSizeLimit) {
            this.undoStack.shift();
        }
        this.undoStack.push(action);
        this.redoStack = [];
    }

    public saveState(action: Action) {
        this.actionSubject.next(action);
    }

    private async performAction(action: Action) {
        switch (action.type) {
            case "LAYER_MODIFIED":
                const serializedLayer = action.payload as SerializedLayerInfo;
                const layer = this.currentLayers.find(x=>x.id == serializedLayer?.id);
                if(!layer) return console.error("Couldn't find modified layer!");
                await layer.setPropertiesFromSerializedData(serializedLayer);
                break;
            case "LAYER_ADDED":
                const layerId = (action.payload as BasicLayerInfo).id;
                const addedLayer = this.currentLayers.find(x=>x.id == layerId);
                if(!addedLayer) return console.error("Couldn't find modified layer!");
                this.dmsCanvas.layerManager.delete(addedLayer);
                break;
            case "LAYER_REMOVED": 
                const _serializedLayer = action.payload as SerializedLayerInfo;
                const _layer = await this.dmsCanvas.addLayerFromSerializedData(_serializedLayer);
                await _layer.setPropertiesFromSerializedData(_serializedLayer);
                break;
            case "CANVAS_BACKGROUND_MODIFIED":
                this.dmsCanvas.applyBackgroundFromSerializedInfo(action.payload as SerializedCanvasBackground);
        }
    }

    private getCounterAction(action:Action) {
        if(!action.payload) return;
        const counterAction:Action = {
            type: action.type,
            payload: null
        }

        if(action.type === "LAYER_MODIFIED") {
            const serializedLayer = action.payload as SerializedLayerInfo;
            const layer = this.currentLayers.find(x=>x.id == serializedLayer?.id);
            if(!layer) return counterAction;
            counterAction.payload = layer.toJSON();
            return counterAction;
        }
        
        if(action.type === "LAYER_REMOVED") {
            counterAction.type = "LAYER_ADDED";
            counterAction.payload = {id: (action.payload as SerializedLayerInfo).id}
            return counterAction;
        }

        if(action.type === "LAYER_ADDED") {
            const layerId = (action.payload as BasicLayerInfo).id;
            const addedLayer = this.currentLayers.find(x=>x.id == layerId);
            if(!addedLayer) return counterAction;
            counterAction.type = "LAYER_REMOVED";
            counterAction.payload = addedLayer.toJSON();
            return counterAction;
        }

        if(action.type === "CANVAS_BACKGROUND_MODIFIED") {
            counterAction.payload = this.dmsCanvas.serializeBackgroundInfo();
            return counterAction;
        }

        return counterAction;
    }

    public async undo() {
        const lastAction = this.undoStack.pop();
        if (lastAction) {
            this.disabled = true;
            const counterAction = this.getCounterAction(lastAction);
            await this.performAction(lastAction);
            this.disabled = false;
            this.redoStack.push(counterAction);
        // if (this.undoStack.length > 0) {
        //     const currentState = this.undoStack.pop();
        //     if (currentState) {
        //         this.dmsCanvas.shouldFireModifiedEvent = false;
        //         this.redoStack.push(currentState);
        //         if (this.undoStack.length > 0) {
        //             const prevState = this.undoStack[this.undoStack.length - 1];
        //             // this.dmsCanvas.layerManager._layers.next([]);
        //             // this.canvas.clear();
        //             await this.dmsCanvas.load(JSON.parse(prevState));
        //         } 
        //         this.dmsCanvas.shouldFireModifiedEvent = true;
        //     }
        }
    }

    public async redo() {
        const nextAction = this.redoStack.pop();
        if (nextAction) {
            this.disabled = true;
            const counterAction = this.getCounterAction(nextAction);
            await this.performAction(nextAction);
            this.disabled = false;
            this.undoStack.push(counterAction);
        }
    }

    public clearHistory() {
        this.undoStack = [];
        this.redoStack = [];
    }
}
