export const materials = [
  "Marble",
  "Wooden",
  "Concrete",
  "Sand",
  "Wallpaper",
  "Bricks",
  "Cotton fabric",
  "Silk fabric",
  "Hardwood",
  "Metal",
  "Glass",
  "Leather",
  "Stone",
  "Plastic",
  "Ceramics",
  "Tile",
  "Cloth",
  "Ice",
  "Rubber",
  "Powder",
  "Textured",
  "Synthetic",
  "Fur",
  "Paper",
  "Gravel",
  "Plaster",
  "Resin",
  "Cement",
  "Acrylic",
  "Limestone",
  "Quartz",
  "Jute",
  "Nylon",
  "Bamboo",
  "Velvet",
  "Terracotta",
  "Cardboard",
  "Suede",
  "Cork",
  "Gypsum",
  "Fiberglass",
  "Wicker",
  "Limestone",
  "Thatch",
  "Granite",
  "Wrought Iron",
  "Stainless Steel",
  "Aluminium",
  "Grass",
  "Tarmac",
  "Dirt",
  "Mud",
  "Male Model",
  "Female Model",
];

export const props = [
  "Plant",
  "Carpet",
  "Wall decals",
  "Paint splash",
  "Potted plant",
  "Statue",
  "Vases",
  "Table Lamp",
  "Books",
  "Stuffed Toy",
  "Guitar",
  "Racquet",
  "Painting",
  "Mobile phone",
  "Laptop",
  "Sofa",
  "Armchair",
  "Table",
  "Ottoman",
  "Flowers",
  "Bookshelf",
  "Plates",
  "Bowl",
  "Tea Cup",
  "Mason Jars",
  "Mirror",
  "Shaving kit",
  "Soap dispenser",
  "Sunglasses",
  "Hairclip",
  "Fruit",
  "Vegetables",
  "Lightfixture",
  "Moon",
  "Sun",
  "Stars",
  "Clouds",
  "Pens",
  "Pencils",
  "Hanger",
  "Decorative",
  "Clothes Rack",
  "Flag",
  "Handbag",
  "Magazine Rack",
  "Firepit",
  "Barbeque",
  "Leaves",
  "Cutting Board",
  "Coffee",
  "Tea",
  "Home Decor",
  "Hammer",
  "Screwdriver",
  "Football",
  "Tennis Ball",
  "Heels",
  "Shoes",
  "Earrings",
  "Necklace",
  "Bracelet",
  "Magazines",
  "Throw",
  "Cushion",
  "Umbrella",
  "Wicker Basket",
  "Stones",
];

export const settings = [
  "floor",
  "table",
  "counter",
  "wall",
  "ceiling",
  "neck",
  "wrist",
  "ear",
  "hand",
  "foot",
];

export const environments = [
  "Kitchen",
  "Living Room",
  "Dining Room",
  "Bedroom",
  "Garden",
  "Beach",
  "Office",
  "Bathroom",
  "Cafe",
  "Restaurant",
  "Hospital",
  "Clinic",
  "Forest",
  "Desert",
  "Mountains",
  "Park",
  "Meadow",
  "Urban Cityscape",
  "Countryside",
  "Library",
  "Classroom",
  "Art Gallery",
  "Theatre",
  "Factory",
  "Underwater",
  "Construction Site",
  "Gym",
  "Salon",
  "Club",
  "Street",
  "Warehouse",
  "Shop",
];

export const promptStyles = [
  "Modern",
  "Antique",
  "Professional",
  "Cozy",
  "Rustic",
  "Fantasy",
  "Industrial",
  "Bohemian",
  "Art Deco",
  "Minimalist",
];

export const themesData = [
  {
      "name": "Kitchen",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Kitchen.jpg"
  },
  {
      "name": "Living",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Living.jpg"
  },
  {
      "name": "Dining",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Dining.jpg"
  },
  {
      "name": "Bedroom",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Bedroom.jpg"
  },
  {
      "name": "Garden",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Garden.jpg"
  },
  {
      "name": "Beach",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Beach.jpg"
  },
  {
      "name": "Outdoor",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Outdoor.jpg"
  },
  {
      "name": "Indoor",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Indoor.jpg"
  },
  {
      "name": "Office",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Office.jpg"
  },
  {
      "name": "Bathroom",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Bathroom.jpg"
  },
  {
      "name": "Forest",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Forest.jpg"
  },
  {
      "name": "Desert",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Desert.jpg"
  },
  {
      "name": "Mountains",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Mountains.jpg"
  },
  {
      "name": "Park",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Park.jpg"
  },
  {
      "name": "Meadow",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Meadow.jpg"
  },
  {
      "name": "Urban Cityscape",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Urban Cityscape.jpg"
  },
  {
      "name": "Countryside",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Countryside.jpg"
  },
  {
      "name": "Library",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Library.jpg"
  },
  {
      "name": "Classroom",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Classroom.jpg"
  },
  {
      "name": "Art Gallery",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Art Gallery.jpg"
  },
  {
      "name": "Theatre",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Theatre.jpg"
  },
  {
      "name": "Tropical",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Tropical.jpg"
  },
  {
      "name": "Underwater",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Underwater.jpg"
  },
  {
      "name": "Suburban",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Suburban.jpg"
  },
  {
      "name": "Parks",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Parks.jpg"
  },
  {
      "name": "Bridge",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Bridge.jpg"
  },
  {
      "name": "Subway",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Subway.jpg"
  },
  {
      "name": "Rooftop",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Rooftop.jpg"
  },
  {
      "name": "Museum",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Museum.jpg"
  },
  {
      "name": "Castle",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Castle.jpg"
  },
  {
      "name": "Village",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Village.jpg"
  },
  {
      "name": "Farm",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Farm.jpg"
  },
  {
      "name": "Cottage",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Cottage.jpg"
  },
  {
      "name": "Vineyards",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Vineyards.jpg"
  },
  {
      "name": "Factory",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Factory.jpg"
  },
  {
      "name": "Warehouse",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Warehouse.jpg"
  },
  {
      "name": "Workstation",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Workstation.jpg"
  },
  {
      "name": "Dreamscapes",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Dreamscapes.jpg"
  },
  {
      "name": "Construction Site",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Construction Site.jpg"
  },
  {
      "name": "Cafe",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Cafe.jpg"
  },
  {
      "name": "Snow",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Snow.jpg"
  },
  {
      "name": "Waterfall",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Waterfall.jpg"
  },
  {
      "name": "Monsoon",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Monsoon.jpg"
  },
  {
      "name": "Street",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Street.jpg"
  },
  {
      "name": "Lightning",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Lightning.jpg"
  },
  {
      "name": "Rural",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Rural.jpg"
  },
  {
      "name": "Fall",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Fall.jpg"
  },
  {
      "name": "Urban",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Urban.jpg"
  },
  {
      "name": "Spring",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Spring.jpg"
  },
  {
      "name": "Outdoor_1",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Outdoor_1.jpg"
  },
  {
      "name": "Summer",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Summer.jpg"
  },
  {
      "name": "Game Zone",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Game Zone.jpg"
  },
  {
      "name": "Winter",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Winter.jpg"
  },
  {
      "name": "Party",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Party.jpg"
  },
  {
      "name": "Clinic",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Clinic.jpg"
  },
  {
      "name": "Club",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Club.jpg"
  },
  {
      "name": "Hospital",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Hospital.jpg"
  },
  {
      "name": "Salon",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Salon.jpg"
  },
  {
      "name": "Restaurant",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Restaurant.jpg"
  },
  {
      "name": "Gym",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Gym.jpg"
  },
  {
      "name": "Skyscrapers",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Skyscrapers.jpg"
  },
  {
      "name": "Lake",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Lake.jpg"
  },
  {
      "name": "Galaxy",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Galaxy.jpg"
  },
  {
      "name": "Clouds",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Clouds.jpg"
  },
  {
      "name": "Space",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Space.jpg"
  },
  {
      "name": "Underwater_1",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Underwater_1.jpg"
  },
  {
      "name": "Post Apocalyptic",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Post Apocalyptic.jpg"
  },
  {
      "name": "Workbench",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Workbench.jpg"
  },
  {
      "name": "Desk",
      "thumbnail": "https://dresma-design.s3.us-east-2.amazonaws.com/03_01_2024/Desk.jpg"
  }
];