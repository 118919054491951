import { css } from "@emotion/react"
import { Icon } from "../Icon"
import { Tooltip } from 'react-tooltip'
import { useId } from "react"

export const IconButtonTypes = {
    dark: "dark",
    grey: "grey",
    light: "light",
    pink: "pink",
    purple: "purple",
    white: "white",
}

export const IconButtonSizes = {
    lg: "lg",
    lgBigIcon:"lgBigIcon",
    md: "md",
    mdBigIcon:"mdBigIcon",
    sm: "sm",
    smBigIcon:"smBigIcon",
    xs: "xs",
    smMidIcon: "smMidIcon",
}

export default function IconButton({icon, type, size, style={}, onClick=(e)=>{}, tooltipText=undefined, onMouseEnter=()=>{}, onMouseLeave=()=>{}}) {
    const id = useId();
    return (<button data-tooltip-id={id} data-tooltip-content={tooltipText} css={[styles.container, styles?.size?.[size]?.container, styles?.type?.[type]?.container]} style={style} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Icon src={icon} height={styles.size?.[size]?.icon.height || 0} width={styles.size?.[size]?.icon.width || 0} style={styles.size?.[size]?.icon || {}}/>
        <Tooltip id={id} style={styles.zIndexTooltip} />
    </button>)
}

const styles = {
    size: {
        xs: {
            container: css`
                padding: 4px;
                border-radius: 2px; 
            `,
            icon: {
                height: 14,
                width: 14,
                opacity:1
            },
        },
        smMidIcon: {
            container: css`
                padding: 4px;
                border-radius: 2px; 
            `,
            icon: {
                height: 22,
                width: 22,
                opacity:1
            },
        },
        smBigIcon: {
            container: css`
                padding: 4px;
                border-radius: 2px; 
            `,
            icon: {
                height: 24,
                width: 24,
                opacity:1
            },
        },
        md: {
            container: css`
                padding: 10px;
                border-radius: 2px; 
            `,
            icon: {
                height: 12,
                width: 12,
                opacity:1
            },
        },
        mdBigIcon: {
            container: css`
                padding: 8px;
                border-radius: 2px; 
            `,
            icon: {
                height: 16,
                width: 16,
                opacity:1
            },
        },
        lg: {
            container: css`
                padding: 10px 32px;
                border-radius: 4px;
            `,
            icon: {
                height: 24,
                width: 24,
                opacity:0.7,
            },
        },
        lgBigIcon: {
            container: css`
                padding: 4px 8px;
                border-radius: 2px; 
            `,
            icon: {
                height: 24,
                width: 24,
                opacity:1
            },
        },
    },
    type: {
        dark: {
            container: css`
                background-color: #464646;
            `,
        },
        light: {
            container: css`
                background-color: #F9F9F9;
            `,
        },
        purple: {
            container: css`
                border-radius: 100px;
                background: linear-gradient(146deg, #9F35F0 4.38%, #5F2EE5 114.06%);
                margin-right: 8px;
            `,
        },
        pink: {
            container: css`
                border-radius: 110px;
                background: linear-gradient(180deg, rgba(233, 183, 212, 0.40) 0%, rgba(249, 218, 190, 0.40) 100%);
                margin-right: 8px;
            `,
        },
        white: {
            container: css`
                background: #ffffff;
            `, 
        }
    },
    container: css `
        border: 0px solid transparent;
        display:flex;
        align-items: center;
        cursor:pointer;
    `,
    zIndexTooltip:{
        zIndex:1
    },
}