import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loadingRelatedTemplates: false,
  error: null,
  templates: [],
  templateCount: 0,
  relatedTemplateCount: 0,
  template: null,
  image: null,
  filterOptions: [],
  sortBy: null,
  searchStrings: [],
  searchString: "",
  popularSearches: [],
  recentSearches: [],
  exclusiveCollection: [],
  relatedTemplates: [],
  favourite: [],
  filters: {
    productType: [],
    imageFormat: [],
    category: [],
    colors:[],
    themes:[]
  },
  limit: 20,
  updateRecentSearch: false,
  jobBasedATemplatective:false,
  colorBasedTemplateActive:false,
  colorBasedTemplates:{},
  showSearchTempByColor:false,
  selectedProjectImage:{},
  selectedImageColorData:{},
  filterColorData:{},
  instagramDoMyShoots:[],
};

const slice = createSlice({
  name: "template",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING RELATED TEMPLATES
    startLoadingRelatedTemplates(state) {
      state.loadingRelatedTemplates = true;
    },
    // STOP LOADING RELATED TEMPLATES
    stopLoadingRelatedTemplates(state) {
      state.loadingRelatedTemplates = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET FILTER OPTIONS
    getFilterOptions(state, action) {
      state.isLoading = false;
      state.filterOptions = action.payload;
    },
    getInstagramDoMyShoots(state, action) {
      state.isLoading = false;
      state.instagramDoMyShoots = action.payload;
    },
    // SET IMAGE
    setImage(state, action) {
      state.isLoading = false;
      state.image = action.payload;
    },
    setPopularSearches(state, action) {
      state.isLoading = false;
      state.popularSearches = action.payload
        ? action.payload
        : state.popularSearches;
    },
    setRecentSearches(state, action) {
      state.isLoading = false;
      state.recentSearches = action.payload
        ? action.payload
        : state.recentSearches;
    },
    setExclusiveCollection(state, action) {
      state.isLoading = false;
      state.exclusiveCollection = action.payload
        ? action.payload
        : state.exclusiveCollection;
    },
    // SET TEMPLATE
    setTemplate(state, action) {
      state.isLoading = false;
      state.template = action.payload;
    },
    // SET TEMPLATE
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    // SET TEMPLATE COUNT
    setTemplateCount(state, action) {
      state.templateCount = action.payload;
    },
    // SET RELATED TEMPLATE COUNT
    setRelatedTemplateCount(state, action) {
      state.isLoading = false;
      state.relatedTemplateCount = action.payload;
    },
    // SET RELATED TEMPLATES
    setRelatedTemplates(state, action) {
      state.loadingRelatedTemplates = false;
      state.relatedTemplates = action.payload;
    },
    // GET TEMPLATES
    getTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.templates = action.payload;
    },
    // GET FAVOURITE
    setFavourite(state, action) {
      state.isLoading = false;
      state.favourite = action.payload;
    },
    //  SORT & FILTER TEMPLATES
    sortByTemplates(state, action) {
      state.sortBy = action.payload;
      state.templates = [];
    },
    searchTemplate(state, action) {
      state.searchStrings = action.payload;
      state.templates = [];
    },
    filterTemplates(state, action) {
      state.filters.productType = action.payload.productType;
      state.filters.imageFormat = action.payload.imageFormat;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.themes = action.payload.themes;
      state.templates = [];
    },
    clearFilters(state, action) {
      state.filters.productType = [];
      state.filters.imageFormat = [];
      state.filters.category = [];
      state.filters.colors = [];
      state.filters.themes = [];
      state.searchStrings = [];
      state.templates = [];
    },
    clearSearchString(state, action) {
      state.searchStrings = [];
    },
    setUpdateRecentSearch(state, action) {
      state.updateRecentSearch = action.payload
    },
    setColorBasedTemplates(state,action){
      state.colorBasedTemplates = action.payload;
    },
    setColorBasedTemplateActive(state,action){
      state.colorBasedTemplateActive = action.payload;
    },
    setJobBasedATemplatective(state,action){
      state.jobBasedATemplatective = action.payload;
    },
    setShowSearchTempByColor(state,action){
      state.showSearchTempByColor=action.payload;
    },
    setColorAndProjectBasedTemplateSearch(state,action){
      state.jobBasedATemplatective=false;
      state.colorBasedTemplateActive=false;
      state.colorBasedTemplates={};
      state.showSearchTempByColor=false;
    },
    setSelectedProjectImage(state,action){
      state.selectedProjectImage = action.payload;
    },
    setSelectedImageColorData(state,action){
      state.selectedImageColorData = action.payload;
    },
    setfilterColorData(state,action){
      state.filterColorData = action.payload;
    },
    resetTemplateReducer(state){
      state.isLoading=false;
      state.loadingRelatedTemplates=false;
      state.error=null;
      state.templates=[];
      state.templateCount=0;
      state.relatedTemplateCount=0;
      state.template=null;
      state.image=null;
      state.sortBy=null;
      state.searchStrings=[];
      state.searchString="";
      state.popularSearches=[];
      state.recentSearches=[];
      state.exclusiveCollection=[];
      state.relatedTemplates=[];
      state.favourite= [];
      state.filters={
        productType: [],
        imageFormat: [],
        category: [],
        colors:[],
        themes:[]
      };
      state.limit=20;
      state.updateRecentSearch=false;
      state.jobBasedATemplatective=false;
      state.colorBasedTemplateActive=false;
      state.colorBasedTemplates={};
      state.showSearchTempByColor=false;
      state.selectedProjectImage={};
      state.selectedImageColorData={};
      state.filterColorData={};
      state.instagramDoMyShoots=[];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByTemplates,
  filterTemplates,
  setTemplate,
  setImage,
  searchTemplate,
  clearFilters,
  clearSearchString,
  setTemplates,
  setRelatedTemplates,
  setUpdateRecentSearch,
  setColorBasedTemplates,
  setColorBasedTemplateActive,
  setJobBasedATemplatective,
  setTemplateCount,
  getTemplatesSuccess,
  setShowSearchTempByColor,
  setColorAndProjectBasedTemplateSearch,
  setSelectedProjectImage,
  setSelectedImageColorData,
  setfilterColorData,
  resetTemplateReducer,
} = slice.actions;

// ----------------------------------------------------------------------

export const getTemplates = (page, pageType, searchStrings, filters, updateRecentSearch) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/v3/exploreTemplates/searchAndFilter?skip=${page}&limit=20`,
        {
          searchStrings: searchStrings,
          searchType: pageType,
          filters: {
            productType: filters.productType,
            imageFormat: filters.imageFormat,
            category: filters.category,
            colors: filters.colors,
            themes: filters.themes
          },
          updateRecentSearch
        }
      );
      dispatch(
        slice.actions.getTemplatesSuccess(response.data.data.templateList)
      );
      dispatch(slice.actions.setTemplateCount(response.data.data.count || 0));
      dispatch(
        slice.actions.setRecentSearches(response.data.data.recentSearches)
      );
      dispatch(
        slice.actions.setPopularSearches(response.data.data.popularSearches)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getFilterOptions = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/v3/exploreTemplates/filters");
      dispatch(slice.actions.getFilterOptions(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};
export const getPreferredCategoryList = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/v4/shootnewLifestyleImage/getSettingTabCategoryList");
      dispatch(slice.actions.getCategoryList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getRelatedTemplates = (payload) => {
  return async () => {
    dispatch(slice.actions.startLoadingRelatedTemplates());
    try {
      const response = await axios.get(
        `/v4/shootnewLifestyleImage/fetchTemplatesByFormat?lifestyle_format=${payload?.lifeStyleFormat}&skip=${payload?.skip}&limit=${payload?.limit}&image_id=${payload?.imageId}`
      );
      dispatch(
        slice.actions.setRelatedTemplates(response?.data?.data?.template_list)
      );
      dispatch(
        slice.actions.setRelatedTemplateCount(response?.data?.data?.count || 0)
      );
    } catch (error) {
      dispatch(slice.actions.stopLoadingRelatedTemplates());
    }
  };
};

export const getTemplatesResults = (page, pageType, searchStrings, filters, updateRecentSearch = false) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/v3/exploreTemplates/searchAndFilter?skip=${page}&limit=20`,
        {
          searchStrings: searchStrings,
          searchType: pageType,
          filters: {
            productType: filters.productType,
            imageFormat: filters.imageFormat,
            category: filters.category,
            colors: filters.colors,
            themes: filters.themes
          },
          updateRecentSearch
        }
      );
      dispatch(
        slice.actions.getTemplatesSuccess(response.data.data.templateList)
      );
      dispatch(
        slice.actions.setRecentSearches(response.data.data.recentSearches)
      );
      dispatch(
        slice.actions.setPopularSearches(response.data.data.popularSearches)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getTemplatebycolorFilter=(red,green,blue,skip=0,limit=20)=>{
  return async ()=>{
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`/v3/exploreTemplates/templatesColorSearch?searchType=colorSelection&r=${red}&g=${green}&b=${blue}&skip=${skip}&limit=${limit}`);
      dispatch(
        slice.actions.getTemplatesSuccess(response.data.data.templateList)
      );
      dispatch(slice.actions.setTemplateCount(response.data.data.count));
    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getInstagramDoMyShoot = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/v4/instagram/me/media?fields=caption,username,media_url");
      dispatch(slice.actions.getInstagramDoMyShoots(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};