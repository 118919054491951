import { createSlice } from "@reduxjs/toolkit"

const initialState={
    navPath:"",
    couponData:{},
    referredEmailId:"",
    referredPhoneNo:"",
    referredCountryCode:""
}

const slice=createSlice({
    name:"appRedirect",
    initialState,
    reducers:{
        setNavPath(state,action){
            state.navPath=action.payload;
        },
        setCouponData(state,action){
            state.couponData = action.payload;
        },
        setReferredEmailId(state,action){
            state.referredEmailId = action.payload;
        },
        setReferredPhoneNo(state,action){
            state.referredPhoneNo = action.payload;
        },
        setReferredCountryCode(state,action){
            state.referredCountryCode = action.payload;
        },
    }
})

export default slice.reducer;

export const {setNavPath, setCouponData, setReferredEmailId, setReferredPhoneNo, setReferredCountryCode} =slice.actions;