import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import React, { useCallback, useEffect, useRef } from 'react';
import { Icon } from '../Icon';
import { css } from '@emotion/react';

interface IPopUpPropType {
    footer?: React.ReactNode;
    children: React.ReactNode;
    showCloseButton?: boolean;
    width?: number;
    onBackdropClick?: () => void;
    styleBody?:{};
    disableBackdropClick?:boolean;
    onCloseHandler?: () => void;
    styleClose?:{};
    onCloseButtonClick?: ()=> void;
    borderRadius?: string;
} 

export interface IPopUpRef {
    open: () => void;
    close: () => void;
}

const useStyles = makeStyles(() => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'white',
        boxShadow:'none',
        padding: "0px 0px",
        top: "50%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

export default React.forwardRef<IPopUpRef,IPopUpPropType>(function PopUp(props, ref) {
    const [isOpen, setIsOpen] = React.useState(false);
    const isCloseRef = useRef(false);
    
    const open = useCallback(() => {
        setIsOpen(true);
        isCloseRef.current = false;
    },[]);

    const close = useCallback(() =>{ 
        setIsOpen(false)
        isCloseRef.current = true;
    },[]);

    const classes = useStyles();

    React.useImperativeHandle(ref, () => ({
        open,
        close
    }),[open, close]);

    const onCloseClick = () => {
        props?.onCloseButtonClick?.();
        close();
    }

    useEffect(()=>{
        if(isCloseRef.current){
            props?.onCloseHandler?.();
            isCloseRef.current = false;
        }
    },[isCloseRef.current]);

    return (
        <Modal classes={classes.modal} open={isOpen} onClose={ props?.disableBackdropClick ? ()=>{} : close } aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" BackdropProps={{sx:{background:"#000000AA", borderRadius: "4px"}}} {...props.onBackdropClick?{onBackdropClick:props.onBackdropClick}:{}} disableAutoFocus={true}>
            <Paper className={classes.paper} sx={{width:props.width || 200, borderRadius:props?.borderRadius || "8px"}}>
                {props.showCloseButton !== false && <Icon src="/icons/close.svg" onClick={onCloseClick} height={20} width={20} style={{position:"absolute", top:18, right:18, cursor:"pointer", ...props.styleClose}}/>}
                <div css={styles.body} style={props.styleBody}>
                    {props.children}
                </div>
                {props.footer && <div css={styles.divider} />}
                {props.footer && <div css={styles.footer}>
                    {props.footer} 
                </div>}
            </Paper>
        </Modal>
    )
})

const styles = {
    divider: css`
        height:1px;
        width:100%;
        background-color:#EEE;
    `,
    body: css`
        display:flex;
        flex-direction:column;
        flex:1;
        padding:24px;
    `,
    footer: css`
        display:flex;
        flex-direction:column;
        padding:16px 0px;
    `
}