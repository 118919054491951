import * as React from "react";
import { Dialog, DialogTitle, Box, Typography, Checkbox, TextField, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Image from "next/image";
import { updateUserDetails } from "src/redux/reducers/settings.reducer";
import useAuth from "src/hooks/useAuth";
import CircularLoader from "src/components/loader/circular";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    textFieldClass: () => ({
        width: "90%",
        left: "5%",

    })
}));

function ThumbsUpJourney(props) {
    const {user, refreshUserDetails} = useAuth();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [instagramHandle, setInstagramHandle] = useState('');
    const [consentFlag, setConsentFlag] = useState(false);
    const [buttonState, setButtonState] = useState('default');
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.ThumbsUpJourney;
    const classes = useStyles();

    const close = () => {
        props.setThumbsUp(false)
    }

    const handleInstagramHandleChange = (event) => {
        const inputValue = event.target.value;
      
        if (inputValue.trim().length === 0) {
          return setInstagramHandle('');
        } 
          setInstagramHandle(inputValue);
      };
      
      
    const handleConsentChange = (event) => {
        setConsentFlag(event.target.checked);
    };

    const handleSubmit = async () => {
        setButtonLoading(true);
        let requestData = {
            action: 'UPDATE_DATA',
            instaHandleID: instagramHandle,
            instaShareConsent: consentFlag
        };
        try {
            const res = await updateUserDetails(requestData);
            if (res.status === 200) {
                setButtonState('success');
                setButtonLoading(false);
                setTimeout(() => {
                    close();
                  }, 2000);
            }
            refreshUserDetails();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let buttonBackground, buttonText, buttonTextColor, buttonBorderColor, avatarImage;
    if (buttonState === 'default') {
        buttonBackground = (instagramHandle && consentFlag) ? "#262626" : "#AFAFAF";
        buttonText = strapiData?.SubmitCTA || "Submit";
        buttonTextColor = "#FFFFFF";
        buttonBorderColor = "rgba(240, 55, 80, 0.16)";
    } else if (buttonState === 'success') {
        buttonBackground = "#F2FFF1";
        buttonText = strapiData?.SuccessfulISubmitted || "Successfully Submitted";
        buttonTextColor = "#4BB543";
        buttonBorderColor = "#4BB543";
        avatarImage = "/icons/settings/done.svg";
    }


    return (
        <Box position="absolute" style={{ width: "100%", height: "40%", zIndex: "10" }}>
            <Dialog open={props.openpop} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box style={{ width: 'fit-content', height: 'fit-content', padding: '20px' }}>
                    <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
                        <Typography style={{ fontSize: "16px", fontWeight: "500", color: "#753C95" }}>
                            {strapiData?.ThisShootCanText || "This shoot can be on Us!"}
                        </Typography>
                        <svg style={{ display: "flex", cursor: "pointer", position: "absolute", right: "3%" }} onClick={close}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9999 3.99998L3.99994 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.99994 3.99998L11.9999 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </DialogTitle>
                    <Box style={{ display: "flex", justifyContent: "center", margin: "5px", }}>
                        <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                            {strapiData?.ShareYourInstaText || "Share your Instagram handle with us!"}
                        </Typography>
                    </Box>
                    <Box style={{ boxShadow: "0px 3px 40px rgba(38, 38, 38, 0.08)", borderRadius: "8px" }}>
                        <Image style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                            src="/icons/settings/ShareInsta.svg"
                            width={560}
                            height={200}
                        />
                        <Box marginTop={1}>
                            <TextField
                                placeholder={user?.user?.insta_handle || strapiData?.InstaPlaceholder || "@enter your instagram handle" }
                                className={classes.textFieldClass}
                                variant="outlined"
                                value={instagramHandle}
                                onChange={handleInstagramHandleChange}
                            />
                            <label style={{ display: "flex", justifyContent: "center", borderBottom: "1px solid #EEEEEE", borderTop: "1px solid #EEEEEE", height: "50px", position: "relative", top: "14px", alignItems: "center", fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>
                                <Checkbox checked={consentFlag} onChange={handleConsentChange} />
                                <span style={{ paddingLeft: "8px" }} onClick={(event) => event.stopPropagation()}>
                                    {strapiData?.ConsentText || "I allow DoMyShoot to share these images on their website"}
                                </span>
                            </label>
                            <Button
                                style={{
                                    width: "90%",
                                    left: "5%",
                                    marginTop: "20px",
                                    border: `1px solid ${buttonBorderColor}`,
                                    borderRadius: "6px",
                                    background: buttonBackground,
                                    color: buttonTextColor,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                onClick={handleSubmit}
                                disabled={ !instagramHandle || !consentFlag}
                                >
                                {avatarImage && <img src={avatarImage} alt="Avatar" style={{ marginRight: "8px", width: "16px", height: "16px" }} />}
                                {buttonText}
                                {buttonLoading && (
                                    <CircularLoader thickness="6" color="inherit" />
                                    )}
                            </Button>

                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "8px" }}>
                                <div marginTop="2px">
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 3.25V5H9.75C10.0961 5 10.4345 4.89737 10.7222 4.70507C11.01 4.51278 11.2343 4.23947 11.3668 3.9197C11.4992 3.59993 11.5339 3.24806 11.4664 2.90859C11.3989 2.56913 11.2322 2.25731 10.9874 2.01256C10.7427 1.76782 10.4309 1.60115 10.0914 1.53363C9.75194 1.4661 9.40008 1.50076 9.08031 1.63321C8.76053 1.76566 8.48722 1.98997 8.29493 2.27775C8.10264 2.56554 8 2.90388 8 3.25V3.25ZM8 3.25V5H6.25C5.90388 5 5.56554 4.89737 5.27775 4.70507C4.98997 4.51278 4.76566 4.23947 4.63321 3.9197C4.50076 3.59993 4.4661 3.24806 4.53363 2.90859C4.60115 2.56913 4.76782 2.25731 5.01256 2.01256C5.25731 1.76782 5.56913 1.60115 5.90859 1.53363C6.24806 1.4661 6.59993 1.50076 6.9197 1.63321C7.23947 1.76566 7.51278 1.98997 7.70507 2.27775C7.89737 2.56554 8 2.90388 8 3.25V3.25Z" stroke="#753C95" stroke-miterlimit="10" stroke-linecap="round" />
                                        <path d="M13 5H3C2.44772 5 2 5.44772 2 6V7.5C2 8.05228 2.44772 8.5 3 8.5H13C13.5523 8.5 14 8.05228 14 7.5V6C14 5.44772 13.5523 5 13 5Z" stroke="#753C95" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 8.5V13C13 13.3978 12.842 13.7794 12.5607 14.0607C12.2794 14.342 11.8978 14.5 11.5 14.5H4.5C4.10218 14.5 3.72064 14.342 3.43934 14.0607C3.15804 13.7794 3 13.3978 3 13V8.5M8 5V14.5" stroke="#753C95" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <Typography style={{ fontSize: "15px", fontWeight: "500", color: "#753C95", marginLeft: "10px" }}>
                                        {strapiData?.WeWillRefund || "We will refund the credits back to you"}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

export default ThumbsUpJourney;
