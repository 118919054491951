import { css } from "@emotion/react"
import AIEditIcon from './aiEdit.svg'
import RightIcon from './rightIcon.svg'
import { useSelector } from "react-redux";

export default function AIBackgroundsBanner({onClick}) {
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    return <>
    <div css={styles.container} onClick={onClick}>    
        <div css={styles.headerText}>
            <img src='/icons/AI-GeneratedVector.svg' style={styles.imageStyleOne}/>
            {strapiText?.AIGenkeratedBackgroundsText || " AI-Generated Backgrounds"}
            <img src='/icons/AI-GeneratedVector.svg' style={styles.imageStyleTwo}/>
            <img src='/icons/DMSlogo.svg' style={styles.imageStyleThree}/>
        </div>
        <div style={{display:"flex", gap:8}}>
            <div style={{flex: 0.3, height:88, display:'flex'}}>
                <img src={strapiText?.aiGeneratedImageOne}/>
            </div>
            <div style={{flex: 0.4, height:88, display:'flex'}}>
                <img src={strapiText?.aiGeneratedImageTwo}/>
            </div>
            <div style={{flex: 0.3, height:88, display:'flex'}}> 
            <   img src={strapiText?.aiGeneratedImageThree}/>
            </div>
        </div>
        <div css={styles.cta}>
            <AIEditIcon />
            <div style={{position:'relative', top:1}}>
            {strapiText?.createText || "Create Your Own"} 
            </div>
            <RightIcon />
        </div>
    </div>
    </>
}

const styles = {
    container: css`
        background: linear-gradient(90.79deg, #5F2EE5 0%, #9F35F0 100%);
        margin:10px;
        padding:10px 10px 0px 10px;
        display:flex;
        flex-direction:column;
        gap:8px;
        cursor: pointer;
        border-radius: 4px;
    `,
    headerText: css`
    font-family: Objectivity-Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 5px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #FFFFFF;
    height: 17%;
    `,
    cta: css`
    background: #FFFFFF3D;
    height:40px;
    border-radius: 4px;
    gap: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0px 8px;
    font-family: Objectivity;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:#FFF;
cursor:pointer;
    `,

    aiGenerated: css`
    font-family: Objectivity;
    font-size: 6px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFF;
    background: linear-gradient(90.79deg, #5F2EE5 0%, #9F35F0 100%);
    display:inline-block;
    padding:0px 4px;
    align-self:flex-start;
    `,
    imageStyleOne: {top: "-5px", left: "-5px", position: "relative"},
    imageStyleTwo: {top: "-10px", left: "250px", position: "relative"},
    imageStyleThree: {top: "-40px", left: "324px", position: "relative"},
}