import { createSlice } from "@reduxjs/toolkit";
import { MY_PROJECT_COMPLETE_STATUS } from "src/utils/constant";

export const filters = {
  skip: 0,
  limit: 20,
  selectedCategory: [],
  selectedUser: "",
  status: "",
  jobOrder_status: [],
  sortType: "Recent",
  dateFilter: "",
  fromDate: "", // Date format: moment(new Date()).format("DD MMM YYYY")
  tillDate: "",
};
const initialState = {
  isMyProjectLoading: false,
  fetchingAllJobsList: null,
  currentSelectedTab: "",
  statusFilterList: MY_PROJECT_COMPLETE_STATUS,
  myProjectFilters: filters,
  myProjectRefreshPage: false,
  selectedProjectList: [],
  allProjectList: [],
  csvLinkStep:"",
  csvCustomFormatData:{},
  csvStandardFormatData:{},
  openFilterOption:false,
};

const slice = createSlice({
  name: "myProject",
  initialState,
  reducers: {
    setMyProjectLoading(state, action) {
      state.isMyProjectLoading = action.payload;
    },
    setFetchingAllJobsList(state,action){
      state.fetchingAllJobsList = action.payload;
    },
    setCurrentSelectedTab(state, action) {
      state.currentSelectedTab = action.payload;
      state.selectedProjectList = [];
    },
    setStatusFilterList(state, action) {
      state.statusFilterList = action.payload;
    },
    setMyProjectFilters(state, action) {
      state.myProjectFilters = action.payload;
    },
    setMyProjectRefreshPage(state, action) {
      state.myProjectRefreshPage = action.payload;
    },
    setSelectedProjectList(state, action) {
      state.selectedProjectList = action.payload;
    },
    setAllProjectList(state,action){
      state.allProjectList = action.payload;
    },
    resetMyProjectReducer(state, action){
      state.isMyProjectLoading = false;
      state.currentSelectedTab = "";
      state.statusFilterList = MY_PROJECT_COMPLETE_STATUS;
      state.myProjectFilters = filters;
      state.myProjectRefreshPage = false;
      state.selectedProjectList = [];
      state.allProjectList = [];
      state.fetchingAllJobsList = false;
    },
    setCsvLinkStep(state, action){
      state.csvLinkStep = action.payload;
    },
    setCsvCustomFormatData(state,action){
      state.csvCustomFormatData = action.payload;
    },
    setCsvStandardFormatData(state,action){
      state.csvStandardFormatData = action.payload;
    },
    setOpenFilterOption(state,action){
      state.openFilterOption = action.payload;
    }
  },
});

export default slice.reducer;

export const {
  setMyProjectLoading,
  setFetchingAllJobsList,
  setCurrentSelectedTab,
  setStatusFilterList,
  setMyProjectFilters,
  setMyProjectRefreshPage,
  setSelectedProjectList,
  setAllProjectList,
  resetMyProjectReducer,
  setCsvLinkStep,
  setCsvCustomFormatData,
  setCsvStandardFormatData,
  setOpenFilterOption,
} = slice.actions;
