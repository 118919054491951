export const DEFAULT_CANVAS_SIZE = {
    height:2000,
    width:2000
}

export const CANVAS_VIEWPORT_SIZE = {
    height:550,
    width:550
}

export const DEFAULT_EDITOR_JSON ={
    layers: [],
    backgroundInfo: {
        scaleX: 1,
        scaleY: 1,
        color: "#FFFFFF"
    },
    size: {
        height: DEFAULT_CANVAS_SIZE.height,
        width: DEFAULT_CANVAS_SIZE.width,
    }
}

export const DEAFULT_WORK_TYPE_LIST = [
    {
        id:1,
        name: 'Product on Lifestyle',
    },
    {
        id:2,
        name: 'Infographics'
    },
    {
        id:3,
        name: 'Size Chart'
    },
    {
        id:4,
        name: 'Ads'
    },
    {
        id:5,
        name: 'Banners'
    },
    {
        id:6,
        name: 'Post'
    },
    {
        id:7,
        name: 'Story'
    }
]