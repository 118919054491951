import API_SERVICE from "src/utils/axios";

const ADD_TO_FAVOURITE_URL =
  "v4/shootnewLifestyleImage/addOrRemoveFavouriteLifestyleTemplate?mode=app";

const IMAGE_UPLOAD_URL = `v3/exploreTemplates/imageUpload`;
const CREATE_NEW_JOB = `v3/exploreTemplates/createNewJoborders`;
const CONFIRM_JOB_ORDER = `v5/shootnewLifestyleImage/checkCostAndConfirmJob`;
const FETCH_TEMPLATE = `v3/exploreTemplates/getTemplateData`;
const CREATE_NEW_ORDER_MODIFY = `/v4/shootnewLifestyleImage/createJobAndFetchAngles`;
const CATEGORY_LIST = `v5/shootnewLifestyleImage/fetchCategoryListNew`;
const ABANDON_SHOOT = `v4/shootnewLifestyleImage/abandonShoot`;
const FETCH_IMAGE_COLOR =`v4/shootnewlifestyleimage/fetchcolorcodebyimageid/`

export const addToFavourite = (userId, templateId) => {
  const payload = {
    user_id: userId,
    template_id: templateId,
    activity: "Add",
  };
  return API_SERVICE.put(ADD_TO_FAVOURITE_URL, payload);
};

export const removeFromFavourite = (userId, templateId) => {
  const payload = {
    user_id: userId,
    template_id: templateId,
    activity: "Remove",
  };
  return API_SERVICE.put(ADD_TO_FAVOURITE_URL, payload);
};

export const createNewJobOrder = (jobId) => {
  return API_SERVICE.post(`${CREATE_NEW_JOB}?jobId=${jobId}`);
};

export const createNewJobOrderModify = (jobId) => {
  return API_SERVICE.post(`${CREATE_NEW_ORDER_MODIFY}`,{
    subcategory_id: "",
    category_id: "",
    marketPlace_id: "",
    abandon_job_id: "",
    adaptation_job_id: jobId,
    isTemplateSync: true
  });
};

export const confirmJobOrder = (payload) => {
  return API_SERVICE.put(CONFIRM_JOB_ORDER, payload);
};

export const abandonShoot = (jobId) => {
  const payload = {
    job_id: jobId,
  };
  return API_SERVICE.post(`${ABANDON_SHOOT}`, payload);
};

export const imageUpload = (payload) => {
  return API_SERVICE.post(IMAGE_UPLOAD_URL, payload);
};

export const fetchTemplate = (templateId) => {
  return API_SERVICE.get(`${FETCH_TEMPLATE}?templateId=${templateId}`);
};

export const fetchCategoryList = () => {
  return API_SERVICE.get(CATEGORY_LIST);
}

export const fetchSelectedImageColor = (imageId) => {
  return API_SERVICE.get(`${FETCH_IMAGE_COLOR}${imageId}`);
}