import { css } from "@emotion/react"
import RightIcon from './rightIcon.svg';
import { FORMAT_TYPES, TEMPLATE_TYPE } from "..";
import ToggleButton from "@/components/toggleSwitch/ToggleButton";
import { hotspotFilterAtom, backgroundFilterIsAllAngleGroupActiveAtom, backgroundFilterProductImageIdAtom } from "@/modules/editor-v2/Atoms";
import { useAtomValue, useAtom } from "jotai";
import { useEffect } from "react";
import DropdownSelection from "@/modules/ui-elements/DropdownSelection";
import { ICONS } from "@/modules/ui-elements/Icon";
import BackgroundAngleList from "./BackgroundAngleList";
import useEditorService from "@/hooks/useEditorService";
import { useState } from "react";
import useComponentVisible from "src/hooks/useComponentVisible";
import { useSelector } from "react-redux";
import { filterObject } from "src/modules/editor-v2/LeftToolBar/Templates"

export default function FiltersPane({
    setTemplateType,
    setSelectedFilter, 
    setIsFiltersPaneVisible,
    selectedFilter,
    strapiData,
    prespectiveList,
    prespectiveListLoading,
    setBackgroundMode,
    onSelectAngleHandler
}) {
    const [switchOn, setSwitch] = useAtom(hotspotFilterAtom);
    const {
        ref: prespectiveRef,
        isComponentVisible: isPrespectiveVisible,
        setIsComponentVisible: setIsPrespectiveVisible,
      } = useComponentVisible(false);
    const switchOnHandler = (item) => {
        setSwitch(item);
        setIsFiltersPaneVisible(false);
        setSelectedFilter((prev) => ({ ...prev, hotspotOnly: item }));
    };

    const formatSelectHandler = async (item) => {
        setIsFiltersPaneVisible(false);
        setTemplateType(TEMPLATE_TYPE.ALL)
        setSelectedFilter((prev) => ({
            ...prev,
            selectedFormat: item,
            imageFormat: [item?.title],
        }));
    };

    const { activeEditor } = useEditorService();
    const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
    const isAllAngleGroupActive = useAtomValue(backgroundFilterIsAllAngleGroupActiveAtom);
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.StudioBackgroundText?.StudioBackgroundText);
    
    return <div css={styles.container}>
        <div
            css={css`
                font-family: Objectivity;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.015em;
                text-align: left;            
            `}
        >
            {strapiText?.advanceFilter || "Advance Filter"}
        </div>
        <div>
                    <DropdownSelection
                        endIcon={ICONS.chevronFilledDown}
                        type={"FORMAT"}
                        titleText={`${selectedFilter?.selectedFormat?.aspectRatio === "1/1" ? strapiData?.SquareText : selectedFilter?.selectedFormat?.aspectRatio === "16/9" ? strapiData?.LandscapeText : selectedFilter?.selectedFormat?.aspectRatio === "9/16" ? strapiData?.PortraitText : selectedFilter?.selectedFormat?.title}`}
                        formatList={FORMAT_TYPES}
                        textLabel={strapiText?.backgroundFormat || "Background Format "}
                        selectedFilter={selectedFilter}
                        onSelectHandler={formatSelectHandler}
                        btnStyle={{ border: "none", height: "23px",paddingBottom:"5px",  background: '#EEE' }}
                        btnTextStyle={{ color: "#262626" }} startIcon={undefined}                    />

        </div>
        
        {(activeEditor && productImageId) && <div css={styles.button}>
            {strapiText?.perspective || "Perspective"}
            <div
            css={styles.sorting(isPrespectiveVisible)}
              onClick={() => setIsPrespectiveVisible((prev) => !prev)}
            >
              <span>
                {selectedFilter?.prespective?.angleGroupId
                  ? selectedFilter?.prespective?.angleGroupName
                  : isAllAngleGroupActive ? `${strapiText?.allText || "All Backgrounds"}` :  `${strapiText?.perspective || "Perspective"}`}
              </span>
              <img src={ICONS.chevronFilledDown} style={{marginTop: "-5px"}}/>
            </div>
          {isPrespectiveVisible && (
            <BackgroundAngleList
              prespectiveRef={prespectiveRef}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              prespectiveList={prespectiveList}
              prespectiveListLoading={prespectiveListLoading}
              setIsPrespectiveVisible={setIsPrespectiveVisible}
              setBackgroundMode={setBackgroundMode}
              onSelectAngleHandler={onSelectAngleHandler}
              setIsFiltersPaneVisible={setIsFiltersPaneVisible}
            />
          )}
        </div>}
        <div css={styles.button} onClick={()=>{switchOnHandler(!switchOn)}}>
            {strapiText?.CustomizableOnly || "Customizable Only Background"}
            <ToggleButton
                switchOn={switchOn}
                setSwitchOn={switchOnHandler}
            />
        </div>
        <div css={styles.button} onClick={()=>{setTemplateType(TEMPLATE_TYPE.FAVOURITE);setIsFiltersPaneVisible(false);setSelectedFilter(filterObject)}}>
            {strapiText?.favouriteBackgrounds || "Favorite Backgrounds"}
            <RightIcon />
        </div>
        <div css={styles.button} onClick={()=>{setTemplateType(TEMPLATE_TYPE.RECENT), setIsFiltersPaneVisible(false);setSelectedFilter(filterObject)}}>
            {strapiText?.recentBackgrounds || "Recent Backgrounds"}
            <RightIcon />
        </div>
    </div>
}


const styles = {
    container: css`
        position:absolute;
        width:calc(100% - 20px);
        top:54px;
        right:10px;
        background: #F9F9F9;
        z-index:10;
        border: 1px solid #EEEEEE66;
        border-radius:4px;
        display:flex;
        flex-direction:column;
        padding:10px;
        gap:8px;
    `,
    button: css`
    border: 1px solid #DEDEDE;
    height: 44px;
    padding: 10px 10px 10px 12px;
    border-radius: 4px;
    font-family: Objectivity;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: left;
    display: flex;
    align-items:center;
    justify-content:space-between;
    cursor: pointer;             
    `,
    sorting: (active) => css`
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-bottom: 0px;
    background: #EEEEEE;
    cursor: pointer;
    border-radius: 4px;
    span {
      color: black;
      text-align: right;
      font-family: "Objectivity";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 170%;
      letter-spacing: 0.24px;
    }
    ,
    img {
      width: 16px;
      height: 16px;
      transform: rotate(${active ? "180deg" : "0deg"});
    }
  `,
}