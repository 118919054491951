import { css } from "@emotion/react"
import TemplatesGrid from './TemplatesGrid'
import { useMemo } from "react"
import { Loader } from "../../MiddleSection/EditorTopToolBar"
import { useAtom, useAtomValue } from "jotai";
import { activePageAtom, backgroundFilterAtom, backgroundFilterProductImageUrlAtom, pagesAtom } from "../../Atoms";
import { useSelector } from "react-redux"
import TemplateItem from "./TemplateItem"
import { ICONS, Icon } from "@/modules/ui-elements/Icon"
import { BACKGROUND_MODE } from "."
import { isArray } from "lodash"
import {useCustomTemplateInfiniteQuery} from '@/hooks/FetchTemplateHook';

export default function FilteredTemplates({templatesResult,templatesResultIsLoading,backgroundMode,setBackgroundMode,prespectiveList,prespectiveListLoading, productImageLayerId, setIsVisualPrompt, setVisualPromptData}) {   
   
    const [pages, setPages] = useAtom(pagesAtom);
    const [activePage, setActivePage] = useAtom(activePageAtom);
    const activeEditor =  useMemo(()=>pages?.[activePage],[pages,activePage]);
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudioBackgroundText?.StudioBackgroundText;
    const productImageUrl = useAtomValue(backgroundFilterProductImageUrlAtom)
    const [selectedFilter,setSelectedFilter] = useAtom(backgroundFilterAtom);
    const {status,error,data,isLoading,isFetchingNextPage,hasNextPage,fetchNextPage} = useCustomTemplateInfiniteQuery();

    const templates = useMemo(()=>{
        return data?.pages?.reduce((acc, page)=>[...acc, ...page], []);
    }, [data]);


    const loadingContainerStyle = useMemo(()=>{
        return {
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            height:"100%",
            color:"white",
            fontSize:"12px",
            fontFamily:"Objectivity",
            fontStyle:"normal",
            fontWeight:"400",
            lineHeight:"150%",
        }
    }, []);
    
    const onScroll = (e)=>{
        if(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100){
            if(!isFetchingNextPage) fetchNextPage();
        }
    }

    const onSelectAngleHandler = (data) => {
        const layer = activeEditor.layerManager._layers.value.find(x=>x.id==productImageLayerId);
        if(!layer) return;
        layer.getTags()?.filter((obj) => {
            if (obj?.includes("angle-group")) layer.removeTag(obj);
          });
        layer.addTag("angle-group");
        layer.addTag("angle-group-id-"+data?.angleGroupId);
        layer.addTag("angle-group-name-"+data?.angleGroupName);
        setSelectedFilter(prev=>({...prev,prespective:{angleGroupId:data?.angleGroupId,angleGroupName:data?.angleGroupName}}));
        setBackgroundMode(BACKGROUND_MODE.BASIC);
    }

    if(backgroundMode === BACKGROUND_MODE.BASIC && !templates || isLoading) return <div style={loadingContainerStyle}>
        {entireStrapiData?.StudioBackgroundText?.LoadingText ||`Loading...`}
    </div>;

    if(backgroundMode === BACKGROUND_MODE.SELECTANGLE && (!prespectiveList || prespectiveListLoading)) return <div style={loadingContainerStyle}>
    {entireStrapiData?.StudioBackgroundText?.LoadingText ||`Loading...`}
    </div>;

    return <div css={styles.container} onScroll={onScroll}>
        {backgroundMode === BACKGROUND_MODE.SELECTANGLE ? <>
        {(!isArray(prespectiveList) || !prespectiveList) && <div css={css`font-size: 12px;width:100%;text-align:center;color:#fff;`}>{prespectiveListLoading ? entireStrapiData?.StudioBackgroundText?.LoadingText ||`Loading...` : 'No Result found'}</div>}
        {isArray(prespectiveList) &&
         prespectiveList?.map((data)=><div>
            <div css={styles.titleRow}>
                <div css={styles.titleContainer}>
                {data?.angleGroupName}
                </div>
                <div css={styles.buttonContainer} onClick={()=>onSelectAngleHandler(data)}>
                   <div style={{marginTop:"5px"}}>View All</div>
                    <Icon src={ICONS.chevronRight} height={12} width={12} />
                </div>
            </div>
            <div css={styles.gridContainer}>
            {data?.hits?.map(template=>(
            <TemplateItem 
              aspectRatio={selectedFilter?.selectedFormat?.aspectRatio} 
              isPaid={!!template?.value?.template_cost} 
              isFavorite={!!template?.value?.isFavourite} 
              src={template.value.editorThumbnail} 
              _id={template.value._id} 
              boundingBox={template?.value?.bounding_box} 
              productImageUrl={productImageUrl} 
              productImageLayerId={productImageLayerId}
            />
        ))}</div>
        </div>)}
        </> :<>
        <div css={styles.resultsContainer}></div>
        <TemplatesGrid templates={templates} aspectRatio={selectedFilter?.selectedFormat?.aspectRatio} productImageLayerId={productImageLayerId} productImageUrl={productImageUrl}             
            setVisualPromptData={setVisualPromptData}
            setIsVisualPrompt={setIsVisualPrompt}/>
        {isFetchingNextPage && <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"white", padding:8}}>
            <Loader />
        </div>}
        {!hasNextPage && status != "loading" && <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:'white', marginTop: "-12px"}}>
        {strapiData?.NoMoreBGText||"No more backgrounds"}
        </div>}
        </>}
    </div>
}


const styles = {
    container: css`
        padding:8px 10px;
        overflow: auto;
    `,
    resultsContainer:css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom:2px;
        color: #EEEEEE;
    `,
    titleRow: css`
        flex-direction:row;
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-bottom:10px;
        margin-top:10px;
    `,
    titleContainer: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #EEEEEE;
    `,
    buttonContainer: css`
        &:hover {
            transform: scale(1.05);
        }
        transition: all 0.2s ease-in-out;
        padding: 2px 4px;    
        background: rgba(238, 238, 238, 0.08);
        border-radius: 2px;
        display:flex;
        flex-direction:row;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 160%;
        /* identical to box height, or 16px */
        cursor:pointer;
        display: flex;
        align-items: center;

        color: rgba(238, 238, 238, 0.7);
    `,
    gridContainer: css`
        display:flex;
        flex-direction:row;
        margin:-2.5px;
        overflow:auto;
    `,
}