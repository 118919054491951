import BtnWithCheck from "src/modules/ui-elements/BtnWithCheck";
import { ICONS } from "@/modules/ui-elements/Icon";
import IconButton, {
    IconButtonSizes,
    IconButtonTypes,
  } from "src/modules/ui-elements/IconButton";
import { FORMAT_TYPES, TEMPLATE_TYPE } from ".";
import { useState } from "react";
import { atom, useAtom} from "jotai";
import { backgroundFilterAtom, backgroundFilterIsAllAngleGroupActiveAtom} from "src/modules/editor-v2/Atoms";
import { filterObject } from "src/modules/editor-v2/LeftToolBar/Templates"

const styles = {
    ComponentContainer: {
        gap: "10px",
        margin: "0px 10px 0px 10px",
        padding: "12px 0px 8px 0px",
        fontSize: "14px",
        fontWeight: "500",
        backgroundColor: "transparent",
        color: "white",
        display: "flex",
        flexDirection: "column"
    },
    TopContainer: {
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    ButtonContainer: {
        display: "flex",
        gap: "10px"
    },
    divider: {
        height: "1px",
        background: "rgba(255, 255, 255, 0.24)"
    }
}

export  default function FilteredTemplateTopPane ({formatSelectHandler, setOpenFilter, templateType, setTemplateType, backgroundMode}){
    const [selectedFilter,setSelectedFilter] = useAtom(backgroundFilterAtom);
    const [selectedFormat, setSelectedFormat] = useState([selectedFilter?.selectedFormat]);
    const [isAllAngleGroupActive,setIsAllAngleGroupActive] = useAtom(backgroundFilterIsAllAngleGroupActiveAtom);

    const backHandler = () => {
        setTemplateType(TEMPLATE_TYPE.ALL)
        setSelectedFilter(filterObject);
        setIsAllAngleGroupActive(true)
    }
    const toggleFormat =(format) =>{setSelectedFormat([format])
        // if(selectedFormat.includes(format)){
        //     setSelectedFormat(selectedFormat.filter((f)=> f !== format));
        // }
        // else{
        //     setSelectedFormat([format])
        // }
    formatSelectHandler(format)
    }

    return (
        <>
                <div style={styles.ComponentContainer}>
                    <div style={styles.TopContainer}>
                    <IconButton type={IconButtonTypes.dark} style={{width: "fit-content", borderRadius: "4px"}}size={IconButtonSizes.mdBigIcon} icon={ICONS.chevronLeft} onClick={backHandler} />
                    <div>{selectedFilter?.prespective?.angleGroupName ? selectedFilter?.prespective?.angleGroupName : templateType === "recentused" ? "Recently Used" : "Favourite"}</div>
                    </div>
                    {!selectedFilter?.prespective?.angleGroupName && <div style={styles.ButtonContainer}>
                        <BtnWithCheck checked={selectedFormat.includes(FORMAT_TYPES[0])} btnText={FORMAT_TYPES[0].title} onClick={()=>{toggleFormat(FORMAT_TYPES[0])}}/>
                        <BtnWithCheck checked={selectedFormat.includes(FORMAT_TYPES[1])} btnText={FORMAT_TYPES[1].title} onClick={()=>{toggleFormat(FORMAT_TYPES[1])}}/>
                        <BtnWithCheck checked={selectedFormat.includes(FORMAT_TYPES[2])} btnText={FORMAT_TYPES[2].title} onClick={()=>{toggleFormat(FORMAT_TYPES[2])}}/>
                    </div>}
                </div>
            <div style={styles.divider}/>    
        </>
    )
}