import { CANVAS_VIEWPORT_SIZE, DEFAULT_CANVAS_SIZE } from "@/modules/editor-v2/constants";
import { createPage, savePage, updateCanvas } from "@/modules/editor-v2/services/Jobs";
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { DMSCanvasFile, IPosition } from "local_modules/editor/src/Interfaces";
import DMSCanvas from "local_modules/editor/src/DMSCanvas";
import { IPixelDimensions } from "local_modules/editor/src/Interfaces";
import Layer from "local_modules/editor/src/Layer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { activePageAtom, activeProjectAtom, backgroundColorAtom, backgroundImageAtom, editorSlider, pageStatesAtom, pagesAtom, pagesImagesAtom, infographicsLayerProgressAtom } from "src/modules/editor-v2/Atoms"
import { getTemplate } from "@/modules/editor-v2/services/MyWorks";
import { IBoundingBox } from "@/modules/ui-elements/TemplatePreview";
import ImageLayer from "local_modules/editor/src/ImageLayer";
import { convertRgbToHex, getFillValues } from "@/modules/editor-v2/LeftToolBar/Elements/ElementsHelper";

const SOLID_FILL_TEMPLATE = process?.env?.NEXT_PUBLIC_SOLID_FILL_TEMPLATE;
const WHITE_TEMPLATE = process?.env?.NEXT_PUBLIC_WHITE_TEMPLATE;

interface Page {
    canvas?: {
      dispose: () => void;
    };
  }

export default function useEditorService() {

    const [pages, setPages] = useAtom(pagesAtom);
    const [activePage, setActivePage]:any = useAtom(activePageAtom);
    const [backgroundImage, setBackgroundImage] = useAtom(backgroundImageAtom as any);

    const activeEditor:DMSCanvas = useMemo(()=>pages?.[activePage],[pages,activePage]);
    const activeProject = useAtomValue<any>(activeProjectAtom);
    const canvasSlider:any = useAtomValue(editorSlider);
    const [pageStates,setPageStates] = useAtom(pageStatesAtom);
    const setActiveProject = useSetAtom<any>(activeProjectAtom);
    const setPagesImages = useSetAtom(pagesImagesAtom)
    const setBackgroundColorAtom = useSetAtom(backgroundColorAtom);

    const setInfographicsLayerProgress = useSetAtom(infographicsLayerProgressAtom);
 
    const setZoom = useCallback((value)=>{
        if(!activeEditor) return;
        activeEditor.zoomAndPanManager.setZoom(value);
    },[activeEditor]);

    const setBackground = useCallback((url,_id)=>{
        if(!activeEditor) return new Promise<void>(res=>res());
        const fn = async () => {
            let image_id = pageStates.find(x=>x.page == activePage).imageId;
            if(image_id && _id) updateCanvas(image_id,_id);
            if(_id === WHITE_TEMPLATE) url = ""; //WAS BREAKING THE AI GENERATION FLOW
            await activeEditor.setBackgroundFromURL(url);
            if(!_id) return;
            setPageStates((prev)=>prev.map((x,i)=>prev[i].page === activePage ? {...x, selectedTemplate: _id} : x));
        };
        return fn().then(()=>setBackgroundImage(url));
    },[activeEditor,setPageStates, setBackgroundImage, activePage, pageStates]);

    const setBackgroundAndSuperImposeProduct = useCallback((backgroundUrl:string,backgroundId:string, productImageLayerId:string, productImageNaturalDimensions:IPixelDimensions, backgroundNaturalDimensions:IPixelDimensions, originalBoundingBox: IBoundingBox)=>{
        if(!activeEditor) return new Promise<void>(res=>res());
        const fn = async () => {
            const layer = activeEditor.layerManager._layers.value.filter(x=>x.id==productImageLayerId)?.[0];
            if(layer && backgroundNaturalDimensions) {
                const scaleY = activeEditor.originalSize.height / backgroundNaturalDimensions?.height;
                const scaleX = activeEditor.originalSize.width / backgroundNaturalDimensions?.width;
                const {dimensions, position} = calcProductImageDimensionsAndPosition(productImageNaturalDimensions,originalBoundingBox,scaleX,scaleY);
                layer?.setDimensions(dimensions);
                layer?.setPosition(position);
                layer?.setRotation(originalBoundingBox.rotation);
                // return;
            }
            let image_id = pageStates.find(x=>x.page == activePage).imageId;
            if(image_id && backgroundId) updateCanvas(image_id,backgroundId);
            await activeEditor.setBackgroundFromURL(backgroundUrl);
            if(!backgroundId) return;
            setPageStates((prev)=>prev.map((x,i)=>prev[i].page === activePage ? {...x, selectedTemplate: backgroundId} : x));
        };
        return fn().then(()=>setBackgroundImage(backgroundUrl));
    },[activeEditor,setPageStates, setBackgroundImage, activePage, pageStates]);

    const resizeViewport = useCallback((dimensions:IPixelDimensions) => {
        if(!activeEditor) return;
        activeEditor.resizeViewport(dimensions);
    },[activeEditor])

    const setBackgroundColor = (color:string) => {
        setBackgroundColorAtom(color);
        activeEditor.setBackgroundColor(color);
    }

    const setBackgroundColorToAnyCanvas =  async (canvas:DMSCanvas , color:string) => {
        setBackgroundColorAtom(color);
        await canvas.setBackgroundColor(color);
    }

    const addImage = useCallback((url,dimensions?:IPixelDimensions)=>{
        if(!activeEditor) return new Promise<void>(res=>res());
        if(!dimensions) dimensions = {
            width: activeEditor.originalSize.width - (activeEditor.originalSize.width/5),
            height: activeEditor.originalSize.height - (activeEditor.originalSize.height/5)
        }
        
        const fn = async () => {
           return await activeEditor.addImageFromUrl({imageUrl:url, dimensions});
        };
        return fn();
    },[activeEditor]);

    // const addText = useCallback((text, fontSize)=>{
    //     if(!activeEditor) return new Promise<void>(res=>res());
    //     const fn = async () => {
    //           return await activeEditor.addText(text, fontSize);
    //     }  
    //     return fn();
    // },[activeEditor]);

    const undo = useCallback(()=>{
        if(!activeEditor) return;
        activeEditor.undo();
    },[activeEditor]);

    const redo = useCallback(()=>{
        if(!activeEditor) return;
        activeEditor.redo();
    },[activeEditor]);

    const addNewPage = useCallback((dimensions, originalSize, templateId?)=>{
        if(pages.length >= 25) throw new Error("Maximum number of pages reached");
        const newPage = new DMSCanvas("canvas"+pages.length,dimensions,originalSize);
        const pageNo = pages.length;
        setPages(pages=>[...pages, newPage]);
        setActivePage(pageNo);
        setPageStates(pageStates=>[...pageStates, {
            page: pageNo,
            isAutoSaving: true,
            selectedTemplate: templateId
        }]);
        createPage(activeProject?.job_id).then((data)=>{
            setPageStates(pageStates=>pageStates.map((x,i)=>i === pageNo ? {...x, imageId:data?.imageId, selectedTemplate:templateId || data?.templateId, isAutoSaving: false} : x));
            if(templateId && data?.imageId) updateCanvas(data.imageId,templateId)
        });
        canvasSlider?.current?.slickGoTo(pageNo);
        return newPage;
    },[setPages, setActivePage,pages,canvasSlider,activeProject,pageStates, setPageStates, setBackgroundImage]);

    const addNewPageWithId = useCallback((dimensions, originalSize, id, selectedTemplate?)=>{
        if(pages.length >= 25) throw new Error("Maximum number of pages reached");
        const newPage = new DMSCanvas("canvas"+pages.length,dimensions,originalSize);
        const pageNo = pages.length;
        setPages(pages=>[...pages, newPage]);
        setPageStates(pageStates=>[...pageStates, {
            page: pageNo,
            isAutoSaving: false,
            imageId: id,
            selectedTemplate
        }]);
        return newPage;
    },[setPages, pages,pageStates, setPageStates]);

    const duplicatePage = useCallback(()=>{
        const callback = async () => {
            const templateId = pageStates.find(x=>x.page == activePage).selectedTemplate;
            const newPage = addNewPage(activeEditor.dimensions,activeEditor.originalSize, templateId);
            const data = await activeEditor.save();
            await newPage.load(data);
        }
        return callback();
    },[activeEditor, addNewPage,pageStates, activePage, backgroundImage]);

    const duplicatePageFromData = useCallback((data:DMSCanvasFile, imageId, selectedTemplateId?)=>{
        const callback = async () => {  
            const newPage = addNewPageWithId(CANVAS_VIEWPORT_SIZE,data.size, imageId, selectedTemplateId);
            await newPage.load(data);
        };

        return callback();
    },[addNewPage]);


    const deAttachBackground = useCallback(()=>{
        console.log({activeEditor, backgroundImage});
        if(!activeEditor || !backgroundImage) return;
        const fn = async () => {
            const image = await addImage(backgroundImage);
            if(!image) return;
            const layer:Layer = await activeEditor.getLayerByRef(image);
            activeEditor.layerManager.bringToBack(layer);
            activeEditor.canvas.renderAll();
            const backgroundId = pageStates?.find(x=>x.page===activePage)?.selectedTemplate;
            if(backgroundId) layer.addTag("background-image-id-" + backgroundId);
            setPageStates((prev)=>prev.map((x,i)=>prev[i].page === activePage ? {...x, selectedTemplate: "63da37542bea89148979a9b6"} : x));
            setBackgroundImage(null)
            await activeEditor.setBackgroundFromURL(null);
            activeEditor.canvas.setActiveObject(image);
        }
        return fn();
    },[activeEditor, backgroundImage, addImage, activePage, pageStates,setPageStates,setBackgroundImage]);

    const deleteBackground = useCallback(()=>{
        if(!activeEditor || !backgroundImage) return;
        const fn = async () => {
            setBackgroundImage(null)
            await activeEditor.setBackgroundFromURL(null);
        }
        return fn();
    },[activeEditor, backgroundImage,setBackgroundImage]);

    const attachBackground = useCallback(async (image:ImageLayer)=>{
        if(!activeEditor) return;
        const backgroundId = image.getTags()?.find(x=>x.includes("background-image-id-"))?.replace("background-image-id-","");
        if(!backgroundId) return;
        await setBackground(image.src,backgroundId)
        activeEditor.layerManager.delete(image);
    },[activeEditor,setBackground])

    const enterCropMode = useCallback(()=>{
        if(!activeEditor) return;
        activeEditor.cropAndResizeManager.enterCropMode();
    },[activeEditor])

    const exitCropMode = useCallback(()=>{
        if(!activeEditor) return;
        activeEditor.cropAndResizeManager.exitCropMode();
    },[activeEditor]);

    const resize = useCallback(({height,width})=>{
        if(!activeEditor) return;
        if(!height || !width) return;
        if(height < 500 || width < 500) throw new Error("Minimum size is 500x500");
        activeEditor.resize({height,width});
    },[activeEditor])

    const layerOpacities = useRef<{[key:string]:number}>({});
    const enterDallEMode = useCallback(()=>{
        if(!activeEditor) return;
        enterCropMode();
        activeEditor.layerManager._layers.getValue().forEach(x=>{
            x.fabricRef.opacity = 0.2;
        })
        activeEditor.canvas.renderAll();
    },[]);

    const exitDallEMode = useCallback(()=>{
        exitCropMode();
        activeEditor.layerManager._layers.getValue().forEach(x=>{
            x.fabricRef.opacity = layerOpacities.current[x.id];
            delete layerOpacities.current[x.id];
        })
        activeEditor.canvas.renderAll();
    },[]);

    const getCropSizeAndPosition = useCallback(()=>{
        if(!activeEditor) return;
        const position = activeEditor.cropAndResizeManager.$cropPosition.getValue();
        position.top = Math.round(position.top);
        position.left = Math.round(position.left);
        const size = activeEditor.cropAndResizeManager.$cropSize.getValue();
        size.width = Math.round(size.width);
        size.height = Math.round(size.height);
        return {...position,...size}
    },[activeEditor]);

    const addProductImage = useCallback((url:string, type:ProductImageType, id:string)=>{
        if(!activeEditor) return;
        // addImage
        return new Promise(async (resolve,reject)=>{
            try {
                let dimensions:IPixelDimensions = {
                    height: DEFAULT_CANVAS_SIZE.height/4,
                    width: DEFAULT_CANVAS_SIZE.width/4
                }
                const image = await addImage(url,dimensions);
                resolve(image);
                if(!image) return;
                const layer = await activeEditor.getLayerByRef(image);
                layer.addTag("product-image");
                layer.addTag(`product-${type===ProductImageType.IMAGE?"image":"asset"}-id-${id}`)
                if(type===ProductImageType.ASSET_NON_BG_REMOVED) layer.addTag("non-bg-removed");
            }
            catch(e) {
                reject(e || "Something Went Wrong, While adding Image!");
            }
        })
    },[activeEditor]);

    const applyInfographic = async (DMSFile: any, _id: string) => {
        if(!activeEditor) return;
        setInfographicsLayerProgress(true);
        await applyInfographicToAnyCanvas(activeEditor, DMSFile, _id);
        setInfographicsLayerProgress(false);
    }

    const applyInfographicToAnyCanvas = async (canvas: DMSCanvas, DMSFile: any, _id: string) => {

        
        const layers = canvas.layerManager._layers.value.filter(layer=>layer.getTags()?.includes("infographic-layer"));
        
        layers.forEach(layer=>{
            canvas.layerManager.delete(layer);
        });
        for(let i = DMSFile?.layers?.length-1; i >= 0 ; i--) {
            const layer= DMSFile?.layers[i];
            const layerRef = await canvas.addLayerFromSerializedData(layer);
            const productImageTags = layer.tags?.filter(x => x.includes("product-image"));
            if (productImageTags && productImageTags.length > 0) {
                layer.tags = layer.tags.map(tag => tag.replace("product-image", ""));
            }
            canvas.layerManager.bringToBack(layerRef);
            await layerRef.setPropertiesFromSerializedData(layer);
            layerRef.addTag("infographic-layer");
        }
        await setBackgroundColorToAnyCanvas(canvas, DMSFile?.backgroundInfo?.color);
        canvas.setBackgroundFromURL(DMSFile?.backgroundInfo?.src)
        setBackgroundImage(DMSFile?.backgroundInfo?.src)
        // setBackgroundImage(DMSCanvas)
    }

    const destroy = useCallback(()=>{
        setPages([]);
        setActivePage(undefined);
        setPageStates([]);
        setActiveProject(undefined);
        setPagesImages([]);
    },[setPages, setActivePage, setPageStates, setActiveProject, setPagesImages,setPageStates]);

    const disposeCanvas = () => {
        try {
          if (pages?.length > 0) {
            pages.forEach((page) => {
              if (page && page?.canvas) {
                page.canvas.dispose();
              }
            });
          }
      
          // Resetting states
          setPages([]);
          setActivePage(undefined);
          setPageStates([]);
          setActiveProject(undefined);
          setPagesImages([]);
        } catch (error) {
          console.error("Error disposing canvas:", error);
        }
      };
      
      
    const addSVG = useCallback(async (url)=>{
        let hexColor;
        if(!activeEditor) return new Promise<void>(res=>res());
        const color = await getFillValues(url)
        
        if(color?.[0] !== "none") hexColor = convertRgbToHex(color?.[0])
        else hexColor = null

        activeEditor.addSVGFromURL(url, hexColor);
    },[activeEditor])  
    

    const clearGrid = async () => {
        try{
        if (!pageStates) return;
        const promises = pageStates.map(async (state, index) => {
            pages?.[state.page]?.layerManager._layers.value.filter(async(x)=>{
            if(x.getTags().includes('grid-line'))await pages?.[state.page].layerManager.delete(x)});
        });

        await Promise.all(promises);
    }catch(err){
        console.error("Error in grid clear",err);
    }
    };
    
    const addGridLinesAndHide = async() => {
        try{
            if (!activeEditor || !activeEditor?.canvas.viewportTransform) return;
            let gridLines = activeEditor.layerManager?._layers?.getValue()?.filter(layer=>layer.getTags()?.includes("grid-line"));
            if (gridLines.length > 0) return;
            // Create grid lines
            const gridSpace = Math.min(activeEditor?.canvas.height,activeEditor?.canvas.width)/10;
            const gridColor = 'rgba(235, 46, 131, 1)'

            const canvasWidth = activeEditor?.canvas.width;
            const canvasHeight = activeEditor?.canvas.height;

            const horizontalLinesCount = canvasWidth / gridSpace;
            const verticalLinesCount = canvasHeight / gridSpace;

            const promises = [];
            for (let i = 1; i < horizontalLinesCount || i < verticalLinesCount; i++) {
                if (i < horizontalLinesCount) {
                    const horizontalLine = new fabric.Line([i * gridSpace, 0, i * gridSpace, canvasHeight], { stroke: gridColor, selectable: false, evented: false });
                    activeEditor?.canvas.add(horizontalLine);
                    const svgLayer = await activeEditor.getLayerByRef(horizontalLine);
                    svgLayer.addTag("grid-line");
                    promises.push(svgLayer);
                }
                if (i < verticalLinesCount) {
                    const verticalLine = new fabric.Line([0, i * gridSpace, canvasWidth, i * gridSpace], { stroke: gridColor, selectable: false, evented: false });
                    activeEditor?.canvas.add(verticalLine);
                    const svgLayer = await activeEditor.getLayerByRef(verticalLine);
                    svgLayer.addTag("grid-line");
                    promises.push(svgLayer);
                }
            }
            await Promise.all(promises);
            gridLines = activeEditor.layerManager._layers.value.filter(layer=>layer.getTags()?.includes("grid-line"));
            gridLines.map(async(layer)=>{
                let currOpacity = 0;
                const opacitySubscription = layer.opacity.subscribe((val)=>{currOpacity = val})
                layer.setOpacity(currOpacity ? 0 : 1);
                opacitySubscription.unsubscribe();
            });
            activeEditor?.canvas.renderAll();
        }catch(err){
            console.error("error in grid generation before eraser",err)
        }
    };

    return {
        activeEditor,
        setZoom,
        setBackground,
        setBackgroundAndSuperImposeProduct,
        resizeViewport,
        addImage,
        /*addText,*/ undo,
        redo,
        addNewPage,
        duplicatePage,
        deAttachBackground,
        duplicatePageFromData,
        addNewPageWithId,
        enterCropMode,
        disposeCanvas,
        exitCropMode,
        resize,
        enterDallEMode,
        exitDallEMode,  
        applyInfographic,
        getCropSizeAndPosition,
        addProductImage,
        destroy,
        attachBackground,
        setBackgroundColor,
        clearGrid,
        addGridLinesAndHide,
        applyInfographicToAnyCanvas,
        addSVG,
        deleteBackground,
    };

}

export const calcProductImageDimensionsAndPosition = (
    productImageDimensions:IPixelDimensions,
    templateBoundingBox:IBoundingBox,
    scaleX:number,
    scaleY:number
  ) => {
    const templateBBHeight = templateBoundingBox.height * scaleY;
    const templateBBWidth = templateBoundingBox.width * scaleX;
    const templateBBYOffset = templateBoundingBox.yOffset * scaleY;
    const templateBBXOffset = templateBoundingBox.xOffset * scaleX;
    const productRatio = productImageDimensions?.height/productImageDimensions?.width || 1;
    const templateBBRatio = templateBBHeight/templateBBWidth;
    const productImageNewDimensions:IPixelDimensions = {height: 0, width: 0} 
    const productImageNewPosition:IPosition = {x:0,y:0} ;
    console.log({productRatio, templateBBRatio});
    // if product height is more than template bounding box
    if(productRatio > templateBBRatio) {
        console.log("PR>TR" , "useEditorInit")
      productImageNewDimensions.height = templateBBHeight;
      productImageNewDimensions.width= templateBBHeight/productRatio;
      // fixing product height, so vertical justification will always be center
      if (templateBoundingBox.horizontalJustification === "center") {

        productImageNewPosition.y = templateBBYOffset;
        productImageNewPosition.x = templateBBXOffset + ((templateBBWidth - productImageNewDimensions.width)/2);
      } else if (templateBoundingBox.horizontalJustification === "left"){
        productImageNewPosition.y = templateBBYOffset;
        productImageNewPosition.x = templateBBXOffset;
      } else {
        productImageNewPosition.y = templateBBYOffset;
        productImageNewPosition.x = templateBBXOffset + (templateBBWidth - productImageNewDimensions.width)
      }
    } else {
      productImageNewDimensions.width = templateBBWidth;
      productImageNewDimensions.height = templateBBWidth * productRatio;
      // fixing product width, so horizontal justification will always be center
      if(templateBoundingBox.verticalJustification === "center"){
        productImageNewPosition.y = templateBBYOffset + ((templateBBHeight - productImageNewDimensions.height)/2);
        productImageNewPosition.x = templateBBXOffset; 
      } else if(templateBoundingBox.verticalJustification === "top"){
        productImageNewPosition.y = templateBBYOffset;
        productImageNewPosition.x = templateBBXOffset;
      } else {
        productImageNewPosition.y = templateBBYOffset + (templateBBHeight - productImageNewDimensions.height);
        productImageNewPosition.x = templateBBXOffset;
      }
    }
    return {dimensions: productImageNewDimensions, position: productImageNewPosition};
};

export enum ProductImageType  {
    ASSET,
    IMAGE,
    ASSET_NON_BG_REMOVED
}