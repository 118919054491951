import useEditorService, { ProductImageType } from "@/hooks/useEditorService";
import useToast from "@/hooks/useToast";
import PopUp, { IPopUpRef } from "@/modules/ui-elements/PopUp";
import PrimaryButton, { ButtonTypes } from "@/modules/ui-elements/PrimaryButton";
import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../MiddleSection/EditorTopToolBar";
import HorizontalLoader from "@/modules/ui-elements/horizontalLoader";
import { leftNavigationActiveIndexAtom } from "../../Atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

const IAddUploadAssetRef = {
    open: (imageUrl, id) => {},
    close: () => {}
}

export default React.forwardRef<any,any>(function AddUploadAssetPopUp(props,ref) {
    const popUpRef = useRef<IPopUpRef>();
    const progressRef = useRef(null);
    const [loadingProgress,setLoadingProgress] = useState(0);
    const [imageURL, setImageURL] = useState();
    const assetIdRef = useRef<string>(null);
    const {entireStrapiData} = useSelector((state:any)=> state.strapi); 
    const strapiData = entireStrapiData?.StudioAssestData?.StudioAssestData;
    const editorService = useEditorService();
    const queryClient = useQueryClient();
    const toaster = useToast();
    const [activeLeftPaneIndex, setActiveLeftPaneIndex] = useAtom(leftNavigationActiveIndexAtom);   
    const loaderStrapiData = useSelector((state:any)=> state.strapi.entireStrapiData?.WebListingScreen); 

    const onApply = () => {
        if(!editorService.activeEditor){
            props?.onApply(imageURL,assetIdRef.current);
            popUpRef.current?.close();
            return;
        }
        const isLayerEmpty = editorService.activeEditor.layerManager._layers.value?.length === 0;
        editorService.addProductImage(imageURL,ProductImageType.ASSET_NON_BG_REMOVED,assetIdRef.current);
        popUpRef.current?.close();
        const refreshingAssetsToasterKey = toaster.info("Refreshing Assets", 60000);
        // if(isLayerEmpty) setActiveLeftPaneIndex(2);
        if(isLayerEmpty && activeLeftPaneIndex !== 3) setActiveLeftPaneIndex(2);
        queryClient.invalidateQueries(['assets']).then(()=>{
            toaster.close(refreshingAssetsToasterKey);
        });
    }
console.log('show me image url1',imageURL, "props image url1",props.imageUrl)
    useImperativeHandle(ref,()=>({
        open: (imageUrl,_id) => {
            setImageURL(imageUrl);
            assetIdRef.current = _id;
            popUpRef.current?.open();
        },
        close: () => {
            popUpRef.current?.close();
        }
    }),[]);

    useEffect(()=>{
        if(props?.loading){
            if(progressRef.current)clearInterval(progressRef.current);
            progressRef.current = setInterval(()=>{
                setLoadingProgress(prev=>prev <= 90 ? prev+3 : prev);
            },1000);
        }else{
            if(progressRef.current)clearInterval(progressRef.current);
            setLoadingProgress(0);
        }
    },[props?.loading]);

    return <PopUp ref={popUpRef} width={460} disableBackdropClick={props?.loading} >
    {
        props.loading ? (
            <div  css={css`
                position: relative;
                width: 260px;
                aspect-ratio: 1;
                margin-left: 76px;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0.6;
            `}  style={{
                backgroundImage:`url(${imageURL || props.imageUrl || ''})`, backgroundSize: "contain", backgroundRepeat:'no-repeat',backgroundPosition: "center", padding:8,
            }}>
                <div css={css`
                    position: absolute;
                    height:100%;
                    object-fit: contain;
                    width:100%;
                `}>
                    <img src={loaderStrapiData?.LinearLineAnimation || '/icons/LinearAnimation.gif'} alt="Loading..."/>
                </div>
            </div>
        )
        : (
            <div css={styles.container}>
            <img src={imageURL} css={styles.image} />
        </div>
        )
    }
    {/* {error && <div css={styles.loaderContainer} style={{color:"#F03750"}}>
        {error}
    </div>} */}
    <div css={styles.divider} />
    {!props?.applyDisabled && <PrimaryButton disabled={props?.loading} type={ButtonTypes.darkOutline} text={strapiData?.ApplyCTA||"Apply"} onClick={onApply} style={{margin:"24px 75px 8px 75px"}} />}
    <PrimaryButton disabled={props?.loading} type={ButtonTypes.darkBgWhiteText} text={strapiData?.RemoveBgText||"Remove Background & Apply"} onClick={props?.onRemoveBg} style={styles.primaryButton} />
</PopUp>
});


const styles = {
    container: css`
        min-width: 360px;
        margin-left: 76px;
    `,
    image: css`
        width: 260px;
        margin-bottom: 16px;
        max-height:55vh;
    `,
    divider: css`
        width: 460px;
        height: 1px;
        background: #EEE;
        margin-left:-24px
    `,
    primaryButton: {
        justifyContent: 'center',
        margin: "0px 75px 0px 75px",
        padding: "8px 27px",
        alignItems: "center",
        color: "#FFF",
        fontFamily: "Objectivity",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "170%", 
        cursor:"pointer",
    },
    loaderContainer: css`
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        color: #262626;
        text-align: center;
        font-family: Objectivity-Medium;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: 0.4px;
        margin:0px 76px 24px 76px;

    `,
}