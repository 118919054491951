import styles from  "../../AIBackgrounds/AIBackgroundResults.module.css" 
import useEditorService from "@/hooks/useEditorService";
import GenerateImageIcon from "../../AIBackgrounds/GenerateImageIcon.svg"
import Loader from '../../AIBackgrounds/loader.svg';
import useToast from "@/hooks/useToast";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useAuth from "@/hooks/useAuth";
import { createNewTemplate } from "@/modules/editor-v2/services/Jobs";
import { generateBackgroundsVariation } from "@/modules/editor-v2/services/AIBackgrounds";
import AIBackgroundResultsHeader from "../../AIBackgrounds/AIBackgroundResultsHeader";
import { useEffectOnce } from "@/hooks/useEffectOnce";

const AI_TEMPLATE = process?.env?.NEXT_PUBLIC_AI_BACKGROUNDS_TEMPLATE;

export default function VisualPrompt({baseUrls=[], setGenerating,  basePrompt="", setS3Url, s3Url, productImage,  generating=false, backgroundImage, setIsVisualPrompt, backgroundBasedAIgeneration=true, setAttributePage}) {
    
    const [loading, setLoading] = useState(false);
    const {setBackground, activeEditor} = useEditorService();
    const toast = useToast();
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const {user} = useAuth();
    const [urls, setUrls] = useState(baseUrls);
    const [prompt, setPrompt] = useState(basePrompt);
    const [selectedTemplateURL, setSelectedTemplateURL] = useState("");

    const {height, width} = useMemo(()=>{
        const aspectRatio = activeEditor?.originalSize.height / activeEditor?.originalSize.width;
        return {height: 170 * aspectRatio, width: 170};
    },[activeEditor]);

    useEffectOnce(()=>{
        const oldUrls = urls
        let newUrls = Array(2).fill(0).map(x=>({url:"",loading:true}));
        const _urls = [...newUrls,...oldUrls,];
        generateMore(2);
        setUrls(_urls);
    },[])

    const onClick = (url) => {
        setBackground(url, AI_TEMPLATE);
        createNewTemplate(url, AI_TEMPLATE,"DALLE", "UPDATE").then((res:any)=>{
            if(res?.editorThumbnail && res?._id) setBackground(res.editorThumbnail,res._id)
        }).catch(console.error);
    };

    const generateMore = async (batch_size=4) => {
        try{
        setLoading(true)
        if(generating) return;
        if(!prompt) return toast.error("Please enter prompt!",3000);
        let newUrls = Array(batch_size).fill(0).map(x=>({url:"",loading:true}));
        const oldUrls = urls
        const _urls = [...newUrls,...oldUrls,];
        setUrls(_urls);
            newUrls = (await generateBackgroundsVariation({
                url:s3Url,
                prompt,
                entity_id: user?.entity?.entity?._id || "",
                background_image: backgroundImage,
                batch_size: batch_size
            })).images.map(x=>({url:x.url,loading:false}));
            setUrls([...newUrls,...oldUrls]);
            setGenerating(false);
            setLoading(false)
        }catch(e){
            toast.error("Error in image Processing")
            setLoading(false)
            setGenerating(false);
            return;
        }
    }

    const onPromptChange = ({target}) => {
        setPrompt(target?.value || "");
    }

    const generateImageStyle = (x) => (
        {
            height:"100%",
            width:"100%",
            backgroundImage:`url('${x?.url}')`,
            backgroundSize:'contain',
            cursor: x?.loading ? "not-allowed" : "pointer",
            border: selectedTemplateURL == x?.url && "3px solid black", 
        }
    )

    return <>
        <AIBackgroundResultsHeader setGenerating={setGenerating} setUrls={setUrls} setIsVisualPrompt={setIsVisualPrompt} urls={urls} backgroundBasedAIgeneration={backgroundBasedAIgeneration} setAttributePage={setAttributePage}/>
        
        <div className={styles.resultContainer} >
            <div>
                <textarea className={styles.prompt} value={prompt} onChange={onPromptChange} rows={4} />
                 <div className={`${styles.generateButton} ${(loading)?styles.generateButtonLoading:""}`} onClick={()=>generateMore(4)}>
                    <GenerateImageIcon />
                    {strapiText?.GenerateFourMoreButton || "Generate 4 More"}
                </div>
            </div>
            <div className={styles.scrollContainer}>
            <div className={styles.row}>
                {!urls.length && Array.from({ length: 4 }).map((_, i) => (
                    <div key={i} className={styles.col} style={{height,width}}>
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    </div>
                ))}
                {urls?.map((x,i)=><div key={i} className={`${styles.col} ${selectedTemplateURL == x?.url && !x?.loading &&  styles.selected}`} style={{height,width}}>
                    {x?.loading &&  <div className={styles.loader}>
                        <Loader />
                    </div>}
                    {x?.url && <div className={styles.image} onClick={(e)=>{e.stopPropagation(); onClick(x?.url); setSelectedTemplateURL(x?.url)}} style={generateImageStyle(x)}></div>}
                </div>)}
            </div>
            </div>
        </div>
    </>
}

