import axios from "axios";
import { createSession, setSession } from "./jwt";

// ----------------------------------------------------------------------

function addQueryParameterToUrl(url, paramName, paramValue) {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${paramName}=${paramValue}`;
}


const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || "",
});

// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
// axiosInstance.defaults.timeout = 10000;
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');

    // Add the Bearer token to the request header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    //website-editor is for testing in non website env
    const websiteEditor = window.location.href.includes("website-editor") || (window.location.origin == process.env.NEXT_PUBLIC_WEBSITE)
      if (response && response.status === 401 && !JSON.parse(localStorage.getItem('magicQrInfo'))) {
      setSession(null).then((result)=>{
        if (websiteEditor) {
          window.location.reload(); 
      } else {
          window.location.href = `${window.location.origin}/dashboard/`;
      }
        });
        
      }
      if (response && response.status === 403 && localStorage.getItem('accessToken')) {
          if(!sessionStorage.getItem('login_flow'))
          {  if (websiteEditor) {
            window.location.reload(); 
          } else {
              window.location.href = `${window.location.origin}/dashboard/`;
          }}
      }
    return Promise.reject(error);
  }
);

export default axiosInstance;
