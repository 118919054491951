import { css } from "@emotion/react";
import React, { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getPaginatedJobs } from "../../services/MyWorks";
import { compact } from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ICONS, Icon } from "@/modules/ui-elements/Icon";
import useEditorService from "@/hooks/useEditorService";
import {DEFAULT_CANVAS_SIZE} from "../../constants";
import { useSelector } from "react-redux";
import ImageLayer from "local_modules/editor/lib/ImageLayer";
import { createEmptyEditorJob, getJobDetails } from "../../services/Jobs";
import { useAtomValue, useSetAtom } from "jotai";
import useOtherCategory from "@/hooks/useOtherCategory";
import useToast from "@/hooks/useToast";
import { activeAssetTabAtom, activeProjectAtom, initializingEditorAtom, isShootAssetIdAtom, searchAssetKeywordAtom, toAddAssetAtom, toAddAssetIdAtom, standardInfographic } from "../../Atoms";
import GetAppLinkByScan from 'src/modules/home/GetAppLinkByScan';
import { Box } from "@mui/material";


export interface IShootAssetsRef {
    fetchMore: ()=>void
}

export interface IShootAssetsProp {
    onListChange: (list)=>void
}

export default React.forwardRef<IShootAssetsRef,IShootAssetsProp>(function ShootAssets({onListChange=(_)=>[]},ref) {
    const searchAssetKeyword = useAtomValue(searchAssetKeywordAtom);
    const activeAssetTab = useAtomValue(activeAssetTabAtom);
    const [openScanLinkDialog,setOpenScanLinkDialog] = useState(false);
    const {status, error, data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage} = useInfiniteQuery({
        queryKey: ["projectList", "infinite",searchAssetKeyword],
        getNextPageParam: (lastPage:Array<any>, pages) =>(lastPage?.length >= 10)?pages.length:undefined,
        queryFn: ({pageParam}) =>getPaginatedJobs({pageParam, searchText:searchAssetKeyword, showPreExistOnly:true}),
        enabled:activeAssetTab === 0
    });
    const {entireStrapiData} = useSelector((state:any)=> state.strapi);
    const strapiData = entireStrapiData?.StudioAssestData?.StudioAssestData;

    useImperativeHandle(ref, () => ({
        fetchMore: () => {
            if(!isFetchingNextPage && hasNextPage) fetchNextPage();
        }
    }));


    const list = useMemo(()=>compact(data?.pages.flatMap(x=>x)).filter(x=>x?.images?.length > 0),[data?.pages]);
    const dontShowBorder = useMemo(()=>list.length > 0, [list.length]);

    useEffect(()=>{
        if(list.length) onListChange(list);
    },[list]);

    return (
        <div css={styles.container}>
            {/* <NoAssetsFound /> */}
            {status !== "loading" && isFetching && <Box mb={1}><Loader dimension={12} /></Box>}
            {status === "loading" && <Loader />}
            {status === "success" && list.length < 1 && <NoAssetsFound setOpenScanLinkDialog={setOpenScanLinkDialog} strapiData={strapiData} />}
            {(status === "success" && list.length > 0) && <AssetsList list={list} setOpenScanLinkDialog={setOpenScanLinkDialog} strapiData={strapiData} />}
            <GetAppLinkByScan
                open={openScanLinkDialog}
                onClose={() => setOpenScanLinkDialog(false)}
            />
        </div>
    );
});

const NoAssetsFound = ({setOpenScanLinkDialog,strapiData}) => {

    return (<div css={assetStyle.noAssetContainer}>
    <Icon src={"/icons/mobile-icon.svg"} width={24} height={24} style={styles.getAppIcon}/>
    <div css={assetStyle.title}>
           {strapiData?.IfYouDontText ||`If you do not have product images, 
           Click images on our mobile app`}
    </div>
    <div css={assetStyle.subtitle} onClick={()=>setOpenScanLinkDialog(prev=>!prev)}>
      {strapiData?.ShootYourProdText ||`Shoot your Product`}
    </div>
</div>)};

const assetStyle ={
    noAssetContainer:css`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:10vh;
    `,
    title:css`
    color: #EEE;
    text-align: center;
    font-family: Objectivity;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    max-width:240px;
    margin-top:16px;
    line-height: 170%;
    `,
    subtitle: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 170%;
        color:#fff;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.12px; 
        gap:5px;
        text-decoration:underline;
        margin-top:10px;
        cursor:pointer;
    `
}
const AssetsList = ({list,setOpenScanLinkDialog,strapiData}) => (<> 
    <div css={btnStyle.buttonBox} onClick={()=>setOpenScanLinkDialog(prev=>!prev)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8.00004 12.6247C8.36823 12.6247 8.66671 12.3262 8.66671 11.958C8.66671 11.5898 8.36823 11.2913 8.00004 11.2913C7.63185 11.2913 7.33337 11.5898 7.33337 11.958C7.33337 12.3262 7.63185 12.6247 8.00004 12.6247Z" fill="white"/>
        <path d="M11.1514 14.6246H4.84869C4.43043 14.5997 4.03895 14.4106 3.75941 14.0985C3.47987 13.7863 3.3349 13.3764 3.35603 12.958V3.04197C3.3349 2.6235 3.47987 2.21362 3.75941 1.90149C4.03895 1.58937 4.43043 1.40026 4.84869 1.37531H11.1514C11.5696 1.40026 11.9611 1.58937 12.2406 1.90149C12.5202 2.21362 12.6652 2.6235 12.644 3.04197V12.958C12.6652 13.3764 12.5202 13.7863 12.2406 14.0985C11.9611 14.4106 11.5696 14.5997 11.1514 14.6246ZM4.84869 2.04197C4.60755 2.06744 4.386 2.18642 4.23158 2.37337C4.07716 2.56033 4.00216 2.80036 4.02269 3.04197V12.958C4.00216 13.1996 4.07716 13.4396 4.23158 13.6266C4.386 13.8135 4.60755 13.9325 4.84869 13.958H11.1514C11.3925 13.9325 11.6141 13.8135 11.7685 13.6266C11.9229 13.4396 11.9979 13.1996 11.9774 12.958V3.04197C11.9979 2.80036 11.9229 2.56033 11.7685 2.37337C11.6141 2.18642 11.3925 2.06744 11.1514 2.04197H4.84869Z" fill="white"/>
        </svg>
        <span css={btnStyle.textStyle}>{strapiData?.ShootYourProdText ||`Shoot your Product`}</span>
    </div> 
    {list.map((job, index) => (
        <div css={css`width:100%;`} key={index}>
            <AssetItem data={job} />
        </div>
    ))}
</>);

const btnStyle = {
    buttonBox: css`
    display: flex;
    height: 40px;
    width:100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: 0.5px dashed rgba(238, 238, 238, 0.24);
    background: rgba(238, 238, 238, 0.16);
    cursor:pointer;
    
    `,
    textStyle: css`
    text-align: center;
    font-family: Objectivity;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0.12px;
    padding-top:4px;
    `
}
const SLIDER_OPTIONS = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true, 
    arrows: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    variableWidth: true,
}

const AssetItem = ({data}) => {
    const sliderRef = React.useRef();
    const editorService = useEditorService();
    const toaster = useToast();
    const setActiveProject = useSetAtom<any>(activeProjectAtom);
    const setToAddAssetId = useSetAtom(toAddAssetIdAtom);
    const setIsShootAssetId = useSetAtom(isShootAssetIdAtom);
    const setToAddAsset = useSetAtom(toAddAssetAtom);
    const setIsEditorInitializing = useSetAtom(initializingEditorAtom);
    const {otherCategory, otherSubCategory, isLoading:isCategoriesLoading} = useOtherCategory();
    const [height,width] = [DEFAULT_CANVAS_SIZE.height * 0.33,DEFAULT_CANVAS_SIZE.width * 0.33];
    const isStandardInfographic = useAtomValue(standardInfographic);
    
    const onClickGenerator = (image,image_id) => async () => {
        if(editorService.activeEditor){
            const imageRef = await editorService.addImage(image, {height,width});
            if(!imageRef)  return;
            editorService.activeEditor.getLayerByRef(imageRef).then((layer)=>{
                layer.addTag("product-image");
                layer.addTag("product-image-id-"+image_id);
            })
            return;
        }
        try{
            setIsEditorInitializing(true);
            setToAddAsset(image);
            setToAddAssetId(image_id);
            setIsShootAssetId(true);
            const jobId = await createEmptyEditorJob(otherCategory?._id, otherSubCategory?._id, isStandardInfographic);
            const job = await getJobDetails(jobId);
            setActiveProject(job);

        }catch(err){
            setIsEditorInitializing(false);
            toaster.error("Something went wrong while adding asset to canvas");
            console.log(err)
        }
        
    }
    return (<div css={css`
        display:flex;
        flex-direction:column;
        margin: 10px 0px 0px 0px;
        width:100%;
    `}>
        <div css={css`
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:space-between;
        `}>
            <div css={css`
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 170%;
                letter-spacing: 0.01em;
                color: #EEEEEE;
            `}>
                {data?.name}
            </div>
            {data.images.length > 3 && <div>
                <button
                    css={css`
                        width: 20px;
                        height: 20px;
                        background: rgba(238, 238, 238, 0.08);
                        border-radius: 2px;
                        border:0px;
                        margin-right:4px;
                        cursor:pointer;
                    `}
                    onClick={()=>{
                        //@ts-ignore
                        sliderRef.current.slickPrev();
                    }}
                >
                    <Icon src={"/icons/chevron-left.png"} width={12} height={12} />
                </button>
                <button
                    css={css`
                        width: 20px;
                        height: 20px;
                        background: rgba(238, 238, 238, 0.08);
                        border-radius: 2px;
                        border:0px;
                        margin-right:4px;
                        cursor:pointer;
                    `}
                    onClick={()=>{
                        //@ts-ignore
                        sliderRef.current.slickNext();
                    }}
                >
                    <Icon src={"/icons/chevron-right-2.png"} width={12} height={12} />
                </button>
            </div>}
        </div>
        <div style={{marginRight:-8, marginTop:10, height:108, marginBottom:10}}>
            <Slider ref={sliderRef} {...SLIDER_OPTIONS} slidesToShow={(data.images.length < 3)?data.images.length:3}>
                {data?.images.map((image, index) => (
                    <div key={index} style={{backgroundColor:'blue', height:108}} onClick={onClickGenerator(image.dmsPNG, image.image_id)}>
                        <img crossOrigin="anonymous" src={image.dmsPNG} alt={data?.name} style={{height:108,width:108, background:'white', objectFit:'contain', marginRight:8, cursor:'pointer'}}/>
                    </div>
                ))}
            </Slider>
        </div>
        <div css={css`margin-top:4px; margin-bottom:0px; height:1px; width:100%; background-color:rgba(255, 255, 255, 0.08);`}></div>
        {/* <img src={data?.thumbnail} alt={data?.name} /> */}

    </div>)
};



export const Loader = ({dimension=24}) => (
    <div css={css`
        display:flex;
        flex:1;
        flex-direction:column;
        height: 100%;
        align-items:center;
        justify-content:center;
    `}>
        <div css={css`
            @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
            }
            width: ${dimension}px;
            height: ${dimension}px;
            border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.24);
            border-left-color: #FFFFFF;
            animation: spin 1s linear infinite;
        `}></div>
    </div>
)

const styles = {
    container : css`
        display:flex;
        flex:1;
        overflow-y:auto;
        flex-direction:column;
        padding-right:20px;
        padding-left:10px;
        border-radius: 4px;
        align-items:center;
        margin:10px;
        margin-bottom:100px;
        justify-content:center;
    `,
    title: css`
        font-family: 'Objectivity';
        color: rgba(238, 238, 238, 0.40);
        font-size: 14px;
        display: flex;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgba(238, 238, 238, 0.24);
        margin-top:78px;
    `,
    subtitle: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 170%;
        color:#fff;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.12px; 
        gap:5px;
        text-decoration:underline;
        margin-top:20px;
        cursor:pointer;
    `,
    getAppIcon:{paddingBottom:'2px'},
}