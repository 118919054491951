import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

import templateReducer from "./reducers/template.reducer";
import editorReducer from "./reducers/editor.reducer";
import fileUploadReducer from "./reducers/file-upload.reducer";
import notificationsReducer from "./reducers/notification.reducer";
import jobsReducer from "./reducers/jobs.reducer";
import strapiReducer from "./reducers/strapi.reducer";
import settingReducer from "./reducers/settings.reducer";
import modifyImageReducer from "./reducers/modifyImage.reducer";
import appRedirectReducer from "./reducers/appRedirect.reducer";
import qcBoardReducer from "./reducers/qcboard.reducer";
import myProjectReducer from "./reducers/myProject.reducer";
// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const editorPersistConfig = {
  key: "edit",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const fileUploadPersistConfig = {
  key: "fileUpload",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const notificationsPersistConfig = {
  key: "notifications",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const templatePersistConfig = {
  key: "template",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "templates",
    "filterOptions",
    "searchStrings",
    "filters",
    "sortBy",
  ],
};

const myProjectPersistConfig = {
  key: "template",
  storage,
  keyPrefix: "redux-",
};

const jobsPersistConfig = {
  key: "jobs",
  storage,
  keyPrefix: "redux-",
  whitelist: ["images"],
};

const strapiConfig = {
  key: "strapi",
  storage,
  keyPrefix: "redux-",
  whitelist: ["profileScreen"],
};

const settingConfig = {
  key: "setting",
  storage,
  keyPrefix: "redux-",
};

const modifyImageConfig = {
  key: "modify",
  storage,
  keyPrefix: "redux-",
};

const qcboardConfig = {
  key: "qcboard",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const appRedirectConfig = {
  key: "appRedirect",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  template: persistReducer(templatePersistConfig, templateReducer),
  edit: persistReducer(editorPersistConfig, editorReducer),
  jobs: persistReducer(jobsPersistConfig, jobsReducer),
  strapi: persistReducer(strapiConfig, strapiReducer),
  setting: persistReducer(settingConfig, settingReducer),
  fileUpload: persistReducer(fileUploadPersistConfig, fileUploadReducer),
  notifications: persistReducer(
    notificationsPersistConfig,
    notificationsReducer
  ),
  modify: persistReducer(modifyImageConfig, modifyImageReducer),
  qcboard: persistReducer(qcboardConfig, qcBoardReducer),
  appRedirect: persistReducer(appRedirectConfig, appRedirectReducer),
  myProject:persistReducer(myProjectPersistConfig,myProjectReducer),
});

export { rootPersistConfig, rootReducer };
