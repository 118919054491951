import AIBackgroundResultsHeader from "./AIBackgroundResultsHeader"
import styles from "./AIBackgroundResults.module.css";
import useEditorService from "@/hooks/useEditorService";
import { generateBackgrounds } from "../../services/AIBackgrounds";
import GenerateImageIcon from "./GenerateImageIcon.svg"
import Loader from './loader.svg';
import { createNewTemplate } from "../../services/AIBackgrounds";
import useToast from "@/hooks/useToast";
import { useMemo, useEffect,useState, useCallback } from "react";
import { useSelector } from "react-redux";
import useAuth from "@/hooks/useAuth";
import {backgroundImageAtom} from "src/modules/editor-v2/Atoms";
import { useAtomValue } from "jotai";
import { Skeleton } from "@mui/material";
import {css} from "@emotion/react";

const AI_TEMPLATE = process?.env?.NEXT_PUBLIC_AI_BACKGROUNDS_TEMPLATE;
const WHITE_TEMPLATE = process?.env?.NEXT_PUBLIC_WHITE_TEMPLATE

export default function AIBackgroundResults({urls=[], setGenerating, setUrls, prompt="", s3Url, setPrompt=(value)=>{}, generating=false, setAttributePage, productImage}) {
    
    const backgroundImage = useAtomValue(backgroundImageAtom);
    const {setBackground, activeEditor} = useEditorService();
    const toast = useToast();
    const strapiText = useSelector((state:any)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const loaderStrapiData = useSelector((state:any)=> state.strapi.entireStrapiData?.WebListingScreen);
    const {user} = useAuth();
    const [onLoad, setLoad] = useState(false);
    const {height, width} = useMemo(()=>{
        const aspectRatio = activeEditor?.originalSize.height / activeEditor?.originalSize.width || 1;
        return {height: 170 * aspectRatio, width: 170};
    },[activeEditor]);

    useEffect(()=>{
        if(!onLoad && !urls?.[0]?.loading && urls?.[0]?.url)
        {
            setLoad(true)
            if(backgroundImage) return;
            onClick(urls?.[0]?.url)
        }
    },[urls])

    
    const onClick = (url) => {
        setBackground(url, AI_TEMPLATE);
        createNewTemplate(url, AI_TEMPLATE).then((res:any)=>{
            // if(res?.editorThumbnail && res?._id) setBackground(res.editorThumbnail,res._id)
        }).catch(console.error);
    };

    const generateMore = async () => {
        if(generating) return;
        if(!prompt) return toast.error("Please enter prompt!",3000);
        setGenerating(true);
        let newUrls = Array(4).fill(0).map(x=>({url:"",loading:true}));
        const oldUrls = urls
        const _urls = [...newUrls,...oldUrls,];
        setUrls(_urls);
        newUrls = (await generateBackgrounds(s3Url,prompt, user?.entity?.entity?._id || "")).map(x=>({url:x.url,loading:false}));
        setUrls([...newUrls,...oldUrls]);
        setGenerating(false);
    }

    const onPromptChange = ({target}) => {
        setPrompt(target?.value || "");
    }

    const generateImageStyle = (url) => ({
        height: "100%",
        width: "100%",
        backgroundImage: `url('${url}')`,
        backgroundSize: 'contain',
        border: backgroundImage == url && "3px solid black",
        borderRadius: "4px"
      });
    
    const handleKeyDown = (event) => {
        // Check for Ctrl+Shift+V and prevent it
        if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'v') {
          event.preventDefault();
          navigator.clipboard.readText().then((text) => {
            // Set the input state with the pasted content
            setPrompt(prev=> prev.length ? `${prev}${text}` : text);
          });
        }
    };
      
    return <>
        <AIBackgroundResultsHeader setGenerating={setGenerating} urls={urls} setAttributePage={setAttributePage}/>
        <div className={styles.resultContainer} style={{ opacity: (urls.length && !urls?.[0]?.loading ) ? 1 : 0.5, pointerEvents: (urls.length && !urls?.[0]?.loading )  ? 'auto' : 'none', cursor: (urls.length && !urls?.[0]?.loading ) ? "pointer" :"aut" }}>
            <div style={{ cursor: generating ? "not-allowed" : "pointer"}}>
                <div style={{ position: 'relative' }}>
                    {
                        !urls.length ? (
                            <div className={styles.prompt} style={localStyles.promptLoaderContainer}>
                                <Skeleton animation="wave"width={'90%'} height={20} style={localStyles.skeletonTextLoader}/>
                                <Skeleton animation="wave"width={'90%'} height={20} style={localStyles.skeletonTextLoader}/>
                                <Skeleton animation="wave"width={'90%'} height={20} style={localStyles.skeletonTextLoader}/>
                            </div>
                        )
                        :
                        <textarea className={styles.prompt} value={prompt} onChange={onPromptChange} rows={4} onKeyDown={handleKeyDown}/>
                    }
                    <img src="/icons/pencil-white.svg" alt="Image" className={styles.pencil} onClick={()=>{
                    setGenerating(false);
                    setAttributePage?.(true);
                }}
                 />
                </div>
                 <div className={`${styles.generateButton} ${(generating)?styles.generateButtonLoading:""}`} style={{ cursor: generating ? "not-allowed" : "pointer"}} onClick={generateMore}>
                    <GenerateImageIcon />
                    {strapiText?.GenerateFourMoreButton || "Generate 4 More"}
                </div>
            </div>
            <div className={styles.scrollContainer}>
            <div className={styles.row} style={{cursor: "pointer"}}>
                {
                !urls.length && Array.from({ length: 4 }).map((_, i) => (
                    <div 
                        key={i} 
                        css={localStyles.imagesLoaderContainer}  
                        style={{ backgroundImage:`url(${productImage?.originalSrc})`, height,width, ...localStyles.extraImagesLoaderStyle}}>
                        <div css={localStyles.starsGif}>
                            <img src={loaderStrapiData?.StarsAnimationGify || '/icons/Stars.gif'} alt="Loading..."/>
                        </div>
                    </div>
                ))
                }
                {urls?.map(x=>
                <div className={`${backgroundImage == x?.url && !x?.loading && styles.imageView}`} style={{height,width}}>
                    {x?.loading && 
                        <div  
                            css={localStyles.singleImageLoaderContainer}  
                            style={{backgroundImage:`url(${productImage?.originalSrc})`, height, width, ...localStyles.extraSingleImageLoaderStyle}}>
                            <div css={localStyles.starsGif}>
                                <img src={loaderStrapiData?.StarsAnimationGify || '/icons/Stars.gif'} alt="Loading..."/>
                            </div>
                        </div>
                    }
                    {x?.url && <div className={styles.imageView} onClick={(event)=>{event.stopPropagation(); onClick(x?.url)}} style={generateImageStyle(x?.url)}></div>}
                </div>)}
            </div>
            </div>
        </div>
    </>
}

const localStyles = {
    imagesLoaderContainer:css`
        position: relative;
        padding:4px;
        width: 100%;
        aspect-ratio: 1;
        background-size: contain;
        display: flex;
        flex-wrap: wrap;
        gap:8px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        border: 1px solid #FFFFFF;
        background: linear-gradient(90.7deg, #DEDEDE 0.78%, #C8C8C8 48.33%, #E0E0E0 98.95%);
        opacity: 0.6;
    `,
    extraImagesLoaderStyle:{
        backgroundSize: "contain", 
        backgroundRepeat:'no-repeat',
        backgroundPosition: "center", 
        padding:8
    },
    starsGif:css`
        position: absolute;
        height:100%;
        object-fit: contain;
        width:100%;
    `,
    singleImageLoaderContainer:css`
        position: relative;
        width: 32px;
        margin-top:2px;
        aspect-ratio: 1;
        background-size: contain;
        display: flex;
        border-radius: 4px;
        border: 1px solid #FFF;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90.7deg, #DEDEDE 0.78%, #C8C8C8 48.33%, #E0E0E0 98.95%);
        align-items: center;
        opacity: 0.6;
    `,
    extraSingleImageLoaderStyle:{
        backgroundSize: "contain", 
        backgroundRepeat:'no-repeat',
        backgroundPosition: "center", 
        padding:8
    },
    promptLoaderContainer:{
        padding:'0px 12px', 
        "flex-direction":'column'
    },
    skeletonTextLoader:{
        background: 'linear-gradient(90.7deg, #DEDEDE 0.78%, #C8C8C8 48.33%, #E0E0E0 98.95%)', 
        marginBottom:6
    }
}

