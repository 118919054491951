import InfoButton from "@/modules/ui-elements/InfoButton";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import useResetAtomHandler from "@/modules/editor-v2/resetAtomHandler";

export default function PromptGeneratorHeader({setIsAIBackgrounds, displaytext}) {

    const strapiText = useSelector((state)=> state?.strapi?.entireStrapiData?.AIBackground?.AIBackground);
    const {resetPromptHandler} = useResetAtomHandler();
    
    return <div css={styles.container}>
        <div css={styles.topRow}>
            <div style={{display:"flex", color:'white', alignItems:'center'}}>
                <div css={styles.backButton} onClick={()=>{
                    setIsAIBackgrounds(false);
                    resetPromptHandler();
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.0002 6L9.00037 11.9999L15.0002 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div css={styles.textContainer}>{displaytext}</div>
            </div>
        </div>
        <div css={styles.divider}></div>
    </div>
}

const styles = {
    container: css`
        position:relative;
        padding-bottom: 10px;
        margin-bottom: 10px
    `,
    textContainer: css`
        color: #FFF;
        margin-bottom: -2px;
        font-family: Objectivity-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%; /* 23.8px */
        letter-spacing: 0.14px;
        display: flex;
        align-items: center;
    `,
    topRow: css`
        flex-direction:row;
        display:flex;
        justify-content:space-between;
        align-items:center;
    `,
    divider: css`
        position: absolute;
        width: 105.5%;
        height: 0.5px;
        bottom: 0px;
        background:rgba(255, 255, 255, 0.24);
        left: -10px;
        right: 10px;
    `,
    backButton: css`
        display: flex;
        width: 32px;
        height: 32px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.16);
        margin-right:16px;
        cursor: pointer;
    `
}
