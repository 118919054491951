
import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import typography from "src/theme/typography";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import useAuth from "src/hooks/useAuth";
import { useRouter } from "next/router";
import { getMyPaymentHistory } from "src/redux/reducers/jobs.reducer";
import API_SERVICE from "src/utils/axios";
import CircularLoader from "src/components/loader/circular";
import { setCurrentSelectedTab } from 'src/redux/reducers/settings.reducer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const cardStyle = {
  padding: '9px', margin: '8px 72px 8px', height: '50px', width: "313px", borderRadius: '8px', fontSize: "11px", background: "rgba(255, 255, 255, 0.5)"
}
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0, fontFamily: typography.fontFamily }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: "absolute",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.common.black,
            },
            backgroundColor: (theme) => theme.palette.common.black,
            right: 8,
            top: 8,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Referal = ({ showReferal, onCloseReferal }) => {
  const user = useAuth();
  const { push } = useRouter();
  const dispatch = useDispatch();
  const textFieldRef = useRef(null);
  const [coupon, setCoupon] = useState('')
  const [share, setShare] = useState(false)
  const [copied, setCopied] = useState(false);
  const [couponLoading, setcouponLoading] = useState(false);
  const [openInfo, setOpenInfo] = useState(false)
  const { entireStrapiData } = useSelector((state) => state.strapi)
  const referalData = entireStrapiData?.NewReferEarnWebapp?.NewReferEarnWebapp
  const { paymentHistory } = useSelector((state) => state.jobs);
  const GENERATECOUPONURL = `/v4/coupon/createCouponApp`;
  const handleCloseReferal = () => {
    onCloseReferal();
    setCopied(false);
    setCoupon('');
    setShare(false);
  };
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  useEffect(() => {
    if (user?.user?.entity?.entity?._id) {
      const params = {
        assigned_user_id: user?.user?.entity?.entity?._id,
        status: "ALL",
      };
      if (!paymentHistory || Object.keys(paymentHistory).length === 0) {
        dispatch(
          getMyPaymentHistory(user?.user?.entity?.entity?._id, params, 0, 20)
        );
      }
    }
  }, [user]);

  const generateReferalcode = () => {
    return API_SERVICE.post(GENERATECOUPONURL);
  };

  useEffect(() => {
    if (coupon) {
      setShare(true);
      setcouponLoading(false);
    }
  }, [coupon]);

  const checkTransactionHistory = async () => {
    if (paymentHistory?.allTransactionCount) {
      setcouponLoading(true);
      const reply = await generateReferalcode();
      setCoupon(reply?.data || '');
      setcouponLoading(false);
    }
    else {
      setOpenInfo(true)
    }
  }

  const copyToClipboard = () => {
    const textFieldValue = textFieldRef.current.value;
    navigator.clipboard.writeText(textFieldValue)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  return (
    <BootstrapDialog
      onClose={handleCloseReferal}
      aria-labelledby="customized-dialog-title"
      open={showReferal}
      maxWidth="sm"
      PaperProps={{
        style: {
          position: "relative",
          width: "490px",
          height: "660px",
          borderRadius: "8px"
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseReferal}
        sx={{
          textAlign: 'center', fontSize: 14, color: '#753C95',
          '& button': {
            color: 'grey',
            backgroundColor: "white",
            fontSize: 10,
          },
        }}
      >
        <div>{referalData?.RnEHeading}</div><img style={{ display: "initial", height: "260px" }} src={referalData?.ReferNEarnPopUp?.BannerImage} /> </BootstrapDialogTitle>

      <DialogContent dividers
        sx={{
          pt: 0,
          color: '#262626',
          background: "linear-gradient(116.1deg, #FFF2F2 0%, #FFE9E2 100%)",
        }}>
        <div style={{ textAlign: "center" }}>{referalData?.ReferNEarnPopUp?.HowItWorksText}</div>
        {referalData?.ReferEarnStepList && referalData?.ReferEarnStepList.map((data) => (
          <Card style={cardStyle} key={data.Number}>
            <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: 'inline-block', position: 'relative' }}>
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: 'black' }}>{data.Number}</span>
                </div>
              </div>
              <div style={{ height: "24px", width: "24px", marginRight: "10px" }}>
                <CardMedia component="img" image={data.StepIcon} />
              </div>
              {data.StepText}
            </div>
          </Card>
        ))}
        <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "center", padding: "10px", letterSpacing: "1%"}}>
          <img style={{ height: "15px", width: "15px", marginRight: "10px" }} src={referalData?.ReferNEarnPopUp?.BlackInfoIcon} onClick={handleOpenInfo} />
          {referalData?.ReferNEarnPopUp?.YouAreEligibleText}
        
          <BootstrapDialog
            onClose={handleCloseInfo}
            aria-labelledby="customized-dialog-title"
            open={openInfo}
            maxWidth="sm"
            PaperProps={{
              style: {
                position: "relative",
                borderRadius: "8px",
                width: "350px",
                height: "254px",
              },
            }}
          >
            <BootstrapDialogTitle
              onClose={handleCloseInfo}
              sx={{
                textAlign: 'center', fontSize: 14,
                '& button': {
                  color: 'grey',
                  backgroundColor: "white",
                  fontSize: 10,
                },
              }}
            >
            </BootstrapDialogTitle>

            <Typography sx={{ display: "flex", alignItems: "center", fontSize: 12 }}>
              <img
                style={{
                  position: 'absolute',
                  width: '40px',
                    height: '40px',
                    left: '157px',
                    top: '32px',
                }}
                src={referalData?.RechargeReferEarn?.CreditIcon}
              />
            </Typography>
            <DialogContent  sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              letterSpacing: '0.01em',
            }}>
              {referalData?.RechargeReferEarn?.RechargeCreditsText}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '14px 16px',
                  gap: '10px',
                  position: 'absolute',
                  width: '318px',
                  height: '46px',
                  left: '16px',
                  top: '184px',
                  fontSize: "14px",
                  background: '#262626',
                  borderRadius: '1000px',
                  color: "white"
                }}
                onClick={() => {push("/dashboard/setting?tab=PAY_PER_USE");  dispatch(setCurrentSelectedTab("PAY_PER_USE")); onCloseReferal() ; setOpenInfo(false)}}
      
      >
                {referalData?.RechargeReferEarn?.RechargeNowCTA}
              </Box>
            </DialogContent>

          </BootstrapDialog>
        </div>
        {share ? (
          <Card style={{ ...cardStyle, background: "white", padding: "8px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                <input type="text" value={coupon} style={{ border: "none",width: "94%" }} ref={textFieldRef} readOnly disabled />
              </div>
              <button onClick={copyToClipboard} disabled={copied} style={{ width: "84px", height: "34px", border: "none", color: "white", borderRadius: "4px", fontSize: "12px", background: "linear-gradient(92.16deg, #F03750 -5.44%, #EB2E83 121.84%)" }}>
                <CardMedia component="img" image={copied ? (referalData?.ReferNEarnPopUp?.copiedIcon) : (referalData?.ReferNEarnPopUp?.copyIcon)} />
                {copied ? (referalData?.ReferNEarnPopUp?.CopiedText) : (referalData?.ReferNEarnPopUp?.CopyText)}
              </button>
            </div>
          </Card>

        ) : (
          <Button style={{ ...cardStyle, background: "linear-gradient(91.85deg, #F03750 -3.7%, #EB2E83 105.3%)" }} onClick={checkTransactionHistory}>
           {!couponLoading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
              <div style={{ height: "15px", width: "15px", marginRight: "10px" }}>
                <CardMedia component="img" image={referalData?.ReferNEarnPopUp?.ShareIcon} />
              </div>
              <div style={{ color: "#FFFFFF" }}>
                {referalData?.ReferNEarnPopUp?.ShareInviteLink} 
              </div>  
            </div> :
              <div style={{ color: "#FFFFFF", display: "flex", alignItems: "center" }}>
                <CircularLoader thickness="6" color="inherit"/>
              </div> }
          </Button>
        )}


      </DialogContent>
    </BootstrapDialog>
  );
};

export default Referal;
