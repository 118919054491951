// import useLocales from "src/hooks/useLocales";
import { completeStrapiData } from "src/strapiData";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const DELETE_ICON = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzc2MV84ODA2KSI+CjxyZWN0IHk9IjAuMjUiIHdpZHRoPSIxMi43NSIgaGVpZ2h0PSIxMi43NSIgcng9IjYuMzc1IiBmaWxsPSIjMjYyNjI2Ii8+CjxwYXRoIGQ9Ik05Ljc1MzA5IDEwLjc1ODJMNi4zNzQzNCA3LjM3NDEzTDIuOTk1NTkgMTAuNzU4MkwyLjI0MTIxIDEwLjAwMzhMNS42MjUyNyA2LjYyNTA3TDIuMjQxMjEgMy4yNDYzMkwyLjk5NTU5IDIuNDkxOTRMNi4zNzQzNCA1Ljg3NjAxTDkuNzUzMDkgMi40OTcyNkwxMC41MDIxIDMuMjQ2MzJMNy4xMjM0IDYuNjI1MDdMMTAuNTAyMSAxMC4wMDM4TDkuNzUzMDkgMTAuNzU4MloiIGZpbGw9IiNFRUVFRUUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF83NjFfODgwNiI+CjxyZWN0IHk9IjAuMjUiIHdpZHRoPSIxMi43NSIgaGVpZ2h0PSIxMi43NSIgcng9IjYuMzc1IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=";

export const CONNECTED_MARKETPLACES = [
  {
    label: "Channel Advisor",
    value: "CHANNEL_ADVISOR",
  },
];

export const CSV_STEPS = {
  CSV_STEP_1 : "CSV_STEP_1",
  CSV_STEP_2 : "CSV_STEP_2",
  CSV_STEP_3 : "CSV_STEP_3"
}
export const FOLDER_SIZE_LIMIT = 100;

export const FILE_UPLOAD_FORMATS = [
  "image/jpeg",
  "image/bmp",
  "image/JFIF",
  "image/jpg",
  "image/cr2",
  "image/png",
  "image/JPEG",
  "image/JPG",
  "image/PNG",
  "image/PSD",
  "image/TIFF",
  "image/NEF",
  "image/RAF",
  "image/CR2",
  "image/DNG",
  "image/ARW",
  "image/CRD",
  "image/gif",
];

export const ERROR_MESSAGES = {
  SELECT_ATLEAST_ONE_IMAGE: "Please select atleast one image!",
  PLEASE_SELECT_TEMPLATE: "Please select template",
  PLEASE_SELECT_IMAGE: "Please select image",
  TEMPLATE_ALREADY_SELECTED: "Template already selected",
  SOME_THING_WENT_WRONG: "Something went wrong, please try again.",
  FOLDER_SIZE_LIMIT_EXCEEDED: "Please select folder of size less than 100 MB.",
  FILE_FORMAT_NOT_SUPPORTED: "Folder contains file formats not supported.",
  FOLDER_IS_EMPTY: "Folder is empty.",
  UNABLE_TO_PROCESS: "Unable to process your request.",
};

export const SUCCESS_MESSAGE = {
  COPIED_LINKS_TO_CLIPBOARD: "Copied zip file link to clipboard.",
  FILE_DOWNLOADED: "File downloaded successfully",
  FILE_DOWNLOADED_CSV: "File downloaded successfully",
};

export const MY_PROJECT_FILTER = [
  {
    label:"Status",
    value:"STATUS",
    strapiId:"Status"
  },
  {
    label:"Product Category",
    value:"PRODUCT_CATEGORY",
    strapiId:"ProductCategory"
  }
];

export const  MY_PROJECT_COMPLETE_STATUS = [
  // {
  //   label:"Uploaded",
  //   value:"Uploaded",
  //   src:"/icons/myWork/icon/log-out.svg",
  //   strapiId:"Uploaded"
  // },
  {
    label:"Download",
    value:"Download",
    src:"/icons/myWork/icon/completed-shoot.svg",
    strapiId:"Download"
  },
];

export const  MY_PROJECT_ACTION_REQUIRED_STATUS = [
  {
    label:"Saved",
    value:"Incomplete",
    src:"/icons/myWork/icon/log-out.svg"
  },
  {
    label:"Pending Approval",
    value:"IncompleteApproval",
    src:"/icons/myWork/icon/completed-shoot.svg"
  },
  {
    label:"Uploading Failed",
    value:"UPLOADING_FAILED",
    src:"/icons/myWork/icon/log-out.svg"
  },
  {
    label:"Payment Due",
    value:"Payment Due",
    src:"/icons/myWork/icon/completed-shoot.svg"
  },
  {
    label:"Archived",
    value:"Archive",
    src:"/icons/myWork/icon/log-out.svg"
  },
  {
    label:"Rejected",
    value:"Rejected Closed",
    src:"/icons/myWork/icon/completed-shoot.svg"
  },
];

export const  MY_PROJECT_IN_PROCESS_STATUS = [
  {
    label:"In process",
    value:"In Production",
    src:"/icons/myWork/icon/log-out.svg"
  }
];

// export const MY_PROJECT_TABS = [
//   {
//     label: 'Completed',
//     value: 'COMPLETED',
//     statusType:MY_PROJECT_COMPLETE_STATUS,
//     visible: true,
//   },
//   {
//     label:'Action Required',
//     value:'ACTION_REQUIRED',
//     statusType:MY_PROJECT_ACTION_REQUIRED_STATUS,
//     visible:true
//   },
//   {
//     label: 'In Process',
//     value: 'IN_PROCESS',
//     statusType:MY_PROJECT_IN_PROCESS_STATUS,
//     visible: true,
//   }
// ];

export const useMyProjectTabs = () => {
  const {entireStrapiData} = useSelector((state)=> state.strapi)
  const strapiData = entireStrapiData?.MyProjectFrontEnd?.MyProjectFrontEnd;
  const defaultStrapiData = completeStrapiData?.MyProjectFrontEnd?.MyProjectFrontEnd;  

  const MY_PROJECT_TABS = useMemo(() => [
    {
      label: strapiData?.CompletedText || defaultStrapiData?.CompletedText,
      value: 'COMPLETED',
      payloadValue: 'COMPLETED',
      statusType: MY_PROJECT_COMPLETE_STATUS,
      visible: true,
    },
    {
      label: strapiData?.ActionRequired || defaultStrapiData?.ActionRequired,
      value: 'ACTION_REQUIRED',
      payloadValue: 'ACTION REQUIRED',
      statusType: MY_PROJECT_ACTION_REQUIRED_STATUS,
      visible: true,
    },
    {
      label: strapiData?.InProcessText || defaultStrapiData?.InProcessText,
      value: 'IN_PROCESS',
      payloadValue: 'IN PROCESS',
      statusType: MY_PROJECT_IN_PROCESS_STATUS,
      visible: true,
    },
  ], [strapiData, defaultStrapiData])
  return MY_PROJECT_TABS;
};

export const MY_WORK_STATUS = {
  DOWNLOAD: "Download",
  IN_PROCESS: "In Process",
  COMPLETED: "Completed",
};

export const MY_WORK_CTA_STATUS_MAPPING = {
  TRACK: "Track",
  VIEWDETAILS: "View Details",
  PLEASERESHOOT: "Please Reshoot",
  COMPLETESHOOT: "Complete Shoot",
  VIEW:"View Details",
  PAYMENT_DUE:"Payment Due"
};

export const CANVAS_MODES = {
  ACTIVE: "active",
  IN_ACTIVE: "in-active",
  EDIT: "edit",
  SAVE: "save",
  INIT: "init",
};

export const CANVAS_EDIT_ACTIONS = {
  SET_ACTIVE: "SET_ACTIVE",
  SET_IN_ACTIVE: "SET_IN_ACTIVE",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  SAVE: "SAVE",
  RESET: "RESET",
  UPDATE_CANVAS_CORDINATES: "UPDATE_CANVAS_CORDINATES",
  UPDATE_JOB_IMAGE_CANVAS_COORDINATES: "UPDATE_JOB_IMAGE_CANVAS_COORDINATES",
  UPDATE_CUSTOM_WIDTH: "UPDATE_CUSTOM_WIDTH",
  UPDATE_CUSTOM_HEIGHT: "UPDATE_CUSTOM_HEIGHT",
  UPDATE_TYPE: "UPDATE_TYPE",
};

export const CANVAS_EDIT_TYPE = {
  CUSTOM: "CUSTOM",
  INSTAGRAM_SQUARE: "INSTAGRAM_SQUARE",
  INSTAGRAM_PORTRAIT: "INSTAGRAM_PORTRAIT",
  INSTAGRAM_LANDSCAPE: "INSTAGRAM_LANDSCAPE",
  FACEBOOK_POST: "FACEBOOK_POST",
  FACEBOOK_STORIES: "FACEBOOK_STORIES",
  EDIT: "EDIT_JOB_IMAGE",
};

export const CROP_IMAGE_EDIT_TYPES = [
  CANVAS_EDIT_TYPE.CUSTOM,
  CANVAS_EDIT_TYPE.INSTAGRAM_LANDSCAPE,
  CANVAS_EDIT_TYPE.INSTAGRAM_PORTRAIT,
  CANVAS_EDIT_TYPE.INSTAGRAM_SQUARE,
  CANVAS_EDIT_TYPE.FACEBOOK_POST,
  CANVAS_EDIT_TYPE.FACEBOOK_STORIES,
];

export const CANVAS_EDIT_SIZE_MAPPING = {
  CUSTOM: { width: 400, height: 400 },
  INSTAGRAM_SQUARE: { width: 1080, height: 1080 },
  INSTAGRAM_PORTRAIT: { width: 1080, height: 1350 },
  INSTAGRAM_LANDSCAPE: { width: 1080, height: 566 },
  FACEBOOK_POST: { width: 1200, height: 566 },
  FACEBOOK_STORIES: { width: 1080, height: 1920 },
};

export const CANVAS_EDIT_LABEL_MAPPING = {
  CUSTOM: "Custom size (px)",
  INSTAGRAM_SQUARE: "Size (1:1)",
  INSTAGRAM_PORTRAIT: "Size (4:5)",
  INSTAGRAM_LANDSCAPE: "Size (1.91:1)",
  FACEBOOK_POST: "Size (1.91:1)",
  FACEBOOK_STORIES: "Size (9:16)",
};

export const NOTIFICATION_TYPE = {
  FOLDER_UPLOADING: "FOLDER_UPLOADING",
  FOLDER_UPLOAD_DONE: "FOLDER_UPLOAD_DONE",
  IMAGE_UPLOADING: "IMAGE_UPLOADING",
  IMAGE_UPLOAD_DONE: "IMAGE_UPLOAD_DONE",
};

export const UPLOAD_STATUS = {
  INIT: "INIT",
  FILE_UPLOADING: "FILE_UPLOADING",
  FILE_UPLOAD_DONE: "FILE_UPLOAD_DONE",
  FILE_UPLOAD_FAILED: "FILE_UPLOAD_FAILED",
  FOLDER_UPLOADING: "FOLDER_UPLOADING",
  FOLDER_UPLOAD_DONE: "FOLDER_UPLOAD_DONE",
  PROJECT_CREATE_IN_PROGRESS: "PROJECT_CREATE_IN_PROGRESS",
  PROJECT_CREATE_IN_DONE: "PROJECT_CREATE_IN_DONE",
  PROJECT_CREATE_FAILED: "PROJECT_CREATE_FAILED",
};
export const STEPS = {
  INIT: "init",
  FILE_UPLOAD: "file-upload",
  FILE_UPLOAD_STEP_2: "file-upload-step-2",
  FILE_UPLOAD_STEP_3: "file-upload-step-3",
  FOLDER_UPLOAD: "folder-upload",
  FOLDER_UPLOAD_STEP_2: "folder-upload-step-2",
  FOLDER_UPLOAD_STEP_3: "folder-upload-step-3",
  LINKED_ACCOUNT: "linked-account",
  LINKED_ACCOUNT_STEP_2:"linked-account-step-2",
  LINKED_ACCOUNT_STEP_3:"linked-account-step-3",
  LINKED_ACCOUNT_PRESET_STEP:"linked-account-preset-step",
  LINKED_ACCOUNT_STEP_4:"linked-account-step-4",
  GOOGLE_DRIVE: "google-drive",
  GOOGLE_DRIVE_STEP_2: "google-drive-step-2",
  GOOGLE_DRIVE_STEP_3: "google-drive-step-3",
  GOOGLE_DRIVE_STEP_4: "google-drive-step-4",
  GOOGLE_DRIVE_STEP_5: "google-drive-step-5",
  CSV_FILE: "csv-file",
  CSV_FILE_STEP_2: "csv-file-step-2",
};

export const STICKERS=[
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR006_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR019_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR020_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR021_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR022_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR023_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR031_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR033_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR041_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR049_presp.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR053_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR057_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR058_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR067_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR070_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR072_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR074_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR078_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR083_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR086_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR092_SALE.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR095_BIGSALEGERMAN.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR102_SPRINGSALEFRENCH.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR110_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR111_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR137_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR138_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR142_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR150_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR154_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR158_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR178_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR179_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR182_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR184_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR185_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR190_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR192_front.png", alt: ""},
  { src:"https://dresma-assets.s3.us-east-2.amazonaws.com/stickers/MR197_front.png", alt: ""},
]