import Image from "next/legacy/image"

export const ICONS = {
    chevronLeft:"/icons/editor-chevron-left.svg",
    chevronLeftDark:"/icons/chevron-left-dark.svg",
    crown:"/icons/ant-design_crown-filled.svg",
    bell:"/icons/Notification.svg",
    helpCircle:"/icons/help-circle.svg",
    folder:"/icons/Books/folder-open.svg",
    templates:"/icons/editor-templates.svg",
    resize: "/icons/bi_crop.svg",
    background:"/icons/Background.svg",
    text:"/icons/Text.svg",
    elements:"/icons/Elements.svg",
    brandKit:"/icons/Brand kit.svg",
    info:"/icons/temp info.svg",
    chevronDown:"/icons/chevron-down.svg",
    upload:"/icons/editor-upload.svg",
    search:"/icons/carbon_search.svg",
    searchLight:"/icons/search-light.svg",
    chevronRight:"/icons/chevron-right.png",
    chevronRightDark:"/icons/chevron-right-dark.svg",
    editTwo:"/icons/Edit/edit-two.png",
    folderUpload:"/icons/folderupload.png",
    helpCircleFooterToolbar:"/icons/help-circle.png",
    config:"/icons/Base/config.png",
    message:"/icons/Communicate/message.png",
    zoomOut:"/icons/Base/zoom-out.png",
    zoomIn:"/icons/Base/zoom-in.png",
    zoomOutDark:"/icons/Base/zoom-out-dark.svg",
    zoomInDark:"/icons/Base/zoom-in-dark.svg",
    scale:"/icons/Edit/scale.png",
    scaleDark:"/icons/Edit/scale-dark.svg",
    maximize:"/icons/maximize.png",
    myWork:"/icons/my-work.png",
    searchLight:"/icons/search.png",
    plus:"/icons/plus.png",
    rightPaneChevronsRight:"/icons/TextSidebar/chevrons-right.png",
    rightPaneUndo:"/icons/TextSidebar/carbon_undo.png",
    rightPaneRedo:"/icons/TextSidebar/carbon_undo-1.png",
    rightPaneUnlock:"/icons/TextSidebar/Lock.png",
    unlock:"/icons/TextSidebar/unlock.svg",
    rightPaneDelete:"/icons/TextSidebar/Delete.png",
    plusDark: "/icons/TextSidebar/plus-1.png",
    minusDark: "/icons/TextSidebar/plus.png",
    aiGenerate:"/icons/ai_generate.svg",
    playstore:"/icons/playstore.png",
    chevronRightVoilet:"/icons/chevron-right-voilet.svg",
    chevronLeftDark:"/icons/chevron-left-dark.svg",
    chevronRightDark:"/icons/chevron-right-dark.svg",
    backgroundVoilet:"/icons/background-voilet.svg",
    settingVoilet:"/icons/setting-config-voilet.svg",
    freezingVoilet:"/icons/freezing-line-column-voilet.svg",
    biStarVoilet:"/icons/bi_stars.svg",
    biStarWhite:'/icons/bi_stars_white.svg',
    invertCamera:"/icons/invert-camera.svg",
    frameLoader:"/icons/frame-loader.svg",
    framePortraitLoader:"/icons/frame-portrait-loader.svg",
    frameLandscapeLoader:"/icons/frame-landscape-loader.svg",
    redHeart:'/icons/red-heart.png',
    crossIcon:'/icons/cross-dark.svg',
    textureVoilet:'/icons/texture-voilet.svg',
    textureDark:'/icons/texture-dark.svg',
    dummyTexture:'/icons/dummy-wallpaper.svg',
    resetDark:'/icons/reset-dark.svg',
    arrowDownDark:'/icons/arrow-down-dark.svg',
    whiteLeftArrow: '/icons/white-left-arrow.svg',
    whiteRightArrow: '/icons/white-right-arrow.svg',
    magnify: "/icons/magnify.svg",
    customPreset: '/icons/custom-preset.svg',
    coverage: '/icons/coverage.svg',
    centerAlign: '/icons/TextSidebar/Center align.png',
    bottomAlign: '/icons/TextSidebar/Bottom position.png',
    topAlign: '/icons/TextSidebar/Top position.png',
    tickIcon: '/icons/TextSidebar/tick.svg',
    pinkHome:'/icons/pink-home.svg',
    homeDark:'/icons/Home.svg',
    settingDark:'/icons/Settings.svg',
    myWorkDark:'/icons/my-work.svg',
    accountDark:'/icons/account-logo.svg',
    chevronDownDark: '/icons/settings/chevon-down-dark.svg',
    colorAdjustment: '/icons/colorAdjustment.svg',
    radioOn:  '/icons/radio-on.svg',
    radioOff: '/icons/radio-off.svg',
    appleDark:'/icons/apple-dark.svg',
    googleDark:'/icons/google-dark.svg',
    phoneDark:'/icons/mobile-dark.svg',
    microsoftDark:'/icons/microsoft-dark.png',
    microsoftColorLogo:'/icons/Microsoft_color_logo.svg',
    emailDark:'/icons/email-dark.svg',
    partyPopper:'/icons/party-popper.svg',
    whiteTick:'/icons/white-tick.svg',
    okGreen:'/icons/okgreen.svg',
    touchPink:'/icons/touch-pink.svg',
    giftVoilet:'/icons/gift-voilet.svg',
    giftGreen:'/icons/gift-green.svg',
    whiteCloud:'/icons/white-cloud.svg',
    crownPink:'/icons/crown-pink.svg',
    crownWhite:'/icons/crown-white.svg',
    batchImage:'/icons/batch-image.svg',
    batchBlack:'/icons/batch-black.svg',
    historyLight:'/icons/history-light.svg',
    historyDark:'/icons/history-dark.svg',
    recentLight:'/icons/recent-light.svg',
    heartLight:'/icons/heart-light.png',
    heartDark:'/icons/exploreTemplate/heart-dark.svg',
    colorWheel:'/icons/color-wheel.svg',
    chevronFilledDown:'/icons/filled-chevron-down.svg',
    crossLight:'/icons/cross-light.svg',
    squareDark:'/icons/exploreTemplate/square.svg',
    landscapeDark:'/icons/exploreTemplate/landscape.svg',
    portraitDark:'/icons/exploreTemplate/portrait.svg',
    squareLight:'/icons/exploreTemplate/square-light.svg',
    landscapeLight:'/icons/exploreTemplate/landscape-light.svg',
    portraitLight:'/icons/exploreTemplate/portrait-light.svg',
    trendingUpDark:'/icons/exploreTemplate/trending-up.svg',
    lockGradient:'/icons/lock-gradient.png',
    customizedButton:'/icons/customized.svg',
    mobileLightIcon:'/icons/mobile-icon.svg',
    demoLight:'/icons/demoLight.svg',
    downloadLight:'/icons/download-light.svg',
    bagDark:'/icons/Bag_dark.svg',
    pencilDark:'/icons/pencil-icon-dark.svg',
    deleteDark:'/icons/delete-dark.svg',
    playDark:'/icons/helpAndSupport/play.svg',
    chatDark:'/icons/helpAndSupport/bx_support.svg',
    orderDark:'/icons/helpAndSupport/my-work.svg',
    faqDark:'/icons/helpAndSupport/paragraph-rectangle.svg',
    deleteDarkFill:'/icons/delete-dark-fill.svg',
    cuttingDark:'/icons/cutting-one-dark.svg',
    opacityDark:'/icons/TextSidebar/Opacity.png',
    shadowDark:'/icons/TextSidebar/Shadow.png',
    eraserRed:'/icons/Edit/eraser.svg',
    eraserRestore:'/icons/Edit/restore-eraser.svg',
    eraserActive:'/icons/Edit/erase_ok.svg',
    restoreActive:'/icons/Edit/restore_ok.svg',
    scissorsLight:"/icons/cutting-one.png",
    circleDark:'/icons/circle-dark.svg',
    circleLight:'/icons/circle-light.svg',
    circleDarkest:'/icons/circle-darkest.svg',
    mobile:'/icons/mobile.svg',
    mobileDark: '/icons/mobile-dark.svg',
    demoDark: '/icons/demoDark.svg',
    credits: '/icons/credits.svg',
    creditsViolet: "/icons/credits-violet.svg",
    darkCredits: '/icons/darkCredits.svg',
    chevronLeftSquare: '/icons/chevon-left-square.svg',
    trash: '/icons/trash.svg',
    proTouchLogo:'/icons/pro-touch.svg',
    okLogoMarigold: '/icons/ok_marigold.svg',
    hdLogo: '/icons/hd_logo.svg',
    proFeatureBackground: '/icons/pro-feature.svg',
    groupDark: '/icons/group.svg',
    userPositionDark: '/icons/user-positioning.svg',
    csvlogo: '/icons/csvlogo.svg',
    adjustLogo: '/icons/adjust.svg',
    aiGenerateLight:"/icons/ai_generate_light.svg",
    prompSkelton: '/icons/promp-skelton.svg',
    editorSkelton: '/icons/editor-skelton.svg',
    coverageArea: '/icons/coverageArea.svg',
    positionArea: '/icons/positionArea.svg',
    chevronsRightDouble: '/icons/chevrons-right-double.svg',
    zoomInLens:"/icons/zoom-in-lens.svg",
    moreFourLight:"/icons/more-four.svg",
    twoDimensionalLight:"/icons/two-dimensional.svg",
    uploadDark:"/icons/upload_dark.svg",
    eyeFilled:"/icons/eye-filled.svg",
    downloadCsv: '/icons/download-three.svg',
    downloadCSVDark: '/icons/downloadCSV-dark.svg',
    transperency: '/images/home/transparency.svg',
    checkboxTicked:'/icons/TickedCheckbox.png',
    checkboxUnticked:'/icons/UntickedCheckbox.png',
    WhitePublish: '/icons/whitePublish.svg',
    downloadDark: '/icons/DownloadDark.png',
    markPencil: '/icons/mark-pencil.svg',
    deleteIcon: '/icons/DeleteIcon.svg',
    editMenuIcon: '/icons/edit-menu.svg',
    babyFaceIcon: '/icons/baby-face.svg',
    eraserLogo: '/icons/eraser-logo.svg',
    brushLogo: '/icons/brush-logo.svg',
    brushLightLogo: '/icons/brush-light-logo.svg',
    brushDarkLogo: '/icons/subway_brush_dark.svg',
    eraserLightLogo: '/icons/eraser-light-logo.svg',
    shapeAddLightLogo: '/icons/shape-add.svg',
    shapeAddDarkLogo: '/icons/shape-add-dark.svg',
    checkedLightLogo: '/icons/checked-light.svg',
    checkedDarkLogo: '/icons/checked-dark.svg',
    checkboxActive: '/icons/checkbox-active.svg',
    checkboxInActive: '/icons/checkbox-inactive.svg',
    inpaintLogo: '/icons/inpaint-logo.svg',
    checkRightLogo: '/icons/checkRight.svg',
    transperency: '/icons/TransperencyIcon.svg',
    gridDark: '/icons/grid-dark.svg',
    gridLight: '/icons/grid-light.svg',
    transperency: '/icons/TransperencyIcon.svg',
    dresmaLogo: '/icons/dresma_logo.png',
    publishDark: '/icons/publishDark.svg',
    lightHelpCircle: '/icons/lightHelpCircle.svg', 
    layerDark: '/icons/layersDark.svg',
    layersLight: '/icons/layersLight.svg',
    setTo30Zoom: '/icons/SetTo30Zoom.svg',
    createNewLight: '/icons/CreateNewLight.svg',
    createLight: '/icons/createLight.svg',
    dresmaIcon: '/icons/Logo.png',
    creditsDark: '/icons/BlackCredits.svg',
    blackPlusIcon: '/icons/BlackPlus.svg',
    downloadBoxDark: '/icons/DownloadBox.svg',
    themePlus: '/icons/theme-plus.png',
    themeUpload: '/icons/theme-upload.png',
    gradientPlus: '/icons/gradien-plus.png',
    infoCircleDark: '/icons/info-circle-dark.png',
    doubleChevronDown: '/icons/double-chevrons-down.png',
    clockDark: '/icons/clock-dark.png',
    restart: '/icons/Restart.png',
    premiumTick: '/icons/PremiumTick.png',
    instructionIcon: '/icons/Instruction.png',
    purpleTick: '/icons/PurpleTick.png',
    uncheckedCircle: '/icons/UncheckCircle.png',
    checkedCircle: '/icons/CheckedCircle.png',
    whiteDownIcon: '/icons/WhiteDownIcon.png',
    pinkSingleStar: '/icons/PinkSingleStar.svg',
    whiteSingleStar: '/icons/WhiteSingleStar.svg',
    lightInstructionIcon: '/icons/LightInstruction.svg',
    chevronUpDark: '/icons/ChevronUp.svg',
    grayPlusIcon: '/icons/GrayPlusIcon.png',
    regenerateIcon: '/icons/ReGenerateIcon.svg',
    grayInfoIcon: '/icons/GraybgInfo.svg',
    twinklingStars: '/icons/Stars.gif',
    orangeExclamation: '/icons/OrangeExclamation.svg',
    darkEmailSendWithTick: '/icons/EmailSendWithTick.svg',
    addMultipleAngle: '/icons/AddMoreAngle.png',
    attachmentIcon: '/icons/AttachmentIcon.png',
    pdfIcon: '/icons/PdfIcon.svg',
    imageIcon: '/icons/ImageFile.svg',
    whiteMail: '/icons/WhiteMail.png',
    customPackageIcon: '/icons/customPackage.svg',
    chevronLeftWhite: '/icons/chevron-left-white.svg',
    onlyLogo: '/icons/Logo.png',
    lowResolution: '/icons/uploadFile/lowResolution.svg',
    blackExclamation: '/icons/BlackExclamation.png',
    linearAnimation: '/icons/LinearAnimation.gif',
    whiteLogo: '/icons/whiteLogo.png',
}


export const Icon = ({src, height, width, alt="", priority=false, style={}, onClick=()=>{}}) => (
    <div style={{display:"flex", justifyContent:'center', alignItems:'center', minWidth:width, minHeight:height, ...style}}>
        <Image priority={priority} src={src} quality={100}  height={height} width={width}  alt={alt} onClick={onClick}/>
    </div>
)