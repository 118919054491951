import {
    Dialog,
    DialogTitle,
    Box,
    Typography,
} from "@mui/material";
import React from 'react'
import Logo from "src/components/Logo";

const SideDislikePopup = (props) => {
    const {openFeedbackChat, isSideMenu, jobInfo, userDetail, companyName, setSideMenu} = props;

    const getfeedbackChatTokenHandler=()=>{
        const token = localStorage.getItem('accessToken');
        const wn=document.getElementById("feedbackChat").contentWindow;
        wn.postMessage(token, '*');
      };
    return (
        <Box style={{
            right: isSideMenu ? "1%" : "-50%", top: "9%",
            overflowX: "hidden",
            bottom: "0px",
            width: "30vw",
            minWidth: "24vw",
            maxWidth: "24vw",
            height: "90vh",
            position: "fixed",
            backgroundColor: "#fff",
            transition: "all 0.35s ease-out",
            display: "block",
            borderRadius: "8px",
            zIndex: "9999999",
            boxShadow: "-40px 40px 80px -8px rgba(0, 0, 0, 0.24)"
        }}>
            <Box>
                <DialogTitle style={{ display: "flex", position: " relative", zIndex: "10" }}>
                    <div style={{ display: "flex", position: "absolute", left: "38%" }}>
                        <Logo disabledLink sx={{ width: 90, height: 24, }} />
                    </div>
                    <svg style={{ display: "flex", cursor: "pointer", position: "relative", top: "-2%", left: "100%" }} onClick={() =>setSideMenu(false)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9999 3.99998L3.99994 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3.99994 3.99998L11.9999 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </DialogTitle>
                <Box>
                    {openFeedbackChat &&
                        <iframe
                            id="feedbackChat"
                            src={`${process.env.NEXT_PUBLIC_FEEDBACK_CHAT}?mode="app"&jobOrderLabel="${jobInfo?.name}"&jobOrderId="${jobInfo?.job_id}"&user_identity="${userDetail?.email || ""}"&company_name="${companyName}"&userId="${userDetail?._id}"&chat_type="EXTERNAL"&chat_journey="FEEDBACK"`}  
                            style={{ width: "101%", height: "calc(100vh - 156px)", border: "none", position: "relative", top: "26px", }}
                            onLoad={getfeedbackChatTokenHandler}>
                        </iframe>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default SideDislikePopup