import { css } from "@emotion/react";
import { borderRadius } from "@mui/system";
import { ICONS, Icon } from "src/modules/ui-elements/Icon";

const TemplateBottomSection = ({ selectedFilter, onRemoveHandler, hotspotOnly, switchOnHandler }) => {

  return (
    <>

      {(selectedFilter.searchString?.length > 0 ||
        selectedFilter.colors?.length > 0 || hotspotOnly) && (
        <div css={styles.searchList}>
          {selectedFilter.searchString.map((obj) => (
            <div
              css={[
                styles.searchLightOption,
                css`
                  min-width: fit-content;
                `,
              ]}
            >
              {obj}
              <Icon
                src={ICONS.crossLight}
                width={12}
                height={12}
                style={{ cursor: "pointer", background: "grey", borderRadius: "99px" }}
                onClick={() => onRemoveHandler(obj)()}
              />
            </div>
          ))}
          {selectedFilter.colors.map((obj) => (
            <div
              css={[
                styles.searchLightOption,
                css`
                  min-width: fit-content;
                `,
              ]}
            >
              <div style={{paddingTop: "3px"}}>{`Color: ${obj.replace("#", "").toUpperCase()}`}</div>
              <Icon
                src={ICONS.crossLight}
                width={10}
                height={10}
                style={{ cursor: "pointer", background: "grey", borderRadius: "99px" }}
                onClick={() => onRemoveHandler(obj)()}
              />
            </div>
          ))}
          {hotspotOnly && <div
              css={[
                styles.searchLightOption,
                css`
                  min-width: fit-content;
                `,
              ]}
            >
              <span>{`Customizable only`}</span>
              <Icon
                src={ICONS.crossLight}
                width={10}
                height={10}
                style={{ cursor: "pointer" }}
                onClick={() => switchOnHandler()}
              />
            </div>}
        </div>
      )}
    </>
  );
};

const styles = {
  searchList: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 0px 0px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  searchLightOption: css`
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    padding: 5px 10px 4px;
    background-color: #464646;
    color: rgba(255, 255, 255, 0.7);
    font-family: Objectivity;
    font-size: 11px;
    line-height: 1.5;
    min-width: 50px;
    margin: 2px 0px 15px 10px;
    min-height: 30px;
  `,
  pointer: {
    cursor: "pointer",
  },
};

export default TemplateBottomSection;