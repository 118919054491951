import { useContext, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import PopUp from "@/modules/ui-elements/PopUp";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import CustomizedButton from "@/modules/ui-elements/CustomizedButton";
import { AuthContext } from "@/contexts/JWTContext";
import { ICONS } from "@/modules/ui-elements/Icon";
import { useDispatch, useSelector } from "react-redux";
import _, { get } from "lodash";
import {
  loginTypeEmailAtom,
  userInputEmailAtom,
  userInputMobileAtom,
  segmentEventPayload,
} from "@/modules/editor-v2/Atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { CircularProgress } from "@mui/material";
import { TrackWebappEvent, IdentifierWebappEvent } from "@/modules/segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "@/modules/segment-event-tracking/constant";
import { isRemoveWatermarkClickedAtom, jobSuccessScreenDataAtom, sendToHomepageAtom, showSuccessProjectPopupAtom, tempComplementryJobIdAtom, tempComplementryProTouchAtom, zipAndDownloadImagesDataAtom } from "../../editor-v2/Atoms";
import { getJobDetails, getProTouchRequiredCredits, proTouchConfirmJob } from "../../editor-v2/services/Jobs";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import { setUserTrackInfoPayload } from 'src/redux/reducers/jobs.reducer';
import PremiumSection from "../premiumSection";
import { zipAndDownloadImages } from "../../editor-v2/ConfirmJobPopUpV2";
import {isDownloadCsvButtonClickedAtom, isLoginInButtonClickedAtom, triggerAutoCsvDownloadWhenLoggedInAtom, triggerAutoPackageSubmissionWhenLoggedInAtom} from '../../marketplaceListing/Atoms';
import {
  setMyProjectRefreshPage
} from "src/redux/reducers/myProject.reducer";

const OtpSection = ({ authRef, verifyRef, handleCreateJobFromTemplate }) => {
  const Toast = useToast();
  const otpValueRef = useRef(null);
  const timerRef = useRef(null);
  const {push} = useRouter();
  const ctx = useContext(AuthContext);
  const [valid, setValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    user,
    logout,
    verifyOtp,
    verifyData,
    login,
    loginMobile,
    refreshUserDetails
  } = useAuth();
  const loginTypeEmailValue = useAtomValue(loginTypeEmailAtom);
  const userInputEmailValue = useAtomValue(userInputEmailAtom);
  const userInputMobileValue = useAtomValue(userInputMobileAtom);
  const isLoginButtonClicked = useAtomValue(isLoginInButtonClickedAtom);
  const setLoginTypeEmail = useSetAtom(loginTypeEmailAtom);
  const setInputEmail = useSetAtom(userInputEmailAtom);
  const setInputMobile = useSetAtom(userInputMobileAtom);
  const [timerValue, setTimerValue] = useState(0);
  const [resendLoading, setResendLoading] = useState(false);
  const setTriggerForAutoCsvDownload = useSetAtom(triggerAutoCsvDownloadWhenLoggedInAtom)
  const setCsvButtonClick = useSetAtom(isDownloadCsvButtonClickedAtom)
  const setTriggerForAutoPackageSubmission = useSetAtom(triggerAutoPackageSubmissionWhenLoggedInAtom)
  const { couponData } = useSelector((state) => state.appRedirect);
  const [getSegmentPayloadAtom, setSegmentPayloadAtom] = useAtom(segmentEventPayload);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getTempComplementryJobId = useAtomValue(tempComplementryJobIdAtom);
  const [getTempComplementryProTouch,setTempComplementryProTouch] = useAtom(tempComplementryProTouchAtom);
  const setTempComplementryJobId = useSetAtom(tempComplementryJobIdAtom);
  const [getZipAndDownloadImagesData,setZipAndDownloadImagesData] = useAtom(zipAndDownloadImagesDataAtom);
  const [getSuccessScreenData,setSuccessScreenData] = useAtom(jobSuccessScreenDataAtom);
  const setSuccessProjectValue = useSetAtom(showSuccessProjectPopupAtom);
  const {entireStrapiData:{EmailOtpOnWebapp,MobileOtpOnWebapp,WebappHomepageV22}} = useSelector((state)=> state.strapi);
  const strapiDataEmailOtp = EmailOtpOnWebapp?.EmailOtpOnWebapp || {};
  const strapiDataMobileOtp = MobileOtpOnWebapp?.MobileOtpOnWebapp || {};
  const onBackHandler = () => {
    sessionStorage.setItem("otp", true)
    authRef.current?.open();
    verifyRef.current?.close();
    otpValueRef.current.value = null;
    refreshUserDetails();
  };
  const setIsRemoveWatermarkClicked = useSetAtom(isRemoveWatermarkClickedAtom);
  const sendToHomepage = useAtomValue(sendToHomepageAtom)
  const setSendToHomepage = useSetAtom(sendToHomepageAtom)

  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const segmentEventHandler = (user) => {
    const payload = {
      userId:user?.user?._id || '',
      login_type:user?.user?.login_type || '',
      email:user?.user?.email || '',
      mobile:user?.user?.phone || '',
      userType:user?.user?.userType || '',
      isPremiumSubscription:user?.entity?.entity?.Entity_Plan?.isPremiumSubscription || '',
      credits:user?.entity?.entity?.credits,
      entity_id:user?.entity?.entity?._id || '',
      platform: "web",
      language: user?.user?.language || '',
      region: user?.entity?.entity?.country || '',
      createdAt: moment(user?.user?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || '',
      identifier: user?.user?.email?.length > 0 ? user?.user?.email : user?.user?.phone ,
      login_mode: user?.user?.meta_data?.login_mode || '',
      name: user?.user?.name || '',
      plan_Start_Date:moment(user?.entity?.entity?.Entity_Plan?.Start_Date).format('MMMM Do YYYY, h:mm:ss a')  || '',
      plan_id:user?.entity?.entity?.Entity_Plan?.plan_id || '',
      recharge_list_type:user?.entity?.entity?.Entity_Plan?.recharge_list_type || '',
      plan_Expiry_Date:moment(user?.entity?.entity?.Entity_Plan?.validTill).format('MMMM Do YYYY, h:mm:ss a')   || '',
      entity_plan_id:user?.entity?.entity?.Entity_Plan?._id || '',
      referredByEntity:user?.entity?.entity?.referredByEntity?._id,
      referredByEntityName:user?.entity?.entity?.company_name,
      user_role:user?.user?.user_role?.name
   }
    setSegmentPayloadAtom(payload);
    dispatch(setUserTrackInfoPayload(payload));
   if(verifyData?.isLogin){
    TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._DMS_LOGIN}`,payload);
    if(user?.user?._id)IdentifierWebappEvent(user?.user?._id,payload);
  }else{
    TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._DMS_SIGN_UP}`,payload);
    if(user?.user?._id)IdentifierWebappEvent(user?.user?._id,payload);
  }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    let isValid = false;
    if(/^\d{6}$/.test(newValue) || newValue?.length == 0 ){
      isValid=true;
      setErrorMessage(null);
    }
    setValid(isValid);
  };

  const handleJobSuccess = async(userData) => {
    try{
      if(!getSuccessScreenData?.type)return;
     if(getTempComplementryProTouch){
      let projectCost = {
        totalCost: getSuccessScreenData?.totalCost,
        templateCost: getSuccessScreenData?.templateCost,
        isComplimentaryShoot: getSuccessScreenData?.isComplimentaryShoot
      };
        projectCost = await getProTouchRequiredCredits(getSuccessScreenData?.job_id, getSuccessScreenData?.selected_templates, getSuccessScreenData?.pre_exist, getSuccessScreenData?.guideline);
        if(projectCost?.isComplimentaryShoot){
          const response = await proTouchConfirmJob(getSuccessScreenData?.job_id, getSuccessScreenData?.selected_templates, getSuccessScreenData?.pre_exist, getSuccessScreenData?.guideline);
          await refreshUserDetails();
          setSuccessProjectValue({...getSuccessScreenData,type:'SEND_JOB_FOR_PROTOUCH',deadlineDate:response?.deadlineDate});
          const segmentObjectData = {
            ...getSegmentPayloadAtom,
            job_id:getSuccessScreenData?.job_id,
            subCategoryName: "",
            category_id: "",
            Template_1:getSuccessScreenData?.selected_templates || "",
            images_clicked_count: getSuccessScreenData?.selected_templates?.length || "",
            jobOrderName: getSuccessScreenData?.projectName || "",
            credit_used: projectCost?.totalCost,
          }
          if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._send_pro_touch}`,segmentObjectData);
          setSuccessScreenData(null);
        }else{
          setSuccessProjectValue({...getSuccessScreenData,type:'SEND_PRO_TOUCH',isComplimentaryShoot:projectCost?.isComplimentaryShoot});
        }
        setTempComplementryProTouch(false);
      }else{
        await zipAndDownloadImages(getZipAndDownloadImagesData?.base64Images,getZipAndDownloadImagesData?.jobName,userData,getZipAndDownloadImagesData?.type);
        setZipAndDownloadImagesData(null);
        setSuccessProjectValue({...getSuccessScreenData,type:'LOGGED_IN_JOB_HIGH_RESOLUTION'});
      }
      setTempComplementryJobId('');
    }catch(error){
      console.error("error in protouch flow",error);
    }
  };
  
  const handleSubmit = async () => {
    const regex = /^\d{6}$/;
    if (!regex.test(otpValueRef.current.value)){
      setErrorMessage('Invalid OTP');
      return;
    }
    try {
      ctx.isSocialLoginHandler(true);
      const payload = {
        otp: otpValueRef.current.value,
        userId: get(verifyData, "userId", ""),
        couponCode: get(couponData, "couponCode", ""),
        // jobOrderId:getTempComplementryJobId || '',
      };
      if(loginTypeEmailValue){
        payload.email = userInputEmailValue;
      }else{
        payload.phone = userInputMobileValue;
      }
      const response = await verifyOtp(payload);
      authRef.current?.close();
      verifyRef.current?.close();
      const userData = await refreshUserDetails();
      dispatch(setMyProjectRefreshPage(true));
      setLoginTypeEmail(true);
      setInputEmail(null);
      setInputMobile({});
      if (userData && get(userData,"user.user_role.name","").toUpperCase() === "APP_USER") {
        Toast.error(WebappHomepageV22?.WebappHomepageV22?.errorLogin || "You don't have the permission to Login!");
        logout();
        return;
      }
      if(!isLoginButtonClicked){
        setTriggerForAutoCsvDownload(true)
        setTriggerForAutoPackageSubmission(true)
      }
      Toast.success(WebappHomepageV22?.WebappHomepageV22?.successLogin || "Successfully Logged in!");
      segmentEventHandler(userData);
      queryClient.invalidateQueries();
      await handleJobSuccess(userData);
      const template_id = sessionStorage.getItem('selected_template_id');
      sessionStorage.removeItem('selected_template_id');
      if(template_id){
        await handleCreateJobFromTemplate(template_id);
        sessionStorage.removeItem('nextPath');
      }else if(sessionStorage.getItem('nextPath')){
        window.location.href = sessionStorage.getItem('nextPath');
      }
      if(sessionStorage.getItem('bulkUploadProTouchActivate')){
        sessionStorage.setItem('bulkUploadProTouchActive',true);
      }
      sessionStorage.removeItem('nextPath');
      sessionStorage.removeItem('login_flow');
      localStorage.removeItem("magicQrInfo")
    } catch (error) {
      Toast.error(error?.response?.data?.message ?? "Error");
    } finally {
      ctx.isSocialLoginHandler(false);
    }
  };

  const handleResendOtpButton = async () => {
    let payload = {};
    if (loginTypeEmailValue) {
      payload = userInputEmailValue;
    } else {
      payload.phone = get(userInputMobileValue, "mobile");
      payload.countryCode = get(userInputMobileValue, "countryCode");
    }
    try {
      setResendLoading(true);
      if (loginTypeEmailValue) {
        await login(payload);
      } else {
        await loginMobile(payload);
      }
      timerHandler();
    } catch (error) {
      Toast.error(error?.response?.data?.message ?? "Error");
    }finally{
      setResendLoading(false);
    }
  };

  const timerHandler = () => {
    setTimerValue(30);
    timerRef.current = setInterval(() => {
      setTimerValue((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);
  };

  const onCloseHandler = () => {
    refreshUserDetails();
    // setTempComplementryJobId('');
    setTempComplementryProTouch(false);
    sessionStorage.removeItem('login_flow');
  }

  
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerHandler();

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);


  useEffect(() => {
    if (timerValue <= 0 && timerRef.current) {
      clearInterval(timerRef.current);
      setTimerValue(0);

    }
  }, [timerValue]);
  
  const onPopUpBackDropAndCloseButtonClick = () => {
    setIsRemoveWatermarkClicked(false)
    setTempComplementryJobId('');
    setTriggerForAutoCsvDownload(false);
    setTriggerForAutoPackageSubmission(false);
    setCsvButtonClick(false);
    sendToHomepage && push("/dashboard/homePage")
    setSendToHomepage(false);
  }


  return (
  <PopUp 
     ref={verifyRef} 
     width={ Math.max(Math.min(screenSize.width - 300, 1040), 430)} 
     borderRadius={"16px"}
     styleBody={{ padding: "0" }} 
     styleClose={styles.styleClose} 
     onCloseHandler={onCloseHandler} 
     onBackdropClick={onPopUpBackDropAndCloseButtonClick} 
     onCloseButtonClick={onPopUpBackDropAndCloseButtonClick}
     disableBackdropClick={sendToHomepage}
  >
    <div css={styles.container2}>
      {screenSize.width > 950 && <PremiumSection/>}
      <div css={styles.premiumContainer}>
        <div css={styles.topContainer}>
        <CustomizedButton
          type="grey"
          btnText={strapiDataEmailOtp?.textBackheader || "Back"}
          startIcon={ICONS.chevronLeftDark}
          btnStyle={styles.backStyle}
          onClick={onBackHandler}
        />
        </div>
      <div css={styles.container}>
        <h2 css={styles.heading}>
          {loginTypeEmailValue ? strapiDataEmailOtp?.textHeader || "Verify Your Email" : strapiDataMobileOtp?.textHeader || "Verify Your Mobile Number"}
        </h2>
        <form css={styles.form} onSubmit={(e)=>e.preventDefault()}>
          <label for="otp" css={styles.inputTitle}>
            {loginTypeEmailValue ? strapiDataEmailOtp?.textByline || `A verification code has been sent to` : strapiDataMobileOtp?.textByline || "Enter the code we sent to"}{" "}
            {loginTypeEmailValue
              ? userInputEmailValue
              : userInputMobileValue?.mobile}
          </label>
          <p css={styles.inputOTPTitle}>
            {strapiDataEmailOtp?.textOTPheader || "OTP"}
          </p>
          <input
            type="number"
            id="otp"
            name="otp"
            ref={otpValueRef}
            css={styles.inputOtp(valid)}
            placeholder="Enter OTP*"
            pattern="\d+"
            required
            onChange={handleInputChange}
            onKeyDown={(e)=>{if(e.key === "Enter")handleSubmit()}}
          />
          <span css={styles.errorMessage}>{errorMessage}</span>
          <CustomizedButton
            type="pink_with_border"
            btnText={ strapiDataEmailOtp?.textButton||"Done"}
            btnStyle={styles.otpButton}
            disabled={false}
            isActive={true}
            onClick={handleSubmit}
          />
        </form>
        <p css={styles.subtitleText2}>
          {timerValue <= 0 ? strapiDataEmailOtp?.textBelowButton?.find(obj=>obj?.id===50)?.textCodeNotReceived?.split("<br/><br/>")?.[0] || `Didn't get a code?  ` : strapiDataEmailOtp?.textResendCodeTimer||`Resend OTP in`}
          {resendLoading ? <CircularProgress size='14px' color='inherit' thickness={3} sx={{ml:'10px'}} /> : timerValue <= 0 ? (
            <span css={styles.subtitleTextBold} onClick={handleResendOtpButton}>
              {strapiDataEmailOtp?.textBelowButton?.find(obj=>obj?.id===50)?.textCodeNotReceived?.split("<br/><br/>")?.[1] || " Resend Code"}
            </span>
          ) : (<span css={styles.counterBold}>
               0:{timerValue}
            </span>
          )}
        </p>
      </div>
      </div>
      </div>
    </PopUp>
  );
};

const styles = {
  container: css`
    padding: 24px 72px;
  `,
  backStyle: {
    padding: "3px 6px",
    width: "fit-content",
    marginBottom: "24px", 
    gap:"2px", 
    boxShadow: "0px 1px 24px 0px rgba(38, 38, 38, 0.05)",
    margin: "16px 20px 20px 20px"
  },
  heading: css`
    margin-bottom: 16px;
    font-family: Objectivity;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(38, 38, 38, 1);
  `,
  topContainer:css`
    height:56px;
    display:flex;
    align-items:center;
    box-shadow: 0px 1px 24px 0px rgba(38, 38, 38, 0.05);
  `,
  form: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  errorMessage:css`
    color: #E50000;
    font-family: Objectivity;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  `,
  inputOtp:(valid)=> css`
    width: 100%;
    height: 54px;
    border-radius: 4px;
    color: ${valid ? '#262626' : 'red'};
    padding: 16px 0px 16px 16px;
    background: #fff;
    :focus {
      border: 1px solid ${valid ? '#262626' : 'red'};
      outline: none;
    };
    ::placeholder {
      color: rgba(38, 38, 38, 0.3);
      font-family: "Objectivity";
      font-size: 14px;
      font-weight: 600;
    }
  `,
  inputTitle: css`
    width: 80%;
    color: #262626;
    font-family: Objectivity;
    font-size: 14px;
    margin-bottom: 8px;
  `,
  inputOTPTitle: css`
    color: rgba(38, 38, 38, 1);
    font-family: Objectivity;
    font-size: 14px;
  `,
  subtitleText: css`
    color: rgba(38, 38, 38, 0.7);
    font-family: "Objectivity";
    font-size: 12px;
    padding: 8px 0px;
  `,
  subtitleText2: css`
    color: #262626;
    font-family: Objectivity;
    font-size: 12px;
    padding: 16px 48px 16px 0px;
    display:flex;
    align-items:center;
    letter-spacing: 1px;
  `,
  subtitleTextBold: css`
    color: #262626;
    font-family: Objectivity;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    padding-left:6px;
    text-decoration-skip-ink: none;
  `,
  counterBold: css`
    color: #262626;
    font-family: Objectivity;
    font-size: 12px;
    font-weight: 600;
    padding-left:6px;
  `,
  otpButton: {
    fontWeight: 400,
    paddingTop: "16px",
    paddingBottom: "16px",
    borderRadius: "4px",
    border:'none',
  },
  container2: css`
  display: flex;
  flex-direction: row;
  height: 635px;
`,

premiumContainer: css `
  color: #FFF;
  font-family: Objectivity;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  flex:1;
  min-width:436px;
`,
header: css`
  font-size: 15px;
  height: 56px;
  font-weight: 500;
  display: flex; 
  flex-direction: row;
  align-items: start;
  margin-bottom: 17px;
  padding: 16px 20px 20px 20px;
  background: rgba(18, 18, 18, 0.08);
`,
unlockPremium: css`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-bottom: 20px;
`,
premiumContent: css`
  display: flex;
  flex-direction: column; 
  margin-top: 30%; 
  align-items: center; 
`,
styleClose: {
  borderRadius: "999px", 
  padding: "8px", 
  background: "white", 
  right: "-45px", 
  top:"-2px"
}
};

export default OtpSection;
