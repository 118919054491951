import { fontFamilyAtom, fontSizeAtom, fontWeightAtom, initialZoomAtom, italicAtom, letterSpacingAtom, lineHeightAtom, selectedLayerAtom, textAlignAtom, underlineAtom } from "@/modules/editor-v2/Atoms";
import { useAtomValue, useSetAtom } from "jotai";
import { CanvasFontStyles, TextAlignment } from "local_modules/editor/src/Interfaces";
import TextLayer from "local_modules/editor/src/TextLayer";
import { useCallback, useMemo } from "react";
import useEditorService from "./useEditorService";

export default function useEditorTextLayerService () {
    const {activeEditor} = useEditorService();
    const selectedLayer = useAtomValue(selectedLayerAtom);
    const initialZoom = useAtomValue(initialZoomAtom);
    const setTextAlignState = useSetAtom<any>(textAlignAtom);
    const setUnderlineState = useSetAtom<any>(underlineAtom);
    const setItalicState = useSetAtom<any>(italicAtom);
    const setFontWeightState = useSetAtom<any>(fontWeightAtom);
    const setFontFamilyState = useSetAtom<any>(fontFamilyAtom);
    const setLineHeightState = useSetAtom<any>(lineHeightAtom);
    const setLetterSpacingState = useSetAtom<any>(letterSpacingAtom);
    const setFontSizeState = useSetAtom<any>(fontSizeAtom);

    const addText = useCallback((text:string, style: CanvasFontStyles)=>{
        const _style = {...style};
        if(!activeEditor) return;
        _style.fontSize = (_style.fontSize ? _style.fontSize: 20)/initialZoom;
        activeEditor.addText(text,_style);
    },[activeEditor, initialZoom]);

    const setTextAlign = useCallback((value:TextAlignment, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.textAlign = value;
    },[activeEditor]);
    
    const setSelectedLayerTextAlign = useCallback((value:TextAlignment)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setTextAlign(value, selectedLayer as TextLayer);
        setTextAlignState(value);
    },[activeEditor, selectedLayer, setTextAlign]);

    const setUnderline = useCallback((value:boolean, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.underline = value;
    },[activeEditor]);

    const setItalic = useCallback((value:boolean, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.italic = value;
    },[activeEditor]);

    const setSelectedLayerUnderline = useCallback((value:boolean)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setUnderline(value, selectedLayer as TextLayer);
        setUnderlineState(value);
    },[activeEditor, selectedLayer, setUnderline]);

    const setSelectedLayerItalic = useCallback((value:boolean)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setItalic(value, selectedLayer as TextLayer);
        setItalicState(value);
    },[activeEditor, selectedLayer, setItalic]);

    const setFontWeight = useCallback((value:string, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.fontWeight = value;
    },[activeEditor]);
    
    const setFontFamily = useCallback((value:string, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.fontFamily = value;
    },[activeEditor]);

    const setSelectedLayerFontWeight = useCallback((value:string)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setFontWeight(value, selectedLayer as TextLayer);
        setFontWeightState(value);
    },[activeEditor, selectedLayer, setFontWeight, setFontWeightState]);

    const setSelectedLayerFontFamily = useCallback((value:string)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setFontFamily(value, selectedLayer as TextLayer);
        setFontFamilyState(value);
    },[activeEditor, selectedLayer, setFontFamily, setFontFamilyState]);

    const setLineHeight = useCallback((value:number, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.lineHeight = value / layer.fontSize;
    },[activeEditor]);

    const setLetterSpacing = useCallback((value:number, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.letterSpacing = value;
    },[activeEditor]);

    const setSelectedLayerLineHeight = useCallback((value:number)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setLineHeight(value, selectedLayer as TextLayer);
        setLineHeightState(value);
    },[activeEditor, selectedLayer, setLineHeight, setLineHeightState]);

    const setSelectedLayerLetterSpacing = useCallback((value:number)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setLetterSpacing(value, selectedLayer as TextLayer);
        setLetterSpacingState(value);
    },[activeEditor, selectedLayer, setLetterSpacing, setLetterSpacingState]);

    const setFontSize = useCallback((value:number, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.fontSize = value;
    },[activeEditor]);

    const setSelectedLayerFontSize = useCallback((value:number)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setFontSize(value, selectedLayer as TextLayer);
        setFontSizeState(value);
    },[activeEditor, selectedLayer, setFontSize, setFontSizeState]);

    const setColor = useCallback((value:string, layer: TextLayer)=>{
        if(!activeEditor) return;
        layer.textColor = value;
    },[activeEditor]);

    const setSelectedLayerColor = useCallback((value:string)=>{
        if(!activeEditor || !selectedLayer) return;
        if(selectedLayer.type != "text") return;
        setColor(value, selectedLayer as TextLayer);
    },[activeEditor, selectedLayer, setColor]);

    return {
        addText,
        setTextAlign, 
        setSelectedLayerTextAlign, 
        setUnderline, 
        setItalic, 
        setSelectedLayerUnderline,
        setSelectedLayerItalic, 
        setFontWeight, 
        setFontFamily, 
        setSelectedLayerFontWeight, 
        setSelectedLayerFontFamily, 
        setLineHeight, 
        setLetterSpacing, 
        setSelectedLayerLineHeight, 
        setSelectedLayerLetterSpacing,
        setFontSize,
        setSelectedLayerFontSize,
        setColor,
        setSelectedLayerColor
    };
}