//
import {
  CheckboxIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
} from "./CustomIcons";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// ----------------------------------------------------------------------

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckBoxOutlineBlankIcon />,
        checkedIcon: <CheckIcon size="small" />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          color: theme.palette.dark,
          "& > .MuiSvgIcon-root": {
            borderRadius: "4px",
            border: `1px solid ${theme.palette.dark}`,
          },
          "& > .MuiSvgIcon-root > path": {
            visibility: "hidden",
          },
          "&.Mui-checked > .MuiSvgIcon-root": {
            color: theme.palette.dark,
            border: `1px solid ${theme.palette.dark}`,
            borderRadius: "4px",
            "& > path": {
              visibility: "visible",
            },
          },
          "&.Mui-checked.Mui-disabled, &.Mui-disabled": {
            color: theme.palette.action.disabled,
            "& > .MuiSvgIcon-root": {
              border: `1px solid ${theme.palette.action.disabled}`,
            },
          },
          "& .MuiSvgIcon-fontSizeMedium": {
            width: 20,
            height: 20,
          },
          "& .MuiSvgIcon-fontSizeSmall": {
            width: 20,
            height: 20,
          },
          svg: {
            fontSize: 24,
            color: theme.palette.dark,
            "&[font-size=small]": {
              fontSize: 20,
            },
          },
        },
      },
    },
  };
}
