import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
export const PRIMARY = {
  lighter: "#eb49a6",
  light: "#e83c9f",
  white:"#FFFFFF",
  main: "#eb349d",
  dark: "#eb2d9e",
  darker: "#eb1594",
};
export const SECONDARY = {
  lighter: "#636060",
  light: "#4a4848",
  main: "#333333",
  dark: "#262626",
  darker: "#1a1919",
};
export const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
export const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  secondary: '#4BB543'
};
export const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
export const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

export const ICON = {
  pink: "#F03750",
  purple: "#753C95",
};

export const BUTTON = {
  pink: "linear-gradient(94.51deg, #F03750 7.58%, #EB2E83 101.48%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
};

export const GREY = {
  0: "#FFFFFF",
  20: "#EEEEEE",
  50: "#F5F5F5",
  60: "#F9F9F9",
  70: "rgba(38, 38, 38, 0.5)",
  70_03: "rgba(38, 38, 38, 0.03)",
  75: "rgba(38, 38, 38, 0.08)",
  80: alpha("#FFFFFF", 0.03),
  85: "rgba(38, 38, 38, 0.08)",
  80_16: alpha("#FFFFFF", 0.16),
  90: alpha("#FFFFFF", 0.5),
  90_05: "rgba(255, 255, 255, 0.05)",
  90_7: "rgba(255, 255, 255, 0.7)",
  90_16: "rgba(255, 255, 255, 0.16)",
  100: "#F9FAFB",
  0: "#F1F1F1",
  1: "#5A5A5A",
  2: "#303030",
  3: "#676767",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  1000: "#121212",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};
 
export const HIGHLIGHTS = {
  primary: "#EB2E83",
};

export const GRADIENTS = {
  primary: "linear-gradient(97.2deg, #F03750 5.71%, #EB2E83 136.98%)",
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  link: "linear-gradient(93.62deg, rgba(255, 243, 243, 0.8) -2.75%, rgba(255, 235, 235, 0.56) 86.11%)",
};

export const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

export const BORDER = {
  grey1: { default: "rgba(38, 38, 38, 0.08)" },
  grey2: { default: "rgba(38, 38, 38, 0.05)" },
  grey3: { default: "E6E8ED" },
};

export const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  text: { primary: "#262626", secondary: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  highlights: HIGHLIGHTS,
  button: BUTTON,
  icon: ICON,
  border: { ...BORDER },
  chart: CHART_COLORS,
  dark: "#262626",
  divider: "rgba(38, 38, 38, 0.08)",
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const colorGradient="linear-gradient(to right,#F03750, #E72D7E)";
const palette = {
  light: {
    ...COMMON,
    mode: "light",
    text: {
      primary: COMMON["dark"],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200], f9: GREY[60] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[800],
      default: GREY[1000],
      neutral: GREY[500_16],
    },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
