import { ICONS, Icon } from "@/modules/ui-elements/Icon";
import { css } from "@emotion/react";
import Head from "next/head";
import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import {useDropzone} from 'react-dropzone'
import { addAsset, addAssets, deleteAsset, getAssets, removeAssetBackground } from "../../services/Assets";
import { Loader } from "./ShootAssets";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import FileUploader from "@/modules/ui-elements/FileUploader";
import useToast from "@/hooks/useToast";
import PopUp, { IPopUpRef } from "@/modules/ui-elements/PopUp";
import useEditorService, { ProductImageType } from "@/hooks/useEditorService";
import { DEFAULT_CANVAS_SIZE } from "../../constants";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { activeAssetTabAtom, activeProjectAtom, consumedStorageAtom, initializingEditorAtom, leftNavigationActiveIndexAtom, searchAssetKeywordAtom, segmentEventPayload, toAddAssetAtom, toAddAssetIdAtom, totalStorageAtom,standardInfographic, uploadedRawImagesDataAtom, uploadedImagesLoaderAtom } from "../../Atoms";
import { formatBytes } from "../../utils";
import { useSelector } from 'react-redux';
import { TrackWebappEvent } from "@/modules/segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "@/modules/segment-event-tracking/constant";
import AddUploadAssetPopUp from "./AddUploadAssetPopUp";
import { createEmptyEditorJob, getJobDetails } from "../../services/Jobs";
import useOtherCategory from "@/hooks/useOtherCategory";
import { m } from "framer-motion";
import Resizer from "react-image-file-resizer";

export default function UploadAssets({isSelected}) {
    const [waitForTransitionIsSelected, setWaitForTransitionIsSelected] = useState(false);
    const [height, setHeight] = useState(100);
    const setConsumedStorage = useSetAtom(consumedStorageAtom);
    const setTotalStorage = useSetAtom(totalStorageAtom);
    const searchAssetKeyword = useAtomValue(searchAssetKeywordAtom);
    const activeAssetTab = useAtomValue(activeAssetTabAtom);
    const setRawImagesData = useSetAtom(uploadedRawImagesDataAtom);
    const {data: assets, isLoading, isError} = useQuery({
        queryKey: ["assets",searchAssetKeyword],
        queryFn: getAssets,
        enabled: activeAssetTab !== 0
    });
    
    const imageCompressor=(files:any)=>{
        return new Promise((res,rej)=> {
          let extention=files?.type?.split('/')[1].toUpperCase();
          const filename=files?.name;
          let resize: number | null = null;
          const bulkImageUploadSize = parseInt(process.env.NEXT_PUBLIC_UPLOAD_SIZE) || 3000000;
          const imageCompressionFactor = parseInt(process.env.NEXT_PUBLIC_COMPRESS_VALUE) || 80;
          if(files?.size >= bulkImageUploadSize) resize = imageCompressionFactor;
          else res(files);
          if(!resize) res(null);
          let w: number;
          let h: number;
          var url = URL.createObjectURL(files);
          var img = document.createElement('img');
          img.src = url;
          img.onload = function(){
              w = img.width;
              h = img.height;
          }
          try {
            Resizer.imageFileResizer(
              files,
              w,
              h,
              extention,
              resize,
              0,
              (uri: string) => {
                 fetch(uri)
                  .then(res => res.blob())
                   .then(blob => {
                          const fd = new FormData();
                          const file = new File([blob], filename);
                          fd.append('image', file);
                         res(file);
                   })
              },
            );
          } catch (err) {
            console.error(err);
            rej(err)
          }
        })
        
      };


    useEffect(()=>{
        if(isSelected) return setWaitForTransitionIsSelected(true);
        setTimeout(()=>{
            setWaitForTransitionIsSelected(isSelected);}, 300)
        }
    ,[isSelected]);

    useEffect(()=>{
        if(!assets || !Array.isArray(assets)) return;
        if(assets.length < 1) {
            setConsumedStorage(0);
            return;
        }
        let {consumedStorage, totalStorage} = assets[assets.length-1];
        setConsumedStorage(consumedStorage);
        setTotalStorage(totalStorage);
    },[assets])

    useLayoutEffect(()=>{
        const _height = document.querySelector('#left-tool-bar-content')?.clientHeight - document.querySelector("#my-assets-header")?.clientHeight - document.querySelector("#my-assets-navigator-header")?.clientHeight - 6 || 786;
        setHeight(_height);
    },[]);

    useEffect(()=>{
        if(assets?.length && !isLoading){
            setRawImagesData([])
        }
    },[assets])

    return (
        <div css={css`
            flex:1;
            flex-direction:column;
            height: 100%;
            display:flex;
            padding-top:8px;
        `}>
            {waitForTransitionIsSelected && <HeadInjector height={height} />}
            {/* <AssetUploadInfoAlert /> */}
            {isLoading && <Loader />}
            {!isLoading && Array.isArray(assets) && assets.length <= 0 && <DragAndDropNoAssets imageCompressor={imageCompressor} />}
            {!isLoading && Array.isArray(assets) && assets.length > 0 && <AssetsList imageCompressor={imageCompressor} assets={assets} isSelected={isSelected} />}
            {/* <AssetsStorageInfo /> */}
        </div>
    )
}

const HeadInjector = ({height}) => {
    return <Head>
        <style>
            {`
                #slider .slick-list {
                    transition: height 0.1s ease-in-out !important;
                    height:${height}px !important;
                }
            `}
        </style>
    </Head>
}

const AssetUploadInfoAlert = () => (<div
    css={css`
        background:white;
        // padding:16px;
        border-radius: 4px;
        margin:0px 10px;
    `}
>
    <div
        css={css`
            background: radial-gradient(742.16% 3217.25% at 0% -3.91%, rgba(95, 46, 235, 0.08) 0%, rgba(235, 46, 131, 0.08) 46.59%, rgba(240, 55, 80, 0.08) 78.3%);
            padding:16px;
        `}
    >
        <div
            css={css`
                background: linear-gradient(0deg, #753C95, #753C95), linear-gradient(97.2deg, #F03750 5.71%, #EB2E83 136.98%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
            `}
        >
           "Note: By Applying your assets, it will automatically remove product background. "<br/>
        </div>
        <div css={css`
            margin-top:8px;
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            background: linear-gradient(0deg, #753C95, #753C95), linear-gradient(97.2deg, #F03750 5.71%, #EB2E83 136.98%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        `}>
            1 Credit = 1 image background removed
        </div>
    </div>
</div>);

export const AssetsStorageInfo = ({strapiData={}}:any) => {
    const availableStorage = useAtomValue(totalStorageAtom);
    const usedStorage = useAtomValue(consumedStorageAtom);
    
    return (
        <div
                css={css`
                    background: rgba(238, 238, 238, 0.08);
                    border:0px;
                    border-top: 0.5px solid rgba(255, 255, 255, 0.24);
                    padding:10px;
                    
                `}
            >
                <div
                    css={css`
                        font-family: 'Objectivity';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 160%;
                        /* identical to box height, or 16px */
                        color: rgba(238, 238, 238, 0.7);
                        text-align: center;
                    `}
                >
                    {formatBytes(usedStorage)} / {formatBytes(availableStorage)} {strapiData?.StorageText||`Storage`}
                </div>
                <div css={css`
                    background: rgba(249, 249, 249, 0.7);
                    height:4px;
                    margin-top:8px;
                    margin-bottom:4px;
                `}>
                    <div css={css`
                        width:${(usedStorage/availableStorage) * 100}%;
                        background: #EB2E83;
                        height:4px;
                        transition:all 0.3s ease-in-out;
                    `}>
                    </div>
                </div>
            </div>
    )
};

const DragAndDropNoAssets = ({imageCompressor}) => {
    const [loading, setLoading] = useState(false);
    const getSegmentPayloadAtom = useAtomValue(segmentEventPayload);

    const queryClient = useQueryClient();

    const onDrop = useCallback(async(acceptedFiles:any) => {
        setLoading(true);
        if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._click_upload_assets_studio}`,getSegmentPayloadAtom);
        let filesCompressed = await Promise.all(acceptedFiles.map(asset => imageCompressor(asset)));
        addAssets(filesCompressed || acceptedFiles).then((res)=>{
            queryClient.invalidateQueries(['assets']).finally(()=>{
                setLoading(false);    
            });
        }).catch((err)=>{
            setLoading(false);
        });
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    }});
    
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudioAssestData?.StudioAssestData;
    if(loading) return (
        <div css={css`
        flex:1;
        overflow-y:auto;
        overflow-x:hidden;
        height: 100%;
        margin:10px 10px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23868686' stroke-width='1' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    `}>
        <Loader />
    </div>
    )
    return <div css={css`
        flex:1;
        overflow-y:auto;
        overflow-x:hidden;
        height: 100%;
        margin:10px 10px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23868686' stroke-width='1' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    `} {...getRootProps()}>
    <div css={css`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        height: 100%;
    `}>
        <input {...getInputProps()} />
        <Icon src={"/icons/log-out.png"} height={24} width={24} />
        <div css={css`
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: center;
                color: #EEEEEE;
                margin-top:16px;
                max-width:200px;
                text-align:center;
        `}>
           {!isDragActive?strapiData?.DragAndDropText || "Drag and drop images from your computer":"Drag Files Here"}
        </div>

        <div css={css`
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            display: flex;
            align-items: center;
            text-align: center;
            text-decoration: underline;
            color: #EEE;
            margin-top:16px;
        `}>
            {strapiData?.UploadAssestSHText||"Upload Assets"}
        </div>
    </div>
    
</div>
}

const AssetsList = ({imageCompressor,assets, isSelected}) => {
    const rawImagesData = useAtomValue(uploadedRawImagesDataAtom);
    const rawImagesLoader = useAtomValue(uploadedImagesLoaderAtom);
    const loaderStrapiData = useSelector((state:any)=> state.strapi.entireStrapiData?.WebListingScreen);

    return (<div css={styles.assetsListContainer} style={{ marginRight: isSelected ? "-18px": "-8px" }}>
        <div
            style={{display:'flex', flexWrap:'wrap', width:'100%', justifyContent:"flex-start", height: "fit-content"}}
        >
            <AddAssetListItem imageCompressor={imageCompressor} />
            {
                rawImagesLoader && Array.isArray(rawImagesData) && rawImagesData?.map((item)=>{
                    return ( 
                        <div  css={styles.imageLoaderContainer}  style={{
                            backgroundImage:`url(${item || ''})`, ...styles.extraImageLoaderStyles
                        }}>
                            <div css={styles.linearAnimationGif}>
                                <img src={loaderStrapiData?.LinearLineAnimation || '/icons/LinearAnimation.gif'} alt="Loading..."/>
                            </div>
                        </div>
                    )}
                )
            }
            {assets.map((asset,index)=><Asset key={asset._id} asset={asset} index={index} />)}
        </div>
    </div>)
}

const Asset = ({asset, index}) => {
    
        const popUpRef = useRef<any>();
        const deletePopUpRef = useRef<any>();
        const toaster = useToast();
        const queryClient = useQueryClient();
        const setActiveProject = useSetAtom<any>(activeProjectAtom);
        const setToAddAssetId = useSetAtom(toAddAssetIdAtom);
        const setToAddAsset = useSetAtom(toAddAssetAtom);
        const {otherCategory, otherSubCategory, isLoading:isCategoriesLoading} = useOtherCategory();
        const [loading,setLoading] = useState<boolean>(false);
        const setIsEditorInitializing = useSetAtom(initializingEditorAtom);
        const setActiveLeftPaneIndex = useSetAtom(leftNavigationActiveIndexAtom);

        
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudionAsHomepage?.StudioAssestData;
    const isStandardInfographic = useAtomValue(standardInfographic);
        const {addProductImage, activeEditor} = useEditorService();

        const createCanvasWithAsset = async(assetURL,assetID) => {
                try{
                    setIsEditorInitializing(true);
                    setToAddAsset(assetURL);
                    setToAddAssetId(assetID);
                    const jobId = await createEmptyEditorJob(otherCategory?._id, otherSubCategory?._id, isStandardInfographic);
                    const job = await getJobDetails(jobId);
                    setActiveProject(job);
        
                }catch(err){
                    setIsEditorInitializing(false);
                    toaster.error("Something went wrong while adding asset to canvas");
                }
            
        }; 
        
        const onClick = useCallback(async()=>{
            if(!asset.isBgRemoved) return popUpRef.current?.open(asset.url,asset._id);
            if(!activeEditor) {
                await createCanvasWithAsset(asset.url,asset._id);
                return;
            }
            const isLayerEmpty = activeEditor.layerManager._layers.value?.length === 0;
            const addingImageToCanvasToasterKey = toaster.info("Adding Image to Canvas", 60000);
            addProductImage(asset.url,ProductImageType.ASSET,asset._id).finally(()=>{
                toaster.close(addingImageToCanvasToasterKey);
                if(isLayerEmpty)setActiveLeftPaneIndex(2);
            })
        }, [asset,addProductImage]);

        const onApply = useCallback(()=>{
            setLoading(true);
            const key = toaster.info("Removing Background",60000);
            removeAssetBackground(asset._id).then(async(res)=>{
                toaster.success("Background Removed");
                const refreshingAssetsToasterKey = toaster.info("Refreshing Assets", 60000);
                queryClient.invalidateQueries(['assets']).then(()=>{
                    toaster.close(refreshingAssetsToasterKey);
                });
                if(!activeEditor) {
                    await createCanvasWithAsset(res.url,asset._id);
                    return;
                }
                const addingImageToCanvasToasterKey = toaster.info("Adding Image to Canvas", 60000);
                addProductImage(res.url,ProductImageType.ASSET,asset._id).finally(()=>{
                    toaster.close(addingImageToCanvasToasterKey);
                })
            }).catch(err=>{
                if(activeEditor) toaster.error(err.response?.data || "Error Removing Background", 2000);
            }).finally(()=>{
                toaster.close(key);
                setLoading(false);
                popUpRef.current.close();
            });
        },[addProductImage,asset]);

        const deleteClickGenerator = useCallback((asset)=>async () =>{
            deletePopUpRef.current.open(asset);
        },[]);

        return <m.div 
        
            initial={{
                transform:'translateY(-10%)',
                opacity: 0,
            }}
            animate={{
                transform: 'translateY(0%)',
                opacity: 1,
                scale:1
            }}
            transition={{
                duration: 0.5,
                delay: index * 0.1,
            }}
            style={{position:'relative',cursor:'pointer'}}>
            <img src={asset.url} css={styles.imageStyle} onClick={onClick} crossOrigin="anonymous"/>
            <div
                style={{
                    color:'white', 
                    fontSize:12, 
                    cursor:'pointer', 
                    height:24, 
                    width:24, 
                    borderRadius:"50%", 
                    background: "#EEEEEE",
                    position:'absolute',
                    right:6,
                    top:14,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                onClick={deleteClickGenerator(asset)}
            >
                <Icon src={ICONS.deleteDarkFill} height={16} width={16}/>
            </div>
            <AddUploadAssetPopUp ref={popUpRef} onRemoveBg={onApply} loading={loading} onApply={createCanvasWithAsset} imageUrl={asset.url}/>
            <DeletePopUp ref={deletePopUpRef} />
        </m.div>
}

const styles = {
    imageStyle: css`
        height:108px;
        width:108px;
        object-fit:contain;
        background:#fff;
        margin-top:8px;
        margin-left:8px;
        transition: all 0.2s ease-in-out;
        &:hover {
            border: 1px solid black;   
            padding: 2px;
            outline: 1px solid white;
        }
    `,
    imageLoaderContainer:css`
        position: relative;
        width: 108px;
        min-height: 108px; 
        aspect-ratio: 1;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:8px;
        margin-left:8px;
        border: 1px solid #EEE;
        opacity: 0.6;
    `,
    linearAnimationGif:css`
        position: absolute;
        height:100%;
        object-fit: contain;
        width:100%;
    `,
    extraImageLoaderStyles:{
        backgroundSize: "contain", 
        backgroundRepeat:'no-repeat',
        backgroundPosition: "center", 
        padding:8
    },
    assetsListContainer:css`
        flex:1;
        overflow-y:auto;
        overflow-x:hidden;
        height: 100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        padding:10px;
        margin-left:-8px;
        margin-top:-8px;
        transition: all 0.3s ease-in;
    `
};

 const DeletePopUp = React.forwardRef((props,ref) => {
    const popUpRef = useRef<IPopUpRef>();
    const [asset, setAsset] = useState(null);
    const queryClient = useQueryClient();
    const toaster = useToast();

    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudionAsHomepage?.StudioAssestData;
    const onDelete = useCallback(()=>{
        let callback = async () =>{
            const key = toaster.info("Deleting Asset",60000);
            popUpRef.current.close();
            try {
                await deleteAsset(asset._id);
                toaster.success("Asset Deleted");
                queryClient.invalidateQueries(['assets']);
            }
            catch(err) {
                toaster.error(err.response.data || "Error Deleting Asset", 2000);
            }
            toaster.close(key);
        }
        callback();
    },[asset,popUpRef.current]);

    useImperativeHandle(ref,()=>({
        open:(asset)=>{
            popUpRef.current.open();
            setAsset(asset);
        },
        close:()=>{
            popUpRef.current.close();
        }
    }))

    return <PopUp ref={popUpRef} width={460} footer={(<>
        <div style={{display:"flex", flexDirection:'row', justifyContent:'flex-end', paddingLeft:24, paddingRight:24}}>
            <div
                css={css`
                    background: #EEEEEE;
                    border-radius: 4px;
                    font-family: 'Objectivity-medium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                    padding:10px 24px;
                    color: #262626;
                    margin-right:16px;
                    cursor:pointer;
                `}
                onClick={()=>popUpRef.current?.close()}
            >{strapiData?.CancelCTA||"Cancel"}</div>
            <div
                css={css`
                    padding:10px 24px;
                    font-family: 'Objectivity-medium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 150%;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    background: #E50000;
                    border-radius: 4px;
                    cursor:pointer;
                `}
                onClick={onDelete}
            >Yes, Delete</div>
        </div>
    </>)}>
        <div
            css={css`
                font-family: 'Objectivity-medium';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                /* identical to box height, or 24px */
                color: #262626;
            `}
        >
            {asset?.name}
        </div>
        <div css={css`
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            /* or 22px */
            margin-top: 10px;
            
            color: #262626;
            margin-bottom:-8px;
        `}>
            Are You Sure you want to delete this Item?
        </div>
    </PopUp>
 });

const AddAssetListItem = ({imageCompressor}) => {
    const DIMENSIONS = {
        width: 108,
        height: 108
    }
    const fileUploaderRef = useRef<any>();
    const toaster = useToast();
    const queryClient = useQueryClient();
    const setRawImagesData = useSetAtom(uploadedRawImagesDataAtom);
    const [rawImagesLoader, setRawImagesLoader] = useAtom(uploadedImagesLoaderAtom);
    const getSegmentPayloadAtom = useAtomValue(segmentEventPayload);

    const onFilesSelect = async (files) => {
        const filesData:any = Array.from(files);
        setRawImagesLoader(true)
        const key = toaster.info("Uploading Assets....", 5000);

        const newPreviews = filesData.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
            reader.onloadend = () => {
            resolve(reader.result);
            };
        });
        });

        Promise.all(newPreviews).then((results) => {
            setRawImagesData(results);
        });

        if(Object.keys(getSegmentPayloadAtom || {}).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._click_upload_assets_studio}`,getSegmentPayloadAtom);
        let filesCompressed = await Promise.all(files.map(asset => imageCompressor(asset)));
        await addAssets(filesCompressed || files).then((res)=>{
            queryClient.invalidateQueries(['assets']).finally(()=>{
                toaster.close(key);    
            });
        }).catch((err)=>{
            toaster.close(key);
            toaster.error(err?.response?.data || "Error uploading assets...", 5000);
        });
        await queryClient.invalidateQueries(['assets']);
        toaster.close(key);
        setRawImagesLoader(false)
    }

    const openFilePicker = () => {
        if(!fileUploaderRef.current) return;
        fileUploaderRef.current.open();
    };
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.StudioAssestData?.StudioAssestData;


    return (
        <div css={css`
            width:${DIMENSIONS.width}px;
            height:${DIMENSIONS.height}px;
            background: rgba(238, 238, 238, 0.16);
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            border:1px dashed #EEEEEE33;
            margin-top:8px;
            margin-left:8px;
            cursor: ${rawImagesLoader ? 'not-allowed':'pointer'}
        `}
        onClick={openFilePicker}
        >
            <Icon src={"/icons/log-out.png"} height={24} width={24} />
            <div css={css`
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                text-align: center;
                text-decoration: underline;
                color: #EEEEEE;
                margin-top:10px;
                cursor: pointer;
            `}>
                {strapiData?.UploadFilesText||"Upload Files"}
            </div>
            {/* @ts-ignore */}
            <FileUploader ref={fileUploaderRef} acceptedExtensions={["image/jpeg","image/png","image/jpg"]} onFileSelect={onFilesSelect} disabled={rawImagesLoader}/>
           
        </div>
    );
}