import useAuth from '@/hooks/useAuth'
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react'
import {debounce} from 'lodash';

const AuthenticationHandler = (props) => {

    const authContext = useAuth();
    const isInitialized = useRef<boolean>(false);
    const {push} = useRouter();

    const urlBasedloginHandler = async()=>{
        const queryUrl = window.location.search;
        const urlParams = new URLSearchParams(queryUrl);
        const userIdQuery = urlParams.get("userId");
        const codeQuery = urlParams.get('code');
        if(userIdQuery && codeQuery){
          const payload = {
            otp: codeQuery,
            userId: userIdQuery,
            source:"magicQr"
          };
          localStorage.setItem('magicQrInfo',JSON.stringify(true));
          await authContext.magicQrLogin(payload);
          push(window.location.pathname);
          return;
        }
        const token = localStorage.getItem("accessToken");
        authContext.initUserSession(token, undefined).then((res:any)=>{
            if(res?.success) isInitialized.current = true;
        })
      };

    useEffect(()=>{
        if(isInitialized.current) return;
        debounce(urlBasedloginHandler,1000)();
    },[]);

    return (<React.Fragment>
        {props.children}
    </React.Fragment>)
}

export default AuthenticationHandler;