import { Box } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "src/redux/store";

export default function MobileInput(props) {
  const [currCountryCode,setCurrCountryCode] = useState(1);
  const {referredPhoneNo, setReferredCountryCode} = useSelector(state=>state.appRedirect);
  const validateNumber = (val, country) => {
    let validNumberOfDigits = _.get(country, "format")
      .split("")
      .filter((item) => item == ".").length;
    if (val.length == validNumberOfDigits) return true;
    return false;
  };

  const PhoneInputHandler = (val, country) => {
    if(referredPhoneNo && setReferredCountryCode)return;
    if(currCountryCode !== country.dialCode){
      setCurrCountryCode(country.dialCode);
      props.setMobile({
        mobile: "",
        countryCode: country.dialCode,
        isValid: validateNumber(val, country),
      });
      return;
    };
    props.setMobile({
      mobile: val.slice(country.dialCode.length),
      countryCode: country.dialCode,
      isValid: validateNumber(val, country),
    });
    setCurrCountryCode((prev)=> prev !== country.dialCode ? country.dialCode : prev);
  }

  return (
    <Box>
      <PhoneInput
        placeholder={props.textPlaceholder}
        value={props.countryCode + "" + props.mobile}
        country="us"
        disabled={props.disabled || (referredPhoneNo && setReferredCountryCode)}
        onChange={PhoneInputHandler}
        countryCodeEditable={false}
        containerStyle={{
        borderRadius: "8px",
        }}
        inputStyle={{
          width: "100%",
          height: "54px",
          fontSize:"14px",
          marginRight: "-1px",
          borderRadius: "3px",
        }}
        buttonStyle={{
          "margin-left": "-1px",
          borderBottomLeftRadius: "3px",
          borderTopLeftRadius: "3px",
          
        }}
      />
    </Box>
  );
}
