import { atom } from "jotai";


export const activeProjectAtom = atom(false);
export const bgRemovedImportAtom = atom(false);
export const batchEditorAtom = atom(false);
export const bgRemovedImageAtom = atom([]);
export const pageAtom = atom([]);
export const selectedTemplateAtom = atom([]);
const newMap = new Map();
export const editorObjectAtom = atom(newMap);
export const backgroundColorAtom = atom();
export const widthAtom = atom();
export const heightAtom = atom();
export const coverageAreaAtom = atom();
export const positionYAtom = atom();
export const positionXAtom = atom();
export const selectedMarketPlaceAtom = atom();
export const adjustmentsAtomsMap = new Map();
 const brightnessAtomsMap = new Map();
 const contrastAtomsMap = new Map();
 const hueAtomsMap = new Map();
 const saturationAtomsMap = new Map();
export const brightnessAtom = atom(brightnessAtomsMap);
export const saturationAtom = atom(saturationAtomsMap);
export const hueAtom = atom(hueAtomsMap);
export const contrastAtom = atom(contrastAtomsMap);
export const appliedAtom = atom(true);
export const fileCountAtom = atom();
export const marketplaceNameAtom=atom();
export const customPresetAtom=atom(false);
export const customPresetAppliedAtom = atom(false);
export const editBatchProjectAtom = atom(null);
export const pagesStatesAtom = atom([]);
export const editorInitializedAtom = atom(false);
export const updateInitializedAtom = atom(false);
pagesStatesAtom.debugLabel = "PagesStatesAtomBatchEditor"

/*
*
*
*/
export const batchEditorEditLoading = atom(false);
export const batchEditorJobNameAtom = atom("");
export const batchEditorJobNameList = atom([]); 
export const batchShowSuccessDialog = atom(false);
export const batchEditorActiveJobAtom = atom(null);
export const batchJobIdAtom = atom(null);
export const batchDeletedImageListAtom = atom([]);
export const batchCompareImageAtom = atom(null);
export const batchSelectedLayerAtom = atom(undefined);
export const batchSelectedBrightnessAtom = atom(0);
export const batchSelectedSaturationAtom = atom(0);
export const batchSelectedContrastAtom = atom(0);
export const batchZoomAtom = atom(100);
export const batchInitialZoomAtom = atom(1);
export const batchShadowTypeAtom = atom(null);
export const batchShadowBlurAtom = atom(0);
export const batchShadowOpacityAtom = atom(70);
export const batchShadowHorizontalAtom = atom(0);
export const batchShadowVerticalAtom = atom(0);
export const batchOpacityAtom=atom(1);
export const batchShadowTypes={
    aiShadow:"aiShadow",
    dropShadow:"dropShadow",
    none:"none",
}
/** Change in zoom Slider */
export const changeZoomSliderAtom = atom(false);

export const batchEditorSelectedPresetAtom = atom({marketplace_id:"64cb7e5423d9644b4cf689e2", name:"Amazon, eBay, Etsy, Walmart"});
export const aiTaskDisableAtom = atom(false);
export const sendForProTouchAtom = atom(false);
