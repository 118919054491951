import { css } from "@emotion/react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ICONS } from "src/modules/ui-elements/Icon";
import IconButton, { IconButtonSizes, IconButtonTypes } from "src/modules/ui-elements/IconButton";
import { activePageAtom, pagesAtom, editorSlider, activeProjectAtom, pageStatesAtom, settingProjectNameAtom, segmentEventPayload, textureAndColorActiveAtom, jobSuccessScreenDataAtom, standardInfographic, initializingEditorAtom, historyDalleAtom } from "../Atoms";
import { useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { setJobName } from "../services/Jobs";
import useAuth from "@/hooks/useAuth";
import CanvasTopBar from "./CanvasTopBar";
import ErrorBoundary from "@/modules/canvas/errorBoundry";
import RightPaneHeader from "../RightPane/RightPaneHeader";

export default function EditorTopToolBar({deletePageRef, resizePopUpRef}) {

    const {user} = useAuth();
    const isInfoGraphicUser = user?.user?.infoGraphicsUser || false;
    const isStandard = useAtomValue(standardInfographic)

    const [activePage, setActivePage] = useAtom(activePageAtom);
    const editorSliderRef = useAtomValue(editorSlider);

    const [editorInitializing, setIsEditorInitializing] = useAtom(
        initializingEditorAtom
    );
    const [pages, setPages] = useAtom(pagesAtom);
    const activeProject = useAtomValue(activeProjectAtom);

    const debounced = useCallback(debounce((activePage)=>{
        editorSliderRef?.current?.slickGoTo(activePage);
    },500,{trailing: true,leading:true}),[editorSliderRef?.current]);

    const slideNext = debounce(() => {
        if(activePage >= pages?.length-1) return;
        const nextPageNo = activePage+1;
        setActivePage(nextPageNo);
        editorSliderRef?.current.slickGoTo(nextPageNo);
    }, 500)

    const slidePrev = debounce(() => {
        if(activePage <= 0) return;
        const nextPageNo = activePage-1;
        setActivePage(nextPageNo);
        editorSliderRef?.current.slickGoTo(nextPageNo);
    }, 500);

    useEffect(()=>{
        debounced(activePage);
    },[activePage, debounced]);
    let count = useRef(0);
    const debouncedProjectNameApiCall = useCallback(debounce(async (projectName,currCount)=>{
        count.current++;
        setSettingProjectName(true);
        await setJobName(activeProject.job_id,projectName).catch(console.error);
        if(count.current == currCount+1) setSettingProjectName(false);
    },200,{trailing: true,leading:false}),[activeProject?.job_id]);

    return (<div css={styles.container} >
        {(window.location.pathname !== '/dashboard/editor') &&
        ((isInfoGraphicUser && !isStandard) || !isInfoGraphicUser) &&
         <div css={styles.middleSection}>
            <IconButton icon={ICONS.chevronLeftDark} size={IconButtonSizes.md} type={IconButtonTypes.dark} onClick={slidePrev} style={{background: "#EEE", padding: "10px"}} />
            <div css={styles.pageNumberContainer}>
                <span css={styles.fw900}>Page{" "}{activePage+1 || "0"}</span>{"  "}/{"  "}{pages?.length || "0"}
            </div>
            <IconButton icon={ICONS.chevronRightDark} size={IconButtonSizes.md} type={IconButtonTypes.dark} onClick={slideNext}  style={{background: "#EEE", padding: "10px"}} />
        </div>}

        {activeProject && !editorInitializing && (
            <div css={styles.rightSection}>
                <CanvasTopBar resizePopUpRef={resizePopUpRef} deletePageRef={deletePageRef} />
                <ErrorBoundary name={'RightPaneHeader'}><RightPaneHeader /></ErrorBoundary>
            </div>
        )}
        
    </div>);
}

export const Loader = ({height=12, width=12, type="dark", flex="1", style={}}) => (
    <div css={styles.loaderContainer(flex)} style={style}>
        <div css={styles.loader(type,width)} style={{height,width}}></div>
    </div>
)

const styles ={ 
    container: css`
        background: #fff;
        // padding: 10px 10px;
        flex-direction:row;
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
        top: 0px;
        z-index: 1001;
        border-bottom: 1px solid #DEDEDE;
    `,
    leftSection: css`
        display:flex; 
        flex:1;
    `,
    middleSection: css`
        display:flex;
        gap: 10px;
        align-items: center;
        padding-left: 10px;
    `,
    rightSection: css`
        display:flex; 
        flex:1; 
        justify-content:flex-end; 
        gap:10px;
        align-items:center;
        position:relative;
    `,
    inputContainer: {
        width:"172px", 
        display:"flex"
    },
    pageNumberContainer: css`
        padding:6px 16px;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #262626B2;
        background: #EEEEEE;
    `,
    rightSectionDivider: css`
        height:24px;
        width:1px;
        background:rgba(238, 238, 238, 0.24);
        margin-right:16px;
    `,
    loaderContainer: (flex)=> css`
        display:flex;
        flex:${flex};
        flex-direction:column;
        height: 100%;
        align-items:center;
        justify-content:center;
    `,
    loader: (type,width) => css`
        @keyframes spin {
            from {
                transform:rotate(0deg);
            }
            to {
                transform:rotate(360deg);
            }
        }
        border-radius: 50%;
        border: ${Number(width)/6}px solid ${type!="light"?type=="voilet"?"rgb(166, 74, 247,0.24)": type === "lightest" ? "rgba(0, 0, 0, 0.25)": "rgba(255, 255, 255, 0.24)":"rgba(0, 0, 0, 0.24)"};
        border-left-color: ${type!="light"?type=="voilet"?"rgb(166, 74, 247,1)":  type === "lightest" ? "rgba(0,0,0,1)": "rgba(0, 0, 0, 1)" :"rgba(0, 0, 0, 1)"};;
        animation: spin 1s linear infinite;
    `,
    backdrop:(visible)=>css`
        position:fixed;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        background:rgba(0,0,0,0.5);
        z-index:1000000000 * ${visible?1:-1}
        height:100vh;
        width:100vw;
        transition:all 0.3s ease;
    `,
    fw900: css`
        -webkit-text-stroke: 0.45px black;
        text-stroke: 0.45px black;
    `
}