import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LoginSection from "./LoginSection";
import OtpSection from "./OtpSection";
import useAuth from "@/hooks/useAuth";
import { useAtomValue, useSetAtom } from "jotai";
import { openVerifyEmailAtom, segmentEventPayload, showLoginPopupAtom } from "src/modules/editor-v2/Atoms";
import { useRouter } from "next/router";
import { getEntireStrapiData } from 'src/redux/reducers/strapi.reducer';
import { getEditorJobId } from "../../services/jobs";
import useToast from '@/hooks/useToast';
import { activeProjectAtom, leftNavigationActiveIndexAtom } from "../editor-v2/Atoms";
import { getJobDetails } from "../editor-v2/services/Jobs";
import useEditorService from '@/hooks/useEditorService';
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { setUserTrackInfoPayload } from 'src/redux/reducers/jobs.reducer';
import { IdentifierWebappEvent } from "src/modules/segment-event-tracking/segmentEventTracking";


const AuthSection = () => {
  const {query, push, pathname} = useRouter();
  const { user, refreshUserDetails } = useAuth();
  const toast = useToast();
  const {destroy} = useEditorService();
  const [autoTrigger,setAutoTrigger] = useState(false);
  const isLoginPopupAtom = useAtomValue(showLoginPopupAtom);
  const setLoginPopupAtom = useSetAtom(showLoginPopupAtom);
  const isVerifyPopupAtom = useAtomValue(openVerifyEmailAtom);
  const setVerifyPopupAtom = useSetAtom(openVerifyEmailAtom);
  const setSegmentPayloadAtom = useSetAtom(segmentEventPayload);
  const setActiveProject = useSetAtom(activeProjectAtom);
  const setleftNavigationActiveIndexAtom = useSetAtom(leftNavigationActiveIndexAtom);
  const authRef = useRef(null);
  const verifyRef = useRef(null);
  const dispatch = useDispatch();

const updateSegmentPayload = useCallback(()=>{
  const userTrackDetailPayload = {
    userId:user?.user?._id || '',
      login_type:user?.user?.login_type || '',
      email:user?.user?.email || '',
      mobile:user?.user?.phone || '',
      userType:user?.user?.userType || '',
      isPremiumSubscription:user?.entity?.entity?.Entity_Plan?.isPremiumSubscription || '',
      credits:user?.entity?.entity?.credits,
      entity_id:user?.entity?.entity?._id || '',
      platform: "web",
      language: user?.user?.language || '',
      region: user?.entity?.entity?.country || '',
      createdAt: moment(user?.user?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || '',
      identifier: user?.user?.email?.length > 0 ? user?.user?.email : user?.user?.phone ,
      login_mode: user?.user?.meta_data?.login_mode || '',
      name: user?.user?.name || '',
      plan_Start_Date: moment(user?.entity?.entity?.Entity_Plan?.Start_Date).format('MMMM Do YYYY, h:mm:ss a') || '',
      plan_id:user?.entity?.entity?.Entity_Plan?.plan_id || '',
      recharge_list_type:user?.entity?.entity?.Entity_Plan?.recharge_list_type || '',
      plan_Expiry_Date: moment(user?.entity?.entity?.Entity_Plan?.validTill).format('MMMM Do YYYY, h:mm:ss a') || '',
      entity_plan_id:user?.entity?.entity?.Entity_Plan?._id || '',
      referredByEntity:user?.entity?.entity?.referredByEntity?._id,
      referredByEntityName:user?.entity?.entity?.company_name,
      user_role:user?.user?.user_role?.name
 }
  setSegmentPayloadAtom(userTrackDetailPayload)
  dispatch(setUserTrackInfoPayload(userTrackDetailPayload));
  if(user?.user?._id && localStorage.getItem("identifier_call")){
    IdentifierWebappEvent(user?.user?._id,userTrackDetailPayload);
    localStorage.removeItem("identifier_call")
  };
},[user?.user?._id,user?.entity?.entity?.credits,user]);

const selectedLanguageRef = useMemo(()=>(user?.user?.language || 'en'),[user?.user?.language])

const multilangCallback = useCallback(()=>getEntireStrapiData(selectedLanguageRef),[selectedLanguageRef]);

const handleCreateJobFromTemplate = async(_id) => {
  try{
    const response = await getEditorJobId({templateId:_id});
    if(response.data?.data?.job_id){
      destroy();
      const newJob = await getJobDetails(response.data?.data?.job_id);
      setActiveProject(newJob);
      setleftNavigationActiveIndexAtom(0);
    }
      else toast.error('Something went wrong');
  }catch(error){
    console.log(error);
    refreshUserDetails();
    toast.error('Something went wrong here');
  }
};

  useEffect(()=>{
    if(isLoginPopupAtom){
      authRef.current?.open();
      setLoginPopupAtom(false);
    }
  },[isLoginPopupAtom]);

  useEffect(()=>{
    if(isVerifyPopupAtom){
      verifyRef.current?.open();
      setVerifyPopupAtom(false);
    }
  },[isVerifyPopupAtom]);

  useEffect(()=>{
    if(localStorage.getItem("magicQrInfo"))return;
    if(query?.login){
      setLoginPopupAtom(true);
      setAutoTrigger(true);
      sessionStorage.setItem('login_flow',true);
    }
  },[query]); 

  useEffect(()=>{
    if(user?.user?._id){
      multilangCallback();
      updateSegmentPayload();
    }
  },[user?.user?._id]);

  return <div 
  style={{display:'flex',width:'2px'}}>
   <LoginSection authRef={authRef} verifyRef={verifyRef} autoTrigger={autoTrigger} setAutoTrigger={setAutoTrigger} handleCreateJobFromTemplate={handleCreateJobFromTemplate} />
   <OtpSection authRef={authRef} verifyRef={verifyRef} handleCreateJobFromTemplate={handleCreateJobFromTemplate}/>
    </div>;
};

export default AuthSection;
