import { css } from "@emotion/react"
import { useAtom, useAtomValue } from "jotai"
import { selectedColorAtom } from "../../../Atoms"
import { useMemo } from "react";
import useEditorTextLayerService from "../../../../../hooks/useEditorTextLayerService";
const COLOR_TYPE = {
    SQUARE:'SQUARE',
    CIRCLE:"CIRCLE"
}

export default function ColorListItem({color, type=COLOR_TYPE.SQUARE, onClickHandler=()=>{}}) {
    const [selectedColor, setSelectedColor] = useAtom(selectedColorAtom);
    const isSelected = useMemo(()=>selectedColor===color, [selectedColor, color]);
    const {setSelectedLayerColor} = useEditorTextLayerService();
    const onClick = () => {
        setSelectedColor(color);
        setSelectedLayerColor(color);
        onClickHandler(color);
    }
    const currStyle = styleController(type);
    return <div css={[styles.listContainer(isSelected?color:"#EEEEEE"),currStyle.listContainer]} onClick={onClick}>
        <div css={[styles.colorBox(color),currStyle.colorBox]}></div>
    </div>
}

const styles = {
    listContainer: (color)=>css`
        border: 1px solid ${color};
        filter: drop-shadow(0px 3px 32px rgba(38, 38, 38, 0.08));
        border-radius: 6px;
        padding:4px;
        margin-right:4px;
        margin-bottom:4px;
    `,
    colorBox:(color) => css`
        width: 26px;
        height: 26px;
        background: ${color};
        border: 1px solid rgba(38, 38, 38, 0.05);
        box-shadow: 0px 3px 32px rgba(38, 38, 38, 0.08);
        border-radius: 4px;
    `
}
function styleController(type){
    let listContainer,colorBox;
    switch(type){
        case COLOR_TYPE.SQUARE:
            {
                listContainer=css`
                `;
                colorBox=css`
                `;
                break;
            }
        case COLOR_TYPE.CIRCLE:
            {
                listContainer=css`
                border-radius:50%;
                `;
                colorBox=css`
                border-radius:50%;
                `;
                break;
            }
    }

    return {listContainer,colorBox}
}