import React from "react";
import {css} from '@emotion/react';
import { CircularProgress } from "@mui/material";
import { AuthContext } from "@/contexts/JWTContext";
import { useContext } from "react";

const MaskLayer = () => {
  const ctx = useContext(AuthContext);

  return (
    <>
      {(ctx?.isSocialLogin || ctx?.isLoadingScreen ) && (
        <div css={styles.container}>
          <CircularProgress
            sx={{ "&.MuiCircularProgress-root": { color: "white" } }}
          />
          <p css={styles.text}>Loading Please wait...</p>
        </div>
      )}
    </>
  );
};

const styles={
    container:css`
    position:absolute;
    z-index:1301;
    background: rgba(0, 0, 0, 0.400);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;`,
    text:css`
    color:#fff;
    font-size:16px;
    margin-left:10px;`
}
export default MaskLayer;
