import axios from "src/utils/axios";
import { get } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "src/redux/store";
import {
  marketplaceBulkSyncApi,
  getChannelAdvisorProductsApi,
  getWalmartProductAttributesApi,
  getMarketplaceProductImagesApi,
  syncImagesToChannelAdvisorApi,
  getChannelAdvisorProductAttributesApi,
  getAmazonProductsApi,
  getAmazonProductDetailsApi,
  syncToAmazonApi,
  getWalmartProductsApi,
  getShopifyProductsApi,
  syncToWalmartApi,
  syncToShopifyApi,
  getShopifyProductAttributesApi
} from "src/services/job-order";
import { getMarketplaceSyncEntriesApi } from "src/services/my-work";
import { setMyProjectLoading, setMyProjectRefreshPage, setFetchingAllJobsList, setAllProjectList } from "./myProject.reducer";

const initialState = {
  isLoading: false,
  error: null,
  images: [],
  job:{},
  jobs: {},
  jobSearch: [],
  jobSearchCount: 0,
  jobId: null,
  newJobId: null,
  jobName: "",
  selectedTemplates: [],
  channelAdvisor: {},
  amazon: {},
  shopify: {},
  marketplace: {
    hasMore: true,
  },
  marketPlaceList: [],
  refreshCount:false,
  approvalStateChanged:false,
  jobOrderImages:{},
  confirmJobOrderImages:{},
  filterSearch:{},
  userTrackInfoPayload:{},
  orderHistory:{},
  paymentHistory:{},
  imagesForAdaptation:[],
};

const DEFAULT_FILTERS_MY_WORK_LIST = {
  skip: 0,
  limit: 20,
  selectedCategory: [],
  selectedUser: "",
  status: "",
  jobOrder_status: [],
  sortType: "Recent",
  dateFilter: "Last 10 Days",
  fromDate: "",
  tillDate: "",
};

const slice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Refresh Count
    setRefreshCount(state, action){
      state.refreshCount = action.payload;
    },
    // SET USER INFO FOR TRACKING
    setUserTrackInfoPayload(state, action){
      state.userTrackInfoPayload = action.payload;
    },
    // GET JOB ID
    setJobId(state, action) {
      state.isLoading = false;
      state.jobId = action.payload;
    },

    // SET JOBS
    setJobs(state, action) {
      state.jobs = action.payload;
    },

    // SET JOB
    setJob(state, action) {
      state.isLoading = false;
      state.job = action.payload;
    },
    // SET NEW JOB ID
    setConfirmOrderJobId(state, action) {
      state.newJobId = action.payload;
    },
    // GET JOB NAME
    setJobName(state, action) {
      state.isLoading = false;
      state.jobName = action.payload;
    },

    // GET IMAGES
    setImages(state, action) {
      state.isLoading = false;
      state.images = action.payload;
    },

    // SET TEMPLATES
    setSelectedTemplates(state, action) {
      state.isLoading = false;
      state.selectedTemplates = action.payload;
    },

    setJobSearch(state, action) {
      state.isLoading = false;
      state.jobSearch = action.payload;
    },

    setJobSearchCount(state, action) {
      state.isLoading = false;
      state.jobSearchCount = action.payload;
    },

    setJobOrder(state, action) {
      state.isLoading = false;
      state.jobOrderImages = action.payload;
    },
    setCompleteJobOrder(state, action) {
      state.isLoading = false;
      state.confirmJobOrderImages = action.payload;
    },
    setMarketPlaceList(state, action) {
      state.isLoading = false;
      state.marketPlaceList = action.payload;
    },

    setChannelAdvisor(state, action) {
      state.isLoading = false;
      state.channelAdvisor.products = action.payload.products;
    },

    setChannelAdvisorImageSyncStatus(state, action) {
      state.isLoading = false;
      state.channelAdvisor.imageSyncStatus = action.payload.status;
      state.channelAdvisor.imageSynError = action.payload.error;
      state.channelAdvisor.message = action.payload.message || "";
      state.channelAdvisor.statusCode = action.payload.statusCode || null;
    },

    setMarketplaceProductFetchStatus(state, action) {
      state.isLoading = false;
      state.marketplace.productFetchStatus = action.payload.status;
      state.marketplace.productFetchError = action.payload.error;
    },

    setMarketplaceProductImages(state, action) {
      state.isLoading = false;
      state.marketplace.productImages = action.payload.data;
    },

    setMarketplaceProductImagesStatus(state, action) {
      state.isLoading = false;
      state.marketplace.productImageFetchStatus = action.payload.status;
      state.marketplace.productFetchError = action.payload.error;
    },

    setMarketplaceSyncResponse(state, action) {
      state.isLoading = false;
      state.marketplace.syncResponse = action.payload.data;
    },

    setMarketplaceSyncEntries(state, action) {
      state.isLoading = false;
      if (action.payload.getEntriesStatus)
        state.marketplace.getEntriesStatus = action.payload.getEntriesStatus;
      if (action.payload.data)
        state.marketplace.syncEntries = {
          ...(state.marketplace.syncEntries
            ? state.marketplace.syncEntries
            : {}),
          ...action.payload.data,
        };
      if (action.payload.rawData)
        state.marketplace.rawSyncEntries = [
          ...(state.marketplace.rawSyncEntries
            ? state.marketplace.rawSyncEntries
            : []),
          ...action.payload.rawData,
        ];
      if (action.payload.getEntriesError)
        state.marketplace.getEntriesError = action.payload.getEntriesError;
      state.marketplace.hasMore = action.payload.hasMore;
      if (action.payload.limit)
        state.marketplace.syncEntriesPage = Math.ceil(
          state.marketplace?.rawSyncEntries?.length / action.payload.limit
        );
    },

    flushMarketplaceSyncEntries(state) {
      state.isLoading = false;
      state.marketplace.getEntriesStatus = null;
      state.marketplace.syncEntries = {};
      state.marketplace.rawSyncEntries = [];
      state.marketplace.getEntriesError = null;
      state.marketplace.hasMore = true;
      state.marketplace.syncEntriesPage = 0;
    },

    setMarketplaceBulkSyncResult(state, action) {
      state.isLoading = false;
      state.marketplace.bulkSyncResult = action.payload.data;
    },

    setMarketplaceBulkSyncStatus(state, action) {
      state.isLoading = false;
      state.marketplace.productAttributesStatus = action.payload.status;
      state.marketplace.paroductAttributesError = action.payload.error;
    },

    setMarketplaceAttributes(state, action) {
      state.isLoading = false;
      state.marketplace.attributes = action.payload.data;
    },

    setMarketplaceAttributesState(state, action) {
      state.isLoading = false;
      state.marketplace.attributeStatus = action.payload.status;
      state.marketplace.attributeError = action.payload.error;
    },

    setMarketplaceProducts(state, action) {
      state.isLoading = false;
      state.marketplace.products = action.payload.products;
    },

    setAmazonSummary(state, action) {
      state.isLoading = false;
      state.amazon.productDetails = action.payload.data;
    },

    setShopifySummary(state, action) {
      state.isLoading = false;
        state.shopify.productDetails = action.payload.data;
    },

    setFetchAmazonSummaryStatus(state, action) {
      state.isLoading = false;
      state.amazon.productDetailsStatus = action.payload.status;
      state.amazon.productDetailsError = action.payload.error;
    },
    setFetchShopifySummaryStatus(state, action) {
      state.isLoading = false;
      state.shopify.productDetailsStatus = action.payload.status;
      state.shopify.productDetailsError = action.payload.error;
    },
    setApprovalStateChanged(state,action){
      state.isLoading = false;
      state.approvalStateChanged = !state.approvalStateChanged;
    },
    setFilterSearch(state,action){
      state.isLoading = false;
      state.filterSearch=action.payload;
    },
    setOrderHistory(state,action){
      state.isLoading = false;
      state.orderHistory=action.payload;
    },
    setPaymentHistory(state,action){
      state.isLoading = false;
      state.paymentHistory=action.payload;
    },
    setImagesForAdaptation(state,action){
      state.imagesForAdaptation = action.payload;
    },
    resetJobsReducer(state, action){
      state.isLoading = false;
      state.error = null;
      state.images = [];
      state.job = {};
      state.jobs = [];
      state.jobSearch = [];
      state.jobSearchCount = 0;
      state.jobId = null;
      state.newJobId = null;
      state.jobName = "";
      state.selectedTemplates = [];
      state.channelAdvisor = {};
      state.amazon = {};
      state.shopify = {};
      state.marketplace = {
        hasMore: true
      };
      state.marketPlaceList = [];
      state.refreshCount = false;
      state.approvalStateChanged = false;
      state.jobOrderImages = {};
      state.filterSearch = {};
      state.userTrackInfoPayload = {};
      state.orderHistory = {};
      state.paymentHistory = {};
    },
    resetMarketPlace(state){
      state.marketplace={
        hasMore: true
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setRefreshCount,
  setJobs,
  setJobId,
  setConfirmOrderJobId,
  setJobName,
  setJob,
  setImages,
  setSelectedTemplates,
  startLoading,
  hasError,
  setJobOrder,
  setCompleteJobOrder,
  setChannelAdvisor,
  setChannelAdvisorImageSyncStatus,
  setMarketplaceProductFetchStatus,
  setChannelAdvisorProductImages,
  setChannelAdvisorProductImagesStatus,
  setMarketplaceProductImages,
  setMarketplaceSyncResponse,
  setMarketplaceProducts,
  setApprovalStateChanged,
  setFilterSearch,
  setUserTrackInfoPayload,
  stopLoading,
  setOrderHistory,
  setPaymentHistory,
  setImagesForAdaptation,
  resetJobsReducer,
  resetMarketPlace,
} = slice.actions;

// ----------------------------------------------------------------------

export const getImages = (jobId) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages`,
        {
          params: {
            job_id: jobId,
          },
        }
      );
      const bgRemovedImages = get(response, "data.data.bg_removed_images", []);
      dispatch(slice.actions.setImages(bgRemovedImages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getAllJobImages = (jobId) => {
    return axios.get(
      `/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages`,
      {
        params: {
          job_id: jobId,
        },
      }
    );
}

export const getJobs = (assignedUserId, status, skip, limit = 10) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/v3/shootsetupjobs/myWorkjoborderlists/${skip}/${limit}`,
        {
          params: {
            assigned_user_id: assignedUserId,
            status: status,
          },
        }
      );
      dispatch(slice.actions.setJobs(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getMyWorkList = (params = DEFAULT_FILTERS_MY_WORK_LIST) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v3/exploreTemplates/myWorkList`, {
        params: params,
      });
      dispatch(slice.actions.setJobs(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

let controller = null;
let isRequestInProgress = false;

export const getNewMyWorkList = (params = DEFAULT_FILTERS_MY_WORK_LIST) => {
  return async (dispatch) => {
    if (isRequestInProgress) {
      return;
    }

    if (controller) {
      controller.abort();
     }
     controller = new AbortController();
     const signal = controller.signal;
     dispatch(setMyProjectLoading(true));
     isRequestInProgress = true;
    try {
      const response = await axios.get(`/v4/exploreTemplates/myWorkListNew`, {
        params: params,
        signal:signal
      });
      dispatch(slice.actions.setJobs(response.data.data));
      controller=null;
      dispatch(setMyProjectRefreshPage(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if(error?.code !== "ERR_CANCELED"){
        dispatch(setMyProjectLoading(false));
      }
    } finally {
      isRequestInProgress = false;
    }
  };
};

export const getAllMyWorkList = (params = DEFAULT_FILTERS_MY_WORK_LIST) => {
  return async () => {
     dispatch(setFetchingAllJobsList(true));
    try {
      const response = await axios.get(`/v4/csv-format/get-job-ids`, {
        params: params,
      });
      dispatch(setAllProjectList(response.data?.data || []));
      dispatch(setFetchingAllJobsList(false));
    } catch (error) {
      dispatch(setFetchingAllJobsList(false));
    }
  };
};

export const getMyOrderHistory = (params={DEFAULT_FILTERS_MY_WORK_LIST,status:"All",dateFilter:""}) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v3/exploreTemplates/myWorkList`, {
        params:params
      });
      dispatch(slice.actions.setOrderHistory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const getMyPaymentHistory = (entity_id,params,skip=0,limit=20) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v4/invoices/invoice/${entity_id}/${skip}/${limit}`,{
        params:params
      });
      dispatch(slice.actions.setPaymentHistory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const searchJobs = (searchStr, skip, limit) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v3/exploreTemplates/jobSearchList`, {
        params: {
          searchStr: searchStr,
          skip: skip,
          limit: limit,
        },
      });
      dispatch(slice.actions.setJobSearch(response.data.data.suggestionList));
      dispatch(slice.actions.setJobSearchCount(response.data.data.count));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};
 
export const getJobOrderById = async (jobId, status = "COMPLETED") => {
  dispatch(slice.actions.startLoading());
  try {
    if (status === "COMPLETED") {
      const { data } = await axios.get(
        `/v3/shootsetupjobs/getAll-angleImage/${jobId}?status=${status}`
      );
      dispatch(slice.actions.setJobOrder(data.data));
    } else {
      const { data } = await axios.get(
        `/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages?job_id=${jobId}`
      );
      const payload = data.data.bg_removed_images.map((img) => ({
        ...img,
        image_url: img.image_png,
      }));
      dispatch(slice.actions.setJobOrder({ image_list: payload }));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getCompletedJobOrderById = async (jobId) => {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.setCompleteJobOrder({}));
  try {
      const { data } = await axios.get(
        `/v4/shootnewLifestyleImage/fetchBackgroundRemovedImages?job_id=${jobId}`
      );
      const payload = data.data.bg_removed_images.map((img) => ({
        ...img,
        image_url: img.image_png,
      }));
      dispatch(slice.actions.setCompleteJobOrder({ image_list: payload }));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const getMarketplaceList = async () => {
  try {
    const { data } = await axios.get(
      `/v4/shootnewLifestyleImage/getShootMarketPlace`
    );
    dispatch(slice.actions.setMarketPlaceList(data.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const setJobOrderData = async (data) => {
  dispatch(slice.actions.setJobOrder(data));
};

export const getChannelAdvisorProducts = async () => {
  try {
    dispatch(
      slice.actions.setMarketplaceProductFetchStatus({ status: "START" })
    );
    const {
      data: { data },
    } = await getChannelAdvisorProductsApi();
    dispatch(
      slice.actions.setMarketplaceProducts({
        products: data,
      })
    );
    dispatch(
      slice.actions.setMarketplaceProductFetchStatus({ status: "FINISH" })
    );
  } catch (e) {
    dispatch(
      slice.actions.setMarketplaceProductFetchStatus({
        status: "FINISH",
        error: e.message,
      })
    );
    console.error(e);
  }
};

export const syncImagesToChannelAdvisor = async (payload) => {
  try {
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_START",
      })
    );
    const {
      data: { data },
    } = await syncImagesToChannelAdvisorApi(payload);
    dispatch(slice.actions.setMarketplaceSyncResponse({ data }));
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_FINISH",
      })
    );
  } catch (e) {
    if (e?.response?.data?.error) {
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_ERROR",
          error: e.response.data.error,
        })
      );
      return;
    }
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_ERROR",
        error: e.message,
      })
    );
  }
};

export const setChannelAdvisorSyncStatus = async (payload) => {
  dispatch(slice.actions.setChannelAdvisorImageSyncStatus(payload));
};

export const getMarketplaceProductImages = async (productId, marketplace, marketplaceMappingId) => {
  try {
    if (!productId) throw Error("Invalid ProductId");
    if (!marketplace) throw Error("Invalid marketplace");
    dispatch(
      slice.actions.setMarketplaceProductImagesStatus({ status: "START" })
    );

    const {
      data: { data },
    } = await getMarketplaceProductImagesApi(productId, marketplace, marketplaceMappingId);
    const dataWithChangedField = data.map((image, index) => ({
      ...image,
      image_url: image.Url,
      image_id: `${image.ProductID}${index}`,
    }));
    dispatch(
      slice.actions.setMarketplaceProductImages({ data: dataWithChangedField })
    );
    dispatch(
      slice.actions.setMarketplaceProductImagesStatus({ status: "FINISH" })
    );
  } catch (e) {
    console.error(e);
    dispatch(
      slice.actions.setMarketplaceProductImagesStatus({
        status: "FINISH",
        error: e.message,
      })
    );
  }
};

export const setMarketplaceProductImagesAction = (payload) => {
  dispatch(slice.actions.setMarketplaceProductImages({ data: payload }))
}

export const getAmazonProducts = async () => {
  try {
    dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'START' }));
    const { data: { data } } = await getAmazonProductsApi();
    dispatch(slice.actions.setMarketplaceProducts({
      products: data,
    }))
    dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'FINISH' }));
  } catch (e) {
    console.error(e);
  }
}

export const getWalmartProducts = async (walmartId,page,size,searchValue) => {
    try {
      dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'START' }));
      const { data: { data } } = await getWalmartProductsApi(walmartId, (page-1)*size, size, searchValue);
      dispatch(slice.actions.setMarketplaceProducts({
        products: data,
      }))
      dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'FINISH' }));
    } catch (e) {
      console.error(e);
      dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'ERROR' }));
      dispatch(slice.actions.setMarketplaceProducts({
        products: [],
      }))
    }
}

export const getShopifyProducts = async (shopifyId) => {
    try {
        dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'START' }));
        const { data: { data } } = await getShopifyProductsApi(shopifyId);
        dispatch(slice.actions.setMarketplaceProducts({
            products: data,
        }))
        dispatch(slice.actions.setMarketplaceProductFetchStatus({ status: 'FINISH' }));
    } catch (e) {
        console.error(e);
    }
}

export const getMarketplaceSyncEntriesAction = async (skip, limit) => {
  try {
    dispatch(
      slice.actions.setMarketplaceSyncEntries({
        getEntriesStatus: "START",
      })
    );
    if (skip === 0) flushMarketplaceSyncEntriesAction();
    const {
      data: { data },
    } = await getMarketplaceSyncEntriesApi(skip, limit);
    
    const result = data.reduce((groupedMarketplace, item) => {
      if (groupedMarketplace[item.marketplaceName])
        groupedMarketplace[item.marketplaceName].push(item);
      else groupedMarketplace[item.marketplaceName] = [item];
      return groupedMarketplace;
    }, {});

    dispatch(
      slice.actions.setMarketplaceSyncEntries({
        getEntriesStatus: "FINISH",
        data: result,
        rawData: data,
        hasMore: data.length >= limit,
        limit,
      })
    );
  } catch (e) {
    let errorMessage = e.message;
    if (e.response) {
      errorMessage = e.response?.data?.error;
    }
    dispatch(
      slice.actions.setMarketplaceSyncEntries({
        getEntriesStatus: "FINSIH",
        getEntriesError: errorMessage || "Something went wrong",
      })
    );
  }
};

export const flushMarketplaceSyncEntriesAction = () => {
  dispatch(slice.actions.flushMarketplaceSyncEntries());
};

export const marketplaceBulkSyncAction = async (
  marketplace,
  jobIds,
  attribute,
  marketplaceId,
  replaceOldImage
) => {
  try {
    dispatch(slice.actions.startLoading());
    dispatch(
      slice.actions.setMarketplaceBulkSyncStatus({
        status: "START",
      })
    );
    const {
      data: { data },
    } = await marketplaceBulkSyncApi(marketplace, jobIds, attribute, marketplaceId,replaceOldImage);
    dispatch(
      slice.actions.setMarketplaceBulkSyncResult({
        data,
      })
    );
    dispatch(
      slice.actions.setMarketplaceBulkSyncStatus({
        status: "FINISH",
      })
    );
    dispatch(slice.actions.stopLoading());
  } catch (e) {
    dispatch(slice.actions.stopLoading());
    dispatch(
      slice.actions.setMarketplaceBulkSyncStatus({
        status: "FINISH",
        error: e.message,
      })
    );
  }
};

export const resetBulkSyncStatus = (status, error) => {
  dispatch(
    slice.actions.setMarketplaceBulkSyncStatus({
      status,
      error,
    })
  );
};

export const getMarketplaceAttributesAction = async (marketplace) => {
  try {
    dispatch(slice.actions.setMarketplaceAttributesState({ status: "START" }));
    let data;
    if (marketplace == "CHANNEL_ADVISOR") {
        data = await getChannelAdvisorProductAttributesApi(marketplace);
    } else if (marketplace == "WALMART") {
        data = await getWalmartProductAttributesApi(marketplace);
    } else if (marketplace == "SHOPIFY") {
        data = await getShopifyProductAttributesApi(marketplace);
    }
    data = data.data;
    dispatch(slice.actions.setMarketplaceAttributes({ data }));
    dispatch(slice.actions.setMarketplaceAttributesState({ status: "FINISH" }));
  } catch (e) {
    console.error(e);
    dispatch(
      slice.actions.setMarketplaceAttributesState({
        status: "FINSIH",
        error: e.message,
      })
    );
  }
};

export const approveThisImage = (image_id) => {
  return axios.put(`v4/shootnewLifestyleImage/manageImage/${image_id}?imageStatus=Approve`);
};

export const declineThisImage = (image_id) => {
  return axios.put(`v4/shootnewLifestyleImage/manageImage/${image_id}?imageStatus=Decline`);
};

export const deleteThisImage = (image_id) => {
  return axios.put(`v4/shootnewLifestyleImage/removeImage?image_id=${image_id}`);
};

export const getAmazonProductDetailsAction = async (sku) => {
  try {
    dispatch(slice.actions.setFetchAmazonSummaryStatus({ status: 'START' }));
    const { data: { data } } = await getAmazonProductDetailsApi(sku);
    dispatch(slice.actions.setAmazonSummary({ data }));
    dispatch(slice.actions.setFetchAmazonSummaryStatus({ status: 'FINISH' }));
  } catch (e) {
    console.error('[getAmazonProductDetailsAction]', e);
    dispatch(slice.actions.setFetchAmazonSummaryStatus({ 
      status: 'ERROR',
      error: e.message,
     }));
  }
}

export const syncToAmazonAction = async (payload) => {
  try {
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_START",
      })
    );
    const {
      data: { data, message, statusCode },
    } = await syncToAmazonApi(payload);
    dispatch(slice.actions.setMarketplaceSyncResponse({ data }));
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_FINISH",
        message: message,
        statusCode: statusCode
      })
    );
  } catch (e) {
    if (e?.response?.data?.error) {
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_ERROR",
          error: e.response.data.error,
        })
      );
      return;
    }
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_ERROR",
        error: e.message,
      })
    );
  }
}

export const syncToWalmartAction = async (walamrtId, payload) => {
  try {
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_START",
      })
    );
    const {
      data: { data },
    } = await syncToWalmartApi(walamrtId, payload);
    dispatch(slice.actions.setMarketplaceSyncResponse({ data }));
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_FINISH",
      })
    );
  } catch (e) {
    if (e?.response?.data?.data) {
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_ERROR",
          error: e.response.data.data,
        })
      );
      return;
    }
    dispatch(
      slice.actions.setChannelAdvisorImageSyncStatus({
        status: "SYNCING_ERROR",
        error: e.message,
      })
    );
  }
}

export const syncToShopifyAction = async (payload) => {
    try {
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_START",
        })
      );
      const {
        data: { data },
      } = await syncToShopifyApi(payload);
      dispatch(slice.actions.setMarketplaceSyncResponse({ data }));
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_FINISH",
        })
      );
    } catch (e) {
      if (e?.response?.data?.error) {
        dispatch(
          slice.actions.setChannelAdvisorImageSyncStatus({
            status: "SYNCING_ERROR",
            error: e.response.data.error,
          })
        );
        return;
      }
      dispatch(
        slice.actions.setChannelAdvisorImageSyncStatus({
          status: "SYNCING_ERROR",
          error: e.message,
        })
      );
    }
  }

  export const setJobsAction = (jobs = []) => {
    dispatch(slice.actions.setJobs(jobs));
  };