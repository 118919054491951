import { useAtomValue } from "jotai";
import {
  backgroundFilterAtom,
  backgroundFilterProductImageIdAtom,
  leftNavigationActiveIndexAtom
} from "../modules/editor-v2/Atoms";
import { getPrespectiveList } from "../modules/editor-v2/services/MyWorks";
import { useQuery } from "@tanstack/react-query";

export function useAngleTemplateQuery() {
  const selectedFilter = useAtomValue(backgroundFilterAtom);
  const productImageId = useAtomValue(backgroundFilterProductImageIdAtom);
  const leftNavigationActive = useAtomValue(leftNavigationActiveIndexAtom);
  const isEnabled = (leftNavigationActive == 2) &&  productImageId ;
  const { data: prespectiveList, isLoading: prespectiveListLoading } = useQuery(
    ["prespectiveSearch", "list", selectedFilter?.imageFormat, productImageId],
    getPrespectiveList,
    {enabled:isEnabled ? true : false}
  );
  return {
    prespectiveList,
    prespectiveListLoading
  };
}
