import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./CropImageView.module.css";
import ReactCrop, { type Crop } from 'react-image-crop';
import PropertyInput from "@/modules/ui-elements/PropertyInput";
import { IPixelDimensions } from "local_modules/editor/src/Interfaces";
import CloseIcon from './closeIcon.svg';
import WidthIcon from './widthIcon.svg';
import AspectRatioLockIcon from './aspectRatioLockIcon.svg';
import HeightIcon from './heightIcon.svg';

interface ICropImageViewProps {
    image: string,
    onCropClose?:()=>void,
    onCropSuccess?:(image:string)=>void
}

interface ICropImageViewRef {
    
}

export default React.forwardRef<ICropImageViewRef,ICropImageViewProps>(function CropImageView(props,ref) {
    const [crop, setCrop] = useState<Crop>(null)
    const [image, setImage] = useState(props.image);
    const imgRef = useRef<HTMLImageElement>(null);
    const [naturalDimensions,setNaturalDimensions] = useState<IPixelDimensions>({height:0,width:0})
    const [loading, setLoading] = useState(false);
    const [hasApplied, setHasApplied] = useState(false);

    const height = useMemo(()=>{
        let scaleX = naturalDimensions.height / imgRef.current?.height;
        return scaleX * crop?.height || 0
    },[naturalDimensions,crop])

    const width = useMemo(()=>{
        let scaleY = naturalDimensions.width / imgRef.current?.width;
        return scaleY * crop?.width || 0
    },[naturalDimensions,crop])

    const canApply = height === naturalDimensions.height && width === naturalDimensions.width;
    
    const applyCrop = async () => {
        if(canApply) return;
        setCrop(null);
        const canvas = document.createElement('canvas');
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
    
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingQuality = 'high';
        
        ctx.drawImage(
            imgRef.current,
            crop.x * scaleX,            
            crop.y * scaleY,            
            crop.width * scaleX,        
            crop.height * scaleY,       
            0,                          
            0,                          
            canvas.width,               
            canvas.height               
        );
        setHasApplied(true);
        let base64 = canvas.toDataURL();
        setImage(base64);
    }


    const handleImageRefOnLoad = ()=>{
        setLoading(false);
        setNaturalDimensions({
            height:imgRef.current.naturalHeight,
            width: imgRef.current.naturalWidth
        })
        setCrop({
            x:0,
            y:0,
            width: imgRef.current.width,
            height: imgRef.current.height,
            unit: "px"
        });
    }

    useEffect(()=>{
        imgRef.current?.addEventListener("load",handleImageRefOnLoad)
        setLoading(true);
        return () => {
            imgRef.current?.removeEventListener("load",handleImageRefOnLoad)
        }
    },[image])

    const onHeightChange = (height) => {
        console.log({height})
        let scaleY = naturalDimensions.height / imgRef.current?.height;
        let cropHeight = height / scaleY;
        let maxCropHeight = imgRef.current.height - crop.y;
        cropHeight = Math.max(0,Math.min(cropHeight,maxCropHeight))
        setCrop(crop=>({...crop,height:cropHeight}));
    }

    const onWidthChange = (width) => {
        let scaleX = naturalDimensions.width / imgRef.current?.width;
        let cropWidth = width / scaleX;
        let maxCropWidth = imgRef.current.width - crop.x;
        cropWidth = Math.max(0,Math.min(cropWidth,maxCropWidth))
        setCrop(crop=>({...crop,width:cropWidth}));
    }
        
    return <div className={styles.container}>
        <div className="header">
                <div className="left-section">
                    <div className={styles.closeIcon} onClick={props?.onCropClose}>
                        <CloseIcon />
                    </div>
                    <div className={styles.headerText}>
                        Crop Image
                    </div>
                </div>
                <div className="right-section">
                    <div className={styles.saveButton} onClick={()=>{hasApplied && props.onCropSuccess(image)}} style={{opacity:hasApplied?1:0.5}}> 
                        <div className={styles.saveButtonText}>
                            Save
                        </div>
                    </div>
                </div>
        </div>
        <div className="divider"></div>
        <div className={styles.row}>
            <div className={styles.colLeft} style={{alignItems:'center',justifyContent:'center'}}>
                <div>
                    <ReactCrop crop={crop} onChange={setCrop} >
                        <img ref={imgRef} src={image} className={styles.image} />
                    </ReactCrop>
                </div>
            </div>
            <div className={styles.colRight}>
                <div className={styles.customSizeContainer}>
                    Custom Size <span className={styles.unitSpan}>(in PX)</span>
                </div>
                
                <div className={styles.inputRow}>
                    <PropertyInput 
                    icon={<WidthIcon />}
                    value={width?.toFixed(0)}
                    propertyText={"W:"} 
                    onChange={onWidthChange}
                    />   
                        <div className={styles.aspectRatio}>
                            <AspectRatioLockIcon />
                        </div>
                    <PropertyInput
                        icon={<HeightIcon />}
                      value={height?.toFixed(0)}
                    propertyText={"H:"} 
                    onChange={onHeightChange}
                    />
                </div>

                <div onClick={applyCrop} className={`${styles.primaryButton} ${canApply && styles.primaryDisabled}`}>
                    {canApply?"Applied!":"Apply"}
                </div>
                <div style={{fontSize:12, margin: '14px 0px'}}>
                    Crop adjustments affect only this image for instant download, with no impact on the original project
                </div>
            </div>
        </div>
    </div>
});