import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, {  useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { activeProjectAtom, jobSuccessScreenDataAtom, pageStatesAtom, pagesAtom, segmentEventPayload, showSuccessProjectPopupAtom, zipAndDownloadImagesDataAtom } from "../Atoms";
import DMSCanvas from "local_modules/editor/src/DMSCanvas";
import { SelectedTemplate, confirmJob, getProTouchRequiredCredits, getRequiredCredits, proTouchConfirmJob, updateInstantDownloadImages } from "../services/Jobs";
import useToast from "@/hooks/useToast";
import JSZip from "jszip";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import {  Icon } from "@/modules/ui-elements/Icon";
import ProjectNameSection from "./ProjectNameSection";
import ConfirmJobPopUpCTAs from "./ConfirmJobPopUpCTAs";
import { Loader } from "../MiddleSection/EditorTopToolBar";
import useAuth from "src/hooks/useAuth";
import { TrackWebappEvent } from "@/modules/segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "@/modules/segment-event-tracking/constant";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import CropImageView from "./CropImageView";
import CheckboxFilledIcon from './checkboxFilled.svg';
import CheckboxIcon from './checkbox.svg';
import CropImageIcon from './cropImageIcon.svg';
import RadioButtonFilledIcon from './radioButtonFilled.svg';
import RadioButtonIcon from './radioButton.svg';
import useEditorService from "@/hooks/useEditorService";
import { Tooltip } from "react-tooltip";

interface IConfirmJobPopUpPropType {

}

interface IConfirmJobPopUpRef {
    open: () => void;
    close: () => void;
}

interface Image {
    image_id: string;
    template_id: string;
    selected: boolean;
    url: string;
}

export default React.forwardRef<IConfirmJobPopUpPropType, IConfirmJobPopUpRef>(function ConfirmJobPopUp(props, ref) {
    
    const {user,refreshUserDetails} = useAuth(); 
    const [isOpen, setIsOpen] = React.useState(false);
    const [type,setType] = React.useState< "jpeg" | "png" >("png");
    const pagesStates = useAtomValue(pageStatesAtom);
    const pages = useAtomValue<DMSCanvas[]>(pagesAtom);
    const toaster = useToast();
    const isDownloadRef = useRef<boolean>(false);
    const activeProject = useAtomValue<any>(activeProjectAtom);
    const [images, setImages] = React.useState<Image[]>([]);
    const [imagesHighRes, setImagesHighRes] = React.useState<Image[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const [loadingSendProTouch, setLoadingSendProTouch] = React.useState(false);
    const [gettingCredits, setGettingCredits] = React.useState(false);
    const [totalCost, setTotalCost] = React.useState<undefined | number>(undefined);
    const [templateCost, setTemplateCost] = React.useState<undefined | number>(undefined);
    const [isComplimentaryJob, setIsComplimentaryJob] = React.useState<boolean>(false);
    const queryClient = useQueryClient();
    const setSuccessProjectValue = useSetAtom<any>(showSuccessProjectPopupAtom);
    const getSegmentPayloadAtom = useAtomValue<any>(segmentEventPayload);
    const setZipAndDownloadImagesData = useSetAtom<any>(zipAndDownloadImagesDataAtom);
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.StudioProjectDetailsNew?.StudioProjectDetailsNew;
    const getAppData = entireStrapiData?.ProfileWebapp?.ProfileWebapp?.GetTheAppOnProfile;
    const selectedTemplates: SelectedTemplate[] = useMemo(()=>{
        return pagesStates.map(pageState=>({image_id:pageState.imageId}))
    },[pagesStates]);
    const router = useRouter();
    const {destroy, clearGrid} = useEditorService();
    // const [selectedImages, setSelectedImages] = React.useState<string[]>([]);

    const getRequiredCreditsInternal = useCallback(async () => {
        if(!Array.isArray(images) || images.length === 0) throw new Error("No images found");
        return await getRequiredCredits(activeProject?.job_id,images.filter(x=>x.selected).map(x=>({image_id: x.image_id})));
    },[activeProject?.job_id, images])

 
    useEffect(()=>{
        if(user?.user?.login_type == 'Guest') return;
        setGettingCredits(true);
        getRequiredCreditsInternal().then(requiredCredits=>{
            setTotalCost(requiredCredits.totalCost);
            setTemplateCost(requiredCredits.templateCost);
            setIsComplimentaryJob(requiredCredits.isComplimentaryShoot);
            setGettingCredits(false)
        }).catch((error)=>{
            setGettingCredits(false);
            console.error(error);
        });
    },[images, getRequiredCreditsInternal])

    const setImagesAsync = () => {
        const resolution = 500;
        return new Promise<any[]>(res=>{
            clearGrid();
            Promise.all(pages.map((page)=>page.save())).then(async objects=>{
                const base64Array = [];
                const base64ArrayHighRes = [];
                for (let index = 0; index < objects.length; index++) {
                    const object = objects[index];
                    const aspectRatio = object?.size?.width/object?.size?.height;
                    const height = (aspectRatio > 1) ? resolution/aspectRatio : resolution;
                    const width = (aspectRatio > 1) ? resolution : resolution*aspectRatio;
                    const editor = new DMSCanvas("hidden-canvas",{height:500,width:500},{height:500,width:500});
                    await editor.load(object);
                    console.log({user}, "watermark")
                    if(user?.user?.login_type === 'Guest') {
                        base64ArrayHighRes.push(editor.toBase64Url());
                        await editor.resize({height,width})
                    }
                    base64Array.push(editor.toBase64Url());
                }
                if(base64ArrayHighRes?.length > 0 ){
                    const highResImages = base64ArrayHighRes.map((imageUrl,index)=>({image_id: pagesStates[index].imageId, template_id: pagesStates[index].selectedTemplate, selected: true, url: imageUrl}))
                    setImagesHighRes(highResImages);
                }
                const images = base64Array.map((imageUrl,index)=>({image_id: pagesStates[index].imageId, template_id: pagesStates[index].selectedTemplate, selected: true, url: imageUrl}))
                setImages(images);
                res(images);
            })
        })
    }

    useImperativeHandle(ref,()=>({
        open: async () => {
            try{
            setIsOpen(true);
            const resolution = 500;
            const setImagesAsync = () => {
                clearGrid();
                return new Promise<void>(res=>{
                    const editor = new DMSCanvas("hidden-canvas",{height:500,width:500},{height:500,width:500});
                    Promise.all(pages.map((page)=>page.save())).then(async objects=>{
                        const base64Array = [];
                        const base64ArrayHighRes = [];
                        for (let index = 0; index < objects.length; index++) {
                            const object = objects[index];
                            const aspectRatio = object?.size?.width/object?.size?.height;
                            const height = (aspectRatio > 1) ? resolution/aspectRatio : resolution;
                            const width = (aspectRatio > 1) ? resolution : resolution*aspectRatio;
                            const editor = new DMSCanvas("hidden-canvas",{height:500,width:500},{height:500,width:500});
                            await editor.load(object);
                            if(user?.user?.login_type === 'Guest') {
                                base64ArrayHighRes.push(editor.toBase64Url());
                                await editor.resize({height,width})
                            }
                            base64Array.push(editor.toBase64Url());
                        }
                        if(base64ArrayHighRes?.length > 0 ){
                            const highResImages = base64ArrayHighRes.map((imageUrl,index)=>({image_id: pagesStates[index].imageId, template_id: pagesStates[index].selectedTemplate, selected: true, url: imageUrl}))
                            setImagesHighRes(highResImages);
                        }
                        const images = base64Array.map((imageUrl,index)=>({image_id: pagesStates[index].imageId, template_id: pagesStates[index].selectedTemplate, selected: true, url: imageUrl}))
                        setImages(images);
                        res();
                    })
                })
            }
            setLoading(true);
            setTotalCost(undefined);
            await new Promise(res=>setTimeout(res,50));
            await setImagesAsync();
            
            setLoading(false);
        }catch(error){
            setIsOpen(false);
            setLoading(false);
            setGettingCredits(false)
        }
        },
        close: () =>{ 
            setIsOpen(false);
            setImages([]);
            setTotalCost(undefined);
            setTemplateCost(undefined);
            setLoading(false);
            setGettingCredits(false);
        }
    }),[pagesStates, pages, activeProject, getRequiredCreditsInternal,user]);

    const disabled = useMemo(()=>!!(loading || gettingCredits || loadingDownload || loadingSendProTouch),[loading,gettingCredits,loadingDownload,loadingSendProTouch]);

    const confirmJobInternal = useCallback(async () => {
        return await confirmJob(activeProject?.job_id,images.filter(x=>x.selected).map(x=>({image_id: x.image_id})));
    },[activeProject?.job_id, images]);


    const _confirmJob = async (successPayload:any) => {
        if(disabled) return;
        let key;
        try {
            await updateInstantDownloadImages(activeProject?.job_id)
            if(successPayload?.images && Array.isArray(successPayload?.images)) {
                successPayload?.images.forEach((item, index)=>{
                    images[index].url = item;
                })
            }
            if(user?.user?.login_type === "Guest"){
                setLoadingDownload(true);
                //  await confirmJobInternal();
                 zipAndDownloadImages(images,successPayload?.projectName, user, type);
                 setZipAndDownloadImagesData({base64Images:imagesHighRes,jobName:successPayload?.projectName, user, type});
                 if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._click_download}`,{
                    ...getSegmentPayloadAtom,
                    job_id:activeProject?.job_id,
                    CategoryName: activeProject?.category || '',
                    subCategoryName: "",
                    category_id: "",
                    Template_1: "",
                    images_clicked_count: images.filter(x=>x.selected).length,
                    jobOrderName: activeProject?.name || "",
                    credit_used: 0,
                    update_date: activeProject?.update_date,
                 });
                 setIsOpen(false);
                 setSuccessProjectValue({...successPayload,job_id:activeProject?.job_id,selected_templates:selectedTemplates,imageType:type});
                 setLoadingDownload(false);
                 return;
                };


            key = toaster.info("Confirming job...",120000);
            setLoadingDownload(true);

            const result = await confirmJobInternal();

            if(result?.isPaymentDue) {
                destroy();
                return router.push("/dashboard/setting?tab=PAY_PER_USE");
            }
            zipAndDownloadImages(images,successPayload?.projectName,user, type);
            
            toaster.close(key);
            toaster.success("Job confirmed");
            setLoadingDownload(false);
            setIsOpen(false);
            if(Object.keys(getSegmentPayloadAtom).length)TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._click_download}`,{
                ...getSegmentPayloadAtom,
                job_id:activeProject?.job_id,
                CategoryName: activeProject?.category || '',
                subCategoryName: "",
                category_id: "",
                Template_1: "",
                images_clicked_count: images.filter(x=>x.selected).length,
                jobOrderName: activeProject?.name || "",
                credit_used: 0,
                update_date: activeProject?.update_date,
                deadlineDate:result?.deadlineDate
             });

            setSuccessProjectValue({...successPayload,deadlineDate:result?.deadlineDate,selected_templates:selectedTemplates});
            queryClient.invalidateQueries();

        } catch(e) {
            console.error(e);
            if(e?.response?.data?.message) toaster.error(e.response.data.message);
            else toaster.error("Something went wrong");
            toaster.close(key);
            setLoadingDownload(false);
        } finally{
            setLoadingDownload(false);
            setIsComplimentaryJob(false);
        }
    }


    const proTouchJobHandler = async (successPayload?:any) => {
        if(disabled) return;
        const key = toaster.info("Confirming job for pro touch...",1200);
        setLoadingSendProTouch(true);
        try {
            const projectCost = await getProTouchRequiredCredits(activeProject?.job_id,images.filter(x=>x.selected).map(x=>({image_id: x.image_id}))); 
            setSuccessProjectValue({
                ...successPayload,
                credits:user?.entity?.entity?.credits,
                totalCost:projectCost?.totalCost,
                totalImages:images.filter(x=>x.selected).length,
                templateCost:projectCost?.templateCost,
                job_id:activeProject?.job_id,
                selected_templates:images.filter(x=>x.selected).map(x=>({image_id: x.image_id})),
                pre_exist:activeProject?.pre_exist,
                guideline:activeProject?.guideline,
                images:images,
                banner_image: activeProject?.banner_image,
                isComplimentaryShoot: projectCost?.isComplimentaryShoot
            });
            setLoadingSendProTouch(false);
            setIsOpen(false);
        } catch(e) {
            console.error(e);
            if(e?.response?.data?.message) toaster.error(e.response.data.message);
            else toaster.error("Something went wrong");
            toaster.close(key);
            setLoadingSendProTouch(false);
        }
        setIsComplimentaryJob(false);
    }

    const [selectedCropIndex, setSelectedCropIndex] = useState<number>(null)

    const onCropImageClick = (index:number) => () => {
        setSelectedCropIndex(index);
    }

    const onCropClose = () => {
        setSelectedCropIndex(null);
    }

    const onCropSuccess = (imageBase64:string) => {
        let _images = [...images];
        if(!_images[selectedCropIndex]) return;
        _images[selectedCropIndex].url = imageBase64;
        setImages(_images);
        setSelectedCropIndex(null);
    }
    
    const onImageClickGenerator = (index:number) => () => {
        if(images.length === 1)
        {
            toaster.error("Please select atleast one image");
            return;
        }
        setImages(images.map((image, _index) => {
            if(_index === index ) {
                return {...image, selected: !image.selected}
            }
            return image;
        }));
    }

    const onCloseIconClick = useCallback(() => {
        if(!loadingSendProTouch || !loadingDownload) setIsOpen(false)
    },[loadingSendProTouch,loadingDownload]);

    return <>
        <div css={styles.backdrop(isOpen)}></div>
        <div css={styles.popUp(isOpen)}>
            {(Number.isInteger(selectedCropIndex)) 
                ? <CropImageView image={images?.[selectedCropIndex]?.url || ""} onCropClose={onCropClose} onCropSuccess={onCropSuccess} />
                :<>
                    <div className="header">
                        <div className="left-section">
                            { strapiData?.ProjectDetailText || "Project Details"}
                        </div>
                        <div className="right-section">
                            <Icon src={"/icons/close.svg"} onClick={onCloseIconClick} height={24} width={24} />
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="popup-row">
                        <div css={styles.leftCol(images.length <= 1)}> 
                            { loading
                                ? <div className="loader">
                                    <Loader type="light" style={legacyStyles.scale1_8}/>
                                  </div>
                                : <div className="image-list">
                                    {images.map((image,index)=>(
                                        <div className="image-item">
                                            <img src={image.url} css={styles.image(images.length)}/>
                                            <div onClick={onImageClickGenerator(index)}  style={legacyStyles.checkbox}>
                                                { image.selected 
                                                    ? <CheckboxFilledIcon />
                                                    : <CheckboxIcon />
                                                }
                                            </div>
                                            <button data-tooltip-id={'toolId'} data-tooltip-content={"Crop"} css={styles.cropImageButton} onClick={onCropImageClick(index)}>
                                                <CropImageIcon />
                                                <Tooltip id={'toolId'} style={styles.tooltipStyle} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                <div className="body-container">
                    <div className="body">
                        <ProjectNameSection />
                        <div css={styles.pt24}>
                            <div css={styles.formatText}> Format </div>
                            <div css={styles.formatSelectionRow}>
                                <div css={type === "png"?styles.formatSelectionCol:styles.formatSelection} onClick={()=>setType("png")}>
                                    <span style={legacyStyles.displayFlex}>
                                        {type === "png"
                                            ? <RadioButtonFilledIcon />
                                            : <RadioButtonIcon />
                                        }
                                    </span>
                                    .png
                                </div>
                                <div css={type === "jpeg"?styles.formatSelectionCol:styles.formatSelection} onClick={()=>setType("jpeg")}>
                                    <span style={legacyStyles.displayFlex}>
                                        {type === "jpeg"
                                            ? <RadioButtonFilledIcon />
                                            : <RadioButtonIcon />
                                        }
                                    </span>
                                    .jpg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div css={styles.loader}>
                        {disabled && <Loader type="light" style={legacyStyles.scale1_8} />}
                        <div css={styles.totalTextContainer}>
                            <span css={styles.totalText}>
                                {strapiData?.TotalImagesText || "Total Images:"} {images.filter(x=>x.selected).length.toLocaleString("en-US",{
                                    minimumIntegerDigits:2,
                                    useGrouping: false
                                })} 
                            </span>
                        </div>
                    </div>
                    
                    <div className="footer">
                        <ConfirmJobPopUpCTAs setImageAsync={setImagesAsync} confirmOrder={_confirmJob} proTouchJobHandler={proTouchJobHandler} setIsOpen={setIsOpen} cancelOrder={()=>router.push("/dashboard/my_projects")} loadingDownload={loadingDownload} loadingSendProTouch={loadingSendProTouch} disabled={disabled} totalCost={totalCost} isComplimentaryJob={isComplimentaryJob}/>
                    </div>

                    <div style={legacyStyles.hiddenCanvas}>
                        <canvas id="hidden-canvas" height={500} width={500}></canvas>
                    </div>
                </div>
            
            </div>
            </>}
        </div> 
    </>
});

const legacyStyles = {
    scale1_8: {
        transform:"scale(1.8)",
    },
    checkbox: {
        cursor: 'pointer',
        position: 'absolute' as any, 
        top:8, 
        right:8
    },
    displayFlex: {
        display:'flex'
    },
    hiddenCanvas: {
        position:'absolute' as any,  
        opacity:0, 
        pointerEvents:'none' as any,
        top:0,
        left:0
    }
}

const styles = {
    backdrop:(visible)=>css`
        position:fixed;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        background:rgba(0, 0, 0, 0.4);
        z-index:100000000000;
        height:100vh;
        width:100vw;
        transition:all 0.5s ease-in-out;
        visibility:${visible?"visible":"hidden"};
        opacity:${visible?"1":"0"};
    `,
    popUp: (visible)=>css`
        display:flex;
        flex-direction: column;
        position:fixed;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width: calc(100vw - 488px);
        min-width:918px;
        max-width:940px;
        z-index:1000000000000001;
        color:grey;
        background:white;
        visibility:${visible?"visible":"hidden"};
        transition:all 0.25s ease;
        opacity:${visible?"1":"0"};
        border-radius: 8px;
        .header {
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:center;
            padding:16px 24px;
        }
        .header .left-section {
            color: #262626;
            font-family: Objectivity;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            display: flex;
            align-items: center;
        }
        .divider {
            display:flex;
            border: 1px solid #EEEEEE;
        }

        .popup-row {
            display:flex;
            flex-direction:row;
            min-height:506px;
            max-height:588px;
        }

        .body-container {
            width: 412px;
            justify-content:space-between;
            display:flex;
            flex-direction:column;
        }

        .body {
            padding:10px 16px;
            flex:1;
        }
        .footer {
            padding:10px 16px;
        }

    `,
    loader:css`
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    `,
    totalTextContainer:css`
    display:flex;
    justify-content:flex-end;
    width:100%;
    `,
    totalText:css`
    color: rgba(38, 38, 38, 0.70);
    font-family: 'Objectivity';
    font-size: 12px;
    font-style: normal;
    padding-right:10px;
    margin-right:8px;
    margin-bottom:2px;
    `,
    leftCol:(single)=>css`
        flex:1; 
        flex-direction:column; 
        display:flex;  
        overflow-y:scroll; 
        background:#F9F9F9; 
        padding:${single?"24px;":"10px 24px"};
        ::-webkit-scrollbar-thumb {
            background: #f1f1f100;
            opacity:0;
            transition: all 2s;
        }
        ::-webkit-scrollbar {
            width: 6px;
        }
        :hover {
            ::-webkit-scrollbar-thumb {
                background: #f1f1f1ff;
                opacity:1;
                border-radius:3px;
                
            }
        }
        .loader {
            flex:1; 
            display:flex; 
            justify-content:center; 
            align-items:center; 
            overflow:visible;
        }
        .image-list { 
            display:flex; 
            flex-direction:row; 
            align-items:center; 
            justify-content:center; 
            gap:8px; 
            margin-bottom:4px; 
            margin-top:4px; 
            flex-wrap:wrap; 
            flex:1;
        }
        .image-item {
            flex:0.5; 
            flex-grow:1; 
            min-width:calc(50% - 12px); 
            position:relative;  
            display:flex; 
            justify-content:center;
        }
    `,
    image: (length:number) => css`
        ${(length <= 1) ? "max-height:506px;" : "width:100%;" } 
        object-fit:contain;
    `,
    cropImageButton: css`
        display:flex;
        position:absolute;
        bottom:8px;
        right:8px;
        background: #F9F9F9;
        border: 1px solid #EEE;
        padding:4px;
        border-radius:4px;
        cursor:pointer;
        transition: all 0.3s ease-in-out;
        :hover {
            border-color: #CCC;
            border-width: 2px;
            border-radius:4px;
        }
    `,
    pt24: css`
        padding-top:24px;
    `,
    formatText: css`        
        color: #262626;
        font-family: Objectivity;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%;
    `,
    formatSelectionRow: css`
        display:flex;
        flex-direction:row;
        gap:16px;
        margin-top:10px;
    `,
    formatSelectionCol: css`        
        flex:1;
        display:flex;
        padding:8px;
        border-radius: 4px;
        border: 1px solid #262626;
        background: #FFF;
        color: rgba(38, 38, 38, 0.90);
        gap:8px;
        font-family: Objectivity;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        cursor: pointer;

    `,
    formatSelection: css`
        flex:1;
        display:flex;
        padding:8px;
        border-radius: 4px;
        border: 1px solid #EEE;
        background: #F9F9F9;
        color: rgba(38, 38, 38, 0.90);
        gap:8px;
        font-family: Objectivity;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        cursor: pointer;
    `,
    tooltipStyle: {
        display:'flex', 
        zIndex:1, 
        padding:6, 
        alignItems:'center', 
        fontSize:10
    }
}

// Function to decode Base64 string into a Blob
function decodeBase64ToBlob(base64String) {
    const parts = base64String?.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    
    for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
    }
    
    return new Blob([uInt8Array], { type: contentType });
}

async function fetchImageAsBlob(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

async function convertBase64ToJpegBlob(base64) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            canvas.toBlob(resolve, 'image/jpeg');
        };
        img.onerror = reject;
        img.src = base64;
    });
}

export async function zipAndDownloadImages(base64Images:any, jobName:any, user:any, type: "jpeg" | "png") {
    const zip = new JSZip();
    let index = 0;
    // Process each base64 image and add to the zip file
    for (const base64Image of base64Images.filter(i => i.selected)) {
        let blob;

        if (type === "jpeg") {
            // Convert PNG to JPEG
            blob = await convertBase64ToJpegBlob(base64Image.url);
            zip.file(`image_${index + 1}.jpeg`, blob);
        } else {
            // Use PNG as is
            blob = decodeBase64ToBlob(base64Image.url);
            zip.file(`image_${index + 1}.png`, blob);
        }
        index++;
    }

    // Append a QR code for non-app users on their first job
    if (!user?.user?.meta_data?.isAppInstall && user?.user?.firstShoot) {
        const qrCodeBlob = await fetchImageAsBlob("/icons/QRWebApp.jpg");
        zip.file(`Get_the_App.jpg`, qrCodeBlob);
    }

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: 'blob' }).then((content) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = `${jobName ? jobName : 'images'}.zip`;
        downloadLink.click();
    });
}