import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import NextLink from "next/link";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useSelector } from "src/redux/store";
import Image from "next/legacy/image";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, variant }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.common.white;
  const PRIMARY_DARK = theme.palette.common.black;
  const webStrapiData = useSelector(state=>state?.strapi?.entireStrapiData?.WebappHomepageV22?.WebappHomepageV22);


  let logo = (
    <Box 
      ref={ref} 
      sx={{  
        position: 'relative',
      }}
    >
      <img 
        src={'/images/LogoLoader.gif'} 
        style={{
          minWidth: '500px',
          maxWidth: '500px',
          objectFit: "contain",
          position: "absolute",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );

  if (variant == "light") {
    logo = (
      <Box 
        ref={ref} 
        sx={{  
          position: 'relative',
        }}
      >
      <img 
        src={'/images/LogoLoader.gif'} 
        style={{
          minWidth: '500px',
          maxWidth: '500px',
          objectFit: "contain",
          position: "absolute",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
    );
  }

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <NextLink href="/dashboard/homePage" legacyBehavior>{logo}</NextLink>;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
};

export default Logo;
