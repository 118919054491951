import { css } from "@emotion/react";
import { Icon, ICONS } from "../Icon";

export const PrimaryInputSizes = {
    lg: "lg",
    md: "md",
    sm: "sm",
    xl:"xl"
}

export const PrimaryInputTypes = {
    white: "white",
    grey: "grey",
    dark: "dark",
    darkNoBorders: "darkNoBorders",
    darkGrey: "darkGrey",
}

const ICON_SIZES = {
    lg: 16,
    md: 14
}

export default function PrimaryInput({size,type, icon, value, placeholder, onChange, label,onFocus=()=>{},onBlur=()=>{},onKeyDown=()=>{},containerStyle={},inputStyle={}, endIcon, endIconStyle={}, endIconClick}) {
    if(!size) size = PrimaryInputSizes.md;
    if(!type) type = PrimaryInputTypes.grey;

    return <div style={{...containerStyle}} css={[styles.container, styles.sizes?.[size]?.container, styles.types?.[type]?.container ]} >
        {icon && <Icon src={icon} height={ICON_SIZES?.[size] || 14} width={ICON_SIZES?.[size] || 14} style={styles.icon}/>}
        {label?<label css={[styles.sizes?.[size]?.input, styles.types?.[type]?.input ]} >{label}</label>:""}
        <input css={[styles.input, (icon)?styles.ml8:null, styles.sizes?.[size]?.input, styles.types?.[type]?.input ]} style={inputStyle} placeholder={placeholder} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyDown} />
        {endIcon && <Icon src={endIcon} height={ICON_SIZES?.[size] || 14} width={ICON_SIZES?.[size] || 14} style={{...styles.icon, ...endIconStyle}} onClick={endIconClick}/>}
    </div>
}


const styles = {
    container: css`
        display:flex;
        flex:1;
        border-radius:4px;
        border:0px solid transparent;
        flex-direction:'row'
    `,
    sizes: {
        lg: {
            container: css`
                padding:10px;
            `,
            input: css `
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 170%;
                letter-spacing: 0.01em;
            `,
        },
        md: {
            container: css`
                padding:8px;
            `,
            input: css`
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
            `
        },
        xl: {
            container: css`
                padding:10px;
            `,
            input: css`
                font-family: 'Objectivity';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            `,
        }
    },
    types: {
        white: {
            container: css`
                background:#FFFFFF;
            `,
            input: css`
                color: #262626;
            `
        },
        grey: {
            container: css`
                background:#F9F9F9;
            `,
            input: css`
                color: #676767;
            `
        },
        greyDashed: {
                container: css`
                    background:#F9F9F9;
                `,
                input: css`
                    color: #676767;
                    margin-right: 10px;
                    border: 2px dashed #262626;
                `
        },
        dark:{
            container: css`
                background:#262626;
                border:1px solid #B2B2B2;
            `,
            input: css`
                color: #B2B2B2;
            `
        },
        darkNoBorders:{
            container: css`
                background:#262626;
                color: #B2B2B2;
                
            `,
            input: css`
                color: #B2B2B2;
                font-family: Objectivity;
                position: relative;
                top: 2px;
            `
        },
        darkGrey:{
            container: css`
                background:rgba(238, 238, 238, 0.08);
                color: #B2B2B2;
                
            `,
        },
    },
    input: css`
        flex:1;
        background: transparent;
        border:0px;
        display: flex;
        align-items: center;
        &:focus-visible {
            outline:0px;
        };
        &::placeholder {
            position: absolute;
            bottom: 12%;
            left: 2px;
            pointer-events: none;
            transition: 0.2s ease all;
          }
    `,
    ml8: css`
        margin-left:8px;
    `,
    icon: {
        paddingTop:"2px"
    },
}