import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { ICONS, Icon } from "../Icon";
import { Radio } from "@mui/material";
import ColorPicker from "@/components/ColorPicker";
import CustomizedButton from "../CustomizedButton";
import { useSelector } from "react-redux";
import ColorOptions from "@/modules/editor-v2/RightPane/TextLayerPane/ColorSelectionPane/ColorOptions";
import { useAtomValue, useSetAtom } from "jotai";
import { documentColorsAtom } from "@/modules/editor-v2/Atoms";
import useEditorService from "@/hooks/useEditorService";
import ColorThief from 'colorthief';


const SELECTION_TYPE = {
  COLOR: "COLOR",
  FORMAT: "FORMAT",
};

const DropdownSelection = ({
  startIcon,
  startIconWidth='14px',
  startIconHeight='14px',
  endIcon,
  titleText,
  formatList,
  selectedFilter,
  onSelectHandler = (item) => {},
  onApplyColor = (item) => {},
  type,
  btnStyle,
  btnTextStyle,
  textLabel
}) => {
  const [active, setActive] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#FFFFFF");
  const [applyActive, setApplyActive] = useState(false);
  const ref = useRef(null);
  const {activeEditor} = useEditorService();
  const documentColors = useAtomValue(documentColorsAtom);
  const setDocumentColors = useSetAtom(documentColorsAtom);

  
  const {entireStrapiData} = useSelector((state)=> state.strapi); 
  const strapiData = entireStrapiData?.StudioBackgroundText?.StudioBackgroundText;
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false);
      setApplyActive(false);
    }
  };
 
  const suggestColorHandler = async() => {
    try{
    const base64Image = await activeEditor?.toBase64Url();
    const image = new Image();
    image.onload = () => {
    const colorThief = new ColorThief();
    const palette = colorThief.getPalette(image,5); // an array of the prominent colors in the image
    console.timeEnd("base64");
    let hexValues = palette.map(rgb => `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`);
        setDocumentColors(hexValues);
    };
    image.src = base64Image;
  }catch(error){
    console.error(error);
  }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(()=>{
    if(active && type === SELECTION_TYPE.COLOR)
      suggestColorHandler();
  },[active]);

  return (
    <div css={styles.container} ref={ref}>
      <div css={styles.btnWithTextLabel(textLabel)} onClick={() => setActive((prev) => !prev)}>
      <div>{textLabel}</div>
      <div
        css={styles.mainContainer}
        style={btnStyle}
      >
        <span style={btnTextStyle}>
          {startIcon && <img src={startIcon} width={startIconWidth} height={startIconHeight} />}
          {titleText}
        </span>
        {endIcon && (
          <img
            src={endIcon}
            width={"16px"}
            height={"16px"}
            style={{
              transform: `rotate(${active ? "-180deg" : 0})`,
              transition: "all 0.5s",
            }}
          />
        )}
      </div>
      </div>
      {active && type === SELECTION_TYPE.FORMAT && (
        <div css={styles.dropdownFormatContainer}>
          {/* <span css={styles.dropdownFormatHeader}>
            {strapiData?.FormatText||"Format"}{" "}
          </span> */}
          {formatList?.map((obj) => (
            <div
              css={styles.dropdownFormatOption(
                selectedFilter?.selectedFormat?.title === obj?.title
              )}
              onClick={() => {
                onSelectHandler(obj);
                setActive(false);
              }}
            >
              <Icon
                src={obj?.icon}
                width={16}
                height={16}
                style={styles.mw16}
              />
              <span>
                {obj?.aspectRatio === "1/1" ? strapiData?.SquareText : obj?.aspectRatio === "16/9" ? strapiData?.LandscapeText : obj?.aspectRatio === "9/16" ? strapiData?.PortraitText : obj?.title}
                <div>
                  <Radio
                    checked={
                      selectedFilter?.selectedFormat?.title === obj?.title
                    }
                    size="small"
                    sx={{
                      color: "#262626",
                      "&:hover": { backgroundColor: "transparent" },
                      "&.Mui-checked": {
                        color: "#262626",
                      },
                      "&.Mui-checked:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                </div>
              </span>
            </div>
          ))}
        </div>
      )}

      {active && type === SELECTION_TYPE.COLOR && (
        <div css={styles.dropdownColorContainer}>
          <div css={styles.colorHeader}>{strapiData?.ExploreByColorText||"Explore by Color"}<img src={ICONS.crossIcon} style={{cursor: "pointer"}}onClick={() => {setActive(false);setApplyActive(false)}}/></div>
          {documentColors?.length > 0 && 
          <div css={styles.colorOptions}>
            <ColorOptions type={'CIRCLE'} 
            title={"Choose Color"} 
            colors={documentColors} 
            onClick={(val)=>{
              setSelectedColor(val);
              setApplyActive(true);
            }}/>
          </div>}
          <div css={styles.colorPicker}>
            <ColorPicker defaultValue={selectedColor} onChange={(val) => {
              setSelectedColor(val);
              setApplyActive(true);
              }} />
          </div>
          <div css={styles.btnContainer}>
            <CustomizedButton
              btnText={strapiData?.CancelCTA||"Cancel"}
              type="grey"
              btnStyle={styles.cancelStyle}
              onClick={() => {
                setActive(false);
                setApplyActive(false);
              }}
            />
            <CustomizedButton
              btnText={strapiData?.ApplyCTA||"Apply"}
              type="dark"
              btnStyle={styles.applyStyle}
              onClick={() => {
                onApplyColor(selectedColor);
                setActive(false);
                setApplyActive(false);
              }}
              disabled={!applyActive}
            />
          </div>
        </div>
      )}
      </div>
  );
};

const styles = {
  container: css`
    //  position:relative;
  `,
  mainContainer: css`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    border: 1px solid rgba(238, 238, 238, 0.08);
    color: #eee;
    align-items: center;
    padding: 6px 5px;
    gap: 20px;
    border-radius: 2px;
    cursor: pointer;
    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-family: "Objectivity";
      gap: 5px;
    }
  `,
  dropdownColorContainer: css`
    position: absolute;
    left: 0;
    top: 0px;
    background-color: #FFFFFF;
    width: 101%;
    z-index: 2;
    border-radius: 4px;
  `,
  colorHeader: {
    padding: "10px",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row"
  },
  colorOptions:css`
    width:100%;
    margin:10px;`,
  colorPicker: css`
    padding: 10px;
  `,
  btnContainer: css`
    display: flex;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    gap: 8px;
    border-radius: 0px 0px 4px 4px;
  `,
  cancelStyle: {
    padding: "10px 20px",
    borderRadius: "4px",
    color: "#555",
  },
  applyStyle: {
    width: "100%",
    borderRadius: "4px",
  },
  dropdownFormatContainer: css`
    position: absolute;
    right: 0;
    top: 80px;
    background-color: #fff;
    width: 44%;
    max-width: 240px;
    z-index: 1;
    border-radius: 9px;
  `,
  dropdownFormatHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: black;
  `,
  dropdownFormatOption: (active) => css`
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 0px 2px 10px;
    background-color: black;
    span {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: white;
    }
    :hover {
      background: #333333;
      ;
    }
  `,
  mw16: {
    maxWidth: "16px",
  },
  containerTextLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    gap: "10px",
    fontSize: "14px",
  },
  btnWithTextLabel: (active) => css`
  border:  ${active && "1px solid #DEDEDE"}; ;
  height: 44px;
  padding: 10px 10px 10px 12px;
  border-radius: 4px;
  font-family: Objectivity;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.015em;
  text-align: left;
  display: flex;
  align-items:center;
  justify-content:space-between;
  cursor: pointer;  
    `,
};

export default DropdownSelection;
