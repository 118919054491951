import * as React from "react";
import {
    Dialog,
    DialogTitle,
    Box,
    Typography,
    Button
} from "@mui/material";
import Logo from '../../../components/Logo';
import { useState } from "react";
import Image from "next/image";
import API_SERVICE from "src/utils/axios";
import useAuth from "src/hooks/useAuth";
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { TrackWebappEvent } from "../../segment-event-tracking/segmentEventTracking";
import { EventTrackName } from "../../segment-event-tracking/constant";
import { segmentEventPayload } from "../../editor-v2/Atoms";
import {useAtomValue} from 'jotai';
import { useSelector } from "react-redux";

function FeedbackPop({openFeedbackChat, setFeedBackChat,isSideMenu, setSideMenu, setShare, share, setThumbsUp, thumbsUp, setOpenFeedBack, handleThumbsUp,popUp , setActivePopUp, ...props}) {
    const {user} = useAuth();
    const [negativeResponse, setNegativeFeedback] = useState(false)
    const getSegmentPayloadAtom = useAtomValue(segmentEventPayload);
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.ThumbsUpJourney;
    const jobInfo = props.jobData;
    const open = (isSideMenu) => {
      setSideMenu(!isSideMenu)
    }
         
    const sendNegativeFeedBack = async () => {
        let payload = {
            jobId : jobInfo?.job_id,
        }
        try {
            TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._Click_Thumbs_Down_Fbk}`,getSegmentPayloadAtom);
            await API_SERVICE.post(`/v4/feedback/negativeFeedback`,payload)
            setNegativeFeedback(true);
        }
        catch(error){
            console.error("error",error)
        }
    }
    
    useEffectOnce(()=>{
        TrackWebappEvent(`${process.env.NEXT_PUBLIC_EVENT_TRACK_TYPE}${EventTrackName._Click_Feedback}`,getSegmentPayloadAtom);
    },[]);

    return (
        <Box position="absolute" style={{ width: "100%", height: "40%" }}>
            <Dialog open={props.openpop} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box style={{ width: '600px', height: "440px", display: "flex", flexDirection: "column" }}>
                    <DialogTitle style={{ display: "flex" }}>

                        <div style={{ display: "flex", position: "relative", left: "41%" }}>
                            <Logo disabledLink sx={{ width: 88, height: 24, }} />
                        </div>
                        <svg style={{ display: "flex", cursor: "pointer", position: "relative", top: "-2%", left: "83%" }} onClick={props.handleClose} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9999 3.99998L3.99994 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.99994 3.99998L11.9999 12" stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </DialogTitle>

                    <Box style={{ display: "flex", justifyContent: "center", margin: "30px", }}>
                        <Typography style={{ fontSize: "20px", fontWeight: "500" }}>
                            {strapiData?.ThankYouForUsing || "Thank you for using DoMyShoot"}
                        </Typography>
                    </Box>
                    <Box style={{
                        background: "radial-gradient(995.17% 3755.52% at -23.28% -23.66%, rgba(95, 46, 235, 0.16) 0%, rgba(235, 46, 131, 0.16) 26.44%, rgba(240, 55, 80, 0.16) 78.3%)",
                        position: "absolute",
                        borderRadius: "8px",
                        width: "560px",
                        height: "256px",
                        left: "20px",
                        top: '30%',
                    }}>
                        <Box style={{ display: "flex", justifyContent: "center", margin: "20px", }}>
                            <Typography style={{ fontSize: "14px", color: "#753C95", fontWeight: "500" }}>
                                {strapiData?.YourFeedbackText || "Your Feedback"}
                            </Typography>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.086 1.3588C21.9516 0.8308 21.2988 0.4 20.634 0.4H3.36599C2.69999 0.4 2.04719 0.8308 1.91399 1.3588L1.67279 2.8H22.326L22.086 1.3588ZM23.2944 4H0.704387C0.607461 4.00006 0.511617 4.02037 0.422994 4.05963C0.334371 4.09888 0.254924 4.15621 0.189742 4.22794C0.124559 4.29968 0.0750782 4.38423 0.0444685 4.4762C0.0138588 4.56817 0.00279527 4.66551 0.0119866 4.762L1.11959 18.7648C1.14165 18.9933 1.24801 19.2055 1.41794 19.3599C1.58787 19.5143 1.80919 19.5999 2.03879 19.6H21.96C22.1896 19.5999 22.4109 19.5143 22.5808 19.3599C22.7508 19.2055 22.8571 18.9933 22.8792 18.7648L23.9868 4.762C23.996 4.66551 23.9849 4.56817 23.9543 4.4762C23.9237 4.38423 23.8742 4.29968 23.809 4.22794C23.7438 4.15621 23.6644 4.09888 23.5758 4.05963C23.4872 4.02037 23.3913 4.00006 23.2944 4ZM14.9256 8.05C15.2241 8.05 15.5104 8.16859 15.7215 8.37968C15.9326 8.59077 16.0512 8.87707 16.0512 9.1756C16.0512 9.47413 15.9326 9.76043 15.7215 9.97152C15.5104 10.1826 15.2241 10.3012 14.9256 10.3012C14.6271 10.3012 14.3408 10.1826 14.1297 9.97152C13.9186 9.76043 13.8 9.47413 13.8 9.1756C13.8 8.87707 13.9186 8.59077 14.1297 8.37968C14.3408 8.16859 14.6271 8.05 14.9256 8.05ZM6.59999 14.8L9.58319 7.9432L12.9756 13.4344L15.8844 11.9896L17.4 14.8H6.59999Z" fill="#EB2E83" fill-opacity="0.24" />
                            </svg>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "center", margin: "10px", }}>
                            <Typography style={{ fontSize: "16px", color: "#753C95", fontWeight: "400" }}>
                                {strapiData?.HowWouldYouText || "How would you rate your product image quality"}
                            </Typography>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "space-evenly", margin: "24px", padding: "10px 20% 10px 20%" }}>
                            <Button>
                            <Image
                                src="/icons/settings/like.svg"
                                width={64}
                                height={64}
                                onClick={() => {
                                    handleThumbsUp();
                                    props.handleClose();     
                                }}
                            />
                            </Button>
                             <Button>
                             <Image
                                src="/icons/settings/dislike.svg"
                                width={64}
                                height={64}
                                onClick={() => {
                                    setOpenFeedBack(false)
                                    open(isSideMenu)
                                    setFeedBackChat(true)
                                    props.handleClose();
                                    {!negativeResponse && sendNegativeFeedBack()}
                                  }}
                            />
                             </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

export default FeedbackPop;
