import DashedInput, { DashedInputTypes } from "@/modules/ui-elements/DashedInput";
import { ICONS } from "@/modules/ui-elements/Icon";
import { activeProjectAtom, settingProjectNameAtom } from "../Atoms";
import { useAtom, useAtomValue } from "jotai";
import { css } from "@emotion/react";
import { useCallback, useRef, useState } from "react";
import { debounce } from "lodash";
import { setJobName } from "../services/Jobs";
import { useSelector } from "react-redux";

export default function ProjectNameSection() {
    const [activeProject, setActiveProject]:any = useAtom(activeProjectAtom);
    const projectName = useAtomValue<any>(activeProjectAtom)?.name;
    const setProjectName = (v) => {
        setActiveProject((prev)=>({...prev,name:v}));
    }
    const [settingProjectName, setSettingProjectName] = useAtom(settingProjectNameAtom);
    //@ts-ignore
    const {entireStrapiData} = useSelector((state)=> state.strapi)
    const strapiData = entireStrapiData?.StudioProjectDetailsNew?.StudioProjectDetailsNew;
    const count = useRef(0);
    const debouncedProjectNameApiCall = useCallback(debounce(async (projectName,currCount)=>{
        count.current++;
        setSettingProjectName(true);
        await setJobName(activeProject.job_id,projectName).catch(console.error);
        if(count.current == currCount+1) setSettingProjectName(false);
    },200,{trailing: true,leading:false}),[activeProject?.job_id]);

    const onProjectNameChange = (e) => {
        const regex = /^[-_a-zA-Z0-9 ]*$/;
        const regex2 = /^ *$/;
        if(e.target.value.length > 0 && !regex.test(e.target.value[e.target.value.length-1])) e.target.value = e.target.value.slice(0,-1);
        if(!regex.test(e.target.value)) e.target.value = e.target.value.replace(/[^-_a-zA-Z0-9 ]/g, "-");
        if(regex2.test(e.target.value)) return;
        setProjectName(e.target.value);
        debouncedProjectNameApiCall(e.target.value,count.current);
    }
    

    return  <div css={stylesheet}>
        <div className="project-name-title">{strapiData?.ProjectNameText||"Project Name"}</div>
        <DashedInput icon={"/images/edit-two.png"} value={projectName} onChange={onProjectNameChange} loading={settingProjectName} disabled={!activeProject} type={DashedInputTypes.light} isDashVisible={true} dashedInputStyle={{display: 'flex'}} />
    </div>
}

const stylesheet = css`
    .project-name-title {
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 170%;
        color: #262626;
        margin-bottom:8px;
    }
`
