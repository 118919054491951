import { css } from "@emotion/react";
import { ICONS } from "src/modules/ui-elements/Icon";
import IconButton, { IconButtonSizes, IconButtonTypes } from "src/modules/ui-elements/IconButton";
import PropertyInput from "src/modules/ui-elements/PropertyInput";
import SizeItem from "./SizeItem";
import { useSelector } from "react-redux";
  

export default function DefaultSizes({onResizeGenerator=(item)=>()=>{},disabled, activeSize={}}) {
    const {entireStrapiData} = useSelector((state)=> state.strapi); 
    const strapiData = entireStrapiData?.StudioMyProjectText?.StudioMyProjectText;

    return <div css={styles.container}>
        <div css={styles.titleContainer}>{strapiData?.DefaultSizesText || `Default Sizes`}</div>
        <div css={styles.list}>
            {DEFAULT_SIZES.map(size=><SizeItem height={size.height} width={size.width} icon={size.icon} name={size?.aspectRatio === '1/1' ? strapiData?.SquareText : size?.aspectRatio === '16/9' ? strapiData?.LandscapText : size?.aspectRatio === '9/16' ? strapiData?.PortraitText : size.name} onClick={onResizeGenerator(size)} disabled={disabled} active={(activeSize?.width === size.width && activeSize?.height === size.height && activeSize?.name === size.name)}/>)}
        </div>
        <div css={styles.divider}></div>
    </div>
}

const styles = {
    container : css`
            padding: 16px 10px 0px 10px;
            display:flex;
            flex-direction:column;
    `,
    titleContainer: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #000;
        margin-bottom:10px;
    `,
    list: css`
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
    `,
    divider: css`
        width: calc(100% + 20px);
        margin-left:-10px;
        height: 0.5px;
        background: #EEE;
        margin-top:8px;
        margin-bottom:8px;
    `
}

 
export const DEFAULT_SIZES = [{
    width: 2000,
    height: 2000,
    name:  `Square`,
    icon: "/icons/resize/square.png",
    aspectRatio:'1/1'
}, {
    width: 2560,
    height: 1440,
    name:  `Landscape`,
    icon: "/icons/resize/landscape.png",
    aspectRatio:'16/9'
}, {
    width: 1440,
    height: 2560,
    name:  `Portrait`,
    icon: "/icons/resize/portrait.png",
    aspectRatio:'9/16'
}]