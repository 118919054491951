import React, { useRef, forwardRef, useImperativeHandle } from "react";

const FileUploader = forwardRef(({ acceptedExtensions, onFileSelect, single, disabled }, ref) => {
  const fileInput = useRef(null);

  const handleFileUpload = async () => {
    if (fileInput.current.files.length > 0) {
      const filteredFiles = Array.from(fileInput.current.files).filter((file) =>
        acceptedExtensions.includes(file.type)
      );
      await onFileSelect?.(filteredFiles);
      if(fileInput.current?.value) fileInput.current.value = null;
    }
  };

  const openFilePicker = () => {
    fileInput.current.click();
  };

  useImperativeHandle(ref, () => ({
    open: openFilePicker,
    onSelect: handleFileUpload,
  }));

  return (
    <input
      ref={fileInput}
      type="file"
      style={{ display: "none" }}
      onChange={handleFileUpload}
      accept={acceptedExtensions.join(",")}
      multiple={!!!single}
      disabled={disabled}
    />
  );
});

export default FileUploader;