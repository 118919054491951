import { css } from "@emotion/react"
import TemplatesGrid from "./TemplatesGrid"

export default function RecentTemplates() {
    return <div css={styles.container}>
        <div css={styles.topRow}>
            <div css={styles.titleContainer}>
                Recent Templates
            </div>
            <div css={styles.ctaContainer}>
                View All
            </div>
        </div>
        <TemplatesGrid />
    </div>
}

const styles = {
    container: css`
        background: rgba(255, 255, 255, 0.08);
    `,
    topRow: css`
        display:flex;
        flex-direction:row;
        padding:16px 10px 6px 10px;
        justify-content:space-between;
    `,
    titleContainer: css`
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;

        letter-spacing: 0.01em;

        color: #FFFFFF;
    `,
    ctaContainer: css `
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */

        text-align: right;
        letter-spacing: 0.01em;
        text-decoration-line: underline;

        color: rgba(255, 255, 255, 0.7);
    `
}