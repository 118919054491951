import { createSlice } from "@reduxjs/toolkit";
import { useContext, } from "react";
import axios from "axios";
import { dispatch } from "../store";


const initialState = {
  isLoading: false,
  error: null,
  homeScreen: [],
  profileSettingScreen: {},
  dashboardScreen:{},
  editorScreen: {},
  exploreTemplateScreen: [],
  myWorkScreen: [],
  loginScreen:[],
  languageSelect:{},
  loginEmailScreen:{},
  loginMobileScreen:{},
  resendOtpText:[],
  otpEmailScreen:{},
  otpMobileScreen:{},
  loginPasswordScreen:{},
  appSumo:{},
  appSumoList:{},
  imageCompressionValue:{},
  entireStrapiData: {},
  createProjectWebAppValue:{},
};

const slice = createSlice({
  name: "strapi",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET dashboard Screen
    setDashboardScreen(state,action) {
      state.isLoading = false;
      state.dashboardScreen = action.payload;
        
    },
    // SET Login Screen
    setLoginScreen(state,action) {
      state.isLoading = false;
      state.loginScreen = action.payload;
    },
    // SET Language
    setLanguageScreen(state, action) {
      state.isLoading = false;
      state.languageSelect = action.payload;
    },
    // SET Login With Email
    setLoginEmailScreen(state, action) {
      state.isLoading = false;
      state.loginEmailScreen = action.payload;
    },
    // SET Login With Mobile
    setLoginMobileScreen(state, action) {
      state.isLoading = false;
      state.loginMobileScreen = action.payload;
    },
    //SET RESND OTP TEXT
    setResendOtpText(state,action){
      state.isLoading=false;
      state.resendOtpText = action.payload;
    },
    // SET OTP With Email
    setOtpEmailScreen(state, action) {
      state.isLoading = false;
      state.otpEmailScreen = action.payload;
    },
    // SET OTP With Mobile
    setOtpMobileScreen(state, action) {
      state.isLoading = false;
      state.otpMobileScreen = action.payload;
    },
    // SET Login With Password
    setLoginPasswordScreen(state, action) {
      state.isLoading = false;
      state.loginPasswordScreen = action.payload;
    },
    // SET Home Screen
    setHomeScreen(state, action) {
      state.isLoading = false;
      state.homeScreen = action.payload;
    },

    // SET Profile Screen
    setProfileSettingScreen(state, action) {
      state.isLoading = false;
      state.profileSettingScreen = action.payload;
    },

    // SET Editor Screen
    setEditorScreen(state, action) {
      state.isLoading = false;
      state.editorScreen = action.payload;
    },

    // SET Explore Template Screen
    setExploreTemplateScreen(state, action) {
      state.isLoading = false;
      state.exploreTemplateScreen = action.payload;
    },

    // SET My Work Screen
    setMyWorkScreen(state, action) {
      state.isLoading = false;
      state.myWorkScreen = action.payload;
    },
    setImageCompressionValue(state,action){
      state.isLoading = false;
      state.imageCompressionValue = action.payload;
    },
    //SET APPSUMO MEMBERSHIP
    setAppSumo(state,action){
      state.appSumo=action.payload;

    },
    setAppSumoList(state,action){
      state.isLoading = false;
      state.appSumoList=action.payload;
    },

    setEntireStrapiData(state,action){
      state.entireStrapiData=action.payload;
    },    
    setCreateProjectWebAppValue(state,action){
      state.isLoading = false;
      state.createProjectWebAppValue = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setProfileSettingScreen} = slice.actions;

// ----------------------------------------------------------------------



export const getEntireStrapiData = async (lan) => {
    dispatch(slice.actions.startLoading());
    try {
      const response1 = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}v3/usage/get-screenwise-static-content-from-strapi-for-webApp`, { language: lan });
      dispatch(slice.actions.setEntireStrapiData(response1.data?.data || {}));     
    } catch (error) {
      
      dispatch(slice.actions.hasError(error));
    }
};

