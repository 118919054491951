import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";

const StyleSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      //   color: '#fff',
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#fff",
        border: "1px solid #000",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#000",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "rgba(38, 38, 38, 0.24)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#EEEEEE",
    boxSizing: "border-box",
    border: "1px solid #EEEEEE",
  },
}));

const ToggleButton = ({ switchOn, setSwitchOn }) => {
  const onSwitchHandler = (e) => {
    setSwitchOn(e.target.checked);
  };

  return (
    <Box>
      <StyleSwitch checked={switchOn} onClick={onSwitchHandler} />
    </Box>
  );
};

export default ToggleButton;
