import Layer from "local_modules/editor/src/Layer";
import { atom, useAtom} from "jotai";
import { DMSCanvasFile } from "local_modules/editor/src/Interfaces";
import { HueShiftFilterValue } from "local_modules/editor/src/Filter";
import { BACKGROUND_MODE, TEMPLATE_TYPE, filterObject } from "../LeftToolBar/Templates";

export interface IPageState {
    page: number;
    isAutoSaving?: boolean;
    imageId?: string;
    selectedTemplate?: string;
    file?: DMSCanvasFile;
}

export interface IResizeAtom {
    height: number, 
    width: number,
}

export enum ShadowTypes {
    aiShadow = "aiShadow",
    dropShadow = "dropShadow",
    none = "none",
}

export interface RGB {
    r: number,
    g: number,
    b: number
}

export const viewAllElements = atom(false);
export const isRightPaneVisibleAtom = atom(true );
export const isLayersPaneVisibleAtom = atom(true);
export const activeProjectAtom = atom(undefined);
export const toAddProductImageAtom = atom("");
export const toAddProductImageIdAtom = atom("");
export const shouldResizeAtom = atom(null);
export const toAddStickerAtom = atom<any>("");
export const toAddAssetAtom = atom<any>("");
export const toAddAssetIdAtom = atom<any>("");
export const isShootAssetIdAtom = atom<boolean>(false);
export const toAddFontTextAtom = atom<any>("");
export const toAddBrandKitLogoAtom = atom<any>("");
export const toAddBackgroundIdAtom = atom<any>("");
export const toAddBackgroundAtom = atom<any>("");
export const toAddBackgroundObjectAtom = atom<any>(undefined);
export const toAddBackgroundFileObject = atom<DMSCanvasFile | undefined>(undefined);
export const isToAddAssetNonBGRemovedAtom = atom<boolean>(false);
export const toAddInfographicAtom = atom<boolean>(false);
export const templateJsonAtom = atom<any>(""); 
export const shouldSetFormatAtom = atom<any>("");
export const toAddSvgElementAtom = atom<any>(null);
export const uploadedRawImagesDataAtom = atom<any>([]);
export const uploadedImagesLoaderAtom = atom(false);

/**
 * Editor Atoms
 */
export const pagesAtom = atom([]);
export const pageStatesAtom = atom<Array<IPageState>>([]);
if (process.env.NODE_ENV !== 'production') {
    pageStatesAtom.debugLabel = 'pageStatesAtom';
}
export const activePageAtom = atom<number | undefined>(undefined);
export const editorSlider = atom(<any>(null));
export const isAutoSavingAtom = atom(false);
export const initializingEditorAtom = atom(false);
export const settingProjectNameAtom = atom(false);
export const pagesImagesAtom = atom([]);
export const gridCanvasAtom = atom(false);
/**
 * BrandKit Atoms
 */
export const isAddColorModalVisibleAtom = atom(false);

/**
 * Active Editor Atoms
 */
export const layersAtom = atom([]);
export const historyDalleAtom = atom<boolean>(false);

/**
 * Base Properties
 */
export const zoomAtom = atom(100);
export const initialZoomAtom = atom(100);
export const selectedLayerAtom = atom<Layer | undefined>(undefined);
export const backgroundImageAtom = atom(undefined);
if (process.env.NODE_ENV !== 'production') {
    backgroundImageAtom.debugLabel = 'backgroundImageAtom';
}
export const backgroundColorAtom = atom("");
export const leftNavigationActiveIndexAtom = atom(-2);
export const isAttachInProgressAtom = atom<boolean>(false);
export const isDeAttachInProgressAtom = atom<boolean>(false);

/**
 * Selected Layer Properties
 */
export const opacityAtom = atom<number | undefined>(undefined);
export const rotationAtom = atom<number | undefined>(undefined);
export const flipXAtom = atom(false);
export const flipYAtom = atom(false);
export const aspectRatioLockAtom = atom(false);
export const copyLayerDataAtom = atom(undefined);

/**
 * Selected Shadow Properties
 */
export const shadowTypeAtom = atom<any>(null);
export const shadowBlurAtom = atom<number | undefined>(2);
export const shadowOpacityAtom = atom<number | undefined>(2);
export const shadowHorizontalAtom = atom<number | undefined>(4);
export const shadowVerticalAtom = atom<number | undefined>(4);
export const shadowColorAtom = atom<RGB | undefined>(undefined);
export const aiShadowLoadingAtom = atom(false);

/**
 * Mirror Reflection Properties
 */
export const mirrorRefBlurAtom = atom<number | undefined>(undefined);
export const mirrorRefOpacityAtom = atom<number | undefined>(undefined);
export const mirrorRefVerticalAtom = atom<number | undefined>(undefined);
export const mirrorRefFeatherAtom = atom<number | undefined>(undefined);


/**
 * Selected TextLayer Properties
 */
export const fontSizeAtom = atom<number | undefined>(undefined);
export const fontFamilyAtom = atom<string | undefined>(undefined);
export const fontWeightAtom = atom<string | undefined>(undefined);
export const textAlignAtom = atom<string | undefined>(undefined);
export const lineHeightAtom = atom<number | undefined>(undefined);
export const letterSpacingAtom = atom<number | undefined>(undefined);
export const textColorAtom = atom<string | undefined>(undefined);
export const underlineAtom = atom(false);
export const italicAtom = atom(false);

/**
 * Selected ImageLayer Properties
 */
export const brightnessAtom = atom<number | undefined>(undefined);
export const contrastAtom = atom<number | undefined>(undefined);
export const saturationAtom = atom<number | undefined>(undefined);
export const hueAtom = atom<number | undefined>(undefined);
export const blurAtom = atom<number | undefined>(undefined);
export const hueShiftAtom = atom<HueShiftFilterValue[]>([])

/**
 * Selected SVGLayer Properties
 */
export const svgBackgroundZoomAtom = atom(1);
export const svgBackgroundFillColorAtom = atom("#FFFFFFFF");
export const svgBackgroundImageUrlAtom = atom("");
export const svgBackgroundPositionXAtom = atom(0);
export const svgBackgroundPositionYAtom = atom(0);
export const svgBackgroundImageOpacityAtom = atom(1);
export const svgBackgroundImageRotationAtom = atom(0);
export const svgBorderThickness = atom(0);
export const svgBorderColorAtom = atom("#000000")

/**
 * Color Atoms
 */
export const documentColorsAtom = atom(["#E7A160", "#60A6E7","#AB60E7","#E76060"]);
export const recentlyUsedColorsAtom = atom([]);
export const selectedColorAtom = atom<string | undefined>(undefined);

/**
 * Right Pane Atoms
 */
export const isColorSelectionPaneOpenAtom = atom(false);
export const isFontSpacingPaneOpenAtom = atom(false);
export const isOpacityPaneOpenAtom = atom(false);
export const isAdjustmentPaneOpenAtom = atom(false);
export const isShadowPanOpenAtom = atom(false);

/**
 * Storage Atoms
 */
export const totalStorageAtom = atom(0);
export const consumedStorageAtom = atom(0);

/**
 * Fonts Atoms
 */
export const fontsAtom = atom<any>([]);


/**
 * texture Atoms
 */
export const selectedTextureAtom = atom({});
export const appliedHotspotSkeltonAtom = atom(0);
export const textureAndColorActiveAtom = atom(false);

/**
 * custom prop Atoms
 */
export const selectedCustomGeneratedPropAtom = atom(null);
export const isCustomPropsEnabledAtom = atom<boolean>(false);

/**
 * success popup Atoms
 */
export const showSuccessProjectPopupAtom = atom<any>(null);
export const reopenSuccessProjectPopupAtom = atom<boolean>(false);
export const tempComplementryJobIdAtom = atom('');
export const tempComplementryProTouchAtom = atom<boolean>(false);
export const jobSuccessScreenDataAtom = atom(null);
export const zipAndDownloadImagesDataAtom = atom(null);

/**
 * login user Atoms
 */
export const loginTypeEmailAtom = atom(true);
export const openVerifyEmailAtom = atom(false);
export const userInputEmailAtom = atom('');
export const userInputMobileAtom = atom({});
export const isProFeatureClickedAtom = atom(false);
export const isRemoveWatermarkClickedAtom = atom(false);
if (process.env.NODE_ENV !== 'production') {
    isRemoveWatermarkClickedAtom.debugLabel = "isRemoveWatermarkClickedAtom";
}
export const showLoginPopupAtom = atom(false);
export const segmentEventPayload = atom({});
export const sendToHomepageAtom = atom(false);

/**
 * Background Filters and Atoms
 */
export const backgroundFilterIsAllAngleGroupActiveAtom = atom(true);
export const backgroundFilterBackgroundModeAtom = atom(BACKGROUND_MODE.BASIC);
export const backgroundFilterTemplateTypeAtom = atom(TEMPLATE_TYPE.ALL);
export const backgroundFilterProductImageLayerIdAtom = atom(undefined);
export const backgroundFilterProductImageUrlAtom = atom(undefined);
export const backgroundFilterProductImageIdAtom = atom(undefined);
export const backgroundFilterAtom = atom(filterObject);
export const hotspotFilterAtom = atom(false);

/**
 * project backtrack
 */
export const backtrackProjectAtom = atom(true);
/**
 * recharge redirection Atoms
 */
export const rechargeRedirection = atom('');
/**
 * assets Atoms
 */
export const searchAssetKeywordAtom = atom('');
export const activeAssetTabAtom = atom(0);
export const isBottomOverlaySelectedAtom = atom(false);
/** 
 * infographics 
*/
export const standardInfographic = atom(true);
export const infographicsLayerProgressAtom = atom(false);

/**
 * AI Background Atoms
 */

export const aiBackgroundUrlsAtoms = atom([]);
export const aiBackgroundPromptAtom = atom("");
export const aiBackgroundsS3UrlsAtom = atom([]);
export const aigenerationLayerProgressAtom = atom<number | undefined>(undefined);

//DYNAMIC ATTRIBUTES
export const materialAtom = atom("");
export const settingsAtom = atom("");
export const productAtom = atom("");
export const lightingAtom = atom("");
export const moodAtom = atom("");
export const scaleAndProportionAtom = atom("");
export const colorPalleteAtom = atom("");
export const additionalElementsAtom = atom("");
export const materialListAtom = atom([]);
export const settingsListAtom = atom([]);
export const productListAtom = atom([]);
export const lightingListAtom = atom([]);
export const moodListAtom = atom([]);
export const scaleAndProportionListAtom = atom([]);
export const colorPalleteListAtom = atom([]);
export const additionalElementsListAtom = atom([]);

/**
 * MY Projects
 */
export const  uploadedCSVAtom = atom([]);
export const  uploadCSVProgressAtom = atom(0);
export const  uploadedCSVStatusAtom = atom(false);
export const  closeUploadedCSVAtom = atom(false);
 
/**
 * Homepage
 */
export const homepageFeatureAtom = atom("");

/**
 * Strapi Dimension Limit
 */
export const strapiDimensionLimitAtom = atom(10);
export const createNewPopUpAtom = atom(false);