import { createSlice } from "@reduxjs/toolkit";

/* 
    NOTIFICATION 
    { 
        id: number, 
        title: string, 
        description: string, 
        read: boolean, 
        type: string, ['image_uploading', 'image_upload_done', 'folder_uploading', 'folder_upload_done']
        createdAt: Date
    }
    {
      id: 1,
      title: "Image Upload",
      description: "Image upload is in progress",
      type: "image_uploading",
      read: false,
      createdAt: new Date(),
    },
*/

const initialState = {
  read: [],
  unread: [],
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addUnReadNotification(state, action) {
      state.unread.push(action.payload);
    },
    addReadNotification(state, action) {
      state.read.push(action.payload);
    },
    updateUnReadNotification(state, action) {
      let unread = state.unread.map((unread) => {
        if (unread.id === action.payload.id) {
          return { ...unread, ...action.payload };
        }
        return unread;
      });
      state.unread = unread;
    },
    removeUnReadNotification(state, action) {
      state.unread = state.unread.filter(
        (notification) => notification.id !== action.payload
      );
    },
    removeReadNotification(state, action) {
      state.read = state.read.filter(
        (notification) => notification.id === action.payload
      );
    },
    markRead(state, action) {
      const readNotification = state.unread.find(
        (notification) => notification.id === action.payload
      );
      state.read.push(readNotification);
    },
    markAllRead(state) {
      state.read = state.unread;
      state.unread = [];
    },
  },
});

// Reducers
export default slice.reducer;

// Actions
export const {
  addUnReadNotification,
  addReadNotification,
  markRead,
  removeUnReadNotification,
  removeReadNotification,
  markAllRead,
  updateUnReadNotification,
} = slice.actions;
