import axios from "axios";
import API_SERVICE from "src/utils/axios";

const CHANNEL_ADVISOR_GET_PRODUCT = "v4/channel-advisor/products";
const CHANNEL_ADVISOR_IMAGE_SYNC = "v4/channel-advisor/image-sync";
const CHANNEL_ADVISOR_PRODUCT_IMAGE_GET = "v4/channel-advisor/product-images";
const GET_CHANNELADVISOR_BULK_JOBS_SYNC = '/v4/channel-advisor/marketplace-product-bulk-sync'
const GET_CHANNELADVISOR_PRODUCT_ATTRIBUTES = '/v4/channel-advisor/product-attributes';
const GET_AMAZON_PRODUCTS = '/v4/amazon/listings';
const GET_AMAZON_PRODUCT_DETAILS = '/v4/amazon/product-details';
const GET_AMAZON_PRODUCT_IMAGES = '/v4/amazon/product-images';
const AMAZON_SYNC = '/v4/amazon/sync-media-internal';
const GET_WALMART_PRODUCTS = '/v4/walmart/products';
const WALMART_SYNC = '/v4/walmart/products';
const GET_WALMART_PRODUCT_ATTRIBUTES = '/v4/walmart/product-attributes';
const GET_WALMART_BULK_JOBS_SYNC = '/v4/walmart/marketplace-product-bulk-sync';
const GET_SHOPIFY_PRODUCTS = '/v4/shopify/get-All-ShopifyProducts';
const GET_SHOPIFY_PRODUCT_DETAILS = '/v4/shopify/get-productById';
const SHOPIFY_SYNC = '/v4/shopify/product-image-sync';
const GET_SHOPIFY_PRODUCT_ATTRIBUTES = '/v4/shopify/product-attributes';
const GET_SHOPIFY_BULK_JOBS_SYNC = '/v4/shopify/marketplace-product-bulk-sync';
const SAVE_SHOPIFY_BULK_UPLOAD_PROJECT = `/v4/shootnewLifestyleImage/marketplaceImport`;

export const getChannelAdvisorProductsApi = () => {
  return API_SERVICE.get(CHANNEL_ADVISOR_GET_PRODUCT);
}

export const syncImagesToChannelAdvisorApi = (payload) => {
  return API_SERVICE.post(CHANNEL_ADVISOR_IMAGE_SYNC, payload);
}

export const getMarketplaceProductImagesApi = async (productId, marketplace, marketplaceMappingId) => {
  let url;
  if (marketplace === 'CHANNEL_ADVISOR') url = `${CHANNEL_ADVISOR_PRODUCT_IMAGE_GET}?productId=${productId}`;
  if (marketplace === 'AMAZON') {
    return { data: { data: [] } };
    url = `${GET_AMAZON_PRODUCT_IMAGES}?sku=${productId}`;
  }
  if (marketplace === 'SHOPIFY') url = `${GET_SHOPIFY_PRODUCT_DETAILS}/${productId}/${marketplaceMappingId}`;
  return await API_SERVICE.get(url);
}

export const getAmazonProductDetailsApi = async (sku) => {
  const url = `${GET_AMAZON_PRODUCT_DETAILS}?sku=${sku}`;
  return await API_SERVICE.get(url)
}

export const getChannelAdvisorProductAttributesApi = async (marketplace) => {
  let url;
  if (marketplace === 'CHANNEL_ADVISOR') url = GET_CHANNELADVISOR_PRODUCT_ATTRIBUTES;
  if (url)
    return await API_SERVICE.get(url);
}

export const getWalmartProductAttributesApi = async (marketplace) => {
  let url;
  if (marketplace === 'WALMART') url = GET_WALMART_PRODUCT_ATTRIBUTES;
  if (url)
    return await API_SERVICE.get(url);
}

export const getShopifyProductAttributesApi = async (marketplace) => {
    let url;
    if (marketplace === 'SHOPIFY') url = GET_SHOPIFY_PRODUCT_ATTRIBUTES;
    if (url)
      return await API_SERVICE.get(url);
  }

export const saveShopifyAccountBulkUpload = async (params) => {
  return await API_SERVICE.post(SAVE_SHOPIFY_BULK_UPLOAD_PROJECT,params)
};
export const marketplaceBulkSyncApi = async (marketplace, jobIds, attribute, marketplaceId,replaceOldImage) => {
  let url;
  if (marketplace === 'CHANNEL_ADVISOR') url = GET_CHANNELADVISOR_BULK_JOBS_SYNC + (replaceOldImage ? "?replaceOldImages=true" : "");
  if (marketplace === 'WALMART') url = GET_WALMART_BULK_JOBS_SYNC ;
  if (marketplace === 'SHOPIFY') url = GET_SHOPIFY_BULK_JOBS_SYNC + (replaceOldImage ? "?replaceOldImages=true" : "");
  return await API_SERVICE.post(url, {
    jobIds,
    attribute,
    marketplaceId
  });
}

export const getAmazonProductsApi = async () => {
  return await API_SERVICE(GET_AMAZON_PRODUCTS);
}

export const getWalmartProductsApi = async (walmartId, offset, limit, search) => {
    let url = `${GET_WALMART_PRODUCTS}/${walmartId}`;
        url += "?";
        url += `offset=${offset || 0}`;
        url += `&limit=${limit || 20}`;
    if(search) {
        url += `&search=${search || 20}`;
    }
  return await API_SERVICE(url);
}

export const getShopifyProductsApi = async (shopifyId) => {
    return await API_SERVICE(`${GET_SHOPIFY_PRODUCTS}/${shopifyId}`);
}

export const syncToAmazonApi = async (payload) => {
  return await API_SERVICE.post(AMAZON_SYNC, payload);
}

export const syncToWalmartApi = async (walmartId, payload) => {
    let url = `${WALMART_SYNC}/${walmartId}`;
    return await API_SERVICE.put(url, payload);
}

export const syncToShopifyApi = async (payload) => {
    let url = `${SHOPIFY_SYNC}`;
    return await API_SERVICE.post(url, payload);
}